import {createAsyncThunk} from '@reduxjs/toolkit';
import {LabelRepository} from '@repositories';

import type {CreateLabelDto, LabelModel} from '@model/extra/Label';
import type {ApiError} from '@services/Axios';

// region --- Get Actions

export const getLabels = createAsyncThunk<
  { labels: LabelModel[]; labelListType: 1 | 2 },
  1 | 2,
  { rejectValue: string }
>('Label/getLabels', async (enumLabelPlace, { rejectWithValue }) => {
  try {
    const response = await LabelRepository.fetchAll(enumLabelPlace);

    return { labels: response.resultObject, labelListType: enumLabelPlace };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getLabelsByPoOrName = createAsyncThunk<
  { labels: LabelModel[]; labelListType: 1 | 2 },
  1 | 2,
  { rejectValue: string }
>('Label/GetLabelsPOOrName', async (switchPoOrName, { rejectWithValue }) => {
  try {
    const response = await LabelRepository.fetchAllByPoOrName(switchPoOrName);

    return { labels: response.resultObject, labelListType: 2 };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addLabel = createAsyncThunk<
  { labels: LabelModel[]; labelListType: 1 | 2; createdLabel: LabelModel },
  { label: Omit<CreateLabelDto, 'enumLabelPlace'>; enumLabelPlace: 1 | 2 },
  { rejectValue: string }
>('Label/addLabel', async (dto, { rejectWithValue }) => {
  try {
    const { resultObject } = await LabelRepository.add({
      ...dto.label,
      enumLabelPlace: dto.enumLabelPlace,
    });
    const response = await LabelRepository.fetchAll(dto.enumLabelPlace);

    return {
      labels: response.resultObject,
      labelListType: dto.enumLabelPlace,
      createdLabel: resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions
