import { createAsyncThunk } from '@reduxjs/toolkit';
import { SiteRepository } from '@repositories';

import type {
  CreateSiteDto,
  FilterSiteDto,
  SiteModel,
  UpdateSiteDto,
  UpdateSiteParentDto,
} from '@model/properties/Site';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer';

// region --- Get Actions

export const getSites = createAsyncThunk('Site/getSites', async (_, { rejectWithValue }) => {
  try {
    const response = await SiteRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getSitesByFilter = createAsyncThunk<
  { sites: SiteModel[]; siteFilters: FilterSiteDto },
  FilterSiteDto,
  { rejectValue: string }
>('Site/getSitesByFilter', async (dto, { rejectWithValue }) => {
  try {
    const response = await SiteRepository.fetchAllByFilter(dto);

    return { sites: response.resultObject, siteFilters: dto };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getSiteById = createAsyncThunk<SiteModel, string>(
  'Site/getSiteById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await SiteRepository.fetchById(id);

      return response.resultObject[0];
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addSite = createAsyncThunk<
  { createdSite: SiteModel; sites: SiteModel[] },
  CreateSiteDto,
  { rejectValue: string }
>('Site/addSite', async (dto, { rejectWithValue }) => {
  try {
    const createdSite = await SiteRepository.add(dto);

    const sites = await SiteRepository.fetchAll();

    return { createdSite: createdSite.resultObject[0], sites: sites.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateSite = createAsyncThunk<
  { sites: SiteModel[]; site: SiteModel },
  UpdateSiteDto,
  { state: RootState }
>('Site/updateSite', async (dto, { rejectWithValue }) => {
  try {
    const response = await SiteRepository.update(dto);
    const responseList = await SiteRepository.fetchAll();

    return { sites: responseList.resultObject, site: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const updateSiteParentRelations = createAsyncThunk<SiteModel[], UpdateSiteParentDto>(
  'Site/updateSiteParentRelations',
  async (dto, { rejectWithValue }) => {
    try {
      await SiteRepository.updateParentRelations(dto);
      const response = await SiteRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Update Actions

// region --- Remove Actions

export const removeSiteById = createAsyncThunk<SiteModel[], number[]>(
  'Site/removeSiteById',
  async (ids, { rejectWithValue }) => {
    try {
      await SiteRepository.removeById(ids);
      const response = await SiteRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Remove Actions
