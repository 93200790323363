import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToolbarDropdownItem } from '@components/common';
import { useModalState } from '@hooks';
import { AssigneePlusIcon } from '@icon/icon-components';

import { useAssetListContext } from '../../../../AssetListProvider';
import { AssigneeModal } from './AssigneeModal';

import './AssigneeDropdown.scss';

export const AssigneeDropdown = memo(() => {
  const { listUsers } = useAssetListContext();

  const { t } = useTranslation();

  const [assigneeID, setAssigneeID] = useState<number | null>(null);

  const assigneeModal = useModalState();

  const assigneeDropdown = useModalState();

  const handleItemClick = (key: string) => {
    setAssigneeID(+key);

    assigneeDropdown.actions.onClose();

    assigneeModal.actions.onOpen();
  };

  const handleOpenChange = () => {
    assigneeDropdown.actions.onToggle();
  };

  const usersWithFullName = useMemo(
    () =>
      listUsers.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        key: user.userId,
      })),
    [listUsers]
  );

  return (
    <>
      <ToolbarDropdownItem
        isOpen={assigneeDropdown.isOpen}
        label={t('titles.Change_With_Name', { name: t('titles.Assignee') })}
        icon={<AssigneePlusIcon fill={assigneeDropdown.isOpen ? '#74AAEA' : '#fff'} />}
        items={usersWithFullName}
        onOpenChange={handleOpenChange}
        onClickItem={handleItemClick}
      />
      <AssigneeModal
        assigneeID={assigneeID}
        isOpen={assigneeModal.isOpen}
        onClose={assigneeModal.actions.onClose}
      />
    </>
  );
});
