import { createSlice } from '@reduxjs/toolkit';
import {
  addAsAtStatus,
  getAsAtStatusById,
  getAsAtStatuses,
  removeAsAtStatusById,
  updateAsAtStatus,
} from '@store/actions/properties/AsAt/Status';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AsAtStatusModel } from '@model/properties/AsAt/Status';

interface AsAtStatusState {
  AsAtStatusesList: AsAtStatusModel[];
  AsAtStatus: AsAtStatusModel | null;
  AsAtStatusStatus: LoadingStatus;
  AsAtStatusError: string | null;
}

const initialState: AsAtStatusState = {
  AsAtStatusesList: [],
  AsAtStatus: null,
  AsAtStatusStatus: 'idle',
  AsAtStatusError: null,
};

const AsAtStatusSlice = createSlice({
  name: 'AsAtStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsAtStatuses.pending, (state) => {
        state.AsAtStatusError = null;
        state.AsAtStatusStatus = 'pending';
      })
      .addCase(getAsAtStatuses.fulfilled, (state, { payload }) => {
        state.AsAtStatusStatus = 'idle';
        state.AsAtStatusesList = payload;
      })
      .addCase(getAsAtStatuses.rejected, (state, { payload }) => {
        state.AsAtStatusStatus = 'idle';
        if (payload) {
          state.AsAtStatusError = payload;
        }
      });

    builder
      .addCase(addAsAtStatus.pending, (state) => {
        state.AsAtStatusStatus = 'pending';
        state.AsAtStatusError = null;
      })
      .addCase(addAsAtStatus.fulfilled, (state, { payload }) => {
        state.AsAtStatusStatus = 'idle';
        state.AsAtStatusesList = [payload, ...state.AsAtStatusesList];
      })
      .addCase(addAsAtStatus.rejected, (state, { payload }) => {
        state.AsAtStatusStatus = 'idle';
        if (payload) {
          state.AsAtStatusError = payload;
        }
      });

    builder
      .addCase(getAsAtStatusById.pending, (state) => {
        state.AsAtStatusStatus = 'pending';
        state.AsAtStatusError = null;
      })
      .addCase(getAsAtStatusById.fulfilled, (state, { payload }) => {
        state.AsAtStatusStatus = 'idle';
        state.AsAtStatus = payload;
      })
      .addCase(getAsAtStatusById.rejected, (state, { payload }) => {
        state.AsAtStatusStatus = 'idle';
        if (payload) {
          state.AsAtStatusError = payload;
        }
      });

    builder
      .addCase(updateAsAtStatus.pending, (state) => {
        state.AsAtStatusStatus = 'pending';
        state.AsAtStatusError = null;
      })
      .addCase(updateAsAtStatus.fulfilled, (state, { payload }) => {
        state.AsAtStatusStatus = 'idle';
        state.AsAtStatusesList = payload.AsAtStatusesList;
        state.AsAtStatus = payload.AsAtStatus;
      })
      .addCase(updateAsAtStatus.rejected, (state, { payload }) => {
        state.AsAtStatusStatus = 'idle';
        if (payload) {
          state.AsAtStatusError = payload;
        }
      });

    builder
      .addCase(removeAsAtStatusById.pending, (state) => {
        state.AsAtStatusStatus = 'pending';
        state.AsAtStatusError = null;
      })
      .addCase(removeAsAtStatusById.fulfilled, (state, { payload }) => {
        state.AsAtStatusStatus = 'idle';
        state.AsAtStatusesList = payload;
      })
      .addCase(removeAsAtStatusById.rejected, (state, { payload }) => {
        state.AsAtStatusStatus = 'idle';
        if (payload) {
          state.AsAtStatusError = payload;
        }
      });
  },
});
const AsAtStatusReducer = AsAtStatusSlice.reducer;

export { AsAtStatusReducer };
