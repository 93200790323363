import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { MaintenanceRepository, MaintenanceResultRepository } from '@repositories';
import { identifyError } from '@shared/utils/functions';

import type {
  CreateMaintenanceResultDto,
  MaintenanceResultByHistoryModel,
  MaintenanceResultVer2Model,
  MaintenanceScheduleModel,
  QueryMaintenanceScheduleDto,
  QueryMaintenanceScheduleKanbanDto,
  UpdateMaintenanceResultDto,
  UpdateMaintenanceResultStatusDto,
} from '@model/management/Maintenance';
import type { RootState } from '@store/rootReducer';
import type { AppDispatch } from '@store/store'; // region --- Get Actions
// region --- Get Actions

export const getMaintenanceResultByScheduleIdVer2 = createAsyncThunk<
  {
    result: MaintenanceResultVer2Model[];
    executeStatusId: number;
    resultType: number;
    scheduleId: number;
  },
  { scheduleId: number | string; maintenanceId: number | string },
  { rejectValue: string }
>(
  'MaintenanceResult/getMaintenanceResultByScheduleId',
  async ({ scheduleId, maintenanceId }, { rejectWithValue }) => {
    try {
      const response = await MaintenanceResultRepository.fetchMaintenanceResultByScheduleIdVer2(
        scheduleId,
        maintenanceId
      );

      return {
        result: response.resultObject,
        resultType: response.resultObjectType,
        executeStatusId: response.maintenanceExecuteStatusId,
        scheduleId: +scheduleId,
      };
    } catch (e: any) {
      const error = identifyError(e);

      return rejectWithValue(error);
    }
  }
);

export const getMaintenanceResultByHistory = createAsyncThunk<
  {
    result: MaintenanceResultByHistoryModel;
    executeStatusId: number;
    resultType: number;
    scheduleId: number;
  },
  { scheduleId: number | string; maintenanceId: number | string; nonCurrAssetId: number | string },
  { rejectValue: string }
>(
  'MaintenanceResult/getMaintenanceResultByHistory',
  async ({ scheduleId, maintenanceId, nonCurrAssetId }, { rejectWithValue }) => {
    try {
      const response = await MaintenanceResultRepository.fetchMaintenanceResultByHistory(
        scheduleId,
        maintenanceId,
        nonCurrAssetId
      );

      return {
        result: response.resultObject,
        resultType: response.resultObjectType,
        executeStatusId: response.maintenanceExecuteStatusId,
        scheduleId: +scheduleId,
      };
    } catch (e: any) {
      const error = identifyError(e);

      return rejectWithValue(error);
    }
  }
);

export const getMaintenanceSchedulesList = createAsyncThunk<
  MaintenanceScheduleModel[],
  QueryMaintenanceScheduleDto,
  { rejectValue: string; state: RootState }
>('MaintenanceResult/getMaintenanceSchedulesList', async (dto, { rejectWithValue, getState }) => {
  try {
    const response = await MaintenanceRepository.fetchMaintenanceSchedulesList(dto);

    const schedulesList = getState().MaintenanceResultReducer.schedulesList;

    if (schedulesList.length) {
      const schedulesIds = schedulesList.map((schedule) => schedule.maintenanceSchedulesId);

      return response.resultObject.filter(
        (schedule) => !schedulesIds.includes(schedule.maintenanceSchedulesId)
      );
    }

    return response.resultObject;
  } catch (e) {
    const error = identifyError(e);

    return rejectWithValue(error);
  }
});

export const getMaintenanceSchedulesKanbanList = createAsyncThunk<
  MaintenanceScheduleModel[],
  QueryMaintenanceScheduleKanbanDto,
  { rejectValue: string }
>('MaintenanceResult/getMaintenanceSchedulesKanbanList', async (dto, { rejectWithValue }) => {
  try {
    const response = await MaintenanceRepository.fetchMaintenanceSchedulesKanbanList(dto);

    return response.resultObject;
  } catch (e) {
    const error = identifyError(e);

    return rejectWithValue(error);
  }
});

// endregion --- Get Actions

// region --- Create Actions

export const createPartialMaintenanceResult = createAsyncThunk<
  void,
  CreateMaintenanceResultDto,
  { rejectValue: string; dispatch: AppDispatch }
>(
  'MaintenanceResult/createPartialMaintenanceResult',
  async (dto, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      let imageUrls: string[] = [];

      if (dto.imgList?.resultImageLinkArray.length) {
        dto.imgList?.resultImageLinkArray.forEach((image) => {
          if (image instanceof File) {
            formData.append('files', image);
          }
        });

        const { resultObject } = await MaintenanceRepository.addWorkStepImage(formData);

        imageUrls = resultObject;
      }

      const updatedDto: CreateMaintenanceResultDto = {
        ...dto,
        imgList: {
          ...dto.imgList,
          resultImageLinkArray: imageUrls,
        } as any,
      };

      await MaintenanceResultRepository.createPartialMaintenanceResult(updatedDto);

      await dispatch(
        getMaintenanceResultByScheduleIdVer2({
          maintenanceId: dto.maintenanceId,
          scheduleId: dto.maintenanceSchedulesId,
        })
      );
    } catch (e) {
      const error = identifyError(e);

      return rejectWithValue(error);
    }
  }
);

export const createMaintenanceResult = createAsyncThunk<
  void,
  CreateMaintenanceResultDto[],
  { rejectValue: string; dispatch: AppDispatch }
>('MaintenanceResult/createMaintenanceResult', async (dto, { rejectWithValue, dispatch }) => {
  try {
    const updateDto = async () => {
      const data = await Promise.all(
        dto.map(async (item) => {
          const formData = new FormData();

          if (item.imgList?.resultImageLinkArray.length) {
            item.imgList?.resultImageLinkArray.forEach((image) => {
              if (image instanceof File) {
                formData.append('files', image);
              }
            });
          }
          if (formData.has('files')) {
            const { resultObject } = await MaintenanceRepository.addWorkStepImage(formData);
            return {
              ...item,
              imgList: {
                workStepsId: item.workStepId,
                resultImageLinkArray: resultObject,
              },
            };
          }
          return item;
        })
      );

      return data;
    };

    const data = await updateDto();

    await MaintenanceResultRepository.createMaintenanceResult(data);

    if (dto.length) {
      const result = dto[0];

      await dispatch(
        getMaintenanceResultByScheduleIdVer2({
          maintenanceId: result.maintenanceId,
          scheduleId: result.maintenanceSchedulesId,
        })
      );
    }
  } catch (e) {
    const error = identifyError(e);

    return rejectWithValue(error);
  }
});

// endregion --- Create Actions

// region --- Update Actions

export const updateMaintenanceResultStatus = createAsyncThunk<
  { executeStatus: number; schedulesList: MaintenanceScheduleModel[] },
  UpdateMaintenanceResultStatusDto,
  { rejectValue: string; state: RootState }
>('MaintenanceResult/updateMaintenanceResultStatus', async (dto, { rejectWithValue, getState }) => {
  try {
    const { resultObject } = await MaintenanceResultRepository.updateMaintenanceResultStatus(dto);
    const { maintenanceExecuteStatusId } = resultObject;

    const schedulesState = getState().MaintenanceResultReducer.schedulesList;

    const patchedSchedules = schedulesState.map((schedule) =>
      schedule.maintenanceSchedulesId === dto.maintenanceSchedulesId
        ? {
            ...schedule,
            maintenanceExecuteStatusId,
          }
        : schedule
    );

    return {
      executeStatus: maintenanceExecuteStatusId,
      schedulesList: patchedSchedules,
    };
  } catch (e) {
    const error = identifyError(e);

    return rejectWithValue(error);
  }
});

export const updateMaintenanceResult = createAsyncThunk<
  MaintenanceResultVer2Model[] | null,
  UpdateMaintenanceResultDto,
  { rejectValue: string; state: RootState }
>('MaintenanceResult/updateMaintenanceResult', async (dto, { rejectWithValue, getState }) => {
  try {
    const { resultObject } = await MaintenanceResultRepository.updateMaintenanceResult(dto);

    const results = getState().MaintenanceResultReducer.maintenanceResult?.result;

    if (results) {
      return results.map((result) => {
        if (result.workStepsId === resultObject.workStepsId) {
          return {
            ...result,
            workSteps: {
              ...result.workSteps,
              maintenancesResults: [resultObject],
            },
          };
        }
        return result;
      });
    }

    return null;
  } catch (e) {
    const err = identifyError(e);

    return rejectWithValue(err);
  }
});

// endregion --- Update Actions

// region --- Simple Actions

export const resetSchedulesList = createAction('MaintenanceResult/resetSchedulesList');

// endregion --- Simple Actions
