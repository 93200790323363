import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetImages, CommonForm } from '@components/common';
import { defaultFormatDate } from '@constants/Date';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Divider, Label } from '@shared/UI';
import { getCurrencies } from '@store/actions/extra/Currency';
import { getAsAtBrands } from '@store/actions/properties/AsAt/Brand';
import { getVendors } from '@store/actions/properties/Vendor';
import { selectCurrenciesList } from '@store/selectors/extra/Currency';
import { selectAssetStatus } from '@store/selectors/management/Asset';
import { selectAsAtBrandsList } from '@store/selectors/properties/AsAt/Brand';
import { selectVendorsList } from '@store/selectors/properties/Vendor';
import { Col, Row } from 'antd';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { AssetModel } from '@model/management/Asset';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormAsset(props: CommonPreviewProps<AssetModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const brandList = useAppSelector(selectAsAtBrandsList);
  const statusAsset = useAppSelector(selectAssetStatus);
  const currencyList = useAppSelector(selectCurrenciesList);
  const listVendor = useAppSelector(selectVendorsList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!brandList.length && entity.nonCurrAssetCardBrandId !== null) {
      dispatch(getAsAtBrands());
    }
    if (!currencyList.length) {
      dispatch(getCurrencies());
    }

    if (listVendor.length === 0) {
      dispatch(getVendors());
    }
  }, []);

  const currency = currencyList.find((item) => item.currencyId === entity.currencyId);

  const partner = listVendor.find((item) => item.partnerId === entity.partnerId);

  const brand = brandList.find(
    (brand) => brand.nonCurrAssetCardBrandId === entity.nonCurrAssetCardBrandId
  );

  const contracts =
    entity.nonCurrAssetContracts && entity.nonCurrAssetContracts.length
      ? entity.nonCurrAssetContracts
          .map((contract) => {
            return contract.contract.name;
          })
          .join(', ')
      : '';

  const propertyValues =
    entity.nonCurrAssetPropertyDetails && entity.nonCurrAssetPropertyDetails.length
      ? entity.nonCurrAssetPropertyDetails.map((property) => ({
          value: property.value,
          propertyName: property?.nonCurrAssetCardProperty?.name || '',
          key: property.nonCurrAssetPropertyDetailId,
        }))
      : '';

  const images = entity?.nonCurrAssetFiles
    ? entity.nonCurrAssetFiles
        .map((file) => ({
          file,
          id: uuidv4(),
        }))
        .sort((a, b) => {
          if (!a.file.rowNumber || !b.file.rowNumber) {
            return 0;
          }
          return a.file.rowNumber - b.file.rowNumber;
        })
    : null;

  const assignTo =
    entity.nonCurrAssetUsers && entity.nonCurrAssetUsers.length
      ? `${entity.nonCurrAssetUsers[0].user.firstName} ${entity.nonCurrAssetUsers[0].user.lastName}`
      : null;

  return (
    <CommonForm
      isPreview
      isOutsidePreviewBack={false}
      loading={statusAsset === 'pending'}
      container={{
        style: { width: '100%', maxWidth: '100%' },
      }}
      header={{
        title: entity.barcode,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField md={24} lg={7}>
        <Item label={t('titles.Barcode')}>
          <PreviewField>{entity.barcode}</PreviewField>
        </Item>
        <Item label={t('titles.Card_Category')}>
          <PreviewField>{entity.nonCurrAssetCardCategory.name}</PreviewField>
        </Item>
        <Item label={t('titles.Vendor')}>
          <PreviewField>{partner?.name}</PreviewField>
        </Item>
        <Item label={t('titles.Status')}>
          <PreviewField>{entity.status.name}</PreviewField>
        </Item>
      </CaseField>
      <CaseField md={24} lg={7}>
        <Item label={t('titles.Serial_Number')}>
          <PreviewField>{entity?.serialNumber}</PreviewField>
        </Item>
        <Item label={t('titles.Brand')}>
          <PreviewField>{brand?.name}</PreviewField>
        </Item>
        <Item label={t('titles.Site')}>
          <PreviewField>{entity.site.name}</PreviewField>
        </Item>
      </CaseField>
      <CaseField md={24} lg={7}>
        <Item label={t('titles.Asset_Card')}>
          <PreviewField>{entity.nonCurrAssetCard.name}</PreviewField>
        </Item>
        <Item label={t('titles.Model')}>
          <PreviewField>{entity?.nonCurrAssetCardModel?.name}</PreviewField>
        </Item>
        <Row>
          <Col lg={12} md={24} xs={24}>
            <Item label={t('titles.Invoice_Date')}>
              <PreviewField inputTextCenter>
                {moment(entity.invoiceDate).format(defaultFormatDate)}
              </PreviewField>
            </Item>
          </Col>
        </Row>
      </CaseField>

      <Divider />

      <CaseField md={24} lg={7} hiddenTitle>
        <Item label={t('titles.Invoice_Number')}>
          <PreviewField>{entity?.invoiceNo}</PreviewField>
        </Item>
        <Item label={t('pages_plural.Contracts')}>
          <PreviewField>{contracts}</PreviewField>
        </Item>

        {propertyValues && propertyValues.length ? (
          <Item label={t('titles.Property_Value')}>
            {propertyValues.map((property) => (
              <PreviewField key={property.key} typeField="property-value">
                <span>{property.propertyName}:</span>
                {property.value}
              </PreviewField>
            ))}
          </Item>
        ) : null}
      </CaseField>
      <CaseField md={24} lg={7} hiddenTitle>
        <Item label={t('titles.Invoice_Amount')}>
          <PreviewField>{entity?.price}</PreviewField>
        </Item>
        <Item label={t('pages_single.Cost_Center')}>
          <PreviewField>{entity?.costCenter?.name || ''}</PreviewField>
        </Item>

        <Item label={t('titles.Description')}>
          <PreviewField typeField="textarea">{entity?.description}</PreviewField>
        </Item>
      </CaseField>
      <CaseField md={24} lg={7} hiddenTitle>
        <Row gutter={[10, 0]} justify="space-between">
          <Col md={10} xs={24}>
            <Item label={t('titles.Warranty_Start')}>
              <PreviewField inputTextCenter>
                {entity?.guaranteeStartDate
                  ? moment(entity?.guaranteeStartDate).format(defaultFormatDate)
                  : null}
              </PreviewField>
            </Item>
          </Col>
          <Col md={10} xs={24}>
            <Item label={t('titles.Warranty_End')}>
              <PreviewField inputTextCenter>
                {entity?.guaranteeEndDate
                  ? moment(entity?.guaranteeEndDate).format(defaultFormatDate)
                  : null}
              </PreviewField>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col md={10} xs={24}>
            <Item label={t('titles.Currency')}>
              <PreviewField>{currency?.name}</PreviewField>
            </Item>
          </Col>
        </Row>

        <Item label={t('titles.Label')}>
          <PreviewField>
            {entity?.labels && entity.labels.length
              ? entity.labels.map((label) => {
                  return (
                    <Label key={label.labelId} color={label.labelColor}>
                      {label.label}
                    </Label>
                  );
                })
              : ''}
          </PreviewField>
        </Item>
      </CaseField>

      <Divider />
      <CaseField md={24} hiddenTitle>
        <Row justify="space-between" gutter={[20, 0]}>
          <Col span={15}>
            <Row gutter={[5, 5]}>
              <AssetImages hiddenUploader images={images} />
            </Row>
          </Col>
          <Col span={8}>
            <CaseField md={24} hiddenTitle>
              <Item label={t('titles.Assign_To')}>
                <PreviewField>{assignTo}</PreviewField>
              </Item>
            </CaseField>
          </Col>
        </Row>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormAsset };
