import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter, PartAndInventoryPropertyFields } from '@components/common';
import { useAppSelector } from '@hooks';
import { Checkbox, Input } from '@shared/UI';
import { selectPaIPropertyStatus } from '@store/selectors/properties/PaI/Property';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreatePaIPropertyDto } from '@model/properties/PaI/Property';

const { CaseField, Item, useForm } = CommonForm;

function FormPartAndInventoryProperty(props: CommonFormProps<CreatePaIPropertyDto>) {
  const [form] = useForm<CreatePaIPropertyDto>();

  const { isModal, ...rest } = props;

  const {t} = useTranslation();

  const statusPartAndInventoryProperty = useAppSelector(selectPaIPropertyStatus);

  return (
    <CommonForm
      initialValues={{
        ...rest.initialValues,
        isUnique: rest.initialValues?.isUnique || false,
        partsAndInventoriesCardPropertyValues:
          rest.initialValues?.partsAndInventoriesCardPropertyValues || [],
      }}
      form={form}
      loading={statusPartAndInventoryProperty === 'pending'}
      isModal={isModal}
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onCancel={rest.header?.onClickCancel}
        /> : null
      }
      name='formPaIProperty'
      {...rest}
    >
      <CaseField hiddenTitle isModal={isModal}>
        <Item name="isUnique" valuePropName="checked">
          <Checkbox>{t('titles.Is_Unique')}</Checkbox>
        </Item>

        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')}/>
        </Item>
        <Item
          label={t('titles.Code')}
          name="partsAndInventoriesCardPropertyCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')}/>
        </Item>
        <PartAndInventoryPropertyFields
          initialPartAndInventoryPropertyTypeId={
            rest.initialValues?.partsAndInventoriesPropertyTypeId
          }
        />
      </CaseField>
    </CommonForm>
  );
}

export {FormPartAndInventoryProperty};
