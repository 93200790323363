import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import {
  selectPaIPropertiesList,
  selectPaIPropertyStatus,
} from '@store/selectors/properties/PaI/Property';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { PaIPropertyModel } from '@model/properties/PaI/Property';

const columns: ColumnsTableType<PaIPropertyModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'partsAndInventoriesCardPropertyCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) =>
      compare(a.partsAndInventoriesCardPropertyCode, b.partsAndInventoriesCardPropertyCode),
  },

  {
    title: <Translation>{(t) => t('titles.Property_Type')}</Translation>,
    dataIndex: ['partsAndInventoriesPropertyType', 'name'],
    key: 'typeName',
    ellipsis: true,
    sorter: (a, b) =>
      compare(a.partsAndInventoriesPropertyType.name, b.partsAndInventoriesPropertyType.name),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

const PartAndInventoryProperties = () => {
  const { t } = useTranslation();

  const listPartAndInventoryProperty = useAppSelector(selectPaIPropertiesList);
  const statusPartAndInventoryProperty = useAppSelector(selectPaIPropertyStatus);

  const { paiAttrAccess } = useGetAccess();

  const csvData = useMemo(
    () =>
      listPartAndInventoryProperty.map((property) => {
        return {
          name: property.name,
          code: property.partsAndInventoriesCardPropertyCode,
          propertyType: property.partsAndInventoriesPropertyType.name,
          isUnique: property.isUnique ? 'Yes' : 'No',
        };
      }),
    [listPartAndInventoryProperty]
  );

  return (
    <BasicTablePage
      table={{
        loading: statusPartAndInventoryProperty === 'pending',
        dataSource: listPartAndInventoryProperty,
        columns: columns,
        rowKey: (record) => record.partsAndInventoriesCardPropertyId,
        dataSourceName: t('pages_plural.PaI_Attr_Properties'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Property') }),
        csvConfig: {
          data: csvData,
          fileName: 'part-and-inventory-properties',
          headers: [
            { label: 'Code', key: 'code' },
            { label: 'Name', key: 'name' },
            { label: 'Property Type', key: 'propertyType' },
            { label: 'Is Unique', key: 'isUnique' },
          ],
        },
        newButtonProps: {
          disabled: !paiAttrAccess.create,
        },
      }}
    />
  );
};

export { PartAndInventoryProperties };
