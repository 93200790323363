import { useState } from 'react';

export interface UseModalStateReturn {
  isOpen: boolean;
  actions: {
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
  };
}

const useModalState = (initialOpen = false): UseModalStateReturn => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    actions: {
      onOpen,
      onClose,
      onToggle,
    },
  };
};

export default useModalState;
