import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useModalState } from '@hooks';
import { ToolbarTrashIcon } from '@icon/icon-components';
import { DeleteModal } from '@shared/UI';
import { getAssetAllRecords, removeAssetById } from '@store/actions/management/Asset';

import { useAssetListContext } from '../../../../AssetListProvider';

import './Delete.scss';

export const Delete = memo(() => {
  const { helperCheckedText, checkedAssets, clearChecked } = useAssetListContext();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const deleteModal = useModalState();

  const handleDelete = () => {
    const ids = checkedAssets.map((asset) => asset.nonCurrAssetId);

    dispatch(removeAssetById(ids))
      .unwrap()
      .then(() => {
        dispatch(getAssetAllRecords());
      })
      .finally(() => {
        deleteModal.actions.onClose();
        clearChecked?.();
      });
  };

  return (
    <>
      <button onClick={deleteModal.actions.onOpen} className="asset-toolbar-delete-button">
        <ToolbarTrashIcon />
        <span>{t('titles.Delete')}</span>
      </button>
      <DeleteModal
        open={deleteModal.isOpen}
        onCancel={deleteModal.actions.onClose}
        onOk={handleDelete}
        deletedName={helperCheckedText}
        centered
      />
    </>
  );
});
