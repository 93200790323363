import { v4 as uuidv4 } from 'uuid';

import type { ActionFunction, CheckStepItem } from '../../types';

export const onChangeStepImages: ActionFunction<{ step: CheckStepItem; images: File[] }> = (
  { step, images },
  { setState }
) => {
  setState((prevState) =>
    prevState.map((s) => {
      if (s.uuid === step.uuid) {
        return {
          ...s,
          imagesList: images.map((img) => ({ uuid: uuidv4(), file: img })),
        };
      }
      return s;
    })
  );
};
