import React from 'react';

import { Popover as AntdPopover } from 'antd';
import cl from 'classnames';

import type { PopoverProps as AntdPopoverProps } from 'antd';

import './Popover.scss';

export interface PopoverProps extends AntdPopoverProps {
  disabledArrow?: boolean;
}

const Popover = (props: PopoverProps) => {
  const { className, disabledArrow = true, ...rest } = props;

  const popoverClasses = cl('custom-popover', className, {
    'custom-popover--disabled-arrow': disabledArrow,
  });

  return <AntdPopover overlayClassName={popoverClasses} {...rest} />;
};

export { Popover };
