import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getTitles } from '@store/actions/user/Title';
import { selectTitlesList } from '@store/selectors/users/Title';

function EntryTitle() {
  useSetPageInfo({
    pageTitle: 'pages_single.Title',
    defaultPagePath: paths.title.default,
  });

  const titles = useAppSelector(selectTitlesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!titles.length) {
      dispatch(getTitles());
    }
  }, []);

  return <Outlet />;
}

export default EntryTitle;
