import { api } from '@services/Axios';

import type {
  CostCenterModel,
  CreateCostCenterDto,
  FilterCostCenterDto,
  UpdateCostCenterDto,
} from '@model/properties/CostCenter';
import type { ApiResponse } from '@services/Axios';

const CostCenterEndpoint = '/CostCenter';

export const CostCenterRepository = {
  async fetchAll() {
    const response: ApiResponse<CostCenterModel[]> = await api.get(
      `${CostCenterEndpoint}/GetCostCenterList`
    );

    return response.data;
  },

  async fetchAllByFilter(dto: FilterCostCenterDto) {
    const response: ApiResponse<CostCenterModel[]> = await api.post(
      `${CostCenterEndpoint}/GetCostCenterListByFilter`,
      dto
    );
    return response.data;
  },

  async add(dto: CreateCostCenterDto) {
    const response: ApiResponse<CostCenterModel> = await api.post(
      `${CostCenterEndpoint}/AddCostCenter`,
      dto
    );

    return response.data;
  },

  async fetchById(id: string) {
    const response: ApiResponse<CostCenterModel> = await api.get(
      `${CostCenterEndpoint}/GetCostCenterById/${id}`
    );

    return response.data;
  },

  async update(dto: UpdateCostCenterDto) {
    const response: ApiResponse<CostCenterModel> = await api.post(
      `${CostCenterEndpoint}/UpdateCostCenter`,
      dto
    );

    return response.data;
  },

  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${CostCenterEndpoint}/RemoveByIdList`, {
      costCenterIds: ids,
    });

    return response.data;
  },
};
