import { mapCheckStepsToWorkStepsDto } from './mapCheckStepsToWorkStepsDto';

import type { CreateMaintenanceStepsDto } from '@model/management/Maintenance';
import type { ActionFunction } from '../../types';

export const getNewWorkSteps: ActionFunction<
  { maintenanceId: number; userId: number },
  CreateMaintenanceStepsDto[]
> = (params, actionAPI) => {
  const onlyNewSteps = actionAPI.state.filter((step) => step.isNew);

  return mapCheckStepsToWorkStepsDto(params, {
    ...actionAPI,
    state: onlyNewSteps,
  });
};
