import React from 'react';
import ReactCrop from 'react-image-crop';

import cl from 'classnames';

import { BaseModal } from '../BaseModal';
import { useImageCropper } from './useImageCropper';

import type { BaseModalProps } from '../BaseModal';

import './ImageCropperModal.scss';

export interface ImageCropperModalProps extends Omit<BaseModalProps, 'onCancel'> {
  imageSrc: string;
  onCompleteCrop: (cropedImage: string | null) => void;
  onClose?: () => void;
}

function ImageCropperModal(props: ImageCropperModalProps) {
  const { className, imageSrc, onCompleteCrop: onCompleteCropProps, onClose, ...rest } = props;

  const {
    imageCropRef,
    crop,
    croppedImage,
    actions: { onImageLoad, onCompleteCrop, onChangeCrop },
  } = useImageCropper();

  const imageCropperModalClass = cl('modal-image-cropper', className);

  function onOk() {
    onCompleteCropProps(croppedImage);
    onClose?.();
    onChangeCrop(undefined);
  }

  return (
    <BaseModal
      onOk={onOk}
      size="large"
      className={imageCropperModalClass}
      onCancel={() => {
        onClose?.();
        onChangeCrop(undefined);
      }}
      {...rest}
    >
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => onChangeCrop(percentCrop)}
        onComplete={(c) => onCompleteCrop(c)}
        aspect={1}
        maxWidth={560}
        circularCrop
      >
        <img
          style={{ maxHeight: 600, height: '100%' }}
          width="100%"
          ref={imageCropRef}
          src={imageSrc}
          alt="Cropper"
          onLoad={onImageLoad}
        />
      </ReactCrop>
    </BaseModal>
  );
}

export { ImageCropperModal };
