const CryptographyService = {
  encrypt(id: string) {
    return Buffer.from(`${id}${process.env.REACT_APP_SECRET_KEY}`).toString('base64');
  },
  decrypt(cipher: string) {
    const decrypted = Buffer.from(cipher, 'base64').toString('ascii');
    const regex = new RegExp(process.env.REACT_APP_SECRET_KEY);
    return decrypted.replace(regex, '');
  },
};

export default CryptographyService;
