import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormRole } from '@components/views/Forms/users/Role';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { normalizeRoleFunctionNames } from '@shared/utils/functions';
import { addRole } from '@store/actions/user/Role';

import type { CreateRoleFormDto } from '@model/users/Role';
import type { FormInstance } from 'antd';

function CreateRole() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onFinish = (dto: CreateRoleFormDto, form?: FormInstance<CreateRoleFormDto>) => {
    const functionList = normalizeRoleFunctionNames(dto.functionList);

    dispatch(addRole({ ...dto, functionList }))
      .unwrap()
      .then((response) => {
        ApiNotifications.create(response.createdRole.name);
        form?.resetFields();
        navigate(paths.roles.default);
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <FormRole
      onFinish={onFinish}
      header={{
        title: t('titles.New_Item', { name: t('titles.Role') }),
      }}
    />
  );
}

export default CreateRole;
