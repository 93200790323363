import type { RootState } from '@store/rootReducer';

export const selectAsAtModelState = (state: RootState) => state.AsAtModelReducer;

export const selectAsAtModelsList = (state: RootState) =>
  selectAsAtModelState(state).AsAtModelsList;

export const selectAsAtModel = (state: RootState) => selectAsAtModelState(state).AsAtModel;

export const selectAsAtModelStatus = (state: RootState) =>
  selectAsAtModelState(state).AsAtModelStatus;

export const selectAsAtModelError = (state: RootState) =>
  selectAsAtModelState(state).AsAtModelError;
