import React from 'react';
import { useTranslation } from 'react-i18next';

import { CreatedBy } from '@components/common';

import { FormAction } from './FormAction';

const Form = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={'form__header'}>
        <h4>{t('titles.Sign_In')}</h4>
        <span className="subtitle">{t('titles.SignIn_Details')}</span>
      </div>
      <FormAction />
      <CreatedBy />
    </>
  );
};

export { Form };
