import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CommonForm, ProfileAvatar } from '@components/common';
import { NonCurrAssetsUserTable } from '@components/views/Tables/users/NonCurrAsetsUser/NonCurrAssetsUserTable';
import { useOutletProfile } from '@constants/OutletContextEntityPages';
import { paths } from '@routes/paths';
import { Button } from '@shared/UI';




const { CaseField, Item, PreviewField } = CommonForm;


function PreviewFormProfile() {
    
  const { entity } = useOutletProfile();
  const { t } = useTranslation();

  const extra = [
    <Button key="1" type="text" style={{ width: '100%' }}>
      <Link to={paths.profile.tabs.general.changePassword}>{t('titles.Change_Password')}</Link>
    </Button>,
    <Button key="2" type="primary">
      <Link to={`${paths.profile.tabs.general.default}/${paths.profile.tabs.general.edit}`}>
        {`${t('titles.Edit')} ${t('pages_single.Profile')}`}
      </Link>
    </Button>,
  ];

  return (
    <div>
    <CommonForm
      hiddenPreviewBack
      isPreview
      container={{
        style: { maxWidth: 1086 },
      }}
      header={{
        previewExtra: extra,
      }}
    >
      <CaseField md={24} lg={7} push={1} style={{ marginBottom: 10 }}>
        <ProfileAvatar 
          avatar={entity.picture || null} 
          style={{
            border: `2px solid ${entity.color}`
          }}
        />
      </CaseField>

      <CaseField title={t('titles.General_Information')} md={24} lg={7}>
        <Item label={t('titles.First_Name')}>
          <PreviewField>{entity?.firstName}</PreviewField>
        </Item>
        <Item label={t('titles.Phone_Num')}>
          <PreviewField>{entity?.phoneNumber}</PreviewField>
        </Item>
      </CaseField>
      <CaseField md={24} lg={7}>
        <Item label={t('titles.Last_Name')}>
          <PreviewField>{entity?.lastName}</PreviewField>
        </Item>
        <Item label={t('titles.Email')}>
          <PreviewField>{entity?.userMail}</PreviewField>
        </Item>
      </CaseField>

      <CaseField md={24} lg={7}></CaseField>
      <CaseField title={t('titles.Company_Info')} md={24} lg={7}>
        <Item label={t('titles.Job_Title')}>
          <PreviewField>{entity?.userTitle?.title}</PreviewField>
        </Item>
      </CaseField>
      <CaseField md={24} lg={7}>
        <Item label={t('titles.Role')}>
          <PreviewField>{entity?.userRoles[0]?.role?.name}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>

<NonCurrAssetsUserTable/>

</div>
  );
}

export { PreviewFormProfile };
