import type { RootState } from '@store/rootReducer';

export const selectAssetState = (state: RootState) => state.AssetReducer;

export const selectAssetsList = (state: RootState) => selectAssetState(state).assetsList;

export const selectAssetsAllRecords = (state: RootState) =>
  selectAssetState(state).assetsAllRecordList;

export const selectAssetStatus = (state: RootState) => selectAssetState(state).assetStatus;

export const selectAssetError = (state: RootState) => selectAssetState(state).assetError;

export const selectAsset = (state: RootState) => selectAssetState(state).asset;

export const selectAssetFilterOptions = (state: RootState) => selectAssetState(state).filterOptions;

export const selectAssetSettings = (state: RootState) => selectAssetState(state).assetSettings;

export const selectAssetImages = (state: RootState) => selectAssetState(state).assetImages;

export const selectAssetViewType = (state: RootState) => selectAssetState(state).assetListView;

export const selectAssetChecked = (state: RootState) => selectAssetState(state).checked;
