import { createSlice } from '@reduxjs/toolkit';
import {
  addAsAtProperty,
  getAsAtProperties,
  getAsAtPropertyById,
  getAsAtPropertyTypes,
  removeAsAtPropertyById,
  updateAsAtProperty,
} from '@store/actions/properties/AsAt/Property';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AsAtPropertyModel, AsAtPropertyTypeModel } from '@model/properties/AsAt/Property';

interface AsAtPropertyState {
  AsAtPropertiesList: AsAtPropertyModel[];
  AsAtPropertyTypesList: AsAtPropertyTypeModel[];
  AsAtProperty: AsAtPropertyModel | null;
  AsAtPropertyStatus: LoadingStatus;
  AsAtPropertyError: string | null;
}

const initialState: AsAtPropertyState = {
  AsAtPropertiesList: [],
  AsAtPropertyTypesList: [],
  AsAtProperty: null,
  AsAtPropertyStatus: 'idle',
  AsAtPropertyError: null,
};

const AsAtPropertySlice = createSlice({
  name: 'AsAtProperty',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsAtProperties.pending, (state) => {
        state.AsAtPropertyError = null;
        state.AsAtPropertyStatus = 'pending';
      })
      .addCase(getAsAtProperties.fulfilled, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        state.AsAtPropertiesList = payload;
      })
      .addCase(getAsAtProperties.rejected, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        if (payload) {
          state.AsAtPropertyError = payload;
        }
      });

    builder
      .addCase(getAsAtPropertyTypes.pending, (state) => {
        state.AsAtPropertyError = null;
        state.AsAtPropertyStatus = 'pending';
      })
      .addCase(getAsAtPropertyTypes.fulfilled, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        state.AsAtPropertyTypesList = payload;
      })
      .addCase(getAsAtPropertyTypes.rejected, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        if (payload) {
          state.AsAtPropertyError = payload;
        }
      });

    builder
      .addCase(addAsAtProperty.pending, (state) => {
        state.AsAtPropertyStatus = 'pending';
        state.AsAtPropertyError = null;
      })
      .addCase(addAsAtProperty.fulfilled, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        state.AsAtPropertiesList = [payload, ...state.AsAtPropertiesList];
      })
      .addCase(addAsAtProperty.rejected, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        if (payload) {
          state.AsAtPropertyError = payload;
        }
      });

    builder
      .addCase(getAsAtPropertyById.pending, (state) => {
        state.AsAtPropertyStatus = 'pending';
        state.AsAtPropertyError = null;
      })
      .addCase(getAsAtPropertyById.fulfilled, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        state.AsAtProperty = payload;
      })
      .addCase(getAsAtPropertyById.rejected, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        if (payload) {
          state.AsAtPropertyError = payload;
        }
      });

    builder
      .addCase(updateAsAtProperty.pending, (state) => {
        state.AsAtPropertyStatus = 'pending';
        state.AsAtPropertyError = null;
      })
      .addCase(updateAsAtProperty.fulfilled, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        state.AsAtPropertiesList = payload.AsAtPropertiesList;
        state.AsAtProperty = payload.AsAtProperty;
      })
      .addCase(updateAsAtProperty.rejected, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        if (payload) {
          state.AsAtPropertyError = payload;
        }
      });

    builder
      .addCase(removeAsAtPropertyById.pending, (state) => {
        state.AsAtPropertyStatus = 'pending';
        state.AsAtPropertyError = null;
      })
      .addCase(removeAsAtPropertyById.fulfilled, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        state.AsAtPropertiesList = payload;
      })
      .addCase(removeAsAtPropertyById.rejected, (state, { payload }) => {
        state.AsAtPropertyStatus = 'idle';
        if (payload) {
          state.AsAtPropertyError = payload;
        }
      });
  },
});

const AsAtPropertyReducer = AsAtPropertySlice.reducer;

export { AsAtPropertyReducer };
