import type { IBasePathsChildren, TabType } from '@constants/pageInfo';

export const basePathChildren: IBasePathsChildren = {
  create: 'create',
  particular: ':id',
  edit: 'edit',
};

function createDefaultPaths<T extends string = string>(defaultPath: T) {
  return { default: defaultPath, ...basePathChildren };
}

export const paths = {
  login: {
    default: '/sign_in',
    recover: 'recover',
  },

  /* ------------------------------ Paths Profile ----------------------------- */
  profile: {
    default: '/profile',
    tabs: {
      general: {
        default: '/profile/general',
        edit: 'edit',
        changePassword: '/profile/general/change_password',
        nameTab: 'General',
        key: 'general',
        keyTranslations: 'titles.General',
      },
      notifications: {
        default: '/profile/notifications',
        nameTab: 'Notifications',
        key: 'notifications',
        keyTranslations: 'pages_plural.Notifications',
      },
    },
  },
  /* ------------------------------ Paths Create Password ----------------------------- */
  createPassword: {
    default: '/create-password/:userId',
  },
  /* ----------------------------- Paths Dashboard ---------------------------- */
  dashboard: {
    default: '/dashboard',
  },
  /* ------------------------- Paths Management Group ------------------------- */
  asset: {
    ...createDefaultPaths('/assets'),
    tabs: {
      general: {
        default: '/assets/:id',
        nameTab: 'General',
        key: 'general',
        keyTranslations: 'titles.General',
      },
      reports: {
        default: '/assets/:id/reports',
        nameTab: 'Reports',
        key: 'reports',
        keyTranslations: 'titles.Reports',
      },
      parts: {
        default: '/assets/:id/parts',
        nameTab: 'Parts',
        key: 'parts',
        keyTranslations: 'titles.Parts',
        edit: '/assets/:id/parts/edit',
      },
    },
  },
  maintenance: {
    ...createDefaultPaths('/maintenances'),
    result: '/maintenances/result/:id/:maintenanceId',
    resultDefault: '/maintenances/result',
    tabs: {
      general: {
        default: '/maintenances/:id/general',
        edit: '/maintenances/:id/general/edit',
        nameTab: 'General',
        key: 'general',
        keyTranslations: 'titles.General',
      },
      workOrders: {
        default: '/maintenances/:id/work_orders',
        edit: '/maintenances/:id/work_orders/edit',
        nameTab: 'Work orders',
        key: 'work_orders',
        keyTranslations: 'titles.Work_Orders',
      },
      parts: {
        default: '/maintenances/:id/parts',
        edit: '/maintenances/:id/parts/edit',
        nameTab: 'Parts',
        key: 'parts',
        keyTranslations: 'titles.Parts',
      },
      history: {
        default: '/maintenances/:id/history',
        nameTab: 'History',
        key: 'history',
        keyTranslations: 'titles.History',
      },
    } as Record<'general' | 'workOrders' | 'parts' | 'history', Required<Omit<TabType, 'edit'>>>,
  },
  partAndInventory: {
    ...createDefaultPaths('/part_and_inventory'),
    consume: 'consume',
    tabs: {},
  },
  partAndInventoryV2: {
    ...createDefaultPaths('/part_and_inventoryv2'),
    consume: 'consume',
    tabs: {},
  },
  /* ------------------------- Paths Properties Group ------------------------- */
  assetAttribute: {
    default: '/asset-attributes',
    tabs: {
      name: {
        ...createDefaultPaths('/asset-attributes/name'),
        nameTab: 'Name',
        key: 'asset-attributes/name',
        keyTranslations: 'pages_single.Asset_Attr_Name',
      },
      brand: {
        ...createDefaultPaths('/asset-attributes/brand'),
        nameTab: 'brand',
        key: 'asset-attributes/brand',
        keyTranslations: 'pages_single.Asset_Attr_Brand',
      },
      model: {
        ...createDefaultPaths('/asset-attributes/model'),
        nameTab: 'model',
        key: 'asset-attributes/model',
        keyTranslations: 'pages_single.Asset_Attr_Model',
      },
      category: {
        ...createDefaultPaths('/asset-attributes/category'),
        nameTab: 'category',
        key: 'asset-attributes/category',
        keyTranslations: 'pages_single.Asset_Attr_Category',
      },
      property: {
        ...createDefaultPaths('/asset-attributes/property'),
        nameTab: 'property',
        key: 'asset-attributes/property',
        keyTranslations: 'pages_single.Asset_Attr_Property',
      },
      status: {
        ...createDefaultPaths('/asset-attributes/status'),
        nameTab: 'status',
        key: 'asset-attributes/status',
        keyTranslations: 'pages_single.Asset_Attr_Status',
      },
    } as Record<'name' | 'brand' | 'model' | 'category' | 'property' | 'status', TabType>,
  },
  partAndInventoryAttributes: {
    default: '/part-and-inventory-attributes',
    tabs: {
      name: {
        ...createDefaultPaths('/part-and-inventory-attributes/name'),
        nameTab: 'name',
        key: 'part-and-inventory-attributes/name',
        keyTranslations: 'pages_single.PaI_Attr_Name',
      },
      brand: {
        ...createDefaultPaths('/part-and-inventory-attributes/brand'),
        nameTab: 'brand',
        key: 'part-and-inventory-attributes/brand',
        keyTranslations: 'pages_single.PaI_Attr_Brand',
      },
      model: {
        ...createDefaultPaths('/part-and-inventory-attributes/model'),
        nameTab: 'model',
        key: 'part-and-inventory-attributes/model',
        keyTranslations: 'pages_single.PaI_Attr_Model',
      },
      category: {
        ...createDefaultPaths('/part-and-inventory-attributes/category'),
        nameTab: 'category',
        key: 'part-and-inventory-attributes/category',
        keyTranslations: 'pages_single.PaI_Attr_Category',
      },
      property: {
        ...createDefaultPaths('/part-and-inventory-attributes/property'),
        nameTab: 'property',
        key: 'part-and-inventory-attributes/property',
        keyTranslations: 'pages_single.PaI_Attr_Property',
      },
      unit: {
        ...createDefaultPaths('/part-and-inventory-attributes/unit'),
        nameTab: 'unit',
        key: 'part-and-inventory-attributes/unit',
        keyTranslations: 'pages_single.PaI_Attr_Unit',
      },
      status: {
        ...createDefaultPaths('/part-and-inventory-attributes/status'),
        nameTab: 'status',
        key: 'part-and-inventory-attributes/status',
        keyTranslations: 'pages_single.PaI_Attr_Status',
      },
    } as Record<'name' | 'brand' | 'model' | 'category' | 'property' | 'unit' | 'status', TabType>,
  },
  maintenanceAttributes: {
    default: '/maintenance-attributes',
    tabs: {
      category: {
        ...createDefaultPaths('/maintenance-attributes/category'),
        nameTab: 'category',
        key: 'maintenance-attributes/category',
        keyTranslations: 'pages_single.Maintenance_Attr_Category',
      },
      checklist: {
        ...createDefaultPaths('/maintenance-attributes/checklist'),
        nameTab: 'checklist',
        key: 'maintenance-attributes/checklist',
        keyTranslations: 'pages_single.Maintenance_Attr_Checklist',
      },
      requestReason: {
        ...createDefaultPaths('/maintenance-attributes/request-reason'),
        nameTab: 'request reason',
        key: 'maintenance-attributes/request-reason',
        keyTranslations: 'pages_single.Maintenance_Attr_Reason',
      },
    },
  },
  vendor: createDefaultPaths('/vendor'),
  site: createDefaultPaths('/site'),
  contract: createDefaultPaths('/contract'),
  department: createDefaultPaths('/department'),
  exitType: createDefaultPaths('/exit-type'),
  costCenter: createDefaultPaths('/cost-center'),
  checkOut: createDefaultPaths('/check-out'),
  /* ---------------------------- Paths User Group ---------------------------- */
  companies: {
    ...createDefaultPaths('/company'),
    create: '/company/create',
  },
  users: createDefaultPaths('/users'),
  usergroups: createDefaultPaths('/usergroups'),
  title: createDefaultPaths('/title'),
  roles: createDefaultPaths('/roles'),
  userauth: createDefaultPaths('/userauth'),
  /* ---------------------------- Paths Management Group ---------------------------- */
  countPlan: createDefaultPaths('/countPlan'),
} as const;
