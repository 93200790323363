import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getPaIById } from '@store/actions/management/PaI';
import { selectPaI } from '@store/selectors/management/PaI';

import type { IOutletPartAndInventory } from '@constants/OutletContextEntityPages';
import type { CreatePaIDto } from '@model/management/PaI';

function PartAndInventory() {
  const PAI = useAppSelector(selectPaI);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !PAI || PAI.partsAndInventoriesId !== +decryptedId;

  useSetPageInfo({
    defaultPagePath: paths.partAndInventory.default,
    pageTitle: 'pages_single.PaI',
  });

  useEffect(() => {
    if (condition) {
      dispatch(getPaIById(decryptedId));
    }
  }, []);

  const PartAndInventoryPropertyDetalisModelList =
    PAI?.partsAndInventoriesPropertyDetails && PAI?.partsAndInventoriesPropertyDetails.length
      ? PAI.partsAndInventoriesPropertyDetails.map((propertyDetail) => ({
          value: propertyDetail.value,
          partsAndInventoriesCardPropertyId: propertyDetail.partsAndInventoriesCardPropertyId,
        }))
      : [];

  const labelsList = PAI?.labels
    ? PAI.labels.map((label) => ({
        label: label.label,
        value: label.label,
        labelColor: label.labelColor,
      }))
    : [];

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreatePaIDto = {
    costCenterId: PAI.costCenterId,
    currencyId: PAI.currencyId,
    expirationDate: PAI.expirationDate,
    isInventory: PAI.isInventory,
    isPart: PAI.isPart,
    labelsList: labelsList,
    partnerId: PAI.partnerId,
    partsAndInventoriesCardBrandId: PAI.partsAndInventoriesCardBrandId,
    partsAndInventoriesCardId: PAI.partsAndInventoriesCardId,
    partsAndInventoriesCategoryId: PAI.partsAndInventoriesCard.partsAndInventoriesCategoryId,
    partsAndInventoriesModelId: PAI.partsAndInventoriesModelId,
    partsAndInventoriesSiteId: PAI.partsAndInventoriesSiteId,
    partsAndInventoriesUnitId: PAI.partsAndInventoriesUnitId,
    purchaseNumber: PAI.purchaseNumber,
    quantity: PAI.quantity,
    serialNumber: PAI.serialNumber,
    unitPrice: PAI.unitPrice,
    partsAndInventoriesPropertyDetalisModelList: PartAndInventoryPropertyDetalisModelList,
    statusId: PAI.statusId
  };

  return <Outlet context={{ initialValues, entity: PAI } as IOutletPartAndInventory} />;
}

export default PartAndInventory;
