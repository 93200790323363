import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { useAppSelector } from '@hooks';
import { Input } from '@shared/UI';
import { selectMaAtRequestReasonStatus } from '@store/selectors/properties/MaAt/RequestReason';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateMaintenanceRequestReasonDto } from '@model/properties/MaAt/RequestReason';

const { CaseField, Item, useForm } = CommonForm;

function FormMaintenanceRequestReason(props: CommonFormProps<CreateMaintenanceRequestReasonDto>) {
  const [form] = useForm<CreateMaintenanceRequestReasonDto>();

  const { isModal, ...rest } = props;

  const {t} = useTranslation();

  const maintenanceRequestReasonStatus = useAppSelector(selectMaAtRequestReasonStatus);

  return (
    <CommonForm 
      form={form} 
      loading={maintenanceRequestReasonStatus === 'pending'} 
      isModal={isModal}
      name="formRequestReason"
      additionalFooter={
        isModal ? 
        <ModalFormFooter
          onCancel={rest.header?.onClickCancel}
        /> : null
      }
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="reasonCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')}/>
        </Item>
        <Item
          label={t('titles.Name')}
          name="reasonName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')}/>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export {FormMaintenanceRequestReason};
