import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import {
  selectCountPlansListWithFormattedDate,
  selectCountPlanStatus,
} from '@store/selectors/management/CountPlan';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { CountPlanModel } from '@model/management/CountPlan';

export const getStatusColor = (planStatus: number) => {
  switch (planStatus) {
    case 1:
      return '#0722AF';
    case 2:
      return '#C11414';
    case 3:
      return '#0C7A02';
    case 4:
      return '#626262';
    case 5:
      return '#EA7000';
  }
};

const columns: ColumnsTableType<CountPlanModel> = [
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: 'planSite',
    key: 'planSite[0]',
    ellipsis: true,
    sorter: (a, b) => compare(a.planSite[0], b.planSite[0]),
  },
  {
    title: <Translation>{(t) => t('titles.Plan_No')}</Translation>,
    dataIndex: 'prdCountPlanNo',
    key: 'prdCountPlanNo',
    sorter: (a, b) => compare(a.prdCountPlanNo, b.prdCountPlanNo),
  },
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'taskName',
    key: 'taskName',
    ellipsis: true,
    sorter: (a, b) => compare(a.taskName, b.taskName),
  },
  {
    title: <Translation>{(t) => t('titles.Start_Date')}</Translation>,
    dataIndex: 'startTime',
    key: 'startTime',
    ellipsis: true,
    sorter: (a, b) => compare(a.startTime, b.startTime),
  },
  {
    title: <Translation>{(t) => t('titles.Status')}</Translation>,
    dataIndex: ['prdCountStatus', 'name'],
    key: 'prdCountStatus.name',
    ellipsis: true,
    sorter: (a, b) => compare(a.prdCountStatus.name, b.prdCountStatus.name),
    render: (_, record) => (
      <span style={{ color: getStatusColor(record.prdCountStatusId) }}>
        {record.prdCountStatus.name}
      </span>
    ),
  },
];

const CountPlans = () => {
  const countPlans = useAppSelector(selectCountPlansListWithFormattedDate);
  const countPlanStatus = useAppSelector(selectCountPlanStatus);

  const { countPlanAccess } = useGetAccess();

  const { t } = useTranslation();

  return (
    <BasicTablePage
      table={{
        loading: countPlanStatus === 'pending',
        dataSource: countPlans,
        columns: columns,
        rowKey: (record) => record.prdCountPlanId,
        dataSourceName: t('pages_plural.Count_Plans'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Count_Plan') }),
        newButtonProps: {
          disabled: !countPlanAccess.create,
        },
      }}
    />
  );
};

export { CountPlans };
