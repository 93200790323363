import React, { memo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { DownloadOutlined } from '@ant-design/icons';
import { DataFilter } from '@components/common/DataFilter';
import { basePathChildren } from '@routes/paths';
import { Button, InputSearch, PageHeader } from '@shared/UI';
import { Dropdown, Space } from 'antd';
import cl from 'classnames';

import type { DataFilterProps } from '@components/common/DataFilter';
import type { CSVConfig } from '@interfaces/interfaces';
import type { ButtonProps, MenuProps,PageHeaderProps } from '@shared/UI';
import type { DropdownProps} from 'antd';

import './Header.scss';

export interface HeaderProps<T extends Record<string, any>> extends PageHeaderProps {
  titleRedirectButton: string;
  onlyRedirectButton?: boolean;
  showTitleHeader?: boolean;
  hiddenSearch?: boolean;
  customExtraActions?: React.ReactElement;
  filterProps?: DataFilterProps;
  csvConfig?: CSVConfig<T>;
  newButtonProps?: ButtonProps;
  dropdownProps?: DropdownProps;
}

interface BaseHeaderProps<T extends Record<string, any>> extends HeaderProps<T> {
  setSearchValue?: (value: string) => void;
}

const Header = <T extends Record<string, any>>(props: BaseHeaderProps<T>) => {
  const {
    titleRedirectButton,
    onlyRedirectButton = false,
    ghost = false,
    title,
    extra,
    showTitleHeader = true,
    customExtraActions,
    className,
    filterProps,
    csvConfig,
    hiddenSearch = false,
    setSearchValue,
    newButtonProps,
    dropdownProps,
    ...rest
  } = props;

  const { pathname } = useLocation();

  const { t } = useTranslation();

  /* ------------------------------- Handle search ------------------------------ */

  const onHandleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSearchValue?.(value);
  };

  /* ------------------------ render page header props ------------------------ */

  const defaultTitle = !hiddenSearch ? (
    <InputSearch placeholder={t('titles.Search')} onChange={onHandleSearch} bordered={false} />
  ) : null;

  const additionalHeader = title ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      {defaultTitle}
      {title}
    </div>
  ) : (
    defaultTitle
  );

  const items: MenuProps['items'] = [
    {
      icon: <DownloadOutlined />,
      className: 'table-dropdown',
      key: 0,
      label:
        <CSVLink
          data={csvConfig?.data ? csvConfig?.data : []}
          headers={csvConfig?.headers}
          filename={csvConfig?.fileName}
          onClick={csvConfig?.onClick}
        >
          <span style={{color: 'black'}}>{t('titles.Export_Excel')}</span>
        </CSVLink>
    },
  ];

  const defaultExtraActions: PageHeaderProps['extra'] = customExtraActions
    ? [customExtraActions]
    : [
        filterProps ? <DataFilter {...filterProps} /> : null,
        <Dropdown menu={{ items }} trigger={['click']} overlayClassName='table-dropdown' {...dropdownProps}>
          <Space className='table-dropdown-icon'>
            <span>...</span>
          </Space>
        </Dropdown>,
      ];

  const defaultExtra: PageHeaderProps['extra'] = [
    !onlyRedirectButton ? defaultExtraActions : null,
    <Button className="table-redirect-button" key="1" type="primary" {...newButtonProps}>
      <Link to={`${pathname}/${basePathChildren.create}`}>{titleRedirectButton}</Link>
    </Button>,
  ];

  const showTitle = showTitleHeader ? additionalHeader : null;

  return (
    <PageHeader
      {...rest}
      className={cl('reusable-table-page__header', className)}
      ghost={ghost}
      title={showTitle}
      extra={extra ? extra : defaultExtra}
    />
  );
};

const MemoizedHeader = memo(Header);

export { MemoizedHeader as Header };
