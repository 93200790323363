import React from 'react';

import { CopyIcon, EditingPen, TrashCan } from '@icon/icon-components';
import cl from 'classnames';

import { useCheckStepContext } from '../_utils';

import styles from './Actions.module.scss';

export const Actions = () => {
  const { handleActiveChange, step, actions } = useCheckStepContext();

  const classes = cl(styles.actions, {
    [styles.actionsActive]: step.isActive,
  });

  const handleCopyClick = () => {
    actions?.onCopyStep?.(step);
  };

  const handleRemoveClick = () => {
    actions?.onRemoveStep?.(step);
  };

  const canShowPenIcon = !step.isActive;

  return (
    <div className={classes}>
      {canShowPenIcon && (
        <span className={styles.icon} onClick={handleActiveChange}>
          <EditingPen />
        </span>
      )}
      <span className={styles.icon} onClick={handleCopyClick}>
        <CopyIcon />
      </span>
      <span className={styles.icon} onClick={handleRemoveClick}>
        <TrashCan />
      </span>
    </div>
  );
};
