import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useModalState } from '@hooks';
import { FilterIcon, FilterIconFilled } from '@icon/icon-components';
import { BaseForm, Button, Popover } from '@shared/UI';
import { checkFilterValues } from '@shared/utils/functions';
import { Col, Row, Space } from 'antd';
import cl from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import type { ButtonProps} from '@shared/UI';
import type { ReactNode} from 'react';

import './DataFilter.scss';

type DataFilterItem = {
  label: ReactNode;
  name: string;
  item: React.ReactElement;
  id?: string;
};

export interface DataFilterProps {
  items: DataFilterItem[];
  initialValuesForm?: any;
  isFiltered?: boolean;
  isDisabled?: boolean;
  cancelAction?: () => void;
  applyAction?: (values: any) => void;
  resetAction?: () => void;

  buttonProps?: ButtonProps;
}

const defaultClassName = 'filter-table';

export const DataFilter = (props: DataFilterProps) => {
  const {
    items,
    initialValuesForm,
    applyAction,
    cancelAction,
    resetAction,
    isFiltered = checkFilterValues(initialValuesForm),
    isDisabled,
  } = props;

  const [form] = BaseForm.useForm();

  const { t } = useTranslation();

  const disabledClassName = isDisabled ? 'filter-table-disabled' : '';

  const {
    isOpen,
    actions: { onOpen, onClose, onToggle },
  } = useModalState();

  const itemsWithID = useMemo(() => {
    return items.map((item) => ({
      ...item,
      id: uuidv4(),
    }));
  }, [items]);

  const resetFields = () => {
    for (let item of items) {
      form.setFieldsValue({ [item.name]: [] });
    }
  };

  const onCloseModal = () => {
    onClose();
    cancelAction?.();
  };

  const onToggleModal = () => {
    if (!checkFilterValues(initialValuesForm)) {
      resetFields();
    }

    onToggle();
  };

  const onReset = () => {
    resetFields();

    resetAction?.();

    onClose();
  };

  const handleSubmit = () => {
    form.submit();
    onClose();
  };

  const content = (
    <>
      <div className={`${defaultClassName}__form-header`}>
        <h6>{t('titles.Filter')}</h6>

        <span onClick={onReset}>{t('titles.Clear_All_Filters')}</span>
      </div>
      <div className={`${defaultClassName}__form-body`}>
        <BaseForm
          container={{
            style: { width: '100%', maxWidth: '100%' },
          }}
          form={form}
          onFinish={applyAction}
          initialValues={initialValuesForm}
        >
          <Row>
            {itemsWithID.map((item) => {
              return (
                <Col span={24} key={item.id}>
                  <BaseForm.Item label={item.label} name={item.name}>
                    {item.item}
                  </BaseForm.Item>
                </Col>
              );
            })}
          </Row>
        </BaseForm>
      </div>

      <div className={`${defaultClassName}__form-footer`}>
        <Space size={68}>
          <Button onClick={onCloseModal}>{t('titles.Cancel')}</Button>
          <Button onClick={handleSubmit} type="primary">
            {t('titles.Submit')}
          </Button>
        </Space>
      </div>
    </>
  );

  return (
    <div className="filter-table-wrapper">
      {isFiltered ? (
        <button className="filter-reset-btn" onClick={onReset}>
          Reset
        </button>
      ) : null}
      <Popover
        open={isOpen}
        onOpenChange={onToggleModal}
        content={content}
        trigger="click"
        disabledArrow
        className={defaultClassName}
      >
        <Button
          className={cl(`${defaultClassName}__button`, `${disabledClassName}__button`)}
          type="text"
          shape="circle"
          icon={isFiltered ? <FilterIconFilled /> : <FilterIcon />}
          onClick={onOpen}
          disabled={isDisabled}
        ></Button>
      </Popover>
    </div>
  );
};
