import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { Input } from '@shared/UI';
import { selectSiteStatus } from '@store/selectors/properties/Site';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateTitleDto } from '@model/users/Title';

const { CaseField, Item, useForm } = CommonForm;

function FormTitle(props: CommonFormProps<CreateTitleDto>) {
  const [form] = useForm<CreateTitleDto>();

  const {t} = useTranslation();

  const statusTitle = useAppSelector(selectSiteStatus);

  return (
    <CommonForm form={form} loading={statusTitle === 'pending'} {...props}>
      <CaseField>
        <Item
          label={t('titles.Code')}
          name="userTitleCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')}/>
        </Item>
        <Item
          label={t('titles.Title')}
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Title')}/>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export {FormTitle};
