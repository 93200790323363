import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Input, Select } from '@shared/UI';
import { getAsAtBrands } from '@store/actions/properties/AsAt/Brand';
import {
  selectAsAtBrandsList,
  selectAsAtBrandStatus,
} from '@store/selectors/properties/AsAt/Brand';
import { selectAsAtModelStatus } from '@store/selectors/properties/AsAt/Model';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateAsAtModelDto } from '@model/properties/AsAt/Model';

const { CaseField, Item, useForm } = CommonForm;

function FormAssetModel(props: CommonFormProps<CreateAsAtModelDto>) {
  const [form] = useForm<CreateAsAtModelDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const assetModelStatus = useAppSelector(selectAsAtModelStatus);
  const assetBrandStatus = useAppSelector(selectAsAtBrandStatus);
  const assetBrandList = useAppSelector(selectAsAtBrandsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!assetBrandList.length) {
      dispatch(getAsAtBrands());
    }
  }, []);

  const brandOptions = useMemo(
    () =>
      assetBrandList.map((brand) => ({
        value: brand.nonCurrAssetCardBrandId,
        label: brand.name,
      })),
    [assetBrandList]
  );

  return (
    <CommonForm 
      form={form} 
      loading={assetModelStatus === 'pending'} 
      isModal={isModal}
      name='formAssetModel'
      additionalFooter={
        isModal ?
        <ModalFormFooter 
          onCancel={rest.header?.onClickCancel} /> : null
      }
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="nonCurrAssetCardModelCode"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item
          label={t('titles.Brand')}
          name="nonCurrAssetCardBrandId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={brandOptions}
            placeholder={t('titles.Choose_Name', { name: t('titles.Brand') })}
            loading={assetBrandStatus === 'pending'}
          />
        </Item>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormAssetModel };
