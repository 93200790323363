import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { TextArea } from '@shared/UI';

import type { ItemProps } from '@shared/UI';

interface TextAreaDescriptionProps extends ItemProps {
  placeholder?: string;
}

const { Item } = CommonForm;

const TextAreaDescription = (props: TextAreaDescriptionProps) => {
  const { label, name, rules: rulesProps, placeholder } = props;

  const { t } = useTranslation();

  const rules = useMemo(() => rulesProps, [rulesProps]);

  return (
    <Item rules={rules} label={label || t('titles.Description')} name={name || 'description'}>
      <TextArea placeholder={placeholder || t('titles.Enter_Descr')} />
    </Item>
  );
};

export { TextAreaDescription };
