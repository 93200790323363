import {createAsyncThunk} from '@reduxjs/toolkit';
import {RoleRepository} from '@repositories';

import type {
  AuthorizationFunctionModel,
  AuthorizationModel
} from '@model/extra/Authorization';
import type {ApiError} from '@services/Axios';

// region --- Get Actions

export const getAuthorizations = createAsyncThunk<
  AuthorizationModel[],
  void,
  { rejectValue: string }
>('Authorization/getAuthorizations', async (_, { rejectWithValue }) => {
  try {
    const response = await RoleRepository.fetchFunctions();
    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getAuthorizationNames = createAsyncThunk<
  AuthorizationFunctionModel[],
  void,
  { rejectValue: string }
>('Authorization/getAuthorizationNames', async (_, { rejectWithValue }) => {
  try {
    const response = await RoleRepository.fetchFunctions();
    const functionList: any[] = [];

    response.resultObject.forEach((item) =>
      item.childrens.forEach((i) =>
        functionList.push({
          roleFunctionNameId: i.roleFunctionNameId,
          roleFunctionValue: false,
        })
      )
    );
    return functionList;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions
