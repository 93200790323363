import React from 'react';

import { CheckStepItem, CommonForm, useCheckStep } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectProfile } from '@store/selectors/profile/Profile';

import type { CommonPreviewProps, DefaultCommonFormProps } from '@components/common';
import type { MaintenanceV2Model } from '@model/management/Maintenance';
import type { ProfileModel } from '@model/profile/Profile';

const PreviewFormMaintenanceWorkOrders = (props: CommonPreviewProps<MaintenanceV2Model>) => {
  const { entity } = props;

  const profile = useAppSelector(selectProfile) as ProfileModel;

  const header: DefaultCommonFormProps['header'] = {
    title: entity.title,
    hiddenDeleteButton: true,
    hiddenEditButton: !entity.buttonPermit,
  };

  const container: DefaultCommonFormProps['container'] = {
    style: {
      maxWidth: '100%',
    },
  };

  const { steps } = useCheckStep({
    maintenanceId: entity.maintenanceId,
    type: 'maintenanceWorkStep',
    initialSteps: entity.maintenancesWorkSteps,
    userId: profile?.userId,
  });

  const renderSteps = steps.map((step) => {
    return (
      <CheckStepItem
        key={step.uuid}
        stepKey={step.stepKey}
        step={step}
        isVisibleActions={false}
        canActivate={false}
        isDraggable={false}
      />
    );
  });

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      header={header}
      alignAdditionalHeader="left"
      container={container}
    >
      {renderSteps}
      {/*<DndProvider backend={HTML5Backend}>*/}
      {/*  {workOrders &&*/}
      {/*    workOrders.map((workOrder, idx) => {*/}
      {/*      return (*/}
      {/*        <DraggableLining*/}
      {/*          key={workOrder.entityId}*/}
      {/*          index={idx}*/}
      {/*          count={`${idx + 1}.`}*/}
      {/*          hiddenEditAction*/}
      {/*          hiddenDeleteAction*/}
      {/*          hiddenCopyAction*/}
      {/*          isDraggable={false}*/}
      {/*        >*/}
      {/*          {workOrder.type === KindOfCheckStep.MultiplyChoice ? (*/}
      {/*            <MultiplyCheckStep multiplyCheckStep={workOrder} />*/}
      {/*          ) : null}*/}
      {/*          {workOrder.type !== KindOfCheckStep.MultiplyChoice &&*/}
      {/*          workOrder.type !== KindOfCheckStep.Photo ? (*/}
      {/*            <OtherCheckStep otherCheckStep={workOrder} />*/}
      {/*          ) : null}*/}
      {/*        </DraggableLining>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*</DndProvider>*/}
    </CommonForm>
  );
};

export { PreviewFormMaintenanceWorkOrders };
