import { useContext } from 'react';

import { StepsContext } from './StepsProvider';

const useStepsContext = () => {
  const ctx = useContext(StepsContext);
  if (ctx === undefined || ctx === null) {
    throw new Error('useStepsContext must be inside a Provider with a value');
  }
  return ctx;
};

export { useStepsContext };
