import { api } from '@services/Axios';

import type { AuthorizationModel } from '@model/extra/Authorization';
import type { CreateRoleDto, RoleModel, UpdateRoleDto } from '@model/users/Role';
import type { ApiResponse } from '@services/Axios';

const RoEndpoint = '/Role';

export const RoleRepository = {
  async fetchAll() {
    const response: ApiResponse<RoleModel[]> = await api.get(`${RoEndpoint}/GetRoleList`);
    return response.data;
  },
  async fetchById(id: string | number) {
    const response: ApiResponse<RoleModel> = await api.get(
      `${RoEndpoint}/GetRoleAuthorizationListByRoleId/${id}`
    );
    return response.data;
  },
  async fetchFunctions() {
    const response: ApiResponse<AuthorizationModel[]> = await api.get(
      `${RoEndpoint}/GetRoleFunctionNamesList`
    );
    return response.data;
  },
  async add(dto: CreateRoleDto) {
    const response: ApiResponse<RoleModel> = await api.post(`${RoEndpoint}/AddRole`, dto);
    return response.data;
  },
  async update(dto: UpdateRoleDto) {
    const response: ApiResponse<RoleModel> = await api.post(`${RoEndpoint}/EditRole`, dto);
    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${RoEndpoint}/RemoveByIdList`, {
      roleIds: ids,
    });
    return response.data;
  },
};
