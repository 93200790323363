import * as React from 'react';

import type { SVGProps } from 'react';

const IntegerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.625 16.875v-.75h2.25v-8.25h-2.25v-.75h3v9h2.25v.75h-5.25Z"
      fill={props.fill || '#E0E0E0'}
    />
    <path
      d="M17.25 7.5v9-9Zm.75-.75h-3.75v1.5h2.25v7.5h-2.25v1.5h6v-1.5H18v-9Zm-14.25 9h7.5v1.5h-7.5v-1.5Zm7.5-6h-3v-3h-1.5v3h-3v1.5h3v3h1.5v-3h3v-1.5Z"
      fill={props.fill || '#E0E0E0'}
    />
  </svg>
);

export { IntegerIcon };
