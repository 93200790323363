import { v4 as uuidv4 } from 'uuid';

import type { CheckStepItemOption } from '@components/common/CheckStepItem/_utils';
import type { CheckStepMultiplyListModel } from '@model/properties/MaAt/Checklist';

export const mapChecklistOptionsToCheckStepOptions = (options: CheckStepMultiplyListModel[]) => {
  return options.map((option): CheckStepItemOption => {
    return {
      title: option.optionText,
      rowId: option.rowId,
      checked: false,
      hasError: false,
      isUpdated: false,
      isNew: false,
      isRemoved: false,
      externalId: option.multipleChoiceListId,
      uuid: uuidv4(),
      value: option.optionText,
    };
  });
};
