import type { RootState } from '@store/rootReducer';

export const selectAuthorizationState = (state: RootState) => state.AuthorizationReducer;

export const selectAuthorizationList = (state: RootState) =>
  selectAuthorizationState(state).listAuthorization;
export const selectAuthorizationFunctionList = (state: RootState) =>
  selectAuthorizationState(state).functionListAuthorization;

export const selectAuthorizationStatus = (state: RootState) =>
  selectAuthorizationState(state).statusAuthorization;

export const selectAuthorizationError = (state: RootState) =>
  selectAuthorizationState(state).errorAuthorization;
111;
