import type { RootState } from '@store/rootReducer';

export const selectCheckOutState = (state: RootState) => state.CheckOutReducer;

export const selectCheckOutsList = (state: RootState) => selectCheckOutState(state).checkOutsList;

export const selectCheckOut = (state: RootState) => selectCheckOutState(state).checkOut;

export const selectCheckOutStatus = (state: RootState) => selectCheckOutState(state).checkOutStatus;

export const selectCheckOutError = (state: RootState) => selectCheckOutState(state).checkOutError;
