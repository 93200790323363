import React, {useEffect} from 'react';
import {Outlet} from 'react-router-dom';

import {useAppDispatch, useAppSelector, useSetPageInfo} from '@hooks';
import {paths} from '@routes/paths';
import LocaleStorage from '@services/LocaleStorage';
import {clearCheckedAssets, getAssets} from '@store/actions/management/Asset';
import {selectAssetsList} from '@store/selectors/management/Asset';

function EntryAsset() {
  useSetPageInfo({
    defaultPagePath: paths.asset.default,
    pageTitle: 'pages_single.Asset'
  });

  const listAsset = useAppSelector(selectAssetsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const view = LocaleStorage.handleListView();

    if (!listAsset.length) {
      dispatch(
        getAssets({
          perPage: view === 'list' ? 10 : 12,
        })
      );
    }

    return () => {
      dispatch(clearCheckedAssets())
    }
  }, []);

  return <Outlet/>;
}

export default EntryAsset;
