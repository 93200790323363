/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {useTranslation} from 'react-i18next';

import {CheckStepItem} from '@components/common';
import {ApiNotifications} from '@services/Notifications/adapters';

import type {CheckStepItemType} from '@components/common';
import type {CheckStepActions} from '@components/common/CheckStepItem/_utils';
import type {
  CreateMaintenanceResultDto,
  MaintenanceResultVer2Model,
  UpdateMaintenanceResultDto,
} from '@model/management/Maintenance';

type WorkStepsProps = {
  executeStatusId: number;

  initialWorkSteps: MaintenanceResultVer2Model[];

  onSave: (result: CreateMaintenanceResultDto) => void;

  onUpdate: (result: UpdateMaintenanceResultDto) => void;

  chestStepsToMaintenanceResult: (
    steps: CheckStepItemType
  ) => CreateMaintenanceResultDto | undefined;

  steps: CheckStepItemType[];

  actions: CheckStepActions;

  validateWorkStepResult: (step: CheckStepItemType) => boolean;
};

export const WorkSteps = (props: WorkStepsProps) => {
  const {
    initialWorkSteps,
    executeStatusId,
    onSave,
    onUpdate,
    chestStepsToMaintenanceResult,
    steps,
    actions,
    validateWorkStepResult,
  } = props;

  const { t } = useTranslation();

  const handleSave = (step: CheckStepItemType) => {
    const isValid = validateWorkStepResult(step);

    if (!isValid) {
      return;
    }

    const result = chestStepsToMaintenanceResult(step);

    if (result) {
      onSave(result);
    }
  };
  const handleUpdate = (step: CheckStepItemType) => {
    if (executeStatusId === 3) {
      return ApiNotifications.info(t('titles.Completed_Maintenance_Status_Cannot_Be_Updated!'));
    }
    const isValid = validateWorkStepResult(step);

    if (!isValid) {
      return;
    }
    const result = chestStepsToMaintenanceResult(step);
    const maintenanceWorkStep = initialWorkSteps.find((s) => s.workStepsId === result?.workStepId);
    const resultId = maintenanceWorkStep?.workSteps.maintenancesResults[0]
      ? maintenanceWorkStep.workSteps.maintenancesResults[0].maintenancesResultsId
      : null;
    if (result && resultId) {
      onUpdate({
        maintenancesResultsId: resultId,
        resultText: result.resultText,
      });
    }
  };

  const renderSteps = steps.map((step) => {
    const currentResultStep = initialWorkSteps.find(
      (workStep) => workStep.workStepsId === step.externalId
    );
    const isDoneWorkStep = currentResultStep
      ? currentResultStep.workSteps.maintenancesResults.length > 0
      : false;
    return (
      <CheckStepItem
        key={step.uuid}
        stepKey={step.stepKey}
        step={step}
        type="workStep"
        actions={actions}
        isDraggable={false}
        isActiveWorkStep={executeStatusId === 2}
        isDoneWorkStep={isDoneWorkStep}
        onSave={handleSave}
        onUpdate={handleUpdate}
      />
    );
  });

  return <>{renderSteps}</>;
};
