import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, DatesBlock, SelectLabels } from '@components/common';
import { Col, Row, Select } from '@shared/UI';

import type { CurrencyModel } from '@model/extra/Currency';
import type { ItemProps } from '@shared/UI';
import type { SelectFieldNames } from '@shared/utils/types';
import type { Moment } from 'moment';

interface ExpandedRightSectionProps {
  currenciesList: Array<CurrencyModel>;

  onOpenLabelModal: () => void;
}

// region --- Field names

const currencyNames: SelectFieldNames<CurrencyModel> = {
  label: 'name',
  value: 'currencyId',
};

// endregion --- Field names

const { CaseField, Item, useWatch, useFormInstance } = CommonForm;
export const ExpandedRightSection = memo((props: ExpandedRightSectionProps) => {
  const { currenciesList, onOpenLabelModal } = props;

  const { t } = useTranslation();

  const form = useFormInstance();

  // region --- observed values

  const warrantyStartDate = useWatch('warrantyStartDate', form) as Moment | null;

  const warrantyEndDate = useWatch('warrantyEndDate', form) as Moment | null;
  // endregion --- observed values

  const startDateItemProps: ItemProps = useMemo(
    () => ({
      label: t('titles.Warranty_Start'),
      name: 'warrantyStartDate',
    }),
    [t]
  );

  const endDateItemProps: ItemProps = useMemo(
    () => ({
      label: t('titles.Warranty_End'),
      name: 'warrantyEndDate',
    }),
    [t]
  );

  return (
    <CaseField hiddenTitle md={24} lg={7}>
      <DatesBlock
        startDate={warrantyStartDate}
        endDate={warrantyEndDate}
        startDateItemProps={startDateItemProps}
        endDateItemProps={endDateItemProps}
      />

      <Row>
        <Col md={10} xs={24}>
          <Item label={t('titles.Currency')} name="currencyId">
            <Select placeholder="USD" options={currenciesList} fieldNames={currencyNames} />
          </Item>
        </Col>
      </Row>

      <SelectLabels enumLabelPlace={1} isCreatable creatableAction={onOpenLabelModal} />
    </CaseField>
  );
});
