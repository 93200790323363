import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter, ModalFormHeader } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Divider, Input, Select, TextArea } from '@shared/UI';
import { getCities, getCountries } from '@store/actions/extra/Definition';
import { getSites } from '@store/actions/properties/Site';
import { selectDefinitionState } from '@store/selectors/extra/Definition';
import { selectSitesListWithoutChildren, selectSiteStatus } from '@store/selectors/properties/Site';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateSiteDto } from '@model/properties/Site';

const { CaseField, Item, useForm } = CommonForm;

const FormSite = (props: CommonFormProps<CreateSiteDto>) => {
  const [form] = useForm<CreateSiteDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const siteStatus = useAppSelector(selectSiteStatus);
  const siteListWithoutChildren = useAppSelector(selectSitesListWithoutChildren);
  const { countriesList, citiesList, definitionStatus } = useAppSelector(selectDefinitionState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!countriesList.length) {
      dispatch(getCountries());
    }
    if (!citiesList.length) {
      dispatch(getCities());
    }
    if (!siteListWithoutChildren.length) {
      dispatch(getSites());
    }
  }, []);

  const optionsParentSite = useMemo(() => {
    return siteListWithoutChildren
      .map((site) => ({
        label: site.name,
        value: site.siteId,
      }))
      .filter((site) => {
        if (rest.initialValues) {
          return site.label !== rest.initialValues.name;
        }
        return site;
      });
  }, [siteListWithoutChildren]);

  const optionsCountry = useMemo(
    () =>
      countriesList.map((country) => ({
        label: country.name,
        value: country.countryId,
        cities: country.cities,
      })),
    [countriesList]
  );
  const optionsCity = useMemo(
    () =>
      citiesList.map((city) => ({
        label: city.name,
        value: city.cityId,
        countryId: city.countryId,
      })),
    [citiesList]
  );

  const onChangeCountryID = (
    value: number,
    option: typeof optionsCountry | typeof optionsCountry[number]
  ) => {
    form.setFieldsValue({
      countryId: value,
      cityId: (option as typeof optionsCountry[number])?.cities[0]?.cityId,
    });
    form.validateFields(['cityId', 'countryId']);
  };

  const [ currentStep, setCurrentStep ] = useState(1);

  function onContinue() {
    form.validateFields(['name', 'siteCode', 'barcode'])
      .then(() => {
        setCurrentStep(2);
      })
      .catch(() => {})
  }

  function onSave() {
    form.submit()
  }

  function onBack() {
    setCurrentStep(1)
  }

  return (
    <CommonForm 
      form={form} 
      loading={siteStatus === 'pending'} 
      isModal={isModal}
      name='formSite'
      hiddenHeader={isModal}
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onContinue={onContinue}
          onBack={onBack}
          onSave={onSave}
          onCancel={rest.header?.onClickCancel}
          currentStep={currentStep}
        /> : null
      }
      additionalHeader={
        isModal ?
        <ModalFormHeader
          step={currentStep}
          title={rest.header?.title as string} />
        : null
      }
      {...rest}
    >
      <CaseField title={t('titles.Summary')} isModal={isModal} hidden={currentStep === 2 && isModal}>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item
          label={t('titles.Code')}
          name="siteCode"
          rules={[
            {
              required: true,
            },
            {
              min: 1,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
      </CaseField>
      <CaseField isModal={isModal} hidden={currentStep === 2 && isModal}>
        <Item
          label={t('titles.Barcode')}
          name="barcode"
          rules={[
            {
              required: true,
            },
            {
              min: 1,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t('titles.Barcode')} />
        </Item>
        <Item label={t('titles.Parent_Site')} name="parentSiteId">
          <Select
            loading={siteStatus === 'pending'}
            placeholder={t('titles.Choose_Name', { name: t('titles.Parent_Site') })}
            options={optionsParentSite}
          />
        </Item>
      </CaseField>
      <Divider style={isModal ? {display: 'none'} : {}} />
      <CaseField title={t('titles.Location')} isModal={isModal} hidden={currentStep === 1 && isModal}>
        <Item
          label={t('titles.Country')}
          name="countryId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={optionsCountry}
            loading={definitionStatus === 'pending'}
            placeholder={t('titles.Choose_Name', { name: t('titles.Country') })}
            onChange={onChangeCountryID}
          />
        </Item>
        <Item
          shouldUpdate={(prevValue: CreateSiteDto, nextValue: CreateSiteDto) =>
            prevValue.cityId !== nextValue.cityId
          }
          noStyle
        >
          {({ getFieldValue }) => {
            const selectedCountry = getFieldValue('countryId');
            let cities = optionsCity;

            if (selectedCountry) {
              cities = cities.filter((city) => {
                return city.countryId === selectedCountry;
              });
            }

            return (
              <Item
                label={t('titles.City')}
                name="cityId"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={cities}
                  loading={definitionStatus === 'pending'}
                  placeholder={t('titles.Choose_Name', { name: t('titles.City') })}
                />
              </Item>
            );
          }}
        </Item>
        <Item label={t('titles.Town')} name={'town'} rules={[ { max: 50 } ]}>
          <Input placeholder={t('titles.Town')} />
        </Item>
        <Item label={t('titles.Area')} name={'area'} rules={[ { max: 50 } ]}>
          <Input placeholder={t('titles.Area')} />
        </Item>
      </CaseField>
      <CaseField isModal={isModal} hidden={currentStep === 1 && isModal}>
        <Item label={t('titles.Address')} name={'address'} rules={[ { max: 200 } ]}>
          <Input placeholder={t('titles.Address')} />
        </Item>
        <Item label={t('titles.Description')} name={'description'} hidden={isModal}>
          <TextArea placeholder={t('titles.Enter_Descr')} />
        </Item>
      </CaseField>
    </CommonForm>
  );
};

export { FormSite };
