import React from 'react';

import { PieAsset } from './PieAsset';
import { PiePAI } from './PiePAI';

import type { DashboardModel } from '@model/dashboard';

import './PieCharts.scss';

interface PieChartsProps {
  data: DashboardModel | null
}

const PieCharts = (props: PieChartsProps) => {
  const { data } = props;
  
  return (
    <div className="pie-charts">
      <PieAsset data={data?.returnTop3StatusTotals} />
      <PiePAI data={data?.returnPAıQuantityRemainingTotals} />
    </div>
  );
};

export { PieCharts };
