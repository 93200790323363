import React from 'react';

import { PlusSquare } from '@icon/icon-components';
import cl from 'classnames';

import type { HTMLAttributes } from 'react';

import './SquarePlusButton.scss';

export type SquarePlusButtonProps = HTMLAttributes<HTMLButtonElement> & {};

export const SquarePlusButton = (props: SquarePlusButtonProps) => {
  const { className, ...restProps } = props;

  const classes = cl('square-plus-button', className);

  return (
    <button type="button" className={classes} {...restProps}>
      <PlusSquare />
    </button>
  );
};
