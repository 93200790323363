import React from 'react';

import { useHandleParamId } from '@hooks';
import { Card, Checkbox, List } from '@shared/UI';
import cl from 'classnames';

import type { CardProps } from '@shared/UI';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import './Cell.scss';

const { Item } = List;

interface CellProps extends CardProps {
  headerElement?: React.ReactElement;

  itemKey: string | number;

  checked?: boolean;

  onChangeCheckbox?: (value: boolean) => void;
}

const Cell = (props: CellProps) => {
  const {
    children,
    className,
    headerElement,
    itemKey,
    checked,
    onChangeCheckbox,
    ...rest
  } = props;

  const {redirect} = useHandleParamId();

  const onClick = () => {
    redirect(itemKey);
  };

  const handleChangeCheckbox = (event: CheckboxChangeEvent) => {
    const {checked} = event.target;

    onChangeCheckbox?.(checked);
  };

  return (
    <Item className="item-list-cell">
      <Card {...rest} className={cl('cell-card', className)}>
        <div className="cell-header">
          <Checkbox
            checked={checked}
            onChange={handleChangeCheckbox}
            className="cell-checkbox"
          />
          <span>{headerElement}</span>
        </div>
        {React.Children.map(children, (child) => {
          const item = child as React.ReactElement;

          return React.cloneElement(item, {
            onClick,
            style: {cursor: 'pointer'},
          });
        })}
      </Card>
    </Item>
  );
};

export {Cell};
