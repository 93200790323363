import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter, ModalFormHeader } from '@components/common';
import { useAppDispatch, useAppSelector, useModalState } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { CreateableEntityModal, Divider } from '@shared/UI';
import { checkStoreStatuses } from '@shared/utils/functions';
import { getCurrencies } from '@store/actions/extra/Currency';
import { addVendor, getVendors } from '@store/actions/properties/Vendor';
import { selectCurrenciesList, selectCurrencyStatus } from '@store/selectors/extra/Currency';
import { selectContractStatus } from '@store/selectors/properties/Contract';
import { selectVendorsList, selectVendorStatus } from '@store/selectors/properties/Vendor';
import moment from 'moment';

import { FormVendor } from '../Vendor';
import { FileBlock } from './FileBlock';
import { LeftSection } from './LeftSection';
import { RightSection } from './RightSection';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { FileWithID } from '@interfaces/interfaces';
import type { CreateContractDto } from '@model/properties/Contract';
import type { CreateVendorDto } from '@model/properties/Vendor';
import type { FormInstance } from 'antd';
import type { FormFinishInfo, FormProviderProps } from 'rc-field-form/lib/FormContext';

const { useForm, Provider } = CommonForm;

type FormsFinish = {
  formVendor: FormInstance<CreateVendorDto>;
};

function FormContract(props: CommonFormProps<CreateContractDto & { files?: FileWithID[] }>) {
  const [form] = useForm<CreateContractDto & { files?: FileWithID[] }>();

  const { initialValues, isModal, ...rest } = props;

  const { t } = useTranslation();

  const vendorModal = useModalState();

  // region --- State selectors
  const currencyList = useAppSelector(selectCurrenciesList);
  const vendorList = useAppSelector(selectVendorsList);

  const currencyStatus = useAppSelector(selectCurrencyStatus);
  const contractStatus = useAppSelector(selectContractStatus);
  const vendorStatus = useAppSelector(selectVendorStatus);
  // endregion --- State selectors

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!currencyList.length) {
      dispatch(getCurrencies());
    }
    if (!vendorList.length) {
      dispatch(getVendors());
    }
  }, []);

  const isLoading = checkStoreStatuses([currencyStatus, vendorStatus, contractStatus]);

  const initialValue: Partial<CreateContractDto & { files?: FileWithID[] }> = useMemo(
    () => ({
      ...initialValues,
      startDate: initialValues?.startDate ? (moment(initialValues.startDate) as any) : null,
      endDate: initialValues?.endDate ? (moment(initialValues.endDate) as any) : null,
      currencyId: initialValues?.currencyId ? initialValues.currencyId : 1,
    }),
    [initialValues]
  );

  const [ currentStep, setCurrentStep ] = useState(1);

  function onContinue() {
    form.validateFields(['name', 'contractCode', 'no', 'price'])
      .then(() => {
        setCurrentStep(2);
      })
      .catch(() => {})
  }

  function onSave() {
    form.submit()
  }

  function onBack() {
    setCurrentStep(1)
  }

  const onFinishVendor = (vendorForm: FormInstance<CreateVendorDto>) => {
    const dto = vendorForm.getFieldsValue();

    dispatch(addVendor(dto))
      .unwrap()
      .then(({ partnerId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Vendor'),
          })
        });

        vendorForm.resetFields();
        form.setFieldValue('partnerId', partnerId);
        vendorModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFormsFinish = (
    name: string,
    { forms }: Omit<FormFinishInfo, 'forms'> & { forms: FormsFinish }
  ) => {
    switch (name) {
      case 'formVendor': {
        onFinishVendor(forms.formVendor);
        break
      }
    }
  };

  const correctInitialValue = {
    ...initialValue,
    price: +(`${initialValue.price}`).split(".")[0] || undefined,
    cent: +(`${initialValue.price}`).split(".")[1] || undefined,
  }

  return (
    <Provider onFormFinish={onFormsFinish as FormProviderProps['onFormFinish']}>
    <CommonForm 
      form={form} 
      loading={isLoading} 
      isModal={isModal}
      initialValues={correctInitialValue} 
      name='formContract'
      hiddenHeader={isModal}
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onContinue={onContinue}
          onBack={onBack}
          onSave={onSave}
          onCancel={rest.header?.onClickCancel}
          currentStep={currentStep}
        /> : null
      }
      additionalHeader={
        isModal ?
        <ModalFormHeader
          step={currentStep}
          title={rest.header?.title as string} />
        : null
      }
      {...rest}
    >
      <LeftSection 
        currencyList={currencyList} 
        isModal={isModal}
        hidden={currentStep === 2 && isModal} 
      />
      <RightSection 
        vendorList={vendorList} 
        isModal={isModal}
        hidden={currentStep === 1 && isModal}
        creatableVendorAction={vendorModal.actions.onOpen}
      />
      <Divider style={isModal ? {display: 'none'} : {}} />
      <FileBlock 
        hidden={currentStep === 1 && isModal} 
        isModal={isModal}
      />

      <CreateableEntityModal
        open={vendorModal.isOpen}
        onCancel={vendorModal.actions.onClose}
        form={
          <FormVendor
            isModal
            header={{
              onClickCancel: vendorModal.actions.onClose,
              title: t('titles.New_Item', { name: t('pages_single.Vendor') }),
            }}
          />
        }
      />
    </CommonForm>
    </Provider>
  );
}

export { FormContract };
