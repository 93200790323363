import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getMaAtRequestReasonById } from '@store/actions/properties/MaAt/RequestReason';
import { selectMaAtRequestReason } from '@store/selectors/properties/MaAt/RequestReason';

import type { IOutletMaintenanceRequestReason } from '@constants/OutletContextEntityPages';
import type { CreateMaintenanceRequestReasonDto } from '@model/properties/MaAt/RequestReason';

function MaintenanceRequestReason() {
  const maintenanceRequestReason = useAppSelector(selectMaAtRequestReason);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition =
    !maintenanceRequestReason || maintenanceRequestReason.requestReasonId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getMaAtRequestReasonById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateMaintenanceRequestReasonDto = {
    reasonCode: maintenanceRequestReason.reasonCode,
    reasonName: maintenanceRequestReason.reasonName,
  };

  return (
    <Outlet
      context={
        { initialValues, entity: maintenanceRequestReason } as IOutletMaintenanceRequestReason
      }
    />
  );
}

export default MaintenanceRequestReason;
