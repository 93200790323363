import React from 'react';

import { DatePicker } from 'antd';
import cl from 'classnames';

import 'antd/es/date-picker/style/index';

import type { PickerTimeProps } from 'antd/lib/date-picker/generatePicker';
import type { Moment } from 'moment';

import './TimeMomentPicker.scss';

export interface TimeMomentPickerProps extends Omit<PickerTimeProps<Moment>, 'picker'> {
  textCenter?: boolean;
}

export const TimeMomentPicker = React.forwardRef<any, TimeMomentPickerProps>(
  (props: TimeMomentPickerProps, ref) => {
    const { className, textCenter, ...restProps } = props;

    const defaultClasses = cl('custom-time-picker', className, {
      'custom-time-picker--text-center': textCenter,
    });

    return (
      <DatePicker
        picker="time"
        className={defaultClasses}
        minuteStep={10}
        mode={undefined}
        ref={ref}
        format="HH:mm"
        suffixIcon={null}
        showNow={false}
        {...restProps}
      />
    );
  }
);
