import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { statusColor } from '@constants/statusColors';
import { useAppSelector } from '@hooks';
import { ColorPicker } from '@shared/UI';
import { selectPaIStatusStatus } from '@store/selectors/properties/PaI/Status';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { PaIStatusModel } from '@model/properties/PaI/Status';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormPartAndInventoryStatus(props: CommonPreviewProps<PaIStatusModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusPartAndInventoryStatus = useAppSelector(selectPaIStatusStatus);

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={statusPartAndInventoryStatus === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.partsAndInventoriesStatusCode}</PreviewField>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Color')}>
          <ColorPicker colors={statusColor} value={entity.color} readonly />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormPartAndInventoryStatus };
