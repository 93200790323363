import * as React from 'react';
import { memo } from 'react';

import type { SVGProps } from 'react';

export const CheckCheckboxIcon = memo((props: SVGProps<SVGSVGElement>) => {
  const { stroke = '#fff', ...restProps } = props;
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <mask
        id="a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path d="M0 0h24v24H0V0Z" fill="#fff" />
      </mask>
      <g
        mask="url(#a)"
        stroke={stroke}
        strokeWidth={1.293}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.137 10.412v7.535a1.19 1.19 0 0 1-1.19 1.19H6.05a1.19 1.19 0 0 1-1.19-1.19V6.05a1.19 1.19 0 0 1 1.19-1.19h8.329" />
        <path d="m8.828 10.414 3.966 3.172 5.95-8.328" />
      </g>
    </svg>
  );
});
