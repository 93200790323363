import * as React from 'react';

import type { SVGProps } from 'react';

const TwoTicks = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={11} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.618 1.551 12.527.5 7.618 5.228 8.71 6.279l4.908-4.728ZM16.901.5 8.71 8.39 5.474 5.28 4.382 6.331 8.71 10.5 18 1.551 16.9.5ZM0 6.331 4.328 10.5l1.091-1.051L1.1 5.28 0 6.331Z"
      fill="#BDBDBD"
    />
  </svg>
);

export { TwoTicks };
