import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector, useDebounce } from '@hooks';
import { InputSearch, Label, Select } from '@shared/UI';
import { getLabelsByPoOrName } from '@store/actions/extra/Label';
import { getPaIs } from '@store/actions/management/PaI';
import { selectLabelsList } from '@store/selectors/extra/Label';
import { selectPaIFilterOptions } from '@store/selectors/management/PaI';

import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

import './PAIExtraTableHeader.scss';

interface PAIExtraTableHeaderProps {
  paIRecordNotFound: boolean;
  isSearch: boolean;
  isLabel: boolean;
  isEmptyLists: boolean;
}

const PAIExtraTableHeader = (props: PAIExtraTableHeaderProps) => {
  const { paIRecordNotFound, isSearch, isLabel, isEmptyLists } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const listLabel = useAppSelector(selectLabelsList);
  const { switchPoOrName, labelArray } = useAppSelector(selectPaIFilterOptions);

  useEffect(() => {
      dispatch(getLabelsByPoOrName(switchPoOrName));
  }, [switchPoOrName]);

  const [inputValue, setInputValue] = React.useState<string | undefined>();

  const debouncedInputValue = useDebounce(inputValue, 500);

  const onHandleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInputValue(value);
  };

  const onHandleSearch = (value: string) => {
    dispatch(getPaIs({ page: 1, searchKeyword: value, switchPoOrName }));
  };

  useEffect(() => {
    if (
      debouncedInputValue !== undefined &&
      (debouncedInputValue.length > 2 || debouncedInputValue === '')
    ) {
      onHandleSearch(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    setInputValue(undefined);
  }, [switchPoOrName]);

  const onChangeLabels = (value: string[]) => {
    dispatch(getPaIs({ page: 1, labelArray: value, switchPoOrName }));
  };

  const labelOptions = useMemo(
    () =>
      listLabel
        .map((label) => ({
          label: label.label,
          value: label.label,
          labelColor: label.labelColor,
        }))
        .reduce((acc: { label: string; value: string; labelColor: string }[], curr) => {
          return acc.some((val) => val.label === curr.label) ? acc : [...acc, curr];
        }, []),
    [listLabel]
  );

  const tagRender = (option: CustomTagProps) => {
    const color = listLabel.find((label) => option.label === label.label)?.labelColor;
    return (
      <Label closable onClose={option.onClose} color={color}>
        {option.label}
      </Label>
    );
  };
  return (
    <>
      <InputSearch 
        onChange={onHandleInput} 
        placeholder={t('titles.Search')} 
        value={inputValue} 
        disabled={paIRecordNotFound && !isSearch}
      />
      <Select
        disabled={paIRecordNotFound && !isLabel || isEmptyLists}
        className="pai-header-action-filter"
        options={labelOptions}
        value={labelArray}
        onChange={onChangeLabels}
        placeholder={`${t('titles.Choose')} ${t('titles.Label').toLowerCase()}`}
        maxTagCount="responsive"
        mode="multiple"
        showArrow
        tagRender={tagRender}
      />
    </>
  );
};

export { PAIExtraTableHeader };
