import { notification } from 'antd';
import cl from 'classnames';

import type { ArgsProps } from 'antd/lib/notification';
import type React from 'react';

export type NotificationProps = Omit<ArgsProps, 'message'> & { message?: React.ReactNode };

const defaultClassNotification = 'custom-notification';

const defaultDuration = 3;

enum NotificationType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
  Open = 'open',
}

class Notifications {
  private defDuration = 3;

  private defClassName = 'custom-notification';

  success(config?: NotificationProps) {
    this.pushNotification(NotificationType.Success, {
      ...config,
      className: `${this.defClassName}-success`,
    });
  }

  error(config?: NotificationProps) {
    this.pushNotification(NotificationType.Error, {
      ...config,
      className: `${this.defClassName}-error`,
    });
  }

  info(config?: NotificationProps) {
    this.pushNotification(NotificationType.Info, {
      ...config,
      className: `${this.defClassName}-info`,
    });
  }

  warning(config?: NotificationProps) {
    this.pushNotification(NotificationType.Warning, {
      ...config,
      className: `${this.defClassName}-warning`,
    });
  }

  open(config?: NotificationProps) {
    this.pushNotification(NotificationType.Open, {
      ...config,
      className: `${this.defClassName}-open`,
    });
  }

  close(key: string) {
    notification.close(key);
  }

  private pushNotification(type: NotificationType, config?: NotificationProps) {
    notification[type]({
      ...config,
      className: cl(this.defClassName, config?.className),
      duration: config?.duration || this.defDuration,
      message: config?.message || type.toUpperCase(),
    });
  }
}

function openSuccessNotification(config: NotificationProps) {
  notification.success({
    ...config,
    className: cl(defaultClassNotification, `${defaultClassNotification}-success`),
    duration: config.duration ? config.duration : defaultDuration,
    message: config.message ? config.message : 'Successfully',
  });
}

function openErrorNotification(config: NotificationProps) {
  notification.error({
    ...config,
    className: cl(defaultClassNotification, `${defaultClassNotification}-error`),
    duration: config.duration ? config.duration : defaultDuration,
    message: config.message ? config.message : 'Error',
  });
}

function openMessageNotification(config: NotificationProps) {
  notification.open({
    ...config,
    className: cl(defaultClassNotification, `${defaultClassNotification}-message`),
    duration: config.duration ? config.duration : defaultDuration,
    message: config.message ? config.message : 'Notification',
    placement: config.placement ? config.placement : 'bottomRight',
  });
}

export const notificationController = {
  success: openSuccessNotification,
  error: openErrorNotification,
  message: openMessageNotification,
};

const NotificationInstance = new Notifications();

export { NotificationInstance as Notifications };
