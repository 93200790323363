import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common/BasicTablePage';
import { useAppSelector } from '@hooks';
import { compare } from '@shared/utils/functions';
import { selectCheckOutsList, selectCheckOutStatus } from '@store/selectors/properties/CheckOut';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { CheckOutModel } from '@model/properties/CheckOut';

const columns: ColumnsTableType<CheckOutModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'code',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.code, b.code),
  },
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

function CheckOuts() {
  const {t} = useTranslation();

  const listCheckOut = useAppSelector(selectCheckOutsList);
  const statusCheckOut = useAppSelector(selectCheckOutStatus);

  return (
    <BasicTablePage
      table={{
        columns,
        dataSource: listCheckOut,
        loading: statusCheckOut === 'pending',
        rowKey: (record) => record.checkFactorId,
        dataSourceName: t('pages_plural.Check_Outs'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', {name: t('pages_single.Check_Out')}),
        csvConfig: {
          data: listCheckOut,
          fileName: 'check-outs.csv',
          headers: [
            {
              label: 'Check Out Code',
              key: 'code',
            },
            {
              label: 'Check Out Name',
              key: 'name',
            },
          ],
        },
      }}
    />
  );
}

export {CheckOuts};
