import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormAssetProperty } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addAsAtProperty } from '@store/actions/properties/AsAt/Property';

import type { CreateAsAtPropertyDto } from '@model/properties/AsAt/Property';
import type { FormInstance } from 'antd';

function CreateAssetProperty() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { assetAttrAccess } = useGetAccess();

  const onFinish = (dto: CreateAsAtPropertyDto, form?: FormInstance<CreateAsAtPropertyDto>) => {
    dispatch(addAsAtProperty(dto))
      .unwrap()
      .then((payload) => {
        ApiNotifications.create(payload.name);
        navigate(paths.assetAttribute.tabs.property.default);
        form?.resetFields();
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <CheckAccess
      hasAccess={assetAttrAccess.create}
      redirectTo={paths.assetAttribute.tabs.property.default}
    >
      <FormAssetProperty
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Property') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreateAssetProperty;
