import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import {
  selectAsAtBrandsList,
  selectAsAtBrandStatus,
} from '@store/selectors/properties/AsAt/Brand';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { AsAtBrandModel } from '@model/properties/AsAt/Brand';

const columns: ColumnsTableType<AsAtBrandModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'nonCurrAssetCardBrandCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.nonCurrAssetCardBrandCode, b.nonCurrAssetCardBrandCode),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

const AssetBrands = () => {
  const {t} = useTranslation();

  const {assetAttrAccess} = useGetAccess();

  const assetBrandList = useAppSelector(selectAsAtBrandsList);
  const assetBrandStatus = useAppSelector(selectAsAtBrandStatus);

  return (
    <BasicTablePage
      table={{
        loading: assetBrandStatus === 'pending',
        dataSource: assetBrandList,
        columns: columns,
        rowKey: (record) => record.nonCurrAssetCardBrandId,
        dataSourceName: t('pages_plural.Asset_Attr_Brands'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', {name: t('pages_single.Asset_Attr_Brand')}),
        csvConfig: {
          data: assetBrandList,
          fileName: 'asset-brands.csv',
          headers: [
            {
              label: 'Code',
              key: 'nonCurrAssetCardBrandCode',
            },
            {
              label: 'Name',
              key: 'name',
            },
          ],
        },
        newButtonProps: {
          disabled: !assetAttrAccess.create,
        }
      }}
    />
  );
};

export {AssetBrands};
