import React, { useRef } from 'react';
import { useDrag } from 'react-dnd';

import cl from 'classnames';

import type { DropModel } from '@constants/DragAndDrop';

import './DraggebleItem.scss';

interface DraggableItemProps<T = any> {
  type: string;
  record: T;
  cellContentRender?: (record: T) => React.ReactNode;
  onClickCell?: (record: T) => void;
}

const DraggableItem = <T,>(props: DraggableItemProps<T>) => {
  const { type, record, cellContentRender, onClickCell } = props;
  const ref = useRef<HTMLDivElement>(null);

  const onClick = () => {
    onClickCell?.(record);
  };

  const [{ isDragging }, drag] = useDrag({
    type,
    item: (): DropModel<T> => {
      return { record, dragType: type };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(ref);

  return (
    <div
      ref={ref}
      onClick={onClick}
      className={cl('kanban-draggable-item', [{ 'kanban-draggable-item--dragging': isDragging }])}
    >
      {cellContentRender?.(record)}
    </div>
  );
};

export { DraggableItem };
