import React from 'react';
import { useTranslation } from 'react-i18next';

import { LineChartCard } from '@components/common';
import { numberWithIndent } from '@constants/regex';
import { 
    AssetBlueIcon,
    InventoriesIcon,
    OrdersIcon,
    PartsIcon,
  } from '@icon/icon-components';

import type { DashboardModel } from '@model/dashboard';

interface AssetLineChartsProps {
  data: DashboardModel | null
}

type InfoType = { 
  TL: number;
  USD: number;
  EUR: number;
  total: number;
};

type OrderInfoType = {
  InProgress: number;
  Open: number;
  Complete: number;
  total: number;
}

const AssetLineCharts = (props: AssetLineChartsProps) => {
  const { data } = props;
  const { t } = useTranslation();

  const assetInfo = data?.resultAssetPriceTotals && 
    data.resultAssetPriceTotals.reduce((acc, n) => 
    ({ ...acc, [n.currencySymbol]: n.totalPrice.toString().replace(numberWithIndent, "."), total: n.nonCurrAssetsCount + acc.total }), 
    { total: 0 });

  const inventoryInfo = data?.resultInventoriesPriceTotals && 
    data.resultInventoriesPriceTotals.reduce((acc, n) => 
    ({ ...acc, [n.currencySymbol]: n.totalPrice.toString().replace(numberWithIndent, "."), total: n.partsCount + acc.total }), 
    { total: 0 }); 

  const partInfo = data?.resultPartPriceTotals && 
    data.resultPartPriceTotals.reduce((acc, n) =>     
    ({ ...acc, [n.currencySymbol]: n.totalPrice.toString().replace(numberWithIndent, "."), total: n.partsCount + acc.total }), 
    { total: 0 }); 


  const orderInfo = data?.returnOrdersTotals && 
    data.returnOrdersTotals.reduce((acc, n) => {
      const statusName = n.executestatusname.split(' ').join('');
      return { ...acc, [statusName]: n.maintenancecount, total: n.maintenancecount + acc.total }
    }, { total: 0 }); 

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(29rem, 1fr))',
        justifyItems: 'center',
        gap: '1.6rem',
      }}
    >
      <LineChartCard 
        icon={<AssetBlueIcon />} 
        title={t('pages_plural.Assets')} 
        info={assetInfo as InfoType}
      />
      <LineChartCard 
        icon={<PartsIcon />} 
        title={t('titles.Parts')} 
        info={partInfo as InfoType} 
      />
      <LineChartCard 
        icon={<InventoriesIcon />} 
        title={t('titles.Inventories')} 
        info={inventoryInfo as InfoType}
      />
      <LineChartCard 
        icon={<OrdersIcon />} 
        title={t('titles.Orders')} 
        orderInfo={orderInfo as OrderInfoType}
      />
    </div>
  )
}

export { AssetLineCharts };