import { useEffect } from 'react';

import { setTabs } from '@store/actions/extra/Local';

import { useAppDispatch } from '../redux-hooks';

import type { TabType } from '@constants/pageInfo';

const useSetTabs = (tabs: TabType[] | null) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!tabs) return;
    dispatch(setTabs(tabs));
  }, []);
};

export { useSetTabs };
