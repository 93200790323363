/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@hooks';
import { clearCheckedAssets, handleCheckedAssets } from '@store/actions/management/Asset';
import {
  selectAssetChecked,
  selectAssetFilterOptions,
  selectAssetSettings,
} from '@store/selectors/management/Asset';

import { useGetAssetData } from '../_utils';
import { AssetListContext } from './AssetListContext';

import type { AssetModel } from '@model/management/Asset';
import type { ReactNode} from 'react';
import type { AssetListContextValue } from '../_utils';

type AssetListProviderProps = {
  children: ReactNode;
};

export const AssetListProvider = (props: AssetListProviderProps) => {
  const { children } = props;

  const { totalRecords } = useAppSelector(selectAssetSettings);

  const { t } = useTranslation();

  const data = useGetAssetData();

  const { page, perPage, labels, ...restFilterData } = useAppSelector(selectAssetFilterOptions);

  const dispatch = useAppDispatch();

  const { entities, isCheckedAll } = useAppSelector(selectAssetChecked);

  const hasFilterValue = Object.values(restFilterData).some((filterValue) => {
    return Array.isArray(filterValue) && filterValue.length > 0;
  });

  const handleChangeChecked = useCallback((checkedRecords: Array<AssetModel>) => {
    dispatch(handleCheckedAssets(checkedRecords));
  }, []);

  const clearChecked = useCallback(() => {
    dispatch(clearCheckedAssets());
  }, []);

  const checkedAssetsLength = entities.length;

  const helperCheckedText = `${checkedAssetsLength} ${
    checkedAssetsLength > 1 ? t('pages_plural.Assets') : t('pages_single.Asset')
  }`;

  const value: AssetListContextValue = {
    ...data,
    ...restFilterData,
    page,
    perPage,
    labels,
    hasFilterValue,
    checkedAssets: entities,
    handleChangeChecked,
    clearChecked,
    checkedAssetsLength,
    helperCheckedText,
    total: totalRecords,
  };

  return <AssetListContext.Provider value={value}>{children}</AssetListContext.Provider>;
};
