export const labelColors = [
  {
    value: '#7C8FC0',
    label: 'Blue',
  },
  {
    value: '#08A8A8',
    label: 'Turquoise',
  },
  {
    value: '#EA7000',
    label: 'Orange',
  },
  {
    value: '#972222',
    label: 'Burgundy',
  },
  {
    value: '#94138F',
    label: 'Purple',
  },
  {
    value: '#330E82',
    label: 'Dark Blue',
  },
  {
    value: '#077E21',
    label: 'Green',
  },
  {
    value: '#EFE500',
    label: 'Yellow',
  },
  {
    value: '#0CDB84',
    label: 'Lime',
  },
  {
    value: '#D8008E',
    label: 'Pink',
  },
  {
    value: '#60788B',
    label: 'Dark Grey',
  },
];
