import * as React from 'react';
import { memo } from 'react';

import type { SVGProps } from 'react';

export const AssigneePlusIcon = memo((props: SVGProps<SVGSVGElement>) => {
  const { fill = '#fff', ...restProps } = props;
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M13 8c0-2.21-1.79-4-4-4S5 5.79 5 8s1.79 4 4 4 4-1.79 4-4Zm-1.562 0c-.01 1.745-1.027 2.68-2.438 2.64C7.589 10.6 6.673 9.745 6.675 8 6.677 6.255 7.798 5.594 9 5.556c1.202-.037 2.447.7 2.438 2.444ZM1 18v1.211h16V18c0-2.66-5.33-4-8-4s-8 1.34-8 4Zm1.556 0c0-.887 2.54-2.712 6.444-2.712 3.903 0 6.343 1.897 6.343 2.712H2.556Zm17.266-3v-3.303H23V10.24h-3.178V7H18.26v3.24H15v1.457h3.26V15h1.562Z"
        fill={fill}
      />
    </svg>
  );
});
