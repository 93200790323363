/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Translation } from 'react-i18next';

import { useAppDispatch, useHandleParamId } from '@hooks';
import { Label, Table } from '@shared/UI';
import { compare } from '@shared/utils/functions';
import { getAssets } from '@store/actions/management/Asset';

import { useAssetListContext } from '../AssetListProvider';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { AssetModel } from '@model/management/Asset';
import type { TableProps } from '@shared/UI';
import type { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import type { Key } from 'react';

import './ViewTable.scss';

interface ViewTableProps extends TableProps<AssetModel> {
  getAssetsByPage: (page: number) => void;
}

const columns: ColumnsTableType<AssetModel> = [
  {
    title: <Translation>{(t) => t('titles.Status')}</Translation>,
    dataIndex: ['status', 'name'],
    key: '4',
    ellipsis: true,
    sorter: true,
    render: (_, { status }) => {
      return (
        <>
          <span style={{ color: status.color }}>{status.name}</span>
        </>
      );
    },
  },
  {
    title: <Translation>{(t) => t('titles.Barcode')}</Translation>,
    dataIndex: 'barcode',
    key: '6',
    ellipsis: true,
    sorter: true,
  },
  {
    title: <Translation>{(t) => t('titles.Asset_Name')}</Translation>,
    dataIndex: ['nonCurrAssetCard', 'name'],
    key: '2',
    ellipsis: true,
    sorter: true,
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: ['site', 'name'],
    key: 'site',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Assign_To')}</Translation>,
    dataIndex: ['nonCurrAssetUsers', '0', 'user', 'firstName'],
    key: 'Assign to',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Label')}</Translation>,
    dataIndex: 'statusName',
    key: 'status',
    ellipsis: true,
    render: (_, { labels }) => {
      return (
        <>
          {labels && labels.length
            ? labels.map((label) => {
                return (
                  <Label key={label.labelId} color={label.labelColor}>
                    {label.label}
                  </Label>
                );
              })
            : null}
        </>
      );
    },
  },
];

const ViewTable = (props: ViewTableProps) => {
  const { loading, dataSource, pagination, getAssetsByPage } = props;

  const dispatch = useAppDispatch();

  const { checkedAssets, handleChangeChecked } = useAssetListContext();

  const { redirect } = useHandleParamId();

  const checkedIds = checkedAssets.map((asset) => asset.nonCurrAssetId);

  const handleChange = (_: Array<Key>, checkedItems: Array<AssetModel>) => {
    handleChangeChecked(checkedItems);
  };

  const onRow: TableProps<AssetModel>['onRow'] = (record) => {
    const key = record.nonCurrAssetId.toString();

    return {
      onClick: () => {
        redirect(key);
      },
    };
  };

  const rowSelectionProps: TableProps<AssetModel>['rowSelection'] = {
    selectedRowKeys: checkedIds,
    onChange: handleChange,
    preserveSelectedRowKeys: true,
  };

  const paginationProps: TableProps<AssetModel>['pagination'] = {
    onChange: getAssetsByPage,
    ...pagination,
  };

  const handleSort = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    const { order, columnKey } = sorter as SorterResult<AssetModel>;

    const sortDirection = 
      (order === "ascend") ? 1 :
      (order === "descend") ? 2 : 3;

    const sortProps = {
      sortDirection, 
      sortBy: columnKey as number, 
      page: pagination.current, 
      perPage: pagination.pageSize
    }

    dispatch(getAssets(sortProps));
  };

  return (
    <Table
      className="asset-table"
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={paginationProps}
      rowSelection={rowSelectionProps}
      rowKey={(record) => record.nonCurrAssetId}
      onRow={onRow}
      onChange={handleSort}
    />
  );
};

const memoViewTable = React.memo(ViewTable);

export { memoViewTable as ViewTable };
