const typeTemplateEN = "'${label}' is not a valid ${type}";

export const validateMessagesEN = {
  default: "Validation error on field '${label}'",
  required: "'${label}' is required",
  enum: "'${label}' must be one of [${enum}]",
  whitespace: "'${label}' cannot be empty",
  date: {
    format: "'${label}' is invalid for format date",
    parse: "'${label}' could not be parsed as date",
    invalid: "'${label}' is invalid date",
  },
  types: {
    string: typeTemplateEN,
    method: typeTemplateEN,
    array: typeTemplateEN,
    object: typeTemplateEN,
    number: typeTemplateEN,
    date: typeTemplateEN,
    boolean: typeTemplateEN,
    integer: typeTemplateEN,
    float: typeTemplateEN,
    regexp: typeTemplateEN,
    email: typeTemplateEN,
    url: typeTemplateEN,
    hex: typeTemplateEN,
  },
  string: {
    len: "'${label}' must be exactly ${len} characters",
    min: "'${label}' must be at least ${min} characters",
    max: "'${label}' cannot be longer than ${max} characters",
    range: "'${label}' must be between ${min} and ${max} characters",
  },
  number: {
    len: "'${label}' must equal ${len}",
    min: "'${label}' cannot be less than ${min}",
    max: "'${label}' cannot be greater than ${max}",
    range: "'${label}' must be between ${min} and ${max}",
  },
  array: {
    len: "'${label}' must be exactly ${len} in length",
    min: "'${label}' cannot be less than ${min} in length",
    max: "'${label}' cannot be greater than ${max} in length",
    range: "'${label}' must be between ${min} and ${max} in length",
  },
  pattern: {
    mismatch: "'${label}' does not match pattern ${pattern}",
  },
};

const typeTemplateTR = "'${label}' geçerli bir ${type} değil";

export const validateMessagesTR = {
  default: "'${label}' alanında doğrulama hatası",
  required: "'${label}' zorunludur",
  enum: "'${label}' must be one of [${enum}]",
  whitespace: "'${label}' boş olamaz",
  date: {
    format: "'${label}' tarih formatı hatalıdır",
    parse: "'${label}' tarih parse edilemedi",
    invalid: "'${label}' geçersiz tarihtir",
  },
  types: {
    string: typeTemplateTR,
    method: typeTemplateTR,
    array: typeTemplateTR,
    object: typeTemplateTR,
    number: typeTemplateTR,
    date: typeTemplateTR,
    boolean: typeTemplateTR,
    integer: typeTemplateTR,
    float: typeTemplateTR,
    regexp: typeTemplateTR,
    email: typeTemplateTR,
    url: typeTemplateTR,
    hex: typeTemplateTR,
  },
  string: {
    len: "'${label}' tam olarak ${len} karakter olmalıdır",
    min: "'${label}' en az ${min} karakter olmalıdır",
    max: "'${label}', ${max} ckarakterden uzun olamaz",
    range: "'${label}', ${min} ile ${max} karakter arasında olmalıdır",
  },
  number: {
    len: "'${label}', ${len} değerine eşit olmalıdır",
    min: "'${label}', ${min} değerinden küçük olamaz",
    max: "'${label}', ${max} değerinden büyük olamaz",
    range: "'${label}', ${min}$ ile ${max} arasında olmalıdır",
  },
  array: {
    len: "'${label}' must be exactly ${len} in length",
    min: "'${label}' cannot be less than ${min} in length",
    max: "'${label}' cannot be greater than ${max} in length",
    range: "'${label}' must be between ${min} and ${max} in length",
  },
  pattern: {
    mismatch: "'${label}' does not match pattern ${pattern}",
  },
};
