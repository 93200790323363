import { mapCheckStepOptionsToWorkStepOptions } from './mapCheckStepOptionsToWorkStepOptions';

import type { StepMcList } from '@model/management/Maintenance';
import type { ActionFunction } from '../../types';

export const getNewWorkStepOptions: ActionFunction<null, StepMcList[]> = (_, actionAPI) => {
  return actionAPI.state.reduce((acc: StepMcList[], step) => {
    if (step.optionsList.length) {
      const newOptions = step.optionsList.filter((opt) => opt.isNew);
      mapCheckStepOptionsToWorkStepOptions(
        {
          options: newOptions,
          stepExternalId: step.externalId,
        },
        actionAPI
      ).forEach((opt) => acc.push(opt));
    }

    return acc;
  }, []);
};
