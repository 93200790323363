import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, RoleAuthorizationsList, TextAreaDescription } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Input } from '@shared/UI';
import { checkStoreStatuses } from '@shared/utils/functions';
import { getAuthorizations } from '@store/actions/extra/Authorization';
import {
  selectAuthorizationList,
  selectAuthorizationStatus,
} from '@store/selectors/extra/Authorization';
import { selectRoleStatus } from '@store/selectors/users/Role';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateRoleFormDto, RoleFunctionNameModel } from '@model/users/Role';

const { CaseField, Item, useForm } = CommonForm;

interface FormRoleProps extends CommonFormProps<CreateRoleFormDto> {}

function FormRole(props: FormRoleProps) {
  const { initialValues, ...rest } = props;
  const [form] = useForm<CreateRoleFormDto>();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const roleStatus = useAppSelector(selectRoleStatus);

  const authorizationList = useAppSelector(selectAuthorizationList);

  const authorizationStatus = useAppSelector(selectAuthorizationStatus);

  useEffect(() => {
    if (authorizationList.length === 0) {
      dispatch(getAuthorizations())
        .unwrap()
        .then((res) => {
          if (!initialValues || initialValues?.functionList?.length === 0) {
            form.setFieldValue('functionList', res);
          }
        });
    }
  }, []);

  const initial: Partial<CreateRoleFormDto> = {
    ...initialValues,
    functionList:
      !initialValues || initialValues?.functionList?.length === 0
        ? (authorizationList as Array<RoleFunctionNameModel>)
        : initialValues.functionList,
  };

  const isLoading = checkStoreStatuses([authorizationStatus, roleStatus]);

  return (
    <CommonForm
      form={form}
      loading={isLoading}
      initialValues={initial}
      container={{
        style: {
          maxWidth: '100%',
        },
      }}
      {...rest}
    >
      <CaseField md={10}>
        <Item
          label={t('titles.Role_Name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Role_Name')} />
        </Item>
        <TextAreaDescription name="description" label={t('titles.Description')} />
      </CaseField>
      <CaseField md={14} hiddenTitle>
        <Item name="functionList">
          <RoleAuthorizationsList />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormRole };
