import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaIStatusRepository } from '@repositories';

import type {
  CreatePaIStatusDto,
  PaIStatusModel,
  UpdatePaIStatusDto,
} from '@model/properties/PaI/Status';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getPaIStatuses = createAsyncThunk<PaIStatusModel[], void, { rejectValue: string }>(
  'PaIStatus/getPaIStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PaIStatusRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getPaIStatusById = createAsyncThunk<PaIStatusModel, string, { rejectValue: string }>(
  'PaIStatus/getPaIStatusById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await PaIStatusRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addPaIStatus = createAsyncThunk<
  PaIStatusModel,
  CreatePaIStatusDto,
  { rejectValue: string }
>('PaIStatus/addPaIStatus', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaIStatusRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updatePaIStatus = createAsyncThunk<
  { models: PaIStatusModel[]; particularModel: PaIStatusModel },
  UpdatePaIStatusDto,
  { rejectValue: string }
>('PaIStatus/updatePaIStatus', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaIStatusRepository.update(dto);
    const responseList = await PaIStatusRepository.fetchAll();

    return { models: responseList.resultObject, particularModel: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removePaIStatusById = createAsyncThunk<
  PaIStatusModel[],
  number[],
  { rejectValue: string }
>('PaIStatus/removePaIStatusById', async (ids, { rejectWithValue }) => {
  try {
    await PaIStatusRepository.removeById(ids);
    const response = await PaIStatusRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
