import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { REQUIRED_RULE } from '@constants/formRules';
import { Select, TextArea } from '@shared/UI';

import { DateBlock } from '../DateBlock';

import type { VendorModel } from '@model/properties/Vendor';
import type { ItemRules, SelectFieldNames } from '@shared/utils/types';

interface RightSectionProps {
  vendorList: Array<VendorModel>;
  isModal?: boolean;
  hidden?: boolean;
  creatableVendorAction: () => void;
}

const {CaseField, Item} = CommonForm;

// region --- Item rules

const partnerRules: ItemRules = [REQUIRED_RULE];

// endregion --- Item rules

// region --- Field names

const vendorNames: SelectFieldNames<VendorModel> = {
  label: 'name',
  value: 'partnerId',
};

// endregion --- Field names

export const RightSection = memo((props: RightSectionProps) => {
  const {vendorList, isModal, hidden, creatableVendorAction} = props;

  const {t} = useTranslation();

  return (
    <CaseField isModal={isModal} hidden={hidden}>
      <Item label={t('titles.Vendor')} name="partnerId" rules={partnerRules}>
        <Select
          options={vendorList}
          fieldNames={vendorNames}
          placeholder={t('titles.Choose_Name', {name: t('titles.Vendor')})}
          isCreatable={!isModal}
          creatableAction={creatableVendorAction}
        />
      </Item>
      <DateBlock/>

      <Item label={t('titles.Description')} name="description" hidden={isModal}>
        <TextArea placeholder={t('titles.Enter_Descr')}/>
      </Item>
    </CaseField>
  );
});
