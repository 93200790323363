import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, FilePreview, UploaderFiles } from '@components/common';
import { useAppDispatch, useIsEditPage } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { removeContractFileById } from '@store/actions/properties/Contract';

import type { FileWithID } from '@interfaces/interfaces';
import type { ContractFileModel, CreateContractDto } from '@model/properties/Contract';
import type { CSSProperties} from 'react';

const { useFormInstance, useWatch, CaseField, Item } = CommonForm;

const showFileName = (file: FileWithID | FileWithID<ContractFileModel>) => {
  if (file.file.hasOwnProperty('originalFileNameForDisplay')) {
    const contractFile = file as FileWithID<ContractFileModel>;
    return `${contractFile.file?.originalFileNameForDisplay}.${contractFile.file?.fileType}`;
  }
  const simpleFile = file as FileWithID;
  return simpleFile.file.name;
};

interface FileBlockProps {
  hidden?: boolean;
  isModal?: boolean;
}

export const FileBlock = memo((props: FileBlockProps) => {
  const { isModal, hidden } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const form = useFormInstance<CreateContractDto & { files?: FileWithID[] }>();

  const [deleteIds, setDeletedIds] = useState<Array<string>>([]);

  // region --- Observed value

  const fileList = useWatch('files', form);

  // endregion --- Observed value

  const isEdit = useIsEditPage();

  const onClickDeleteFile = useCallback(
    (file: FileWithID | FileWithID<ContractFileModel>) => {
      const allFiles = form.getFieldValue('files') as FileWithID[];
      const filteredFiles = allFiles.filter((f) => f.id !== file.id);
      form.setFieldsValue({ files: filteredFiles });

      if (isEdit) {
        if ('contractFilesId' in file.file) {
          dispatch(removeContractFileById(file.file.contractFilesId))
            .unwrap()
            .then(() => {
              ApiNotifications.remove(showFileName(file));
            });
        }
      }

      setDeletedIds((prev) => [...prev, file.id]);
    },
    [fileList]
  );

  const caseStyles: CSSProperties | undefined = isModal
    ? {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }
    : undefined;

  const getValueFromEvent = (files: Array<FileWithID>) => {
    return files.filter((file) => {
      return !deleteIds.some((val) => val === file.id);
    });
  };

  return (
    <CaseField
      md={24}
      title={t('titles.Documents')}
      isModal={isModal}
      hidden={hidden}
      style={caseStyles}
      extra={
        <Item name="files" getValueFromEvent={getValueFromEvent}>
          <UploaderFiles maxFiles={100} isModal={isModal} />
        </Item>
      }
    >
      {fileList &&
        fileList.map((file) => {
          const name = showFileName(file);

          return (
            <FilePreview
              key={file.id}
              fileName={name}
              onClickDelete={() => {
                onClickDeleteFile(file);
              }}
            />
          );
        })}
    </CaseField>
  );
});
