import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectUserGroupStatus } from '@store/selectors/users/UserGroup';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { UserGroupModel } from '@model/users/UserGroup';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormUserGroup(props: CommonPreviewProps<UserGroupModel>) {
  const { entity, onDelete } = props;

  const { t } = useTranslation();

  const statusUserGroup = useAppSelector(selectUserGroupStatus);

  return (
    <CommonForm
      isPreview
      loading={statusUserGroup === 'pending'}
      header={{
        title: entity.groupName,
        onClickDelete: onDelete,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.groupCode}</PreviewField>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.groupName}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormUserGroup };
