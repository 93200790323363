import React, { memo, useState } from 'react';

import { Arrow } from '@icon/icon-components';
import { Dropdown } from '@shared/UI';
import cl from 'classnames';
import get from 'lodash.get';

import { SearchItem } from './SearchItem';

import type { DropdownProps, MenuProps } from '@shared/UI';
import type { SearchItemProps } from './SearchItem';

import './ToolbarDropdownItem.scss';

type ToolbarDropdownMenu = Omit<MenuProps, 'items' | 'onClick'>;

type FieldNames<T> = { label: keyof T; key: keyof T };

type ToolbarDropdownItemProps<T> = SearchItemProps & {
  items: Array<T>;

  fieldNames?: FieldNames<T>;

  isOpen: boolean;

  label: React.ReactNode;

  menu?: ToolbarDropdownMenu;

  onClickItem?: (key: string) => void;

  onOpenChange?: DropdownProps['onOpenChange'];

  icon?: React.ReactNode;

  className?: string;

  overlayClassName?: string;

  showSearchItem?: boolean;
};

const SEARCH_ITEM_KEY = 'toolbar-item-search';

const getMenuItems = <T,>(items: Array<T>, fieldNames?: FieldNames<T>) => {
  return items.reduce((acc: Array<{ label: string; key: number | string }>, item, idx) => {
    const option = {
      label: (get(item, fieldNames?.label || 'label') as string) || '',
      key: (get(item, fieldNames?.key || 'key') as number | string) || idx,
    };

    const isExist = acc.some((current) => current.key === option.key);

    if (!isExist) {
      acc.push(option);
    }

    return acc;
  }, []);
};

const getMenuItemsByQuery = <T,>(items: Array<T>, query: string, fieldNames?: FieldNames<T>) => {
  const menuItems = getMenuItems(items, fieldNames);

  return menuItems.filter((item) => item.label.toLowerCase().includes(query.toLowerCase()));
};

export const ToolbarDropdownItem = memo(<T,>(props: ToolbarDropdownItemProps<T>) => {
  const {
    items,
    fieldNames,
    label,
    isOpen,
    icon,
    menu,
    className,
    overlayClassName,
    onOpenChange,
    showSearchItem = true,
    searchQuery,
    onChangeSearch,
    onClickItem,
  } = props;

  const classes = cl('toolbar-dropdown-item', className);

  const overlayClasses = cl('toolbar-dropdown-item-overlay', overlayClassName);

  const trigger: DropdownProps['trigger'] = ['click'];

  const [query, setQuery] = useState('');

  const [menuItems, setMenuItems] = useState(() => getMenuItems(items, fieldNames));

  const handleItemClick: MenuProps['onClick'] = ({ key }) => {
    if (key !== SEARCH_ITEM_KEY) {
      onClickItem?.(key);
    }
  };

  const handleSearchChange = (value: string) => {
    setQuery(value);

    setMenuItems(() => getMenuItemsByQuery(items, value, fieldNames));
  };

  const itemsWithSearch: MenuProps['items'] = [
    {
      label: (
        <SearchItem
          searchQuery={searchQuery || query}
          onChangeSearch={onChangeSearch || handleSearchChange}
        />
      ),
      key: SEARCH_ITEM_KEY,
      className: 'toolbar-dropdown-item-search',
    },
    ...menuItems,
  ];

  const updatedMenu: MenuProps = {
    ...menu,
    onClick: handleItemClick,
    items: showSearchItem ? itemsWithSearch : menuItems,
  };

  return (
    <Dropdown
      className={classes}
      overlayClassName={overlayClasses}
      open={isOpen}
      menu={updatedMenu as DropdownProps['menu']}
      trigger={trigger}
      onOpenChange={onOpenChange}
    >
      <button>
        {icon}
        <span className="toolbar-dropdown-item-label"> {label}</span>
        <Arrow className="toolbar-dropdown-item-arrow" />
      </button>
    </Dropdown>
  );
});
