import { createAsyncThunk } from '@reduxjs/toolkit';
import { AlertRepository } from '@repositories';

import type { AlertModel } from '@model/extra/Alert';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer';

export const getUserAlerts = createAsyncThunk<AlertModel[], void, { rejectValue: string }>(
  'Alert/getAlerts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AlertRepository.fetchUserAlerts();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const markAlertAsRead = createAsyncThunk<
  void,
  string | number | undefined,
  { rejectValue: string; state: RootState }
>('Alert/markAlertAsRead', async (alertId, { rejectWithValue, dispatch, getState }) => {
  try {
    const numberOfUnread = getState().AlertReducer.alertsList.filter(
      (alert) => !alert.isRead
    ).length;

    const alertIsRead = alertId
      ? getState().AlertReducer.alertsList.find((alert) => alert.alertId === alertId)
      : null;

    if (numberOfUnread === 0 || (alertIsRead && alertIsRead.isRead)) {
      return;
    }

    await AlertRepository.markAlertAsRead(alertId);

    await dispatch(getUserAlerts());
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});
