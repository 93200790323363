import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { languages } from '@constants/languages';
import { useClickOutside } from '@hooks';
import { LanguageLCAdapter } from '@services/LocaleStorage/adapters/LanguageLC.adapter';
import cl from 'classnames';

import type { CSSProperties} from 'react';

import './LanguageSwitcher.scss';

interface LanguageSwitcherProps {
  className?: string;
  style?: CSSProperties;
}

const LanguageSwitcher = (props: LanguageSwitcherProps) => {
  const { className, style } = props;
  const { i18n } = useTranslation();

  const [lng, setLng] = useState(() => {
    const currentLanguage = LanguageLCAdapter.getCurrentLanguage();
    return currentLanguage ? currentLanguage : 'en';
  });
  const [active, setActive] = useState(false);

  const classPrefix = 'language-switcher';
  const classes = cl(classPrefix, className, {
    [`${classPrefix}--active`]: active,
  });

  const currentLanguage = languages.find((language) => language.key === lng) || languages[0];

  const ref = useClickOutside<HTMLDivElement>(() => {
    setActive(false);
  });

  const renderLanguages = useMemo(
    () =>
      languages.map((language) => (
        <li key={language.key} onClick={onSelect(language.key)}>
          <span className={`${classPrefix}-prefix`}>{language.prefix}</span>
          <span className={`${classPrefix}-icon`}> {language.icon}</span>
        </li>
      )),
    []
  );

  function onClick() {
    setActive((prevState) => !prevState);
  }

  function onSelect(value: string) {
    return () => {
      i18n.changeLanguage(value).then(() => {
        setLng(() => value);
        setActive(false);
      });
    };
  }

  return (
    <>
      <div className={classes} style={style} onClick={onClick}>
        <div className={`${classPrefix}-value`}>
          <span className={`${classPrefix}-prefix`}>{currentLanguage.prefix}</span>
          <span className={`${classPrefix}-icons`}>{currentLanguage.icon}</span>
        </div>
        {active && (
          <div ref={ref} className={`${classPrefix}-list`}>
            <ul>{renderLanguages}</ul>
          </div>
        )}
      </div>
    </>
  );
};

export { LanguageSwitcher };
