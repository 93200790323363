import React from 'react';

import { PreviewFormAsset } from '@components/views/Previews/management';
import { useOutletAsset } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeAssetById } from '@store/actions/management/Asset';

import type { CommonPreviewProps } from '@components/common';

function PreviewAsset() {
  const { entity } = useOutletAsset();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { assetAccess } = useGetAccess();

  const onDelete = () => {
    dispatch(removeAssetById([entity.nonCurrAssetId]))
      .unwrap()
      .then(() => {
        notificationController.success({
          description: `${entity.barcode} deleted`,
        });
        navigate(paths.asset.default);
      })
      .catch((err) => {
        if (typeof err === 'string') {
          notificationController.error({
            description: err,
          });
        }
      });
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !assetAccess.edit,
    },
    deleteButtonProps: {
      disabled: !assetAccess.delete,
    },
  };

  return <PreviewFormAsset entity={entity} onDelete={onDelete} header={headerProps} />;
}

export default PreviewAsset;
