import type { CreateMaintenanceResultDto } from '@model/management/Maintenance';
import type { CheckStepItem } from '../../types';

export const mapCheckStepsToWorkStepResults = (
  steps: CheckStepItem[],
  maintenanceId: number,
  scheduleId: number,
  userId: number,
  executeId: number
): CreateMaintenanceResultDto[] => {
  return steps.map((step) => {
    const dto: CreateMaintenanceResultDto = {
      maintenanceId,
      resultText: step.value,
      workStepId: step.externalId as number,
      loggedUserId: userId,
      maintenanceSchedulesId: scheduleId,
      maintenanceExecuteStatusId: executeId,
      imgList: {
        resultImageLinkArray: step.imagesList.map((image) => image.file),
        workStepsId: step.externalId as number,
      },
      mcList: step.optionsList.map((option) => ({
        resultText: option.value,
        workStepsId: step.externalId as number,
        multipleChoiceListForWorkStepsId: option.externalId as number,
      })),
    };

    return dto;
  });
};
