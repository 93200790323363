import { api } from '@services/Axios';

import type {
  CreatePaIBrandDto,
  PaIBrandModel,
  UpdatePaIBrandDto,
} from '@model/properties/PaI/Brand';
import type { ApiResponse } from '@services/Axios';

const PaIBrandEndpoint = '/PartsAndInventoriesCardBrands';

export const PaIBrandRepository = {
  async fetchAll() {
    const response: ApiResponse<PaIBrandModel[]> = await api.get(
      `${PaIBrandEndpoint}/GetPartsAndInventoriesCardBrandsList`
    );

    return response.data;
  },
  async add(dto: CreatePaIBrandDto) {
    const response: ApiResponse<PaIBrandModel> = await api.post(
      `${PaIBrandEndpoint}/AddPartsAndInventoriesCardBrand`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<PaIBrandModel> = await api.get(
      `${PaIBrandEndpoint}/GetPartsAndInventoriesCardBrandById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdatePaIBrandDto) {
    const response: ApiResponse<PaIBrandModel> = await api.post(
      `${PaIBrandEndpoint}/UpdatePartsAndInventoriesCardBrand`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${PaIBrandEndpoint}/RemoveByIdList`, {
      partsAndInventoriesCardBrandIds: ids,
    });

    return response.data;
  },
};
