import React from 'react';

const HeartUkrFlag = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1865_85952"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="15"
      >
        <path
          d="M7.69533 14.2061L7.36238 13.9841C5.77973 12.9272 3.77719 11.7326 2.29405 9.97682C0.731013 8.12707 -0.0188766 6.22738 0.000360789 4.1686C0.0236677 1.86973 1.86713 0 4.10977 0C5.8896 0 7.07307 1.03586 7.69533 1.78353C8.31759 1.03586 9.50106 0 11.2809 0C13.5235 0 15.367 1.86973 15.3903 4.16749C15.411 6.22738 14.6611 8.12596 13.0966 9.97571C11.6135 11.7326 9.61093 12.9272 8.02829 13.9841L7.69533 14.2061Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_1865_85952)">
        <path
          d="M7.69601 14.7497C12.309 14.7497 16.0327 11.026 16.0327 6.41309H-0.640625C-0.640625 11.026 3.08307 14.7497 7.69601 14.7497Z"
          fill="#FFE62E"
        />
        <path
          d="M7.69601 -1.92383C3.08307 -1.92383 -0.640625 1.79987 -0.640625 6.41281H16.0327C16.0327 1.79987 12.309 -1.92383 7.69601 -1.92383Z"
          fill="#428BC1"
        />
      </g>
    </svg>
  );
};

export { HeartUkrFlag };
