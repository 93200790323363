import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FormMaintenanceResult } from '@components/views/Forms/managment';
import { useAppDispatch, useAppSelector } from '@hooks';
import CryptographyService from '@services/Cryptography';
import { ApiNotifications } from '@services/Notifications/adapters';
import { Loader } from '@shared/UI';
import { checkStoreStatuses } from '@shared/utils/functions';
import { getMaintenanceById } from '@store/actions/management/Maintenance';
import {
  createMaintenanceResult,
  createPartialMaintenanceResult,
  getMaintenanceResultByScheduleIdVer2,
  updateMaintenanceResult,
  updateMaintenanceResultStatus,
} from '@store/actions/management/MaintenanceResult';
import {
  selectMaintenance,
  selectMaintenanceStatus,
} from '@store/selectors/management/Maintenance';
import {
  selectMaintenanceResult,
  selectMaintenanceResultStatus,
} from '@store/selectors/management/MaintenanceResult';
import { selectProfile } from '@store/selectors/profile/Profile';

import type {
  CreateMaintenanceResultDto,
  UpdateMaintenanceResultDto,
} from '@model/management/Maintenance';

const MaintenanceResult = () => {
  const dispatch = useAppDispatch();

  const params = useParams<{ id: string; maintenanceId: string }>();

  const scheduleId = CryptographyService.decrypt(params.id ? params.id : '');
  const maintenanceId = CryptographyService.decrypt(
    params.maintenanceId ? params.maintenanceId : ''
  );

  const profile = useAppSelector(selectProfile)!;
  const maintenanceResult = useAppSelector(selectMaintenanceResult);
  const maintenanceResultStatus = useAppSelector(selectMaintenanceResultStatus);
  const maintenance = useAppSelector(selectMaintenance);
  const maintenanceStatus = useAppSelector(selectMaintenanceStatus);

  const loading = checkStoreStatuses([maintenanceResultStatus, maintenanceStatus]);

  const onSubmit = (dto: CreateMaintenanceResultDto) => {
    dispatch(createPartialMaintenanceResult(dto));
  };

  const onComplete = (dto: CreateMaintenanceResultDto[]) => {
    dispatch(createMaintenanceResult(dto))
      .unwrap()
      .then()
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onUpdate = (value: UpdateMaintenanceResultDto) => {
    dispatch(updateMaintenanceResult(value))
      .unwrap()
      .catch((e) => {
        ApiNotifications.error(e);
      });
  };

  function onClick() {
    if (maintenanceResult) {
      let statusId = 1;
      if (maintenanceResult.executeStatusId === 1) {
        statusId = 2;
      }
      if (maintenanceResult.executeStatusId === 2) {
        statusId = 3;
      }
      dispatch(
        updateMaintenanceResultStatus({
          maintenanceSchedulesId: +scheduleId,
          maintenanceExecuteStatusId: statusId,
        })
      );
    }
  }

  useEffect(() => {
    if (!maintenanceResult || maintenanceResult.scheduleId !== +scheduleId) {
      dispatch(getMaintenanceResultByScheduleIdVer2({ scheduleId, maintenanceId }));
    }

    if (!maintenance || maintenance.maintenanceId !== +maintenanceId) {
      dispatch(getMaintenanceById(maintenanceId));
    }
  }, []);

  if (!maintenance || !maintenanceResult) {
    return <Loader middle />;
  }

  return (
    <FormMaintenanceResult
      loading={loading}
      onSubmit={onSubmit}
      maintenance={maintenance}
      maintenanceResult={maintenanceResult}
      onClick={onClick}
      onComplete={onComplete}
      userId={profile.userId}
      scheduleId={+scheduleId}
      onUpdate={onUpdate}
    />
  );
};

export default MaintenanceResult;
