import { createSlice } from '@reduxjs/toolkit';
import {
  addCompany,
  getAllCompanies,
  getCompanies,
  getCompaniesByFilter,
  getCompanyById,
  removeCompanyById,
  updateCompany,
} from '@store/actions/user/Company';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { CompanyModel, FilterCompanyDto } from '@model/users/Company';

type CompanyState = {
  companiesList: CompanyModel[];
  companysFilteredList: CompanyModel[];
  companiesAllList: CompanyModel[];
  companyStatus: LoadingStatus;
  company: CompanyModel | null;
  companyError: string | null;
  companyFilters: FilterCompanyDto;
};

const initialState: CompanyState = {
  companiesList: [],
  companysFilteredList: [],
  companiesAllList: [],
  companyStatus: 'idle',
  companyError: null,
  company: null,
  companyFilters: {
    companyCodesArray: [],
    namesArray: [],
    addressesArray: [],
    taxNumbersArray: [],
    taxOfficesArray: [],
  },
};
const CompanySlice = createSlice({
  name: 'Company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.companyError = null;
        state.companyStatus = 'pending';
        state.companysFilteredList = [];
        state.companyFilters = {
          companyCodesArray: [],
          namesArray: [],
          addressesArray: [],
          taxNumbersArray: [],
          taxOfficesArray: [],
        };
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.companyStatus = 'idle';
        if (action.payload) {
          state.companiesList = action.payload;
        }
      })
      .addCase(getCompanies.rejected, (state) => {
        state.companyStatus = 'idle';
      });
    builder
      .addCase(getCompaniesByFilter.pending, (state) => {
        state.companyError = null;
        state.companyStatus = 'pending';
      })
      .addCase(getCompaniesByFilter.fulfilled, (state, { payload }) => {
        state.companyStatus = 'idle';
        state.companysFilteredList = payload.companys;
        state.companyFilters = payload.companysFilters;
      })
      .addCase(getCompaniesByFilter.rejected, (state, action) => {
        state.companyStatus = 'idle';
        if (action.payload) {
          state.companyError = action.payload;
        }
      });
    builder
      .addCase(getAllCompanies.pending, (state) => {
        state.companyStatus = 'pending';
      })
      .addCase(getAllCompanies.fulfilled, (state, action) => {
        state.companyStatus = 'idle';
        if (action.payload) {
          state.companiesAllList = action.payload;
        }
      })
      .addCase(getAllCompanies.rejected, (state) => {
        state.companyStatus = 'idle';
      });
    builder
      .addCase(addCompany.pending, (state) => {
        state.companyStatus = 'pending';
        state.companyError = null;
      })
      .addCase(addCompany.fulfilled, (state, { payload }) => {
        state.companyStatus = 'idle';
        state.companiesList = [payload, ...state.companiesList];
      })
      .addCase(addCompany.rejected, (state) => {
        state.companyStatus = 'idle';
      });
    builder
      .addCase(getCompanyById.pending, (state) => {
        state.companyStatus = 'pending';
        state.companyError = null;
      })
      .addCase(getCompanyById.fulfilled, (state, { payload }) => {
        state.companyStatus = 'idle';
        state.company = payload;
      })
      .addCase(getCompanyById.rejected, (state) => {
        state.companyStatus = 'idle';
      });
    builder
      .addCase(updateCompany.pending, (state) => {
        state.companyStatus = 'pending';
        state.companyError = null;
      })
      .addCase(updateCompany.fulfilled, (state, { payload }) => {
        state.companyStatus = 'idle';
        state.company = payload.someCompany;
        state.companiesList = payload.companies;
      })
      .addCase(updateCompany.rejected, (state) => {
        state.companyStatus = 'idle';
      });
    builder
      .addCase(removeCompanyById.pending, (state) => {
        state.companyStatus = 'pending';
        state.companyError = null;
      })
      .addCase(removeCompanyById.fulfilled, (state, { payload }) => {
        state.companyStatus = 'idle';
        state.companiesList = payload;
      })
      .addCase(removeCompanyById.rejected, (state) => {
        state.companyStatus = 'idle';
      });
  },
});

const CompanyReducer = CompanySlice.reducer;

export { CompanyReducer };
