import React from 'react';

import { Button as AntdButton } from 'antd';
import cl from 'classnames';

import type { ButtonProps as AntdButtonProps } from 'antd';

import './Button.scss';

export interface ButtonProps extends Omit<AntdButtonProps, 'className'> {
  color?: 'sky';
  className?: string | string[];
}

interface ButtonInterface<T> extends React.FC<T> {
  Group: typeof AntdButton.Group;
}

const Button: ButtonInterface<ButtonProps> = (props) => {
  const { children, type, className, color, ...rest } = props;

  const defaultClass = cl('custom-button', { [`custom-button-color--${color}`]: color }, className);

  return (
    <AntdButton className={defaultClass} type={type} {...rest}>
      {children}
    </AntdButton>
  );
};

Button.Group = AntdButton.Group;

export { Button };
