import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@shared/UI';

import { useCheckStepContext } from '../../_utils';

import styles from './BaseField.module.scss';

export const BaseField = () => {
  const { t } = useTranslation();

  const { step } = useCheckStepContext();

  const getPlaceholder = () => {
    switch (step.stepKey) {
      case 1:
        return t('titles.Text');
      case 3:
        return '0000';
      case 4:
        return '00.00';
      case 5:
        return t('titles.Inspection');
    }
  };

  return <Input className={styles.baseField} readOnly disabled placeholder={getPlaceholder()} />;
};
