import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getCompanies } from '@store/actions/user/Company';
import { selectCompaniesList } from '@store/selectors/users/Company';

const EntryCompany = () => {
  useSetPageInfo({
    defaultPagePath: paths.companies.default,
    pageTitle: 'pages_single.Company',
  });

  const companies = useAppSelector(selectCompaniesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!companies.length) {
      dispatch(getCompanies());
    }
  }, []);

  return <Outlet />;
};

export default EntryCompany;
