import React from 'react';

import { useCheckStepContext } from '../../_utils';
import { MultipleVariant } from './MultipleVariant';

import styles from './MultipleChoice.module.scss';

export const MultipleChoice = () => {
  const { step } = useCheckStepContext();

  const options = step.optionsList.map((option) => {
    if (option.isRemoved) return null;
    return <MultipleVariant key={option.uuid} option={option} />;
  });

  return (
    <div className={styles.multipleChoiceField}>
      {options}
      {step.isActive && <MultipleVariant type="button" />}
    </div>
  );
};
