import React from 'react';

const Maintenances = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 18H2.5C1.39543 18 0.5 17.1046 0.5 16V2C0.5 0.89543 1.39543 0 2.5 0H16.5C17.6046 0 18.5 0.89543 18.5 2V16C18.5 17.1046 17.6046 18 16.5 18ZM2.5 2V16H16.5V2H2.5ZM14.5 14H12.5V7H14.5V14ZM10.5 14H8.5V4H10.5V14ZM6.5 14H4.5V9H6.5V14Z"
      fill="white"
    />
  </svg>
);

export { Maintenances };
