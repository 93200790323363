import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '@hooks';
import { Label, Select } from '@shared/UI';
import { getAssets } from '@store/actions/management/Asset';

import { getLabelOptions } from '../../_utils';
import { useAssetListContext } from '../../AssetListProvider';

export type LabelFilterProps = {
  disabledLabelFilter?: boolean;
};

export const LabelFilter = memo((props: LabelFilterProps) => {
  const { disabledLabelFilter = false } = props;

  const dispatch = useAppDispatch();

  const { listLabel, labels } = useAssetListContext();

  const { t } = useTranslation();

  const options = useMemo(() => getLabelOptions(listLabel), [listLabel]);

  const renderLabelTag = (option: {
    value: string;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    [key: string]: any;
  }) => {
    const color = options.find((labelOpt) => option.label === labelOpt.value)?.color;
    return (
      <Label closable onClose={option.onClose} color={color}>
        {option.label}
      </Label>
    );
  };

  const handleChange = (value: Array<string>) => {
    dispatch(getAssets({ labels: value }));
  };

  return (
    <Select
      mode="multiple"
      options={options}
      showArrow
      tagRender={renderLabelTag}
      maxTagCount="responsive"
      placeholder={t('titles.Choose_Name', { name: t('titles.Label') })}
      onChange={handleChange}
      value={labels}
      disabled={disabledLabelFilter}
    />
  );
});
