import React from 'react';

import { Segmented } from 'antd';
import cl from 'classnames';

import type { SegmentedProps } from 'antd';

import './Segment.scss';

export interface SegmentProps extends SegmentedProps {}

const Segment = (props: SegmentProps) => {
  const { ref: refs, className, ...rest } = props;

  const segmentClasses = cl(className, 'custom-segment');

  // @ts-ignore
  return <Segmented className={segmentClasses} {...rest} />;
};

export { Segment };
