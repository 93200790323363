import type { RootState } from '@store/rootReducer';

export const selectAsAtBrandState = (state: RootState) => state.AsAtBrandReducer;

export const selectAsAtBrandsList = (state: RootState) =>
  selectAsAtBrandState(state).AsAtBrandsList;

export const selectAsAtBrand = (state: RootState) => selectAsAtBrandState(state).AsAtBrand;

export const selectAsAtBrandStatus = (state: RootState) =>
  selectAsAtBrandState(state).AsAtBrandStatus;

export const selectAsAtBrandError = (state: RootState) =>
  selectAsAtBrandState(state).AsAtBrandError;
