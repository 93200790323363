import React, { memo } from 'react';

import { Input } from 'antd';
import cl from 'classnames';

import type { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input';

import './TextArea.scss';

export interface TextAreaProps extends Omit<AntdTextAreaProps, 'className'> {
  className?: string | string[];
}

const { TextArea: AntdTextArea } = Input;

export const TextArea = memo((props: TextAreaProps) => {
  const {
    showCount = true,
    maxLength = 150,
    autoSize = { minRows: 6, maxRows: 6 },
    className,
    ...rest
  } = props;

  return (
    <AntdTextArea
      className={cl('custom-textarea', className)}
      autoSize={autoSize}
      maxLength={maxLength}
      showCount={showCount}
      {...rest}
    />
  );
});
