import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToolbarDropdownItem } from '@components/common';
import { useModalState } from '@hooks';
import { CheckCheckboxIcon } from '@icon/icon-components';

import { useAssetListContext } from '../../../../AssetListProvider';
import { StatusModal } from './StatusModal';

export const StatusDropdown = memo(() => {
  const statusDropdown = useModalState();

  const { t } = useTranslation();

  const statusModal = useModalState();

  const { listAssetStatuses } = useAssetListContext();

  const [statusId, setSiteId] = useState<null | number>(null);

  const handleOpenChange = () => {
    statusDropdown.actions.onToggle();
  };

  const handleClickItem = (key: string) => {
    setSiteId(+key);

    statusDropdown.actions.onClose();

    statusModal.actions.onOpen();
  };

  return (
    <>
      <ToolbarDropdownItem
        isOpen={statusDropdown.isOpen}
        label={t('titles.Change_With_Name', { name: t('titles.Status') })}
        icon={<CheckCheckboxIcon stroke={statusDropdown.isOpen ? '#74AAEA' : '#fff'} />}
        items={listAssetStatuses}
        fieldNames={{ label: 'name', key: 'nonCurrAssetStatusId' }}
        onOpenChange={handleOpenChange}
        onClickItem={handleClickItem}
        showSearchItem={false}
      />
      <StatusModal
        isOpen={statusModal.isOpen}
        onClose={statusModal.actions.onClose}
        statusId={statusId}
      />
    </>
  );
});
