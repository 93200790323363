import React from 'react';

import { PreviewFormCostCenter } from '@components/views/Previews/properties';
import { useOutletCostCenter } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeCostCenterById } from '@store/actions/properties/CostCenter';

import type { CommonPreviewProps } from '@components/common';

function PreviewCostCenter() {
  const { entity } = useOutletCostCenter();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { costCenterAccess } = useGetAccess();

  const onDelete = () => {
    dispatch(removeCostCenterById([entity.costCenterId]))
      .unwrap()
      .then(() => {
        notificationController.success({
          description: `${entity.name} deleted`,
        });
        navigate(paths.costCenter.default);
      })
      .catch((err) => {
        if (typeof err === 'string') {
          notificationController.error({
            description: err,
          });
        }
      });
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !costCenterAccess.edit,
    },
    deleteButtonProps: {
      disabled: !costCenterAccess.delete,
    },
  };

  return <PreviewFormCostCenter entity={entity} onDelete={onDelete} header={headerProps} />;
}

export default PreviewCostCenter;
