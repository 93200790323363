import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getAsAtCardById } from '@store/actions/properties/AsAt/Card';
import { selectAsAtCard } from '@store/selectors/properties/AsAt/Card';

import type { IOutletAssetName } from '@constants/OutletContextEntityPages';
import type { CreateAsAtCardDto } from '@model/properties/AsAt/Card';

function AssetName() {
  const assetName = useAppSelector(selectAsAtCard);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !assetName || assetName.nonCurrAssetCardId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getAsAtCardById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateAsAtCardDto = {
    nonCurrAssetCardCode: assetName.nonCurrAssetCardCode,
    name: assetName.name,
    nonCurrAssetCardCategoryId: assetName.nonCurrAssetCardCategoryId,
  };

  return <Outlet context={{ initialValues, entity: assetName } as IOutletAssetName} />;
}

export default AssetName;
