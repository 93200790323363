import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import { selectPaICardsList, selectPaICardStatus } from '@store/selectors/properties/PaI/Card';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { PaICardModel } from '@model/properties/PaI/Card';

const columns: ColumnsTableType<PaICardModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'partsAndInventoriesCardCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.partsAndInventoriesCardCode, b.partsAndInventoriesCardCode),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'partsAndInventoriesCardName',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.partsAndInventoriesCardName, b.partsAndInventoriesCardName),
  },
  {
    title: <Translation>{(t) => t('titles.Category')}</Translation>,
    dataIndex: ['partsAndInventoriesCategory', 'partsAndInventoriesCategoryName'],
    key: 'category',
    ellipsis: true,
    sorter: (a, b) =>
      compare(
        a.partsAndInventoriesCategory.partsAndInventoriesCategoryName,
        b.partsAndInventoriesCategory.partsAndInventoriesCategoryName
      ),
  },
  {
    title: <Translation>{(t) => t('titles.Min_Stock_Level')}</Translation>,
    dataIndex: 'minimumStockLevel',
    key: 'stockLevel',
    ellipsis: true,
    sorter: (a, b) => a.minimumStockLevel - b.minimumStockLevel,
  },
  {
    title: <Translation>{(t) => t('titles.Unit')}</Translation>,
    dataIndex: ['partsAndInventoriesUnit', 'partsAndInventoriesUnitName'],
    key: 'unit',
    ellipsis: true,
    sorter: (a, b) =>
      compare(
        a.partsAndInventoriesUnit.partsAndInventoriesUnitName,
        b.partsAndInventoriesUnit.partsAndInventoriesUnitName
      ),
  },
];

const PartAndInventoryNames = () => {
  const {t} = useTranslation();

  const listPartAndInventoryName = useAppSelector(selectPaICardsList);
  const statusPartAndInventoryName = useAppSelector(selectPaICardStatus);


  const {paiAttrAccess} = useGetAccess();

  const csvData = useMemo(
    () =>
      listPartAndInventoryName.map((name) => ({
        code: name.partsAndInventoriesCardCode,
        name: name.partsAndInventoriesCardName,
        category: name.partsAndInventoriesCategory.partsAndInventoriesCategoryName,
        stockLevel: name.minimumStockLevel,
        unit: name.partsAndInventoriesUnit.partsAndInventoriesUnitName,
        enumTrackingMethode: name.enumTrackingMethode === 1 ? 'FIFO' : 'LIFO',
      })),
    [listPartAndInventoryName]
  );

  return (
    <BasicTablePage
      table={{
        loading: statusPartAndInventoryName === 'pending',
        dataSource: listPartAndInventoryName,
        columns: columns,
        rowKey: (record) => record.partsAndInventoriesCardId,
        dataSourceName: t('pages_plural.PaI_Attr_Names'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', {name: t('pages_single.PaI_Attr_Name')}),
        csvConfig: {
          data: csvData,
          fileName: 'part-and-inventory-names',
          headers: [
            {label: 'Code', key: 'code'},
            {label: 'Name', key: 'name'},
            {label: 'Category', key: 'category'},
            {label: 'Minimum Stock Level', key: 'stockLevel'},
            {label: 'Unit', key: 'unit'},
            {label: 'Tracking Methode', key: 'enumTrackingMethode'},
          ],
        },
        newButtonProps: {
          disabled: !paiAttrAccess.create
        }
      }}
    />
  );
};

export {PartAndInventoryNames};
