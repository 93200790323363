import { api } from '@services/Axios';

import type { AlertModel } from '@model/extra/Alert';
import type { ApiResponse } from '@services/Axios';

const AlertEndpoint = '/Alert';

export const AlertRepository = {
  async fetchUserAlerts() {
    const response: ApiResponse<AlertModel[]> = await api.post(`${AlertEndpoint}/GetUserAlertList`);

    return response.data;
  },

  async markAlertAsRead(alertId?: string | number) {
    const response: ApiResponse<AlertModel> = await api.post(
      `${AlertEndpoint}/ChangeIsReadStatusTrue`,
      {
        alertId: alertId ? alertId : 0,
        isReadAll: alertId !== undefined,
      }
    );

    return response.data;
  },
};
