import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { MinusCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Button, Input, Option, Select } from '@shared/UI';
import { checkEditPage } from '@shared/utils/functions';
import { getAsAtProperties, getAsAtPropertyTypes } from '@store/actions/properties/AsAt/Property';
import {
  selectAsAtPropertiesList,
  selectAsAtPropertyTypesList,
} from '@store/selectors/properties/AsAt/Property';
import { Col, Row } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { CommonForm } from '../CommonForm';

import type { CreateAssetDto } from '@model/management/Asset';
import type { ChangeEvent } from 'react';

import './PropertyValues.scss';

const { useFormInstance, Item, List } = CommonForm;

type SelectedProperty = {
  label: string;
  value: number;
  propertyType: string;
  isUnique: boolean;
};

type PropertyValue = {
  value: string;
  nonCurrAssetCardPropertyId: number;
  nonCurrAssetPropertyValueId?: number | string;
  isRemove?: boolean;
};

interface PropertyValuesProps {
  defaultValue?: PropertyValue[] | null;
  creatableAction?: () => void;
  isCreatable?: boolean;
}

const PropertyValues = (props: PropertyValuesProps) => {
  const { defaultValue, isCreatable, creatableAction } = props;
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const form = useFormInstance<CreateAssetDto>();

  const assetPropertyList = useAppSelector(selectAsAtPropertiesList);
  const assetPropertyTypeList = useAppSelector(selectAsAtPropertyTypesList);

  const [removedPropertyValues, setRemovedPropertyValues] = useState<(number | string)[]>([]);

  const [selectedPropertyType, setSelectedPropertyType] = useState<null | SelectedProperty>(null);

  const [propertyValue, setPropertyValue] = useState<null | PropertyValue>(null);

  const [submittedValues, setSubmittedValues] = useState<PropertyValue[]>(() => {
    if (defaultValue) {
      return defaultValue;
    }
    return [];
  });

  useEffect(() => {
    if (!assetPropertyList.length) {
      dispatch(getAsAtProperties());
    }

    if (!assetPropertyTypeList.length) {
      dispatch(getAsAtPropertyTypes());
    }
  }, []);

  /**
   * It checks if the submittedValues array contains an object with a property of
   * nonCurrAssetCardPropertyId that matches the id passed in as an argument
   * @param {number} id - number - the id of the property
   * @returns Boolean
   */
  const checkSubmittedValues = (id: number) => {
    const result = submittedValues.find((value) => value.nonCurrAssetCardPropertyId === id);
    return Boolean(result);
  };

  /**
   * It checks if the value is unique and if it is, it checks if the value has been submitted.
   * @param {boolean} isUnique - boolean - this is the result of the checkUnique function.
   * @param {number} id - The id of the current item.
   * @returns a boolean value.
   */
  const checkIsUnique = (isUnique: boolean, id: number) => {
    return isUnique && checkSubmittedValues(id);
  };

  /**
   * It checks if the id of a property value is in the array of removed property values
   * @param {number | string | undefined} id - The id of the property value.
   * @returns A function that takes an id and returns a boolean.
   */
  const checkRemovedValues = (id: number | string | undefined) => {
    if (!id) return false;
    return removedPropertyValues.includes(id);
  };

  /* It checks if the page is in edit page. */
  const isEdit = checkEditPage(pathname);

  const fieldPropertyId = form.getFieldValue('selectedProperty');
  /* It checks if the property is added via a form instance */
  const isFormInstancePropertyId =
    fieldPropertyId && fieldPropertyId !== selectedPropertyType?.value;

  /* ------------------------- Create Property Type Options ------------------------ */
  const propertyTypeOptions = useMemo(() => {
    return assetPropertyList.reduce((acc, property) => {
      if (!checkIsUnique(property.isUnique, property.nonCurrAssetCardPropertyId)) {
        acc.push({
          label: property.name,
          value: property.nonCurrAssetCardPropertyId,
          isUnique: property.isUnique,
          propertyType: property.nonCurrAssetType.name,
        });
        return acc;
      }
      return acc;
    }, [] as SelectedProperty[]);
  }, [assetPropertyList, assetPropertyTypeList, submittedValues]);

  /* ------------------------- Create Property List Values ------------------------ */
  /* if we selected property which have type List, we create option for select with that property values */
  const createPropertyListValues = useCallback(
    (property: SelectedProperty) => {
      const currProperty = assetPropertyList.find(
        (stateProperty) => stateProperty.nonCurrAssetCardPropertyId === property.value
      );
      if (currProperty) {
        return currProperty.nonCurrAssetPropertyValues.map((value) => {
          return {
            label: value.value,
            value: value.nonCurrAssetPropertyValueId,
            nonCurrAssetCardPropertyId: value.nonCurrAssetCardPropertyId,
          };
        });
      }
    },
    [selectedPropertyType, assetPropertyList]
  );

  const renderSubmittedValueButton = useCallback(
    (add: (defValue: PropertyValue) => void) => {
      const onClick = () => {
        setSubmittedValues((prevValue) => {
          if (propertyValue) {
            add(propertyValue);
            return [...prevValue, propertyValue];
          }
          return prevValue;
        });
        setPropertyValue(null);
        setSelectedPropertyType(null);

        if (selectedPropertyType?.isUnique) {
          form.setFieldValue('selectedProperty', null);
        }
      };

      const isDisabledButton = Boolean(propertyValue && propertyValue.value.length > 50);

      return (
        <Button
          onClick={onClick}
          className="property-values__button-ok"
          disabled={isDisabledButton}
        >
          {t('titles.Ok')}
        </Button>
      );
    },
    [selectedPropertyType, propertyValue, submittedValues]
  );

  /* ------------------- Addition function for property value ------------------- */
  /* If we added a parameter via form instance */

  /* Sets the correct parameter to be able to add a Property Value */
  function getPropertyById(id: number) {
    const property = assetPropertyList.find((obj) => obj.nonCurrAssetCardPropertyId === id);

    if (property) {
      return {
        label: property.name,
        value: property.nonCurrAssetCardPropertyId,
        propertyType: property.nonCurrAssetType.name,
      } as SelectedProperty;
    }
  }

  useEffect(() => {
    if (assetPropertyList.length > 0 && isFormInstancePropertyId) {
      const newProperty = getPropertyById(fieldPropertyId);
      if (newProperty) {
        setSelectedPropertyType(newProperty);
      }
    }
  }, [isFormInstancePropertyId]);

  /* ------------------- Render function for property value ------------------- */
  /* If we have selected type 'List', we render select which will have options from propertyValues, else we render Input  */
  const renderPropertyValue = useCallback(
    (fnAdd: (defValue: PropertyValue) => void) => {
      const isSubmittedValue = checkSubmittedValues(fieldPropertyId);

      if (selectedPropertyType?.propertyType === 'List') {
        const options = createPropertyListValues(selectedPropertyType);

        return (
          <Item
            label={t('titles.Property_Value')}
            name="selectValue"
            required={!isSubmittedValue}
            rules={[
              () => ({
                validator: () => {
                  if (!isSubmittedValue) {
                    return Promise.reject(new Error('Please confirm the value'));
                  }
                  return Promise.resolve();
                },
                validateTrigger: 'onSubmit',
              }),
            ]}
          >
            <Row align="middle">
              <Col span={20}>
                <Select
                  className="property-values__property-value-select"
                  options={options}
                  onChange={(_, option) => {
                    if (option && !Array.isArray(option)) {
                      setPropertyValue({
                        value: option.label,
                        nonCurrAssetCardPropertyId: option.nonCurrAssetCardPropertyId,
                      });
                    }
                  }}
                />
              </Col>
              <Col span={4}>{renderSubmittedValueButton(fnAdd)}</Col>
            </Row>
          </Item>
        );
      }

      if (selectedPropertyType && selectedPropertyType.propertyType !== 'List') {
        const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
          setPropertyValue({
            value: e.target.value,
            nonCurrAssetCardPropertyId: selectedPropertyType.value,
            nonCurrAssetPropertyValueId: uuidv4(),
          });
        };
        return (
          <Item
            label={t('titles.Property_Value')}
            name="inputValue"
            rules={[
              {
                max: 50,
              },
              {
                required: !isSubmittedValue,
              },
              () => ({
                validator: (_, value) => {
                  if (!isSubmittedValue && value) {
                    return Promise.reject(new Error('Please confirm the value'));
                  }
                  return Promise.resolve();
                },
                validateTrigger: 'onSubmit',
              }),
            ]}
          >
            <Row>
              <Col span={20}>
                <Input
                  className="property-values__property-value-input"
                  value={propertyValue?.value}
                  onChange={onChangeInput}
                />
              </Col>
              <Col span={4}>{renderSubmittedValueButton(fnAdd)}</Col>
            </Row>
          </Item>
        );
      }

      return (
        <Item
          label={t('titles.Property_Value')}
          help={t('titles.Choose_Name', { name: t('titles.Property') })}
        >
          <Input readOnly disabled placeholder={t('titles.Property_Value')} />
        </Item>
      );
    },
    [selectedPropertyType, propertyValue, submittedValues]
  );

  return (
    <>
      <Item
        label={t('titles.Property')}
        name="selectedProperty"
        extra={selectedPropertyType?.isUnique ? t('titles.Property_Is_Unique') : null}
      >
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Property') })}
          value={selectedPropertyType?.value}
          onChange={(_, option) => {
            setSelectedPropertyType(option as unknown as SelectedProperty);
          }}
          isCreatable={isCreatable}
          creatableAction={creatableAction}
        >
          {propertyTypeOptions.map((option) => {
            return (
              <Option key={option.value} {...option}>
                {option.label}
                <span className="property-values__option-type">{option.propertyType}</span>
              </Option>
            );
          })}
        </Select>
      </Item>
      <List name="nonCurrAssetPropertyDetalisModelList">
        {(fields, { add, remove }) => {
          return (
            <div>
              {renderPropertyValue(add)}
              <div className="property-values__list-items-wrapper">
                {fields.map(({ key, name }) => {
                  const currentPropertyValue = form.getFieldValue([
                    'nonCurrAssetPropertyDetalisModelList',
                    name,
                  ]) as PropertyValue;

                  if (
                    checkRemovedValues(currentPropertyValue?.nonCurrAssetPropertyValueId) &&
                    isEdit
                  ) {
                    return null;
                  }

                  const fieldName = assetPropertyList.find(
                    (property) =>
                      property.nonCurrAssetCardPropertyId ===
                      currentPropertyValue.nonCurrAssetCardPropertyId
                  );
                  return (
                    <div key={key}>
                      <Row className="property-values__list-item" justify="space-between">
                        <Col span={22}>
                          <p className="property-values__list-item-value">
                            <span>{fieldName?.name}:</span> {currentPropertyValue.value}
                          </p>
                        </Col>
                        <Col>
                          <MinusCircleOutlined
                            onClick={() => {
                              const allPropertyValues = form.getFieldValue(
                                'nonCurrAssetPropertyDetalisModelList'
                              ) as PropertyValue[];

                              setSubmittedValues((prevValue) => {
                                return prevValue.filter(
                                  ({ nonCurrAssetPropertyValueId }) =>
                                    currentPropertyValue.nonCurrAssetPropertyValueId !==
                                    nonCurrAssetPropertyValueId
                                );
                              });

                              if (isEdit) {
                                form.setFieldsValue({
                                  nonCurrAssetPropertyDetalisModelList: allPropertyValues.map(
                                    (property, index) =>
                                      index === name
                                        ? {
                                            ...property,
                                            isRemove: true,
                                          }
                                        : property
                                  ),
                                });
                                setRemovedPropertyValues((prevValue) => {
                                  if (currentPropertyValue.nonCurrAssetPropertyValueId) {
                                    return [
                                      ...prevValue,
                                      currentPropertyValue.nonCurrAssetPropertyValueId,
                                    ];
                                  }
                                  return prevValue;
                                });
                              } else {
                                remove(name);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      </List>
    </>
  );
};

export { PropertyValues };
