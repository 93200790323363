import type { ActionAPI, CheckStepItem } from '../../types';

export function setSteps(val: CheckStepItem[], actionApi: ActionAPI) {
  const { setState } = actionApi;
  setState((prevState) =>
    [...prevState, ...val].map((step, idx) => ({
      ...step,
      rowId: idx + 1,
    }))
  );
}
