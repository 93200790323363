import { api } from '@services/Axios';

import type {
  MaintenanceResultByHistoryModel,
  WorkStepResultModel,
} from '@model/management/Maintenance';
import type {
  CreateMaintenanceResultDto,
  MaintenanceResultModel,
  MaintenanceResultVer2Model,
  MaintenanceScheduleModel,
  UpdateMaintenanceResultDto,
  UpdateMaintenanceResultStatusDto,
} from '@model/management/Maintenance';
import type { ApiResponse } from '@services/Axios';

const MaintenanceResultEndpoint = '/MaintenanceResult';

export const MaintenanceResultRepository = {
  /* ------------------------------- Fetch ------------------------------ */
  async fetchMaintenanceResultByScheduleId(id: string | number) {
    const response: ApiResponse<MaintenanceResultModel[]> = await api.get(
      `${MaintenanceResultEndpoint}/GetMaintenanceResultsBySchedulesId/${id}`
    );

    return response.data;
  },

  async fetchMaintenanceResultByScheduleIdVer2(
    scheduleId: number | string,
    maintenanceId: number | string
  ) {
    const response: ApiResponse<
      MaintenanceResultVer2Model[],
      { resultObjectType: number; maintenanceExecuteStatusId: number }
    > = await api.get(
      `${MaintenanceResultEndpoint}/GetMaintenanceResultsBySchedulesIdVer3/${scheduleId}/${maintenanceId}`
    );

    return response.data;
  },

  async fetchMaintenanceResultByHistory(
    scheduleId: number | string,
    maintenanceId: number | string,
    nonCurrAssetId: number | string
  ) {
    const response: ApiResponse<
      MaintenanceResultByHistoryModel,
      { resultObjectType: number; maintenanceExecuteStatusId: number }
    > = await api.get(
      `${MaintenanceResultEndpoint}/GetMaintenanceHistoryPopup/${scheduleId}/${maintenanceId}/${nonCurrAssetId}`
    );

    return response.data;
  },

  /* ------------------------------- Create ------------------------------ */

  async createMaintenanceResult(dto: CreateMaintenanceResultDto[]) {
    const response: ApiResponse<null> = await api.post(
      `${MaintenanceResultEndpoint}/AddMaintenanceResults`,
      {
        addMaintenanceResultArray: dto,
      }
    );

    return response.data;
  },

  async createPartialMaintenanceResult(dto: CreateMaintenanceResultDto) {
    const response: ApiResponse<null> = await api.post(
      `${MaintenanceResultEndpoint}/AddParticialMaintenanceResult`,
      dto
    );

    return response.data;
  },

  /* ------------------------------- Update  ------------------------------ */

  async updateMaintenanceResultStatus(dto: UpdateMaintenanceResultStatusDto) {
    const response: ApiResponse<MaintenanceScheduleModel> = await api.post(
      `${MaintenanceResultEndpoint}/ChangeMaintenanceStatus`,
      dto
    );

    return response.data;
  },

  async updateMaintenanceResult(dto: UpdateMaintenanceResultDto) {
    const response: ApiResponse<WorkStepResultModel> = await api.post(
      `${MaintenanceResultEndpoint}/UpdateMaintenanceResults`,
      dto
    );

    return response.data;
  },
};
