import { api } from '@services/Axios';

import type {
  AsAtStatusModel,
  CreateAsAtStatusDto,
  UpdateAsAtStatusDto,
} from '@model/properties/AsAt/Status';
import type { ApiResponse } from '@services/Axios';

const AsAtStatusEndpoint = '/NonCurrAssetStatus';

const AsAtStatusRepository = {
  async fetchAll() {
    const response: ApiResponse<AsAtStatusModel[]> = await api.get(
      `${AsAtStatusEndpoint}/GetNonCurrAssetStatusList`
    );

    return response.data;
  },
  async add(dto: CreateAsAtStatusDto) {
    const response: ApiResponse<AsAtStatusModel> = await api.post(
      `${AsAtStatusEndpoint}/AddNonCurrAssetStatus`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<AsAtStatusModel> = await api.get(
      `${AsAtStatusEndpoint}/GetNonCurrAssetStatusById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdateAsAtStatusDto) {
    const response: ApiResponse<AsAtStatusModel> = await api.post(
      `${AsAtStatusEndpoint}/UpdateNonCurrAssetStatus`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(
      `${AsAtStatusEndpoint}/RemoveNonCurrAssetStatusByIdList`,
      {
        nonCurrAssetStatusIds: ids,
      }
    );

    return response.data;
  },
};

export { AsAtStatusRepository };
