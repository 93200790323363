import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Select } from '@shared/UI';
import { getSites } from '@store/actions/properties/Site';
import { selectSitesListWithoutChildren, selectSiteStatus } from '@store/selectors/properties/Site';

import type { ItemProps } from '@shared/UI';

interface SelectSiteProps extends ItemProps {
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  disabled?: boolean;
}

const { Item } = CommonForm;

const SelectSite = (props: SelectSiteProps) => {
  const { rules: rulesProps, mode, name, label, disabled, placeholder, ...restItem } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const statusSite = useAppSelector(selectSiteStatus);
  const listSites = useAppSelector(selectSitesListWithoutChildren);

  useEffect(() => {
    if (!listSites.length) {
      dispatch(getSites());
    }
  }, []);

  const options = useMemo(() => {
    return listSites.map((site) => ({
      value: site.siteId,
      label: `${site.name}`,
    }));
  }, [listSites]);

  return (
    <Item
      name={name || 'siteId'}
      label={label || t('titles.Site')}
      rules={rulesProps}
      {...restItem}
    >
      <Select
        mode={mode}
        options={options}
        placeholder={placeholder || t('titles.Choose')}
        loading={statusSite === 'pending'}
        disabled={disabled}
      />
    </Item>
  );
};

export { SelectSite };
