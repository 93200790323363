import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, PropertyValues } from '@components/common';
import { Input, Select } from '@shared/UI';

import type { AssetPropertyDetailModel } from '@model/management/Asset';
import type { ContractModel } from '@model/properties/Contract';
import type { SelectFieldNames } from '@shared/utils/types';

interface ExpandedLeftSectionProps {
  contractsList: Array<ContractModel>;

  initialPropertyValues?: Array<AssetPropertyDetailModel> | null;

  onAssetPropertyOpenModal: () => void;

  onContractOpenModal: () => void;
}

const { CaseField, Item } = CommonForm;

// region --- Field names

const contractNames: SelectFieldNames<ContractModel> = {
  label: 'name',
  value: 'contractId',
};

// endregion --- Field names

export const ExpandedLeftSection = memo((props: ExpandedLeftSectionProps) => {
  const { 
    contractsList, 
    initialPropertyValues, 
    onAssetPropertyOpenModal, 
    onContractOpenModal 
  } = props;

  const { t } = useTranslation();

  return (
    <CaseField hiddenTitle md={24} lg={7}>
      <Item 
        label={t('titles.Invoice_Number')} 
        name="invoiceNo"
        rules={[
          {
            max: 20,
          },
        ]}
      >
        <Input placeholder={t('titles.Invoice_Number')} />
      </Item>

      <Item label={t('titles.Contracts')} name="contractIds">
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Contracts') })}
          options={contractsList}
          fieldNames={contractNames}
          maxTagCount={5}
          showArrow
          mode="multiple"
          isCreatable
          creatableAction={onContractOpenModal}
        />
      </Item>

      <PropertyValues 
        defaultValue={initialPropertyValues} 
        isCreatable
        creatableAction={onAssetPropertyOpenModal}
      />
    </CaseField>
  );
});
