import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaICategoryRepository } from '@repositories';

import type {
  CreatePaICategoryDto,
  PaICategoryModel,
  UpdatePaICategoryDto,
  UpdatePaICategoryParentDto,
} from '@model/properties/PaI/Category';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getPaICategories = createAsyncThunk<PaICategoryModel[], void, { rejectValue: string }>(
  'PaICategory/getPaICategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PaICategoryRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getPaICategoryById = createAsyncThunk<
  PaICategoryModel,
  string,
  { rejectValue: string }
>('PaICategory/getPaICategoryById', async (id, { rejectWithValue }) => {
  try {
    const response = await PaICategoryRepository.fetchById(id);

    return response.resultObject[0];
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addPaICategory = createAsyncThunk<
  {
    particularCategory: PaICategoryModel;
    categories: PaICategoryModel[];
  },
  CreatePaICategoryDto,
  { rejectValue: string }
>('PaICategory/addPaICategory', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaICategoryRepository.add(dto);
    const responseList = await PaICategoryRepository.fetchAll();

    return { particularCategory: response.resultObject, categories: responseList.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateParICategory = createAsyncThunk<
  {
    categories: PaICategoryModel[];
    particularCategory: PaICategoryModel;
  },
  UpdatePaICategoryDto,
  { rejectValue: string }
>('PaICategory/updateParICategory', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaICategoryRepository.update(dto);
    const responseList = await PaICategoryRepository.fetchAll();

    return { categories: responseList.resultObject, particularCategory: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const updatePaICategoryParentRelations = createAsyncThunk<
  PaICategoryModel[],
  UpdatePaICategoryParentDto,
  { rejectValue: string }
>('PaICategory/updatePaICategoryParentRelations', async (dto, { rejectWithValue }) => {
  try {
    await PaICategoryRepository.updatePatentRelations(dto);
    const response = await PaICategoryRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removePaICategoryById = createAsyncThunk<
  PaICategoryModel[],
  number[],
  { rejectValue: string }
>('PaICategory/removePaICategoryById', async (ids, { rejectWithValue }) => {
  try {
    await PaICategoryRepository.removeById(ids);
    const response = await PaICategoryRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
