import React from 'react';
import { Outlet } from 'react-router-dom';

import { useSetTabs } from '@hooks';
import { paths } from '@routes/paths';

function EntryPartAndInventories() {
  useSetTabs(Object.values(paths.partAndInventoryAttributes.tabs));

  return <Outlet />;
}

export default EntryPartAndInventories;
