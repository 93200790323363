import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectCheckOutStatus } from '@store/selectors/properties/CheckOut';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { CheckOutModel } from '@model/properties/CheckOut';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormCheckOut(props: CommonPreviewProps<CheckOutModel>) {
  const { entity, onDelete } = props;

  const { t } = useTranslation();

  const statusCheckOut = useAppSelector(selectCheckOutStatus);

  return (
    <CommonForm
      isPreview
      loading={statusCheckOut === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.code}</PreviewField>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Description')}>
          <PreviewField typeField="textarea">{entity.description}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormCheckOut };
