import React from 'react';

import { PreviewFormMaintenanceHistory } from '@components/views/Previews/management';
import { useOutletMaintenance } from '@constants/OutletContextEntityPages';

const PreviewMaintenanceHistory = () => {
  const { entity } = useOutletMaintenance();

  return <PreviewFormMaintenanceHistory entity={entity} />;
};

export default PreviewMaintenanceHistory;
