import React, { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { AssetUploaderImage } from '@components/common';
import { useIsCreatePage, useIsEditPage, useModalState } from '@hooks';
import { CarouselModal, ImageContainer } from '@shared/UI';
import { createUrlObject } from '@shared/utils/functions';
import { Col, Row, Space } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { CommonForm } from '../CommonForm';
import { DraggableContainer } from './DraggableContainer';

import type { FileWithID } from '@interfaces/interfaces';
import type { WorkStepImageResultModel } from '@model/management/Maintenance/WorkStepImageResult.model';
import type { SwiperProps } from 'swiper/react/swiper-react';

interface MaintenanceImagesProps {
  images?: (FileWithID | FileWithID<WorkStepImageResultModel>)[] | null;
  hiddenUploader?: boolean;
  hiddenLabel?: boolean;
}

const { Item } = CommonForm;

const createImageUrl = (images?: (FileWithID | FileWithID<WorkStepImageResultModel>)[] | null) => {
  const imagePaths: Array<string> = [];

  images &&
    images.forEach((img) => {
      if (img.file instanceof File) {
        imagePaths.push(createUrlObject(img.file));
      } else {
        imagePaths.push(img.file.imageLink);
      }
    });

  return imagePaths;
};

function MaintenanceImages(props: MaintenanceImagesProps) {
  const { images, hiddenUploader, hiddenLabel } = props;

  const { t } = useTranslation();

  const sliderModal = useModalState();

  const [initialSlide, setInitialSlide] = useState(0);

  const isEdit = useIsEditPage();

  const isCreate = useIsCreatePage();

  const handleImageClick = (index: number) => {
    setInitialSlide(index);

    sliderModal.actions.onOpen();
  };

  const renderImages = () => {
    if (!images || !images.length) return null;

    return images.map((img, idx) => {
      const { file, id } = img;

      if (file instanceof File) {
        return (
          <Col key={id}>
            <DraggableContainer
              draggable={false}
              file={img}
              index={idx}
              canDelete={isEdit || isCreate}
              onClickImage={() => handleImageClick(idx)}
              src={file}
            />
          </Col>
        );
      }

      const src = `${file.imageLink}`;
      return (
        <Col key={file.maintenanceImageListResultsId}>
          <DraggableContainer
            draggable={false}
            file={img}
            index={idx}
            canDelete={false}
            onClickImage={() => handleImageClick(idx)}
            src={src}
          />
        </Col>
      );
    });
  };

  const renderPlugs = useCallback(() => {
    if (images && images?.length >= 5) return null;

    const plugLength = images && images.length > 0 ? 5 - images.length : 5;

    const arrayPlug = new Array(plugLength).fill(0).map(() => uuidv4());

    return arrayPlug.map((itemId) => (
      <Col key={itemId}>
        <ImageContainer />
      </Col>
    ));
  }, [images]);

  const swiperConfig: SwiperProps = {
    initialSlide,
  };

  return (
    <>
      <Space align="start">
        <Item label={!hiddenLabel ? t('titles.Uploaded_Images') : null}>
          <Row gutter={[5, 5]}>
            <DndProvider backend={HTML5Backend}>{renderImages()}</DndProvider>
            {renderPlugs()}
          </Row>
        </Item>
        {!hiddenUploader && (
          <Item name="images">
            <AssetUploaderImage />
          </Item>
        )}
      </Space>
      <CarouselModal
        images={createImageUrl(images)}
        open={sliderModal.isOpen}
        onCancel={sliderModal.actions.onClose}
        swiperConfig={swiperConfig}
      />
    </>
  );
}

export { MaintenanceImages };
