import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getRoles } from '@store/actions/user/Role';
import { selectRoleList } from '@store/selectors/users/Role';

function EntryRole() {
  useSetPageInfo({
    defaultPagePath: paths.roles.default,
    pageTitle: 'pages_plural.Roles',
  });

  const roles = useAppSelector(selectRoleList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!roles.length) {
      dispatch(getRoles());
    }
  }, []);

  return <Outlet />;
}

export default EntryRole;
