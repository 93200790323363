import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getProfile } from '@store/actions/profile/Profile';
import { selectProfile } from '@store/selectors/profile/Profile';

import type { IOutletProfile } from '@constants/OutletContextEntityPages';
import type { ProfileModel } from '@model/profile/Profile';

function Profile() {
  useSetPageInfo(
    {
      defaultPagePath: paths.profile.tabs.general.default,
      pageTitle: 'pages_single.Profile',
    },
    {
      copyTabs: true,
    }
  );

  const profile = useAppSelector(selectProfile);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!profile) {
      dispatch(getProfile());
    }
  }, []);

  if (!profile) {
    return <Loader />;
  }

  return <Outlet context={{ entity: profile as ProfileModel } as IOutletProfile} />;
}

export default Profile;
