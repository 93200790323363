import { v4 as uuidv4 } from 'uuid';

import type { MultiChoiceForWorkStepModel } from '@model/management/Maintenance';
import type { CheckStepItemOption } from '../../types';

export const mapWorkStepOptionsToCheckStepOptions = (
  workStepOptions: MultiChoiceForWorkStepModel[]
): CheckStepItemOption[] => {
  return workStepOptions.map((option): CheckStepItemOption => {
    return {
      title: option.optionText,
      rowId: option.rowId,
      checked: false,
      hasError: false,
      isUpdated: false,
      isNew: false,
      isRemoved: false,
      externalId: option.multipleChoiceListForWorkStepsId,
      uuid: uuidv4(),
      value: option.optionText,
    };
  });
};
