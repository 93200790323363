import type { RootState } from '@store/rootReducer';

export const selectVersionState = (state: RootState) => state.VersionReducer;

export const selectVersionStatus = (state: RootState) => selectVersionState(state).versionStatus;

export const selectVersionApp = (state: RootState) => selectVersionState(state).versionApp;

export const selectVersionApi = (state: RootState) => selectVersionState(state).versionApi;

export const selectVersionError = (state: RootState) => selectVersionState(state).versionError;
