import { createAsyncThunk } from '@reduxjs/toolkit';
import { CheckOutRepository } from '@repositories';

import type { CheckOutModel, CreateCheckOutDto, UpdateCheckOutDto } from '@model/properties/CheckOut';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getCheckOuts = createAsyncThunk<CheckOutModel[], void, { rejectValue: string }>(
  'CheckOut/getCheckOuts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CheckOutRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getCheckOutById = createAsyncThunk<CheckOutModel, string, { rejectValue: string }>(
  'CheckOut/getCheckOutById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await CheckOutRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addCheckOut = createAsyncThunk<
  CheckOutModel,
  CreateCheckOutDto,
  { rejectValue: string }
>('CheckOut/addCheckOut', async (dto, { rejectWithValue }) => {
  try {
    const response = await CheckOutRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateCheckOut = createAsyncThunk<
  { CheckOuts: CheckOutModel[]; checkOut: CheckOutModel },
  UpdateCheckOutDto,
  { rejectValue: string }
>('CheckOut/updateCheckOut', async (dto, { rejectWithValue }) => {
  try {
    const response = await CheckOutRepository.update(dto);
    const responseList = await CheckOutRepository.fetchAll();

    return { CheckOuts: responseList.resultObject, checkOut: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeCheckOutById = createAsyncThunk<
  CheckOutModel[],
  number[],
  { rejectValue: string }
>('CheckOut/removeCheckOutById', async (ids, { rejectWithValue }) => {
  try {
    await CheckOutRepository.removeById(ids);
    const response = await CheckOutRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
