import { api } from '@services/Axios';

import type {
  AsAtBrandModel,
  CreateAsAtBrandDto,
  UpdateAsAtBrandDto,
} from '@model/properties/AsAt/Brand';
import type { ApiResponse } from '@services/Axios';

const AsAtBrandEndpoint = '/NonCurrAssetCardBrands';

const AsAtBrandRepository = {
  async fetchAll() {
    const response: ApiResponse<AsAtBrandModel[]> = await api.get(
      `${AsAtBrandEndpoint}/GetNonCurrAssetCardBrandsList`
    );

    return response.data;
  },
  async add(dto: CreateAsAtBrandDto) {
    const response: ApiResponse<AsAtBrandModel> = await api.post(
      `${AsAtBrandEndpoint}/AddNonCurrAssetCardBrand`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<AsAtBrandModel> = await api.get(
      `${AsAtBrandEndpoint}/GetNonCurrAssetCardBrandById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdateAsAtBrandDto) {
    const response: ApiResponse<AsAtBrandModel> = await api.post(
      `${AsAtBrandEndpoint}/UpdateNonCurrAssetCardBrand`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${AsAtBrandEndpoint}/RemoveByIdList`, {
      nonCurrAssetCardBrandIds: ids,
    });

    return response.data;
  },
};

export { AsAtBrandRepository };
