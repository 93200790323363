import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { NavigateOptions } from 'react-router/dist/lib/context';

export type AppNavigateOptions = Omit<NavigateOptions, 'state'> & {
  state?: {
    prevPath: string;
    [key: string]: any;
  };
};

function useAppNavigate() {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const appNavigate = useCallback(
    (path: string, options?: AppNavigateOptions) => {
      navigate(path, {
        state: {
          prevPath: pathname,
          ...options?.state,
        },
        ...options,
      });
    },
    [navigate]
  );

  return appNavigate;
}

export { useAppNavigate };
