import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MaintenanceContext } from './MaintenanceContext';

import type { MaintenanceContextProps, MaintenanceContextValue } from '../_utils';

export const MaintenanceProvider = (props: MaintenanceContextProps) => {
  const { children, checkedMaintenances, clearChecked } = props;

  const { t } = useTranslation();

  const checkedItemsNumber = checkedMaintenances.length;

  const toolbarDescription = `${checkedItemsNumber} ${
    checkedItemsNumber > 1 ? t('pages_plural.PaIs') : t('pages_single.PaI')
  }`;

  const value: MaintenanceContextValue = useMemo(
    () => ({
      checkedMaintenances,
      clearChecked,
      toolbarDescription,
      checkedItemsNumber,
    }),
    [checkedMaintenances, clearChecked, toolbarDescription, checkedItemsNumber]
  );

  return <MaintenanceContext.Provider value={value}>{children}</MaintenanceContext.Provider>;
};
