import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectCostCenterStatus } from '@store/selectors/properties/CostCenter';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { CostCenterModel } from '@model/properties/CostCenter';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormCostCenter(props: CommonPreviewProps<CostCenterModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusCostCenter = useAppSelector(selectCostCenterStatus);

  return (
    <CommonForm
      isPreview
      loading={statusCostCenter === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.costCenterCode}</PreviewField>
        </Item>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormCostCenter };
