import React, { useEffect } from 'react';

import { CommonForm } from '@components/common';
import { useAppDispatch, useAppSelector, useIsCreatePage } from '@hooks';
import { Input } from '@shared/UI';
import { checkStoreStatuses } from '@shared/utils/functions';
import { getPaIsTypeName } from '@store/actions/management/PaI';
import { selectAssetStatus } from '@store/selectors/management/Asset';
import { selectAssetPartsStatuses } from '@store/selectors/management/AssetParts';
import { selectPaIStatus, selectPaIsTypeNameListAllRecords } from '@store/selectors/management/PaI';

import { Steps } from '../Asset/Steps';
import { PartsList } from './PartsList';

import type { CommonFormProps } from '@components/common';
import type { CreateAssetPartDto } from '@model/management/AssetParts';

const { useForm, Item, CaseField } = CommonForm;

export function FormAssetParts(props: CommonFormProps<CreateAssetPartDto>) {
  const { onFinish, initialValues, ...restProps } = props;

  const dispatch = useAppDispatch();

  const isCreate = useIsCreatePage();

  const [form] = useForm<CreateAssetPartDto>();

  const paiList = useAppSelector(selectPaIsTypeNameListAllRecords);

  const assetStatus = useAppSelector(selectAssetStatus);
  const { statusCreate, statusUpdate } = useAppSelector(selectAssetPartsStatuses);

  const paiStatus = useAppSelector(selectPaIStatus);

  useEffect(() => {
    if (!paiList.length) {
      dispatch(getPaIsTypeName());
    }
  }, []);

  const isLoading = checkStoreStatuses([paiStatus, statusCreate, assetStatus, statusUpdate]);

  const handleFinish = (dto: CreateAssetPartDto) => {
    onFinish?.(dto);
  };

  const additionalHeader = isCreate ? <Steps /> : null;

  const initial =
    Object.keys(initialValues || {}).length > 0
      ? initialValues
      : ({
          mpmodel: [
            {
              partsAndInventoriesId: undefined,
            },
          ],
        } as any as CreateAssetPartDto);

  return (
    <CommonForm
      form={form}
      loading={isLoading}
      onFinish={handleFinish}
      container={{
        style: {
          maxWidth: '100%',
        },
      }}
      initialValues={initial}
      alignAdditionalHeader="left"
      additionalHeader={additionalHeader}
      {...restProps}
    >
      <CaseField md={12} xl={10}>
        <Item hidden name="nonCurrAssetId">
          <Input />
        </Item>
        <PartsList />
      </CaseField>
    </CommonForm>
  );
}
