import { Modal } from 'antd';
import cl from 'classnames';

import type { ModalFuncProps } from 'antd/lib/modal/Modal';

import './Confirm.scss';

const { confirm } = Modal;

function ConfirmModal(props: ModalFuncProps) {
  const { className, okButtonProps, cancelButtonProps, ...rest } = props;

  const classes = cl('custom-confirm-modal', className);

  return confirm({
    className: classes,
    icon: null,
    centered: true,
    okButtonProps: {
      className: 'custom-confirm-modal-button-ok',
      ...okButtonProps,
    },
    cancelButtonProps: {
      className: 'custom-confirm-modal-button-cancel',
      ...cancelButtonProps,
    },
    ...rest,
  });
}

export { ConfirmModal as confirmModal };
