import React from 'react';

import { List as AntdList } from 'antd';
import cl from 'classnames';

import type { ListProps as AntdListProps } from 'antd';

import './List.scss';

const { Item } = AntdList;

export interface ListProps<T> extends Omit<AntdListProps<T>, 'className'> {
  className?: string | string[];
}

export interface ListInterface {
  Item: typeof Item;
}

interface DefaultListInterface extends ListInterface {
  <T>(props: ListProps<T>): React.ReactElement<ListProps<T>>;
}

const List: DefaultListInterface = (props) => {
  const { className, pagination, ...rest } = props;

  const listClasses = cl('custom-list', className);

  return (
    <AntdList
      {...rest}
      className={listClasses}
      pagination={
        pagination
          ? {
              position: 'bottom',
              itemRender: (page, type, element) => {
                if (type === 'jump-prev') {
                  return element;
                }
                if (type === 'jump-next') {
                  return element;
                }

                if (type === 'next') {
                  return (
                    <a className="custom-list__pagination--next custom-list__pagination--button">
                      {element}
                    </a>
                  );
                }
                if (type === 'prev') {
                  return (
                    <a className="custom-list__pagination--prev custom-list__pagination--button">
                      {element}
                    </a>
                  );
                }
                return page;
              },
              showSizeChanger: false,
              showQuickJumper: false,
              ...pagination,
            }
          : undefined
      }
    />
  );
};

List.Item = Item;

export { List };
