import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getCompanyById } from '@store/actions/user/Company';
import { selectCompany } from '@store/selectors/users/Company';

import type { IOutletCompany } from '@constants/OutletContextEntityPages';
import type { CreateCompanyDto } from '@model/users/Company';

function Company() {
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();
  const company = useAppSelector(selectCompany);

  const condition = !company || company.companyId !== +decryptedId;

  useSetPageInfo({
    defaultPagePath: paths.companies.default,
    pageTitle: 'pages_single.Company',
  });

  useEffect(() => {
    if (condition) {
      dispatch(getCompanyById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateCompanyDto = {
    name: company.name,
    companyCode: company.companyCode,
    cityId: company.cityId,
    countryId: company.city.countryId,
    address: company.address,
    description: company.description,
    contactName: company.contactName,
    phone: company.phone,
    taxNumber: company.taxNumber,
    taxOffice: company.taxOffice,
    color: company.color,
  };

  return <Outlet context={{ initialValues, entity: company } as IOutletCompany} />;
}

export default Company;
