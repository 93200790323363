import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormTitle } from '@components/views/Forms/users';
import { useOutletTitle } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { updateTitle } from '@store/actions/user/Title';

import type { CreateTitleDto } from '@model/users/Title';

function Edit() {
  const { entity, initialValues } = useOutletTitle();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (dto: CreateTitleDto) => {
    dispatch(updateTitle({ ...dto, userTitleId: entity.userTitleId }))
      .unwrap()
      .then(({ title }) => {
        notificationController.success({
          description: `"${title.title}" is updated`,
          message: 'Successful',
        });

        navigate(paths.title.default);
      });
  };

  return (
    <FormTitle
      initialValues={initialValues}
      onFinish={onFinish}
      header={{
        title: entity.title,
      }}
    />
  );
}

export default Edit;
