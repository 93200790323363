import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRepository } from '@repositories';

import type {
  CreateUserDto,
  FilterUserDto,
  UpdateUserDto,
  UpdateUserParentDto,
  UserModel,
} from '@model/users/User';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer';

// region --- Get Actions

export const getUsers = createAsyncThunk<UserModel[], void, { rejectValue: string }>(
  'User/getUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);
export const getUsersByFilter = createAsyncThunk<
  { users: UserModel[]; userFilters: FilterUserDto },
  FilterUserDto,
  { rejectValue: string }
>('User/getUsersByFilter', async (dto, { rejectWithValue }) => {
  try {
    const response = await UserRepository.fetchByFilter(dto);
    return { users: response.resultObject, userFilters: dto };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// region --- Greate Actions

export const addUser = createAsyncThunk<
  { usersList: UserModel[]; createdUser: UserModel },
  CreateUserDto
>('User/addUser', async (dto, { rejectWithValue }) => {
  try {
    const createdUser = await UserRepository.add(dto);
    const response = await UserRepository.fetchAll();

    return {
      usersList: response.resultObject,
      createdUser: createdUser.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// region --- Get by Id Actions

export const getUserById = createAsyncThunk<UserModel, string>(
  'User/getUserById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await UserRepository.fetchById(id);
      return res.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// region --- Update Actions

export const updateUser = createAsyncThunk<
  { users: UserModel[]; user: UserModel },
  UpdateUserDto,
  { state: RootState }
>('User/updateUser', async (dto, { rejectWithValue, getState }) => {
  try {
    const res = await UserRepository.update(dto);

    const { resultObject } = res;

    const { usersList } = getState().UserReducer;

    const users = usersList.map((user) =>
      user.userId === resultObject.userId ? resultObject : user
    );

    return { users, user: resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const updateUserParentRelations = createAsyncThunk<UserModel[], UpdateUserParentDto>(
  'User/updateUserParentRelations',
  async (dto, { rejectWithValue }) => {
    try {
      await UserRepository.updateParentRelations(dto);
      const response = await UserRepository.fetchAll();
      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// region --- Remove Actions

export const removeUserById = createAsyncThunk<UserModel[], number[], { state: RootState }>(
  'User/removeUserById',
  async (ids, { rejectWithValue }) => {
    try {
      await UserRepository.removeById(ids);

      const response = await UserRepository.fetchAll();
      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion ---
