import { createSlice } from '@reduxjs/toolkit';
import {
  addAssetParts,
  deleteAssetParts,
  getAssetPartsByAssetId,
  updateAssetParts,
} from '@store/actions/management/AssetParts';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AssetPartModel } from '@model/management/AssetParts';

interface AssetPartsState {
  entities: AssetPartModel[];

  status: LoadingStatus;

  statusUpdate: LoadingStatus;

  statusCreate: LoadingStatus;

  currentAssetId: null | number;
  error: string | null;
}

const initialState: AssetPartsState = {
  entities: [],

  status: 'idle',

  statusCreate: 'idle',

  statusUpdate: 'idle',

  currentAssetId: null,

  error: null,
};

const AssetPartsSlice = createSlice({
  name: 'AssetParts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssetPartsByAssetId.pending, (state) => {
        state.error = null;
        state.status = 'pending';
      })
      .addCase(getAssetPartsByAssetId.fulfilled, (state, { payload }) => {
        state.entities = payload.parts;
        state.currentAssetId = payload.id;
        state.status = 'idle';
      })
      .addCase(getAssetPartsByAssetId.rejected, (state, { payload }) => {
        if (payload) {
          state.error = payload;
        }
        state.status = 'idle';
      });
    builder
      .addCase(addAssetParts.pending, (state) => {
        state.error = null;
        state.statusCreate = 'pending';
      })
      .addCase(addAssetParts.fulfilled, (state, { payload }) => {
        state.statusCreate = 'idle';
        state.entities = payload;
      })
      .addCase(addAssetParts.rejected, (state, { payload }) => {
        if (payload) {
          state.error = payload;
        }
        state.statusCreate = 'idle';
      });

    builder
      .addCase(updateAssetParts.pending, (state) => {
        state.error = null;
        state.statusUpdate = 'pending';
      })
      .addCase(updateAssetParts.fulfilled, (state, { payload }) => {
        state.statusUpdate = 'idle';
        state.entities = payload;
      })
      .addCase(updateAssetParts.rejected, (state, { payload }) => {
        if (payload) {
          state.error = payload;
        }
        state.statusUpdate = 'idle';
      });
    builder
      .addCase(deleteAssetParts.pending, (state) => {
        state.error = null;
        state.statusUpdate = 'pending';
      })
      .addCase(deleteAssetParts.fulfilled, (state, { payload }) => {
        state.statusUpdate = 'idle';
        state.entities = payload;
      })
      .addCase(deleteAssetParts.rejected, (state, { payload }) => {
        if (payload) {
          state.error = payload;
        }
        state.statusUpdate = 'idle';
      });
  },
});

export const AssetPartsReducer = AssetPartsSlice.reducer;
