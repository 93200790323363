import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormAssetProperty } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletAssetProperty } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateAsAtProperty } from '@store/actions/properties/AsAt/Property';

import type { CreateAsAtPropertyDto } from '@model/properties/AsAt/Property';

function EditAssetProperty() {
  const { initialValues, entity } = useOutletAssetProperty();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { assetAttrAccess } = useGetAccess();

  const onFinish = (dto: CreateAsAtPropertyDto) => {
    dispatch(
      updateAsAtProperty({
        ...dto,
        nonCurrAssetCardPropertyId: entity.nonCurrAssetCardPropertyId,
      })
    )
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.AsAtProperty.name);
        navigate(paths.assetAttribute.tabs.property.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <CheckAccess hasAccess={assetAttrAccess.edit} redirectTo={paths.assetAttribute.tabs.property.default}>
      <FormAssetProperty
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditAssetProperty;
