import type { ActionFunction, CheckStepItem, CheckStepItemOption } from '../../types';

export const onRemoveOption: ActionFunction<{
  step: CheckStepItem;
  option: CheckStepItemOption;
}> = ({ step, option }, { setState }) => {
  setState((prevState) => {
    return prevState.map((s) => {
      if (s.uuid === step.uuid) {
        return {
          ...s,
          optionsList: s.optionsList
            .filter((o) => o.uuid !== option.uuid)
            .map((o, idx) => ({
              ...o,
              isUpdated: true,
              rowId: idx + 1,
            })),
        };
      }

      return s;
    });
  });
};
