import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getBatchPaIDTO } from '@components/views/Tables/managment/PartAndInventoryV2/_utils';
import { useAppDispatch } from '@hooks';
import { notificationController } from '@services/Notifications';
import { ApiNotifications } from '@services/Notifications/adapters';
import { BaseModal } from '@shared/UI';
import { batchUpdatePaI } from '@store/actions/management/PaI';

import { usePaIToolbarContext } from '../../../../PaIToolbarContext';

import type { BatchUpdatePaIsDto } from '@model/management/PaI';

import './SiteModal.scss';

interface SiteModalProps {
  isOpen: boolean;

  onClose: () => void;

  siteId: number | null;
}

export const SiteModal = memo((props: SiteModalProps) => {
  const { isOpen, onClose, siteId } = props;

  const { selectedDescription, selectedPaIs, clearSelected } = usePaIToolbarContext();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleOk = async () => {
    const DTO: BatchUpdatePaIsDto = getBatchPaIDTO(selectedPaIs, { siteId });

    try {
      onClose();
      await dispatch(batchUpdatePaI(DTO)).unwrap();
      clearSelected();

      notificationController.success({
        description: t('notifications.Changed', { name: t('titles.Site') }),
      });
    } catch (e) {
      ApiNotifications.error(e);
    }
  };

  return (
    <BaseModal
      centered
      className="assignee-change-confirm"
      open={isOpen}
      onCancel={onClose}
      onOk={handleOk}
      title={t('titles.Change_With_Name', { name: t('titles.Site') })}
      okText={t('titles.Confirm')}
      size="small"
      closable={false}
    >
      <span>
        <Trans i18nKey="common.Modal_Change_Site">
          Do you want to change a site for
          <span className="assignee-change-confirm-name">{{ name: selectedDescription }}</span>?
        </Trans>
      </span>
    </BaseModal>
  );
});
