import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { updatePageInfo } from '@store/actions/extra/Local';
import { selectPageTabs } from '@store/selectors/extra/Local';

import { useAppDispatch, useAppSelector } from '../redux-hooks';

import type { IPageInfo } from '@constants/pageInfo';

function useSetPageInfo(
  info: Omit<IPageInfo, 'tabs'> & { tabs?: IPageInfo['tabs'] },
  options?: { copyTabs?: boolean; clearTabs?: boolean }
) {
  const dispatch = useAppDispatch();
  const tabs = useAppSelector(selectPageTabs);
  const { pathname } = useLocation();

  useEffect(() => {
    let isTabs = null;

    if (info.tabs) {
      isTabs = info.tabs;
    } else if (options?.copyTabs) {
      isTabs = tabs;
    }

    dispatch(updatePageInfo({ ...info, tabs: isTabs }));
  }, [pathname]);
}

export default useSetPageInfo;
