import { useAppSelector } from '@hooks';
import {
  selectAssetAttrRoleAccess,
  selectAssetRoleAccess,
  selectContractRoleAccess,
  selectCostCenterRoleAccess,
  selectCountPlanRoleAccess,
  selectDepartmentRoleAccess,
  selectExitTypeRoleAccess,
  selectMaintenanceAttrRoleAccess,
  selectPaIAttrRoleAccess,
  selectPaIRoleAccess,
  selectSiteRoleAccess,
  selectUserRoleAccess,
  selectVendorRoleAccess,
} from '@store/selectors/profile/Profile';

export function useGetAccess() {
  const vendorAccess = useAppSelector(selectVendorRoleAccess);

  const paiAccess = useAppSelector(selectPaIRoleAccess);

  const assetAttrAccess = useAppSelector(selectAssetAttrRoleAccess);

  const paiAttrAccess = useAppSelector(selectPaIAttrRoleAccess);

  const maintenanceAttrAccess = useAppSelector(selectMaintenanceAttrRoleAccess);

  const departmentAccess = useAppSelector(selectDepartmentRoleAccess);

  const siteAccess = useAppSelector(selectSiteRoleAccess);

  const contractAccess = useAppSelector(selectContractRoleAccess);

  const exitTypeAccess = useAppSelector(selectExitTypeRoleAccess);

  const costCenterAccess = useAppSelector(selectCostCenterRoleAccess);

  const userAccess = useAppSelector(selectUserRoleAccess);

  const assetAccess = useAppSelector(selectAssetRoleAccess);

  const countPlanAccess = useAppSelector(selectCountPlanRoleAccess);

  return {
    vendorAccess,
    paiAccess,
    assetAttrAccess,
    paiAttrAccess,
    maintenanceAttrAccess,
    departmentAccess,
    siteAccess,
    contractAccess,
    exitTypeAccess,
    costCenterAccess,
    userAccess,
    assetAccess,
    countPlanAccess,
  };
}
