import React from 'react';

import { Arrow } from '@icon/icon-components';
import { Loader } from '@shared/UI';
import { TreeSelect as AntdTreeSelect } from 'antd';
import cl from 'classnames';

import type { TreeSelectProps as AntdTreeSelectProps } from 'antd';

import './TreeSelect.scss';

export interface TreeSelectProps<TD = any, TO = any> extends AntdTreeSelectProps<TD, TO> {}

const TreeSelect = <TD, TO>(props: TreeSelectProps<TD, TO>) => {
  const {
    className,
    popupClassName,
    showArrow = true,
    allowClear = true,
    loading,
    ...rest
  } = props;

  const treeSelectClasses = cl('custom-tree-select', className);

  const treeSelectDropdownClasses = cl('custom-tree-select-dropdown', popupClassName);

  return (
    <AntdTreeSelect
      loading={loading}
      allowClear={allowClear}
      showArrow={showArrow}
      suffixIcon={!loading ? <Arrow /> : <Loader sizeIcon={20} />}
      popupClassName={treeSelectDropdownClasses}
      className={treeSelectClasses}
      {...rest}
    />
  );
};

export { TreeSelect };
