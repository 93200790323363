import React from 'react';

import { FormGeneralV2 } from '@components/views/Forms/managment';
import { useOutletMaintenance } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateMaintenanceGeneral } from '@store/actions/management/Maintenance';

import type { CommonFormProps } from '@components/common';
import type { CreateMaintenanceGeneralV2Dto } from '@model/management/Maintenance';

const EditMaintenanceGeneral = () => {
  const { initialValuesGeneral, entity } = useOutletMaintenance();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const header: CommonFormProps['header'] = {
    title: entity.title,
  };

  const onFinish = (dto: CreateMaintenanceGeneralV2Dto) => {
    const normalizeDto = {
      ...dto,
      maintenanceId: entity.maintenanceId,
    };

    dispatch(updateMaintenanceGeneral(normalizeDto))
      .unwrap()
      .then((res) => {
        ApiNotifications.update(res.title);
        navigate(paths.maintenance.default);
      })
      .catch((e) => {
        ApiNotifications.error(e);
      });
  };

  return <FormGeneralV2 header={header} onFinish={onFinish} initialValues={initialValuesGeneral} />;
};

export default EditMaintenanceGeneral;
