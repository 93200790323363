import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Select } from '@shared/UI';
import { checkFilterValues, compare } from '@shared/utils/functions';
import { getCompanies, getCompaniesByFilter } from '@store/actions/user/Company';
import { 
  selectCompaniesFilteredList,
  selectCompaniesFilters,
  selectCompaniesList, 
  selectCompanyStatus
 } from '@store/selectors/users/Company';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { CompanyModel, FilterCompanyDto } from '@model/users/Company';

const columns: ColumnsTableType<CompanyModel> = [
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
  {
    title: <Translation>{(t) => t('titles.TXN')}</Translation>,
    dataIndex: 'taxNumber',
    key: 'taxNumber',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Phone')}</Translation>,
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.City')}</Translation>,
    dataIndex: ['city', 'name'],
    key: 'city.name',
    ellipsis: true,
    sorter: (a, b) => compare(a.city.name, b.city.name),
  },
];

const Companies = () => {
  const dispatch = useAppDispatch();

  const {t} = useTranslation();

  const companies = useAppSelector(selectCompaniesList);
  const filteredListCompany = useAppSelector(selectCompaniesFilteredList);
  const companyStatus = useAppSelector(selectCompanyStatus);
  const filters = useAppSelector(selectCompaniesFilters);

  const csvData = useMemo(
    () =>
      companies.map((company) => ({
        name: company.name,
        taxNumber: company.taxNumber,
        phone: company.phone,
        city: company.city.name,
        taxOffice: company.taxOffice,
        companyCode: company.companyCode,
        address: company.address,
        email: company.contactName,
      })),
    [companies]
  );

  const dataSource = useMemo(
    () => (Boolean(filteredListCompany.length) ? filteredListCompany : companies),
    [filteredListCompany, companies]
  );

  const taxNumberArray = companies.map(company => ({
    label: company.taxNumber, value: company.taxNumber
  })).filter(taxNumber => taxNumber.value);

  const taxOfficesArray = companies.map(company => ({
    label: company.taxOffice, value: company.taxOffice
  })).filter(taxOffice => taxOffice.value);

  const addressArray = companies.map(company => ({
    label: company.address, value: company.address
  }));

  const uniqueAddressArray = useMemo(
    () =>
      Array.from(
        new Map(addressArray.map((item) => [item.value, item])),
        ([, value]) => value
      ),
    [addressArray]
  );

  const filterProps = {
    items: [
      {
        label: t('titles.Name'),
        name: 'namesArray',
        item: (
          <Select
            options={companies}
            maxTagCount={4}
            mode="tags"
            fieldNames={{ label: 'name', value: 'name' }}
          />
        ),
      },
      {
        label: t('titles.Code'),
        name: 'companyCodesArray',
        item: (
          <Select
            options={companies}
            maxTagCount={4}
            mode="tags"
            fieldNames={{ label: 'companyCode', value: 'companyCode' }}
          />
        ),
      },
      {
        label: t('titles.Address'),
        name: 'addressesArray',
        item: (
          <Select
            options={uniqueAddressArray}
            maxTagCount={4}
            mode="tags"
          />
        ),
      },
      {
        label: t('titles.TXN'),
        name: 'taxNumbersArray',
        item: (
          <Select
            options={taxNumberArray}
            maxTagCount={4}
            mode="tags"
          />
        ),
      },
      {
        label: t('titles.Tax_Office'),
        name: 'taxOfficesArray',
        item: (
          <Select
            options={taxOfficesArray}
            maxTagCount={4}
            mode="tags"
          />
        ),
      },
    ],
    applyAction: (values: FilterCompanyDto) => {
      if (!checkFilterValues(values)) {
        getCompaniesList();
        return;
      }

      dispatch(getCompaniesByFilter(values));
    },
    resetAction: () => {
      if (checkFilterValues(filters)) {
        getCompaniesList();
      }
    },
    initialValuesForm: {
      namesArray: filters?.namesArray,
      companyCodesArray: filters?.companyCodesArray,
      addressesArray: filters?.addressesArray,
      taxNumbersArray: filters?.taxNumbersArray,
      taxOfficesArray: filters?.taxOfficesArray,
    },
  };

  function getCompaniesList() {
    dispatch(getCompanies());
  }

  return (
    <BasicTablePage
      table={{
        loading: companyStatus === 'pending',
        dataSource: dataSource,
        columns: columns,
        rowKey: (record) => record.companyId,
        dataSourceName: t('pages_plural.Companies'),
      }}
      header={{
        csvConfig: {
          data: csvData,
          headers: [
            {label: 'Name', key: 'name'},
            {label: 'Tax Number', key: 'taxNumber'},
            {label: 'Phone', key: 'phone'},
            {label: 'City', key: 'city'},
            {label: 'Tax Office', key: 'taxOffice'},
            {label: 'Company Code', key: 'companyCode'},
            {label: 'Address', key: 'address'},
            {label: 'Email', key: 'email'},
          ],
          fileName: 'companies.csv',
        },
        filterProps,
        titleRedirectButton: t('titles.New_Item', {name: t('pages_single.Company')}),
      }}
    />
  );
};

export {Companies};
