import { createAsyncThunk } from '@reduxjs/toolkit';

import type { IPageInfo, TabType } from '@constants/pageInfo';
import type { RootState } from '@store/rootReducer';

export const setTabs = createAsyncThunk<TabType[], TabType[]>(
  'Local/changeDefaultPathname',
  (tabs) => {
    return tabs;
  }
);

export const updatePageInfo = createAsyncThunk<
  IPageInfo | null,
  IPageInfo,
  {
    state: RootState;
  }
>(
  'Local/updatePageInfo',
  (info) => {
    return info;
  },
  {
    condition: (info, { getState }) => {
      const { currentRequestId, status } = getState().LocalReducer;

      if (status === 'pending' || currentRequestId !== null) {
        return false;
      }
    },
  }
);

export const updateActivePage = createAsyncThunk('LocalePage/changeTablePage', (page: number) => {
  return page;
});
