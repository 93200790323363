import { createAsyncThunk } from '@reduxjs/toolkit';
import { AssetPartsRepository } from '@repositories';
import { identifyError } from '@shared/utils/functions';

import type {
  AssetPartModel,
  CreateAssetPartDto,
  UpdateAssetPartDto,
} from '@model/management/AssetParts';
import type { RootState } from '@store/rootReducer';

export const getAssetPartsByAssetId = createAsyncThunk<
  { parts: AssetPartModel[]; id: number },
  number | string,
  { rejectValue: string }
>('AssetParts/getAssetPartsByAssetId', async (id, { rejectWithValue }) => {
  try {
    const response = await AssetPartsRepository.getByAssetId(id);

    return { parts: response.resultObject, id: +id };
  } catch (e) {
    return rejectWithValue(identifyError(e));
  }
});

export const addAssetParts = createAsyncThunk<
  AssetPartModel[],
  CreateAssetPartDto,
  { rejectValue: string }
>('AssetParts/addAssetParts', async (dto, { rejectWithValue }) => {
  try {
    const { resultObject } = await AssetPartsRepository.add(dto);

    return resultObject;
  } catch (e) {
    return rejectWithValue(identifyError(e));
  }
});

export const updateAssetParts = createAsyncThunk<
  AssetPartModel[],
  UpdateAssetPartDto,
  { rejectValue: string }
>('AssetParts/updateAssetParts', async (dto, { rejectWithValue }) => {
  try {
    const { resultObject } = await AssetPartsRepository.update(dto);

    return resultObject;
  } catch (e) {
    return rejectWithValue(identifyError(e));
  }
});

export const deleteAssetParts = createAsyncThunk<
  AssetPartModel[],
  number[],
  { rejectValue: string; state: RootState }
>('AssetParts/deleteAssetParts', async (ids, { rejectWithValue, getState }) => {
  try {
    const entities = getState().AssetPartsReducer.entities;

    if (!ids.length) {
      return entities;
    }
    await AssetPartsRepository.delete(ids);

    return entities.filter((entity) => ids.indexOf(entity.nonCurrAssetsPartsId) === -1);
  } catch (e) {
    return rejectWithValue(identifyError(e));
  }
});
