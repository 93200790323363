export const requiredFieldMessage = 'The field is required';

export const requiredRule = (isPreview?: boolean) => ({
  required: !isPreview,
  message: requiredFieldMessage,
});

export const REQUIRED_RULE = {
  required: true,
};
