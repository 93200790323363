import { v4 as uuidv4 } from 'uuid';

import { checkStepKeys } from '../../checkStepTypes';

import type { CheckStepItem } from '../../types';

export function onCopyStep(
  state: CheckStepItem[],
  setState: (cb: (prevState: CheckStepItem[]) => CheckStepItem[]) => void,
  step: CheckStepItem
) {
  const uuid = uuidv4();
  let newStep = {
    ...step,
    uuid,
    rowId: state.length + 1,
    isNew: true,
    hasError: false,
    isActive: true,
  };

  if (step.stepKey === checkStepKeys.multiple && step.optionsList.length) {
    newStep = {
      ...newStep,
      optionsList: step.optionsList.map((option) => ({
        ...option,
        uuid: uuidv4(),
        isNew: true,
        hasError: false,
      })),
    };
  }

  setState((prevState) =>
    [...prevState, newStep].map((s) =>
      s.uuid !== uuid
        ? {
            ...s,
            isActive: false,
          }
        : s
    )
  );
}
