/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { FormAsset } from '@components/views/Forms/managment';
import { CheckAccess } from '@components/wrappers';
import { useOutletAsset } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { formatMomentDate } from '@shared/utils/functions';
import { updateAsset } from '@store/actions/management/Asset';

import type { CreateAssetDto } from '@model/management/Asset';

function EditAsset() {
  const { initialValues, entity } = useOutletAsset();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { assetAccess } = useGetAccess();

  const onFinish = (dto: CreateAssetDto) => {
    const labelList =
      dto.labelsList &&
      dto.labelsList.map((label) => ({
        label: label.label,
        labelColor: label.labelColor,
      }));

    const properties = dto.nonCurrAssetPropertyDetalisModelList?.map((property) => {
      if (typeof property.nonCurrAssetPropertyValueId === 'string') {
        if (property.isRemove) {
          return {
            nonCurrAssetCardPropertyId: property.nonCurrAssetCardPropertyId,
            value: property.value,
            isRemove: true,
          };
        }
        return {
          nonCurrAssetCardPropertyId: property.nonCurrAssetCardPropertyId,
          value: property.value,
        };
      }
      return property;
    });

    const normalizeDate: CreateAssetDto = {
      ...dto,
      invoiceDate: dto.invoiceDate ? formatMomentDate(dto.invoiceDate) : null,
      warrantyStartDate: dto.warrantyStartDate ? formatMomentDate(dto.warrantyStartDate) : null,
      warrantyEndDate: dto.warrantyEndDate ? formatMomentDate(dto.warrantyEndDate) : null,
      labelsList: labelList,
      nonCurrAssetPropertyDetalisModelList: properties || null,
    };

    dispatch(
      updateAsset({
        ...normalizeDate,
        nonCurrAssetId: entity.nonCurrAssetId,
      })
    )
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.asset.barcode);
        navigate(paths.asset.default);
      })
      .catch((error) => {
        if (typeof error === 'string') {
          ApiNotifications.error(error);
        }
      });
  };

  return (
    <CheckAccess hasAccess={assetAccess.edit} redirectTo={paths.asset.default}>
      <FormAsset
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.barcode,
        }}
      />
    </CheckAccess>
  );
}

export default EditAsset;
