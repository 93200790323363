import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Translation, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { adaptMaintenanceAttrCheckStepsToCheckSteps } from '@adapters';
import {
  AddCheckStepButton,
  CommonForm,
  DraggableLining,
  MultiplyCheckStep,
  OtherCheckStep,
  useHandleCheckStepItem,
} from '@components/common';
import { KindOfCheckStep } from '@constants/kindOfCheckStep';
import { useAppDispatch, useAppSelector, useIsCreatePage } from '@hooks';
import { TakePhotoIcon } from '@icon/icon-components';
import { Radio, Select } from '@shared/UI';
import { checkStoreStatuses } from '@shared/utils/functions';
import {
  getMaAtChecklistById,
  getMaAtChecklistsSummary,
} from '@store/actions/properties/MaAt/Checklist';
import { selectMaintenanceStatus } from '@store/selectors/management/Maintenance';
import {
  selectMaAtChecklist,
  selectMaAtChecklistsSummary,
  selectMaAtChecklistStatus,
} from '@store/selectors/properties/MaAt/Checklist';

import { MaintenanceSteps } from '../MaintenanceSteps';

import type { CommonFormProps, DefaultCommonFormProps } from '@components/common';
import type { CheckStepValue } from '@constants/checkStepItems';
import type { RadioChangeEvent } from 'antd';

const { CaseField, Item, useForm } = CommonForm;

enum ValueRadioButton {
  Step = 1,
  Checklist = 2,
}

const PhotoVariant = {
  type: KindOfCheckStep.Photo,

  title: <Translation>{(t) => t('titles.Take_Photo')}</Translation>,

  icon: <TakePhotoIcon />,
};

const FormStepAndChecklist = (
  props: CommonFormProps<CheckStepValue[]> & {
    onRemoveItemAction?: (id: number) => void;
    onRemoveOptionAction?: (stepId: number, id: number) => void;
  }
) => {
  const {
    initialValues,
    onRemoveItemAction,
    onRemoveOptionAction,
    onFinish: onFinishProps,
    ...restFormProps
  } = props;

  const [form] = useForm<CheckStepValue[]>();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // state of radio buttons
  const [typeRadio, setTypeRadio] = useState<ValueRadioButton>(ValueRadioButton.Step);

  // state of selected maintenance checklist id
  const [selectedChecklistId, setSelectedChecklistId] = useState<number | undefined>();

  // variables from global state
  const statusMaintenance = useAppSelector(selectMaintenanceStatus);

  const summaryListMaintenanceChecklists = useAppSelector(selectMaAtChecklistsSummary);
  const particularMaintenanceChecklist = useAppSelector(selectMaAtChecklist);

  const maintenanceChecklistStatus = useAppSelector(selectMaAtChecklistStatus);

  const { pathname } = useLocation();
  const isEditPage = pathname.includes('edit');

  const [errorsSteps, setErrorsSteps] = useState<
    {
      uuid: string;
      isSubmitted: boolean;
    }[]
  >([]);

  const initialValue = initialValues as CheckStepValue[] | undefined;

  // state for work steps
  const {
    state,
    actions: {
      onAddNewItem,
      onAddNewMultiplyOption,
      onMoveItem,
      onCopyItem,
      onRemoveItem,
      onRemoveMultiplyOption,
      onEditItem,
      onChangeItemTitle,
      onChangeMultiplyItemRadioText,
      setSteps,
      clearState,
    },
  } = useHandleCheckStepItem();

  const isCreate = useIsCreatePage();

  const onFinish = () => {
    // if it has errors, for all errors change isSubmitted from false to true and show errors
    if (errorsSteps.length > 0) {
      setErrorsSteps((prev) =>
        prev.map((item) => ({
          ...item,
          isSubmitted: true,
        }))
      );
      return;
    }

    if (errorsSteps.length === 0) {
      onFinishProps?.(state, form);
    }
  };

  useEffect(() => {
    clearState();
    if (initialValue && initialValue.length) {
      setSteps(initialValue);
    }
  }, []);

  useEffect(() => {
    if (typeRadio === ValueRadioButton.Checklist && particularMaintenanceChecklist) {
      const checklist = adaptMaintenanceAttrCheckStepsToCheckSteps(
        particularMaintenanceChecklist.checkListCheckSteps,
        false
      );
      setSteps(checklist);
    }
  }, [particularMaintenanceChecklist]);

  useEffect(() => {
    if (state.length === 0) {
      setErrorsSteps([]);
    } else {
      state.forEach((step) => {
        const isExist = errorsSteps.find((item) => item.uuid === step.uuid);
        if (step.title.length > 0 && isExist) {
          setErrorsSteps((prev) => prev.filter((item) => item.uuid !== step.uuid));
        }
        if (step.title.length === 0 && !isExist) {
          setErrorsSteps((prev) => [
            ...prev,
            {
              uuid: step.uuid,
              isSubmitted: false,
            },
          ]);
        }
      });
    }
  }, [state]);

  useEffect(() => {
    if (!summaryListMaintenanceChecklists.length) {
      dispatch(getMaAtChecklistsSummary());
    }
  }, []);

  useEffect(() => {
    // when selectedChecklistId has changed
    // it needs to get particular MaAtChecklist
    if (selectedChecklistId) {
      if (
        !particularMaintenanceChecklist ||
        (particularMaintenanceChecklist &&
          particularMaintenanceChecklist.checkListId !== selectedChecklistId)
      ) {
        dispatch(getMaAtChecklistById(selectedChecklistId));
      }

      if (
        particularMaintenanceChecklist &&
        particularMaintenanceChecklist.checkListId === selectedChecklistId
      ) {
        const st = adaptMaintenanceAttrCheckStepsToCheckSteps(
          particularMaintenanceChecklist.checkListCheckSteps,
          false
        );
        setSteps(st);
      }
    }
  }, [selectedChecklistId, particularMaintenanceChecklist]);

  /* ------------------------------- Handle function ------------------------------ */

  function onChangeRadio(event: RadioChangeEvent) {
    setTypeRadio(event.target.value as ValueRadioButton);
    // if typeRadio has changed then need to clear state with steps
    clearState();
    setSelectedChecklistId(undefined);
  }

  function onChangeChecklist(value: number) {
    setSelectedChecklistId(value);
  }

  /* ------------------------------- Render function ------------------------------ */

  function renderSelectChecklist() {
    if (typeRadio === ValueRadioButton.Checklist || (isEditPage && state.length === 0)) {
      return (
        <CaseField
          md={24}
          hiddenTitle
          style={{
            marginTop: 20,
          }}
        >
          <Item label={t('titles.Select_From')}>
            <Select
              placeholder={t('titles.Select_From')}
              style={{
                maxWidth: 400,
              }}
              onChange={onChangeChecklist}
              options={summaryListMaintenanceChecklists}
              fieldNames={fieldNamesSummaryChecklist}
            />
          </Item>
        </CaseField>
      );
    }
    return null;
  }

  // static constants
  const loading = checkStoreStatuses([statusMaintenance, maintenanceChecklistStatus]);

  const renderAdditionalHeader = isCreate ? <MaintenanceSteps /> : null;

  const container: DefaultCommonFormProps['container'] = {
    style: {
      maxWidth: '100%',
    },
  };

  const fieldNamesSummaryChecklist = {
    value: 'checkListId',
    label: 'checklistName',
  };

  return (
    <CommonForm
      onFinish={onFinish}
      form={form}
      loading={loading}
      additionalHeader={renderAdditionalHeader}
      alignAdditionalHeader="left"
      container={container}
      {...restFormProps}
    >
      {!initialValue ? (
        <CaseField span={24} md={24}>
          <Radio.Group onChange={onChangeRadio} value={typeRadio}>
            <Radio value={ValueRadioButton.Step}>Step</Radio>
            <Radio value={ValueRadioButton.Checklist}>Checklist</Radio>
          </Radio.Group>
        </CaseField>
      ) : null}
      {renderSelectChecklist()}
      <DndProvider backend={HTML5Backend}>
        {state.map((value, idx) => {
          const isError = errorsSteps.some(
            (error) => error.uuid === value.uuid && error.isSubmitted
          );
          return (
            <DraggableLining
              key={value.uuid}
              onActionDelete={() => {
                onRemoveItem(value.uuid);
                if (value.entityId) {
                  onRemoveItemAction?.(value.entityId);
                }
              }}
              onActionCopy={() => {
                onCopyItem(value.uuid);
              }}
              onActionEdit={() => {
                onEditItem(value.uuid);
              }}
              isFocus={value.isFocus}
              count={`${idx + 1}.`}
              index={idx}
              onMoveItem={onMoveItem}
              hiddenEditAction={value.isFocus}
            >
              {value.type === KindOfCheckStep.MultiplyChoice ? (
                <MultiplyCheckStep
                  isErrorTitle={isError}
                  multiplyCheckStep={value}
                  onChangeTitle={onChangeItemTitle}
                  onChangeRadioTitle={onChangeMultiplyItemRadioText}
                  onAddNewRadio={onAddNewMultiplyOption}
                  onRemoveOption={(parentId, key, id) => {
                    onRemoveMultiplyOption(parentId, key);
                    if (id && value.entityId) {
                      onRemoveOptionAction?.(value.entityId, id);
                    }
                  }}
                />
              ) : null}

              {value.type !== KindOfCheckStep.MultiplyChoice &&
              value.type !== KindOfCheckStep.Photo ? (
                <OtherCheckStep
                  isErrorTitle={isError}
                  otherCheckStep={value}
                  onChangeTitle={onChangeItemTitle}
                />
              ) : null}
            </DraggableLining>
          );
        })}
      </DndProvider>
      {typeRadio || isEditPage ? (
        <AddCheckStepButton
          style={{
            marginTop: 20,
          }}
          onClickVariant={onAddNewItem}
          newVariants={[PhotoVariant]}
        />
      ) : null}
    </CommonForm>
  );
};

export { FormStepAndChecklist };
