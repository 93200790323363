import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectUserStatus } from '@store/selectors/users/User';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { UserModel } from '@model/users/User';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormUser(props: CommonPreviewProps<UserModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusUser = useAppSelector(selectUserStatus);

  return (
    <CommonForm
      isPreview
      loading={statusUser === 'pending'}
      header={{
        title: `${entity.firstName} ${entity.lastName}`,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField title={t('titles.Primary_Info')}>
        <Item label={t('titles.User_Code')}>
          <PreviewField>{entity.userCode}</PreviewField>
        </Item>
        <Item label={t('titles.Registration_Number')}>
          <PreviewField>{entity.registrationNumber}</PreviewField>
        </Item>
        <Item label={t('titles.First_Name')}>
          <PreviewField>{entity.firstName}</PreviewField>
        </Item>
        <Item label={t('titles.Last_Name')}>
          <PreviewField>{entity.lastName}</PreviewField>
        </Item>
        <Item label={t('titles.Title')}>
          <PreviewField>{entity.userTitle.title}</PreviewField>
        </Item>
        <Item label={t('titles.Role')}>
          <PreviewField>
            {Boolean(entity.userRoles.length)
              ? entity.userRoles.map((item) => item.role.name)
              : null}
            {entity.roleName}
          </PreviewField>
        </Item>
        <Item label={t('pages_single.User_Group')}>
          <PreviewField>
            {Boolean(entity.userGroups.length)
              ? entity.userGroups.map((item) => item.groups.groupName)
              : null}
            {entity.roleName}
          </PreviewField>
        </Item>
      </CaseField>
      <CaseField title={t('titles.Contact_Info')}>
        <Item label={t('titles.Email')}>
          <PreviewField>{entity.userMail}</PreviewField>
        </Item>
        <Item label={t('titles.Phone')}>
          <PreviewField>{entity.phoneNumber}</PreviewField>
        </Item>
        <Item label={t('pages_single.Department')}>
          <PreviewField>{entity.department.name}</PreviewField>
        </Item>
        <Item label={t('titles.Parent_User')}>
          {/* Api returning with no parejt json even parentid is presented to be fixed */}
          <PreviewField>{entity.parentUserId}</PreviewField>
        </Item>
        <Item label={t('titles.Site')}>
          <PreviewField>{entity.site.name}</PreviewField>
        </Item>
        <Item label={t('titles.Description')}>
          <PreviewField typeField="textarea">{entity.description}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormUser };
