import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { Button } from '@shared/UI';

export const NewButton = memo(() => {
  const { t } = useTranslation();
  const navigate = useAppNavigate();

  const { assetAccess } = useGetAccess();

  const handleClick = () => {
    navigate(paths.asset.create);
  };

  return (
    <Button onClick={handleClick} key="1" type="primary" disabled={!assetAccess.create}>
      {t('titles.New_Item', { name: t('pages_single.Asset') })}
    </Button>
  );
});
