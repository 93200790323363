import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { checkSpace } from '@constants/regex';
import { useAppDispatch, useAppSelector } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { BaseForm, Button } from '@shared/UI';
import { createPassword } from '@store/actions/profile/Profile';
import { selectProfileLoading } from '@store/selectors/profile/Profile';
import { Input } from 'antd';

import type { CreatePasswordDto } from '@model/profile/Profile';

const CreatePasswordForm = () => {
  const { t } = useTranslation();

  const [form] = BaseForm.useForm<
    Omit<CreatePasswordDto, 'userIdHashed'> & { confirmPassword: string }
  >();

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const createPasswordStatus = useAppSelector(selectProfileLoading);

  const onFinish = (dto: 
    Omit<CreatePasswordDto, 'userIdHashed'> & { confirmPassword: string
  }) => {
    if(params && params.userId) 
      dispatch(createPassword({
        userIdHashed: params.userId,
        password: dto.confirmPassword,
      }))
      .unwrap()
      .then(() => {
        navigate(paths.login.default);
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <BaseForm 
      form={form} 
      className="actions" 
      onFinish={onFinish}
    >
      <BaseForm.Item
        required={false}
        name="password"
        label={t('titles.New_Password')}
        rules={[
          {
            required: true,
            min: 6,
          },
          () => ({
            validator(_, value) {
              const withoutSpace = value ? value.match(checkSpace) : null;
              if (withoutSpace) {
                return Promise.reject(t('errors.Password_Contain_Spaces'));
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input className={'input'} type='password' />
      </BaseForm.Item>

      <BaseForm.Item
          required={false}
          name="confirmPassword"
          label={t('titles.Confirm_Password')}
          dependencies={['password']}
          rules={[
            {
              required: true,
              min: 6,
            },
            ({ getFieldValue, isFieldTouched }) => ({
              validator(_, value) {
                const pass = getFieldValue('password') as string;
                const touchedPass = isFieldTouched('password');
                const touchedConfirmPass = isFieldTouched('confirmPassword');
                const withoutSpace = value ? value.match(checkSpace) : null;

                if (withoutSpace) {
                  return Promise.reject(t('errors.Password_Contain_Spaces'));
                }

                if (touchedPass && touchedConfirmPass && pass !== value) {
                  return Promise.reject(t('errors.Passwords_Not_Match'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input className={'input'} type="password" />
        </BaseForm.Item>

      <BaseForm.Item>
        <Button loading={createPasswordStatus} htmlType="submit" className="button">
          {t('titles.Confirm')}
        </Button>
      </BaseForm.Item>
    </BaseForm>
  );
};

export { CreatePasswordForm };
