import React, { memo } from 'react';

import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { ListViewType } from '@constants/listViewType';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Segment } from '@shared/UI';
import { updateAssetView } from '@store/actions/management/Asset';
import { selectAssetViewType } from '@store/selectors/management/Asset';

import type { SegmentProps } from '@shared/UI';

export type ViewSwitcherProps = {
  disabledSwitcher?: boolean;
};

const options = [
  {
    value: ListViewType.List,
    icon: <BarsOutlined/>,
  },
  {
    value: ListViewType.Card,
    icon: <AppstoreOutlined/>,
  },
];

export const ViewSwitcher = memo((props: ViewSwitcherProps) => {
  const {disabledSwitcher = false} = props;

  const view = useAppSelector(selectAssetViewType);

  const dispatch = useAppDispatch();

  const handleChange = (value: ListViewType) => {
    dispatch(updateAssetView(value))
  };

  return (
    <Segment
      options={options}
      value={view}
      onChange={handleChange as SegmentProps['onChange']}
      disabled={disabledSwitcher}
    />
  );
});
