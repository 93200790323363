import { api } from '@services/Axios';

import type {
  CreateExitTypeDto,
  ExitTypeModel,
  FilterExitTypeDto,
  UpdateExitTypeDto,
} from '@model/properties/ExitType';
import type { ApiResponse } from '@services/Axios';

const ExitTypeEndpoint = '/ExitTypes';

export const ExitTypeRepository = {
  async fetchAll() {
    const response: ApiResponse<ExitTypeModel[]> = await api.get(
      `${ExitTypeEndpoint}/GetExitTypesList`
    );

    return response.data;
  },

  async fetchAllByFilter(dto: FilterExitTypeDto) {
    const response: ApiResponse<ExitTypeModel[]> = await api.post(
      `${ExitTypeEndpoint}/GetExitTypesListByFilter`,
      dto
    );
    return response.data;
  },

  async add(dto: CreateExitTypeDto) {
    const response: ApiResponse<ExitTypeModel> = await api.post(
      `${ExitTypeEndpoint}/AddExitTypes`,
      dto
    );

    return response.data;
  },

  async fetchById(id: string) {
    const response: ApiResponse<ExitTypeModel> = await api.get(
      `${ExitTypeEndpoint}/GetExitTypeById/${id}`
    );

    return response.data;
  },

  async update(dto: UpdateExitTypeDto) {
    const response: ApiResponse<ExitTypeModel> = await api.post(
      `${ExitTypeEndpoint}/UpdateExitTypes`,
      dto
    );

    return response.data;
  },

  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${ExitTypeEndpoint}/RemoveByIdList`, {
      exitTypeIds: ids,
    });

    return response.data;
  },
};
