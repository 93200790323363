/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStepsContext } from '@components/providers/StepsProvider';
import { Steps as UiSteps } from '@shared/UI';

export const Steps = () => {
  const { step } = useStepsContext();

  const { t } = useTranslation();

  const items = [
    {
      title: t('titles.General'),
    },
    {
      title: t('titles.Parts'),
    },
  ];

  return (
    <UiSteps
      progressDot
      items={items}
      current={step}
      style={{
        maxWidth: '400px',
      }}
    />
  );
};
