import React, { useCallback, useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppDispatch, useAppSelector, useGetAccess } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { Select } from '@shared/UI';
import { checkFilterValues, compare } from '@shared/utils/functions';
import { getUsers, getUsersByFilter, updateUserParentRelations } from '@store/actions/user/User';
import {
  selectUserFilters,
  selectUsersFilteredList,
  selectUsersList,
  selectUsersListWithoutChildren,
  selectUserStatus,
} from '@store/selectors/users/User';

import type { ColumnsTableType, DragEnd } from '@components/common';
import type { FilterUserDto, UserModel } from '@model/users/User';

const columns: ColumnsTableType<UserModel> = [
  {
    title: <Translation>{(t) => t('titles.First_Name')}</Translation>,
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
    sorter: (a, b) => compare(a.firstName, b.firstName),
  },
  {
    title: <Translation>{(t) => t('titles.Last_Name')}</Translation>,
    dataIndex: 'lastName',
    key: 'lastName',
    ellipsis: true,
    sorter: (a, b) => compare(a.lastName, b.lastName),
  },
  {
    title: <Translation>{(t) => t('titles.Title')}</Translation>,
    dataIndex: ['userTitle', 'title'],
    key: 'userTitle',
    ellipsis: true,
    sorter: (a, b) => compare(a.title, b.title),
  },
  {
    title: <Translation>{(t) => t('titles.Role')}</Translation>,
    dataIndex: 'roleName',
    key: 'roleName',
    ellipsis: true,
    sorter: (a, b) => compare(a.roleName, b.roleName),
  },
  {
    title: <Translation>{(t) => t('pages_single.Department')}</Translation>,
    dataIndex: ['department', 'name'],
    key: 'department',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: ['site', 'name'],
    key: 'site',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

function Users() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const users = useAppSelector(selectUsersList);
  const userListWithoutChildren = useAppSelector(selectUsersListWithoutChildren);
  const filteredListUser = useAppSelector(selectUsersFilteredList);
  const statusUser = useAppSelector(selectUserStatus);
  const filtersUser = useAppSelector(selectUserFilters);

  const { userAccess } = useGetAccess();

  const data = useCallback(
    (users: UserModel[]) => {
      return users.map(
        ({ childUser, ...user }): UserModel => ({
          ...user,
          childUser: childUser?.length ? data(childUser) : undefined,
        })
      );
    },
    [users]
  );

  const listUsers = useMemo(() => data(users), [users]);

  const updateParent = (data: DragEnd<UserModel>) => {
    dispatch(
      updateUserParentRelations({
        fromUserIds: [data.draggingRecordId],
        toUserId: data.dropId,
        toCompanyId: data.draggingRecords.companyId,
      })
    );
  };

  const getDefaultList = useCallback(async () => {
    try {
      await dispatch(getUsers()).unwrap();
    } catch (e) {
      ApiNotifications.error(e);
    }
  }, []);

  const onSubmitFilter = async (values: FilterUserDto) => {
    const checkedValues = checkFilterValues(values);

    if (!checkedValues) {
      await getDefaultList();
    } else {
      try {
        await dispatch(getUsersByFilter(values)).unwrap();
      } catch (e) {
        ApiNotifications.error(e);
      }
    }
  };

  const csvData = useMemo(
    () =>
      listUsers.map((user) => {
        return {
          firstName: user.firstName,
          lastName: user.lastName,
          roleName: user.roleName,
          userTitle: user.userTitle.title,
          department: user.department.name,
          site: user.site.name,
        };
      }),
    [listUsers]
  );

  const dataSource = useMemo(
    () => (Boolean(filteredListUser.length) ? filteredListUser : listUsers),
    [filteredListUser, listUsers]
  );

  return (
    <BasicTablePage
      table={{
        loading: statusUser === 'pending',
        dataSource: dataSource,
        draggable: true,
        columns,
        rowKey: (record) => record.userId,
        dropAction: updateParent,
        expandable: {
          childrenColumnName: 'childUser',
        },
        dataSourceName: t('pages_plural.Users'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.User') }),
        filterProps: {
          items: [
            {
              label: t('titles.User_Code'),
              name: 'userCodesArray',
              item: (
                <Select
                  value={filtersUser?.userCodesArray}
                  maxTagCount={4}
                  mode="tags"
                  options={userListWithoutChildren}
                  fieldNames={{ label: 'userCode', value: 'userCode' }}
                />
              ),
            },
            {
              label: t('titles.Registration_Number'),
              name: 'registrationNumberArray',
              item: (
                <Select
                  value={filtersUser?.registrationNumberArray}
                  maxTagCount={4}
                  options={userListWithoutChildren}
                  mode="tags"
                  fieldNames={{
                    label: 'registrationNumber',
                    value: 'registrationNumber',
                  }}
                />
              ),
            },
            {
              label: t('titles.First_Name'),
              name: 'firstNamesArray',
              item: (
                <Select
                  value={filtersUser?.firstNamesArray}
                  maxTagCount={4}
                  options={userListWithoutChildren}
                  mode="tags"
                  fieldNames={{ label: 'firstName', value: 'firstName' }}
                />
              ),
            },
            {
              label: t('titles.Last_Name'),
              name: 'lastNamesArray',
              item: (
                <Select
                  value={filtersUser?.lastNamesArray}
                  maxTagCount={4}
                  options={userListWithoutChildren}
                  mode="tags"
                  fieldNames={{ label: 'lastName', value: 'lastName' }}
                />
              ),
            },
            {
              label: t('titles.Email'),
              name: 'userMailsArray',
              item: (
                <Select
                  value={filtersUser?.userMailsArray}
                  maxTagCount={4}
                  options={userListWithoutChildren}
                  mode="tags"
                  fieldNames={{ label: 'userMail', value: 'userMail' }}
                />
              ),
            },
          ],
          applyAction: onSubmitFilter,
          resetAction: async () => {
            try {
              await dispatch(getUsers()).unwrap();
            } catch (e) {
              ApiNotifications.error(e);
            }
          },
          initialValuesForm: filtersUser,
        },
        csvConfig: {
          data: csvData,
          fileName: 'users.csv',
          headers: [
            {
              label: 'Name',
              key: 'firstName',
            },
            {
              label: 'Last Name',
              key: 'lastName',
            },
            {
              label: 'Title',
              key: 'userTitle',
            },
            {
              label: 'Roles',
              key: 'roleName',
            },
            {
              label: 'Department',
              key: 'department',
            },
            {
              label: 'Location',
              key: 'site',
            },
          ],
        },
        newButtonProps: {
          disabled: !userAccess.create,
        },
      }}
    />
  );
}

export { Users };
