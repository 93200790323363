import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getDepartments } from '@store/actions/properties/Department';
import { selectDepartmentsList } from '@store/selectors/properties/Department';

function EntryDepartment() {
  useSetPageInfo({
    defaultPagePath: paths.department.default,
    pageTitle: 'pages_single.Department',
  });

  const departments = useAppSelector(selectDepartmentsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!departments.length) {
      dispatch(getDepartments());
    }
  }, []);

  return <Outlet />;
}

export default EntryDepartment;
