import { api } from '@services/Axios';

import type {
  CreateMaAtCategoryDto,
  MaAtCategoryModel,
  UpdateMaAtCategoryDto,
} from '@model/properties/MaAt/Category';
import type { ApiResponse } from '@services/Axios';

const MaAtCategoryEndpoint = '/MaintinanceCategories';

export const MaAtCategoryRepository = {
  async fetchAll() {
    const response: ApiResponse<MaAtCategoryModel[]> = await api.get(
      `${MaAtCategoryEndpoint}/GetMaintinanceCategories`
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<MaAtCategoryModel> = await api.get(
      `${MaAtCategoryEndpoint}/GetMaintinanceCategoriesById/${id}`
    );

    return response.data;
  },
  async add(dto: CreateMaAtCategoryDto) {
    const response: ApiResponse<MaAtCategoryModel> = await api.post(
      `${MaAtCategoryEndpoint}/AddMaintinanceCategories`,
      dto
    );

    return response.data;
  },

  async update(dto: UpdateMaAtCategoryDto) {
    const response: ApiResponse<MaAtCategoryModel> = await api.post(
      `${MaAtCategoryEndpoint}/UpdateMaintinanceCategories`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${MaAtCategoryEndpoint}/RemoveByIdList`, {
      maintinanceCategoryIds: ids,
    });

    return response.data;
  },
};
