import { defaultFormatDate } from '@constants/Date';
import moment from 'moment/moment';

import { CSVHeaders } from './CSVHeaders';

import type { AssetModel } from '@model/management/Asset';
import type { AsAtBrandModel } from '@model/properties/AsAt/Brand';
import type { AsAtCategoryModel } from '@model/properties/AsAt/Category';
import type { AsAtModelModel } from '@model/properties/AsAt/Model';
import type { CostCenterModel } from '@model/properties/CostCenter';
import type { VendorModel } from '@model/properties/Vendor';

interface AssetCsvConfigProps {
  allAssetRecord: AssetModel[];
  listAssetCategory: Omit<AsAtCategoryModel, 'childNonCurrAssetCardCategories'>[];
  listAssetBrand: AsAtBrandModel[];
  listAssetModel: AsAtModelModel[];
  listCostCenter: CostCenterModel[];
  listVendor: VendorModel[];
}

export function getAssetCSVConfig(config: AssetCsvConfigProps) {
  const {
    allAssetRecord,
    listAssetCategory,
    listAssetBrand,
    listAssetModel,
    listCostCenter,
    listVendor,
  } = config;

  const csvData =
    allAssetRecord.map((asset) => {
      const assetBrand = listAssetBrand.find(
        (brand) => brand.nonCurrAssetCardBrandId === asset.nonCurrAssetCardBrandId
      )?.name;

      const assetCategory = listAssetCategory.find(
        (category) => category.nonCurrAssetCardCategoryId === asset.nonCurrAssetCardCategoryId
      )?.name;

      const costCenter = listCostCenter.find(
        (cost) => cost.costCenterId === asset.costCenterId
      )?.name;

      const vendor = listVendor.find((vendor) => vendor.partnerId === asset.partnerId)?.name;

      const assetModel = listAssetModel.find(
        (model) => model.nonCurrAssetCardModelId === asset?.nonCurrAssetCardModelId
      )?.name;

      return {
        barcode: asset.barcode,
        category: assetCategory ? assetCategory : '----',
        vendor: vendor ? vendor : '----',
        status: asset.status.name,
        serialNumber: asset.serialNumber ? asset.serialNumber : '----',
        assetBrand: assetBrand ? assetBrand : '----',
        site: asset.site.name,
        assetCard: asset.nonCurrAssetCard.name,
        assetModel: assetModel ? assetModel : '----',
        invoiceDate: moment(asset.invoiceDate).format(defaultFormatDate),
        invoiceNumber: asset.invoiceNo ? asset.invoiceNo : '----',
        contracts: '----',
        invoiceAmount: asset.price ? asset.price : '----',
        costCenter: costCenter ? costCenter : '----',
        warrantyStartDate: moment(asset.guaranteeStartDate).format(defaultFormatDate),
        warrantyEndDate: moment(asset.guaranteeEndDate).format(defaultFormatDate),
        user: asset.nonCurrAssetUsers?.length
          ? `${asset.nonCurrAssetUsers[0].user.firstName} ${asset.nonCurrAssetUsers[0].user.lastName}`
          : '----',
      };
    })

  return {
    fileName: 'assets',
    headers: CSVHeaders,
    data: csvData,
  };
}
