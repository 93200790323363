import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import { selectPaIUnitsList, selectPaIUnitStatus } from '@store/selectors/properties/PaI/Unit';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { PaIUnitModel } from '@model/properties/PaI/Unit';

const columns: ColumnsTableType<PaIUnitModel> = [
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'partsAndInventoriesUnitName',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.partsAndInventoriesUnitName, b.partsAndInventoriesUnitName),
  },

  {
    title: <Translation>{(t) => t('titles.Short_Name')}</Translation>,
    dataIndex: 'partsAndInventoriesUnitShortName',
    key: 'shortName',
    ellipsis: true,
    sorter: (a, b) =>
      compare(a.partsAndInventoriesUnitShortName, b.partsAndInventoriesUnitShortName),
  },
];

const PartAndInventoryUnits = () => {
  const { t } = useTranslation();

  const listPartAndInventoryUnit = useAppSelector(selectPaIUnitsList);
  const statusPartAndInventoryUnit = useAppSelector(selectPaIUnitStatus);

  const { paiAttrAccess } = useGetAccess();

  return (
    <BasicTablePage
      table={{
        loading: statusPartAndInventoryUnit === 'pending',
        dataSource: listPartAndInventoryUnit,
        columns: columns,
        rowKey: (record) => record.partsAndInventoriesUnitId,
        dataSourceName: t('pages_plural.PaI_Attr_Units'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Unit') }),
        csvConfig: {
          data: listPartAndInventoryUnit,
          fileName: 'part-and-inventory-units',
          headers: [
            { label: 'name', key: 'partsAndInventoriesUnitName' },
            { label: 'short name', key: 'partsAndInventoriesUnitShortName' },
          ],
        },
        newButtonProps: {
          disabled: !paiAttrAccess.create,
        },
      }}
    />
  );
};

export { PartAndInventoryUnits };
