import React, {  useState } from 'react';
import { Translation,useTranslation } from 'react-i18next';

import { SimpleTable } from '@components/common';
import { defaultFormatDate } from '@constants/Date';
import { useOutletProfile } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { notificationController } from '@services/Notifications';
import { BaseModal } from '@shared/UI';
import { updateNonCurrAssetUsersApproveStatus } from '@store/actions/profile/Profile';
import moment from 'moment';

import './nonCurrAssetUsersTable.scss'

// type NonCurrAssetsUserTableProps = {
//     nonCurrAssetUserId: number;
// };


const columns = [
    {
      title: <Translation>{(t) => t('titles.Serial_Number')}</Translation>,
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      ellipsis: true,
    },
    {
      title: <Translation>{(t) => t('titles.Asset_Assignor')}</Translation>,
      dataIndex: 'assignedFromName',
      key: 'assignedFromName',
      ellipsis: true,
    },
    {
      title: <Translation>{(t) => t('titles.Asset_Card')}</Translation>,
      dataIndex: 'ncaCardName',
      key: 'ncaCardName',
      ellipsis: true,
    },
    {
      title: <Translation>{(t) => t('titles.Assign_Date')}</Translation>,
      dataIndex: 'createdDate',
      key: 'createdDate',
      ellipsis: true,
    },
    {
      title: <Translation>{(t) => t('titles.Is_It_Approved')}</Translation>,
      dataIndex: 'isUserApprove',
      key: 'isUserApprove',
      ellipsis: true,
    }
  ];

const NonCurrAssetsUserTable = () => {
  
//   const { nonCurrAssetUserId } = props;
  const { entity } = useOutletProfile();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [modalMode, setModalMode] = useState(false);
  
  const [selectedNonCurrAssetUser, setSelectedNonCurrAssetUser] = useState<any>(null);

  const handleOpenModal = (nonCurrAssetUserId: any) => {    
    const nonCurrAssetUser = entity.nonCurrAssetUsers.find((item) => item.nonCurrAssetUserId === nonCurrAssetUserId);
    if (nonCurrAssetUser) {
      setSelectedNonCurrAssetUser(nonCurrAssetUser);
      setModalMode(true);
    }
  };

  const update = (nonCurrAssetUserId: number) => {
    
    if (nonCurrAssetUserId!=null) {

      console.log(nonCurrAssetUserId);
      dispatch(
        
        updateNonCurrAssetUsersApproveStatus({
          nonCurrAssetUserId: nonCurrAssetUserId as number,
          
        })
      )
        .unwrap()
        .then(() => {
   
          notificationController.success({
            description: 'Update operation successful',
          });
        })
        .catch((err) => {
          if (typeof err === 'string') {
            notificationController.error({
              description: err,
            });
          }
        })
        .finally(() => setModalMode(false));
    }
  };

  const dataSourceWithFormattedDate = entity?.nonCurrAssetUsers.map(item => {
    const approvalStatus = item.isUserApprove ? <span className="noncurrassets-users-approved">
       {`${t('titles.Approved')}`}</span> : <span className="noncurrassets-users-not-approved">{`${t('titles.Not_Approved')}`}</span> ;
  
    return {
      ...item,
      createdDate: moment(item.createdDate).format(defaultFormatDate),
      isUserApprove: approvalStatus
    };
  });
  

  return (

    <><div className="noncurrassets-users-table">
          <SimpleTable
              dataSource={dataSourceWithFormattedDate}
              rowKey={(row) => row.nonCurrAssetUserId}
              columns={columns}
              onRow={(record) => ({
                  onClick: () => handleOpenModal(record.nonCurrAssetUserId),
              })} />

      </div><BaseModal
          open={modalMode}
          onOk={() => update(selectedNonCurrAssetUser.nonCurrAssetUserId)}
          onCancel={() => setModalMode(false)}
          title="Confirm"
          className="modal-movement"
      >
              <span>
                  Do you confirm that you own the asset:
                  <br />

                  {selectedNonCurrAssetUser && selectedNonCurrAssetUser.ncaCardName}

              </span>
          </BaseModal></>
  );
};
export { NonCurrAssetsUserTable };
