import type { RootState } from '@store/rootReducer';

export const selectPaIBrandState = (state: RootState) => state.PaIBrandReducer;

export const selectPaIBrandsList = (state: RootState) => selectPaIBrandState(state).PaIBrandsList;

export const selectPaIBrand = (state: RootState) => selectPaIBrandState(state).PaIBrand;

export const selectPaIBrandStatus = (state: RootState) => selectPaIBrandState(state).PaIBrandStatus;

export const selectPaIBrandError = (state: RootState) => selectPaIBrandState(state).PaIBrandError;
