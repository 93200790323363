import React, { useState } from 'react';

import { UserNotification } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { BaseModal } from '@shared/UI';
import { markAlertAsRead } from '@store/actions/extra/Alert';
import { selectAlerts, selectAlertStatus } from '@store/selectors/extra/Alert';
import { List } from 'antd';

import './Notifications.scss';

const Notifications = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState<any>(null);
  const dispatch = useAppDispatch();
  const listNotifications = useAppSelector(selectAlerts);
  const statusAlert = useAppSelector(selectAlertStatus);

  const handleOpenModal = (alertId: any) => {
    const alert = listNotifications.find((item) => item.alertId === alertId);
    if (alert) {
      setSelectedAlert(alert);
      dispatch(markAlertAsRead(alertId));
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="list-notifications">
      <List
        bordered={false}
        dataSource={listNotifications}
        pagination={{
          pageSize: 12,
        }}
        loading={statusAlert === 'pending'}
        className="list-notifications__list"
        renderItem={(alert) => (
          <List.Item
            key={alert.alertId}
            onClick={() => handleOpenModal(alert.alertId)}
          >
            <UserNotification
              hiddenMarker={alert.isRead}
              description={alert.alertHeader}
              isRead={alert.isRead}
              notificationDate={alert.createdDate}
            />
          </List.Item>
        )}
      />
      <BaseModal
        className="notifications-modal"
        open={isModalOpen}
        onCancel={handleCloseModal}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        width="120rem"
        centered
      >
        {selectedAlert && selectedAlert.description}
      </BaseModal>
    </div>
  );
};

export { Notifications };
