import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, TextAreaDescription } from '@components/common';
import { Input, Radio, Select } from '@shared/UI';

import { AssignBlock } from '../AssignBlock';

import type { MaAtCategoryModel } from '@model/properties/MaAt/Category';
import type { SiteModel } from '@model/properties/Site';
import type { UserModel } from '@model/users/User';

interface LeftSectionFieldsProps {
  usersList: Array<UserModel>;

  maiCategoriesList: Array<MaAtCategoryModel>;

  sitesList: Array<SiteModel>;

  onSiteOpenModal: () => void;

  onMaiCategoryOpenModal: () => void;
}

const { CaseField, Item } = CommonForm;

// region --- Field Rules

const titleRules = [{ required: true }];

const maiCategoryIdRules = [{ required: true }];

const priorityRules = [{ required: true }];

const siteIdRules = [{ required: true }];

// endregion --- Field Rules

// region --- Select field names

const maiCategoryNames = {
  label: 'categoryName',
  value: 'maintinanceCategoryId',
};

const siteNames = { label: 'name', value: 'siteId' };

// endregion --- Select field names

export const LeftSectionFields = memo((props: LeftSectionFieldsProps) => {
  const { usersList, maiCategoriesList, sitesList, onSiteOpenModal, onMaiCategoryOpenModal } =
    props;

  const { t } = useTranslation();

  return (
    <CaseField>
      <Item label={t('titles.Title')} name="title" rules={titleRules}>
        <Input placeholder={t('titles.Title')} autoComplete="off" />
      </Item>

      <Item
        name="maintinanceCategoryId"
        label={`${t('pages_single.Maintenance')} ${t('titles.Category')}`}
        rules={maiCategoryIdRules}
      >
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Category') })}
          options={maiCategoriesList}
          fieldNames={maiCategoryNames}
          isCreatable
          creatableAction={onMaiCategoryOpenModal}
        />
      </Item>

      <Item label={t('titles.Priority')} name="maintenancePriorityId" rules={priorityRules}>
        <Radio.Group>
          <Radio value={3}>{t('titles.Low')}</Radio>
          <Radio value={2}>{t('titles.Medium')}</Radio>
          <Radio value={1}>{t('titles.High')}</Radio>
        </Radio.Group>
      </Item>

      <Item name="siteId" label={t('titles.Site')} rules={siteIdRules}>
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Site') })}
          options={sitesList}
          fieldNames={siteNames}
          isCreatable
          creatableAction={onSiteOpenModal}
        />
      </Item>

      <TextAreaDescription />

      <AssignBlock usersList={usersList} />
    </CaseField>
  );
});
