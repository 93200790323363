import { createAsyncThunk } from '@reduxjs/toolkit';
import { CompanyRepository } from '@repositories';
import { identifyError } from '@shared/utils/functions'; // region --- Get Actions

import type { CompanyModel, CreateCompanyDto, FilterCompanyDto, UpdateCompanyDto } from '@model/users/Company';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer'; // region --- Get Actions

// region --- Get Actions

export const getCompanies = createAsyncThunk(
  'Company/getCompanies',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CompanyRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      return rejectWithValue(err.data);
    }
  }
);

export const getCompaniesByFilter = createAsyncThunk<
  {
    companys: CompanyModel[];
    companysFilters: FilterCompanyDto;
  },
  FilterCompanyDto,
  { rejectValue: string }
>('Company/getCompaniesByFilter', async (dto, { rejectWithValue }) => {
  try {
    const response = await CompanyRepository.fetchAllByFilter(dto);
    return { companys: response.resultObject, companysFilters: dto };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getAllCompanies = createAsyncThunk(
  'Company/getAllCompanies',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CompanyRepository.fetchAllWithoutRestiction();

      return response.resultObject;
    } catch (err: any) {
      return rejectWithValue(err.data);
    }
  }
);

export const getCompanyById = createAsyncThunk(
  'Company/getCompanyById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await CompanyRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addCompany = createAsyncThunk(
  'Company/addCompany',
  async (dto: CreateCompanyDto, { rejectWithValue }) => {
    try {
      const response = await CompanyRepository.add(dto);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Add Actions

// region --- Update Actions

export const updateCompany = createAsyncThunk<
  { companies: CompanyModel[]; someCompany: CompanyModel },
  UpdateCompanyDto,
  { state: RootState }
>('Company/updateCompany', async (dto: UpdateCompanyDto, { rejectWithValue, getState }) => {
  try {
    const response = await CompanyRepository.update(dto);

    const someCompany = response.resultObject;

    const { companiesList } = getState().CompanyReducer;

    const companies = companiesList.map((Company) =>
      Company.companyId === someCompany.companyId ? someCompany : Company
    );

    return { companies, someCompany: someCompany };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const updateCompanySession = createAsyncThunk<string, number, { rejectValue: string }>(
  'Company/updateCompanySession',
  async (id, thunkAPI) => {
    try {
      const response = await CompanyRepository.updateCompanySession(id);

      return response.token;
    } catch (e) {
      return thunkAPI.rejectWithValue(identifyError(e));
    }
  }
);

// endregion --- Update Actions

// region --- Remove Actions

export const removeCompanyById = createAsyncThunk<CompanyModel[], number[], { state: RootState }>(
  'Company/removeCompanyById',
  async (ids: number[], { rejectWithValue, getState }) => {
    try {
      await CompanyRepository.removeById(ids);

      const companies = getState().CompanyReducer.companiesList;

      return companies.filter((Company) => !ids.includes(Company.companyId));
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Remove Actions
