import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAuthorizationRepository } from '@repositories';

import type { UserModel } from '@model/users/User';
import type { CreateUserAuthorizationDto } from '@model/users/UserAuthorization';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getUserAuthorizations = createAsyncThunk<UserModel[], void, { rejectValue: string }>(
  'UserAuthorization/getUserAuthorizations',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserAuthorizationRepository.fetchAll(1);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// region --- Add Actions

export const addUserAuthorization = createAsyncThunk<
  { userAuthList: UserModel[]; createdAuthorization: UserModel },
  CreateUserAuthorizationDto
>('UserAuthorization/createUserAuthorization', async (dto, { rejectWithValue }) => {
  try {
    const createdAuthorization = await UserAuthorizationRepository.add(dto);
    const response = await UserAuthorizationRepository.fetchAll(1);

    return {
      userAuthList: response.resultObject,
      createdAuthorization: createdAuthorization.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// region --- Get by Id Actions

export const getUserAuthorizationById = createAsyncThunk<UserModel, string>(
  'UserAuthorization/getUserAuthorizationById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await UserAuthorizationRepository.fetchById('1', id, '1');

      return res.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// region --- Update Actions

export const updateUserAuthorization = createAsyncThunk<
  { userAuthList: UserModel[]; editedAuthorization: UserModel },
  CreateUserAuthorizationDto
>('UserAuthorization/updateUserAuthorization', async (dto, { rejectWithValue }) => {
  try {
    const editedAuthorization = await UserAuthorizationRepository.update(dto);
    const response = await UserAuthorizationRepository.fetchAll(1);

    return {
      userAuthList: response.resultObject,
      editedAuthorization: editedAuthorization.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions
