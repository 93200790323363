import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@shared/UI';

import './BaseCheckStepTitle.scss';

interface BaseCheckStepTitleProps {
  title: string;
  onChangeTitle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isFocus: boolean;
  isError?: boolean;
}

const BaseCheckStepTitle = (props: BaseCheckStepTitleProps) => {
  const {title, isFocus, isError, onChangeTitle} = props;

  const {t} = useTranslation();

  const statusError = isError ? 'error' : '';

  return (
    <Input
      value={title}
      className={'base-check-step-title'}
      onChange={onChangeTitle}
      disabled={!isFocus}
      placeholder={t('titles.Untitled_Question')}
      status={statusError}
    />
  );
};

export {BaseCheckStepTitle};
