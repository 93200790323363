import { createSlice } from '@reduxjs/toolkit';
import {
  addContract,
  addContractFiles,
  downloadContractFileById,
  getContractById,
  getContractFilesById,
  getContracts,
  getContractsByFilter,
  removeContractById,
  removeContractFileById,
  updateContract,
} from '@store/actions/properties/Contract';

import type { LoadingStatus } from '@constants/loadingStatus';
import type {
  ContractFileModel,
  ContractModel,
  FilterContractDto,
} from '@model/properties/Contract';

type ContractState = {
  contractsList: ContractModel[];
  contractsFilteredList: ContractModel[];
  contract: null | ContractModel;
  // TODO: need to change. It need to add to particular contract object
  contractFiles: null | ContractFileModel[];
  contractStatus: LoadingStatus;
  contractError: null | string;
  departmentFilters: FilterContractDto;
};

const initialState: ContractState = {
  contractsList: [],
  contractsFilteredList: [],
  contract: null,
  contractFiles: null,
  contractStatus: 'idle',
  contractError: null,
  departmentFilters: {
    contractCodeArray: [],
    contractNameArray: [],
    contractNoArray: [],
  },
};

export const ContractSlice = createSlice({
  name: 'Contract',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContracts.pending, (state) => {
        state.contractStatus = 'pending';
        state.contractError = null;
        state.departmentFilters = {
          contractCodeArray: [],
          contractNameArray: [],
          contractNoArray: [],
        };
        state.contractsFilteredList = [];
      })
      .addCase(getContracts.fulfilled, (state, action) => {
        state.contractsList = action.payload;
        state.contractStatus = 'idle';
      })
      .addCase(getContracts.rejected, (state, action) => {
        state.contractStatus = 'idle';
        if (action.payload) {
          state.contractError = action.payload;
        }
      });
    builder
      .addCase(getContractsByFilter.pending, (state) => {
        state.contractStatus = 'pending';
        state.contractError = null;
      })
      .addCase(getContractsByFilter.fulfilled, (state, action) => {
        state.contractsFilteredList = action.payload.contracts;
        state.departmentFilters = action.payload.departmentFilters;
        state.contractStatus = 'idle';
      })
      .addCase(getContractsByFilter.rejected, (state, action) => {
        state.contractStatus = 'idle';
        if (action.payload) {
          state.contractError = action.payload;
        }
      });
    builder
      .addCase(addContract.pending, (state) => {
        state.contractStatus = 'pending';
        state.contractError = null;
      })
      .addCase(addContract.fulfilled, (state, {payload}) => {
        state.contractStatus = 'idle';
        state.contractsList = [payload, ...state.contractsList];
      })
      .addCase(addContract.rejected, (state, {payload}) => {
        state.contractStatus = 'idle';
        if (payload) {
          state.contractError = payload;
        }
      });

    builder
      .addCase(addContractFiles.pending, (state) => {
        state.contractStatus = 'pending';
        state.contractError = null;
      })
      .addCase(addContractFiles.fulfilled, (state, {payload}) => {
        state.contractStatus = 'idle';
        if (payload.isEdit) {
          state.contractFiles = payload.files;
        }
      })
      .addCase(addContractFiles.rejected, (state, {payload}) => {
        state.contractStatus = 'idle';
        if (payload) {
          state.contractError = payload;
        }
      });
    builder
      .addCase(getContractById.pending, (state) => {
        state.contractStatus = 'pending';
        state.contractError = null;
      })
      .addCase(getContractById.fulfilled, (state, {payload}) => {
        state.contractStatus = 'idle';
        state.contract = payload;
      })
      .addCase(getContractById.rejected, (state, {payload}) => {
        state.contractStatus = 'idle';
        if (payload) {
          state.contractError = payload;
        }
      });
    builder
      .addCase(getContractFilesById.pending, (state) => {
        state.contractStatus = 'pending';
        state.contractError = null;
      })
      .addCase(getContractFilesById.fulfilled, (state, {payload}) => {
        state.contractStatus = 'idle';
        state.contractFiles = payload;
      })
      .addCase(getContractFilesById.rejected, (state, {payload}) => {
        state.contractStatus = 'idle';
        state.contractFiles = null;
        if (payload) {
          state.contractError = payload;
        }
      });

    builder
      .addCase(downloadContractFileById.pending, (state) => {
        state.contractStatus = 'pending';
        state.contractError = null;
      })
      .addCase(downloadContractFileById.fulfilled, (state, {payload}) => {
        state.contractStatus = 'idle';
        state.contractFiles = payload;
      })
      .addCase(downloadContractFileById.rejected, (state, {payload}) => {
        state.contractStatus = 'idle';
        state.contractFiles = null;
        if (payload) {
          state.contractError = payload;
        }
      });

    builder
      .addCase(updateContract.pending, (state) => {
        state.contractStatus = 'pending';
        state.contractError = null;
      })
      .addCase(updateContract.fulfilled, (state, {payload}) => {
        state.contractStatus = 'idle';
        state.contractsList = payload.contractsList;
        state.contract = payload.contract;
      })
      .addCase(updateContract.rejected, (state, {payload}) => {
        state.contractStatus = 'idle';
        if (payload) {
          state.contractError = payload;
        }
      });

    builder
      .addCase(removeContractById.pending, (state) => {
        state.contractStatus = 'pending';
        state.contractError = null;
      })
      .addCase(removeContractById.fulfilled, (state, {payload}) => {
        state.contractStatus = 'idle';
        state.contractsList = payload;
      })
      .addCase(removeContractById.rejected, (state, {payload}) => {
        state.contractStatus = 'idle';
        if (payload) {
          state.contractError = payload;
        }
      });

    builder.addCase(removeContractFileById.pending, (state) => {
      state.contractError = null;
    }).addCase(removeContractFileById.fulfilled, (state, {payload}) => {
      state.contractFiles = payload;
    }).addCase(removeContractFileById.rejected, (state, {payload}) => {
      if (payload) {
        state.contractError = payload;
      }
    })
  },
});

const ContractReducer = ContractSlice.reducer;

export {ContractReducer};
