import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormDepartment } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addDepartment } from '@store/actions/properties/Department';

import type { CreateDepartmentDto } from '@model/properties/Department';
import type { FormInstance } from 'antd';

const CreateDepartment = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { departmentAccess } = useGetAccess();

  const onFinish = (dto: CreateDepartmentDto, form?: FormInstance<CreateDepartmentDto>) => {
    dispatch(addDepartment(dto))
      .unwrap()
      .then((response) => {
        ApiNotifications.create(response.createdDepartment.name);
        navigate(paths.department.default);
        form?.resetFields();
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <CheckAccess hasAccess={departmentAccess.create} redirectTo={paths.department.default}>
      <FormDepartment
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.Department') }),
        }}
      />
    </CheckAccess>
  );
};

export default CreateDepartment;
