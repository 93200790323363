import React, { useEffect } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { CommonForm, SimpleTable } from '@components/common';
import { defaultFormatDate } from '@constants/Date';
import { useAppDispatch, useAppSelector } from '@hooks';
import { getPaIConsumeByCardId } from '@store/actions/management/PaI';
import { getPaICategories } from '@store/actions/properties/PaI/Category';
import { selectPaIConsumeName, selectPaIStatus } from '@store/selectors/management/PaI';
import {
  selectPaICategoriesList,
  selectPaICategoryStatus,
} from '@store/selectors/properties/PaI/Category';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { ColumnsTableType } from '@components/common/SimpleTable/SimpleTable';
import type { PaIConsumeNAMEModel, PaIModel } from '@model/management/PaI';

const { CaseField, Item, PreviewField } = CommonForm;

const columns: ColumnsTableType<PaIConsumeNAMEModel> = [
  {
    title: <Translation>{(t) => t('titles.Demand_Quantity')}</Translation>,
    dataIndex: 'demandQuantity',
    key: 'demandQuantity',
  },
  {
    title: <Translation>{(t) => t('titles.Used_Quantity')}</Translation>,
    dataIndex: 'usedQuantity',
    key: 'usedQuantity',
  },
  {
    title: <Translation>{(t) => t('titles.Remaining_Quantity')}</Translation>,
    dataIndex: 'remainingQuantity',
    key: 'remainingQuantity',
  },
  {
    title: <Translation>{(t) => t('titles.Currency')}</Translation>,
    dataIndex: 'currencySymbol',
    key: 'currencySymbol',
  },
  {
    title: <Translation>{(t) => t('titles.Total_Cost')}</Translation>,
    dataIndex: 'totalCost',
    key: 'totalCost',
  },
  {
    title: <Translation>{(t) => t('titles.Used_For')}</Translation>,
    dataIndex: 'usedFor',
    key: 'usedFor',
  },
  {
    title: <Translation>{(t) => t('titles.Used_Date')}</Translation>,
    dataIndex: 'usedDate',
    key: 'usedDate',
  },
];

function PreviewFormPartAndInventoryByName(props: Omit<CommonPreviewProps<PaIModel>, 'onDelete'>) {
  const { entity, header } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const consumeName = useAppSelector(selectPaIConsumeName);

  const statusPartAndInventoryName = useAppSelector(selectPaIStatus);

  const listPartAndInventoryCategory = useAppSelector(selectPaICategoriesList);
  const statusPartAndInventoryCategory = useAppSelector(selectPaICategoryStatus);

  useEffect(() => {
    if (!listPartAndInventoryCategory.length) {
      dispatch(getPaICategories());
    }
    dispatch(getPaIConsumeByCardId(`${entity.partsAndInventoriesCardId}`));
  }, []);

  const valuePartAndInventoryCategory = listPartAndInventoryCategory.find(
    (category) =>
      category.partsAndInventoriesCategoryId ===
      entity.partsAndInventoriesCard.partsAndInventoriesCategoryId
  );

  const consume =
    consumeName && consumeName.length
      ? consumeName?.map((order) => ({
          ...order,
          usedDate: moment(order.usedDate).format(defaultFormatDate),
          ID: uuidv4(),
        }))
      : [];

  return (
    <>
      <CommonForm
        isPreview
        loading={
          statusPartAndInventoryName === 'pending' || statusPartAndInventoryCategory === 'pending'
        }
        header={{
          title: entity.partsAndInventoriesCard.partsAndInventoriesCardName,
          extra: [],
          ...header,
        }}
        container={{
          style: {
            maxWidth: '100%',
          },
        }}
      >
        <CaseField>
          <Item label={t('titles.Name')}>
            <PreviewField>
              {entity.partsAndInventoriesCard.partsAndInventoriesCardName}
            </PreviewField>
          </Item>
        </CaseField>
        <CaseField>
          <Item label={t('titles.Category')}>
            <PreviewField>
              {valuePartAndInventoryCategory?.partsAndInventoriesCategoryName}
            </PreviewField>
          </Item>
        </CaseField>

        <CaseField md={24}>
          <SimpleTable dataSource={consume} rowKey={(row) => row.ID} columns={columns} />
        </CaseField>
      </CommonForm>
    </>
  );
}

export { PreviewFormPartAndInventoryByName };
