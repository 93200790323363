import { createSlice } from '@reduxjs/toolkit';
import {
  addSite,
  getSiteById,
  getSites,
  getSitesByFilter,
  removeSiteById,
  updateSite,
  updateSiteParentRelations,
} from '@store/actions/properties/Site';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { FilterSiteDto, SiteModel } from '@model/properties/Site';

type SiteState = {
  sitesList: SiteModel[];
  sitesFilteredList: SiteModel[];
  siteStatus: LoadingStatus;
  site: SiteModel | null;
  siteError: string | null;
  siteFilters: FilterSiteDto;
};

const initialState: SiteState = {
  sitesList: [],
  siteStatus: 'idle',
  site: null,
  siteError: null,
  sitesFilteredList: [],
  siteFilters: {
    siteCodesArray: [],
    namesArray: [],
    barcodesArray: [],
    addressesArray: [],
    townsArray: [],
  },
};

const SiteSlice = createSlice({
  name: 'Site',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSites.pending, (state) => {
        state.siteError = null;
        state.siteStatus = 'pending';
        state.sitesFilteredList = [];
        state.siteFilters = {
          siteCodesArray: [],
          namesArray: [],
          barcodesArray: [],
          addressesArray: [],
          townsArray: [],
        };
      })
      .addCase(getSites.fulfilled, (state, { payload }) => {
        state.siteStatus = 'idle';
        state.sitesList = payload;
      })
      .addCase(getSites.rejected, (state) => {
        state.siteStatus = 'idle';
      });
    builder
      .addCase(getSitesByFilter.pending, (state) => {
        state.siteError = null;
        state.siteStatus = 'pending';
      })
      .addCase(getSitesByFilter.fulfilled, (state, { payload }) => {
        state.siteStatus = 'idle';
        state.sitesFilteredList = payload.sites;
        state.siteFilters = payload.siteFilters;
      })
      .addCase(getSitesByFilter.rejected, (state) => {
        state.siteStatus = 'idle';
      });
    builder
      .addCase(addSite.pending, (state) => {
        state.siteStatus = 'pending';
        state.siteError = null;
      })
      .addCase(addSite.fulfilled, (state, { payload }) => {
        state.siteStatus = 'idle';
        state.sitesList = payload.sites;
      })
      .addCase(addSite.rejected, (state) => {
        state.siteStatus = 'idle';
      });
    builder
      .addCase(getSiteById.pending, (state) => {
        state.siteStatus = 'pending';
        state.siteError = null;
      })
      .addCase(getSiteById.fulfilled, (state, { payload }) => {
        state.siteStatus = 'idle';
        state.site = payload;
      })
      .addCase(getSiteById.rejected, (state) => {
        state.siteStatus = 'idle';
      });
    builder
      .addCase(updateSite.pending, (state) => {
        state.siteStatus = 'pending';
        state.siteError = null;
      })
      .addCase(updateSite.fulfilled, (state, { payload }) => {
        state.siteStatus = 'idle';
        state.sitesList = payload.sites;
        state.site = payload.site;
      })
      .addCase(updateSite.rejected, (state) => {
        state.siteStatus = 'idle';
      });
    builder
      .addCase(removeSiteById.pending, (state) => {
        state.siteStatus = 'pending';
        state.siteError = null;
      })
      .addCase(removeSiteById.fulfilled, (state, { payload }) => {
        state.siteStatus = 'idle';
        state.sitesList = payload;
      })
      .addCase(removeSiteById.rejected, (state) => {
        state.siteStatus = 'idle';
      });
    builder
      .addCase(updateSiteParentRelations.pending, (state) => {
        state.siteStatus = 'pending';
        state.siteError = null;
      })
      .addCase(updateSiteParentRelations.fulfilled, (state, { payload }) => {
        state.siteStatus = 'idle';
        state.sitesList = payload;
      })
      .addCase(updateSiteParentRelations.rejected, (state) => {
        state.siteStatus = 'idle';
      });
  },
});

const SiteReducer = SiteSlice.reducer;

export { SiteReducer };
