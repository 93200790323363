import { api } from '@services/Axios';

import type {
  AsAtCardModel,
  CreateAsAtCardDto,
  UpdateAsAtCardDto,
} from '@model/properties/AsAt/Card';
import type { ApiResponse } from '@services/Axios';

const AsAtCardEndpoint = '/NonCurrAssetCard';

const AsAtCardRepository = {
  async fetchAll() {
    const response: ApiResponse<AsAtCardModel[]> = await api.get(
      `${AsAtCardEndpoint}/GetNonCurrAssetsCardsList`
    );

    return response.data;
  },
  async add(dto: CreateAsAtCardDto) {
    const response: ApiResponse<AsAtCardModel> = await api.post(
      `${AsAtCardEndpoint}/AddNonCurrAssetCard`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<AsAtCardModel> = await api.get(
      `${AsAtCardEndpoint}/GetNonCurrAssetCardById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdateAsAtCardDto) {
    const response: ApiResponse<AsAtCardModel> = await api.post(
      `${AsAtCardEndpoint}/UpdateNonCurrAssets`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${AsAtCardEndpoint}/RemoveByIdList`, {
      nonCurrAssetCardIds: ids,
    });

    return response.data;
  },
};

export { AsAtCardRepository };
