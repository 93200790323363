import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormPartAndInventoryCategory } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addPaICategory } from '@store/actions/properties/PaI/Category';

import type { CreatePaICategoryDto } from '@model/properties/PaI/Category';
import type { FormInstance } from 'antd';

function CreatePartAndInventoryCategory() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onFinish = async (dto: CreatePaICategoryDto, form?: FormInstance<CreatePaICategoryDto>) => {
    try {
      const response = await dispatch(addPaICategory(dto)).unwrap();
      ApiNotifications.create(response.particularCategory.partsAndInventoriesCategoryName);
      navigate(paths.partAndInventoryAttributes.tabs.category.default);
      form?.resetFields();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess
      hasAccess={paiAttrAccess.create}
      redirectTo={paths.partAndInventoryAttributes.tabs.category.default}
    >
      <FormPartAndInventoryCategory
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Category') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreatePartAndInventoryCategory;
