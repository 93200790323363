import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormTitle } from '@components/views/Forms/users';
import { useAppDispatch, useAppNavigate } from '@hooks';
import { paths } from '@routes/paths';
import { addTitle } from '@store/actions/user/Title';

import type { CreateTitleDto } from '@model/users/Title';
import type { FormInstance } from 'antd';

function CreateTitle() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const navigate = useAppNavigate();

  const onFinish = (dto: CreateTitleDto, form?: FormInstance<CreateTitleDto>) => {
    dispatch(addTitle(dto))
      .unwrap()
      .then(() => {
        if (form) {
          navigate(paths.title.default);
          form.resetFields();
        }
      });
  };

  return (
    <FormTitle
      onFinish={onFinish}
      header={{
        title: t('titles.New_Item', { name: t('pages_single.Title') }),
      }}
    />
  );
}

export default CreateTitle;
