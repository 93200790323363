import React from 'react';

import { Table } from 'antd';
import cl from 'classnames';

import type { TableProps } from 'antd';
import type { ColumnsType, GetRowKey } from 'antd/lib/table/interface';

import './SimpleTable.scss';

export type ColumnsTableType<T> = ColumnsType<T>;

interface SimpleTableProps<T extends Record<string, any>> extends Omit<TableProps<T>, 'rowKey'> {
  rowKey: GetRowKey<T>;
}

const SimpleTable = <T extends Record<string, any>>(props: SimpleTableProps<T>) => {
  const { className, pagination, ...rest } = props;

  const consumptionTableClasses = cl('consumption-history-table', className);

  return (
    <Table
      className={consumptionTableClasses}
      bordered={false}
      pagination={
        pagination
          ? {
              position: ['bottomCenter'],
              itemRender: (page, type, element) => {
                if (type === 'jump-prev') {
                  return element;
                }
                if (type === 'jump-next') {
                  return element;
                }

                if (type === 'next') {
                  return (
                    <a className="custom-list__pagination--next custom-list__pagination--button">
                      {element}
                    </a>
                  );
                }
                if (type === 'prev') {
                  return (
                    <a className="custom-list__pagination--prev custom-list__pagination--button">
                      {element}
                    </a>
                  );
                }
                return page;
              },
              showSizeChanger: false,
              showQuickJumper: false,
              ...pagination,
            }
          : undefined
      }
      size="small"
      tableLayout="fixed"
      {...rest}
    />
  );
};

export { SimpleTable };
