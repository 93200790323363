import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Select } from '@shared/UI';
import { getAllCompanies, getCompanies } from '@store/actions/user/Company';
import {
  selectAllCompaniesList,
  selectCompaniesList,
  selectCompanyStatus,
} from '@store/selectors/users/Company';

import type { ItemProps } from '@shared/UI';

interface SelectCompanyProps extends ItemProps {
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  disabled?: boolean;
  restict?: boolean | true;
}

const { Item } = CommonForm;

const SelectCompany = (props: SelectCompanyProps) => {
  const {
    rules: rulesProps,
    name,
    mode,
    label,
    disabled,
    restict,
    placeholder,
    ...restItem
  } = props;
  const { t } = useTranslation();

  const listCompanies = useAppSelector(restict ? selectCompaniesList : selectAllCompaniesList);
  const companyStatus = useAppSelector(selectCompanyStatus);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listCompanies.length) {
      restict ? dispatch(getCompanies()) : dispatch(getAllCompanies());
    }
  }, []);

  const options = useMemo(() => {
    return listCompanies.map((company) => ({
      value: company.companyId,
      label: company.name,
    }));
  }, [listCompanies]);

  return (
    <Item
      name={name || 'companyId'}
      label={label || t('pages_single.Company')}
      rules={rulesProps}
      {...restItem}
    >
      <Select
        mode={mode}
        options={options}
        placeholder={placeholder || t('titles.Choose')}
        loading={companyStatus === 'pending'}
        disabled={disabled}
      />
    </Item>
  );
};

export { SelectCompany };
