import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToolbarDropdownItem } from '@components/common';
import { useModalState } from '@hooks';
import { LocationIcon } from '@icon/icon-components';

import { useAssetListContext } from '../../../../AssetListProvider';
import { SiteModal } from './SiteModal';

export const SiteDropdown = memo(() => {
  const siteDropdown = useModalState();

  const { t } = useTranslation();

  const siteModal = useModalState();

  const { listSites } = useAssetListContext();

  const [siteId, setSiteId] = useState<null | number>(null);

  const handleOpenChange = () => {
    siteDropdown.actions.onToggle();
  };

  const handleClickItem = (key: string) => {
    setSiteId(+key);

    siteDropdown.actions.onClose();

    siteModal.actions.onOpen();
  };

  return (
    <>
      <ToolbarDropdownItem
        isOpen={siteDropdown.isOpen}
        label={t('titles.Change_With_Name', { name: t('titles.Site') })}
        icon={<LocationIcon fill={siteDropdown.isOpen ? '#74AAEA' : '#fff'} />}
        items={listSites}
        fieldNames={{ label: 'name', key: 'siteId' }}
        onOpenChange={handleOpenChange}
        onClickItem={handleClickItem}
      />
      <SiteModal isOpen={siteModal.isOpen} onClose={siteModal.actions.onClose} siteId={siteId} />
    </>
  );
});
