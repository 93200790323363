import React from 'react';

import { SmallImagePreviewIcon, TrashCan } from '@icon/icon-components';

import { useCheckStepContext } from '../_utils';
import { StepCount } from '../StepCount';

import styles from './TitleTakePhoto.module.scss';

export const TitleTakePhoto = () => {
  const { actions, step, isVisibleActions, type } = useCheckStepContext();
  const handleRemoveClick = () => {
    actions?.onRemoveStep?.(step);
  };

  return (
    <div className={styles.titleTakePhoto}>
      <StepCount />
      <div className={styles.content}>
        <span>
          <SmallImagePreviewIcon />
        </span>

        <span className={styles.title}>Take Photo</span>
      </div>

      {isVisibleActions && type !== 'workStep' && (
        <span className={styles.icon} onClick={handleRemoveClick}>
          <TrashCan />
        </span>
      )}
    </div>
  );
};
