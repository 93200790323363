import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import {
  selectMaAtCategoriesList,
  selectMaAtCategoryStatus,
} from '@store/selectors/properties/MaAt/Category';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { MaAtCategoryModel } from '@model/properties/MaAt/Category';

const columns: ColumnsTableType<MaAtCategoryModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'categoryCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.categoryCode, b.categoryCode),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'categoryName',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.categoryName, b.categoryName),
  },
];

const MaintenanceCategories = () => {
  const { t } = useTranslation();

  const maintenanceCategoryList = useAppSelector(selectMaAtCategoriesList);
  const maintenanceCategoryStatus = useAppSelector(selectMaAtCategoryStatus);

  const { maintenanceAttrAccess } = useGetAccess();

  return (
    <BasicTablePage
      table={{
        loading: maintenanceCategoryStatus === 'pending',
        dataSource: maintenanceCategoryList,
        columns: columns,
        rowKey: (record) => record.maintinanceCategoryId,
        dataSourceName: t('pages_plural.Maintenance_Attr_Category'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', {
          name: t('pages_single.Maintenance_Attr_Category'),
        }),
        csvConfig: {
          data: maintenanceCategoryList,
          fileName: 'maintenance-categories.csv',
          headers: [
            {
              label: 'Code',
              key: 'categoryCode',
            },
            {
              label: 'Name',
              key: 'categoryName',
            },
          ],
        },
        newButtonProps: {
          disabled: !maintenanceAttrAccess.create,
        },
      }}
    />
  );
};

export { MaintenanceCategories };
