import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getAssetById } from '@store/actions/management/Asset';
import { selectAsset } from '@store/selectors/management/Asset';
import { selectAssetPartsStatus } from '@store/selectors/management/AssetParts';
import { v4 as uuidv4 } from 'uuid';

import type { IOutletAsset } from '@constants/OutletContextEntityPages';
import type { TabType } from '@constants/pageInfo';
import type { CreateAssetDto } from '@model/management/Asset';

function Asset() {
  const asset = useAppSelector(selectAsset);
  const assetPartsStatus = useAppSelector(selectAssetPartsStatus);
  const dispatch = useAppDispatch();
  const { decryptedId, encryptedId } = useHandleParamId();

  const tabs = [
    {
      ...paths.asset.tabs.general,
      default: `/assets/${encryptedId}`,
    },
    // {
    //   ...paths.asset.tabs.reports,
    //   default: `assets/${encryptedId}/reports`,
    // },
    {
      ...paths.asset.tabs.parts,
      default: `assets/${encryptedId}/parts`,
    },
  ] as TabType[];

  useSetPageInfo(
    {
      pageTitle: 'pages_single.Asset',
      defaultPagePath: paths.asset.default,
      tabs,
    },
    { copyTabs: true }
  );

  const condition = !asset || asset.nonCurrAssetId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getAssetById(decryptedId));
    }
  }, []);

  const contractIds =
    asset?.nonCurrAssetContracts && asset?.nonCurrAssetContracts.length
      ? asset.nonCurrAssetContracts.map((contract) => contract.contractId)
      : [];

  const nonCurrAssetPropertyDetalisModelList =
    asset?.nonCurrAssetPropertyDetails && asset?.nonCurrAssetPropertyDetails.length
      ? asset.nonCurrAssetPropertyDetails.map((propertyDetail) => ({
          value: propertyDetail.value,
          nonCurrAssetCardPropertyId: propertyDetail.nonCurrAssetCardPropertyId,
          nonCurrAssetPropertyValueId: propertyDetail.nonCurrAssetPropertyDetailId,
        }))
      : [];

  const assignedUserId =
    asset?.nonCurrAssetUsers && asset?.nonCurrAssetUsers.length
      ? asset.nonCurrAssetUsers[0]?.user.userId
      : null;

  const labelsList = asset?.labels
    ? asset.labels.map((label) => ({
        label: label.label,
        value: label.label,
        labelColor: label.labelColor,
      }))
    : [];

  const images = asset?.nonCurrAssetFiles
    ? asset.nonCurrAssetFiles
        .map((file) => ({
          file,
          id: uuidv4(),
        }))
        .sort((a, b) => {
          if (!a.file.rowNumber || !b.file.rowNumber) {
            return 0;
          }
          return a.file.rowNumber - b.file.rowNumber;
        })
    : null;

  if (condition || assetPartsStatus === 'pending') {
    return <Loader middle />;
  }

  const initialValues: CreateAssetDto = {
    barcode: asset.barcode,
    nonCurrAssetCardId: asset.nonCurrAssetCardId,
    nonCurrAssetCardModelId: asset.nonCurrAssetCardModelId,
    nonCurrAssetCardBrandId: asset.nonCurrAssetCardBrandId,
    serialNumber: asset.serialNumber,
    nonCurrAssetCardCategoryId: asset.nonCurrAssetCardCategoryId,
    partnerId: asset.partnerId,
    siteId: asset.siteId,
    invoiceDate: asset.invoiceDate,
    statusId: asset.statusId,
    invoiceNo: asset.invoiceNo,
    invoiceAmount: asset.price,
    warrantyStartDate: asset.guaranteeStartDate,
    warrantyEndDate: asset.guaranteeEndDate,
    contractIds,
    costCenterId: asset.costCenterId,
    currencyId: asset.currencyId,
    nonCurrAssetPropertyDetalisModelList,
    description: asset.description,
    assignedUserId,
    labelsList,
    images: images || [],
  };

  return (
    <Outlet
      context={
        {
          initialValues,
          entity: asset,
        } as IOutletAsset
      }
    />
  );
}

export default Asset;
