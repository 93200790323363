import type { RootState } from '@store/rootReducer';

export const selectVendorState = (state: RootState) => state.VendorReducer;

export const selectVendorsList = (state: RootState) => selectVendorState(state).vendorsList;

export const selectVendor = (state: RootState) => selectVendorState(state).vendor;

export const selectVendorStatus = (state: RootState) => selectVendorState(state).vendorStatus;

export const selectVendorError = (state: RootState) => selectVendorState(state).vendorError;

export const selectVendorsFilteredList = (state: RootState) =>
  selectVendorState(state).vendorsFilteredList;

export const selectVendorFilters = (state: RootState) => selectVendorState(state).vendorFilters;
