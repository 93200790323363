import React from 'react';
import { Translation } from 'react-i18next';

export const occurrenceOptions = [
  {
    label: <Translation>{(t) => t('titles.Never')}</Translation>,
    value: 0,
  },
  {
    label: <Translation>{(t) => t('titles.Every_Day')}</Translation>,
    value: 1,
  },
  {
    label: <Translation>{(t) => t('titles.Every_Week')}</Translation>,
    value: 2,
  },
  {
    label: <Translation>{(t) => t('titles.Every_Month')}</Translation>,
    value: 3,
  },
];
