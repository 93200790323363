import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsAtPropertyRepository } from '@repositories';

import type {
  AsAtPropertyModel,
  AsAtPropertyTypeModel,
  CreateAsAtPropertyDto,
  UpdateAsAtPropertyDto,
} from '@model/properties/AsAt/Property';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getAsAtProperties = createAsyncThunk<
  AsAtPropertyModel[],
  void,
  { rejectValue: string }
>('AsAtProperty/getAsAtProperties', async (_, { rejectWithValue }) => {
  try {
    const response = await AsAtPropertyRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getAsAtPropertyTypes = createAsyncThunk<
  AsAtPropertyTypeModel[],
  void,
  { rejectValue: string }
>('AsAtProperty/getAsAtPropertyTypes', async (_, { rejectWithValue }) => {
  try {
    const response = await AsAtPropertyRepository.fetchAsAtPropertyTypes();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getAsAtPropertyById = createAsyncThunk<
  AsAtPropertyModel,
  string,
  { rejectValue: string }
>('AsAtProperty/getAsAtPropertyById', async (id, { rejectWithValue }) => {
  try {
    const response = await AsAtPropertyRepository.fetchById(id);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addAsAtProperty = createAsyncThunk<
  AsAtPropertyModel,
  CreateAsAtPropertyDto,
  { rejectValue: string }
>('AsAtProperty/addAsAtProperty', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtPropertyRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateAsAtProperty = createAsyncThunk<
  { AsAtPropertiesList: AsAtPropertyModel[]; AsAtProperty: AsAtPropertyModel },
  UpdateAsAtPropertyDto,
  { rejectValue: string }
>('AsAtProperty/updateAsAtProperty', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtPropertyRepository.update(dto);

    const responseList = await AsAtPropertyRepository.fetchAll();

    return {
      AsAtPropertiesList: responseList.resultObject,
      AsAtProperty: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeAsAtPropertyById = createAsyncThunk<
  AsAtPropertyModel[],
  number[],
  { rejectValue: string }
>('AsAtProperty/removeAsAtPropertyById', async (ids, { rejectWithValue }) => {
  try {
    await AsAtPropertyRepository.removeById(ids);
    const response = await AsAtPropertyRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
