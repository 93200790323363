import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormAssetCategory } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addAsAtCategory } from '@store/actions/properties/AsAt/Category';

import type { CreateAsAtCategoryDto } from '@model/properties/AsAt/Category';
import type { FormInstance } from 'antd';

function CreateAssetCategory() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { assetAttrAccess } = useGetAccess();

  const onFinish = (dto: CreateAsAtCategoryDto, form?: FormInstance<CreateAsAtCategoryDto>) => {
    dispatch(addAsAtCategory(dto))
      .unwrap()
      .then((payload) => {
        ApiNotifications.create(payload.AsAtCategory.name);
        navigate(paths.assetAttribute.tabs.category.default);
        form?.resetFields();
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <CheckAccess
      hasAccess={assetAttrAccess.create}
      redirectTo={paths.assetAttribute.tabs.category.default}
    >
      <FormAssetCategory
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Category') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreateAssetCategory;
