import React from 'react';

import { Col } from 'antd';
import cl from 'classnames';

import type { ColProps } from 'antd';

import './RowCell.scss';

export interface RowCellProps extends Omit<ColProps, 'className'> {
  className?: string | string[];
  label?: React.ReactNode;
  description?: React.ReactNode;
}

const RowCell = (props: RowCellProps) => {
  const { span = 3, className, children, label, description, ...rest } = props;

  const rowCellClasses = cl('common-list-row-cell', className);

  const renderDefaultContent = (
    <>
      <div className="row-cell-default">
        <h6>{label}</h6>
        <p>{description}</p>
      </div>
    </>
  );

  return (
    <Col
      className={rowCellClasses}
      span={span}
      {...rest}
      style={{
        textAlign: 'start',
      }}
    >
      {children ? children : renderDefaultContent}
    </Col>
  );
};

export { RowCell };
