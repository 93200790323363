import { createSlice } from '@reduxjs/toolkit';
import { setTabs, updateActivePage, updatePageInfo } from '@store/actions/extra/Local';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { IPageInfo, ITable } from '@constants/pageInfo';

type LocalPageState = {
  pageInfo: IPageInfo;
  tableState: ITable;
  status: LoadingStatus;
  currentRequestId: string | null;
};

const initialState: LocalPageState = {
  pageInfo: {
    pageTitle: '',
    defaultPagePath: '',
    tabs: null,
    strictCompareTabs: false,
  },
  tableState: {
    activePage: 1,
  },
  status: 'idle',
  currentRequestId: null,
};

const LocalSlice = createSlice({
  name: 'localPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePageInfo.pending, (state, { meta }) => {
        state.status = 'pending';
        state.currentRequestId = meta.requestId;
      })
      .addCase(updatePageInfo.fulfilled, (state, { payload, meta }) => {
        if (state.currentRequestId === meta.requestId && payload) {
          state.status = 'idle';
          state.currentRequestId = null;
          state.pageInfo = payload;
        }
      });
    builder.addCase(setTabs.fulfilled, (state, { payload }) => {
      state.pageInfo.tabs = payload;
    });
    builder.addCase(updateActivePage.fulfilled, (state, { payload }) => {
      state.tableState.activePage = payload;
    });
  },
});

const LocalReducer = LocalSlice.reducer;

export { LocalReducer };
