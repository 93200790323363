import React, { useCallback } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { AppstoreOutlined, BarsOutlined, DownloadOutlined } from '@ant-design/icons';
import { DataFilter } from '@components/common/DataFilter';
import { useAppNavigate } from '@hooks';
import { basePathChildren } from '@routes/paths';
import { Button, PageHeader, Segment, Select } from '@shared/UI';
import { Dropdown, Space } from 'antd';

import type { DataFilterProps } from '@components/common/DataFilter';
import type { CSVConfig } from '@interfaces/interfaces';
import type { ButtonProps, MenuProps, PageHeaderProps, SegmentProps , SelectProps } from '@shared/UI';
import type { DropdownProps} from 'antd';

import './Header.scss';

export interface HeaderProps<
  ST = any,
  SV = any,
  TCSV extends Record<string, any> = Record<string, any>
> extends PageHeaderProps {
  titleRedirectButton: string;
  onlyRedirectButton?: boolean;
  showTitleHeader?: boolean;
  sort?: {
    sortOptions: {
      label: string;
      value: string | number;
      [key: string]: string | number;
    }[];

    handleSort?: (value: string | number) => void;

    value?: string | number | null;
  };
  filterByLabels: Omit<SelectProps<ST, SV>, 'mode'>;
  onChangeView?: (view: string) => void;
  valueView?: string;
  csvConfig?: CSVConfig<TCSV>;
  filterProps?: DataFilterProps;

  newButtonProps?: ButtonProps;

  dropdownProps?: DropdownProps;

  switcherProps?: Partial<SegmentProps>;

  sortSelectProps?: SelectProps;

  showDropdown?: boolean;

  showSwitcher?: boolean;

  showSortSelect?: boolean;

  showFilterSelect?: boolean;

  showDataFilter?: boolean;
}

const Header = <
  ST,
  SV extends { value: string; label: string; color: string },
  TCSV extends Record<string, any>
>(
  props: HeaderProps<ST, SV, TCSV>
) => {
  const {
    titleRedirectButton,
    onlyRedirectButton = false,
    ghost = false,
    title,
    extra,
    showTitleHeader = true,
    sort,
    filterByLabels,
    filterProps,
    onChangeView,
    valueView,
    csvConfig,
    newButtonProps,
    dropdownProps,
    sortSelectProps,
    switcherProps,
    showDropdown = true,
    showSwitcher = true,
    showSortSelect = true,
    showFilterSelect = true,
    showDataFilter = true,
    ...rest
  } = props;
  const navigate = useAppNavigate();

  const { t } = useTranslation();

  const redirectToCreatePage = useCallback(() => {
    navigate(basePathChildren.create);
  }, []);

  /* ---------------------------- Handle view type ---------------------------- */

  const viewSwitcher = showSwitcher ? (
    <Segment
      value={valueView}
      onChange={onChangeView as SegmentProps['onChange']}
      options={[
        {
          value: 'list',
          icon: <BarsOutlined />,
        },
        {
          value: 'grid',
          icon: <AppstoreOutlined />,
        },
      ]}
      {...switcherProps}
    />
  ) : null;

  const sortSelect = showSortSelect ? (
    <Select
      className={'header-sort'}
      options={sort?.sortOptions}
      placeholder={t('titles.Sort_By')}
      onChange={sort?.handleSort}
      value={sort?.value}
      {...sortSelectProps}
    />
  ) : null;

  const filterSelect = showFilterSelect ? (
    <Select
      style={{
        width: 350,
      }}
      mode="multiple"
      showArrow
      {...filterByLabels}
    />
  ) : null;

  const defaultTitle = (
    <>
      {viewSwitcher}
      {sortSelect}
      {filterSelect}
    </>
  );

  const items: MenuProps['items'] = [
    {
      icon: <DownloadOutlined />,
      className: 'table-dropdown',
      key: 0,
      label:
        <CSVLink
          data={csvConfig?.data ? csvConfig?.data : []}
          headers={csvConfig?.headers}
          filename={csvConfig?.fileName}
          onClick={csvConfig?.onClick}
        >
          <span style={{color: 'black'}}>{t('titles.Export_Excel')}</span>
        </CSVLink>
    },
  ];

  const dropdown = showDropdown ? (
    <Dropdown menu={{ items }} trigger={['click']} overlayClassName='table-dropdown' {...dropdownProps}>
      <Space className='table-dropdown-icon'>
        <span>...</span>
      </Space>
    </Dropdown>
  ) : null;

  const dataFilter = filterProps && showDataFilter ? <DataFilter {...filterProps} /> : null;

  const defaultExtraActions: PageHeaderProps['extra'] = [dataFilter, dropdown];

  const defaultExtra: PageHeaderProps['extra'] = [
    !onlyRedirectButton ? defaultExtraActions : null,
    <Button onClick={redirectToCreatePage} key="1" type="primary" {...newButtonProps}>
      {titleRedirectButton}
    </Button>,
  ];

  const showTitle = showTitleHeader ? (title ? title : defaultTitle) : null;

  return (
    <PageHeader
      {...rest}
      className="list-page__header"
      ghost={ghost}
      title={showTitle}
      extra={extra ? extra : defaultExtra}
      {...rest}
    />
  );
};

export { Header };
