import React from 'react';

import type { SVGProps } from 'react';

const CheckRound = (props: SVGProps<SVGSVGElement>) => (
  <>
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.5C4.925 0.5 0 5.425 0 11.5C0 17.575 4.925 22.5 11 22.5C17.075 22.5 22 17.575 22 11.5C22 5.425 17.075 0.5 11 0.5ZM15.768 9.64C15.8558 9.53964 15.9226 9.42274 15.9646 9.29617C16.0065 9.1696 16.0227 9.03591 16.0123 8.90298C16.0018 8.77005 15.9648 8.64056 15.9036 8.52213C15.8423 8.40369 15.758 8.29871 15.6555 8.21334C15.5531 8.12798 15.4346 8.06396 15.3071 8.02506C15.1796 7.98616 15.0455 7.97316 14.9129 7.98683C14.7802 8.00049 14.6517 8.04055 14.5347 8.10463C14.4178 8.16872 14.3149 8.25554 14.232 8.36L9.932 13.519L7.707 11.293C7.5184 11.1108 7.2658 11.01 7.0036 11.0123C6.7414 11.0146 6.49059 11.1198 6.30518 11.3052C6.11977 11.4906 6.0146 11.7414 6.01233 12.0036C6.01005 12.2658 6.11084 12.5184 6.293 12.707L9.293 15.707C9.39126 15.8052 9.50889 15.8818 9.63842 15.9321C9.76794 15.9823 9.9065 16.005 10.0453 15.9986C10.184 15.9923 10.32 15.9572 10.4444 15.8954C10.5688 15.8337 10.6791 15.7467 10.768 15.64L15.768 9.64Z"
        fill="#0C7A02"
      />
    </svg>
  </>
);

export { CheckRound };
