import * as React from 'react';

import type { SVGProps } from 'react';

const FlagEuropeanUnion = (props: SVGProps<SVGSVGElement>) => (
  <svg 
    width="24" 
    height="25" 
    viewBox="0 0 24 25"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_11255_292240)">
    <path d="M21.3333 3.40186H2.66667C1.95942 3.40186 1.28115 3.68281 0.781049 4.1829C0.280952 4.683 0 5.36128 0 6.06852L0 18.0685C0 18.7758 0.280952 19.454 0.781049 19.9541C1.28115 20.4542 1.95942 20.7352 2.66667 20.7352H21.3333C22.0406 20.7352 22.7189 20.4542 23.219 19.9541C23.719 19.454 24 18.7758 24 18.0685V6.06852C24 5.36128 23.719 4.683 23.219 4.1829C22.7189 3.68281 22.0406 3.40186 21.3333 3.40186Z" fill="#003399"/>
    <path d="M12.3597 6.53858L12.9257 6.12725H12.2264L12.0097 5.46191L11.7937 6.12725H11.0944L11.6604 6.53858L11.4437 7.20391L12.0097 6.79258L12.5757 7.20391L12.3597 6.53858ZM12.3597 18.0939L12.9257 17.6826H12.2264L12.0097 17.0172L11.7937 17.6826H11.0944L11.6604 18.0939L11.4437 18.7592L12.0097 18.3479L12.5757 18.7592L12.3597 18.0939ZM6.58241 12.3159L7.14841 11.9046H6.44841L6.23241 11.2392L6.01574 11.9046H5.31641L5.88241 12.3159L5.66641 12.9812L6.23241 12.5699L6.79841 12.9812L6.58241 12.3159ZM7.32041 9.45925L7.88641 9.04791H7.18641L6.97041 8.38258L6.75441 9.04791H6.05441L6.62041 9.45925L6.40441 10.1246L6.97041 9.71325L7.53641 10.1246L7.32041 9.45925ZM7.32041 15.2052L7.88641 14.7939H7.18641L6.97041 14.1286L6.75441 14.7939H6.05441L6.62041 15.2052L6.40441 15.8706L6.97041 15.4592L7.53641 15.8706L7.32041 15.2052ZM9.47107 7.31258L10.0371 6.90125H9.33707L9.12107 6.23591L8.90507 6.90125H8.20507L8.77107 7.31258L8.55507 7.97791L9.12107 7.56658L9.68707 7.97791L9.47107 7.31258ZM9.47107 17.3572L10.0371 16.9459H9.33707L9.12107 16.2806L8.90507 16.9459H8.20507L8.77107 17.3572L8.55507 18.0226L9.12107 17.6119L9.68707 18.0226L9.47107 17.3572ZM17.4184 12.3159L16.8524 11.9046H17.5524L17.7684 11.2392L17.9851 11.9046H18.6844L18.1184 12.3159L18.3344 12.9812L17.7684 12.5699L17.2024 12.9812L17.4184 12.3159ZM16.6804 9.45925L16.1144 9.04791H16.8144L17.0304 8.38258L17.2464 9.04791H17.9464L17.3804 9.45925L17.5964 10.1246L17.0304 9.71325L16.4644 10.1246L16.6804 9.45925ZM16.6804 15.2052L16.1144 14.7939H16.8144L17.0304 14.1286L17.2464 14.7939H17.9464L17.3804 15.2052L17.5964 15.8706L17.0304 15.4592L16.4644 15.8706L16.6804 15.2052ZM14.5297 7.31258L13.9637 6.90125H14.6637L14.8797 6.23591L15.0957 6.90125H15.7957L15.2297 7.31258L15.4457 7.97791L14.8797 7.56658L14.3137 7.97791L14.5297 7.31258ZM14.5297 17.3572L13.9637 16.9459H14.6637L14.8797 16.2806L15.0957 16.9459H15.7957L15.2297 17.3572L15.4457 18.0226L14.8797 17.6119L14.3137 18.0226L14.5297 17.3572Z" fill="#FFCC00"/>
    </g>
    <defs>
    <clipPath id="clip0_11255_292240">
    <rect width="24" height="24" fill="white" transform="translate(0 0.0683594)"/>
    </clipPath>
    </defs>
  </svg>
);

export { FlagEuropeanUnion };
