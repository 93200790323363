import React from 'react';

import { Table as AntdTable } from 'antd';
import cl from 'classnames';

import type { TableProps as AntdTableProps } from 'antd';

import './Table.scss';

export type TableProps<T extends Record<string, any>> = {} & AntdTableProps<T>;

const Table = <T extends Record<string, any>>(props: TableProps<T>) => {
  const {
    rowKey,
    pagination,
    bordered = false,
    showSorterTooltip = false,
    tableLayout = 'fixed',
    size = 'middle',
    className,
    ...rest
  } = props;

  const tableClasses = cl(className, 'custom-table');

  const itemRender = (
    page: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    element: React.ReactNode
  ) => {
    if (type === 'jump-prev') {
      return element;
    }
    if (type === 'jump-next') {
      return element;
    }

    if (type === 'next') {
      return (
        <a className="custom-table__pagination--next custom-table__pagination--button">
          {element}
        </a>
      );
    }
    if (type === 'prev') {
      return (
        <a className="custom-table__pagination--prev custom-table__pagination--button">
          {element}
        </a>
      );
    }
    return page;
  };

  const paginationProps: TableProps<T>['pagination'] = pagination
    ? {
        position: ['bottomCenter'],
        itemRender: itemRender,
        showSizeChanger: false,
        ...pagination,
      }
    : false;

  return (
    <AntdTable
      rowKey={rowKey}
      className={tableClasses}
      pagination={paginationProps}
      bordered={bordered}
      showSorterTooltip={showSorterTooltip}
      tableLayout={tableLayout}
      size={size}
      {...rest}
    />
  );
};

export default React.memo(Table) as typeof Table;
