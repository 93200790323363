import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { FormAssetParts } from '@components/views/Forms/managment';
import { useAppDispatch, useAppNavigate, useAppSelector } from '@hooks';
import { addAssetParts, updateAssetParts } from '@store/actions/management/AssetParts';
import { selectAssetParts } from '@store/selectors/management/AssetParts';

import type {
  CreateAssetPartDto,
  CreatePartDto,
  UpdateAssetPartDto,
  UpdatePartDto,
} from '@model/management/AssetParts';
import type { AssetPartsOutlet } from '../_utils';

export function EditAssetParts() {
  const { entity, initialValues } = useOutletContext<AssetPartsOutlet>();

  const assetParts = useAppSelector(selectAssetParts);

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const getNewParts = (parts: (CreatePartDto | UpdatePartDto)[]): CreatePartDto[] => {
    return parts.reduce((acc: CreatePartDto[], part) => {
      if (!('nonCurrAssetsPartsId' in part && 'nonCurrAssetId' in part)) {
        acc.push(part);
      }
      return acc;
    }, []);
  };

  const wasUpdated = (updPart: UpdatePartDto) => {
    const part = assetParts.find((p) => p.nonCurrAssetsPartsId === updPart.nonCurrAssetsPartsId);

    if (!part) {
      return false;
    }
    return part.partsAndInventoriesId !== updPart.partsAndInventoriesId;
  };

  const getUpdatedParts = (parts: (CreatePartDto | UpdatePartDto)[]): UpdatePartDto[] => {
    return parts.reduce((acc: UpdatePartDto[], part) => {
      if ('nonCurrAssetsPartsId' in part && 'nonCurrAssetId' in part) {
        if (wasUpdated(part)) {
          acc.push(part);
        }
      }
      return acc;
    }, []);
  };

  const handleCreateParts = (mpmodel: CreatePartDto[]) => {
    if (!mpmodel.length) {
      return;
    }
    const dto: CreateAssetPartDto = {
      nonCurrAssetId: entity.nonCurrAssetId,
      mpmodel,
    };

    dispatch(addAssetParts(dto));
  };
  const handleUpdateParts = (mpmodel: UpdatePartDto[]) => {
    if (!mpmodel.length) {
      return;
    }
    const dto: UpdateAssetPartDto = {
      nonCurrAssetId: entity.nonCurrAssetId,
      mpmodel,
    };
    dispatch(updateAssetParts(dto));
  };

  const handleFinish = (dto: CreateAssetPartDto) => {
    const newParts = getNewParts(dto.mpmodel);
    const updatedParts = getUpdatedParts(dto.mpmodel);

    handleCreateParts(newParts);
    handleUpdateParts(updatedParts);

    navigate('..');
  };

  return (
    <FormAssetParts
      onFinish={handleFinish}
      initialValues={initialValues}
      header={{
        title: entity.barcode,
      }}
    />
  );
}
