import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import { selectAsAtCardsList, selectAsAtCardStatus } from '@store/selectors/properties/AsAt/Card';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { AsAtCardModel } from '@model/properties/AsAt/Card';

const columns: ColumnsTableType<AsAtCardModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'nonCurrAssetCardCode',
    key: 'nonCurrAssetCardCode',
    ellipsis: true,
    sorter: (a, b) => compare(a.nonCurrAssetCardCode, b.nonCurrAssetCardCode),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
  {
    title: <Translation>{(t) => t('titles.Category')}</Translation>,
    dataIndex: ['nonCurrAssetCardCategory', 'name'],
    key: 'category',
    ellipsis: true,
    sorter: (a, b) => compare(a.nonCurrAssetCardCategory.name, b.nonCurrAssetCardCategory.name),
  },
];

const AssetNames = () => {
  const {t} = useTranslation();

  const {assetAttrAccess} = useGetAccess();

  const listAssetName = useAppSelector(selectAsAtCardsList);
  const statusAssetName = useAppSelector(selectAsAtCardStatus);

  const csvData = useMemo(
    () =>
      listAssetName.map((name) => ({
        code: name.nonCurrAssetCardCode,
        name: name.name,
        category: name.nonCurrAssetCardCategory.name,
      })),
    [listAssetName]
  );

  return (
    <BasicTablePage
      table={{
        loading: statusAssetName === 'pending',
        dataSource: listAssetName,
        columns: columns,
        rowKey: (record) => record.nonCurrAssetCardId,
        dataSourceName: t('pages_single.PaI_Attr_Name'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', {name: t('pages_single.PaI_Attr_Name')}),
        csvConfig: {
          data: csvData,
          fileName: 'asset-names.csv',
          headers: [
            {label: 'Code', key: 'code'},
            {label: 'Name', key: 'name'},
            {label: 'Category', key: 'category'},
          ],
        },
        newButtonProps: {
          disabled: !assetAttrAccess.create
        }
      }}
    />
  );
};

export {AssetNames};
