import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { TrashCan } from '@icon/icon-components';
import { Select } from '@shared/UI';
import { selectPaIsTypeNameListAllRecords } from '@store/selectors/management/PaI';

import styles from './Fields.module.scss';

interface FieldsProps {
  onDelete?: () => void;

  name: number;
}

const { Item } = CommonForm;

const paiOptionNames = {
  label: 'partsAndInventoriesCardName',
  value: 'partsAndInventoriesId',
};

const rules = [
  {
    required: true,
  },
];

export const Fields = (props: FieldsProps) => {
  const { onDelete, name } = props;

  const paiList = useAppSelector(selectPaIsTypeNameListAllRecords);

  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Item
        name={[name, 'partsAndInventoriesId']}
        label={t('titles.Name')}
        rules={rules}
        className={styles.nameItem}
      >
        <Select allowClear={false} options={paiList} fieldNames={paiOptionNames} />
      </Item>
      <button type="button" className={styles.deleteButton} onClick={onDelete}>
        <TrashCan />
      </button>
    </div>
  );
};
