import { useContext } from 'react';

import { PaIToolbarContext } from './PaIToolbarContext';

export function usePaIToolbarContext() {
  const ctx = useContext(PaIToolbarContext);

  if (ctx === undefined || ctx === null) {
    throw new Error('usePaIToolbarContext must be inside a Provider with a value');
  }
  return ctx;
}
