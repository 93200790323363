import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormCompany } from '@components/views/Forms/users';
import { useOutletCompany } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateCompany } from '@store/actions/user/Company';

import type { CreateCompanyDto } from '@model/users/Company';

const Edit = () => {
  const { initialValues, entity } = useOutletCompany();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (dto: CreateCompanyDto) => {
    dispatch(updateCompany({ ...dto, companyId: entity.companyId }))
      .unwrap()
      .then(({ someCompany }) => {
        ApiNotifications.update(someCompany.name);
        navigate(paths.companies.default);
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <FormCompany
      initialValues={initialValues}
      onFinish={onFinish}
      header={{
        title: entity.name,
      }}
    />
  );
};

export default Edit;
