import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStepsContext } from '@components/providers/StepsProvider';
import { FormWorkOrders } from '@components/views/Forms/managment/Maintenance/FormWorkOrders';
import { paths } from '@routes/paths';

import { FormGeneralV2 as FormGeneralV2Update } from './GeneralV2';
import { FormPart } from './Part';

import type { CommonFormProps } from '@components/common';
import type { CheckStepValue } from '@constants/checkStepItems';
import type {
  CreateMaintenanceGeneralV2Dto,
  CreateMaintenanceStepsDto,
  MpModelDto,
} from '@model/management/Maintenance';
import type { FormInstance } from 'antd';

interface FormMaintenanceProps {
  onFinishGeneral?: (data: CreateMaintenanceGeneralV2Dto) => Promise<void>;

  onFinishSteps: (
    data: CreateMaintenanceStepsDto[],
    form?: FormInstance<CheckStepValue[]>
  ) => Promise<void>;

  onFinishPart: (data: MpModelDto[], form?: FormInstance<MpModelDto[]>) => Promise<void>;

  maintenanceId: number | null;

  userId: number;
}

function FormMaintenance(props: FormMaintenanceProps) {
  const {
    onFinishGeneral: onFinishGeneralProps,
    onFinishSteps: onFinishStepsProps,
    onFinishPart: onFinishPartProps,
    maintenanceId,
    userId,
  } = props;

  const { t } = useTranslation();

  const { step, onNextStep, onChangeStep } = useStepsContext();

  const navigate = useNavigate();

  const onFinishGeneral = async (dto: CreateMaintenanceGeneralV2Dto) => {
    try {
      await onFinishGeneralProps?.(dto);
      onNextStep();
    } catch (e) {}
  };

  const handleWorkStepsFinish = (dto: CreateMaintenanceStepsDto[]) => {
    onFinishStepsProps(dto).then(() => {
      onNextStep();
    });
  };

  const onFinishPart = async (dto: MpModelDto[], form?: FormInstance<MpModelDto[]>) => {
    onFinishPartProps(dto, form).then(() => {
      onChangeStep(0);
      navigate(paths.maintenance.default);
    });
  };

  const onClickCancel = () => {
    if (step === 0 || step === 2) {
      navigate(paths.maintenance.default);
      return;
    }

    if (step === 1) {
      onChangeStep(2);
    }
  };

  const headerConfigurations: CommonFormProps['header'] = useMemo(() => {
    return {
      textSaveButton: step === 2 ? t('titles.Save') : t('titles.Save_And_Next'),
      textCancelButton: step === 0 ? t('titles.Cancel') : t('titles.Skip'),
      onClickCancel,
      title: t('titles.New_Item', { name: t('pages_single.Maintenance') }),
    };
  }, [step, t]);

  return (
    <>
      {step === 0 && (
        <FormGeneralV2Update onFinish={onFinishGeneral} header={headerConfigurations} />
      )}
      {step === 1 && (
        <FormWorkOrders
          onFinish={handleWorkStepsFinish}
          header={headerConfigurations}
          maintenanceId={maintenanceId!}
          userId={userId}
        />
      )}
      {step === 2 && <FormPart onFinish={onFinishPart} header={headerConfigurations} />}
    </>
  );
}

export { FormMaintenance };
