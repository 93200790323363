import React, { memo, useCallback } from 'react';

import { useClassPrefix } from '@hooks';
import { DatePicker } from '@shared/UI';
import { createIterationDateLabel, formatIterationEndDate } from '@shared/utils/functions';
import moment from 'moment/moment';

import type { IterationMonthType } from '@constants/IterationMonthType';
import type { IterationValue } from '@constants/iterationValue';
import type { DayOfWeek } from '@shared/utils/types';
import type { Moment } from 'moment';

import './IterationEndDate.scss';

interface IterationEndDateProps {
  type: 'form' | 'preview';

  iterationType: IterationValue;

  startDate: Moment;

  endDate: Moment;

  daysOfWeek: Array<DayOfWeek>;

  monthType: IterationMonthType;

  onChange?: (endDate: Moment | null) => void;

  onClickPreviewLabel?: () => void;
}

export const IterationEndDate = memo((props: IterationEndDateProps) => {
  const {
    type,
    iterationType,
    daysOfWeek,
    monthType,
    startDate,
    endDate,
    onChange,
    onClickPreviewLabel,
  } = props;

  const {prefix, withPrefix} = useClassPrefix('iteration-endDate');

  const dateLabel = createIterationDateLabel(iterationType, daysOfWeek, startDate, monthType);

  const disabledDate = useCallback(
    (current: Moment) => {
      const date = moment(startDate);

      return (
        current > date.clone().add(1, 'year').endOf('day') || current < date.clone().add(1, 'day')
      );
    },
    [startDate, endDate]
  );

  const datePreview = (
    <span onClick={onClickPreviewLabel} className={withPrefix('preview-date')}>
      {formatIterationEndDate(endDate)}
    </span>
  );

  const elementForm = (
    <label className={withPrefix('label-date')}>
      <DatePicker
        className={withPrefix('endDate-picker')}
        onChange={onChange}
        value={endDate}
        disabledDate={disabledDate}
        allowClear={false}
      />
      {datePreview}
    </label>
  );

  const renderElementByType = () => {
    switch (type) {
      case 'form':
        return elementForm;
      case 'preview':
        return datePreview;
    }
  };

  return (
    <div className={prefix}>
      <p className={withPrefix('text-until')}>{dateLabel}</p>
      {renderElementByType()}
    </div>
  );
});
