import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Input, Select } from '@shared/UI';
import { getAsAtCategories } from '@store/actions/properties/AsAt/Category';
import { selectAsAtCardStatus } from '@store/selectors/properties/AsAt/Card';
import {
  selectAsAtCategoriesListWithoutChildren,
  selectAsAtCategoryStatus,
} from '@store/selectors/properties/AsAt/Category';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateAsAtCardDto } from '@model/properties/AsAt/Card';

const { CaseField, Item, useForm } = CommonForm;

function FormAssetName(props: CommonFormProps<CreateAsAtCardDto>) {
  const [form] = useForm<CreateAsAtCardDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const assetNameStatus = useAppSelector(selectAsAtCardStatus);
  const assetCategoryStatus = useAppSelector(selectAsAtCategoryStatus);
  const assetCategoryListWithoutChildren = useAppSelector(selectAsAtCategoriesListWithoutChildren);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!assetCategoryListWithoutChildren.length) {
      dispatch(getAsAtCategories());
    }
  }, []);

  const categoryOptions = useMemo(
    () =>
      assetCategoryListWithoutChildren.map((category) => ({
        value: category.nonCurrAssetCardCategoryId,
        label: category.name,
      })),
    [assetCategoryListWithoutChildren]
  );

  return (
    <CommonForm 
      form={form} 
      loading={assetNameStatus === 'pending'} 
      isModal={isModal}
      name='formAssetCard'
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onCancel={rest.header?.onClickCancel} /> : null
      }
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="nonCurrAssetCardCode"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item
          label={t('titles.Category')}
          name="nonCurrAssetCardCategoryId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={categoryOptions}
            placeholder={t('titles.Choose_Name', { name: t('titles.Category') })}
            loading={assetCategoryStatus === 'pending'}
          />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormAssetName };
