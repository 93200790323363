import { api } from '@services/Axios';

import type {
  CreateUserGroupDto,
  UpdateUserGroupDto,
  UserGroupModel,
} from '@model/users/UserGroup';
import type { ApiResponse } from '@services/Axios';

const UsGrEndpoint = '/Group';

export const UserGroupRepository = {
  async fetchAll() {
    const response: ApiResponse<UserGroupModel[]> = await api.get(`${UsGrEndpoint}/GetGroups`);
    return response.data;
  },
  async add(dto: CreateUserGroupDto) {
    const response: ApiResponse<UserGroupModel> = await api.post(`${UsGrEndpoint}/AddGroup`, dto);

    return response.data;
  },
  async update(dto: UpdateUserGroupDto) {
    const response: ApiResponse<UserGroupModel> = await api.post(
      `${UsGrEndpoint}/UpdateGroup`,
      dto
    );
    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<UserGroupModel> = await api.get(
      `${UsGrEndpoint}/GetGroupById/${id}`
    );
    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${UsGrEndpoint}/RemoveByIdList`, {
      requestReasonIds: ids,
    });
    return response.data;
  },
};
