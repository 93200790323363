import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormCheckOut } from '@components/views/Forms/properties';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addCheckOut } from '@store/actions/properties/CheckOut';

import type { CreateCheckOutDto } from '@model/properties/CheckOut';
import type { FormInstance } from 'antd';

function CreateCheckOut() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (dto: CreateCheckOutDto, form?: FormInstance<CreateCheckOutDto>) => {
    dispatch(addCheckOut(dto))
      .unwrap()
      .then((payload) => {
        ApiNotifications.create(payload.name);
        navigate(paths.checkOut.default);
        form?.resetFields();
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <FormCheckOut
      onFinish={onFinish}
      header={{
        title: t('titles.New_Item', { name: t('pages_single.Check_Out') }),
      }}
    />
  );
}

export default CreateCheckOut;
