import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useOutletAsset } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppSelector } from '@hooks';
import { getAssetPartsByAssetId } from '@store/actions/management/AssetParts';
import {
  selectAssetParts,
  selectAssetPartsCurrentAssetId,
} from '@store/selectors/management/AssetParts';

import type { AssetPartsOutlet } from './_utils';

export const AssetParts = () => {
  const { entity } = useOutletAsset();

  const assetParts = useAppSelector(selectAssetParts);
  const currentAssetId = useAppSelector(selectAssetPartsCurrentAssetId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!currentAssetId || currentAssetId !== entity.nonCurrAssetId) {
      dispatch(getAssetPartsByAssetId(entity.nonCurrAssetId));
    }
  }, []);

  const initialValues: AssetPartsOutlet['initialValues'] = {
    nonCurrAssetId: entity.nonCurrAssetId,
    mpmodel: assetParts.length
      ? assetParts.map((part) => ({
          partsAndInventoriesId: part.partsAndInventoriesId,
          nonCurrAssetId: part.nonCurrAssetId,
          nonCurrAssetsPartsId: part.nonCurrAssetsPartsId,
        }))
      : [],
  };

  const ctxValue: AssetPartsOutlet = {
    entity,
    initialValues,
  };

  return <Outlet context={ctxValue} />;
};
