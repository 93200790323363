import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlagEuropeanUnion, FlagTurkey, FlagUnitedStates } from '@icon/icon-components';
import cl from 'classnames';

import './LineChartCard.scss';

type InfoType = { 
  TL: number;
  USD: number;
  EUR: number;
  total: number;
};

type OrderInfoType = {
  InProgress: number;
  Open: number;
  Complete: number;
  total: number;
}

export interface LineChartCardProps {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  info?: InfoType;
  chart?: React.ReactElement;
  extra?: React.ReactElement;
  orderInfo?: OrderInfoType;
  size?: 'small' | 'medium' | 'large';
  inlineInfo?: boolean;
}

const LineChartCard = (props: LineChartCardProps) => {
  const { title, icon, extra, size = 'small', info, orderInfo } = props;

  const {t} = useTranslation();

  function sizeCard() {
    switch (size) {
      case 'small':
        return 'line-chart-card--small';
      case 'medium':
        return 'line-chart-card--medium';
      case 'large':
        return 'line-chart-card--large';
      default:
        return 'line-chart-card--small';
    }
  }

  const lineChartCardClasses = cl('line-chart-card', sizeCard());

  return (
    <div className={lineChartCardClasses}>
      <div className="line-chart-card__header">
        <div className="wrapper-title">
          <span>{icon}</span>
          <h6>{title}</h6>
        </div>
        <div className="line-chart-card__extra">{extra}</div>
      </div>
      <div className="line-chart-card__content">
        {orderInfo ? 
          <div className='content__quantity'>
            <p className='content__quantity__header'>{t('titles.Total')}: {orderInfo.total}</p>
            <div className='content__quantity__info'>
              <p>{t('titles.Open')}<span>{orderInfo.Open || 0}</span></p>
              <p>{t('titles.In_Progress')}<span>{orderInfo.InProgress || 0}</span></p>
              <p>{t('titles.Complete')}<span>{orderInfo.Complete || 0}</span></p>
            </div>
          </div>
        : 
          <>
            <div className='content__quantity'>
              <p className='content__quantity__header'>{t('titles.Quantity')}: {info && info.total}</p>
              <FlagTurkey />
              <p className='content__quantity__price'>{info?.TL || 0} TL</p>
            </div>
            <div className='content__other'>
              <div><FlagUnitedStates /> <p>{info?.USD || 0} USD</p></div>
              <div><FlagEuropeanUnion /> <p>{info?.EUR || 0} EUR</p></div>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export { LineChartCard };
