import React from 'react';
import {Translation} from 'react-i18next';

import {KindOfCheckStep} from '@constants/kindOfCheckStep';
import {useModalState} from '@hooks';
import {
  DecimalIcon,
  IntegerIcon,
  MultiplyChoice,
  TextIcon
} from '@icon/icon-components';
import {Inspection} from '@icon/icon-components/Inspection';
import {PlusButton} from '@shared/UI';
import {Tooltip} from 'antd';
import cl from 'classnames';

import './AddCheckStepButton.scss';

type Variant = {
  type: KindOfCheckStep;
  title: React.ReactNode;

  icon?: React.ReactNode;
};

export interface AddCheckStepButtonProps {
  className?: string;
  onClickVariant?: (variant: KindOfCheckStep) => void;
  style?: React.CSSProperties;

  newVariants?: Variant[]
}

const listOfVariants: Variant[] = [
  {
    type: KindOfCheckStep.MultiplyChoice,
    title: <Translation>{(t) => t('titles.Multiply_Choice')}</Translation>,
    icon: <MultiplyChoice/>,
  },
  {
    type: KindOfCheckStep.Text,
    title: <Translation>{(t) => t('titles.Text')}</Translation>,
    icon: <TextIcon/>,
  },
  {
    type: KindOfCheckStep.Inspection,
    title: <Translation>{(t) => t('titles.Inspection')}</Translation>,
    icon: <Inspection/>,
  },
  {
    type: KindOfCheckStep.Integer,
    title: <Translation>{(t) => t('titles.Integer')}</Translation>,
    icon: <IntegerIcon/>,
  },
  {
    type: KindOfCheckStep.Decimal,
    title: <Translation>{(t) => t('titles.Decimal')}</Translation>,
    icon: <DecimalIcon/>,
  },
];

const defaultClassPrefix = 'add-check-step-button';

const AddCheckStepButton = (props: AddCheckStepButtonProps) => {
  const {className, style, onClickVariant, newVariants = []} = props;

  const {
    isOpen,
    actions: {onToggle},
  } = useModalState(false);

  const defaultClasses = cl(defaultClassPrefix, className, {
    [`${defaultClassPrefix}--visible`]: isOpen,
  });

  const onChooseItem = (variant: KindOfCheckStep) => {
    return () => {
      onClickVariant?.(variant);
    };
  };

  const list = [...listOfVariants, ...newVariants]

  return (
    <div className={defaultClasses} style={style}>
      <PlusButton
        className={cl(`${defaultClassPrefix}__button`, {
          [`${defaultClassPrefix}__button--active`]: isOpen,
        })}
        onClick={onToggle}
        iconColor={isOpen ? '#97D5FF' : undefined}
      />
      <ul className={cl(`${defaultClassPrefix}__list`)}>
        {list.map(({type, title, icon}) => (
          <Tooltip key={type} title={title}>
            <li className={cl(`${defaultClassPrefix}__list-item`)}
                onClick={onChooseItem(type)}>
              <span
                className={cl(`${defaultClassPrefix}__list-item-icon`)}>{icon}</span>
              <span
                className={cl(`${defaultClassPrefix}__list-item-title`)}>{title}</span>
            </li>
          </Tooltip>
        ))}
      </ul>
    </div>
  );
};

export {AddCheckStepButton};
