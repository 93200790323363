import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import {
  AddCheckStepButton,
  CommonForm,
  DraggableLining,
  MultiplyCheckStep,
  OtherCheckStep,
  useHandleCheckStepItem,
} from '@components/common';
import { KindOfCheckStep } from '@constants/kindOfCheckStep';
import { useAppSelector } from '@hooks';
import { Input } from '@shared/UI';
import { selectMaAtChecklistStatus } from '@store/selectors/properties/MaAt/Checklist';

import type { CommonFormProps, DefaultCommonFormProps } from '@components/common';
import type { FormRule } from '@interfaces/interfaces';
import type { CreateChecklistCheckStepsDto } from '@model/properties/MaAt/Checklist';
import type { FormInstance } from 'antd';

const { CaseField, useForm, Item } = CommonForm;

const FormMaintenanceChecklist = (
  props: Omit<CommonFormProps<CreateChecklistCheckStepsDto>, 'onFinish'> & {
    onRemoveStepAction?: (id: number) => void;
    onRemoveOptionAction?: (stepId: number, id: number) => void;
    onFinish: (
      values: CreateChecklistCheckStepsDto,
      form?: FormInstance<CreateChecklistCheckStepsDto>,
      clearSteps?: () => void
    ) => void;
  }
) => {
  const {
    initialValues,
    onRemoveStepAction,
    onRemoveOptionAction,
    onFinish: onFinishProps,
    ...restFormProps
  } = props;

  const [form] = useForm<CreateChecklistCheckStepsDto>();

  const { t } = useTranslation();

  const [errorsSteps, setErrorsSteps] = useState<
    {
      uuid: string;
      isSubmitted: boolean;
    }[]
  >([]);

  // state for check steps
  const {
    state,
    actions: {
      onAddNewItem,
      onAddNewMultiplyOption,
      onMoveItem,
      onCopyItem,
      onRemoveItem,
      onRemoveMultiplyOption,
      onEditItem,
      onChangeItemTitle,
      onChangeMultiplyItemRadioText,
      clearState,
    },
  } = useHandleCheckStepItem(initialValues?.checkSteps);

  const statusMaintenanceChecklist = useAppSelector(selectMaAtChecklistStatus);

  const onFinish = () => {
    if (errorsSteps.length > 0) {
      setErrorsSteps((prev) =>
        prev.map((item) => ({
          ...item,
          isSubmitted: true,
        }))
      );
      return;
    }

    const formValues = form.getFieldsValue();
    const values = {
      ...formValues,
      checkSteps: state,
    };
    if (errorsSteps.length === 0) {
      onFinishProps?.(values, form, clearState);
    }
  };

  useEffect(() => {
    if (state.length === 0) {
      setErrorsSteps([]);
    }
    state.forEach((step) => {
      const isExist = errorsSteps.find((item) => item.uuid === step.uuid);
      if (step.title.length > 0 && isExist) {
        setErrorsSteps((prev) => prev.filter((item) => item.uuid !== step.uuid));
      }
      if (step.title.length === 0 && !isExist) {
        setErrorsSteps((prev) => [
          ...prev,
          {
            uuid: step.uuid,
            isSubmitted: false,
          },
        ]);
      }
    });
  }, [state]);

  // static props for form
  const container: DefaultCommonFormProps['container'] = {
    style: {
      maxWidth: '100%',
    },
  };

  const loading = statusMaintenanceChecklist === 'pending';

  // static props for item of form
  const rulesChecklistName: FormRule[] = [
    {
      required: true,
    },
  ];

  return (
    <>
      <CommonForm
        onFinish={onFinish}
        form={form}
        container={container}
        loading={loading}
        initialValues={initialValues}
        {...restFormProps}
      >
        <CaseField md={12}>
          <Item name="checklistName" label={t('titles.Name')} rules={rulesChecklistName}>
            <Input placeholder={t('titles.Name')} />
          </Item>
        </CaseField>
        <CaseField md={12}>
          <Item name="description" label={t('titles.Description')}>
            <Input placeholder={t('titles.Description')} />
          </Item>
        </CaseField>
        <DndProvider backend={HTML5Backend}>
          {state.map((value, idx) => {
            const isError = errorsSteps.some(
              (error) => error.uuid === value.uuid && error.isSubmitted
            );
            return (
              <DraggableLining
                key={value.uuid}
                onActionDelete={() => {
                  onRemoveItem(value.uuid);
                  if (value.entityId) {
                    onRemoveStepAction?.(value.entityId);
                  }
                }}
                onActionCopy={() => {
                  onCopyItem(value.uuid);
                }}
                onActionEdit={() => {
                  onEditItem(value.uuid);
                }}
                isFocus={value.isFocus}
                count={`${idx + 1}.`}
                index={idx}
                onMoveItem={onMoveItem}
                hiddenEditAction={value.isFocus}
              >
                {value.type === KindOfCheckStep.MultiplyChoice ? (
                  <MultiplyCheckStep
                    isErrorTitle={isError}
                    multiplyCheckStep={value}
                    onChangeTitle={onChangeItemTitle}
                    onChangeRadioTitle={onChangeMultiplyItemRadioText}
                    onAddNewRadio={onAddNewMultiplyOption}
                    onRemoveOption={(parentId, key, id) => {
                      onRemoveMultiplyOption(parentId, key);
                      if (id && value.entityId) {
                        onRemoveOptionAction?.(value.entityId, id);
                      }
                    }}
                  />
                ) : null}

                {value.type !== KindOfCheckStep.MultiplyChoice &&
                value.type !== KindOfCheckStep.Photo ? (
                  <OtherCheckStep
                    isErrorTitle={isError}
                    otherCheckStep={value}
                    onChangeTitle={onChangeItemTitle}
                  />
                ) : null}
              </DraggableLining>
            );
          })}
        </DndProvider>

        <AddCheckStepButton
          style={{
            marginLeft: 15,
            marginTop: 20,
          }}
          onClickVariant={onAddNewItem}
        />
      </CommonForm>
    </>
  );
};

export { FormMaintenanceChecklist };
