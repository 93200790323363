import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAssetListContext } from '../../../../AssetListProvider';

export const AssetCount = memo(() => {
  const { t } = useTranslation();

  const { checkedAssetsLength } = useAssetListContext();

  return (
    <span className="asset-toolbar-selected-count">
      {t('titles.Selected_Records_Count', {
        name: '',
        number: checkedAssetsLength,
      })}
    </span>
  );
});
