import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MaintenanceSwitcher } from '@components/common';
import { InputSearch } from '@shared/UI';

import type { ChangeEvent} from 'react';

interface HeaderProps {
  onChangeSearch: (value: string) => void;
  searchValue: string;
}

const AdditionalHeader = (props: HeaderProps) => {
  const { searchValue, onChangeSearch: onChangeSearchProps } = props;

  const { t } = useTranslation();

  const onChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      onChangeSearchProps(value);
    },
    [onChangeSearchProps]
  );

  return (
    <>
      <InputSearch
        placeholder={t('titles.Search')}
        className="maintenance-calendar-header-input"
        onChange={onChangeSearch}
        value={searchValue}
      />

      <MaintenanceSwitcher className="maintenance-calendar-header-switcher" />
    </>
  );
};

export { AdditionalHeader };
