import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsAtStatusRepository } from '@repositories';

import type {
  AsAtStatusModel,
  CreateAsAtStatusDto,
  UpdateAsAtStatusDto,
} from '@model/properties/AsAt/Status';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getAsAtStatuses = createAsyncThunk<AsAtStatusModel[], void, { rejectValue: string }>(
  'AsAtStatus/getAsAtStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AsAtStatusRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);
export const getAsAtStatusById = createAsyncThunk<AsAtStatusModel, string, { rejectValue: string }>(
  'AsAtStatus/getAsAtStatusById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await AsAtStatusRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addAsAtStatus = createAsyncThunk<
  AsAtStatusModel,
  CreateAsAtStatusDto,
  { rejectValue: string }
>('AsAtStatus/addAsAtStatus', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtStatusRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateAsAtStatus = createAsyncThunk<
  { AsAtStatusesList: AsAtStatusModel[]; AsAtStatus: AsAtStatusModel },
  UpdateAsAtStatusDto,
  { rejectValue: string }
>('AsAtStatus/updateAsAtStatus', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtStatusRepository.update(dto);

    const responseList = await AsAtStatusRepository.fetchAll();

    return {
      AsAtStatusesList: responseList.resultObject,
      AsAtStatus: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeAsAtStatusById = createAsyncThunk<
  AsAtStatusModel[],
  number[],
  { rejectValue: string }
>('AsAtStatus/removeAsAtStatusById', async (ids, { rejectWithValue }) => {
  try {
    await AsAtStatusRepository.removeById(ids);
    const response = await AsAtStatusRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
