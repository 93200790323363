import React from 'react';

import { PlusSquare } from '@icon/icon-components';
import { Button } from 'antd';
import cl from 'classnames';

import type { ButtonProps } from 'antd';

import './PlusButton.scss';

export interface PlusButtonProps extends Omit<ButtonProps, 'children'> {
  iconColor?: string;
}

const PlusButton = (props: PlusButtonProps) => {
  const { className, iconColor, ...rest } = props;

  const defaultClasses = cl('custom-plus-button', className);

  return <Button className={defaultClasses} icon={<PlusSquare fill={iconColor} />} {...rest} />;
};

export { PlusButton };
