import { useEffect, useRef, useState } from 'react';
import { centerCrop, makeAspectCrop } from 'react-image-crop';

import { useDebounce } from '@hooks';

import type { MutableRefObject, SyntheticEvent} from 'react';
import type { Crop, PercentCrop, PixelCrop } from 'react-image-crop';

const TO_RADIANS = Math.PI / 180;

export const getCroppedImage = async (
  pixelCrop: PixelCrop,
  image: HTMLImageElement
): Promise<string | undefined> => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(pixelCrop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(pixelCrop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = pixelCrop.x * scaleX;
  const cropY = pixelCrop.y * scaleY;
  const rotateRads = 0 * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(1, 1);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );
  ctx.restore();

  return new Promise((resolve) => {
    resolve(canvas.toDataURL('image/jpeg'));
  });
};

function centerAspectCrop(mediaWidth: number, mediaHeight: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 50,
      },
      1,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

type UseImageCropperReturn = {
  imageCropRef: MutableRefObject<HTMLImageElement | null>;
  croppedImage: string | null;

  crop: Crop | undefined;

  actions: {
    onImageLoad: (event: SyntheticEvent<HTMLImageElement>) => void;
    onCompleteCrop: (pixelCrop: PixelCrop) => void;
    onChangeCrop: (crop: PercentCrop | undefined) => void;
  };
};

function useImageCropper(): UseImageCropperReturn {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const imageCropRef = useRef<HTMLImageElement | null>(null);
  const debouncedCrop = useDebounce(completedCrop, 100);

  const onImageLoad = (event: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = event.currentTarget;

    setCrop(centerAspectCrop(width, height));
  };

  useEffect(() => {
    if (debouncedCrop?.width && debouncedCrop?.height && imageCropRef.current) {
      getCroppedImage(debouncedCrop, imageCropRef.current).then((result) => {
        if (result) {
          setCroppedImage(result);
        }
      });
    }
  }, [debouncedCrop]);

  return {
    crop,
    croppedImage,
    imageCropRef,
    actions: {
      onImageLoad,
      onCompleteCrop: setCompletedCrop,
      onChangeCrop: setCrop,
    },
  };
}

export { useImageCropper };
