import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { Input } from '@shared/UI';
import { selectUserGroupStatus } from '@store/selectors/users/UserGroup';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateUserGroupDto } from '@model/users/UserGroup';

const { CaseField, Item, useForm } = CommonForm;

function FormUserGroup(props: CommonFormProps<CreateUserGroupDto>) {
  const [form] = useForm<CreateUserGroupDto>();

  const { t } = useTranslation();

  const statusUserGroup = useAppSelector(selectUserGroupStatus);

  return (
    <CommonForm form={form} loading={statusUserGroup === 'pending'} {...props}>
      <CaseField>
        <Item
          label={t('titles.Code')}
          name="groupCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item
          label={t('titles.Name')}
          name="groupName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormUserGroup };
