import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { AssetUploaderImage } from '@components/common';
import {
  useAppDispatch,
  useAppSelector,
  useIsCreatePage,
  useIsEditPage,
  useModalState,
} from '@hooks';
import { CarouselModal, ImageContainer } from '@shared/UI';
import { createUrlObject } from '@shared/utils/functions';
import {
  addAssetImagesById,
  removeAssetImageById,
  updateOrderAssetPicture,
} from '@store/actions/management/Asset';
import { selectAsset } from '@store/selectors/management/Asset';
import { Col, Row, Space } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { CommonForm } from '../CommonForm';
import { DraggableContainer } from './DraggableContainer';

import type { DropImageValue } from '@components/common';
import type { FileWithID } from '@interfaces/interfaces';
import type {
  AssetFileModel,
  CreateAssetDto,
  SetOrderAssetPictureDto,
} from '@model/management/Asset';
import type { SwiperProps } from 'swiper/react/swiper-react';

interface AssetImagesProps {
  images?: (FileWithID | FileWithID<AssetFileModel>)[] | null;
  hiddenUploader?: boolean;
  hiddenLabel?: boolean;
}

const { Item, useFormInstance } = CommonForm;

const createImageUrl = (images?: (FileWithID | FileWithID<AssetFileModel>)[] | null) => {
  const imagePaths: Array<string> = [];

  images &&
    images.forEach((img) => {
      if (img.file instanceof File) {
        imagePaths.push(createUrlObject(img.file));
      } else {
        imagePaths.push(img.file.filePath);
      }
    });

  return imagePaths;
};

function AssetImages(props: AssetImagesProps) {
  const { images, hiddenUploader, hiddenLabel } = props;

  const { t } = useTranslation();

  const form = useFormInstance<CreateAssetDto>();

  const dispatch = useAppDispatch();

  const sliderModal = useModalState();

  const currentAsset = useAppSelector(selectAsset);

  const [initialSlide, setInitialSlide] = useState(0);

  const [deletedImages, setDeletedImages] = useState<Array<string>>([]);

  const isEdit = useIsEditPage();

  const isCreate = useIsCreatePage();

  const handleImageClick = (index: number) => {
    setInitialSlide(index);

    sliderModal.actions.onOpen();
  };

  const handleClickDelete = (id: string, assetFileId?: number) => {
    setDeletedImages((prevState) => [...prevState, id]);

    const filteredImages = images ? images.filter((image) => image.id !== id) : [];

    form.setFieldValue('images', filteredImages);

    if (assetFileId) {
      dispatch(removeAssetImageById(assetFileId));
    }
  };

  const uploadNewImagesOnEditPage = async () => {
    if (isEdit && currentAsset) {
      const files = images?.filter((image) => image.file instanceof File) || null;
      try {
        if (files && files.length) {
          const res = await dispatch(
            addAssetImagesById({
              files,
              assetId: currentAsset.nonCurrAssetId,
            })
          ).unwrap();
          if (res) {
            const newImages = res.nonCurrAssetFiles
              ? res.nonCurrAssetFiles
                  .map((file) => ({
                    file,
                    id: uuidv4(),
                  }))
                  .sort((a, b) => {
                    if (!a.file.rowNumber || !b.file.rowNumber) {
                      return 0;
                    }
                    return a.file.rowNumber - b.file.rowNumber;
                  })
              : [];

            form.setFieldValue('images', newImages);
          }
        }
      } catch (e) {}
    }
  };

  useEffect(() => {
    uploadNewImagesOnEditPage();
  }, [images]);

  const getValueFromEvent = (value: Array<FileWithID | FileWithID<AssetFileModel>>) => {
    const filteredValue = value.filter((item) => {
      const isRemoved = deletedImages.some((imgId) => item.id === imgId);

      return !isRemoved;
    });

    return filteredValue;
  };

  const handleMoveImage = (value: DropImageValue) => {
    const cloneImages = structuredClone(images) || [];
    cloneImages.splice(value.dragIndex, 1);
    cloneImages.splice(value.hoverIndex, 0, value.dragFile);

    form.setFieldValue('images', cloneImages);

    if (currentAsset && isEdit) {
      const nonCurrAssetFileIds = cloneImages.reduce((acc: Array<number>, image) => {
        if ('nonCurrAssetFileId' in image.file) {
          acc.push(image.file.nonCurrAssetFileId);
        }
        return acc;
      }, []);
      const dto: SetOrderAssetPictureDto = {
        nonCurrAssetId: currentAsset.nonCurrAssetId,
        nonCurrAssetFileIds,
      };
      dispatch(updateOrderAssetPicture(dto));
    }
  };

  const renderImages = () => {
    if (!images || !images.length) return null;

    return images.map((img, idx) => {
      const { file, id } = img;

      if (file instanceof File) {
        return (
          <Col key={id}>
            <DraggableContainer
              onMoveItem={handleMoveImage}
              file={img}
              index={idx}
              canDelete={isEdit || isCreate}
              onClickImage={() => handleImageClick(idx)}
              onClickDelete={() => handleClickDelete(id)}
              src={file}
            />
          </Col>
        );
      }

      const src = `${file.filePath}`;
      return (
        <Col key={file.nonCurrAssetFileId}>
          <DraggableContainer
            onMoveItem={handleMoveImage}
            file={img}
            index={idx}
            canDelete={isEdit || isCreate}
            onClickImage={() => handleImageClick(idx)}
            src={src}
            onClickDelete={() => handleClickDelete(id, file.nonCurrAssetFileId)}
          />
        </Col>
      );
    });
  };

  const renderPlugs = useCallback(() => {
    if (images && images?.length >= 5) return null;

    const plugLength = images && images.length > 0 ? 5 - images.length : 5;

    const arrayPlug = new Array(plugLength).fill(0).map(() => uuidv4());

    return arrayPlug.map((itemId) => (
      <Col key={itemId}>
        <ImageContainer />
      </Col>
    ));
  }, [images]);

  const swiperConfig: SwiperProps = {
    initialSlide,
  };

  return (
    <>
      <Space align="start">
        <Item label={!hiddenLabel ? t('titles.Uploaded_Images') : null}>
          <Row gutter={[5, 5]}>
            <DndProvider backend={HTML5Backend}>{renderImages()}</DndProvider>
            {renderPlugs()}
          </Row>
        </Item>
        {!hiddenUploader && (
          <Item name="images" getValueFromEvent={getValueFromEvent}>
            <AssetUploaderImage />
          </Item>
        )}
      </Space>
      <CarouselModal
        images={createImageUrl(images)}
        open={sliderModal.isOpen}
        onCancel={sliderModal.actions.onClose}
        swiperConfig={swiperConfig}
      />
    </>
  );
}

export { AssetImages };
