import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getExitTypeById } from '@store/actions/properties/ExitType';
import { selectExitType } from '@store/selectors/properties/ExitType';

import type { IOutletExitType } from '@constants/OutletContextEntityPages';
import type { CreateExitTypeDto } from '@model/properties/ExitType';

function ExitType() {
  const exitType = useAppSelector(selectExitType);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !exitType || exitType.checkFactorId !== +decryptedId;

  useSetPageInfo({
    defaultPagePath: paths.exitType.default,
    pageTitle: 'pages_single.Exit_Type',
  });

  useEffect(() => {
    if (condition) {
      dispatch(getExitTypeById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateExitTypeDto = {
    exitTypeCode: exitType.code,
    name: exitType.name,
    description: exitType.description,
  };

  return <Outlet context={{ initialValues, entity: exitType } as IOutletExitType} />;
}

export default ExitType;
