import React from 'react';

import cl from 'classnames';

import { StepType } from '../StepType';

import type { StepTypeItem } from '../_utils';
import type { StepTypeProps } from '../StepType';

import styles from './StepTypes.module.scss';

export type StepTypesProps = {
  items: StepTypeItem[];
  active: boolean;
} & Pick<StepTypeProps, 'onClick'>;

export const StepTypes = (props: StepTypesProps) => {
  const { items, onClick, active } = props;

  const mapStepTypes = items.map((stepType) => {
    const { typeKey, type, label, icon } = stepType;
    return (
      <StepType
        key={typeKey}
        typeKey={typeKey}
        type={type}
        label={label}
        icon={icon}
        onClick={onClick}
      />
    );
  });

  const classes = cl(styles.stepTypes, {
    [styles.stepTypesActive]: active,
  });

  return <div className={classes}>{mapStepTypes}</div>;
};
