import React from 'react';

const OtherIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.49658 10.9102H3.29085C1.75275 10.9102 0.5 12.1831 0.5 13.7481V16.8617C0.5 18.4246 1.75275 19.6976 3.29085 19.6976H6.49658C8.03671 19.6976 9.28744 18.4246 9.28744 16.8617V13.7481C9.28744 12.1831 8.03469 10.9102 6.49658 10.9102ZM7.78981 16.8617C7.78981 17.5876 7.20897 18.1758 6.49658 18.1758H3.29085C2.57847 18.1758 1.99763 17.5856 1.99763 16.8617V13.7481C1.99763 13.0222 2.57847 12.432 3.29085 12.432H6.49658C7.21099 12.432 7.78981 13.0222 7.78981 13.7481V16.8617Z"
      fill="white"
    />
    <path
      d="M6.49658 0.723633H3.29085C1.75275 0.723633 0.5 1.99661 0.5 3.55955V6.6731C0.5 8.23809 1.75275 9.51107 3.29085 9.51107H6.49658C8.03671 9.51107 9.28744 8.23809 9.28744 6.6731V3.55955C9.28744 1.99661 8.03469 0.723633 6.49658 0.723633ZM7.78981 6.6731C7.78981 7.39904 7.20897 7.98926 6.49658 7.98926H3.29085C2.57847 7.98926 1.99763 7.39904 1.99763 6.6731V3.55955C1.99763 2.8336 2.57847 2.24545 3.29085 2.24545H6.49658C7.21099 2.24545 7.78981 2.83566 7.78981 3.55955V6.6731Z"
      fill="white"
    />
    <path
      d="M19.7109 2.97601L17.5222 0.788917C17.0122 0.279245 16.336 0 15.6169 0C14.8977 0 14.2196 0.281198 13.7115 0.788917L11.6205 2.88033C10.5692 3.93091 10.5692 5.63958 11.6205 6.69017L13.8092 8.87726C14.3193 9.38694 14.9954 9.66618 15.7146 9.66618C16.4337 9.66618 17.1118 9.38694 17.6199 8.87726L19.7129 6.78585C20.7603 5.73527 20.7603 4.0266 19.7109 2.97601ZM18.6869 5.76261L16.5959 7.85402C16.123 8.32658 15.3003 8.32658 14.8293 7.85402L12.6406 5.66692C12.154 5.18068 12.154 4.38786 12.6406 3.90162L14.7336 1.81021C14.97 1.57393 15.2827 1.44505 15.6169 1.44505C15.951 1.44505 16.2637 1.57393 16.5002 1.81021L18.6889 3.99731C19.1755 4.48355 19.1755 5.27637 18.6869 5.76261Z"
      fill="white"
    />
    <path
      d="M16.7661 10.9102H13.5624C12.0223 10.9102 10.7695 12.1831 10.7695 13.7481V16.8617C10.7695 18.4246 12.0223 19.6976 13.5604 19.6976H16.7661C18.3062 19.6976 19.557 18.4246 19.557 16.8617V13.7481C19.559 12.1831 18.3062 10.9102 16.7661 10.9102ZM18.0614 16.8617C18.0614 17.5876 17.4805 18.1758 16.7681 18.1758H13.5624C12.85 18.1758 12.2692 17.5856 12.2692 16.8617V13.7481C12.2692 13.0222 12.85 12.432 13.5624 12.432H16.7681C17.4825 12.432 18.0614 13.0222 18.0614 13.7481V16.8617Z"
      fill="white"
    />
  </svg>
);

export { OtherIcon };
