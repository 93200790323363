import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { Divider } from '@shared/UI';
import { selectSitesListWithoutChildren, selectSiteStatus } from '@store/selectors/properties/Site';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { SiteModel } from '@model/properties/Site';

const { CaseField, Item, PreviewField } = CommonForm;

const PreviewFormSite = (props: CommonPreviewProps<SiteModel>) => {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusSite = useAppSelector(selectSiteStatus);
  const listSite = useAppSelector(selectSitesListWithoutChildren);

  const parentSite = listSite.find((site) => site.siteId === entity.parentSiteId);

  return (
    <CommonForm
      isPreview
      loading={statusSite === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField title={t('titles.Summary')}>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.siteCode}</PreviewField>
        </Item>
      </CaseField>

      <CaseField>
        <Item label={t('titles.Barcode')}>
          <PreviewField>{entity.barcode}</PreviewField>
        </Item>
        <Item label={t('titles.Site')}>
          <PreviewField>{parentSite?.name}</PreviewField>
        </Item>
      </CaseField>
      <Divider />
      <CaseField title={t('titles.Location')}>
        <Item label={t('titles.Country')}>
          <PreviewField>{entity.city.country.name}</PreviewField>
        </Item>
        <Item label={t('titles.City')}>
          <PreviewField>{entity.city.name}</PreviewField>
        </Item>
        <Item label={t('titles.Town')}>
          <PreviewField>{entity.town}</PreviewField>
        </Item>
        <Item label={t('titles.Area')}>
          <PreviewField>{entity.area}</PreviewField>
        </Item>
      </CaseField>

      <CaseField title={t('titles.Contacts')}>
        <Item label={t('titles.Address')}>
          <PreviewField>{entity.address}</PreviewField>
        </Item>
        <Item label={t('titles.Description')}>
          <PreviewField typeField="textarea">{entity.description}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
};

export { PreviewFormSite };
