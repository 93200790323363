import { createAsyncThunk } from '@reduxjs/toolkit';
import { MaAtChecklistRepository } from '@repositories';

import type {
  CreateCheckStepDto,
  CreateMaintenanceChecklistDto,
  CreateMultiplyChoiceListDto,
  FilterMaintenanceCheckListDto,
  MaAtChecklistModel,
  MaAtChecklistSummaryModel,
  UpdateCheckStepDto,
  UpdateMaintenanceChecklistDto,
  UpdateMultipleChoiceListDto,
} from '@model/properties/MaAt/Checklist';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer';
import type { AppDispatch } from '@store/store';

// region --- Get Actions

export const getMaAtChecklists = createAsyncThunk<
  {
    MaAtChecklistResults: MaAtChecklistModel[];
    MaAtChecklistSettings: {
      totalPage: number;
      totalRecords: number;
    };
    options: Partial<FilterMaintenanceCheckListDto> | null;
  },
  Partial<FilterMaintenanceCheckListDto> | void,
  {
    state: RootState;
    rejectValue: string;
  }
>('MaAtChecklist/getMaAtChecklists', async (filterOptions, { rejectWithValue, getState }) => {
  try {
    const stateFilterOptions = getState().MaAtChecklistReducer.filterOptions;

    const response = await MaAtChecklistRepository.fetchAll({
      ...stateFilterOptions,
      ...filterOptions,
    });

    return {
      MaAtChecklistResults: response.resultObject,
      MaAtChecklistSettings: {
        totalPage: response.totalPage,
        totalRecords: response.totalRecords,
      },
      options: filterOptions || null,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getMaAtChecklistById = createAsyncThunk<
  MaAtChecklistModel[],
  string | number,
  { rejectValue: string }
>('MaAtChecklist/getMaAtChecklistById', async (id, { rejectWithValue }) => {
  try {
    const response = await MaAtChecklistRepository.fetchById(id);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getMaAtChecklistsSummary = createAsyncThunk<
  MaAtChecklistSummaryModel[],
  void,
  { rejectValue: string }
>('MaAtChecklist/getMaintenanceChecklistSummary', async (_, { rejectWithValue }) => {
  try {
    const response = await MaAtChecklistRepository.fetchSummaryList();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addMaAtChecklist = createAsyncThunk<
  MaAtChecklistModel,
  CreateMaintenanceChecklistDto,
  { rejectValue: string }
>('MaAtChecklist/addMaAtChecklist', async (MaAtChecklist, { rejectWithValue }) => {
  try {
    const response = await MaAtChecklistRepository.addChecklist(MaAtChecklist);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const addMaAtCheckStep = createAsyncThunk<
  MaAtChecklistModel,
  CreateCheckStepDto[],
  { rejectValue: string }
>('MaAtChecklist/addMaAtCheckStep', async (checkStep, { rejectWithValue }) => {
  try {
    await MaAtChecklistRepository.addCheckSteps(checkStep);

    const response = await MaAtChecklistRepository.fetchById(checkStep[0].checklistId);

    return response.resultObject[0];
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const addMaAtMultipleChoiceList = createAsyncThunk<
  MaAtChecklistModel,
  CreateMultiplyChoiceListDto,
  { rejectValue: string }
>('MaAtChecklist/addMaAtMultipleChoiceList', async (MaAtChecklist, { rejectWithValue }) => {
  try {
    const response = await MaAtChecklistRepository.addMultipleChoiceList(MaAtChecklist);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateMaAtChecklist = createAsyncThunk<
  MaAtChecklistModel,
  UpdateMaintenanceChecklistDto,
  { rejectValue: string; dispatch: AppDispatch }
>('MaAtChecklist/updateMaAtChecklist', async (MaAtChecklist, { rejectWithValue, dispatch }) => {
  try {
    const response = await MaAtChecklistRepository.updateChecklist(MaAtChecklist);

    dispatch(getMaAtChecklists());

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const updateMaAtCheckStep = createAsyncThunk<
  MaAtChecklistModel,
  Omit<UpdateCheckStepDto, 'mcList'>,
  { rejectValue: string }
>('MaAtChecklist/updateMaAtCheckStep', async (MaAtChecklist, { rejectWithValue }) => {
  try {
    const response = await MaAtChecklistRepository.updateCheckStep(MaAtChecklist);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const updateMaAtMultipleChoiceList = createAsyncThunk<
  MaAtChecklistModel,
  UpdateMultipleChoiceListDto,
  { rejectValue: string }
>('MaAtChecklist/updateMaAtMultipleChoiceList', async (MaAtChecklist, { rejectWithValue }) => {
  try {
    const response = await MaAtChecklistRepository.updateMultipleChoiceList(MaAtChecklist);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Delete Actions

export const removeMaAtChecklistByIds = createAsyncThunk<
  MaAtChecklistModel[],
  number[],
  { rejectValue: string; state: RootState }
>('MaAtChecklist/removeMaAtChecklistByIds', async (ids, { rejectWithValue, getState }) => {
  try {
    await MaAtChecklistRepository.removeChecklistByIds(ids);

    const checkLists = getState().MaAtChecklistReducer.MaAtChecklistsList;

    return checkLists.filter(({ checkListId }) => !ids.includes(checkListId));
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const removeMaAtCheckStepByIds = createAsyncThunk<
  MaAtChecklistModel,
  number[],
  { rejectValue: string; state: RootState }
>('MaAtChecklist/removeMaAtCheckStepByIds', async (ids, { rejectWithValue, getState }) => {
  try {
    await MaAtChecklistRepository.removeCheckStepByIds(ids);

    const checkList = getState().MaAtChecklistReducer.MaAtChecklist as MaAtChecklistModel;

    const updateCheckSteps = checkList.checkListCheckSteps.filter(
      ({ checkStepsId }) => !ids.includes(checkStepsId)
    );

    return { ...checkList, checkListCheckSteps: updateCheckSteps };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const removeMaAtMultipleChoiceListByIds = createAsyncThunk<
  MaAtChecklistModel,
  { stepId: number; optionIds: number[] },
  { rejectValue: string; state: RootState }
>(
  'MaAtChecklist/removeMaAtMultipleChoiceListByIds',
  async ({ optionIds, stepId }, { rejectWithValue, getState }) => {
    try {
      await MaAtChecklistRepository.removeMultipleChoiceListByIds(optionIds);

      const checkList = getState().MaAtChecklistReducer.MaAtChecklist as MaAtChecklistModel;

      const checkStep = checkList.checkListCheckSteps.find(
        ({ checkStepsId }) => checkStepsId === stepId
      );

      if (checkStep) {
        const updateMultiplyChoiceList = checkStep.checkSteps.multipleChoiceLists.filter(
          (multiple) => !optionIds.includes(multiple.multipleChoiceListId)
        );
        const updateCheckSteps = checkList.checkListCheckSteps.map((step) => {
          if (step.checkStepsId === checkStep.checkStepsId) {
            return {
              ...step,
              checkSteps: {
                ...step.checkSteps,
                multipleChoiceLists: updateMultiplyChoiceList,
              },
            };
          }
          return step;
        });

        return { ...checkList, checkListCheckSteps: updateCheckSteps };
      }

      return checkList;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Delete Actions
