import React from 'react';

import { Menu as AntdMenu } from 'antd';
import cl from 'classnames';

import type { MenuProps as AntdMenuProps } from 'antd';

import './Menu.scss';

export interface MenuProps extends Omit<AntdMenuProps, 'className'> {
  className?: string | string[];
}

const Menu = (props: MenuProps) => {
  const { children, className, ...rest } = props;

  const defaultMenuClass = cl('custom__menu', className);

  return (
    <AntdMenu {...rest} className={defaultMenuClass}>
      {children}
    </AntdMenu>
  );
};

export default Menu;
