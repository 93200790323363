import React from 'react';
import { useTranslation } from 'react-i18next';

import { CreatedBy } from '@components/common';
import { AsetlyLogo } from '@icon/icon-components';

import { ForgotPasswordForm } from './Form';

import './ForgotPassword.scss';

const ForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <div className="recovery_container">
      <AsetlyLogo />
      <h4>{t('titles.Password_Recovery')}</h4>
      <p
        className="recovery_container__description"
        style={{
          maxWidth: 250,
        }}
      >
        {t('titles.Forgot_Password_Details')}
      </p>

      <ForgotPasswordForm />

      <CreatedBy />
    </div>
  );
};

export default ForgotPassword;
