import React from 'react';

import type { SVGProps } from 'react';

const DropdownLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={16} cy={16} r={16} fill="#F6F6F6" />
    <path
      d="M19.26 15.82h-4.075a.722.722 0 0 0 0 1.445h4.075l-.81.81a.722.722 0 1 0 1.021 1.021l2.043-2.042a.721.721 0 0 0 0-1.022l-2.042-2.042a.722.722 0 1 0-1.022 1.02l.81.81Zm-6.923-5.653a.722.722 0 0 0-.723.722v11.556a.722.722 0 0 0 1.445 0V10.889a.722.722 0 0 0-.722-.722Z"
      fill="#374957"
    />
  </svg>
);

export { DropdownLogout };
