import { createAsyncThunk } from '@reduxjs/toolkit';
import { TitleRepository } from '@repositories';

import type { CreateTitleDto, TitleModel, UpdateTitleDto } from '@model/users/Title';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer';

// region --- Get Actions

export const getTitles = createAsyncThunk<TitleModel[]>(
  'Title/getTitles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await TitleRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getTitleById = createAsyncThunk<TitleModel, string>(
  'Title/getTitleById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await TitleRepository.fetchById(id);

      return res.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addTitle = createAsyncThunk<TitleModel, CreateTitleDto>(
  'Title/createTitle',
  async (dto, { rejectWithValue }) => {
    try {
      const response = await TitleRepository.add(dto);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Add Actions

// region --- Update Actions

export const updateTitle = createAsyncThunk<
  { titles: TitleModel[]; title: TitleModel },
  UpdateTitleDto,
  { state: RootState }
>('Title/updateTitle', async (dto, { rejectWithValue, getState }) => {
  try {
    const res = await TitleRepository.update(dto);

    const { resultObject } = res;

    const { titlesList } = getState().TitleReducer;

    const titles = titlesList.map((title) =>
      title.userTitleId === resultObject.userTitleId ? resultObject : title
    );

    return { titles, title: resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeTitleById = createAsyncThunk<TitleModel[], number[], { state: RootState }>(
  'Title/removeTitleById',
  async (ids, { getState, rejectWithValue }) => {
    try {
      await TitleRepository.removeById(ids);

      const { titlesList } = getState().TitleReducer;

      return titlesList.filter((title) => !ids.includes(title.userTitleId));
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Remove Actions
