import {createAsyncThunk} from '@reduxjs/toolkit';
import {DashboardRepository} from '@repositories';
import { identifyError } from '@shared/utils/functions';

import type { DashboardModel } from '@model/dashboard';

// region --- Get Actions

export const getDashboard = createAsyncThunk<DashboardModel, void, { rejectValue: string }>(
  'Dashboard/getDashboard',
  async (_, { rejectWithValue }) => {
    try {
      const response = await DashboardRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error = identifyError(err);
      return rejectWithValue(error);
    }
  }
);

// endregion --- Get Actions