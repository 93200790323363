import { createAsyncThunk } from '@reduxjs/toolkit';
import { DepartmentRepository } from '@repositories';

import type {
  CreateDepartmentDto,
  DepartmentModel,
  FilterDepartmentDto,
  UpdateDepartmentDto,
  UpdateDepartmentParentDto,
} from '@model/properties/Department';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getDepartments = createAsyncThunk<DepartmentModel[], void, { rejectValue: string }>(
  'Department/getDepartments',
  async (_, { rejectWithValue }) => {
    try {
      const response = await DepartmentRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getDepartmentsByFilter = createAsyncThunk<
  { departments: DepartmentModel[]; departmentFilters: FilterDepartmentDto },
  FilterDepartmentDto,
  { rejectValue: string }
>('Department/getDepartmentsByFilter', async (dto, { rejectWithValue }) => {
  try {
    const response = await DepartmentRepository.fetchByFilter(dto);

    return { departments: response.resultObject, departmentFilters: dto };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});
export const getDepartmentById = createAsyncThunk<DepartmentModel, string>(
  'Department/getDepartmentById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await DepartmentRepository.fetchById(id);

      return response.resultObject[0];
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);
// endregion --- Get Actions

// region --- Add Actions

export const addDepartment = createAsyncThunk<
  { departmentsList: DepartmentModel[]; createdDepartment: DepartmentModel },
  CreateDepartmentDto
>('Department/addDepartment', async (dto, { rejectWithValue }) => {
  try {
    const createdDepartment = await DepartmentRepository.add(dto);

    const response = await DepartmentRepository.fetchAll();

    return {
      departmentsList: response.resultObject,
      createdDepartment: createdDepartment.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateDepartment = createAsyncThunk<
  { departments: DepartmentModel[]; department: DepartmentModel },
  UpdateDepartmentDto,
  { rejectValue: string }
>('Department/updateDepartment', async (dto, { rejectWithValue }) => {
  try {
    const response = await DepartmentRepository.update(dto);
    const responseList = await DepartmentRepository.fetchAll();

    return { departments: responseList.resultObject, department: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});
export const updateDepartmentParentRelations = createAsyncThunk<
  DepartmentModel[],
  UpdateDepartmentParentDto
>('Department/updateDepartmentParentRelations', async (dto, { rejectWithValue }) => {
  try {
    await DepartmentRepository.updateParentRelations(dto);
    const response = await DepartmentRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeDepartmentById = createAsyncThunk<DepartmentModel[], number[]>(
  'Department/removeDepartmentById',
  async (ids, { rejectWithValue }) => {
    try {
      await DepartmentRepository.removeById(ids);
      const response = await DepartmentRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Remove Actions
