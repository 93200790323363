import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getMaAtCategoryById } from '@store/actions/properties/MaAt/Category';
import { selectMaAtCategory } from '@store/selectors/properties/MaAt/Category';

import type { IOutletMaintenanceCategory } from '@constants/OutletContextEntityPages';
import type { CreateMaAtCategoryDto } from '@model/properties/MaAt/Category';

function MaintenanceCategory() {
  const maintenanceCategory = useAppSelector(selectMaAtCategory);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition =
    !maintenanceCategory || maintenanceCategory.maintinanceCategoryId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getMaAtCategoryById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateMaAtCategoryDto = {
    categoryCode: maintenanceCategory.categoryCode,
    categoryName: maintenanceCategory.categoryName,
  };

  return (
    <Outlet
      context={{ initialValues, entity: maintenanceCategory } as IOutletMaintenanceCategory}
    />
  );
}

export default MaintenanceCategory;
