import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getUserAuthorizations } from '@store/actions/user/UserAuthorization';
import { selectUserAuthList } from '@store/selectors/users/UserAuthorization';

function EntryUserAuthorization() {
  useSetPageInfo({
    defaultPagePath: paths.userauth.default,
    pageTitle: 'pages_single.Authorization',
  });

  const authorizations = useAppSelector(selectUserAuthList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!authorizations.length) {
      dispatch(getUserAuthorizations());
    }
  }, []);

  return <Outlet />;
}

export default EntryUserAuthorization;
