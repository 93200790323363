import React from 'react';
import { useNavigate } from 'react-router-dom';

import {FormChangePassword} from '@components/views/Forms/profile';
import {useOutletProfile} from '@constants/OutletContextEntityPages';
import {useAppDispatch} from '@hooks';
import { paths } from '@routes/paths';
import {notificationController} from '@services/Notifications';
import {updatePassword} from '@store/actions/profile/Profile';

import type {UpdateProfilePasswordDto} from '@model/profile/Profile';
import type {FormInstance} from 'antd';

function ChangePassword() {
  const dispatch = useAppDispatch();
  const {entity} = useOutletProfile();
  const navigate = useNavigate();

  const onFinish = (
    dto: Omit<UpdateProfilePasswordDto, 'userMail'> & { confirmPassword: string },
    formInstance:
      | FormInstance<Omit<UpdateProfilePasswordDto, 'userMail'> & { confirmPassword: string }>
      | undefined
  ) => {
    const {password} = dto;

    dispatch(updatePassword({password, userMail: entity.userMail}))
      .unwrap()
      .then(() => {
        notificationController.success({
          description: 'Password updated successfully',
        });
        formInstance?.resetFields();
        navigate(paths.profile.tabs.general.default);
      })
      .catch((err) => {
        if (typeof err === 'string') {
          notificationController.error({
            description: err,
          });
        }
      });
  };

  return <FormChangePassword onFinish={onFinish}/>;
}

export default ChangePassword;
