import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { CountingResultsTable } from '@components/views/Tables/managment';
import { baseFormatDate, defaultFormatDate } from '@constants/Date';
import { useAppSelector } from '@hooks';
import { selectCountPlanStatus } from '@store/selectors/management/CountPlan';
import { Col, Row } from 'antd';
import { format } from 'date-fns';
import moment from 'moment';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { CountPlanModel } from '@model/management/CountPlan';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormCountPlan(props: CommonPreviewProps<CountPlanModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const countPlanStatus = useAppSelector(selectCountPlanStatus);
  const isPlanning = entity.prdCountStatusId === 1;

  return (
    <div>
      <CommonForm
        isPreview
        loading={countPlanStatus === 'pending' && !entity.prdCountPlanId}
        header={{
          title: entity.taskName,
          subTitle: t('titles.Date_Completed', {
            date: format(new Date(entity.endTime), baseFormatDate),
          }),
          onClickDelete: onDelete,
          extra: !isPlanning ? [] : null,
          ...header,
        }}
      >
        <CaseField>
          <Item label={t('titles.Plan_No')}>
            <PreviewField>{entity.prdCountPlanNo}</PreviewField>
          </Item>
          <Item label={t('titles.Name')}>
            <PreviewField>{entity.taskName}</PreviewField>
          </Item>
          <Row gutter={[10, 0]} justify="space-between">
            <Col span={10}>
              <Item label={t('titles.Start_Date')}>
                <PreviewField>{moment(entity.startTime).format(defaultFormatDate)}</PreviewField>
              </Item>
            </Col>
            <Col span={10}>
              <Item label={t('titles.End_Date')}>
                <PreviewField>{moment(entity.endTime).format(defaultFormatDate)}</PreviewField>
              </Item>
            </Col>
          </Row>
          <Item label={t('titles.Site')}>
            <PreviewField style={{height: '100%', minHeight: '45px'}}>
              {entity.planSite.map((site, i) => <p key={i} style={{color: 'inherit'}}>{site}</p>)}
            </PreviewField>
          </Item>
        </CaseField>

        <CaseField>
          <Item label={t('titles.Assign_To')}>
            <PreviewField>{entity.assignToName}</PreviewField>
          </Item>
          <Item label={t('titles.Description')}>
            <PreviewField typeField="textarea">{entity.description}</PreviewField>
          </Item>
        </CaseField>
      </CommonForm>
      {!isPlanning ? (
        <CaseField lg={24} md={24} sm={24}>
          <CountingResultsTable prdCountPlanId={entity.prdCountPlanId} />
        </CaseField>
      ) : null}
    </div>
  );
}

export { PreviewFormCountPlan };
