import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getMaAtChecklists } from '@store/actions/properties/MaAt/Checklist';
import { selectMaAtChecklistsList } from '@store/selectors/properties/MaAt/Checklist';

function EntryMaintenanceChecklist() {
  useSetPageInfo(
    {
      defaultPagePath: paths.maintenanceAttributes.tabs.checklist.default,
      pageTitle: 'pages_single.Maintenance_Attr',
    },
    {
      copyTabs: true,
    }
  );
  const maintenanceChecklist = useAppSelector(selectMaAtChecklistsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!maintenanceChecklist.length) {
      dispatch(getMaAtChecklists());
    }
  }, []);

  return <Outlet />;
}

export default EntryMaintenanceChecklist;
