import React, { useEffect } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { SimpleTable } from '@components/common';
import { defaultFormatDate } from '@constants/Date';
import { useAppDispatch, useAppSelector, useModalState } from '@hooks';
import { paths } from '@routes/paths';
import { BackLink, BaseModal, PageHeader } from '@shared/UI';
import { getMaintenanceHistoryList } from '@store/actions/management/Maintenance';
import { getMaintenanceResultByHistory } from '@store/actions/management/MaintenanceResult';
import {
  selectFilterHistoryListOptions,
  selectMaintenanceHistoryList,
  selectMaintenanceStatus,
  selectTotalHistoryRecords,
} from '@store/selectors/management/Maintenance';
import {
  selectMaintenancByHistoryResult,
  selectMaintenanceResultStatus,
} from '@store/selectors/management/MaintenanceResult';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { HistoryStepResult } from './HistoryStepResults';

import type { ColumnsTableType, CommonPreviewProps } from '@components/common';
import type { MaintenanceHistoryModel, MaintenanceV2Model } from '@model/management/Maintenance';
import type { TableProps } from '@shared/UI';

import './History.scss';

const columns: ColumnsTableType<MaintenanceHistoryModel> = [
  {
    title: <Translation>{(t) => t('titles.Barcode')}</Translation>,
    dataIndex: 'barcode',
    key: 'barcode',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Assign_To')}</Translation>,
    dataIndex: 'assignToName',
    key: 'assignToName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Additional_Assign_To')}</Translation>,
    dataIndex: 'assigneeNames',
    key: 'assigneeNames',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Maintenance_Date')}</Translation>,
    ellipsis: true,
    render: (_, { maintenanceDate }) => <>{moment(maintenanceDate).format(defaultFormatDate)}</>,
  },
  {
    title: <Translation>{(t) => t('titles.Execute_Hour')}</Translation>,
    dataIndex: 'totalMaintenanceDurationInHours',
    key: 'totalMaintenanceDurationInHours',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: 'siteName',
    key: 'siteName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Category')}</Translation>,
    dataIndex: 'categoryName',
    key: 'categoryName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Status')}</Translation>,
    ellipsis: true,
    render: (_, obj) => {
      const doneWorkStep = `${(obj.maintenanceResultCount / obj.workStepCount) * 100}%`;
      const statusColor =
        obj.maintenanceExecuteStatusId === 1
          ? '#DF0909'
          : obj.maintenanceExecuteStatusId === 2
          ? '#032F5C'
          : '#0C7A02';

      return (
        <div className="history-status">
          <span>{`${obj.maintenanceResultCount}/${obj.workStepCount}`}</span>
          <span>
            <span style={{ width: doneWorkStep, backgroundColor: statusColor }} />
          </span>
        </div>
      );
    },
  },
];

const PreviewFormMaintenanceHistory = (props: CommonPreviewProps<MaintenanceV2Model>) => {
  const { entity } = props;
  const { maintenanceId } = entity;

  const dispatch = useAppDispatch();

  const historyList = useAppSelector(selectMaintenanceHistoryList);
  const totalRecords = useAppSelector(selectTotalHistoryRecords);
  const filterHistoryListOptions = useAppSelector(selectFilterHistoryListOptions);
  const loadingStatus = useAppSelector(selectMaintenanceStatus);
  const historyResults = useAppSelector(selectMaintenancByHistoryResult);
  const resultLoadingStatus = useAppSelector(selectMaintenanceResultStatus);

  const historyModal = useModalState();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      getMaintenanceHistoryList({
        page: 1,
        perPage: 10,
        maintenanceId: maintenanceId,
      })
    );
  }, []);

  const onRow: TableProps<MaintenanceHistoryModel>['onRow'] = (record) => {
    return {
      onClick: () => {
        dispatch(
          getMaintenanceResultByHistory({
            maintenanceId: maintenanceId,
            scheduleId: record.maintenanceSchedulesId,
            nonCurrAssetId: record.noncurrassetid | 0,
          })
        )
          .unwrap()
          .then(() => historyModal.actions.onOpen());
      },
    };
  };

  const onChangePage = (page: number) => {
    dispatch(
      getMaintenanceHistoryList({
        page: page,
        perPage: 10,
        maintenanceId: entity.maintenanceId,
      })
    );
  };

  const resultTotalInfo = historyResults?.result.topdetails;

  const popupHeaderInfo = [
    {
      title: t('titles.Date'),
      value: moment(resultTotalInfo?.lastExecuteDate).format(defaultFormatDate),
    },
    {
      title: t('titles.Priority'),
      value: resultTotalInfo?.priorityName,
    },
    {
      title: t('titles.Assign_To'),
      value: resultTotalInfo?.assignToName,
    },
    {
      title: t('titles.Site'),
      value: resultTotalInfo?.siteName,
    },
  ];

  const values =
    historyResults &&
    historyResults.result.getMaintenanceResultsBySchedulesIdVer3.map(({ workSteps }, count) => {
      const {
        stepName,
        multipleChoiceListForWorkSteps,
        maintenancesResults,
        stepTypes,
        maintenanceImageListResults,
      } = workSteps;

      const mcList = multipleChoiceListForWorkSteps.map((multi) => {
        const resultText =
          workSteps.maintenanceMultipleChoiceListResults[0] &&
          workSteps.maintenanceMultipleChoiceListResults[0].resultText === multi.optionText
            ? workSteps.maintenanceMultipleChoiceListResults[0].resultText
            : multi.optionText;
        return resultText;
      });

      const images = maintenanceImageListResults?.length
        ? maintenanceImageListResults
            .map((file) => ({
              file,
              id: uuidv4(),
            }))
            .sort((a, b) => {
              if (!a.file.maintenanceImageListResultsId || !b.file.maintenanceImageListResultsId) {
                return 0;
              }
              return a.file.maintenanceImageListResultsId - b.file.maintenanceImageListResultsId;
            })
        : [];

      const resultDate = maintenancesResults[0]
        ? moment(maintenancesResults[0].createdDate).format(defaultFormatDate)
        : '';
      const resultText = maintenancesResults[0] ? maintenancesResults[0].resultText : '';
      const resultUser = resultTotalInfo ? resultTotalInfo?.assignToName : '';

      return {
        stepName,
        images: images,
        mcList,
        stepType: stepTypes.stepTypeName,
        resultText,
        resultDate,
        resultUser,
        count: count + 1,
      };
    });

  const statusColor =
    resultTotalInfo?.executeStatusName === 'Open'
      ? '#DDEEFF'
      : resultTotalInfo?.executeStatusName === 'Complete'
      ? '#FBE3D1'
      : '#D6FFAD';

  return (
    <>
      <BackLink path={paths.maintenance.default} />

      <div className="history-page">
        <PageHeader title={entity.title} />
        <SimpleTable
          className="history-page-table"
          dataSource={historyList}
          loading={loadingStatus === 'pending' || resultLoadingStatus === 'pending'}
          rowKey={(row) => row.maintenanceSchedulesId}
          columns={columns}
          onRow={onRow}
          pagination={{
            onChange: onChangePage,
            total: totalRecords,
            current: filterHistoryListOptions?.page,
          }}
        />

        <BaseModal
          className="history-modal"
          open={historyModal.isOpen}
          onCancel={historyModal.actions.onClose}
          cancelButtonProps={{ hidden: true }}
          okButtonProps={{ hidden: true }}
          width="120rem"
          centered
        >
          <PageHeader
            className="history-popup-header"
            title={resultTotalInfo?.title}
            subTitle={
              <span style={{ backgroundColor: statusColor }}>
                {resultTotalInfo?.executeStatusName.toUpperCase()}
              </span>
            }
            footer={`Steps: ${resultTotalInfo?.maintenanceResultCount}/${resultTotalInfo?.workStepCount}`}
            extra={
              <div className="history-popup-info">
                {popupHeaderInfo.map((info, idx) => (
                  <div key={idx}>
                    <p className="info-title">{info.title}:</p>
                    <p className="info-desc">{info.value}</p>
                  </div>
                ))}
              </div>
            }
          />
          <div className="history-modal-content">
            {values?.map((step) => (
              <HistoryStepResult {...step} key={step.count} />
            ))}
          </div>
        </BaseModal>
      </div>
    </>
  );
};

export { PreviewFormMaintenanceHistory };
