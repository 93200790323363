import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { useAppSelector } from '@hooks';
import { Input, TreeSelect } from '@shared/UI';
import {
  selectPaICategoriesList,
  selectPaICategoryStatus,
} from '@store/selectors/properties/PaI/Category';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreatePaICategoryDto } from '@model/properties/PaI/Category';

const { CaseField, Item, useForm } = CommonForm;

function FormPartAndInventoryCategory(props: CommonFormProps<CreatePaICategoryDto>) {
  const [form] = useForm<CreatePaICategoryDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const statusPartAndInventoryCategory = useAppSelector(selectPaICategoryStatus);

  const listPartAndInventoryCategory = useAppSelector(selectPaICategoriesList);

  return (
    <CommonForm 
      form={form} 
      loading={statusPartAndInventoryCategory === 'pending'} 
      isModal={isModal}
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onCancel={rest.header?.onClickCancel}
        /> : null
      }
      name='formPaICategory'
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="partsAndInventoriesCategoryCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item
          label={t('titles.Name')}
          name="partsAndInventoriesCategoryName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item label={t('titles.Parent_Category')} name="partsAndInventoriesParentCategoryId">
          <TreeSelect
            loading={statusPartAndInventoryCategory === 'pending'}
            treeData={listPartAndInventoryCategory}
            fieldNames={{
              label: 'partsAndInventoriesCategoryName',
              value: 'partsAndInventoriesCategoryId',
              children: 'childPartsAndInventoriesCategories',
            }}
            placeholder={t('titles.Choose_Name', { name: t('titles.Parent_Category') })}
          />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormPartAndInventoryCategory };
