import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import type { RootState } from '@store/rootReducer';

export const selectAlertState = (state: RootState) => state.AlertReducer;

// export const selectAlerts = (state: RootState) => selectAlertState(state).alertsList;

export const selectAlerts = createSelector(selectAlertState, (state) => {
  const list = [...state.alertsList];

  return list.sort((a, b) => {
    const aDate = moment(a.createdDate);
    const bDate = moment(b.createdDate);

    return aDate > bDate ? -1 : 1;
  });
});

export const selectAlertStatus = (state: RootState) => selectAlertState(state).alertStatus;

export const selectAlertError = (state: RootState) => selectAlertState(state).alertError;

export const selectAlertsReadAndUnread = createSelector([selectAlerts], (alerts) => {
  const readAlerts = alerts
    .filter((alert) => alert.isRead)
    .sort((a, b) => {
      return moment(a.createdDate) > moment(b.createdDate) ? -1 : 1;
    });
  const unreadAlerts = alerts
    .filter((alert) => !alert.isRead)
    .sort((a, b) => {
      return moment(a.createdDate) > moment(b.createdDate) ? -1 : 1;
    });

  return { readAlerts, unreadAlerts };
});
