import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUploadFile } from '@hooks';
import { v4 as uuidv4 } from 'uuid';

import type { FileWithID } from '@interfaces/interfaces';
import type { AssetFileModel } from '@model/management/Asset';

import './AssetUploaderImage.scss';

interface AssetUploaderImageProps {
  getUploadedFiles?: (uploadedFiles: string[]) => void;
  onChange?: (value: Array<FileWithID | FileWithID<AssetFileModel>>) => void;
  value?: FileWithID[];
}

const AssetUploaderImage = (props: AssetUploaderImageProps) => {
  const { value, getUploadedFiles, onChange: onChangeProps } = props;

  const { t } = useTranslation();

  const [state, setState] = React.useState<Array<FileWithID | FileWithID<AssetFileModel>>>([]);

  const { onChange, inputRef } = useUploadFile({
    cb: (uploadedFiles, uploadedFilesBase64) => {
      const files = uploadedFiles.map((file) => ({ file, id: uuidv4() }));
      getUploadedFiles && getUploadedFiles(uploadedFilesBase64);
      let values: Array<FileWithID | FileWithID<AssetFileModel>> = [];
      setState((prevState) => {
        values = [...prevState, ...files];
        return values;
      });
      onChangeProps?.(values);
    },
    currentFilesLength: state.length,
  });

  useEffect(() => {
    if (value) {
      setState(value);
    }
  }, [value]);

  return (
    <div className="asset-uploader-image">
      <div className="asset-uploader-image__input-wrapper">
        <label htmlFor="asset-uploader-image">
          {t('titles.Upload_Image')}
          <input
            id="asset-uploader-image"
            type="file"
            onChange={onChange}
            ref={inputRef}
            accept=".png, .jpg, .jpeg, .webp"
            multiple
          />
        </label>
      </div>
      <span className="asset-uploader-image__info">
        {t('common.placeholder_image_size', { number: 5 })}
      </span>
    </div>
  );
};

export { AssetUploaderImage };
