import { useTranslation } from 'react-i18next';

import { PreviewFormUser } from '@components/views/Previews/users';
import { useOutletUser } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeUserById } from '@store/actions/user/User';

import type { CommonPreviewProps } from '@components/common';

function PreviewUser() {
  const { entity } = useOutletUser();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { t } = useTranslation();

  const { userAccess } = useGetAccess();

  const onDelete = () => {
    dispatch(removeUserById([entity.userId]))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t(`notifications.Success`),
          description: t('notifications.Deleted', { name: entity.userCode }),
        });
        navigate(paths.users.default);
      })
      .catch((error) => {
        notificationController.error({
          message: t(`notifications.Error`),
          description: t(`errors.${error}`),
        });
      });
  };
  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !userAccess.edit,
    },
    deleteButtonProps: {
      disabled: !userAccess.delete,
    },
  };

  return <PreviewFormUser entity={entity} onDelete={onDelete} header={headerProps} />;
}

export default PreviewUser;
