import React from 'react';
import { useTranslation } from 'react-i18next';

import { Pie, PieChart } from 'recharts';

import { NoResults } from '../../NoResults';

import './PieAsset.scss';

interface PieAssetProps {
  data: {
    statusId: number;
    name: string;
    count: number;
  }[] | undefined
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  value: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
      {value}
    </text>
  );
};

const PieAsset = (props: PieAssetProps) => {
  const { data } = props;
  const { t } = useTranslation();

  const pieData = data?.map((one, i) => {    
    switch (i) {
      case 0: return {
        ...one,
        fill: '#00458A',
      }
      case 1: return {
        ...one,
        fill: '#1163B6',
      }
      case 2: return {
        ...one,
        fill: '#4DA1F5',
      }
    }
  });

  return (
    <div className="pie-asset-chart">
      <div className="pie-asset-chart__header">
        <h6>{t('titles.Asset_By_Status')}</h6>
      </div>
      <div className="pie-asset-chart__content">
      {(data && !data.length) ?
        <NoResults />
        :
        <>
        <div className="pie-asset-chart__info">
          {data && data.map(one => 
            <div className="pie-asset-chart__info-item" key={one.statusId}>
              <span className="item-title">{one.name}</span>
              <span className="item-value value-active">{one.count}</span>
            </div>
          )}
        </div>
          <div className="pie-asset-chart__chart">
            <PieChart width={220} height={220}>
              <Pie
                cx="50%"
                cy="50%"
                data={pieData}
                labelLine={false}
                label={renderCustomizedLabel}
                innerRadius={50}
                outerRadius={90}
                dataKey="count"
                fill="#4DA1F5"
                blendStroke
                minAngle={20}
              />
            </PieChart>
          </div>
          </>
        }
      </div>
    </div>
  );
};

export { PieAsset };
