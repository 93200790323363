import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PreviewFormAssetName } from '@components/views/Previews/properties';
import { useOutletAssetName } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeAsAtCardById } from '@store/actions/properties/AsAt/Card';

import type { CommonPreviewProps } from '@components/common';

function PreviewAssetName() {
  const { entity } = useOutletAssetName();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { assetAttrAccess } = useGetAccess();

  const onDelete = () => {
    dispatch(removeAsAtCardById([entity.nonCurrAssetCardId]))
      .unwrap()
      .then(() => {
        notificationController.success({
          description: `${entity.name} deleted`,
        });
        navigate(paths.assetAttribute.tabs.name.default);
      })
      .catch((err) => {
        if (typeof err === 'string') {
          notificationController.error({
            description: err,
          });
        }
      });
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !assetAttrAccess.edit,
    },
    deleteButtonProps: {
      disabled: !assetAttrAccess.delete,
    },
  };

  return <PreviewFormAssetName entity={entity} onDelete={onDelete} header={headerProps} />;
}

export default PreviewAssetName;
