import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getPaIs } from '@store/actions/management/PaI';
import { selectPaIsList } from '@store/selectors/management/PaI';

function EntryPartAndInventory() {
  useSetPageInfo({
    defaultPagePath: paths.partAndInventory.default,
    pageTitle: 'pages_single.PaI',
  });

  const listPartAndInventory = useAppSelector(selectPaIsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listPartAndInventory.length) {
      dispatch(getPaIs());
    }
  }, []);

  return <Outlet />;
}

export default EntryPartAndInventory;
