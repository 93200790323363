import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getPaIModels } from '@store/actions/properties/PaI/Model';
import { selectPaIModelsList } from '@store/selectors/properties/PaI/Model';

function EntryPartAndInventoryModel() {
  useSetPageInfo(
    {
      pageTitle: 'pages_single.PaI_Attr',
      defaultPagePath: paths.partAndInventoryAttributes.tabs.model.default,
    },
    {
      copyTabs: true,
    }
  );

  const partAndInventoryModelList = useAppSelector(selectPaIModelsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!partAndInventoryModelList.length) {
      dispatch(getPaIModels());
    }
  }, []);

  return <Outlet />;
}

export default EntryPartAndInventoryModel;
