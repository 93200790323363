import type { CheckStepItem, CheckStepState, CheckStepStateHandler } from '../../types';

export const onRemoveStep = (
  state: CheckStepState,
  setState: CheckStepStateHandler,
  step: CheckStepItem,
  cb?: (removedStep: CheckStepItem) => void
) => {
  setState((prevState) =>
    prevState
      .filter((s) => s.uuid !== step.uuid)
      .map((step, idx) => ({
        ...step,
        rowId: idx + 1,
      }))
  );

  if (cb) {
    cb(step);
  }
};
