import {createAsyncThunk} from '@reduxjs/toolkit';
import {PaIRepository} from '@repositories';
import {identifyError} from '@shared/utils/functions';

import type {FilterAssetDto} from '@model/management/Asset';
import type {
  BatchUpdatePaIsDto,
  ConsumePaIDto,
  CreatePaIDto,
  FilterPaIDto,
  PaIConsumeNAMEModel,
  PaIConsumePOModel,
  PaIModel,
  PaITypeNAMEModel,
  UpdatePaIDto,
} from '@model/management/PaI';
import type {ApiError} from '@services/Axios';
import type {RootState} from '@store/rootReducer'; // region --- Get Actions
import type {AppDispatch} from '@store/store';

// region --- Get Actions

export const getPaIs = createAsyncThunk<
  {
    partsAndInventories: PaIModel[] | PaITypeNAMEModel[];
    settingPartsAndInventories: {
      totalPage: number;
      totalRecords: number;
    };
    options: Partial<FilterPaIDto> | null;
  },
  Partial<FilterPaIDto> | void,
  {
    state: RootState;
    rejectValue: Record<'error' | 'options', any>;
  }
>('PaI/getPaIs', async (filterOptions, { rejectWithValue, getState }) => {
  try {
    const stateFilterOptions = getState().PaIReducer.filterOptions;

    const response = await PaIRepository.fetchAll({
      ...stateFilterOptions,
      ...filterOptions,
    });

    return {
      partsAndInventories: response.resultObject,
      settingPartsAndInventories: {
        totalPage: response.totalPage,
        totalRecords: response.totalRecords,
      },
      options: filterOptions || null,
    };
  } catch (err: any) {
    const error = identifyError(err);

    return rejectWithValue({
      error,
      options: filterOptions,
    });
  }
});

export const getPaIsTypePO = createAsyncThunk<PaIModel[], void, { rejectValue: string }>(
  'PaI/getPaIsTypePO',
  async (_, { rejectWithValue }) => {
    try {
      const getTotalRecords = await PaIRepository.fetchAll({
        page: 1,
        perPage: 1,
        switchPoOrName: 1,
      });
      const totalRecords = getTotalRecords.totalRecords;
      const response = await PaIRepository.fetchAll({
        page: 1,
        perPage: totalRecords,
        switchPoOrName: 1,
      });

      return response.resultObject as PaIModel[];
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getPaIsTypeName = createAsyncThunk<PaITypeNAMEModel[], void, { rejectValue: string }>(
  'PaI/getPaIsTypeName',
  async (_, { rejectWithValue }) => {
    try {
      const getTotalRecords = await PaIRepository.fetchAll({
        page: 1,
        perPage: 1,
        switchPoOrName: 2,
      });
      const totalRecords = getTotalRecords.totalRecords;
      const response = await PaIRepository.fetchAll({
        page: 1,
        perPage: totalRecords,
        switchPoOrName: 2,
      });

      return response.resultObject as PaITypeNAMEModel[];
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getPaIById = createAsyncThunk<
  PaIModel,
  string,
  { rejectValue: string; state: RootState }
>('PaI/getPaIById', async (id, { rejectWithValue, getState }) => {
  try {
    const consumeType = getState().PaIReducer.settingsPartAndInventory.consumeType;

    const type = consumeType === 1 ? 'po' : 'name';

    const response = await PaIRepository.fetchById(id, type);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getPaIConsumeByCardId = createAsyncThunk<
  PaIConsumeNAMEModel[],
  string,
  { rejectValue: string }
>('PaI/getPaIConsumeByCardId', async (cardId, { rejectWithValue }) => {
  try {
    const response = await PaIRepository.fetchConsumeByPaiCardId(cardId);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});



export const getPaIConsumeByRowId = createAsyncThunk<
  PaIConsumePOModel[],
  number,
  { rejectValue: string }
>('PaI/getPaIConsumeByRowId', async (poNumber, { rejectWithValue }) => {
  try {

    const response = await PaIRepository.fetchConsumeByPO(poNumber);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addPaIConsume = createAsyncThunk<
  PaIModel[] | PaITypeNAMEModel[],
  ConsumePaIDto,
  { rejectValue: string; state: RootState }
>('PaI/addPaIConsume', async (dto, { rejectWithValue, getState }) => {
  try {
    const { page, switchPoOrName, perPage } = getState().PaIReducer.filterOptions;

    await PaIRepository.addConsume(dto);
    const response = await PaIRepository.fetchAll({
      page,
      switchPoOrName,
      perPage,
    });

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const addPaI = createAsyncThunk<PaIModel, CreatePaIDto, { rejectValue: string }>(
  'PaI/addPaI',
  async (dto, { rejectWithValue }) => {
    try {
      const response = await PaIRepository.add(dto);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Add Actions

// region --- Update Actions

export const batchUpdatePaI = createAsyncThunk<
  void,
  BatchUpdatePaIsDto,
  { rejectValue: string; state: RootState; dispatch: AppDispatch }
>('PaI/batchUpdatePaI', async (dto, { rejectWithValue, getState, dispatch }) => {
  try {
    await PaIRepository.batchUpdate(dto);

    const currentFilters: FilterAssetDto = getState().PaIReducer.filterOptions;

    await dispatch(getPaIs(currentFilters));
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

export const updatePaIFilterOptions = createAsyncThunk<
  Partial<FilterPaIDto>,
  Partial<FilterPaIDto>
>('PaI/updatePaIFilterOptions', async (filterOptions) => {
  return filterOptions;
});

export const updatePaIConsumeType = createAsyncThunk<1 | 2, 1 | 2>(
  'PaI/updatePaIConsumeType',
  (consumeType) => {
    return consumeType;
  }
);

export const updatePaI = createAsyncThunk<
  {
    particularPartAndInventory: PaIModel;
    partsAndInventories: PaIModel[] | PaITypeNAMEModel[];
  },
  UpdatePaIDto,
  { rejectValue: string; state: RootState }
>('PaI/updatePaI', async (dto, { rejectWithValue, getState }) => {
  try {
    const stateFilterOptions = getState().PaIReducer.filterOptions;

    const response = await PaIRepository.update(dto);
    const responseList = await PaIRepository.fetchAll(stateFilterOptions);

    return {
      particularPartAndInventory: response.resultObject,
      partsAndInventories: responseList.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions
