import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { Checkbox } from '@shared/UI';
import { selectAsAtPropertyStatus } from '@store/selectors/properties/AsAt/Property';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { AsAtPropertyModel } from '@model/properties/AsAt/Property';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormAssetProperty(props: CommonPreviewProps<AsAtPropertyModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const assetPropertyStatus = useAppSelector(selectAsAtPropertyStatus);

  return (
    <CommonForm
      isPreview
      isOutsidePreviewBack={false}
      loading={assetPropertyStatus === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField hiddenTitle>
        <Item>
          <Checkbox checked={entity.isUnique}>{t('titles.Is_Unique')}</Checkbox>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.nonCurrAssetCardPropertyCode}</PreviewField>
        </Item>
        <Item label={t('titles.Property_Type')}>
          <PreviewField>{entity.nonCurrAssetType.name}</PreviewField>
        </Item>

        {entity.nonCurrAssetPropertyValues.length
          ? entity.nonCurrAssetPropertyValues.map((propertyValue) => (
              <PreviewField
                typeField="property-value"
                key={propertyValue.nonCurrAssetPropertyValueId}
              >
                {propertyValue.value}
              </PreviewField>
            ))
          : null}
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormAssetProperty };
