import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { useAppSelector } from '@hooks';
import { Input } from '@shared/UI';
import { selectAsAtBrandStatus } from '@store/selectors/properties/AsAt/Brand';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateAsAtBrandDto } from '@model/properties/AsAt/Brand';

const { CaseField, Item, useForm } = CommonForm;

function FormAssetBrand(props: CommonFormProps<CreateAsAtBrandDto>) {
  const [form] = useForm<CreateAsAtBrandDto>();

  const { isModal, ...rest } = props;

  const {t} = useTranslation();

  const assetBrandStatus = useAppSelector(selectAsAtBrandStatus);

  return (
    <CommonForm
      form={form}
      loading={assetBrandStatus === 'pending'}
      isModal={isModal}
      name="formAssetBrand"
      additionalFooter={
        isModal ?
        <ModalFormFooter 
          onCancel={rest.header?.onClickCancel} /> : null
      }
      {...rest}
    >
      <CaseField hiddenTitle isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="nonCurrAssetCardBrandCode"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Code')}/>
        </Item>

        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Name')}/>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export {FormAssetBrand};
