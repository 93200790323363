import axios from 'axios';

import type { ApiResponse } from '@services/Axios';

const VersionUrl = 'Version';

export const VersionRepository = {
  async fetch() {
    const response: ApiResponse<string> = await axios.get(
      `${process.env.REACT_APP_API_URL}/${VersionUrl}/GetVersionNumber`
    );

    return response.data
  },
};
