import React, { useEffect, useState } from 'react';
import { Trans, Translation, useTranslation } from 'react-i18next';

import { SimpleTable } from '@components/common';
import { HistoryStepResult } from '@components/views/Previews/management/Maintenance/History/HistoryStepResults';
import { defaultFormatDate } from '@constants/Date';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
  useDebounce,
  useGetMaintenanceSchedules,
  useModalState,
  useUpdateEffect,
} from '@hooks';
import { paths } from '@routes/paths';
import CryptographyService from '@services/Cryptography';
import { Notifications } from '@services/Notifications';
import { ApiNotifications } from '@services/Notifications/adapters';
import { Kanban, PageHeader } from '@shared/UI';
import { BaseModal } from '@shared/UI';
import { checkStoreStatuses } from '@shared/utils/functions';
import {
  getMaintenanceById,
  getMaintenanceHistoryList,
  getMaintenances,
} from '@store/actions/management/Maintenance';
import {
  createMaintenanceResult,
  getMaintenanceResultByHistory,
  getMaintenanceResultByScheduleIdVer2,
  getMaintenanceSchedulesKanbanList,
  updateMaintenanceResultStatus,
} from '@store/actions/management/MaintenanceResult';
import { getUsers } from '@store/actions/user/User';
import {
  selectMaintenanceHistoryList,
  selectMaintenancesList,
  selectMaintenanceStatus,
} from '@store/selectors/management/Maintenance';
import {
  selectMaintenancByHistoryResult,
  selectMaintenanceResultStatus,
} from '@store/selectors/management/MaintenanceResult';
import { selectProfile } from '@store/selectors/profile/Profile';
import { selectUsersList } from '@store/selectors/users/User';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { CellContent } from './CellContent';
import { Header } from './Header';

import type { ColumnsTableType } from '@components/common';
import type {
  CreateMaintenanceResultDto,
  MaintenanceHistoryModel,
  MaintenanceResultVer2Model,
  MaintenanceScheduleModel,
} from '@model/management/Maintenance';
import type { ColumnConfig, DragEnd } from '@shared/UI';

import './MaintenanceKanban.scss';

const columns: ColumnConfig<MaintenanceScheduleModel>[] = [
  {
    type: 'open',
    key: 'OPEN',
    accept: 'open',
    title: <Translation>{(t) => t('titles.Open')}</Translation>,
    headerColor: '#DDEEFF',
    filterData: (schedule) => schedule.maintenanceExecuteStatusId === 1,
  },
  {
    type: 'inProgress',
    key: 'InPROGRESS',
    accept: 'open',
    title: <Translation>{(t) => t('titles.In_Progress')}</Translation>,
    headerColor: '#D6FFAD',
    filterData: (schedule) => schedule.maintenanceExecuteStatusId === 2,
  },
  {
    type: 'complete',
    key: 'COMPLETE',
    accept: 'inProgress',
    title: <Translation>{(t) => t('titles.Complete')}</Translation>,
    headerColor: '#FBE3D1',
    filterData: (schedule) => schedule.maintenanceExecuteStatusId === 3,
  },
];

const columns1: ColumnsTableType<MaintenanceHistoryModel> = [
  {
    title: <Translation>{(t) => t('titles.Barcode')}</Translation>,
    dataIndex: 'barcode',
    key: 'barcode',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Assign_To')}</Translation>,
    dataIndex: 'assignToName',
    key: 'assignToName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Additional_Assign_To')}</Translation>,
    dataIndex: 'assigneeNames',
    key: 'assigneeNames',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Maintenance_Date')}</Translation>,
    ellipsis: true,
    render: (_, { maintenanceDate }) => <>{moment(maintenanceDate).format(defaultFormatDate)}</>,
  },
  {
    title: <Translation>{(t) => t('titles.Execute_Hour')}</Translation>,
    dataIndex: 'totalMaintenanceDurationInHours',
    key: 'totalMaintenanceDurationInHours',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: 'siteName',
    key: 'siteName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Category')}</Translation>,
    dataIndex: 'categoryName',
    key: 'categoryName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Status')}</Translation>,
    ellipsis: true,
    render: (_, obj) => {
      const doneWorkStep = `${(obj.maintenanceResultCount / obj.workStepCount) * 100}%`;
      const statusColor =
        obj.maintenanceExecuteStatusId === 1
          ? '#DF0909'
          : obj.maintenanceExecuteStatusId === 2
          ? '#032F5C'
          : '#0C7A02';

      return (
        <div className="history-status">
          <span>{`${obj.maintenanceResultCount}/${obj.workStepCount}`}</span>
          <span>
            <span style={{ width: doneWorkStep, backgroundColor: statusColor }} />
          </span>
        </div>
      );
    },
  },
];

const MaintenanceKanban = () => {
  const [state, setState] = useState({
    startDate: formatISO(startOfMonth(new Date())),
    endDate: formatISO(endOfMonth(new Date())),
  });

  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearch = useDebounce(searchValue, 500);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useAppNavigate();

  const profile = useAppSelector(selectProfile)!;
  const maintenanceStatus = useAppSelector(selectMaintenanceStatus);
  const maintenanceResultStatus = useAppSelector(selectMaintenanceResultStatus);
  const maintenancesList = useAppSelector(selectMaintenancesList);

  const historyList = useAppSelector(selectMaintenanceHistoryList);
  const historyResults = useAppSelector(selectMaintenancByHistoryResult);

  const { schedules } = useGetMaintenanceSchedules(state, [state]);

  const [data, setData] = useState(schedules);

  const usersList = useAppSelector(selectUsersList);

  const historyModal = useModalState();
  const maintenanceModal = useModalState();

  const loading = checkStoreStatuses([maintenanceResultStatus, maintenanceStatus]);
  const loadingStatus = useAppSelector(selectMaintenanceStatus);
  const resultLoadingStatus = useAppSelector(selectMaintenanceResultStatus);

  useEffect(() => {
    dispatch(getMaintenanceSchedulesKanbanList(state));

    if (!maintenancesList.length) {
      dispatch(getMaintenances());
    }
    if (!usersList.length) {
      dispatch(getUsers());
    }
  }, []);

  useUpdateEffect(() => {
    dispatch(getMaintenanceSchedulesKanbanList(state))
      .unwrap()
      .catch(() => {
        ApiNotifications.info('There is no maintenance on the dates you selected');
      });

    setSearchValue('');
  }, [state]);

  useEffect(() => {
    setData(schedules);
  }, [schedules]);

  useUpdateEffect(() => {
    handleDataBySearch();
  }, [debouncedSearch]);

  function handleDataBySearch() {
    const foundSchedules = schedules.filter((schedule) =>
      schedule.title.toLowerCase().includes(debouncedSearch.toLowerCase())
    );

    if (debouncedSearch.length === 0) {
      setData(() => schedules);
    }

    setData(() => foundSchedules);
  }

  const renderCellContent = (schedule: typeof schedules[number]) => {
    return <CellContent schedule={schedule} />;
  };

  async function getResults(schedule: typeof schedules[number]) {
    const response = await dispatch(
      getMaintenanceResultByScheduleIdVer2({
        scheduleId: schedule.maintenanceSchedulesId,
        maintenanceId: schedule.maintenanceId,
      })
    ).unwrap();

    return response;
  }

  async function onDropToInProgress(scheduleId: number) {
    try {
      // change status from 1 to 2
      await dispatch(
        updateMaintenanceResultStatus({
          maintenanceExecuteStatusId: 2,
          maintenanceSchedulesId: scheduleId,
        })
      );
    } catch (err) {
      ApiNotifications.error(err);
    }
  }

  // It creates a list of completed work steps,
  // if step has maintenancesResults, and it isn't empty,
  // it will add to completed array.
  // It needs to compare with the response from API
  // and check if it has the same length

  async function onDropToComplete(
    records: MaintenanceResultVer2Model[],
    scheduleID: number,
    maintenanceID: number
  ) {
    const completedResult = createCompletedResult(records, scheduleID);
    if (completedResult && completedResult.length === records.length) {
      try {
        await dispatch(createMaintenanceResult(completedResult)).unwrap();
        await dispatch(getMaintenanceSchedulesKanbanList(state));
      } catch (err) {
        ApiNotifications.error(err);
      }
    } else {
      const response = await dispatch(getMaintenanceById(maintenanceID)).unwrap();
      ApiNotifications.error(t('errors.Has_Unfinished_Work_Steps', { name: response.title }));
    }
  }

  function createCompletedResult(records: MaintenanceResultVer2Model[], scheduleID: number) {
    return records.reduce((acc: CreateMaintenanceResultDto[] | null, result) => {
      if (acc === null) {
        return acc;
      }
      const {
        maintenancesResults,
        maintenanceMultipleChoiceListResults,
        maintenanceImageListResults,
      } = result.workSteps;
      if (maintenancesResults.length && maintenancesResults[0]) {
        const res = maintenancesResults[0];
        const dto: CreateMaintenanceResultDto = {
          workStepId: res.workStepsId,
          maintenanceId: res.maintenanceId,
          maintenanceSchedulesId: scheduleID,
          resultText: res.resultText,
          maintenanceExecuteStatusId: 3,
          loggedUserId: profile.userId,
          mcList:
            maintenanceMultipleChoiceListResults.length && maintenanceMultipleChoiceListResults[0]
              ? [
                  {
                    resultText: maintenanceMultipleChoiceListResults[0].resultText,
                    workStepsId: res.workStepsId,
                    multipleChoiceListForWorkStepsId:
                      maintenanceMultipleChoiceListResults[0]
                        .maintenanceMultipleChoiceListResultsId,
                  },
                ]
              : [],
          imgList: {
            resultImageLinkArray: maintenanceImageListResults.length
              ? maintenanceImageListResults.map((image) => image.imageLink)
              : [],
            workStepsId: res.workStepsId,
          },
        };
        acc.push(dto);
      }
      return acc;
    }, []);
  }

  async function onDrop(result: DragEnd<typeof schedules[number]>) {
    if (result.dragType === result.targetColumn) return;
    const { record } = result;
    try {
      // get a result with work steps
      const response = await getResults(record);
      // if the result by drop has status 1
      // It will change the status to 2
      if (
        result.targetColumn === 'inProgress' &&
        result.dragType === 'open' &&
        record.maintenanceExecuteStatusId === 1
      ) {
        await onDropToInProgress(record.maintenanceSchedulesId);
      }
      if (
        result.targetColumn === 'complete' &&
        result.dragType === 'inProgress' &&
        record.maintenanceExecuteStatusId === 2
      ) {
        await onDropToComplete(
          response.result,
          record.maintenanceSchedulesId,
          record.maintenanceId
        );
      }
    } catch {
      try {
        const response = await dispatch(getMaintenanceById(record.maintenanceId)).unwrap();
        ApiNotifications.info(
          <span>
            <Trans i18nKey="errors.There_Arent_Work_Steps">
              There aren't work steps in the
              <a
                onClick={() => {
                  onToEditWorkSteps(record.maintenanceId);
                  Notifications.close(record.maintenanceId.toString());
                }}
                style={{ fontWeight: 600, display: 'block' }}
              >
                {{ name: response.title }}
              </a>
            </Trans>
          </span>,
          {
            key: record.maintenanceId.toString(),
          }
        );
      } catch (err) {
        ApiNotifications.error(err);
      }
    }
  }

  async function onClickCell(record: typeof schedules[number]) {
    dispatch(
      getMaintenanceHistoryList({
        page: 1,
        perPage: 50,
        maintenanceId: record.maintenanceId,
      })
    )
      .unwrap()
      .then(() =>
        !record.nonCurrAssetId ? historyModal.actions.onOpen() : maintenanceModal.actions.onOpen()
      );

    dispatch(
      getMaintenanceResultByHistory({
        maintenanceId: record.maintenanceId,
        scheduleId: record.maintenanceSchedulesId,
        nonCurrAssetId: record.nonCurrAssetId || 0,
      })
    );

    //   try {
    //     await dispatch(
    //       getMaintenanceResultByScheduleIdVer2({
    //         scheduleId: record.maintenanceSchedulesId,
    //         maintenanceId: record.maintenanceId,
    //       })
    //     ).unwrap();
    //     navigate(`${paths.maintenance.resultDefault}/${scheduleId}/${maintenanceId}`);
    //   } catch {
    //     try {
    //       const response = await dispatch(getMaintenanceById(record.maintenanceId)).unwrap();
    //       ApiNotifications.info(
    //         <span>
    //           There aren't work steps in the
    //           <a
    //             onClick={() => {
    //               onToEditWorkSteps(record.maintenanceId);
    //               Notifications.close(maintenanceId);
    //             }}
    //             style={{ fontWeight: 600, display: 'block' }}
    //           >
    //             {response.title}
    //           </a>
    //         </span>,
    //         {
    //           key: maintenanceId,
    //         }
    //       );
    //     } catch (err) {
    //       ApiNotifications.error(err);
    //     }
    //   }
  }

  const resultTotalInfo = historyResults?.result.topdetails;

  const popupHeaderInfo = [
    {
      title: t('titles.Date'),
      value: moment(resultTotalInfo?.lastExecuteDate).format(defaultFormatDate),
    },
    {
      title: t('titles.Priority'),
      value: resultTotalInfo?.priorityName,
    },
    {
      title: t('titles.Assign_To'),
      value: resultTotalInfo?.assignToName,
    },
    {
      title: t('titles.Site'),
      value: resultTotalInfo?.siteName,
    },
  ];

  const popupHeaderInfo2 = [
    {
      title: t('titles.Date'),
      value: moment(resultTotalInfo?.lastExecuteDate).format(defaultFormatDate),
    },
    {
      title: t('titles.Assign_To'),
      value: resultTotalInfo?.assignToName,
    },
  ];

  const values =
    historyResults &&
    historyResults.result.getMaintenanceResultsBySchedulesIdVer3.map(({ workSteps }, count) => {
      const {
        stepName,
        multipleChoiceListForWorkSteps,
        maintenancesResults,
        stepTypes,
        maintenanceImageListResults,
      } = workSteps;

      const mcList = multipleChoiceListForWorkSteps.map((multi) => {
        const resultText =
          workSteps.maintenanceMultipleChoiceListResults[0] &&
          workSteps.maintenanceMultipleChoiceListResults[0].resultText === multi.optionText
            ? workSteps.maintenanceMultipleChoiceListResults[0].resultText
            : multi.optionText;
        return resultText;
      });

      const images = maintenanceImageListResults?.length
        ? maintenanceImageListResults
            .map((file) => ({
              file,
              id: uuidv4(),
            }))
            .sort((a, b) => {
              if (!a.file.maintenanceImageListResultsId || !b.file.maintenanceImageListResultsId) {
                return 0;
              }
              return a.file.maintenanceImageListResultsId - b.file.maintenanceImageListResultsId;
            })
        : [];

      const resultDate = maintenancesResults[0]
        ? moment(maintenancesResults[0].createdDate).format(defaultFormatDate)
        : '';
      const resultText = maintenancesResults[0] ? maintenancesResults[0].resultText : '';
      const resultUser = resultTotalInfo ? resultTotalInfo?.assignToName : '';

      return {
        stepName,
        mcList,
        images,
        stepType: stepTypes.stepTypeName,
        resultText,
        resultDate,
        resultUser,
        count: count + 1,
      };
    });

  const statusColor =
    resultTotalInfo?.executeStatusName === 'Open'
      ? '#DDEEFF'
      : resultTotalInfo?.executeStatusName === 'Complete'
      ? '#FBE3D1'
      : '#D6FFAD';

  async function onToEditWorkSteps(maintenanceId: number) {
    const hashId = CryptographyService.encrypt(`${maintenanceId}`);
    const workStepsPath = paths.maintenance.tabs.workOrders.default;
    const toEditWorkSteps = workStepsPath.replace(':id', hashId);
    try {
      await dispatch(getMaintenanceById(maintenanceId));
      navigate(toEditWorkSteps);
    } catch (err) {
      ApiNotifications.error(err);
    }
  }

  return (
    <div className="maintenance-kanban">
      <Header
        searchValue={searchValue}
        onChangeSearch={setSearchValue}
        onChangeDates={setState}
        {...state}
      />
      <Kanban
        loading={loading}
        data={data}
        dataKey={(rc) => rc.maintenanceSchedulesId}
        columns={columns}
        cellContentRender={renderCellContent}
        onDrop={onDrop}
        onClickCell={onClickCell}
      />
      <BaseModal
        className="history-modal"
        open={historyModal.isOpen}
        onCancel={historyModal.actions.onClose}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        width="120rem"
        centered
      >
        <PageHeader
          className="history-popup-header"
          title={resultTotalInfo?.title}
          extra={
            <div className="history-popup-info">
              {popupHeaderInfo2.map((info, idx) => (
                <div key={idx}>
                  <p className="info-title">{info.title}:</p>
                  <p className="info-desc">{info.value}</p>
                </div>
              ))}
            </div>
          }
        />
        <div className="history-modal-content">
          <SimpleTable
            className="history-page-table"
            dataSource={historyList}
            loading={loadingStatus === 'pending' || resultLoadingStatus === 'pending'}
            rowKey={Math.random}
            columns={columns1}
            pagination={{ defaultPageSize: 50 }}
          />
        </div>
      </BaseModal>
      <BaseModal
        className="history-modal"
        open={maintenanceModal.isOpen}
        onCancel={maintenanceModal.actions.onClose}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        width="120rem"
        centered
      >
        <PageHeader
          className="history-popup-header"
          title={resultTotalInfo?.title}
          subTitle={
            <span style={{ backgroundColor: statusColor }}>
              {resultTotalInfo?.executeStatusName.toUpperCase()}
            </span>
          }
          footer={`Steps: ${resultTotalInfo?.maintenanceResultCount}/${resultTotalInfo?.workStepCount}`}
          extra={
            <div className="history-popup-info">
              {popupHeaderInfo.map((info, idx) => (
                <div key={idx}>
                  <p className="info-title">{info.title}:</p>
                  <p className="info-desc">{info.value}</p>
                </div>
              ))}
            </div>
          }
        />
        <div className="history-modal-content">
          {values?.map((step) => (
            <HistoryStepResult {...step} key={step.count} />
          ))}
        </div>
      </BaseModal>
    </div>
  );
};

export { MaintenanceKanban };
