import React from 'react';

import { FieldSearchIcon } from '@icon/icon-components';
import { Input as AntdInput } from 'antd';
import cl from 'classnames';

import type { InputProps as AntdInputProps, InputRef as AntdInputRef } from 'antd';

import './InputSearch.scss';

export interface InputSearchProps extends AntdInputProps {}

export const InputSearch = React.forwardRef<AntdInputRef, InputSearchProps>((props, ref) => {
  const { className, ...rest } = props;

  const defaultClasses = cl('custom-input-search', className);

  return <AntdInput bordered={false} ref={ref} className={defaultClasses} {...rest} prefix={<FieldSearchIcon/>} />;
});
