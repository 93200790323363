import React from "react"
import { useTranslation } from "react-i18next";

import { FormLabel } from "@components/views/Forms/extra";
import {
  FormCostCenter,
  FormPartAndInventoryBrand,
  FormPartAndInventoryModel,
  FormPartAndInventoryName,
  FormPartAndInventoryProperty,
  FormPartAndInventoryStatus,
  FormSite,
  FormVendor,
} from '@components/views/Forms/properties';
import { CreateableEntityModal } from "@shared/UI"

import type { UseModalStateReturn } from "@hooks";

interface CreateableEntityModalProps {
  paINameModal: UseModalStateReturn;
  paIBrandModal: UseModalStateReturn;
  paIModelModal: UseModalStateReturn;
  paIPropertyModal: UseModalStateReturn;
  paIStatusModal: UseModalStateReturn;
  costCenterModal: UseModalStateReturn;
  labelModal: UseModalStateReturn;
  vendorModal: UseModalStateReturn;
  siteModal: UseModalStateReturn;
}

const CreateableEntityModals = (props: CreateableEntityModalProps) => {
  const { 
    paINameModal,
    paIBrandModal,
    paIModelModal, 
    paIPropertyModal, 
    paIStatusModal,
    labelModal, 
    vendorModal, 
    siteModal, 
    costCenterModal 
  } = props;

  const { t } = useTranslation();

    return (
      <>
        <CreateableEntityModal
          open={paINameModal.isOpen}
          onCancel={paINameModal.actions.onClose}
          form={
            <FormPartAndInventoryName
              isModal
              header={{
                onClickCancel: paINameModal.actions.onClose,    
                title: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Name') }),
              }}
            />
          }
        />
        <CreateableEntityModal
          open={paIBrandModal.isOpen}
          onCancel={paIBrandModal.actions.onClose}
          form={
            <FormPartAndInventoryBrand
              isModal
              header={{
                onClickCancel: paIBrandModal.actions.onClose,    
                title: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Brand') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={paIModelModal.isOpen}
          onCancel={paIModelModal.actions.onClose}
          form={
            <FormPartAndInventoryModel
              isModal
              header={{
                onClickCancel: paIModelModal.actions.onClose,    
                title: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Model') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={paIPropertyModal.isOpen}
          onCancel={paIPropertyModal.actions.onClose}
          form={
            <FormPartAndInventoryProperty
              isModal
              header={{
                onClickCancel: paIPropertyModal.actions.onClose,    
                title: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Property') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={paIStatusModal.isOpen}
          onCancel={paIStatusModal.actions.onClose}
          form={
            <FormPartAndInventoryStatus
              isModal
              header={{
                onClickCancel: paIStatusModal.actions.onClose,    
                title: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Status') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={costCenterModal.isOpen}
          onCancel={costCenterModal.actions.onClose}
          form={
            <FormCostCenter
              isModal
              header={{
                onClickCancel: costCenterModal.actions.onClose,    
                title: t('titles.New_Item', { name: t('pages_single.Cost_Center') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={vendorModal.isOpen}
          onCancel={vendorModal.actions.onClose}
          form={
            <FormVendor
              isModal
              header={{
                onClickCancel: vendorModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Vendor') }),
              }}
            />
          }
        />
        <CreateableEntityModal
          open={siteModal.isOpen}
          onCancel={siteModal.actions.onClose}
          form={
            <FormSite
              isModal
              header={{
                onClickCancel: siteModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Site') }),
              }}
            />
          }
        />
        <CreateableEntityModal
          open={labelModal.isOpen}
          onCancel={labelModal.actions.onClose}
          form={
            <FormLabel
              isModal
              header={{
                onClickCancel: labelModal.actions.onClose,
                title: t('titles.New_Item', { name: t('titles.Label') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
      </>
    )
}

export { CreateableEntityModals };