import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AccessKey } from '@constants/AccessKey';
import {
  AssetIcon,
  AttributeIcon,
  CompanyIcon,
  ContractIcon,
  DashboardIcon,
  DepartmentIcon,
  Dot,
  Maintenances,
  OtherIcon,
  PartAndInventoryIcon,
  RoleAndAuthorizationIcon,
  SiteIcon,
  TaskIcon,
  TitleIcon,
  UserIcon,
  VendorIcon,
} from '@icon/icon-components';
import { paths } from '@routes/paths';

import type {
  ItemType,
  MenuDividerType,
  MenuItemGroupType,
  MenuItemType,
  SubMenuType,
} from 'antd/es/menu/hooks/useItems';

export type ItemT = ItemType & { accessKey?: AccessKey };

export type MenuItem =
  | (MenuItemType & { accessKey?: AccessKey })
  | (Omit<MenuItemGroupType, 'children'> & { children?: ItemT[] })
  | (MenuDividerType & { accessKey?: AccessKey })
  | (Omit<SubMenuType, 'children'> & { children?: ItemT[] });

const Item = ({
  title,
  link,
  keyTranslation,
}: {
  title: string;
  keyTranslation?: string;
  link?: string;
}) => {
  const { t } = useTranslation();
  const translation = keyTranslation ? t(`${keyTranslation}`) : null;

  return (
    <>
      <span>{translation || title}</span>
      {link ? <Link to={link} /> : null}
    </>
  );
};

export const menuItems: MenuItem[] = [
  {
    label: <Item title="Dashboard" link={'/'} keyTranslation="pages_single.Dashboard" />,
    key: paths.dashboard.default,
    icon: <DashboardIcon />,
  },
  /* Group Management */
  {
    label: <Item title="Management" keyTranslation="pages_plural.Managements" />,
    type: 'group',
  },
  {
    label: <Item title="Assets" link={paths.asset.default} keyTranslation="pages_single.Asset" />,
    key: paths.asset.default,
    icon: <AssetIcon />,
    accessKey: AccessKey.Asset,
  },
  {
    label: (
      <Item
        title="Maintenances"
        link={paths.maintenance.default}
        keyTranslation="pages_single.Maintenance"
      />
    ),
    key: paths.maintenance.default,
    icon: <Maintenances />,
    accessKey: AccessKey.Maintenance,
  },
  {
    label: (
      <Item
        title="Parts and Inventories"
        link={paths.partAndInventory.default}
        keyTranslation="pages_single.PaI"
      />
    ),
    key: paths.partAndInventory.default,
    icon: <PartAndInventoryIcon />,
    accessKey: AccessKey.PaI,
  },
  {
    label: <Item title="Tasks" keyTranslation="pages_single.Task" />,
    key: 'Tasks',
    icon: <TaskIcon />,
    children: [
      {
        label: (
          <Item
            title="Count Plans"
            link={paths.countPlan.default}
            keyTranslation="pages_single.Count_Plan"
          />
        ),
        key: paths.countPlan.default,
        icon: <Dot />,
        accessKey: AccessKey.CountPlan,
      },
    ],
  },
  /* Group Properties */
  {
    label: <Item title="Properties" keyTranslation="pages_plural.Properties" />,
    type: 'group',
  },
  {
    label: <Item title="Attributes" keyTranslation="pages_single.Attribute" />,
    key: 'Attributes',
    icon: <AttributeIcon />,
    children: [
      {
        label: (
          <Item
            title="Asset Attributes"
            keyTranslation="pages_single.Asset_Attr"
            link={paths.assetAttribute.tabs.name.default}
          />
        ),
        key: paths.assetAttribute.default,
        icon: <Dot />,
        accessKey: AccessKey.AssetAttributes,
      },
      {
        label: (
          <Item
            title="Parts And Inventories"
            keyTranslation="pages_single.PaI_Attr"
            link={paths.partAndInventoryAttributes.tabs.name.default}
          />
        ),
        key: paths.partAndInventoryAttributes.default,
        icon: <Dot />,
        accessKey: AccessKey.PaIAttributes,
      },
      {
        label: (
          <Item
            title="Maintenance Attributes"
            keyTranslation="pages_single.Maintenance_Attr"
            link={paths.maintenanceAttributes.tabs.category.default}
          />
        ),
        key: paths.maintenanceAttributes.default,
        icon: <Dot />,
        accessKey: AccessKey.MaintenanceAttributes,
      },
    ],
  },
  {
    label: (
      <Item title="Vendors" keyTranslation="pages_single.Vendor" link={paths.vendor.default} />
    ),
    key: paths.vendor.default,
    icon: <VendorIcon />,
    accessKey: AccessKey.Vendor,
  },
  {
    label: (
      <Item
        title="Departments"
        keyTranslation="pages_single.Department"
        link={paths.department.default}
      />
    ),
    key: 'Departments',
    icon: <DepartmentIcon />,

    accessKey: AccessKey.Department,
  },
  {
    label: <Item title="Sites" keyTranslation="pages_single.Site" link={paths.site.default} />,
    key: paths.site.default,
    icon: <SiteIcon />,

    accessKey: AccessKey.Site,
  },
  {
    label: <Item title="Other" keyTranslation="pages_single.Other" />,
    key: 'Other',
    icon: <OtherIcon />,
    children: [
      {
        label: (
          <Item
            title="Exit Types"
            keyTranslation="pages_single.Exit_Type"
            link={paths.exitType.default}
          />
        ),
        key: paths.exitType.default,
        icon: <Dot />,
        accessKey: AccessKey.ExitType,
      },
      {
        label: (
          <Item
            title="Cost Centers"
            keyTranslation="pages_single.Cost_Center"
            link={paths.costCenter.default}
          />
        ),
        key: paths.costCenter.default,
        icon: <Dot />,

        accessKey: AccessKey.CostCenter,
      },
    ],
  },
  {
    label: (
      <Item
        title="Contracts"
        keyTranslation="pages_single.Contract"
        link={paths.contract.default}
      />
    ),
    key: paths.contract.default,
    icon: <ContractIcon />,
    accessKey: AccessKey.Contract,
  },
  /* Group User */
  {
    label: <Item title="Company account" keyTranslation="pages_plural.Company_Account" />,
    type: 'group',
  },
  {
    label: <Item title="Users" keyTranslation="pages_single.User" link={paths.users.default} />,
    key: paths.users.default,
    icon: <UserIcon />,
    accessKey: AccessKey.User,
  },
  {
    label: (
      <Item
        title="User groups"
        keyTranslation="pages_single.User_Group"
        link={paths.usergroups.default}
      />
    ),
    key: paths.usergroups.default,
    icon: <UserIcon />,
    accessKey: AccessKey.UserGroup,
  },
  {
    label: <Item title="Titles" keyTranslation="pages_single.Title" link={paths.title.default} />,
    key: paths.title.default,
    icon: <TitleIcon />,
    accessKey: AccessKey.Title,
  },
  {
    label: <Item title="Settings" keyTranslation="pages_single.Settings" />,
    key: 'Settings',
    icon: <RoleAndAuthorizationIcon />,
    children: [
      {
        label: (
          <Item
            title="Companies"
            keyTranslation="pages_single.Company"
            link={paths.companies.default}
          />
        ),
        key: paths.companies.default,
        icon: <CompanyIcon />,
        accessKey: AccessKey.Company,
      },
      {
        label: (
          <Item title="Roles" keyTranslation="pages_plural.Roles" link={paths.roles.default} />
        ),
        key: 'Roles',
        icon: <Dot />,
        accessKey: AccessKey.Role,
      },
      {
        label: (
          <Item
            title="Authorizations"
            keyTranslation="pages_plural.Authorizations"
            link={paths.userauth.default}
          />
        ),
        key: 'AndAuthorization',
        icon: <Dot />,
        accessKey: AccessKey.Authorization,
      },
    ],
  },
];
