import React from 'react';

import { PreviewFormCountPlan } from '@components/views/Previews/management';
import { deleteNotificationSuccess } from '@constants/notificationMessages';
import { useOutletCountPlan } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeCountPlanById } from '@store/actions/management/CountPlan';

import type { CommonPreviewProps } from '@components/common';

function PreviewCountPlan() {
  const { entity } = useOutletCountPlan();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { countPlanAccess } = useGetAccess();

  const onDelete = () => {
    dispatch(removeCountPlanById([entity.prdCountPlanId])).then(() => {
      notificationController.success({
        description: deleteNotificationSuccess(entity.taskName),
      });
      navigate(paths.countPlan.default);
    });
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !countPlanAccess.edit,
    },
    deleteButtonProps: {
      disabled: !countPlanAccess.delete,
    },
  };

  return <PreviewFormCountPlan entity={entity} onDelete={onDelete} header={headerProps} />;
}

export default PreviewCountPlan;
