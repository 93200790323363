import { api } from '@services/Axios';

import type { CreatePaICardDto, PaICardModel, UpdatePaICardDto } from '@model/properties/PaI/Card';
import type { ApiResponse } from '@services/Axios';

const PaICardEndpoint = '/PartsAndInventoriesCards';

export const PaICardRepository = {
  async fetchAll() {
    const response: ApiResponse<PaICardModel[]> = await api.get(
      `${PaICardEndpoint}/PartsAndInventoriesCardsList`
    );

    return response.data;
  },
  async add(dto: CreatePaICardDto) {
    const response: ApiResponse<PaICardModel> = await api.post(
      `${PaICardEndpoint}/AddPartsAndInventoriesCard`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<PaICardModel> = await api.get(
      `${PaICardEndpoint}/GetPartsAndInventoriesCardById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdatePaICardDto) {
    const response: ApiResponse<PaICardModel> = await api.post(
      `${PaICardEndpoint}/UpdatePartsAndInventoriesCard`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${PaICardEndpoint}/RemoveByIdList`, {
      partsAndInventoriesCardIds: ids,
    });

    return response.data;
  },
};
