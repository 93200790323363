import type { ReactNode } from 'react';

export const updateNotificationSuccess = (entityName: ReactNode): ReactNode => {
  return `${entityName} updated`;
};

export const createNotificationSuccess = (entityName: ReactNode): ReactNode => {
  return `${entityName} created`;
};

export const deleteNotificationSuccess = (entityName: ReactNode): ReactNode => {
  return `${entityName} deleted`;
};
