import { createSlice } from '@reduxjs/toolkit';
import {
  addPaIUnit,
  getPaIUnitById,
  getPaIUnits,
  removePaIUnitById,
  updatePaIUnit,
} from '@store/actions/properties/PaI/Unit';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { PaIUnitModel } from '@model/properties/PaI/Unit';

interface PaIUnitState {
  PaIUnitsList: PaIUnitModel[];
  PaIUnitStatus: LoadingStatus;
  PaIUnit: null | PaIUnitModel;
  PaIUnitError: null | string;
}

const initialState: PaIUnitState = {
  PaIUnitsList: [],
  PaIUnitStatus: 'idle',
  PaIUnit: null,
  PaIUnitError: null,
};

const PaIUnitSlice = createSlice({
  name: 'PaIUnit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaIUnits.pending, (state) => {
        state.PaIUnitError = null;
        state.PaIUnitStatus = 'pending';
      })
      .addCase(getPaIUnits.fulfilled, (state, { payload }) => {
        state.PaIUnitStatus = 'idle';
        state.PaIUnitsList = payload;
      })
      .addCase(getPaIUnits.rejected, (state, { payload }) => {
        state.PaIUnitStatus = 'idle';
        if (payload) {
          state.PaIUnitError = payload;
        }
      });

    builder
      .addCase(addPaIUnit.pending, (state) => {
        state.PaIUnitStatus = 'pending';
        state.PaIUnitError = null;
      })
      .addCase(addPaIUnit.fulfilled, (state, { payload }) => {
        state.PaIUnitStatus = 'idle';
        state.PaIUnitsList = [payload, ...state.PaIUnitsList];
      })
      .addCase(addPaIUnit.rejected, (state, { payload }) => {
        state.PaIUnitStatus = 'idle';
        if (payload) {
          state.PaIUnitError = payload;
        }
      });

    builder
      .addCase(getPaIUnitById.pending, (state) => {
        state.PaIUnitStatus = 'pending';
        state.PaIUnitError = null;
      })
      .addCase(getPaIUnitById.fulfilled, (state, { payload }) => {
        state.PaIUnitStatus = 'idle';
        state.PaIUnit = payload;
      })
      .addCase(getPaIUnitById.rejected, (state, { payload }) => {
        state.PaIUnitStatus = 'idle';
        if (payload) {
          state.PaIUnitError = payload;
        }
      });

    builder
      .addCase(updatePaIUnit.pending, (state) => {
        state.PaIUnitStatus = 'pending';
        state.PaIUnitError = null;
      })
      .addCase(updatePaIUnit.fulfilled, (state, { payload }) => {
        state.PaIUnitStatus = 'idle';
        state.PaIUnitsList = payload.units;
        state.PaIUnit = payload.particularUnit;
      })
      .addCase(updatePaIUnit.rejected, (state, { payload }) => {
        state.PaIUnitStatus = 'idle';
        if (payload) {
          state.PaIUnitError = payload;
        }
      });

    builder
      .addCase(removePaIUnitById.pending, (state) => {
        state.PaIUnitStatus = 'pending';
        state.PaIUnitError = null;
      })
      .addCase(removePaIUnitById.fulfilled, (state, { payload }) => {
        state.PaIUnitStatus = 'idle';
        state.PaIUnitsList = payload;
      })
      .addCase(removePaIUnitById.rejected, (state, { payload }) => {
        state.PaIUnitStatus = 'idle';
        if (payload) {
          state.PaIUnitError = payload;
        }
      });
  },
});

const PaIUnitReducer = PaIUnitSlice.reducer;

export { PaIUnitReducer };
