import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonList } from '@components/common';
import { Label, StatusBadge } from '@shared/UI';

import EmptyImage from '../../../../../../../assets/icons/empty-image.svg';

import type { AssetModel } from '@model/management/Asset';

import '../ViewCard.scss';

interface CardsProps {
  asset: AssetModel;

  checked: boolean;

  onChangeCheckbox: (value: boolean, asset: AssetModel) => void;
}

const {Cell} = CommonList;

export const Cards = memo((props: CardsProps) => {
  const {asset, checked, onChangeCheckbox} = props;

  const {t} = useTranslation();

  const headerElement = <StatusBadge color={asset.status.color}
                                     label={asset.status.name}/>;

  const firstLabel = asset.labels && asset.labels.length ? asset.labels[0] : null;

  const countOtherLabels = asset.labels && asset.labels.length ? asset.labels.length - 1 : 0;

  const cardImage = asset.nonCurrAssetFiles
    ? asset.nonCurrAssetFiles.find((image) => image.rowNumber === 1)
    : null;

  const getImage = () => {
    if (cardImage) {
      return cardImage;
    }
    if (asset.nonCurrAssetFiles && asset.nonCurrAssetFiles.length) {
      return asset.nonCurrAssetFiles[0];
    }
    return null;
  };

  const image = getImage();

  const handleChangeCheckbox = (value: boolean) => {
    onChangeCheckbox(value, asset);
  }

  return (
    <Cell
      checked={checked}
      onChangeCheckbox={handleChangeCheckbox}
      headerElement={headerElement}
      className="asset-grid-cell"
      itemKey={asset.nonCurrAssetId}
    >
      <div className="asset-wrapper-image">
        <img
          src={image ? image.filePath : EmptyImage}
          alt={image ? image.changedFileName : 'empty'}
        />
      </div>

      <div className="asset-wrapper-info">
        <div className="asset-info">
          <span className="asset-info-title">{t('titles.Barcode')}: </span>
          <span className="asset-info-description">{asset.barcode}</span>
        </div>
        <div className="asset-info">
          <span className="asset-info-title">{t('titles.Asset_Card')}: </span>
          <span
            className="asset-info-description">{asset.nonCurrAssetCard.name}</span>
        </div>
        <div className="asset-info">
          <span className="asset-info-title">{t('titles.Site')}: </span>
          <span className="asset-info-description">{asset.site.name}</span>
        </div>
        <div className="asset-info">
          <span className="asset-info-title">{t('titles.Assign_To')}: </span>
          <span className="asset-info-description">
            {asset.nonCurrAssetUsers && asset.nonCurrAssetUsers.length
              ? asset.nonCurrAssetUsers[0].user.firstName
              : ''}
          </span>
        </div>
      </div>
      <div className="asset-wrapper-labels">
        {firstLabel &&
          <Label color={firstLabel.labelColor}>{firstLabel.label}</Label>}
        {countOtherLabels > 0 && (
          <span className="count-labels">
            ... <span>{countOtherLabels}</span>
          </span>
        )}
      </div>
    </Cell>
  );
});
