import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { MaintenanceImages } from '@components/common/MaintenanceImages';
import { KindOfCheckStep } from '@constants/kindOfCheckStep';
import cl from 'classnames';

import type { WorkStepImageResultModel } from '@model/management/Maintenance/WorkStepImageResult.model';

import './HistoryStepResult.scss';

interface HistoryStepResultProps {
  stepName: string;
  resultText: string;
  resultDate: string;
  resultUser: string;
  mcList: string[];
  count: number | string;
  stepType: string;
  images: { file: WorkStepImageResultModel; id: string }[] | [];
}

const HistoryStepResult = (props: HistoryStepResultProps) => {
  const { stepName, resultText, resultDate, resultUser, mcList, count, stepType, images } = props;

  const stepStatusClass = !resultText.length
    ? 'history-result-step-fail'
    : 'history-result-step-success';

  const { t } = useTranslation();

  const renderContent = () => {
    if (stepType === KindOfCheckStep.MultiplyChoice && !resultText.length) {
      return (
        <ul>
          {mcList.map((itm) => (
            <li key={itm}>{itm}</li>
          ))}
        </ul>
      );
    }

    if (stepType === KindOfCheckStep.Inspection && !resultText.length) {
      return (
        <ul>
          <li>{t('titles.Yes')}</li>
          <li>{t('titles.No')}</li>
        </ul>
      );
    }

    if (stepType === KindOfCheckStep.Photo && !resultText.length) {
      return <MaintenanceImages hiddenUploader hiddenLabel images={images} />;
    }

    return <p>{resultText}</p>;
  };

  return (
    <div className={cl('history-result-step', stepStatusClass)}>
      {!resultText.length ? <WarningFilled /> : <CheckCircleFilled />}
      <div className="history-result-step-header">
        <div className="history-result-step-name-container">
          <span className="history-result-step-count">{count}.</span>
          <p className="history-result-step-name">
            {stepType === KindOfCheckStep.Photo ? t('titles.Take_Photo') : stepName}
          </p>
        </div>
      </div>
      <div className="history-result-step-content">{renderContent()}</div>
      {resultText.length ? (
        <div className="history-result-step-complete-info">
          <p>{resultUser}</p>
          <span>{resultDate}</span>
        </div>
      ) : null}
    </div>
  );
};

export { HistoryStepResult };
