import React from 'react';

import { PreviewFormExitType } from '@components/views/Previews/properties';
import { useOutletExitType } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { removeExitTypeById } from '@store/actions/properties/ExitType';

import type { CommonPreviewProps } from '@components/common';

function PreviewExitType() {
  const { entity } = useOutletExitType();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { exitTypeAccess } = useGetAccess();
  const onDelete = () => {
    dispatch(removeExitTypeById([entity.checkFactorId]))
      .unwrap()
      .then(() => {
        ApiNotifications.remove(entity.name);
        navigate(paths.exitType.default);
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !exitTypeAccess.edit,
    },
    deleteButtonProps: {
      disabled: !exitTypeAccess.delete,
    },
  };

  return <PreviewFormExitType entity={entity} onDelete={onDelete} header={headerProps} />;
}

export default PreviewExitType;
