import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormPart } from '@components/views/Forms/managment';
import { useOutletMaintenance } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import {
  addMaintenanceParts,
  getMaintenanceById,
  removeMaintenanceParts,
  updateMaintenanceParts,
} from '@store/actions/management/Maintenance';

import type { CommonFormProps } from '@components/common';
import type { MaintenancePartModel, MpModelDto } from '@model/management/Maintenance';
import type { UpdateMaintenancePartDto } from '@model/management/Maintenance/dto/update-maintenance-part.dto';

const EditMaintenanceParts = () => {
  const { entity, initialValuesParts } = useOutletMaintenance();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const header: CommonFormProps['header'] = {
    title: entity.title,
  };

  async function updateExistingParts(parts: MaintenancePartModel[]) {
    const dto: UpdateMaintenancePartDto[] = parts.map((part) => ({
      maintenancesPartsId: part.maintenancesPartsId,
      partsAndInventoriesId: part.partsAndInventoriesId,
      quantity: part.quantity as number,
      maintenanceId: part.maintenanceId,
    }));

    try {
      await Promise.all(dto.map((part) => dispatch(updateMaintenanceParts(part)).unwrap()));

      await dispatch(getMaintenanceById(entity.maintenanceId));
    } catch (e) {
      ApiNotifications.error(e);
    }
  }

  async function addNewParts(dto: MpModelDto[]) {
    const payload = {
      maintenanceId: entity.maintenanceId,
      mpmodel: dto,
    };
    try {
      await dispatch(addMaintenanceParts(payload)).unwrap();
    } catch (e) {
      ApiNotifications.error(e);
    }
  }

  async function removeParts(ids: number[]) {
    try {
      await dispatch(removeMaintenanceParts(ids));
    } catch (e) {
      ApiNotifications.error(e);
    }
  }

  async function onFinish(dto: MpModelDto[]) {
    // remove parts
    const needToRemove = entity.maintenancesParts.reduce((acc: number[], part) => {
      const isExist = dto
        .filter((dtoPart) => 'maintenancesPartsId' in dtoPart)
        .find((dtoPart) => dtoPart.maintenancesPartsId === part.maintenancesPartsId);

      if (!isExist) {
        acc.push(part.maintenancesPartsId);
      }

      return acc;
    }, []);

    if (needToRemove.length > 0) {
      await removeParts(needToRemove);
    }

    // Update existing parts if any property was changed
    const existingParts = entity.maintenancesParts.reduce((acc: MaintenancePartModel[], part) => {
      const exist = dto.find((dtoPart) => dtoPart.maintenancesPartsId === part.maintenancesPartsId);
      if (
        exist &&
        (exist.quantity !== part.quantity ||
          exist.partsAndInventoriesId !== part.partsAndInventoriesId)
      ) {
        acc.push({
          ...part,
          quantity: exist.quantity,
          partsAndInventoriesId: exist.partsAndInventoriesId,
        });
      }
      return acc;
    }, []);
    if (existingParts.length) {
      await updateExistingParts(existingParts);
    }

    // Create new parts
    const newParts = dto.filter((part) => {
      return !('maintenancesPartsId' in part);
    });
    if (newParts.length) {
      await addNewParts(newParts);
    }

    navigate(paths.maintenance.default);
  }

  return <FormPart onFinish={onFinish} header={header} initialValues={initialValuesParts} />;
};

export default EditMaintenanceParts;
