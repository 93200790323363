import React from 'react';

const PartAndInventoryIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 20C4.97715 20 0.5 15.5228 0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10C20.4939 15.5203 16.0203 19.9939 10.5 20ZM9.5 2.062C6.22604 2.47603 3.54227 4.85789 2.74231 8.05949C1.94234 11.2611 3.19036 14.6254 5.88479 16.5307C8.57922 18.436 12.1671 18.4913 14.919 16.67L14.819 16.741L14.913 16.676L14.972 16.635L15.036 16.59L15.052 16.579L15.061 16.572L9.933 11.442C9.65437 11.1585 9.49879 10.7765 9.5 10.379V2.062ZM12.329 11L16.556 15.227L16.563 15.219L16.568 15.213L16.558 15.224C17.5896 14.0335 18.244 12.5633 18.438 11H12.329ZM11.5 2.062V9H18.438C17.9815 5.37411 15.1259 2.51851 11.5 2.062Z"
      fill="white"
    />
  </svg>
);

export { PartAndInventoryIcon };
