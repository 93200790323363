import React from 'react';
import { useTranslation } from 'react-i18next';

import { PaIToolbarContext } from './PaIToolbarContext';

import type { PaIToolbarContextProps, PaIToolbarContextValues } from '../_utils';

export const PaIToolbarProvider = (props: PaIToolbarContextProps) => {
  const { children, selectedPaIs, clearSelected, vendorsList, sitesList, statusesList } = props;

  const { t } = useTranslation();

  const selectedLength = selectedPaIs.length;

  const selectedDescription = `${selectedLength} ${
    selectedLength > 1 ? t('pages_plural.PaIs') : t('pages_single.PaI')
  }`;

  const value: PaIToolbarContextValues = {
    clearSelected,
    selectedPaIs,
    selectedLength,
    selectedDescription,
    vendorsList,
    sitesList,
    statusesList,
  };

  return <PaIToolbarContext.Provider value={value}>{children}</PaIToolbarContext.Provider>;
};
