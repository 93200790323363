import { api } from '@services/Axios';

import type {
  CreatePaIPropertyDto,
  PaIPropertyModel,
  PaIPropertyTypeModel,
  UpdatePaIPropertyDto,
} from '@model/properties/PaI/Property';
import type { ApiResponse } from '@services/Axios';

const PaIPropertyEndpoint = '/PartsAndInventoriesCardProperties';

const PaIPropertyTypeEndpoint = 'PartsAndInventoriesPropertyTypes';

const PaIPropertyRepository = {
  async fetchAll() {
    const response: ApiResponse<PaIPropertyModel[]> = await api.get(
      `${PaIPropertyEndpoint}/GetPartsAndInventoriesCardPropertiesList`
    );

    return response.data;
  },

  async fetchPartAndInventoryTypes() {
    const response: ApiResponse<PaIPropertyTypeModel[]> = await api.get(
      `${PaIPropertyTypeEndpoint}/GetPartsAndInventoriesPropertyTypesList`
    );

    return response.data;
  },
  async add(dto: CreatePaIPropertyDto) {
    const response: ApiResponse<PaIPropertyModel> = await api.post(
      `${PaIPropertyEndpoint}/AddPartsAndInventoriesCardProperty`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<PaIPropertyModel> = await api.get(
      `${PaIPropertyEndpoint}/GetPartsAndInventoriesCardPropertyById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdatePaIPropertyDto) {
    const response: ApiResponse<PaIPropertyModel> = await api.post(
      `${PaIPropertyEndpoint}/UpdatePartsAndInventoriesCardProperties`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${PaIPropertyEndpoint}/RemoveByIdList`, {
      partsAndInventoriesCardPropertyIds: ids,
    });

    return response.data;
  },
};

export { PaIPropertyRepository };
