export const barcodeRegex = new RegExp(/^.*[a-zA-Z][0-9]+$/);

export const onlyNumberRegex = new RegExp(/[\D]+/g);

export const validateNumberRegex = new RegExp(/[^\d.]|\.(?=.*\.)/g);

export const checkSpace = new RegExp(/[ +]/gm);

export const phoneNumberPattern = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);

export const numberWithIndent = new RegExp(/\B(?=(\d{3})+(?!\d))/g);