import React from 'react';

import type { StepTypeItem } from '../_utils';

import styles from './StepType.module.scss';

export type StepTypeProps = StepTypeItem & {
  onClick?: (key: number) => void;
};

export const StepType = (props: StepTypeProps) => {
  const { label, typeKey, icon, onClick } = props;

  const handleTypeClick = () => {
    onClick?.(typeKey);
  };

  return (
    <div onClick={handleTypeClick} className={styles.stepType}>
      <span className={styles.stepTypeIcon}>{icon}</span>
      <span className={styles.stepTypeText}>{label}</span>
    </div>
  );
};
