import React from 'react';

import { FormCostCenter } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletCostCenter } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateCostCenter } from '@store/actions/properties/CostCenter';

import type { CreateCostCenterDto } from '@model/properties/CostCenter';

function CostCenterType() {
  const { initialValues, entity } = useOutletCostCenter();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { costCenterAccess } = useGetAccess();

  const onFinish = (dto: CreateCostCenterDto) => {
    dispatch(updateCostCenter({ ...dto, costCenterId: entity.costCenterId }))
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.CostCenter.name);
        navigate(paths.costCenter.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <CheckAccess hasAccess={costCenterAccess.edit} redirectTo={paths.costCenter.default}>
      <FormCostCenter
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default CostCenterType;
