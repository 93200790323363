import { api } from '@services/Axios';

import type {
  CreateVendorDto,
  FilterVendorDto,
  UpdateVendorDto,
  VendorModel,
} from '@model/properties/Vendor';
import type { ApiResponse } from '@services/Axios';

const VendorEndpoint = '/Vendor';

export const VendorRepository = {
  async fetchAll() {
    const response: ApiResponse<VendorModel[]> = await api.get(`${VendorEndpoint}/GetVendorList`);
    return response.data;
  },

  async fetchAllByFilter(dto: FilterVendorDto) {
    const response: ApiResponse<VendorModel[]> = await api.post(
      `${VendorEndpoint}/GetVendorsListByFilter`,
      dto
    );
    return response.data;
  },
  async add(dto: CreateVendorDto) {
    const response: ApiResponse<VendorModel> = await api.post(`${VendorEndpoint}/AddVendor`, dto);

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<VendorModel> = await api.get(
      `${VendorEndpoint}/GetVendorById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdateVendorDto) {
    const response: ApiResponse<VendorModel> = await api.post(
      `${VendorEndpoint}/UpdateVendor`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${VendorEndpoint}/RemoveByIdList`, {
      partnerIds: ids,
    });

    return response.data;
  },
};
