import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormAssetStatus } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletAssetStatus } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateAsAtStatus } from '@store/actions/properties/AsAt/Status';

import type { CreateAsAtStatusDto } from '@model/properties/AsAt/Status';

function EditAssetStatus() {
  const { initialValues, entity } = useOutletAssetStatus();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { assetAttrAccess } = useGetAccess();

  const onFinish = (dto: CreateAsAtStatusDto) => {
    dispatch(
      updateAsAtStatus({
        ...dto,
        nonCurrAssetCardStatusId: entity.nonCurrAssetStatusId,
      })
    )
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.AsAtStatus.name);
        navigate(paths.assetAttribute.tabs.status.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <CheckAccess hasAccess={assetAttrAccess.edit} redirectTo={paths.assetAttribute.tabs.status.default}>
      <FormAssetStatus
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditAssetStatus;
