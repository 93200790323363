import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getAsAtStatusById } from '@store/actions/properties/AsAt/Status';
import { selectAsAtStatus } from '@store/selectors/properties/AsAt/Status';

import type { IOutletAssetStatus } from '@constants/OutletContextEntityPages';
import type { CreateAsAtStatusDto } from '@model/properties/AsAt/Status';

function AssetStatus() {
  const assetStatus = useAppSelector(selectAsAtStatus);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !assetStatus || assetStatus.nonCurrAssetStatusId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getAsAtStatusById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateAsAtStatusDto = {
    nonCurrAssetStatusCode: assetStatus.nonCurrAssetStatusCode,
    name: assetStatus.name,
    color: assetStatus.color,
  };

  return <Outlet context={{ initialValues, entity: assetStatus } as IOutletAssetStatus} />;
}

export default AssetStatus;
