import React from 'react';

const UserIcon = () => (
  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.78047 0.607422C4.29519 0.607422 2.28047 2.59727 2.28047 5.05187C2.28047 7.50646 4.29519 9.49631 6.78047 9.49631C9.26575 9.49631 11.2805 7.50646 11.2805 5.05187C11.2805 2.59727 9.26575 0.607422 6.78047 0.607422ZM4.08047 5.05187C4.08047 3.57911 5.2893 2.3852 6.78047 2.3852C8.27164 2.3852 9.48047 3.57911 9.48047 5.05187C9.48047 6.52463 8.27164 7.71853 6.78047 7.71853C5.2893 7.71853 4.08047 6.52463 4.08047 5.05187Z"
      fill="white"
    />
    <path
      d="M13.898 5.24589C13.6448 5.11839 13.3647 5.05192 13.0805 5.05192V3.27414C13.6489 3.27414 14.2093 3.40703 14.7157 3.66204C14.7715 3.69013 14.8264 3.71961 14.8805 3.75044C15.3173 3.99954 15.6969 4.33692 15.994 4.74125C16.3279 5.19562 16.5481 5.72162 16.6366 6.27619C16.7251 6.83077 16.6793 7.39816 16.5031 7.93191C16.3269 8.46567 16.0252 8.95063 15.6227 9.34709C15.2202 9.74356 14.7284 10.0403 14.1875 10.2129C13.7062 10.3665 13.1982 10.4181 12.6967 10.3649C12.6346 10.3584 12.5726 10.3502 12.5108 10.3404C11.9499 10.2516 11.4182 10.033 10.9593 9.70244L10.9583 9.70176L12.0194 8.2657C12.249 8.43117 12.515 8.54055 12.7956 8.58497C13.0763 8.62939 13.3636 8.60754 13.634 8.52122C13.9044 8.4349 14.1503 8.28656 14.3516 8.08833C14.5528 7.89011 14.7036 7.64763 14.7917 7.38076C14.8798 7.11389 14.9027 6.83021 14.8585 6.55293C14.8142 6.27565 14.7041 6.01266 14.5372 5.78548C14.3703 5.5583 14.1512 5.37339 13.898 5.24589Z"
      fill="white"
    />
    <path
      d="M16.6788 16.6074C16.6788 16.1407 16.5857 15.6786 16.4049 15.2474C16.224 14.8162 15.959 14.4245 15.6248 14.0945C15.2907 13.7644 14.894 13.5027 14.4575 13.3241C14.0209 13.1455 13.553 13.0536 13.0805 13.0536V11.2741C13.6943 11.2741 14.303 11.3775 14.8805 11.5791C14.9701 11.6104 15.0589 11.6441 15.147 11.6801C15.8021 11.9481 16.3974 12.3409 16.8988 12.8362C17.4003 13.3314 17.798 13.9194 18.0694 14.5664C18.1059 14.6534 18.14 14.7411 18.1716 14.8296C18.3758 15.4 18.4805 16.0011 18.4805 16.6074H16.6788Z"
      fill="white"
    />
    <path
      d="M13.0805 16.6074H11.2805C11.2805 14.1528 9.26575 12.163 6.78047 12.163C4.29519 12.163 2.28047 14.1528 2.28047 16.6074H0.480469C0.480469 13.171 3.30107 10.3852 6.78047 10.3852C10.2599 10.3852 13.0805 13.171 13.0805 16.6074Z"
      fill="white"
    />
  </svg>
);

export { UserIcon };
