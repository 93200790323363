import React, { useState } from 'react';

import { useModalState } from '@hooks';
import { CarouselModal, ImageContainer } from '@shared/UI';
import { createUrlObject } from '@shared/utils/functions';
import { v4 as uuidv4 } from 'uuid';

import { useCheckStepContext } from '../../../_utils';

import type { SwiperProps } from 'swiper/react/swiper-react';
import type { CheckStepItemImage } from '../../../_utils';

type ImagesProps = {};

const createImageUrls = (images: CheckStepItemImage[]) => {
  return images.map((image) => {
    if (typeof image.file === 'string') {
      return image.file;
    }
    return createUrlObject(image.file);
  });
};

export const Images = (props: ImagesProps) => {
  const {} = props;

  const {step, actions} = useCheckStepContext();

  const [initialSlide, setInitialSlide] = useState(0);

  const sliderModal = useModalState();

  const handleImageClick = (index: number) => {
    setInitialSlide(index);

    sliderModal.actions.onOpen();
  };

  const handleImageRemove = (uuid: string) => {
    actions?.onRemoveCheckStepImage?.(step, uuid)
  }

  const renderImages = step.imagesList.map((img, idx) => {
    return (
      <ImageContainer
        key={img.uuid}
        src={typeof img.file === 'string' ? img.file : createUrlObject(img.file)}
        onClickImage={() => handleImageClick(idx)}
        onClickDelete={() => handleImageRemove(img.uuid)}
        canDelete
      />
    );
  });

  const renderPlugs = Array(5 - step.imagesList.length)
    .fill(null)
    .map(() => {
      return <ImageContainer key={uuidv4()}/>;
    });

  const swiperConfig: SwiperProps = {
    initialSlide,
  };

  return (
    <>
      {renderImages}
      {renderPlugs}
      <CarouselModal
        images={createImageUrls(step.imagesList)}
        open={sliderModal.isOpen}
        onCancel={sliderModal.actions.onClose}
        swiperConfig={swiperConfig}
      />
    </>
  );
};
