import React from 'react';

const TaskIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.1429 17.2381V6.7619C19.1429 5.70993 18.2901 4.85714 17.2381 4.85714H6.76414V3.90476H20.0975V17.2381H19.1429ZM19.1429 19.1429H20.0975C21.1494 19.1429 22.0022 18.2901 22.0022 17.2381V3.90476C22.0022 2.85279 21.1494 2 20.0975 2H6.76414C5.71217 2 4.85938 2.85279 4.85938 3.90476V4.85714H3.90476C2.85279 4.85714 2 5.70993 2 6.7619V20.0952C2 21.1472 2.85279 22 3.90476 22H17.2381C18.2901 22 19.1429 21.1472 19.1429 20.0952V19.1429ZM6.76414 6.7619H4.85938H3.90476V20.0952H17.2381V6.7619H6.76414Z" fill="white"/>
    <path d="M11.15 10C11.15 9.64101 10.859 9.35 10.5 9.35C10.141 9.35 9.85 9.64101 9.85 10H11.15ZM9.85 17C9.85 17.359 10.141 17.65 10.5 17.65C10.859 17.65 11.15 17.359 11.15 17H9.85ZM7 12.85C6.64101 12.85 6.35 13.141 6.35 13.5C6.35 13.859 6.64101 14.15 7 14.15V12.85ZM14 14.15C14.359 14.15 14.65 13.859 14.65 13.5C14.65 13.141 14.359 12.85 14 12.85V14.15ZM9.85 10V17H11.15V10H9.85ZM7 14.15H14V12.85H7V14.15Z" fill="white"/>
  </svg>
);

export { TaskIcon };
