import React, { useCallback, useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppDispatch, useAppSelector, useGetAccess } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { Select } from '@shared/UI';
import { checkFilterValues, compare } from '@shared/utils/functions';
import { getVendors, getVendorsByFilter } from '@store/actions/properties/Vendor';
import {
  selectVendorFilters,
  selectVendorsFilteredList,
  selectVendorsList,
  selectVendorStatus,
} from '@store/selectors/properties/Vendor';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { FilterVendorDto, VendorModel } from '@model/properties/Vendor';

const columns: ColumnsTableType<VendorModel> = [
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
  {
    title: <Translation>{(t) => t('titles.TXN')}</Translation>,
    dataIndex: 'taxNumber',
    key: 'taxNumber',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Phone')}</Translation>,
    dataIndex: 'phone',
    key: 'phone',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.City')}</Translation>,
    dataIndex: ['city', 'name'],
    key: 'city.name',
    ellipsis: true,
    sorter: (a, b) => compare(a.city.name, b.city.name),
  },
];

const csvHeaders = [
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Tax Office',
    key: 'taxOffice',
  },
  {
    label: 'Tax Number',
    key: 'taxNumber',
  },
  {
    label: 'Phone',
    key: 'phone',
  },
  {
    label: 'City',
    key: 'city',
  },
  {
    label: 'Vendor Code',
    key: 'vendorCode',
  },
  {
    label: 'Address',
    key: 'address',
  },
  {
    label: 'Email',
    key: 'email',
  },
];

const Vendors = () => {
  const {t} = useTranslation();

  const dispatch = useAppDispatch();

  const {vendorAccess} = useGetAccess();

  const vendors = useAppSelector(selectVendorsList);
  const filteredListVendor = useAppSelector(selectVendorsFilteredList);
  const filters = useAppSelector(selectVendorFilters);
  const loadingStatus = useAppSelector(selectVendorStatus);

  const csvData = useMemo(
    () =>
      vendors.map((vendor) => ({
        name: vendor.name,
        taxNumber: vendor.taxNumber,
        phone: vendor.phone,
        city: vendor.city.name,
        taxOffice: vendor.taxOffice,
        vendorCode: vendor.partnerCode,
        address: vendor.address,
        email: vendor.email,
      })),
    [vendors]
  );

  const emailFilterOptions = useMemo(
    () =>
      vendors.reduce((acc: VendorModel[], vendor) => {
        const {email} = vendor;
        if (email && email !== '' && !acc.some((value) => value.email === email)) {
          acc.push(vendor);
        }
        return acc;
      }, []),
    [vendors]
  );

  const cityFilterOptions = useMemo(
    () =>
      vendors.map((vendor) => ({
        cityName: vendor.city.name,
        cityId: vendor.city.cityId,
      })),
    [vendors]
  );

  const uniqueCityFilterOptions = useMemo(
    () =>
      Array.from(
        new Map(cityFilterOptions.map((item) => [item.cityName, item])),
        ([, value]) => value
      ),
    [cityFilterOptions]
  );

  const dataSource = useMemo(
    () => (Boolean(filteredListVendor.length) ? filteredListVendor : vendors),
    [filteredListVendor, vendors]
  );


  const getDefaultList = useCallback(async () => {
    try {
      await dispatch(getVendors()).unwrap();
    } catch (e) {
      ApiNotifications.error(e);
    }
  }, []);

  const onSubmitFilter = async (values: FilterVendorDto) => {
    const checkedValues = checkFilterValues(values);

    if (!checkedValues) {
      await getDefaultList();
    } else {
      try {
        await dispatch(getVendorsByFilter(values)).unwrap();
      } catch (e) {
        ApiNotifications.error(e);
      }
    }
  };

  const filterProps = {
    items: [
      {
        label: t('titles.Name'),
        name: 'partnerNamesArray',
        item: (
          <Select
            options={vendors}
            maxTagCount={4}
            mode="tags"
            fieldNames={{label: 'name', value: 'name'}}
          />
        ),
      },
      {
        label: t('titles.Code'),
        name: 'partnerCodesArray',
        item: (
          <Select
            options={vendors}
            maxTagCount={4}
            mode="tags"
            fieldNames={{label: 'partnerCode', value: 'partnerCode'}}
          />
        ),
      },
      {
        label: t('titles.Email'),
        name: 'partnerEmailsArray',
        item: (
          <Select
            options={emailFilterOptions}
            maxTagCount={4}
            mode="tags"
            fieldNames={{label: 'email', value: 'email'}}
          />
        ),
      },
      {
        label: t('titles.City'),
        name: 'partnerCityIdsArray',
        item: (
          <Select
            options={uniqueCityFilterOptions}
            maxTagCount={4}
            mode="tags"
            fieldNames={{label: 'cityName', value: 'cityId'}}
          />
        ),
      },
    ],
    initialValuesForm: filters,
    applyAction: onSubmitFilter,
    resetAction: async () => {
      if (checkFilterValues(filters)) {
        await getDefaultList();
      }
    },
  }

  return (
    <BasicTablePage
      table={{
        loading: loadingStatus === 'pending',
        dataSource: dataSource,
        columns: columns,
        rowKey: (record) => record.partnerId,
        dataSourceName: t('pages_plural.Vendors'),
      }}
      header={{
        csvConfig: {
          data: csvData,
          headers: csvHeaders,
          fileName: 'vendors.csv',
        },
        titleRedirectButton: t('titles.New_Item', {name: t('pages_single.Vendor')}),
        filterProps,
        newButtonProps: {
          disabled: !vendorAccess.create
        }
      }}
    />
  );
};

export {Vendors};
