import { v4 as uuidv4 } from 'uuid';

import { mapChecklistOptionsToCheckStepOptions } from './mapChecklistOptionsToCheckStepOptions';

import type { MaAtCheckStepModel } from '@model/properties/MaAt/Checklist';
import type { ChecklistStepTypeKeys } from '../../checkStepTypes';
import type { CheckStepItem } from '../../types';

export const mapChecklistsToCheckSteps = (checklists: MaAtCheckStepModel[]) => {
  return checklists.map((checklist): CheckStepItem => {
    const { checkSteps, checkStepsId } = checklist;
    return {
      stepKey: checkSteps.stepTypeId as ChecklistStepTypeKeys,
      hasError: false,
      isNew: false,
      isUpdate: false,
      isRemoved: false,
      title: checkSteps.stepName,
      rowId: checkSteps.rowId,
      value: '',
      uuid: uuidv4(),
      imagesList: [],
      optionsList: mapChecklistOptionsToCheckStepOptions(checkSteps.multipleChoiceLists),
      externalId: checkStepsId,
      isCompleted: false,
      isActive: false,
    };
  });
};
