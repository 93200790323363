import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { Input, TextArea } from '@shared/UI';
import { selectExitTypeStatus } from '@store/selectors/properties/ExitType';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateExitTypeDto } from '@model/properties/ExitType';

const { CaseField, Item, useForm } = CommonForm;

function FormExitType(props: CommonFormProps<CreateExitTypeDto>) {
  const [form] = useForm<CreateExitTypeDto>();

  const {t} = useTranslation();

  const exitTypeStatus = useAppSelector(selectExitTypeStatus);

  return (
    <CommonForm form={form} loading={exitTypeStatus === 'pending'} {...props}>
      <CaseField>
        <Item
          label={t('titles.Code')}
          name="exitTypeCode"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')}/>
        </Item>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')}/>
        </Item>
        <Item label={t('titles.Description')} name="description">
          <TextArea placeholder={t('titles.Enter_Descr')}/>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export {FormExitType};
