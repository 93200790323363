import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Arrow } from '@icon/icon-components';
import cl from 'classnames';

import './BackLink.scss';

interface BackLinkProps {
  path: string;
  isOutsidePreviewBack?: boolean;
}

const BackLink = (props: BackLinkProps) => {
  const { path, isOutsidePreviewBack } = props;

  const { t } = useTranslation();

  return (
    <Link
      to={path}
      className={cl('preview__back-icon', {
        ['preview__back-icon--outside']: isOutsidePreviewBack,
      })}
    >
      <span className="icon-back">
        <Arrow />
      </span>
      <span className="text">{t('titles.Back')}</span>
    </Link>
  )
}

export { BackLink };