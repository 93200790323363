import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import cl from 'classnames';

import type { DragEnd } from '@components/common/BasicTablePage/BasicTablePage';

import './DraggableRow.scss';

export interface DraggableRowProps<T = Record<string, any>> {
  dropAction?: (data: DragEnd<T>) => void;
}

interface DefaultDraggableRowProps<T = Record<string, any>>
  extends React.HTMLAttributes<HTMLTableRowElement>,
    DraggableRowProps<T> {
  record: T;
  recordKey: React.Key;
}

export const dragType = 'DraggableBodyRow';

function DraggableRow<T extends Record<string, any>>(props: DefaultDraggableRowProps<T>) {
  const { className, record, recordKey, dropAction, ...rest } = props;
  const ref = useRef<HTMLTableRowElement>(null);

  /* ------------------------------ Drop Function ----------------------------- */
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: dragType,
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
    drop: (items: Omit<DragEnd<T>, 'dropId' | 'dropRecord'>): DragEnd<T> => {
      return { ...items, dropRecord: record, dropId: recordKey as number };
    },

    canDrop: (item) => {
      return item.draggingRecordId !== recordKey;
    },
  });
  /* ------------------------------ Drag Function ----------------------------- */
  const [{ isDragging }, drag] = useDrag({
    type: dragType,
    item: (): Omit<DragEnd<T>, 'dropId' | 'dropRecord'> => {
      return {
        draggingRecords: record,
        draggingRecordId: recordKey as number,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),

    end: (_, monitor) => {
      const resultDrop: (DragEnd<T> & { dropEffect: string }) | null = monitor.getDropResult();
      if (resultDrop) {
        const { dropEffect, ...result } = resultDrop;
        dropAction && dropAction(result);
      }
    },
  });

  /* --------------------------- Drag&Drop classes --------------------------- */
  const isFocusRecord = canDrop && isOver;

  const defaultClasses = cl(className, 'draggable-row', {
    ['draggable-row--focused']: isFocusRecord,
    ['draggable-row--dragging']: isDragging,
  });

  drop(drag(ref));

  return <tr ref={ref} className={defaultClasses} {...rest}></tr>;
}

export default DraggableRow;
