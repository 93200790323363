import React, { memo } from 'react';

import { DataFilter } from '@components/common/DataFilter';
import { useAppDispatch } from '@hooks';
import { getAssets, resetAssetFilters } from '@store/actions/management/Asset';

import { getFilterItems } from '../../_utils';
import { useAssetListContext } from '../../AssetListProvider';

import type { FilterAssetDto } from '@model/management/Asset';

export type FilterProps = {
  disabledFilter?: boolean;
};

type FilterValue = Omit<
  FilterAssetDto,
  | 'page'
  | 'perPage'
  | 'labels'
  | 'sortBy'
  | 'showSuspendedAssets'
  | 'showGuaranteedAssets'
  | 'showLostAssetStatusedRecords'
>;

export const Filter = memo((props: FilterProps) => {
  const { disabledFilter = false } = props;

  const dispatch = useAppDispatch();

  const data = useAssetListContext();

  const filterItems = getFilterItems({
    allAssetRecords: data.allAssetRecord,
    assetCardsList: data.listAssetNames,
    assetCategoriesList: data.listAssetCategory,
    assetBrandsList: data.listAssetBrand,
    assetModelsList: data.listAssetModel,
    assetStatusesList: data.listAssetStatuses,
    departmentsList: data.listDepartments,
    sitesList: data.listSites,
    usersList: data.listUsers,
  });

  const initialValues: FilterValue = {
    barcodes: data.barcodes,
    brandsIds: data.brandsIds,
    departmentsIds: data.departmentsIds,
    nonCurrAssetCardCategoriesIds: data.nonCurrAssetCardCategoriesIds,
    nonCurrAssetCardModelsIds: data.nonCurrAssetCardModelsIds,
    nonCurrAssetCardsIds: data.nonCurrAssetCardsIds,
    nonCurrAssetCardPropertyValues: data.nonCurrAssetCardPropertyValues,
    serialNumbers: data.serialNumbers,
    usersIds: data.usersIds,
    sitesIds: data.sitesIds,
    statusesIds: data.statusesIds,
  };

  const handleApply = (dto: FilterValue) => {
    dispatch(getAssets(dto));
  };

  const handleReset = () => {
    dispatch(resetAssetFilters());
    dispatch(getAssets());
  };

  return (
    <DataFilter
      items={filterItems}
      initialValuesForm={initialValues}
      applyAction={handleApply}
      resetAction={handleReset}
      isFiltered={data.hasFilterValue}
      isDisabled={disabledFilter}
    />
  );
});
