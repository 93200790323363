import React, { useCallback, useEffect, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { SimpleTable } from '@components/common';
import { AdditionalHeader } from '@components/views/Calendar/MaintenanceCalendar/AdditionalHeader';
import { HistoryStepResult } from '@components/views/Previews/management/Maintenance/History/HistoryStepResults';
import { defaultFormatDate } from '@constants/Date';
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useGetMaintenanceSchedules,
  useModalState,
  useUpdateEffect,
} from '@hooks';
import { BaseModal, Calendar, PageHeader } from '@shared/UI';
import { getMaintenanceHistoryList } from '@store/actions/management/Maintenance';
import {
  getMaintenanceResultByHistory,
  getMaintenanceSchedulesList,
  resetSchedulesList,
} from '@store/actions/management/MaintenanceResult';
import {
  selectMaintenanceHistoryList,
  selectMaintenanceStatus,
} from '@store/selectors/management/Maintenance';
import {
  selectMaintenancByHistoryResult,
  selectMaintenanceResultStatus,
} from '@store/selectors/management/MaintenanceResult';
import { format, getYear, isSameMonth, parseISO, startOfWeek } from 'date-fns';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { WeekCell } from './WeekCell';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type {
  MaintenanceHistoryModel,
  QueryMaintenanceScheduleDto,
} from '@model/management/Maintenance';

import './MaintenanceCalendar.scss';

const columns: ColumnsTableType<MaintenanceHistoryModel> = [
  {
    title: <Translation>{(t) => t('titles.Barcode')}</Translation>,
    dataIndex: 'barcode',
    key: 'barcode',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Assign_To')}</Translation>,
    dataIndex: 'assignToName',
    key: 'assignToName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Additional_Assign_To')}</Translation>,
    dataIndex: 'assigneeNames',
    key: 'assigneeNames',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Maintenance_Date')}</Translation>,
    ellipsis: true,
    render: (_, { maintenanceDate }) => <>{moment(maintenanceDate).format(defaultFormatDate)}</>,
  },
  {
    title: <Translation>{(t) => t('titles.Execute_Hour')}</Translation>,
    dataIndex: 'totalMaintenanceDurationInHours',
    key: 'totalMaintenanceDurationInHours',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: 'siteName',
    key: 'siteName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Category')}</Translation>,
    dataIndex: 'categoryName',
    key: 'categoryName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Status')}</Translation>,
    ellipsis: true,
    render: (_, obj) => {
      const doneWorkStep = `${(obj.maintenanceResultCount / obj.workStepCount) * 100}%`;
      const statusColor =
        obj.maintenanceExecuteStatusId === 1
          ? '#DF0909'
          : obj.maintenanceExecuteStatusId === 2
          ? '#032F5C'
          : '#0C7A02';

      return (
        <div className="history-status">
          <span>{`${obj.maintenanceResultCount}/${obj.workStepCount}`}</span>
          <span>
            <span style={{ width: doneWorkStep, backgroundColor: statusColor }} />
          </span>
        </div>
      );
    },
  },
];

const MaintenanceCalendar = () => {
  const [currentDate, setCurrentDate] = useState<QueryMaintenanceScheduleDto>({
    year: getYear(new Date()),
    month: parseInt(format(new Date(), 'M')),
  });

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // const navigate = useAppNavigate();

  const historyList = useAppSelector(selectMaintenanceHistoryList);
  const historyResults = useAppSelector(selectMaintenancByHistoryResult);
  const loadingStatus = useAppSelector(selectMaintenanceStatus);
  const resultLoadingStatus = useAppSelector(selectMaintenanceResultStatus);

  const { schedules, loading } = useGetMaintenanceSchedules(currentDate);

  const [schedulesData, setSchedulesData] = useState(schedules);

  const [searchValue, setSearchValue] = useState('');

  const debouncedSearch = useDebounce(searchValue, 500);

  const historyModal = useModalState();
  const maintenanceModal = useModalState();

  useEffect(() => {
    // get the schedules by current month
    dispatch(getMaintenanceSchedulesList(currentDate));
    const date = new Date(currentDate.year, currentDate.month - 1);
    // if a month of the start day is different from the current month,
    // then we get the previous month from API
    const startDay = startOfWeek(date);
    if (!isSameMonth(date, startDay)) {
      dispatch(
        getMaintenanceSchedulesList({
          year: getYear(startDay),
          month: parseInt(format(startDay, 'M')),
        })
      );
    }

    return () => {
      dispatch(resetSchedulesList());
    };
  }, []);

  useEffect(() => {
    setSchedulesData(schedules);
  }, [schedules]);

  useUpdateEffect(() => {
    handleDataBySearch();
  }, [debouncedSearch]);

  const additionalHeader = useCallback(
    () => <AdditionalHeader onChangeSearch={setSearchValue} searchValue={searchValue} />,
    [searchValue, setSearchValue]
  );

  function handleDataBySearch() {
    const foundSchedules = schedules.filter((schedule) =>
      schedule.title.toLowerCase().includes(debouncedSearch.toLowerCase())
    );

    if (debouncedSearch.length === 0) {
      setSchedulesData(() => schedules);
    }

    setSchedulesData(() => foundSchedules);
  }

  async function onClickCell(record: typeof schedules[number]) {
    dispatch(
      getMaintenanceHistoryList({
        page: 1,
        perPage: 20,
        maintenanceId: record.maintenanceId,
      })
    )
      .unwrap()
      .then(() =>
        !record.nonCurrAssetId ? historyModal.actions.onOpen() : maintenanceModal.actions.onOpen()
      );

    dispatch(
      getMaintenanceResultByHistory({
        maintenanceId: record.maintenanceId,
        scheduleId: record.maintenanceSchedulesId,
        nonCurrAssetId: record.nonCurrAssetId || 0,
      })
    );

    // const scheduleId = CryptographyService.encrypt(`${record.maintenanceSchedulesId}`);
    // const maintenanceId = CryptographyService.encrypt(`${record.maintenanceId}`);

    // try {
    //   const res = await dispatch(
    //     getMaintenanceResultByScheduleIdVer2({
    //       scheduleId: record.maintenanceSchedulesId,
    //       maintenanceId: record.maintenanceId,
    //     })
    //   ).unwrap();
    //   if (res.result) {
    //     navigate(`${paths.maintenance.resultDefault}/${scheduleId}/${maintenanceId}`);
    //   }
    // } catch {
    //   try {
    //     const response = await dispatch(getMaintenanceById(record.maintenanceId)).unwrap();
    //     ApiNotifications.info(
    //       <span>
    //         <Trans i18nKey="errors.There_Arent_Work_Steps">
    //           There aren't work steps in the
    //           <a
    //             onClick={() => {
    //               onToEditWorkSteps(record.maintenanceId);
    //               Notifications.close(maintenanceId);
    //             }}
    //             style={{ fontWeight: 600, display: 'block' }}
    //           >
    //             {{ name: response.title }}
    //           </a>
    //         </Trans>
    //       </span>,
    //       {
    //         key: maintenanceId,
    //       }
    //     );
    //   } catch (err) {
    //     ApiNotifications.error(err);
    //   }
    // }
  }

  const onChangeDate = (date: Date) => {
    const newCurrentDate = { year: getYear(date), month: parseInt(format(date, 'M')) };

    if (!isSameMonth(date, new Date(currentDate.year, currentDate.month - 1))) {
      setCurrentDate(newCurrentDate);
      dispatch(getMaintenanceSchedulesList(newCurrentDate));
    }
    setSearchValue('');
  };

  const onChangeMode = () => {
    setSearchValue('');
  };

  const resultTotalInfo = historyResults?.result.topdetails;

  const popupHeaderInfo = [
    {
      title: t('titles.Date'),
      value: moment(resultTotalInfo?.lastExecuteDate).format(defaultFormatDate),
    },
    {
      title: t('titles.Priority'),
      value: resultTotalInfo?.priorityName,
    },
    {
      title: t('titles.Assign_To'),
      value: resultTotalInfo?.assignToName,
    },
    {
      title: t('titles.Site'),
      value: resultTotalInfo?.siteName,
    },
  ];

  const popupHeaderInfo2 = [
    {
      title: t('titles.Date'),
      value: moment(resultTotalInfo?.lastExecuteDate).format(defaultFormatDate),
    },
    {
      title: t('titles.Assign_To'),
      value: resultTotalInfo?.assignToName,
    },
  ];

  const values =
    historyResults &&
    historyResults.result.getMaintenanceResultsBySchedulesIdVer3.map(({ workSteps }, count) => {
      const {
        stepName,
        multipleChoiceListForWorkSteps,
        maintenancesResults,
        stepTypes,
        maintenanceImageListResults,
      } = workSteps;

      const mcList = multipleChoiceListForWorkSteps.map((multi) => {
        const resultText =
          workSteps.maintenanceMultipleChoiceListResults[0] &&
          workSteps.maintenanceMultipleChoiceListResults[0].resultText === multi.optionText
            ? workSteps.maintenanceMultipleChoiceListResults[0].resultText
            : multi.optionText;
        return resultText;
      });

      const images = maintenanceImageListResults?.length
        ? maintenanceImageListResults
            .map((file) => ({
              file,
              id: uuidv4(),
            }))
            .sort((a, b) => {
              if (!a.file.maintenanceImageListResultsId || !b.file.maintenanceImageListResultsId) {
                return 0;
              }
              return a.file.maintenanceImageListResultsId - b.file.maintenanceImageListResultsId;
            })
        : [];

      const resultDate = maintenancesResults[0]
        ? moment(maintenancesResults[0].createdDate).format(defaultFormatDate)
        : '';
      const resultText = maintenancesResults[0] ? maintenancesResults[0].resultText : '';
      const resultUser = resultTotalInfo ? resultTotalInfo?.assignToName : '';

      return {
        stepName,
        images,
        mcList,
        stepType: stepTypes.stepTypeName,
        resultText,
        resultDate,
        resultUser,
        count: count + 1,
      };
    });

  const statusColor =
    resultTotalInfo?.executeStatusName === 'Open'
      ? '#DDEEFF'
      : resultTotalInfo?.executeStatusName === 'Complete'
      ? '#FBE3D1'
      : '#D6FFAD';

  // async function onToEditWorkSteps(maintenanceId: number) {
  //   const hashId = CryptographyService.encrypt(`${maintenanceId}`);
  //   const workStepsPath = paths.maintenance.tabs.workOrders.default;
  //   const toEditWorkSteps = workStepsPath.replace(':id', hashId);
  //   try {
  //     await dispatch(getMaintenanceById(maintenanceId));
  //     navigate(toEditWorkSteps);
  //   } catch (err) {
  //     ApiNotifications.error(err);
  //   }
  // }

  return (
    <div className="maintenance-calendar" style={{ height: '100%' }}>
      <Calendar
        addHeaderElements={additionalHeader}
        onChangeDate={onChangeDate}
        onChangeMode={onChangeMode}
        data={schedulesData}
        getCellKey={(record) => record.maintenanceSchedulesId}
        monthCellConfig={(record) => ({
          date: parseISO(record.maintenanceDate),
          label: record.title,
          id: record.maintenanceSchedulesId,
          badge: record.nonCurrAssetId,
        })}
        weekCellConfig={(record) => ({
          date: parseISO(record.maintenanceDate),
          id: record.maintenanceSchedulesId,
        })}
        weekCellContentRender={(record) => <WeekCell record={record} />}
        loading={loading}
        onClickCell={onClickCell}
      />
      <BaseModal
        className="history-modal"
        open={historyModal.isOpen}
        onCancel={historyModal.actions.onClose}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        width="120rem"
        centered
      >
        <PageHeader
          className="history-popup-header"
          title={resultTotalInfo?.title}
          extra={
            <div className="history-popup-info">
              {popupHeaderInfo2.map((info, idx) => (
                <div key={idx}>
                  <p className="info-title">{info.title}:</p>
                  <p className="info-desc">{info.value}</p>
                </div>
              ))}
            </div>
          }
        />
        <div className="history-modal-content">
          <SimpleTable
            className="history-page-table"
            dataSource={historyList}
            loading={loadingStatus === 'pending' || resultLoadingStatus === 'pending'}
            rowKey={Math.random}
            columns={columns}
            pagination={{ defaultPageSize: 50 }}
          />
        </div>
      </BaseModal>
      <BaseModal
        className="history-modal"
        open={maintenanceModal.isOpen}
        onCancel={maintenanceModal.actions.onClose}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        width="120rem"
        centered
      >
        <PageHeader
          className="history-popup-header"
          title={resultTotalInfo?.title}
          subTitle={
            <span style={{ backgroundColor: statusColor }}>
              {resultTotalInfo?.executeStatusName.toUpperCase()}
            </span>
          }
          footer={`Steps: ${resultTotalInfo?.maintenanceResultCount}/${resultTotalInfo?.workStepCount}`}
          extra={
            <div className="history-popup-info">
              {popupHeaderInfo.map((info, idx) => (
                <div key={idx}>
                  <p className="info-title">{info.title}:</p>
                  <p className="info-desc">{info.value}</p>
                </div>
              ))}
            </div>
          }
        />
        <div className="history-modal-content">
          {values?.map((step) => (
            <HistoryStepResult {...step} key={step.count} />
          ))}
        </div>
      </BaseModal>
    </div>
  );
};

export { MaintenanceCalendar };
