import React from 'react';

import { FormCountPlan } from '@components/views/Forms/managment/CountPlan';
import { CheckAccess } from '@components/wrappers';
import { updateNotificationSuccess } from '@constants/notificationMessages';
import { useOutletCountPlan } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { updateCountPlan } from '@store/actions/management/CountPlan';

import type { CreateCountPlanDto } from '@model/management/CountPlan';

const Edit = () => {
  const { initialValues, entity } = useOutletCountPlan();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { countPlanAccess } = useGetAccess();

  const onFinish = (dto: CreateCountPlanDto) => {
    dispatch(updateCountPlan({ ...dto, prdCountPlanId: entity.prdCountPlanId }))
      .unwrap()
      .then(() => {
        notificationController.success({
          description: updateNotificationSuccess(entity.taskName),
        });
        navigate(paths.countPlan.default);
      })
      .catch((error) => {
        if (typeof error === 'string') {
          notificationController.error({
            description: error,
          });
        }
      });
  };

  return (
    <CheckAccess hasAccess={countPlanAccess.edit} redirectTo={paths.countPlan.default}>
      <FormCountPlan initialValues={initialValues} onFinish={onFinish} />
    </CheckAccess>
  );
};

export default Edit;
