import { createSlice } from '@reduxjs/toolkit';
import { getVersionApi } from '@store/actions/extra/Version';

import { version } from '../../../../../package.json';

import type { LoadingStatus } from '@constants/loadingStatus';

type VersionState = {
  versionApi: string | null;
  versionApp: string;
  versionStatus: LoadingStatus;
  versionError: string | null;
};

const initialState: VersionState = {
  versionApi: null,
  versionApp: version,
  versionStatus: 'idle',
  versionError: null,
};

const VersionSlice = createSlice({
  name: 'Version',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVersionApi.pending, (state) => {
        state.versionStatus = 'pending';
        state.versionError = null;
      })
      .addCase(getVersionApi.fulfilled, (state, { payload }) => {
        state.versionApi = payload;
        state.versionStatus = 'idle';
      })
      .addCase(getVersionApi.rejected, (state, { payload }) => {
        state.versionStatus = 'idle';
        if (payload) {
          state.versionError = payload;
        }
      });
  },
});

const VersionReducer = VersionSlice.reducer;

export { VersionReducer };
