import React from 'react';

import { FormMaintenanceCategory } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletMaintenanceCategory } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateMaAtCategory } from '@store/actions/properties/MaAt/Category';

import type { CreateMaAtCategoryDto } from '@model/properties/MaAt/Category';

function EditMaintenanceCategory() {
  const { initialValues, entity } = useOutletMaintenanceCategory();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { maintenanceAttrAccess } = useGetAccess();

  const onFinish = async (dto: CreateMaAtCategoryDto) => {
    try {
      const response = await dispatch(
        updateMaAtCategory({
          ...dto,
          maintinanceCategoryId: entity.maintinanceCategoryId,
        })
      ).unwrap();
      ApiNotifications.update(response.MaAtCategory.categoryName);
      navigate(paths.maintenanceAttributes.tabs.category.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={maintenanceAttrAccess.edit} redirectTo={paths.maintenanceAttributes.tabs.category.default}>
      <FormMaintenanceCategory
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.categoryName,
        }}
      />
    </CheckAccess>
  );
}

export default EditMaintenanceCategory;
