import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getAsAtCards } from '@store/actions/properties/AsAt/Card';
import { selectAsAtCardsList } from '@store/selectors/properties/AsAt/Card';

function EntryAssetName() {
  useSetPageInfo(
    {
      defaultPagePath: paths.assetAttribute.tabs.name.default,
      pageTitle: 'pages_single.Asset_Attr',
    },
    {
      copyTabs: true,
    }
  );

  const assetNameList = useAppSelector(selectAsAtCardsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!assetNameList.length) {
      dispatch(getAsAtCards());
    }
  }, []);

  return <Outlet />;
}

export default EntryAssetName;
