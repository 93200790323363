import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormPartAndInventory } from '@components/views/Forms/managment';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addPaI } from '@store/actions/management/PaI';

import type { CreatePaIDto } from '@model/management/PaI';
import type { FormInstance } from 'antd';

function CreatePartAndInventoryV2() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { paiAccess } = useGetAccess();

  const onFinish = (dto: CreatePaIDto, form?: FormInstance<CreatePaIDto>) => {
    const purchaseNumber = dto.purchaseNumber === '' ? null : dto.purchaseNumber;

    dispatch(addPaI({ ...dto, purchaseNumber }))
      .unwrap()
      .then((payload) => {
        ApiNotifications.create(payload.partsAndInventoriesCard.partsAndInventoriesCardName);
        form?.resetFields();
        navigate(paths.partAndInventory.default);
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <CheckAccess hasAccess={paiAccess.create} redirectTo={paths.partAndInventory.default}>
      <FormPartAndInventory
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.PaI') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreatePartAndInventoryV2;
