import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormVendor } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addVendor } from '@store/actions/properties/Vendor';

import type { CreateVendorDto } from '@model/properties/Vendor';
import type { FormInstance } from 'antd';

const CreateVendor = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { vendorAccess } = useGetAccess();

  const onFinish = async (dto: CreateVendorDto, form?: FormInstance<CreateVendorDto>) => {
    try {
      const response = await dispatch(addVendor(dto)).unwrap();
      ApiNotifications.create(response.name);
      navigate(paths.vendor.default);
      form?.resetFields();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={vendorAccess.create} redirectTo={paths.vendor.default}>
      <FormVendor
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.Vendor') }),
        }}
      />
    </CheckAccess>
  );
};

export default CreateVendor;
