import React, { cloneElement } from 'react';

import { useHandleParamId } from '@hooks';
import { Checkbox, List } from '@shared/UI';
import { Row as AntdRow } from 'antd';
import cl from 'classnames';

import { RowCell } from './elements';

import type { RowProps as AntdRowProps } from 'antd';
import type { ListItemProps } from 'antd/lib/list';
import type { PropsWithChildren, ReactElement } from 'react';
import type { RowCellProps } from './elements/RowCell/RowCell';

import './Row.scss';

const { Item } = List;

interface RowProps extends ListItemProps, Pick<AntdRowProps, 'gutter'> {
  hiddenCheckbox?: boolean;
  itemKey: string | number;
}

interface RowInterface {
  (props: RowProps): React.ReactElement<RowProps>;
  RowCell: typeof RowCell;
}

const Row: RowInterface = (props) => {
  const { children, gutter = [0, 0], className, itemKey, ...rest } = props;
  const { redirect } = useHandleParamId();

  const rowClasses = cl('common-list-row', className);

  const onClick = () => {
    redirect(itemKey);
  };

  return (
    <Item {...rest} className={rowClasses}>
      <AntdRow
        gutter={gutter}
        wrap={false}
        justify="space-between"
        align="middle"
        style={{
          width: '100%',
        }}
      >
        <RowCell span={1} className="row-checkbox">
          <Checkbox />
        </RowCell>
        {React.Children.map(children, (child) => {
          const item = child as ReactElement<PropsWithChildren<RowCellProps>>;
          if (item.type === RowCell) {
            return cloneElement(item, { onClick });
          }
          return child;
        })}
      </AntdRow>
    </Item>
  );
};

Row.RowCell = RowCell;

export { Row };
