import { v4 as uuidv4 } from 'uuid';

import type {
  CheckStepItem,
  CheckStepItemOption,
  CheckStepState,
  CheckStepStateHandler,
} from '../../types';

export function onAddNewOption(
  state: CheckStepState,
  setState: CheckStepStateHandler,
  step: CheckStepItem
) {
  const newOption: CheckStepItemOption = {
    uuid: uuidv4(),
    rowId: step.optionsList.length + 1,
    isNew: true,
    isRemoved: false,
    title: '',
    checked: false,
    isUpdated: false,
    hasError: false,
    value: '',
  };

  const updatedOptionList = [...step.optionsList, newOption];

  setState((prevState) =>
    prevState.map((checkStep) =>
      checkStep.uuid === step.uuid
        ? {
            ...step,
            optionsList: updatedOptionList,
          }
        : checkStep
    )
  );
}
