import React from 'react';
import { useTranslation } from 'react-i18next';

import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { NoResults } from '../../NoResults';

import './LocationBarChart.scss';

interface LocationBarChartProps {
  data: {
    siteName: string;
    numNonCurrAssets: number;
  }[] | undefined
}

const LocationBarChart = (props: LocationBarChartProps) => {
  const {data} = props;
  
  const { t } = useTranslation();

  const getCorrectValue = (obj:any) => {
    const text = obj.payload.value;
    const textStyle = {
      fill: obj.fill,
      textAnchor: obj.textAnchor,
      dy: '0.36em',
      x: obj.x,
      y: obj.y,
    }

    const correctValue = text.length > 25 ?
      <>
        <text {...textStyle}>{text.slice(0, 25)}</text>
        <text {...textStyle} dy='1.5em'>{text.slice(25)}</text>
      </> 
      : 
      <text {...textStyle}>{text}</text>;

    return correctValue
  };
  
  return (
    <div className="location-bar-chart">
      <div className="location-bar-chart__header">
        <h6>{t('titles.Assets_by_Location')}</h6>
      </div>
        <div className="location-bar-chart__horizontal_chart">
        {(data && !data.length) ?
        <NoResults />
        :
        <>
        <ResponsiveContainer width={"100%"} height={250}>
          <BarChart
            layout='vertical'
            data={data}
          >
            <XAxis hide axisLine={false} type="number" />
            <YAxis
              hide={!data?.length}
              dataKey="siteName" 
              type='category'
              axisLine={false}
              tickLine={false}
              width={180}
              orientation={'left'}
              tick={getCorrectValue}
            />
            <YAxis
              hide={!data?.length}
              orientation="right"
              yAxisId={1}
              dataKey={'numNonCurrAssets'}
              type="category"
              tickFormatter={value => value.toLocaleString()}
            />
            <Bar barSize={10} dataKey="numNonCurrAssets" minPointSize={2} fill=" #00458A" />
          </BarChart>
          </ResponsiveContainer>
          </>
        }
        </div>
    </div>
  );
};

export { LocationBarChart };
