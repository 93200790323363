import * as React from 'react';

import type { SVGProps } from 'react';

const PlusSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.055 4.427a1.125 1.125 0 0 1 1.125 1.125v13.5a1.125 1.125 0 0 1-1.125 1.125h-13.5a1.125 1.125 0 0 1-1.125-1.125v-13.5a1.125 1.125 0 0 1 1.125-1.125h13.5Zm-13.5-1.125a2.25 2.25 0 0 0-2.25 2.25v13.5a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25v-13.5a2.25 2.25 0 0 0-2.25-2.25h-13.5Z"
      fill={props.fill || '#032F5C'}
    />
    <path
      d="M12.305 7.802a.562.562 0 0 1 .562.562v3.375h3.375a.562.562 0 1 1 0 1.125h-3.375v3.375a.563.563 0 0 1-1.125 0v-3.375H8.367a.562.562 0 0 1 0-1.125h3.375V8.364a.563.563 0 0 1 .563-.562Z"
      fill={props.fill || '#032F5C'}
    />
  </svg>
);

export { PlusSquare };
