import React from 'react';

import { Switch as AntSwitch } from 'antd';
import cl from 'classnames';

import type { SwitchProps as AntSwitchProps } from 'antd';

import './Switch.scss';

export interface SwitchProps extends AntSwitchProps {}

export const Switch = (props: SwitchProps) => {
  const { className, ...restProps } = props;

  const classes = cl('custom-switch', className);

  return <AntSwitch className={classes} {...restProps} />;
};
