import React from 'react';

import { PreviewFormMaintenanceGeneral } from '@components/views/Previews/management';
import { useOutletMaintenance } from '@constants/OutletContextEntityPages';

const PreviewMaintenanceGeneral = () => {
  const { entity, initialValuesGeneral } = useOutletMaintenance();

  return <PreviewFormMaintenanceGeneral entity={entity} initialValues={initialValuesGeneral} />;
};

export default PreviewMaintenanceGeneral;
