import { createSlice } from '@reduxjs/toolkit';
import {
  addUserGroup,
  getUserGroupById,
  getUserGroups,
  removeUserGroupById,
  updateUserGroup,
} from '@store/actions/user/UserGroup';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { UserGroupModel } from '@model/users/UserGroup';

type UserGroupState = {
  groupList: UserGroupModel[];
  groupStatus: LoadingStatus;
  group: UserGroupModel | null;
  groupError: string | null;
};

const initialState: UserGroupState = {
  groupList: [],
  groupError: null,
  group: null,
  groupStatus: 'idle',
};

const UserGroupSlice = createSlice({
  name: 'UserGroups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserGroups.pending, (state) => {
        state.groupStatus = 'pending';
        state.groupError = null;
      })
      .addCase(getUserGroups.fulfilled, (state, { payload }) => {
        state.groupStatus = 'idle';
        state.groupList = payload;
      })
      .addCase(getUserGroups.rejected, (state, { payload }) => {
        state.groupStatus = 'failed';
        state.groupError = payload as string;
      });
    builder
      .addCase(addUserGroup.pending, (state) => {
        state.groupError = null;
        state.groupStatus = 'pending';
      })
      .addCase(addUserGroup.fulfilled, (state, { payload }) => {
        state.groupStatus = 'idle';
        state.groupList = payload.groupList;
      })
      .addCase(addUserGroup.rejected, (state) => {
        state.groupStatus = 'idle';
      });
    builder
      .addCase(getUserGroupById.pending, (state) => {
        state.groupError = null;
        state.groupStatus = 'pending';
      })
      .addCase(getUserGroupById.fulfilled, (state, { payload }) => {
        state.groupStatus = 'idle';
        state.group = payload;
      })
      .addCase(getUserGroupById.rejected, (state) => {
        state.groupStatus = 'idle';
      });
    builder
      .addCase(updateUserGroup.pending, (state) => {
        state.groupError = null;
        state.groupStatus = 'pending';
      })
      .addCase(updateUserGroup.fulfilled, (state, { payload }) => {
        state.groupStatus = 'idle';
        state.group = payload.group;
        state.groupList = payload.groups;
      })
      .addCase(updateUserGroup.rejected, (state) => {
        state.groupStatus = 'idle';
      });
    builder
      .addCase(removeUserGroupById.pending, (state) => {
        state.groupError = null;
        state.groupStatus = 'pending';
      })
      .addCase(removeUserGroupById.fulfilled, (state, { payload }) => {
        state.groupStatus = 'idle';
        state.groupList = payload;
      })
      .addCase(removeUserGroupById.rejected, (state) => {
        state.groupStatus = 'idle';
      });
  },
});

const UserGroupReducer = UserGroupSlice.reducer;

export { UserGroupReducer };
