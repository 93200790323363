import { v4 as uuidv4 } from 'uuid';

import { checkStepKeys } from '../../checkStepTypes';

import type { ChecklistStepTypeKeys } from '../../checkStepTypes';
import type {
  CheckStepItem,
  CheckStepItemOption,
  CheckStepState,
  CheckStepStateHandler,
} from '../../types';

export const addNewStep = (
  state: CheckStepState,
  setState: CheckStepStateHandler,
  key: ChecklistStepTypeKeys
) => {
  const uuid = uuidv4();
  let step: CheckStepItem = {
    uuid,
    title: '',
    isUpdate: false,
    isNew: true,
    isRemoved: false,
    rowId: state.length + 1,
    value: '',
    imagesList: [],
    optionsList: [],
    stepKey: key,
    hasError: false,
    isCompleted: false,
    isActive: true,
  };

  if (key === checkStepKeys.multiple) {
    const optionsList: CheckStepItem['optionsList'] = Array(2)
      .fill(null)
      .map(
        (_, idx): CheckStepItemOption => ({
          uuid: uuidv4(),
          rowId: idx + 1,
          isNew: true,
          isRemoved: false,
          title: '',
          checked: false,
          isUpdated: false,
          hasError: false,
          value: '',
        })
      );
    step = {
      ...step,
      optionsList,
    };
  }

  setState((prevState) =>
    [...prevState, step].map((s) =>
      s.uuid !== uuid
        ? {
            ...s,
            isActive: false,
          }
        : s
    )
  );
};
