import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, TextAreaDescription } from '@components/common';
import { SiteModalForm } from '@components/views/Forms/users/User/SiteModalForm';
import { phoneNumberPattern } from '@constants/regex';
import { useAppDispatch, useAppSelector, useModalState } from '@hooks';
import { Input, Select } from '@shared/UI';
import { getDepartments } from '@store/actions/properties/Department';
import { getSites } from '@store/actions/properties/Site';
import { getRoles } from '@store/actions/user/Role';
import { getTitles } from '@store/actions/user/Title';
import { getUsers } from '@store/actions/user/User';
import { getUserGroups } from '@store/actions/user/UserGroup';
import { selectDepartmentsListWithoutChildren } from '@store/selectors/properties/Department';
import { selectSitesListWithoutChildren } from '@store/selectors/properties/Site';
import { selectRoleList } from '@store/selectors/users/Role';
import { selectTitlesList } from '@store/selectors/users/Title';
import { selectUsersListWithoutChildren, selectUserStatus } from '@store/selectors/users/User';
import { selectUserGroupsList } from '@store/selectors/users/UserGroup';

import { DepartmentModalForm } from './DepartmentModalForm';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateUserDto } from '@model/users/User';
import type { Rule } from 'antd/lib/form';

const { CaseField, Item, useForm, Provider } = CommonForm;

// region --- ToolbarItems rules

const userCodeRules = [
  {
    required: true,
  },
];

const registerNumberRules = [
  {
    required: true,
  },
];

const firstNameRules = [
  {
    required: true,
  },
];

const lastNameRules = [
  {
    required: true,
  },
];

const userTitleRules = [
  {
    required: true,
  },
];

const roleRules = [
  {
    required: true,
  },
];

const emailRules = [
  {
    required: true,
    type: 'email',
  },
];

const departmentRules = [
  {
    required: true,
  },
];
const siteRules = [
  {
    required: true,
  },
];

// endregion --- ToolbarItems rules

// region --- Fields names

const departmentNames = { label: 'name', value: 'departmentId' };

const titleNames = { label: 'title', value: 'userTitleId' };

const roleNames = { label: 'name', value: 'roleId' };

const userGroupNames = { label: 'groupName', value: 'groupsId' };

const siteNames = { label: 'name', value: 'siteId' };

// endregion --- Fields names

function FormUser(props: CommonFormProps<CreateUserDto>) {
  const [form] = useForm<CreateUserDto>();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // region --- State selectors
  const departmentsList = useAppSelector(selectDepartmentsListWithoutChildren);
  const usersList = useAppSelector(selectUsersListWithoutChildren);
  const sitesList = useAppSelector(selectSitesListWithoutChildren);
  const titlesList = useAppSelector(selectTitlesList);
  const rolesList = useAppSelector(selectRoleList);
  const userGroupsList = useAppSelector(selectUserGroupsList);

  const userStatus = useAppSelector(selectUserStatus);
  // endregion --- State selectors

  // region --- modals states

  const siteModal = useModalState();

  const departmentModal = useModalState();

  // endregion --- modals states

  useEffect(() => {
    if (departmentsList.length === 0) {
      dispatch(getDepartments());
    }

    if (usersList.length === 0) {
      dispatch(getUsers());
    }

    if (sitesList.length === 0) {
      dispatch(getSites());
    }

    if (titlesList.length === 0) {
      dispatch(getTitles());
    }

    if (rolesList.length === 0) {
      dispatch(getRoles());
    }

    if (userGroupsList.length === 0) {
      dispatch(getUserGroups());
    }
  }, []);

  const userOptions = useMemo(
    () =>
      usersList.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.userId,
      })),
    [usersList]
  );

  return (
    <Provider>
      <CommonForm name="userForm" form={form} loading={userStatus === 'pending'} {...props}>
        <CaseField title={t('titles.Primary_Info')}>
          <Item label={t('titles.User_Code')} name="userCode" rules={userCodeRules}>
            <Input placeholder={t('titles.User_Code')} />
          </Item>
          <Item
            label={t('titles.Registration_Number')}
            name="registrationNumber"
            rules={registerNumberRules}
          >
            <Input placeholder={t('titles.Registration_Number')} />
          </Item>
          <Item label={t('titles.First_Name')} name="firstName" rules={firstNameRules}>
            <Input placeholder={t('titles.First_Name')} />
          </Item>
          <Item label={t('titles.Last_Name')} name="lastName" rules={lastNameRules}>
            <Input placeholder={t('titles.Last_Name')} />
          </Item>
          <Item name="userTitleId" label={t('titles.Title')} rules={userTitleRules}>
            <Select
              fieldNames={titleNames}
              options={titlesList}
              placeholder={t('titles.Choose_Name', { name: t('titles.Title') })}
            />
          </Item>
          <Item name="roleId" label={t('titles.Role')} rules={roleRules}>
            <Select
              fieldNames={roleNames}
              options={rolesList}
              placeholder={t('titles.Choose_Name', { name: t('titles.Role') })}
            />
          </Item>
          <Item name="groupsId" label={t('pages_single.User_Group')}>
            <Select
              fieldNames={userGroupNames}
              options={userGroupsList}
              placeholder={t('titles.Choose_Name', { name: t('pages_single.User_Group') })}
            />
          </Item>
        </CaseField>
        <CaseField title={t('titles.Contact_Info')}>
          <Item label={t('titles.Email')} name="userMail" rules={emailRules as Rule[]}>
            <Input placeholder={t('titles.Email')} />
          </Item>
          <Item 
            label={t('titles.Phone')} 
            name="phoneNumber"
            rules={[
              {
                required: true,
              },
              {
                pattern: phoneNumberPattern,
                message: t('errors.Not_Valid', { name: t('titles.Phone_Num') }),
              },
              {
                max: 15,
              },
              {
                min: 12,
              }
            ]}
          >

            <Input placeholder={t('titles.Phone')} />
          </Item>
          <Item name="departmentId" rules={departmentRules} label={t('pages_single.Department')}>
            <Select
              isCreatable
              creatableAction={departmentModal.actions.onOpen}
              fieldNames={departmentNames}
              options={departmentsList}
              placeholder={t('titles.Choose_Name', { name: t('pages_single.Department') })}
            />
          </Item>
          <Item name="parentUserId" label={t('titles.Parent_User')}>
            <Select
              options={userOptions}
              placeholder={t('titles.Choose_Name', { name: t('titles.Parent_User') })}
            />
          </Item>

          <Item name="siteId" rules={siteRules} label={t('titles.Site')}>
            <Select
              creatableAction={siteModal.actions.onOpen}
              isCreatable
              fieldNames={siteNames}
              options={sitesList}
              placeholder={t('titles.Choose_Name', { name: t('titles.Site') })}
            />
          </Item>
          <TextAreaDescription />
        </CaseField>
      </CommonForm>

      <DepartmentModalForm
        formInstance={form}
        siteRules={siteRules}
        siteNames={siteNames}
        sitesList={sitesList}
        departmentList={departmentsList}
        departmentNames={departmentNames}
        isOpen={departmentModal.isOpen}
        onClose={departmentModal.actions.onClose}
      />
      <SiteModalForm
        isOpen={siteModal.isOpen}
        onClose={siteModal.actions.onClose}
        formInstance={form}
      />
    </Provider>
  );
}

export { FormUser };
