import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getUsers } from '@store/actions/user/User';
import { selectUsersList } from '@store/selectors/users/User';

function EntryUser() {
  useSetPageInfo({
    defaultPagePath: paths.users.default,
    pageTitle: 'pages_single.User',
  });

  const users = useAppSelector(selectUsersList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!users.length) {
      dispatch(getUsers());
    }
  }, []);

  return <Outlet />;
}

export default EntryUser;
