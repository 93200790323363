import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useGetAccess, useWindowSize } from '@hooks';
import { AsetlyLogo } from '@icon/icon-components';
import { Menu } from '@shared/UI';
import { Layout } from 'antd';
import cl from 'classnames';

import { menuItems } from './menu-items';

import type { AccessKey } from '@constants/AccessKey';

import './Navbar.scss';

const { Sider } = Layout;

const flatPaths = (items: any[]) => {
  return items
    .map((item): any => {
      if (item.type === 'group') {
        return null;
      }
      if (item.children) {
        return flatPaths(item.children);
      }
      return item.key;
    })
    .filter((item) => item !== null)
    .flat();
};

const urls = flatPaths(menuItems);

const onWindowWidth = (width?: number) => {
  let defaultWidth = 320;

  if (!width) return defaultWidth;

  if (width <= 1540) {
    defaultWidth = 250;
  }

  return defaultWidth;
};

const Navbar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const windowSize = useWindowSize();

  const accesses = useGetAccess();

  useEffect(() => {
    if (!windowSize.width || windowSize.width > 1100) {
      return;
    }
    setCollapsed(true);
  }, [windowSize.width]);

  const defSelectedKey = urls.filter((url) => {
    return location.pathname.includes(url);
  });

  const isHasAccessToView = (accessKey: AccessKey) => {
    const found = Object.values(accesses).find((ac) => {
      if (accessKey === ac.accessKey) {
        return ac;
      }
      return null;
    });

    return found && 'view' in found ? found.view : true;
  };

  const getItems = (items: any[]): any[] => {
    return items.map((item) => {
      if ('type' in item && (item.type === 'group' || item.type === 'divider')) {
        return item;
      }
      if ('children' in item && item.children?.length) {
        const isEmpty = item.children
          .map((itm: any) => isHasAccessToView(itm.accessKey))
          .every((bol: boolean) => !bol);
        if (isEmpty) {
          return null;
        }
        return {
          ...item,
          children: getItems(item.children),
        };
      }
      if (isHasAccessToView(item.accessKey)) {
        return item;
      }

      return null;
    });
  };
  const classes = cl('custom-layout-navbar', {
    'custom-layout-navbar--collapsed': collapsed,
  });

  return (
    <Sider
      breakpoint="sm"
      className={classes}
      collapsible
      trigger={null}
      collapsed={collapsed}
      width={onWindowWidth(windowSize.width)}
      collapsedWidth={65}
    >
      <div className="navbar-logo">
        {collapsed ? null : (
          <span
            style={{
              marginTop: 15,
            }}
          >
            <AsetlyLogo />
          </span>
        )}

        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: () => setCollapsed(!collapsed),
          style: {
            fontSize: 20,
          },
        })}
      </div>
      <Menu defaultSelectedKeys={defSelectedKey} mode="inline" items={getItems(menuItems)} />
    </Sider>
  );
};

export default memo(Navbar);
