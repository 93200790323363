import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getContractById, getContractFilesById } from '@store/actions/properties/Contract';
import { selectContract, selectCurrentContractFiles } from '@store/selectors/properties/Contract';

import type { IOutletContract } from '@constants/OutletContextEntityPages';
import type { CreateContractDto } from '@model/properties/Contract';

function Contract() {
  const contract = useAppSelector(selectContract);
  const contractFiles = useAppSelector(selectCurrentContractFiles);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !contract || contract.contractId !== +decryptedId;

  useSetPageInfo({
    defaultPagePath: paths.contract.default,
    pageTitle: 'pages_single.Contract',
  });

  useEffect(() => {
    if (condition) {
      dispatch(getContractById(decryptedId));
      dispatch(getContractFilesById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateContractDto = {
    currencyId: contract.currencyId,
    name: contract.name,
    contractCode: contract.contractCode,
    endDate: contract.endDate,
    startDate: contract.startDate,
    no: contract.no,
    partnerId: contract.partnerId,
    price: contract.price,
    description: contract.description,
    files: contractFiles,
  } as CreateContractDto;

  return <Outlet context={{ initialValues, entity: contract } as IOutletContract} />;
}

export default Contract;
