import { createSlice } from '@reduxjs/toolkit';
import { addLabel, getLabels, getLabelsByPoOrName } from '@store/actions/extra/Label';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { LabelModel } from '@model/extra/Label';

interface LabelState {
  labelsList: LabelModel[];
  labelListType: 1 | 2 | null;
  labelStatus: LoadingStatus;
  labelError: string | null;
  label: LabelModel | null;
}

const initialState: LabelState = {
  labelsList: [],
  labelStatus: 'idle',
  labelError: null,
  label: null,
  labelListType: null,
};

const LabelSlice = createSlice({
  name: 'label',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLabels.pending, (state) => {
        state.labelError = null;
        state.labelStatus = 'pending';
      })
      .addCase(getLabels.fulfilled, (state, { payload }) => {
        state.labelStatus = 'idle';
        state.labelsList = payload.labels;
        state.labelListType = payload.labelListType;
      })
      .addCase(getLabels.rejected, (state) => {
        state.labelStatus = 'idle';
      });
    
    builder
      .addCase(getLabelsByPoOrName.pending, (state) => {
        state.labelError = null;
        state.labelStatus = 'pending';
      })
      .addCase(getLabelsByPoOrName.fulfilled, (state, { payload }) => {
        state.labelStatus = 'idle';
        state.labelsList = payload.labels;
        state.labelListType = payload.labelListType;
      })
      .addCase(getLabelsByPoOrName.rejected, (state) => {
        state.labelStatus = 'idle';
      });

    builder
      .addCase(addLabel.pending, (state) => {
        state.labelStatus = 'pending';
        state.labelError = null;
      })
      .addCase(addLabel.fulfilled, (state, { payload }) => {
        state.labelStatus = 'idle';
        state.labelsList = payload.labels;
        state.labelListType = payload.labelListType;
      })
      .addCase(addLabel.rejected, (state, { payload }) => {
        state.labelStatus = 'idle';
        if (payload) {
          state.labelError = payload;
        }
      });
  },
});

const LabelReducer = LabelSlice.reducer;

export { LabelReducer };
