import { api } from '@services/Axios';

import type { CreateTitleDto, TitleModel, UpdateTitleDto } from '@model/users/Title';
import type { ApiResponse } from '@services/Axios';

const TiEndpoint = '/UserTitle';

const TitleRepository = {
  async fetchAll() {
    const response: ApiResponse<TitleModel[]> = await api.get(`${TiEndpoint}/GetTitleList`);

    return response.data;
  },
  async add(dto: CreateTitleDto) {
    const response: ApiResponse<TitleModel> = await api.post(`${TiEndpoint}/AddTitle`, dto);

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<TitleModel> = await api.get(`${TiEndpoint}/GetTitleById/${id}`);

    return response.data;
  },
  async update(dto: UpdateTitleDto) {
    const response: ApiResponse<TitleModel> = await api.post(`${TiEndpoint}/UpdateTitle`, dto);

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${TiEndpoint}/RemoveByIdList`, {
      userTitleIds: ids,
    });

    return response.data;
  },
};

export { TitleRepository };
