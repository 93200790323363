import React from 'react';

import cl from 'classnames';
import { Navigation as NavigationSwiper, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import { BaseModal } from '../BaseModal/BaseModal';
import { Navigation } from './Navigation';

import type { SwiperProps } from 'swiper/react/swiper-react';
import type { BaseModalProps } from '../BaseModal/BaseModal';

import './CarouselModal.scss';

export interface CarouselModalProps extends BaseModalProps {
  swiperConfig?: SwiperProps;
  images: Array<string>;
}

const CarouselModal = (props: CarouselModalProps) => {
  const { swiperConfig: swiperConfigProps, images, className, ...rest } = props;

  const swiperConfig: SwiperProps = {
    modules: [NavigationSwiper, Pagination],
    centeredSlides: true,
    centerInsufficientSlides: true,
    allowTouchMove: false,
    effect: 'flip',
    initialSlide: 0,
    ...swiperConfigProps,
  };

  const carouselModalClass = cl('carousel-modal', className);

  if (!images) {
    return null;
  }

  return (
    <BaseModal size="large" className={carouselModalClass} footer={[]} destroyOnClose {...rest}>
      <Swiper {...swiperConfig}>
        <div className="carousel-modal__wrapper-slides">
          {images.map((image) => (
            <SwiperSlide key={image} className="carousel-modal__slide">
              <img src={image} alt="" />
            </SwiperSlide>
          ))}
        </div>
        <Navigation lengthImages={images.length} initialSlide={swiperConfig.initialSlide} />
      </Swiper>
    </BaseModal>
  );
};

export { CarouselModal };
