import { createSlice } from '@reduxjs/toolkit';
import {
  addAsAtBrand,
  getAsAtBrandById,
  getAsAtBrands,
  removeAsAtBrandById,
  updateAsAtBrand,
} from '@store/actions/properties/AsAt/Brand';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AsAtBrandModel } from '@model/properties/AsAt/Brand';

interface AsAtBrandState {
  AsAtBrandsList: AsAtBrandModel[];
  AsAtBrand: AsAtBrandModel | null;
  AsAtBrandStatus: LoadingStatus;
  AsAtBrandError: string | null;
}

const initialState: AsAtBrandState = {
  AsAtBrandsList: [],
  AsAtBrand: null,
  AsAtBrandStatus: 'idle',
  AsAtBrandError: null,
};

const AsAtBrandSlice = createSlice({
  name: 'AsAtBrand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsAtBrands.pending, (state) => {
        state.AsAtBrandError = null;
        state.AsAtBrandStatus = 'pending';
      })
      .addCase(getAsAtBrands.fulfilled, (state, { payload }) => {
        state.AsAtBrandStatus = 'idle';
        state.AsAtBrandsList = payload;
      })
      .addCase(getAsAtBrands.rejected, (state, { payload }) => {
        state.AsAtBrandStatus = 'idle';
        if (payload) {
          state.AsAtBrandError = payload;
        }
      });

    builder
      .addCase(addAsAtBrand.pending, (state) => {
        state.AsAtBrandStatus = 'pending';
        state.AsAtBrandError = null;
      })
      .addCase(addAsAtBrand.fulfilled, (state, { payload }) => {
        state.AsAtBrandStatus = 'idle';
        state.AsAtBrandsList = [payload, ...state.AsAtBrandsList];
      })
      .addCase(addAsAtBrand.rejected, (state, { payload }) => {
        state.AsAtBrandStatus = 'idle';
        if (payload) {
          state.AsAtBrandError = payload;
        }
      });

    builder
      .addCase(getAsAtBrandById.pending, (state) => {
        state.AsAtBrandStatus = 'pending';
        state.AsAtBrandError = null;
      })
      .addCase(getAsAtBrandById.fulfilled, (state, { payload }) => {
        state.AsAtBrandStatus = 'idle';
        state.AsAtBrand = payload;
      })
      .addCase(getAsAtBrandById.rejected, (state, { payload }) => {
        state.AsAtBrandStatus = 'idle';
        if (payload) {
          state.AsAtBrandError = payload;
        }
      });

    builder
      .addCase(updateAsAtBrand.pending, (state) => {
        state.AsAtBrandStatus = 'pending';
        state.AsAtBrandError = null;
      })
      .addCase(updateAsAtBrand.fulfilled, (state, { payload }) => {
        state.AsAtBrandStatus = 'idle';
        state.AsAtBrandsList = payload.AsAtBrandsList;
        state.AsAtBrand = payload.AsAtBrand;
      })
      .addCase(updateAsAtBrand.rejected, (state, { payload }) => {
        state.AsAtBrandStatus = 'idle';
        if (payload) {
          state.AsAtBrandError = payload;
        }
      });

    builder
      .addCase(removeAsAtBrandById.pending, (state) => {
        state.AsAtBrandStatus = 'pending';
        state.AsAtBrandError = null;
      })
      .addCase(removeAsAtBrandById.fulfilled, (state, { payload }) => {
        state.AsAtBrandStatus = 'idle';
        state.AsAtBrandsList = payload;
      })
      .addCase(removeAsAtBrandById.rejected, (state, { payload }) => {
        state.AsAtBrandStatus = 'idle';
        if (payload) {
          state.AsAtBrandError = payload;
        }
      });
  },
});

const AsAtBrandReducer = AsAtBrandSlice.reducer;

export { AsAtBrandReducer };
