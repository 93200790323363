import React from 'react';
import { useTranslation } from 'react-i18next';

import { adaptCheckStepsToMaintenanceAttrCheckSteps } from '@adapters';
import { FormMaintenanceChecklist } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useAppSelector, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addMaAtChecklist, addMaAtCheckStep } from '@store/actions/properties/MaAt/Checklist';
import { selectProfile } from '@store/selectors/profile/Profile';

import type { ProfileModel } from '@model/profile/Profile';
import type {
  CreateChecklistCheckStepsDto,
  CreateCheckStepDto,
  CreateMaintenanceChecklistDto,
} from '@model/properties/MaAt/Checklist';
import type { FormInstance } from 'antd';

const CreateMaintenanceChecklist = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { maintenanceAttrAccess } = useGetAccess();

  const currentUser = useAppSelector(selectProfile) as ProfileModel;

  const onFinish = async (
    dto: CreateChecklistCheckStepsDto,
    form?: FormInstance<CreateChecklistCheckStepsDto>,
    clearSteps?: () => void
  ) => {
    const { checklistName, description, checkSteps } = dto;

    const checkListDto: CreateMaintenanceChecklistDto = {
      checklistName: checklistName,
      description: description,
    };

    try {
      const { checkListId, checklistName } = await dispatch(
        addMaAtChecklist(checkListDto)
      ).unwrap();
      const steps = adaptCheckStepsToMaintenanceAttrCheckSteps(
        checkSteps,
        checkListId,
        currentUser.userId
      ) as CreateCheckStepDto[];

      try {
        await dispatch(addMaAtCheckStep(steps));
      } catch (error) {
        ApiNotifications.error(error);
      }
      ApiNotifications.create(checklistName);
      clearSteps?.();
      navigate(paths.maintenanceAttributes.tabs.checklist.default);
      form?.resetFields();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  const header = {
    title: t('titles.New_Item', { name: t('pages_single.Maintenance_Attr_Checklist') }),
  };

  return (
    <CheckAccess
      hasAccess={maintenanceAttrAccess.create}
      redirectTo={paths.maintenanceAttributes.tabs.checklist.default}
    >
      <FormMaintenanceChecklist header={header} onFinish={onFinish} />
    </CheckAccess>
  );
};

export default CreateMaintenanceChecklist;
