import { api } from '@services/Axios';

import type {
  CreatePaICategoryDto,
  PaICategoryModel,
  UpdatePaICategoryDto,
  UpdatePaICategoryParentDto,
} from '@model/properties/PaI/Category';
import type { ApiResponse } from '@services/Axios';

const PaICategoryEndpoint = '/PartsAndInventoriesCategories';

export const PaICategoryRepository = {
  async fetchAll() {
    const response: ApiResponse<PaICategoryModel[]> = await api.get(
      `${PaICategoryEndpoint}/PartsAndInventoriesCategoryList`
    );

    return response.data;
  },
  async add(dto: CreatePaICategoryDto) {
    const response: ApiResponse<PaICategoryModel> = await api.post(
      `${PaICategoryEndpoint}/AddPartsAndInventoriesCategory`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<PaICategoryModel[]> = await api.get(
      `${PaICategoryEndpoint}/GetPartsAndInventoriesCategoryById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdatePaICategoryDto) {
    const response: ApiResponse<PaICategoryModel> = await api.post(
      `${PaICategoryEndpoint}/UpdatePartsAndInventoriesCategory`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${PaICategoryEndpoint}/RemoveByIdList`, {
      partsAndInventoriesCategoryIds: ids,
    });

    return response.data;
  },

  async updatePatentRelations(dto: UpdatePaICategoryParentDto) {
    const response: ApiResponse<null> = await api.post(
      `${PaICategoryEndpoint}/ChangeDepartmentParentReleations`,
      dto
    );

    return response.data;
  },
};
