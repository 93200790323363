import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaIUnitRepository } from '@repositories';

import type { CreatePaIUnitDto, PaIUnitModel, UpdatePaIUnitDto } from '@model/properties/PaI/Unit';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getPaIUnits = createAsyncThunk<PaIUnitModel[], void, { rejectValue: string }>(
  'PaIUnit/getPaIUnits',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PaIUnitRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getPaIUnitById = createAsyncThunk<PaIUnitModel, string, { rejectValue: string }>(
  'PaIUnit/getPaIUnitById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await PaIUnitRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;

      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addPaIUnit = createAsyncThunk<PaIUnitModel, CreatePaIUnitDto, { rejectValue: string }>(
  'PaIUnit/addPaIUnit',
  async (dto, { rejectWithValue }) => {
    try {
      const response = await PaIUnitRepository.add(dto);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Add Actions

// region --- Update Actions

export const updatePaIUnit = createAsyncThunk<
  { units: PaIUnitModel[]; particularUnit: PaIUnitModel },
  UpdatePaIUnitDto,
  { rejectValue: string }
>('PaIUnit/updatePaIUnit', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaIUnitRepository.update(dto);
    const responseList = await PaIUnitRepository.fetchAll();

    return { units: responseList.resultObject, particularUnit: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removePaIUnitById = createAsyncThunk<
  PaIUnitModel[],
  number[],
  { rejectValue: string }
>('PaIUnit/removePaIUnitById', async (ids, { rejectWithValue }) => {
  try {
    await PaIUnitRepository.removeById(ids);
    const response = await PaIUnitRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
