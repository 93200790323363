/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {cloneElement, useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';

import cl from 'classnames';

import {DRAGGING_TYPE} from '../_utils';

import type {XYCoord} from 'dnd-core';
import type {CheckStepsDnDContainerProps} from '../_utils';

import styles from './CheckStepDnDContainer.module.scss';

export const CheckStepDnDContainer = (props: CheckStepsDnDContainerProps) => {
  const { children, isDraggable, step, stepIndex, onMoveStep } = props;

  const dragRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: DRAGGING_TYPE,
    item: () => {
      return {
        source: step,
        sourceIndex: stepIndex,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return !!isDraggable;
    },
  });

  const [{ handlerId, isOver }, drop] = useDrop({
    accept: DRAGGING_TYPE,

    collect: (monitor) => ({
      isOver: monitor.isOver(),
      handlerId: monitor.getHandlerId(),
    }),

    hover: (item: any, monitor) => {
      if (!dragRef.current) return;

      const dragIndex = item?.sourceIndex;
      const hoverIndex = stepIndex;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMoveStep?.(dragIndex, hoverIndex);

      item.sourceIndex = hoverIndex;
    },
  });

  drag(dragRef);
  drop(preview(dropRef));

  const classes = cl(styles.container, {
    [styles.containerIsDragging]: isDragging,
    [styles.containerIsOver]: isOver,
  });

  return (
    <div ref={dropRef} className={classes} data-handler-id={handlerId}>
      {cloneElement(children, {
        dragRef: dragRef,
        ...children.props,
      })}
    </div>
  );
};
