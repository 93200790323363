import React, { memo } from 'react';

import { AssetCount } from './AssetCount';
import { AssigneeDropdown } from './AssigneeDropdown';
import { CheckOutDropdown } from './CheckOutDropdown';
import { Delete } from './Delete';
import { SiteDropdown } from './SiteDropdown';
import { StatusDropdown } from './StatusDropdown';

import './LeftItems.scss';

export const LeftItems = memo(() => {
  return (
    <div className="asset-toolbar-left-side">
      <AssetCount />
      <CheckOutDropdown />
      <AssigneeDropdown />
      <SiteDropdown />
      <StatusDropdown />
      <Delete />
    </div>
  );
});
