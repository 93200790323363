import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getAuthorizationNames } from '@store/actions/extra/Authorization';
import { getRoleById } from '@store/actions/user/Role';
import { selectAuthorizationFunctionList } from '@store/selectors/extra/Authorization';
import { selectRole } from '@store/selectors/users/Role';

import type { IOutletRole } from '@constants/OutletContextEntityPages';
import type { CreateRoleFormDto } from '@model/users/Role';

function Role() {
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();
  const role = useAppSelector(selectRole);

  const condition = !role || role.roleId !== +decryptedId;
  const authorizationNames = useAppSelector(selectAuthorizationFunctionList);

  useSetPageInfo({
    defaultPagePath: paths.roles.default,
    pageTitle: 'pages_plural.Roles',
  });

  useEffect(() => {
    if (condition) {
      dispatch(getRoleById(decryptedId));
    }
    if (!authorizationNames.length) {
      dispatch(getAuthorizationNames());
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateRoleFormDto = {
    name: role.name,
    description: role.description,
    functionList: role.roleFunctionNameObject,
  };

  return <Outlet context={{ initialValues, entity: role } as IOutletRole} />;
}

export default Role;
