import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStepsContext } from '@components/providers/StepsProvider';
import { Steps } from '@shared/UI';

const MaintenanceSteps = () => {
  const { step } = useStepsContext();

  const { t } = useTranslation();

  const items = [
    {
      title: t('titles.General'),
    },
    {
      title: t('titles.Work_Orders'),
    },
    {
      title: t('titles.Parts'),
    },
  ];

  return (
    <Steps
      current={step}
      progressDot
      style={{
        maxWidth: '821px',
      }}
      items={items}
    />
  );
};

export { MaintenanceSteps };
