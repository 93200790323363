import history from '@routes/history';
import { paths } from '@routes/paths';
import LocaleStorage from '@services/LocaleStorage';
import { notificationController } from '@services/Notifications';
import axios from 'axios';

import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export type CustomResponse<T, A = unknown> = {
  resultStatus: boolean;
  resultObject: T;
  languageKeyword: string;
} & A;

export type ApiResponse<T, A = unknown> = AxiosResponse<CustomResponse<T, A>>;
export type ApiError = AxiosResponse<CustomResponse<null>>;

export type ApiErrorType = string | AxiosError | Error | ApiError | unknown;

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const authInterceptor = (config: AxiosRequestConfig) => {
  const authToken = LocaleStorage.handleAuthToken();

  if (authToken && config.headers) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
};

export const requestInterceptor = api.interceptors.request.use(authInterceptor, (error) => {
  return Promise.reject(error);
});

export const responseInterceptor = api.interceptors.response.use(
  (response) => {
    if (!response.data?.resultStatus) {
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      LocaleStorage.removeAuthToken();
      history.replace(paths.login.default);
      notificationController.error({
        description: error.response.data.languageKeyword,
      });
    }
    return Promise.reject(error);
  }
);
