import { NoResultsIcon } from "@icon/icon-components";

import './NoResults.scss';

const NoResults = () => {

  return (
    <div className="no-results-block">
      <NoResultsIcon />
      <p>No results yet</p>
    </div>
  )
}

export { NoResults };