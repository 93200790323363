import { checkStepKeys } from '@components/common/CheckStepItem/_utils';
import { v4 as uuidv4 } from 'uuid';

import { mapWorkStepOptionsToCheckStepOptions } from './mapWorkStepOptionsToCheckStepOptions';

import type { ChecklistStepTypeKeys } from '@components/common';
import type { MaintenanceWorkStepModel } from '@model/management/Maintenance';
import type { CheckStepItem } from '../../types';

export const mapWorkStepsToCheckSteps = (workSteps: MaintenanceWorkStepModel[]) => {
  return workSteps.map((step): CheckStepItem => {
    const { workSteps: workStep } = step;
    let baseStep: CheckStepItem = {
      stepKey: workStep.stepTypeId as ChecklistStepTypeKeys,
      hasError: false,
      isNew: false,
      isUpdate: false,
      isRemoved: false,
      title: workStep.stepName,
      rowId: workStep.rowId,
      value: '',
      uuid: uuidv4(),
      imagesList: [],
      optionsList: [],
      externalId: workStep.workStepsId,
      isCompleted: false,
      isActive: false,
    };

    if (workStep.stepTypeId === checkStepKeys.multiple) {
      baseStep = {
        ...baseStep,
        optionsList: mapWorkStepOptionsToCheckStepOptions(workStep.multipleChoiceListForWorkSteps),
      };
    }

    return baseStep;
  });
};
