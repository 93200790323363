import { useCallback } from 'react';

export function useClassPrefix(prefix: string) {
  const withPrefix = useCallback(
    (suffix: string) => {
      return `${prefix}-${suffix}`;
    },
    [prefix]
  );

  return { prefix, withPrefix };
}
