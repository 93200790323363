export enum AccessKey {
  Asset,
  Maintenance,
  PaI,
  CountPlan,

  PaIAttributes,

  AssetAttributes,

  MaintenanceAttributes,

  Company,

  Vendor,

  Department,

  Site,

  ExitType,

  CostCenter,

  Contract,

  User,

  UserGroup,

  Role,

  Authorization,
  Title
}
