import React from 'react';
import { useTranslation } from 'react-i18next';

import { modalSizes } from '@constants/modalSizes';
import { Button } from '@shared/UI';
import { Modal as AntdModal } from 'antd';
import cl from 'classnames';

import type { IModalSizes } from '@constants/modalSizes';
import type { ButtonProps } from '@shared/UI';
import type { ModalProps as AntdModalProps } from 'antd';

import './BaseModal.scss';

export interface BaseModalProps extends AntdModalProps {
  size?: keyof IModalSizes;
}

function BaseModal(props: BaseModalProps) {
  const {
    children,
    className,
    footer,
    size = 'medium',
    onCancel,
    onOk,
    cancelText,
    okText,
    cancelButtonProps,
    okButtonProps,
    ...rest
  } = props;

  const modalSize = Object.entries(modalSizes).find((sz) => sz[0] === size)?.[1];

  const { t } = useTranslation();

  const baseModalClass = cl('custom-modal', className);

  const defaultFooter = [
    <Button key="cancel" onClick={onCancel} {...(cancelButtonProps as ButtonProps)}>
      {cancelText ? cancelText : t('titles.Cancel')}
    </Button>,
    <Button type="primary" key="ok" onClick={onOk} {...(okButtonProps as ButtonProps)}>
      {okText ? okText : t('titles.Ok')}
    </Button>,
  ];

  return (
    <AntdModal
      onCancel={onCancel}
      width={modalSize}
      className={baseModalClass}
      footer={footer ? footer : defaultFooter}
      {...rest}
    >
      {children}
    </AntdModal>
  );
}

export { BaseModal };
