import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { MaintenanceSteps } from '@components/views/Forms/managment/Maintenance/MaintenanceSteps';
import { useAppDispatch, useAppSelector, useIsCreatePage } from '@hooks';
import { TrashCan } from '@icon/icon-components';
import { Input, Option, PlusButton, Select } from '@shared/UI';
import { replaceStrToNum } from '@shared/utils/functions';
import { getPaIsTypeName } from '@store/actions/management/PaI';
import { selectMaintenanceStatus } from '@store/selectors/management/Maintenance';
import { selectPaIStatus, selectPaIsTypeNameListAllRecords } from '@store/selectors/management/PaI';

import type { CommonFormProps } from '@components/common';
import type { FormRule } from '@interfaces/interfaces';
import type { MpModelDto } from '@model/management/Maintenance';

const { List, Item, CaseField, useForm, useWatch } = CommonForm;

const FormPart = (props: CommonFormProps<MpModelDto[]>) => {
  const { onFinish: onFinishProps, initialValues, ...rest } = props;

  const isCreate = useIsCreatePage();

  const [form] = useForm<MpModelDto[]>();

  const { t } = useTranslation();

  const partValues = useWatch('parts', form) as MpModelDto[];

  const dispatch = useAppDispatch();

  const listPartAndInventories = useAppSelector(selectPaIsTypeNameListAllRecords);
  const statusPartAndInventories = useAppSelector(selectPaIStatus);

  const statusMaintenance = useAppSelector(selectMaintenanceStatus);

  useEffect(() => {
    if (!listPartAndInventories.length) {
      dispatch(getPaIsTypeName());
    }
  }, []);

  const optionsPAI = listPartAndInventories.map((pai) => ({
    value: pai.partsAndInventoriesId,
    label: `${pai.partsAndInventoriesCardName}  [${pai.sumQuantity || 0}]`,
  }));

  const isHiddenButtonAdd = optionsPAI.length === partValues?.length;

  const listInitialValues = !initialValues
    ? [{ partsAndInventoriesId: undefined, quantity: undefined }]
    : initialValues;

  /* ------------------------------- Filed validation rules ------------------------------ */
  const rulesPartName = [{ required: true }];
  const rulesQuantity = (key: number): FormRule[] => {
    return [
      ({ getFieldValue }) => ({
        validator(_, value) {
          const selectedPAI = getFieldValue(['parts', key, 'partsAndInventoriesId']) as number;
          const availableQuantity = listPartAndInventories.find(
            (pai) => pai.partsAndInventoriesId === selectedPAI
          )?.sumQuantity;
          if (
            availableQuantity !== undefined &&
            availableQuantity !== null &&
            value > availableQuantity
          ) {
            return Promise.reject(new Error(t('errors.Quantity_Cant_Be_Greater')));
          }
          return Promise.resolve();
        },
      }),
    ];
  };

  const onFinish = (dto: MpModelDto[]) => {
    const values = (dto as unknown as { parts: MpModelDto[] }).parts.reduce(
      (acc: MpModelDto[], item) => {
        if (!item.maintenancesPartsId) {
          acc.push({
            partsAndInventoriesId: item.partsAndInventoriesId,
            quantity: item.quantity,
          });
        } else {
          acc.push(item);
        }
        return acc;
      },
      []
    );
    onFinishProps?.(values);
  };

  const renderAdditionalHeader = isCreate ? <MaintenanceSteps /> : null;

  const loading = statusMaintenance === 'pending' || statusPartAndInventories === 'pending';

  return (
    <CommonForm
      form={form}
      additionalHeader={renderAdditionalHeader}
      alignAdditionalHeader="left"
      container={{
        style: {
          maxWidth: '1200px',
        },
      }}
      loading={loading}
      onFinish={onFinish}
      {...rest}
    >
      <List name="parts" initialValue={listInitialValues}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restFields }, idx) => (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  marginTop: idx === 0 ? 20 : 0,
                }}
                key={key}
              >
                <CaseField hiddenTitle md={10}>
                  <Item
                    label={t('titles.Name')}
                    name={[name, 'partsAndInventoriesId']}
                    rules={rulesPartName}
                    {...restFields}
                  >
                    <Select
                      placeholder={t('titles.Choose_Name', { name: t('titles.Part') })}
                      loading={statusPartAndInventories === 'pending'}
                    >
                      {optionsPAI.map((option) => {
                        const exist = partValues?.some(
                          (value) => value?.partsAndInventoriesId === option.value
                        );
                        return (
                          <Option disabled={exist} key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Item>
                </CaseField>

                <CaseField hiddenTitle md={10}>
                  <Item
                    label={t('titles.Quantity')}
                    name={[name, 'quantity']}
                    normalize={replaceStrToNum}
                    dependencies={['parts', name, 'partsAndInventoriesId']}
                    rules={rulesQuantity(name)}
                    {...restFields}
                  >
                    <Input placeholder={t('titles.Quantity')} />
                  </Item>
                </CaseField>

                <Item hidden name={[name, 'maintenancesPartsId']}>
                  <Input />
                </Item>

                <div
                  style={{
                    marginLeft: 20,
                    cursor: 'pointer',
                  }}
                >
                  <TrashCan onClick={() => remove(name)} />
                </div>
              </div>
            ))}
            {!isHiddenButtonAdd && (
              <CaseField>
                <PlusButton onClick={() => add()} />
              </CaseField>
            )}
          </>
        )}
      </List>
    </CommonForm>
  );
};

export { FormPart };
