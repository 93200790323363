import type { RootState } from '@store/rootReducer';

export const selectCostCenterState = (state: RootState) => state.CostCenterReducer;

export const selectCostCentersList = (state: RootState) =>
  selectCostCenterState(state).CostCentersList;

export const selectCostCentersFilteredList = (state: RootState) => 
  selectCostCenterState(state).CostCentersFilteredList;

export const selectCostCentersFilters = (state: RootState) => 
  selectCostCenterState(state).CostCenterFilters;

export const selectCostCenter = (state: RootState) => selectCostCenterState(state).CostCenter;

export const selectCostCenterStatus = (state: RootState) =>
  selectCostCenterState(state).CostCenterStatus;

export const selectCostCenterError = (state: RootState) =>
  selectCostCenterState(state).CostCenterError;
