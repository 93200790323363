import React from 'react';

import { HeartUkrFlag } from '@icon/icon-components';

import './CreatedBy.scss';

const CreatedBy = () => {
  return (
    <div className={'createdBy'}>
      <div className={'wrapper__icon'}>
        <HeartUkrFlag />
      </div>

      <p className={'text'}>
        <span>CREATED BY</span>
        &nbsp;
        <span>UKRAINIANS</span>
      </p>
    </div>
  );
};

export { CreatedBy };
