import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MaintenanceSwitcher } from '@components/common';
import { InputSearch, RangePicker } from '@shared/UI';
import { formatISO } from 'date-fns';

import type { ChangeEvent} from 'react';

interface HeaderProps {
  onChangeDates: (payload: { startDate: string; endDate: string }) => void;
  onChangeSearch: (value: string) => void;
  startDate: string;
  endDate: string;
  searchValue: string;
}

const Header = (props: HeaderProps) => {
  const {
    startDate,
    endDate,
    searchValue,
    onChangeDates,
    onChangeSearch: onChangeSearchProps,
  } = props;

  const { t } = useTranslation();

  const onChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onChangeSearchProps(value);
    },
    [onChangeSearchProps]
  );

  return (
    <div className="maintenance-kanban-header">
      <InputSearch
        placeholder={t('titles.Search')}
        className="maintenance-kanban-header-input"
        onChange={onChangeSearch}
        value={searchValue}
      />

      <MaintenanceSwitcher className="maintenance-kanban-header-switcher" />

      <RangePicker
        value={[new Date(startDate), new Date(endDate)]}
        allowClear={false}
        className="maintenance-kanban-header-range-picker"
        onChange={(date) => {
          if (date && date.length === 2) {
            const startDate = formatISO(date[0] as Date);
            const endDate = formatISO(date[1] as Date);
            onChangeDates({ startDate, endDate });
          }
        }}
      />
    </div>
  );
};

export { Header };
