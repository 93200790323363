import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormVendor } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletVendor } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateVendor } from '@store/actions/properties/Vendor';

import type { CreateVendorDto } from '@model/properties/Vendor';

const Edit = () => {
  const { initialValues, entity } = useOutletVendor();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { vendorAccess } = useGetAccess();

  const onFinish = async (dto: CreateVendorDto) => {
    try {
      const response = await dispatch(
        updateVendor({ ...dto, partnerId: entity.partnerId })
      ).unwrap();
      ApiNotifications.update(response.vendor.name);
      navigate(paths.vendor.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={vendorAccess.edit} redirectTo={paths.vendor.default}>
      <FormVendor
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
};

export default Edit;
