import React, { memo, useMemo } from 'react';

import { SubRoleItem } from '@components/common/RoleAuthorizationsList/SubRoleItem';

import type { RoleFunctionNameModel } from '@model/users/Role';

interface SubRolesListProps {
  authorizationsChildrenList: Array<RoleFunctionNameModel>;

  onChangeChild: (checked: boolean, id: number) => void;
}

export const SubRolesList = memo((props: SubRolesListProps) => {
  const { authorizationsChildrenList, onChangeChild } = props;

  const renderList = useMemo(
    () =>
      authorizationsChildrenList.map((childOption) => (
        <SubRoleItem
          onChangeChild={onChangeChild}
          key={childOption.roleFunctionNameId}
          authorizationChild={childOption}
        />
      )),
    [authorizationsChildrenList, onChangeChild]
  );

  return <ul>{renderList}</ul>;
});
