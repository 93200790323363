import type { UniqueValueArrayByKey } from '@shared/utils/types';

export const checkStepTypes = [
  {
    type: 'Text Field',
    key: 1,
  },
  {
    type: 'Multiple Choice',
    key: 2,
  },
  {
    type: 'Numeric Field',
    key: 3,
  },
  {
    type: 'Decimal Field',
    key: 4,
  },
  {
    type: 'Boolean Field(Yes/No)',
    key: 5,
  },
  {
    type: 'Take a Photo',
    key: 7,
  },
] as const;

export const checkStepKeys = {
  text: 1,
  multiple: 2,
  integer: 3,
  decimal: 4,
  boolean: 5,
  photo: 7,
} as const;

export type ChecklistStepTypeKeys = UniqueValueArrayByKey<typeof checkStepTypes, 'key'>;

export type ChecklistStepTypeNames = UniqueValueArrayByKey<typeof checkStepTypes, 'type'>;
