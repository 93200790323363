import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { Input, TextArea } from '@shared/UI';
import { selectCheckOutStatus } from '@store/selectors/properties/CheckOut';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateCheckOutDto } from '@model/properties/CheckOut';

const { CaseField, Item, useForm } = CommonForm;

function FormCheckOut(props: CommonFormProps<CreateCheckOutDto>) {
  const [form] = useForm<CreateCheckOutDto>();

  const {t} = useTranslation();

  const checkOutStatus = useAppSelector(selectCheckOutStatus);

  const rulesOfCode = useMemo(() => {
    return [
      {
        required: true,
      },
      {
        max: 50,
      },
    ];
  }, []);

  const rulesOfName = useMemo(() => {
    return [
      {
        required: true,
      },
      {
        max: 50,
      },
    ];
  }, []);

  return (
    <CommonForm form={form} loading={checkOutStatus === 'pending'} {...props}>
      <CaseField>
        <Item label={t('titles.Code')} name="code" rules={rulesOfCode}>
          <Input placeholder={t('titles.Code')}/>
        </Item>
        <Item label={t('titles.Name')} name="name" rules={rulesOfName}>
          <Input placeholder={t('titles.Name')}/>
        </Item>
        <Item label={t('titles.Description')} name="description">
          <TextArea placeholder={t('titles.Enter_Descr')}/>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export {FormCheckOut};
