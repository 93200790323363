import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { CopyIcon, EditingPen, TrashCan } from '@icon/icon-components';
import { DragHandle } from '@shared/UI';
import cl from 'classnames';

import type { Identifier, XYCoord } from 'dnd-core';

import './DraggableLining.scss';

export interface DraggableLiningProps {
  isFocus?: boolean;
  identifier?: string | number;
  index: number;
  count?: number | string;
  hiddenEditAction?: boolean;
  hiddenDeleteAction?: boolean;
  hiddenCopyAction?: boolean;
  isDraggable?: boolean;
  children?: React.ReactNode;
  onActionEdit?: () => void;
  onActionDelete?: () => void;
  onActionCopy?: () => void;
  onMoveItem?: (dragIndex: number, hoverIndex: number) => void;
}

/* The variable that is used to create the class names for the component. */
const defaultClassPrefix = 'draggable-lining';

/* The variable that is used for useDrag and useDrop in "type" and "accept" . */
const dragItemType = 'draggable-lining';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const DraggableLining = (props: DraggableLiningProps) => {
  const {
    isFocus,
    count,
    children,
    identifier,
    index,
    hiddenEditAction,
    hiddenDeleteAction,
    hiddenCopyAction,
    isDraggable = true,
    onMoveItem,
    onActionCopy,
    onActionDelete,
    onActionEdit,
  } = props;

  const dragRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: dragItemType,
    item: () => {
      return { identifier, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return !isFocus && isDraggable;
    },
  });
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: dragItemType,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      handlerId: monitor.getHandlerId(),
    }),
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      console.log(item);

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = dragRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onMoveItem?.(dragIndex, hoverIndex);

      // // Note: we're mutating the monitor item here!
      // // Generally it's better to avoid mutations,
      // // but it's good here for the sake of performance
      // // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const defaultClasses = cl(defaultClassPrefix, {
    [`${defaultClassPrefix}--focus`]: isFocus,
    [`${defaultClassPrefix}--dragging`]: isDragging,
  });

  const renderDragHandle = isDraggable && !isFocus ? <DragHandle /> : null;

  drag(drop(dragRef));

  return (
    <div className={defaultClasses} ref={dragRef} data-handler-id={handlerId}>
      <div className={cl(`${defaultClassPrefix}__handle`)}>{renderDragHandle}</div>
      <div className={cl(`${defaultClassPrefix}__content`)}>
        {count && <div className={cl(`${defaultClassPrefix}__count`)}>{count}</div>}
        <div className={cl(`${defaultClassPrefix}__content-value`)}>{children}</div>
      </div>
      <div className={cl(`${defaultClassPrefix}__actions`)}>
        {!hiddenEditAction && (
          <span>
            <EditingPen onClick={onActionEdit} />
          </span>
        )}
        {!hiddenCopyAction && (
          <span>
            <CopyIcon onClick={onActionCopy} />
          </span>
        )}
        {!hiddenDeleteAction && (
          <span>
            <TrashCan fill="#E0E0E0" onClick={onActionDelete} />
          </span>
        )}
      </div>
    </div>
  );
};

export { DraggableLining };
