import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getPaICardById } from '@store/actions/properties/PaI/Card';
import { selectPaICard } from '@store/selectors/properties/PaI/Card';

import type { IOutletPartAndInventoryName } from '@constants/OutletContextEntityPages';
import type { CreatePaICardDto } from '@model/properties/PaI/Card';

function PartAndInventoryName() {
  const partAndInventoryName = useAppSelector(selectPaICard);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition =
    !partAndInventoryName || partAndInventoryName.partsAndInventoriesCardId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getPaICardById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreatePaICardDto = {
    partsAndInventoriesCardCode: partAndInventoryName.partsAndInventoriesCardCode,
    partsAndInventoriesCardName: partAndInventoryName.partsAndInventoriesCardName,
    description: partAndInventoryName.description,
    enumTrackingMethode: partAndInventoryName.enumTrackingMethode,
    minimumStockLevel: partAndInventoryName.minimumStockLevel,
    partsAndInventoriesCategoryId: partAndInventoryName.partsAndInventoriesCategoryId,
    partsAndInventoriesUnitId: partAndInventoryName.partsAndInventoriesUnitId,
  };

  return (
    <Outlet
      context={{ initialValues, entity: partAndInventoryName } as IOutletPartAndInventoryName}
    />
  );
}

export default PartAndInventoryName;
