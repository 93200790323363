import React, { memo } from 'react';

import type { SVGProps } from 'react';

export const Arrow = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 14L7.5 9"
      stroke="#374957"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.5 14L17.5 9" stroke="#374957" strokeWidth="2" strokeLinecap="round" />
  </svg>
));
