import { IterationMonthType } from '@constants/IterationMonthType';
import { IterationValue } from '@constants/iterationValue';
import moment from 'moment';

import { daysOfWeekList } from './daysOfWeekList';

import type { IterationModalState } from './types';

const startDate = moment();

const endDate = startDate.clone().add(1, 'month');

const ROUNDING = 20 * 60 * 1000;

const startTime = moment(Math.ceil(+startDate.clone() / ROUNDING) * ROUNDING);

export const defaultIterationState: IterationModalState = {
  startDate,
  startTime,
  repeatNumber: 1,
  repeatType: IterationValue.NEVER,
  daysOfWeek: daysOfWeekList,
  monthType: IterationMonthType.ByDay,
  endDate,
};
