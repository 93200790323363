import './ModalFormHeader.scss';

interface ModalFormHeaderProps {
    title?: string;
    step: number;
}

const ModalFormHeader = (props: ModalFormHeaderProps) => {
    const { title, step } = props;

    return (
        <div className='modal-form-header'>
            <h5>
                {title}
            </h5>
            <div>
                Step {step}/2
            </div>
        </div> 
    )
}

export { ModalFormHeader };