import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import { selectPaIModelStatus } from '@store/selectors/properties/PaI/Model';
import { selectPaIStatusesList } from '@store/selectors/properties/PaI/Status';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { PaIStatusModel } from '@model/properties/PaI/Status';

const columns: ColumnsTableType<PaIStatusModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'partsAndInventoriesStatusCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.partsAndInventoriesStatusCode, b.partsAndInventoriesStatusCode),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

const PartAndInventoryStatuses = () => {
  const { t } = useTranslation();

  const listPartAndInventoryStatuses = useAppSelector(selectPaIStatusesList);
  const statusPartAndInventoryStatus = useAppSelector(selectPaIModelStatus);

  const { paiAttrAccess } = useGetAccess();

  const csvData = useMemo(
    () =>
      listPartAndInventoryStatuses.map((model) => ({
        name: model.name,
        code: model.partsAndInventoriesStatusCode,
      })),
    [listPartAndInventoryStatuses]
  );

  return (
    <BasicTablePage
      table={{
        loading: statusPartAndInventoryStatus === 'pending',
        dataSource: listPartAndInventoryStatuses,
        columns: columns,
        rowKey: (record) => record.partsAndInventoriesStatusId,
        dataSourceName: t('pages_plural.PaI_Attr_Statuses'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Status') }),
        csvConfig: {
          data: csvData,
          fileName: 'part-and-inventory-statuses',
          headers: [
            { label: 'code', key: 'code' },
            { label: 'name', key: 'name' },
          ],
        },
        newButtonProps: {
          disabled: !paiAttrAccess.create,
        },
      }}
    />
  );
};

export { PartAndInventoryStatuses };
