import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Select } from '@shared/UI';
import { getCities, getCountries } from '@store/actions/extra/Definition';
import { selectDefinitionState } from '@store/selectors/extra/Definition';

import type { FormRule } from '@interfaces/interfaces';
import type { CityModel, CountryModel } from '@model/extra/Definition';
import type { CreateCompanyDto } from '@model/users/Company';
import type { SelectProps } from '@shared/UI';

interface SelectCountryAndCityProps {
  nameCity?: string;
  nameCountry?: string;

  labelCity?: string;
  labelCountry?: string;

  rulesCity?: FormRule[];
  rulesCountry?: FormRule[];
}

const {Item, useFormInstance} = CommonForm;

const SelectCountryAndCity = (props: SelectCountryAndCityProps) => {
  const {
    nameCity,
    nameCountry,
    labelCountry,
    labelCity,
    rulesCountry,
    rulesCity
  } = props;

  const {t} = useTranslation();

  const formInstance = useFormInstance();
  const dispatch = useAppDispatch();

  const {
    countriesList,
    citiesList,
    definitionStatus
  } = useAppSelector(selectDefinitionState);

  useEffect(() => {
    if (!countriesList.length) {
      dispatch(getCountries());
    }
    if (!citiesList.length) {
      dispatch(getCities());
    }
  }, []);

  const onChangeCountryID = (value: number, option: CountryModel) => {
    formInstance.setFieldsValue({
      countryId: value,
      cityId: (option as typeof countriesList[number])?.cities[0]?.cityId,
    });
    formInstance.validateFields(['cityId', 'countryId']);
  };

  const fieldNamesOfCountry = useMemo(() => ({
    value: 'countryId',
    label: 'name'
  }), []);
  const fieldNamesOfCity = useMemo(() => ({
    value: 'cityId',
    label: 'name'
  }), []);

  const rulesOfCountry = useMemo((): FormRule[] => {
    return rulesCountry || [{required: true}];
  }, [rulesCountry]);
  const rulesOfCity = useMemo((): FormRule[] => {
    return rulesCity || [{required: true}];
  }, [rulesCity]);

  const getFilterOptions = (
      inputValue: string,
      option: CityModel | CountryModel | undefined
  ) => {
    return (option?.name.toLowerCase() || '').includes(inputValue.toLowerCase())
  }

  return (
      <>
        <Item
            label={labelCountry || t('titles.Country')}
            name={nameCountry || 'countryId'}
            rules={rulesOfCountry}
        >
          <Select
              options={countriesList}
              loading={definitionStatus === 'pending'}
              placeholder={t('titles.Choose_Name', {name: t('titles.Country')})}
              onChange={onChangeCountryID as SelectProps['onChange']}
              filterOption={getFilterOptions}
              fieldNames={fieldNamesOfCountry}
              placement='bottomLeft'
          />
        </Item>
        <Item
            shouldUpdate={(prevValue: CreateCompanyDto, nextValue: CreateCompanyDto) =>
                prevValue.cityId !== nextValue.cityId
            }
            noStyle
        >
          {({getFieldValue}) => {
            const selectedCountry = getFieldValue('countryId') as number;
            let cities = citiesList;

            if (selectedCountry) {
              cities = cities.filter((city) => {
                return city.countryId === selectedCountry;
              });
            }

            return (
                <Item
                    label={labelCity || t('titles.City')}
                    name={nameCity || 'cityId'}
                    rules={rulesOfCity}
                >
                  <Select
                      options={cities}
                      loading={definitionStatus === 'pending'}
                      placeholder={t('titles.Choose_Name', {name: t('titles.City')})}
                      filterOption={getFilterOptions}
                      fieldNames={fieldNamesOfCity}
                      placement='bottomLeft'
                      className={'selectm'}
                  />
                </Item>
            );
          }}
        </Item>
      </>
  );
};

export {SelectCountryAndCity};
