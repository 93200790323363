import type { RootState } from '@store/rootReducer';

export const selectPaICardState = (state: RootState) => state.PaICardReducer;

export const selectPaICardsList = (state: RootState) => selectPaICardState(state).PaICardsList;

export const selectPaICard = (state: RootState) => selectPaICardState(state).PaICard;

export const selectPaICardStatus = (state: RootState) => selectPaICardState(state).PaICardStatus;

export const selectPaICardError = (state: RootState) => selectPaICardState(state).PaICardError;
