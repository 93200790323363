/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { REQUIRED_RULE } from '@constants/formRules';
import { Input, Select } from '@shared/UI';

import type { AsAtCategoryModel } from '@model/properties/AsAt/Category';
import type { AsAtStatusModel } from '@model/properties/AsAt/Status';
import type { VendorModel } from '@model/properties/Vendor';
import type { ItemRules, SelectFieldNames } from '@shared/utils/types';

interface LeftSectionProps {
  assetCategoriesList: Array<Omit<AsAtCategoryModel, 'childNonCurrAssetCardCategories'>>;
  onAssetCategoryOpenModal: () => void;
  assetStatusesList: Array<AsAtStatusModel>;
  onAssetStatusOpenModal: () => void;
  vendorsList: Array<VendorModel>;
  onVendorOpenModal: () => void;
}

const { CaseField, Item, useFormInstance } = CommonForm;

// region --- Item rules

const assetCategoryRules: ItemRules = [REQUIRED_RULE];

const vendorRules: ItemRules = [REQUIRED_RULE];

const assetStatusRules: ItemRules = [REQUIRED_RULE];

// endregion --- Item rules

// region --- Field names

const assetCategoryNames: SelectFieldNames<
  Omit<AsAtCategoryModel, 'childNonCurrAssetCardCategories'>
> = {
  label: 'name',
  value: 'nonCurrAssetCardCategoryId',
};

const assetStatusNames: SelectFieldNames<AsAtStatusModel> = {
  label: 'name',
  value: 'nonCurrAssetStatusId',
};

const vendorNames: SelectFieldNames<VendorModel> = {
  label: 'name',
  value: 'partnerId',
};

// endregion --- Field names

export const LeftSection = memo((props: LeftSectionProps) => {
  const {
    assetCategoriesList,
    onAssetCategoryOpenModal,
    vendorsList,
    assetStatusesList,
    onAssetStatusOpenModal,
    onVendorOpenModal,
  } = props;

  const { t } = useTranslation();

  const form = useFormInstance();

  const handleCategoryChange = (value: number | null) => {
    form.setFieldValue('nonCurrAssetCardId', null);

    return value;
  };

  return (
    <CaseField md={24} lg={7}>
      <Item
        label={t('titles.Barcode')}
        name="barcode"
        rules={[
          {
            required: true,
          },
          {
            max: 50,
          },
        ]}
      >
        <Input placeholder="FGRS45678" />
      </Item>
      <Item
        label={t('titles.Card_Category')}
        name="nonCurrAssetCardCategoryId"
        rules={assetCategoryRules}
        getValueFromEvent={handleCategoryChange}
      >
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Card_Category') })}
          options={assetCategoriesList}
          fieldNames={assetCategoryNames}
          isCreatable
          creatableAction={onAssetCategoryOpenModal}
        />
      </Item>

      <Item label={t('titles.Vendor')} name="partnerId" rules={vendorRules}>
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Vendor') })}
          options={vendorsList}
          fieldNames={vendorNames}
          isCreatable
          creatableAction={onVendorOpenModal}
        />
      </Item>

      <Item label={t('titles.Status')} name="statusId" rules={assetStatusRules}>
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Status') })}
          options={assetStatusesList}
          fieldNames={assetStatusNames}
          isCreatable
          creatableAction={onAssetStatusOpenModal}
        />
      </Item>
    </CaseField>
  );
});
