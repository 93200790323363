import type { RootState } from '@store/rootReducer';

export const selectMaintenanceResultState = (state: RootState) => state.MaintenanceResultReducer;

export const selectMaintenanceResultsList = (state: RootState) =>
  selectMaintenanceResultState(state).maintenanceResultsList;

export const selectMaintenanceSchedulesList = (state: RootState) =>
  selectMaintenanceResultState(state).schedulesList;

export const selectMaintenanceResult = (state: RootState) =>
  selectMaintenanceResultState(state).maintenanceResult;

export const selectMaintenancByHistoryResult = (state: RootState) =>
  selectMaintenanceResultState(state).maintenanceHistoryResult;

export const selectMaintenanceResultStatus = (state: RootState) =>
  selectMaintenanceResultState(state).maintenanceResultStatus;

export const selectMaintenanceResultError = (state: RootState) =>
  selectMaintenanceResultState(state).maintenanceResultError;
