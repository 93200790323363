import { api } from '@services/Axios';

import type { AssetModel } from '@model/management/Asset';
import type {
  CountPlanModel,
  CountPlanResultModel,
  CreateCountPlanDto,
  FilterCountPlanDto,
  UpdateCountPlanDto,
} from '@model/management/CountPlan';
import type { ApiResponse } from '@services/Axios';

const CountPlanEndpoint = '/PrdCountingPlans';

export const CountPlanRepository = {
  async fetchAll() {
    const response: ApiResponse<CountPlanModel[]> = await api.get(
      `${CountPlanEndpoint}/GetCountingPlansList`
    );
    return response.data;
  },
  async add(dto: CreateCountPlanDto) {
    const response: ApiResponse<CountPlanModel> = await api.post(
      `${CountPlanEndpoint}/AddCountingPlan`,
      dto
    );
    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<CountPlanModel> = await api.get(
      `${CountPlanEndpoint}/GetCountingPlansByPrdCountPlanId/${id}`
    );

    return response.data;
  },

  async update(dto: UpdateCountPlanDto) {
    const response: ApiResponse<CountPlanModel> = await api.post(
      `${CountPlanEndpoint}/UpdateCountingPlan`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${CountPlanEndpoint}/RemoveByIdList`, {
      CountingPlanIds: ids,
    });

    return response.data;
  },
  async fetchAllCountPlanResults(filterOptions: FilterCountPlanDto) {
    const response: ApiResponse<
      CountPlanResultModel[] | AssetModel[],
      { totalRecords: number; totalPage: number }
    > = await api.post('PrdCountingResults/GetPrdCountingResultsById', filterOptions);

    return response.data;
  },
  async updateCountPlanResult(dto: { barcodes: string[]; prdCountPlanId: number }) {
    const response: ApiResponse<null> = await api.post(
      'PrdCountingResults/UpdateFindDifferentSitesDuringTheCountingNonCurrAssets',
      dto
    );
    return response.data;
  },
};
