import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common/BasicTablePage';
import { useAppDispatch, useAppSelector, useGetAccess } from '@hooks';
import { Select } from '@shared/UI';
import { checkFilterValues, compare } from '@shared/utils/functions';
import { getCostCenters, getCostCentersByFilter } from '@store/actions/properties/CostCenter';
import {
  selectCostCentersFilteredList,
  selectCostCentersFilters,
  selectCostCentersList,
  selectCostCenterStatus,
} from '@store/selectors/properties/CostCenter';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { CostCenterModel, FilterCostCenterDto } from '@model/properties/CostCenter';

const columns: ColumnsTableType<CostCenterModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'costCenterCode',
    key: 'costCenterCode',
    ellipsis: true,
    sorter: (a, b) => compare(a.costCenterCode, b.costCenterCode),
  },
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

function CostCenters() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const listCostCenter = useAppSelector(selectCostCentersList);
  const filteredListCostCenter = useAppSelector(selectCostCentersFilteredList);
  const statusCostCenter = useAppSelector(selectCostCenterStatus);
  const filters = useAppSelector(selectCostCentersFilters);

  const { costCenterAccess } = useGetAccess();

  const dataSource = useMemo(
    () => (Boolean(filteredListCostCenter.length) ? filteredListCostCenter : listCostCenter),
    [filteredListCostCenter, listCostCenter]
  );

  const filterProps = {
    items: [
      {
        label: t('titles.Name'),
        name: 'nameArray',
        item: (
          <Select
            options={listCostCenter}
            maxTagCount={4}
            mode="tags"
            fieldNames={{ label: 'name', value: 'name' }}
          />
        ),
      },
      {
        label: t('titles.Code'),
        name: 'costCenterCodeArray',
        item: (
          <Select
            options={listCostCenter}
            maxTagCount={4}
            mode="tags"
            fieldNames={{ label: 'costCenterCode', value: 'costCenterCode' }}
          />
        ),
      },
    ],
    applyAction: (values: FilterCostCenterDto) => {
      if (!checkFilterValues(values)) {
        getCostCentersList();
        return;
      }

      dispatch(getCostCentersByFilter(values));
    },
    resetAction: () => {
      if (checkFilterValues(filters)) {
        getCostCentersList();
      }
    },
    initialValuesForm: {
      nameArray: filters?.nameArray,
      codeArray: filters?.costCenterCodeArray,
    },
  };

  function getCostCentersList() {
    dispatch(getCostCenters());
  }

  return (
    <BasicTablePage
      table={{
        columns,
        dataSource: dataSource,
        loading: statusCostCenter === 'pending',
        rowKey: (record) => record.costCenterId,
        dataSourceName: t('pages_plural.Cost_Centers'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Cost_Center') }),
        csvConfig: {
          data: listCostCenter,
          fileName: 'cost-centers.csv',
          headers: [
            {
              label: 'Code',
              key: 'costCenterCode',
            },
            {
              label: 'Name',
              key: 'name',
            },
          ],
        },
        filterProps,
        newButtonProps: {
          disabled: !costCenterAccess.create,
        },
      }}
    />
  );
}

export { CostCenters };
