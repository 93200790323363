import React, { memo } from 'react';

import { checkStepKeys, CheckStepProvider } from './_utils/';
import { Actions } from './Actions';
import { Checklist } from './Checklist';
import { Title } from './Title';
import { TitleTakePhoto } from './TitleTakePhoto';
import { WorkStep } from './WorkStep';
import { WorkStepAction } from './WorkStepAction';
import { Wrapper } from './Wrapper';

import type { CheckStepItemProps } from './_utils';

export const CheckStepItem = memo((props: CheckStepItemProps) => {
  const {
    stepKey,
    step,
    actions,
    type = 'checklist',
    canActivate = true,
    isDraggable = true,
    isVisibleActions = true,
    isActiveWorkStep = false,
    isDoneWorkStep = false,
    onSave,
    onUpdate,
    dragRef,
  } = props;

  if (step.isRemoved) return null;

  return (
    <CheckStepProvider
      stepKey={stepKey}
      step={step}
      actions={actions}
      canActivate={canActivate}
      isDraggable={isDraggable}
      isVisibleActions={isVisibleActions}
      type={type}
      isActiveWorkStep={isActiveWorkStep}
    >
      <Wrapper dragRef={dragRef}>
        {step.stepKey === checkStepKeys.photo ? <TitleTakePhoto /> : <Title />}
        {type === 'checklist' && step.stepKey !== checkStepKeys.photo && <Checklist />}
        {type === 'workStep' && <WorkStep />}
        {step.stepKey !== checkStepKeys.photo && isVisibleActions && type === 'checklist' && (
          <Actions />
        )}
        {type === 'workStep' && isActiveWorkStep && (
          <WorkStepAction isDoneWorkStep={isDoneWorkStep} onSave={onSave} onUpdate={onUpdate} />
        )}
      </Wrapper>
    </CheckStepProvider>
  );
});
