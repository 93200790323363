import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getCheckOuts } from '@store/actions/properties/CheckOut';
import { selectCheckOutsList } from '@store/selectors/properties/CheckOut';

function EntryCheckOut() {
  const { t } = useTranslation();

  useSetPageInfo({
    defaultPagePath: paths.checkOut.default,
    pageTitle: t('pages_single.Check_Out'),
  });

  const listCheckOut = useAppSelector(selectCheckOutsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listCheckOut.length) {
      dispatch(getCheckOuts());
    }
  }, []);

  return <Outlet />;
}

export default EntryCheckOut;
