import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getAsAtCategories } from '@store/actions/properties/AsAt/Category';
import { selectAsAtCategoriesList } from '@store/selectors/properties/AsAt/Category';

function EntryAssetCategory() {
  useSetPageInfo(
    {
      defaultPagePath: paths.assetAttribute.tabs.category.default,
      pageTitle: 'pages_single.Asset_Attr',
    },
    {
      copyTabs: true,
    }
  );
  const assetCategoryList = useAppSelector(selectAsAtCategoriesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!assetCategoryList.length) {
      dispatch(getAsAtCategories());
    }
  }, []);

  return <Outlet />;
}

export default EntryAssetCategory;
