import type { RootState } from '@store/rootReducer';

export const selectExitTypeState = (state: RootState) => state.ExitTypeReducer;

export const selectExitTypesList = (state: RootState) => selectExitTypeState(state).exitTypesList;

export const selectExitTypesFilteredList = (state: RootState) => 
    selectExitTypeState(state).exitTypesFilteredList;

export const selectExitTypesFilters = (state: RootState) => 
    selectExitTypeState(state).exitTypeFilters;

export const selectExitType = (state: RootState) => selectExitTypeState(state).exitType;

export const selectExitTypeStatus = (state: RootState) => selectExitTypeState(state).exitTypeStatus;

export const selectExitTypeError = (state: RootState) => selectExitTypeState(state).exitTypeError;
