import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getCheckOutById } from '@store/actions/properties/CheckOut';
import { selectCheckOut } from '@store/selectors/properties/CheckOut';

import type { IOutletCheckOut } from '@constants/OutletContextEntityPages';
import type { CreateCheckOutDto } from '@model/properties/CheckOut';

function CheckOut() {
  const checkOut = useAppSelector(selectCheckOut);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !checkOut || checkOut.checkFactorId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getCheckOutById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateCheckOutDto = {
    code: checkOut.code,
    name: checkOut.name,
    description: checkOut.description,
  };

  return <Outlet context={{ initialValues, entity: checkOut } as IOutletCheckOut} />;
}

export default CheckOut;
