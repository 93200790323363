import React, { memo } from 'react';

import { IterationMonthType } from '@constants/IterationMonthType';
import { Radio } from '@shared/UI';
import { getByDayValue, getByWeekOfMonthValue } from '@shared/utils/functions';
import { Space } from 'antd';

import type { RadioChangeEvent } from 'antd';
import type { Moment } from 'moment';

interface MonthTypeRadiosProps {
  value: IterationMonthType;

  startDate: Moment;

  onChange: (value: IterationMonthType) => void;
}

export const MonthTypeRadios = memo((props: MonthTypeRadiosProps) => {
  const { startDate, value, onChange } = props;

  const handleChange = (event: RadioChangeEvent) => {
    const { value } = event.target;

    onChange(value as IterationMonthType);
  };

  return (
    <Radio.Group value={value} onChange={handleChange}>
      <Space direction="vertical">
        <Radio value={IterationMonthType.ByDay}>{getByDayValue(startDate)}</Radio>
        <Radio value={IterationMonthType.ByWeek}>{getByWeekOfMonthValue(startDate)}</Radio>
      </Space>
    </Radio.Group>
  );
});
