import type { RootState } from '@store/rootReducer';

export const selectDashboardState = (state: RootState) => state.DashboardReducer;

export const selectDashboardData = (state: RootState) => selectDashboardState(state).dashboardData;

export const selectDashboardStatus = (state: RootState) =>
  selectDashboardState(state).dashboardStatus;

export const selectDashboardError = (state: RootState) =>
  selectDashboardState(state).dashboardError;
