import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tabs } from '@shared/UI';

import type { TabType } from '@constants/pageInfo';

import './ContentTabs.scss';

interface ContentTabsProps {
  tabs: TabType[];
  strictCompareTabs?: boolean;
}

function ContentTabs(props: ContentTabsProps) {
  const { tabs } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);

  useEffect(() => {
    const includedTabPath = tabs.find((tab) => pathname.includes(tab.key));
    if (includedTabPath) {
      setActiveTab(() => includedTabPath.key);
    }
  }, [tabs, pathname]);

  const onTabClick = (key: string) => {
    const path = tabs.find((tab) => tab.key === key);

    if (path) {
      navigate(path.default);
    }

    setActiveTab(key);
  };

  const tabsItems = tabs
    ? tabs.map((tab) => ({
        label: t(tab.keyTranslations),
        key: tab.key,
      }))
    : undefined;

  return (
    <Tabs
      size="middle"
      className="content-tabs"
      activeKey={activeTab}
      type="card"
      onTabClick={onTabClick}
      items={tabsItems}
    />
  );
}

export { ContentTabs };
