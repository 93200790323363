import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import { selectPaIBrandsList, selectPaIBrandStatus } from '@store/selectors/properties/PaI/Brand';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { PaIBrandModel } from '@model/properties/PaI/Brand';

const columns: ColumnsTableType<PaIBrandModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'partsAndInventoriesCardBrandCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) =>
      compare(a.partsAndInventoriesCardBrandCode, b.partsAndInventoriesCardBrandCode),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

const PartAndInventoryBrands = () => {
  const {t} = useTranslation();

  const {paiAttrAccess} = useGetAccess();

  const listPartAndInventoryBrand = useAppSelector(selectPaIBrandsList);
  const statusPartAndInventoryBrand = useAppSelector(selectPaIBrandStatus);

  return (
    <BasicTablePage
      table={{
        loading: statusPartAndInventoryBrand === 'pending',
        dataSource: listPartAndInventoryBrand,
        columns: columns,
        rowKey: (record) => record.partsAndInventoriesCardBrandId,
        dataSourceName: t('pages_plural.PaI_Attr_Brands'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', {name: t('pages_single.PaI_Attr_Brand')}),
        csvConfig: {
          data: listPartAndInventoryBrand,
          fileName: 'part-and-inventory-brands',
          headers: [
            {label: 'code', key: 'partsAndInventoriesCardBrandCode'},
            {label: 'name', key: 'name'},
          ],
        },
        newButtonProps: {
          disabled: !paiAttrAccess.create
        }
      }}
    />
  );
};

export {PartAndInventoryBrands};
