import React from 'react';

import { Form } from 'antd';
import cl from 'classnames';

import type { FormItemProps } from 'antd';

import './Item.scss';

export interface ItemProps<Values = any> extends FormItemProps<Values> {
  disabledItem?: boolean;
}

const { Item: AntdItem } = Form;

function Item(props: ItemProps) {
  const { className, disabledItem, ...rest } = props;

  const classes = cl(
    'custom-base-form__item',
    {
      'custom-base-form__item--disabled': disabledItem,
    },
    className
  );

  return <AntdItem className={classes} {...rest} />;
}

export { Item };
