import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaIPropertyRepository } from '@repositories';

import type {
  CreatePaIPropertyDto,
  PaIPropertyModel,
  PaIPropertyTypeModel,
  UpdatePaIPropertyDto,
} from '@model/properties/PaI/Property';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getPaIProperties = createAsyncThunk<PaIPropertyModel[], void, { rejectValue: string }>(
  'PaIProperty/getPaIProperties',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PaIPropertyRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getPaIPropertyTypes = createAsyncThunk<
  PaIPropertyTypeModel[],
  void,
  { rejectValue: string }
>('PaIProperty/getPaIPropertyTypes', async (_, { rejectWithValue }) => {
  try {
    const response = await PaIPropertyRepository.fetchPartAndInventoryTypes();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getPaIPropertyById = createAsyncThunk<
  PaIPropertyModel,
  string,
  { rejectValue: string }
>('PaIProperty/getPaIPropertyById', async (id, { rejectWithValue }) => {
  try {
    const response = await PaIPropertyRepository.fetchById(id);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addPaIProperty = createAsyncThunk<
  PaIPropertyModel,
  CreatePaIPropertyDto,
  { rejectValue: string }
>('PaIProperty/addPaIProperty', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaIPropertyRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updatePaIProperty = createAsyncThunk<
  {
    properties: PaIPropertyModel[];
    particularProperty: PaIPropertyModel;
  },
  UpdatePaIPropertyDto,
  { rejectValue: string }
>('PaIProperty/updatePaIProperty', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaIPropertyRepository.update(dto);
    const responseList = await PaIPropertyRepository.fetchAll();

    return { properties: responseList.resultObject, particularProperty: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removePaIPropertyById = createAsyncThunk<
  PaIPropertyModel[],
  number[],
  { rejectValue: string }
>('PaIProperty/removePaIPropertyById', async (ids, { rejectWithValue }) => {
  try {
    await PaIPropertyRepository.removeById(ids);
    const response = await PaIPropertyRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
