import { api } from '@services/Axios';

import type {
  CreatePaIModelDto,
  PaIModelModel,
  UpdatePaIModelDto,
} from '@model/properties/PaI/Model';
import type { ApiResponse } from '@services/Axios';

const PaIModelEndpoint = '/PartsAndInventoriesCardModels';

export const PaIModelRepository = {
  async fetchAll() {
    const response: ApiResponse<PaIModelModel[]> = await api.get(
      `${PaIModelEndpoint}/GetPartsAndInventoriesCardModelsList`
    );

    return response.data;
  },
  async add(dto: CreatePaIModelDto) {
    const response: ApiResponse<PaIModelModel> = await api.post(
      `${PaIModelEndpoint}/AddPartsAndInventoriesCardModel`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<PaIModelModel> = await api.get(
      `${PaIModelEndpoint}/GetPartsAndInventoriesCardModelsById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdatePaIModelDto) {
    const response: ApiResponse<PaIModelModel> = await api.post(
      `${PaIModelEndpoint}/UpdatePartsAndInventoriesCardModel`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${PaIModelEndpoint}/RemoveByIdList`, {
      modelIds: ids,
    });

    return response.data;
  },
};
