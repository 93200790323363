import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '@components/providers/AuthProvider';
import { statusColor } from '@constants/statusColors';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Arrow, DropdownLogout, DropdownProfile } from '@icon/icon-components';
import { paths } from '@routes/paths';
import LocalStorageService from '@services/LocaleStorage';
import { Badge } from '@shared/UI';
import { getCompanies, updateCompanySession } from '@store/actions/user/Company';
import { selectProfile } from '@store/selectors/profile/Profile';
import { selectCompaniesList } from '@store/selectors/users/Company';
import { Avatar, Dropdown, Space } from 'antd';

import type { MenuProps } from '@shared/UI';

const Profile = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const profile = useAppSelector(selectProfile);
  const companiesList = useAppSelector(selectCompaniesList);

  const { signOut } = useAuth();

  const userAvatar = profile && profile.picture ? `data:image/jpg;base64,${profile.picture}` : null;

  useEffect(() => {
    if (companiesList.length <= 0) {
      fetchCompanies();
    }
  }, []);

  function fetchCompanies() {
    dispatch(getCompanies());
  }

  function getCurrentCompany() {
    const currentCompanyId = profile?.companyId ?? 0;
    const currentCompany = companiesList.find((company) =>
      currentCompanyId !== 0 ? company.companyId === currentCompanyId : null
    );

    const color = statusColor.find((currColor) => {
      if (currentCompany && currentCompany.color) {
        return currentCompany.color === currColor.value;
      }
      return null;
    });

    return {
      abbreviation: currentCompany?.name[0] || '',
      color: color ? color.value : statusColor[0].value,
    };
  }

  const currentCompany = getCurrentCompany();

  function handleClickCompany(id: number) {
    dispatch(updateCompanySession(id))
      .unwrap()
      .then((res) => {
        LocalStorageService.setToken(res);

        window.location.reload();
      });
  }

  function companyItems() {
    return companiesList.map((company) => ({
      label: company.name,
      key: company.companyId,
      icon: (
        <div
          className="profile-company-icon"
          style={{
            background: company.color || statusColor[0].value,
          }}
        >
          <span>{company.name[0]}</span>
        </div>
      ),
      onClick: () => handleClickCompany(company.companyId),
    }));
  }

  function logOut() {
    signOut();
  }

  const items: MenuProps['items'] = [
    {
      type: 'group',
      label: t('titles.My_Companies'),
      className: 'my-companies-group profile-dropdown-group',
      children: companyItems(),
    },
    {
      type: 'divider',
    },
    {
      type: 'group',
      label: t('titles.General'),
      className: 'general-group profile-dropdown-group',
      children: [
        {
          label: <Link to={paths.profile.tabs.general.default}>{t('titles.My_Profile')}</Link>,
          key: '0',
          icon: <DropdownProfile />,
        },
        {
          label: <a>{t('titles.Log_Out')}</a>,
          key: '1',
          icon: <DropdownLogout />,
          onClick: logOut,
        },
      ],
    },
  ];

  return (
    <>
      <Badge
        count={currentCompany.abbreviation.toUpperCase()}
        color={currentCompany.color}
        offset={['-45%', '65%']}
      >
        <Avatar
          src={userAvatar}
          style={{
            width: '4.2rem',
            height: '4.2rem',
            border: `1.5px solid ${profile?.color}`
          }}
          icon={<UserOutlined />}
        />
      </Badge>
      <Dropdown overlayClassName="layout-dropdown-overlay" menu={{ items }} trigger={['click']}>
        <a>
          <Space>
            <div className="wrapper-space-item">
              {profile && profile.firstName ? (
                <span className="profile-name">{profile.firstName}</span>
              ) : (
                ''
              )}
              <Arrow />
            </div>
          </Space>
        </a>
      </Dropdown>
    </>
  );
};

export default Profile;
