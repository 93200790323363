import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { REQUIRED_RULE } from '@constants/formRules';
import { CommaIcon } from '@icon/icon-components';
import { Col, Input, Row, Select } from '@shared/UI';
import { replaceStrToNum } from '@shared/utils/functions';

import type { CurrencyModel } from '@model/extra/Currency';
import type { ItemRules, SelectFieldNames } from '@shared/utils/types';

interface CurrencyBlockProps {
  currencyList: Array<CurrencyModel>;
}

const { Item } = CommonForm;

// region --- Item Rules

const priceRules: ItemRules = [REQUIRED_RULE];

const currencyRules: ItemRules = [
  {
    ...REQUIRED_RULE,
    message: '',
  },
];

// endregion --- Item Rules

// region --- Fields names

const currencyNames: SelectFieldNames<CurrencyModel> = {
  label: 'symbol',
  value: 'currencyId',
};

// endregion --- Fields names

export const CurrencyBlock = memo((props: CurrencyBlockProps) => {
  const { currencyList } = props;

  const { t } = useTranslation();

  return (
    <Item label={t('titles.Agreement_Price')} required>
      <Row gutter={[10, 10]}>
        <Col md={10} xs={24}>
          <Item
            name="price"
            normalize={replaceStrToNum}
            rules={priceRules}
          >
            <Input placeholder="0" />
          </Item>
        </Col>
        <Col md={1} xs={24} style={{paddingTop: '2rem'}}>
          <CommaIcon />
        </Col>
        <Col md={5} xs={24}>
          <Item
            name="cent"
            normalize={replaceStrToNum}
          >
            <Input placeholder="00" maxLength={2} />
          </Item>
        </Col>
        <Col md={8} xs={24}>
          <Item noStyle required={false} name="currencyId" rules={currencyRules}>
            <Select
              options={currencyList}
              placeholder="USD"
              allowClear={false}
              showSearch={false}
              fieldNames={currencyNames}
            />
          </Item>
        </Col>
      </Row>
    </Item>
  );
});
