import type { RootState } from '@store/rootReducer';

export const selectPaIPropertyState = (state: RootState) => state.PaIPropertyReducer;

export const selectPaIPropertiesList = (state: RootState) =>
  selectPaIPropertyState(state).PaIPropertiesList;

export const selectPaIPropertyTypesList = (state: RootState) =>
  selectPaIPropertyState(state).PaIPropertyTypesList;

export const selectPaIProperty = (state: RootState) => selectPaIPropertyState(state).PaIProperty;

export const selectPaIPropertyStatus = (state: RootState) =>
  selectPaIPropertyState(state).PaIPropertyStatus;

export const selectPaIPropertyError = (state: RootState) =>
  selectPaIPropertyState(state).PaIPropertyError;
