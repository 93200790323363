import React from 'react';

import type { SVGProps } from 'react';

const SigInRectangleRB = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="201"
      height="201"
      viewBox="0 0 201 201"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          opacity="0.4"
          d="M89.2889 166.897L159.232 132.655C167.168 128.77 170.452 119.186 166.567 111.25L132.325 41.3069C128.439 33.3704 118.856 30.0864 110.919 33.9719L40.9764 68.2138C33.0399 72.0993 29.7559 81.6829 33.6414 89.6193L67.8833 159.562C71.7688 167.499 81.3524 170.783 89.2889 166.897Z"
          fill="url(#paint0_linear_6_3)"
        />
        <path
          opacity="0.7"
          d="M80.0485 194.04L186.934 141.712C194.622 137.948 197.804 128.664 194.04 120.976L141.712 14.0905C137.948 6.40205 128.664 3.22067 120.975 6.98472L14.0898 59.3126C6.40135 63.0766 3.21997 72.3607 6.98402 80.0492L59.3119 186.934C63.0759 194.623 72.36 197.804 80.0485 194.04Z"
          stroke="url(#paint1_linear_6_3)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6_3"
          x1="124.261"
          y1="149.776"
          x2="75.9487"
          y2="51.0935"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#404A8F" />
          <stop offset="1" stopColor="#404A8F" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6_3"
          x1="132.341"
          y1="168.184"
          x2="117.595"
          y2="75.1768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6FEAFC" />
          <stop offset="1" stopColor="#6FEAFC" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { SigInRectangleRB };
