import React from 'react';

import { List } from '@shared/UI';
import cl from 'classnames';

import { Cell, Header, Row } from './elemnts';

import type { ListProps } from '@shared/UI';
import type { PaginationProps } from 'antd';
import type { HeaderProps} from './elemnts';

import './CommonList.scss';

const { Item } = List;

export interface CommonListProps<ST, SV, CSV> {
  header: HeaderProps<ST, SV, CSV>;
}

interface DefaultCommonListProps<T = any, ST = any, SV = any, CSV = any>
  extends Omit<ListProps<T>, 'header'>,
    CommonListProps<ST, SV, CSV> {
  currentPage?: number;
  perPage?: number;
  // actions?: {
  //   handlePage?: (page: number) => void;
  // };
  onChangePage?: (page: number) => void;
}

export interface CommonListInterface {
  <T,
    ST,
    SV extends { value: string; label: string; color: string },
    CSV extends Record<string, any> = Record<string, any>>(
    props: DefaultCommonListProps<T, ST, SV, CSV>
  ): React.ReactElement<DefaultCommonListProps<T>>;

  Item: typeof Item;
  Row: typeof Row;
  Cell: typeof Cell;
}

const CommonList: CommonListInterface = (props) => {
  const {
    className,
    pagination,
    currentPage: currentPageProps,
    perPage = 10,
    onChangePage: onChangePageProps,
    header,
    ...rest
  } = props;

  const [currentPage, setCurrentPage] = React.useState(currentPageProps || 1);

  const commonListClasses = cl('common-list', className);

  const onChangePage: PaginationProps['onChange'] = (page) => {
    onChangePageProps?.(page);

    setCurrentPage(page);
  };

  return (
    <div className="wrapper-common-list">
      <Header {...header} />
      <List
        split={false}
        className={commonListClasses}
        pagination={{
          onChange: onChangePage,
          defaultPageSize: perPage,
          current: currentPage,

          ...pagination,
        }}
        {...rest}
      />
    </div>
  );
};

CommonList.Item = Item;
CommonList.Row = Row;
CommonList.Cell = Cell;

export {CommonList};
