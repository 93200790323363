import React from 'react';

import cl from 'classnames';
import { format, isWeekend } from 'date-fns';

import type { ReactNode } from 'react';

import './DateCell.scss';

interface DateCellProps {
  className?: string;
  children?: ReactNode;
  date: Date;
}

const defaultClass = 'custom-calendar';

const DateCell = (props: DateCellProps) => {
  const { className, date, children } = props;

  const day = format(date, 'd');

  const classNames = cl(`${defaultClass}-date-cell`, className, {
    [`${defaultClass}-date-cell--weekend`]: isWeekend(date),
  });

  return (
    <div className={classNames}>
      <div className={`${defaultClass}-date`}>
        <span>{day}</span>
      </div>
      <div className={cl(`${defaultClass}-cell-content`)}>{children}</div>
    </div>
  );
};

export { DateCell };
