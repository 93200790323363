import { FlagEngland, FlagTurkeyCircle } from '@icon/icon-components';

import type { ReactElement } from 'react';

export type Language = {
  language: string;
  prefix: string;
  key: string;
  icon: ReactElement;
};

export const languages: Language[] = [
  {
    language: 'English',
    prefix: 'Eng',
    key: 'en',
    icon: <FlagEngland />,
  },
  {
    language: 'Turkish',
    prefix: 'TR',
    key: 'tr',
    icon: <FlagTurkeyCircle />,
  },
];
