import { api } from '@services/Axios';

import type { CityModel, CountryModel } from '@model/extra/Definition';
import type { ApiResponse } from '@services/Axios';

const DefinitionEndpoint = '/Definition';

export const DefinitionRepository = {
  async fetchCities() {
    const response: ApiResponse<CityModel[]> = await api.get(`${DefinitionEndpoint}/GetCitiesList`);

    return response.data;
  },
  async fetchCountries() {
    const response: ApiResponse<CountryModel[]> = await api.get(
      `${DefinitionEndpoint}/GetCountriesList`
    );

    return response.data;
  },
};
