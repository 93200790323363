import React from 'react';

import generatePicker from 'antd/es/date-picker/generatePicker';
import cl from 'classnames';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import 'antd/es/date-picker/style/index';

import type { PickerTimeProps } from 'antd/lib/date-picker/generatePicker';

import './TimePicker.scss';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export interface TimePickerProps extends Omit<PickerTimeProps<Date>, 'picker'> {
  textCenter?: boolean;
}

const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => {
  const { className, textCenter, ...rest } = props;

  const defaultClasses = cl('custom-time-picker', className, {
    'custom-time-picker--text-center': textCenter,
  });

  return (
    <DatePicker
      picker="time"
      className={defaultClasses}
      minuteStep={10}
      mode={undefined}
      ref={ref}
      format="HH:mm"
      suffixIcon={null}
      showNow={false}
      {...rest}
    />
  );
});

// @ts-ignore
TimePicker.displayName = 'TimePicker';

export { TimePicker };
