export const statusColor = [
  {
    value: '#D2D2D2',
    label: 'Default',
  },
  {
    value: '#626262',
    label: 'Gray',
  },
  {
    value: '#4A0000',
    label: 'Brown',
  },
  {
    value: '#DC6A00',
    label: 'Orange',
  },
  {
    value: '#F8EF14',
    label: 'Yellow',
  },
  {
    value: '#0C7A02',
    label: 'Green',
  },
  {
    value: '#0722AF',
    label: 'Blue',
  },
  {
    value: '#83006E',
    label: 'Purple',
  },
  {
    value: '#DF00A1',
    label: 'Pink',
  },
  {
    value: '#C11414',
    label: 'Red',
  },
];
