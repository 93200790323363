import React from 'react';

const DepartmentIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="9.25"
      y="2.43359"
      width="6.5"
      height="6.5"
      rx="1.25"
      stroke="white"
      strokeWidth="1.5"
    />
    <rect
      x="3.78516"
      y="15.0664"
      width="6.5"
      height="6.5"
      rx="1.25"
      stroke="white"
      strokeWidth="1.5"
    />
    <rect
      x="14.7148"
      y="15.0664"
      width="6.5"
      height="6.5"
      rx="1.25"
      stroke="white"
      strokeWidth="1.5"
    />
    <rect x="11.75" y="8.52051" width="1.5" height="3.84885" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66797 13.1104H16.3294C16.6056 13.1104 16.8294 13.3342 16.8294 13.6104V14.3168H18.3294V13.6104C18.3294 12.5058 17.434 11.6104 16.3294 11.6104H8.66797C7.5634 11.6104 6.66797 12.5058 6.66797 13.6104V14.3168H8.16797V13.6104C8.16797 13.3342 8.39183 13.1104 8.66797 13.1104Z"
      fill="white"
    />
  </svg>
);

export { DepartmentIcon };
