import { createSlice } from '@reduxjs/toolkit';
import {
  addMaAtCategory,
  getMaAtCategories,
  getMaAtCategoryById,
  removeMaAtCategoryById,
  updateMaAtCategory,
} from '@store/actions/properties/MaAt/Category';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { MaAtCategoryModel } from '@model/properties/MaAt/Category';

interface MaAtCategoryState {
  MaAtCategoriesList: MaAtCategoryModel[];
  MaAtCategory: MaAtCategoryModel | null;
  MaAtCategoryStatus: LoadingStatus;
  MaAtCategoryError: string | null;
}

const initialState: MaAtCategoryState = {
  MaAtCategoriesList: [],
  MaAtCategory: null,
  MaAtCategoryStatus: 'idle',
  MaAtCategoryError: null,
};

const MaAtCategorySlice = createSlice({
  name: 'MaAtCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMaAtCategories.pending, (state) => {
        state.MaAtCategoryError = null;
        state.MaAtCategoryStatus = 'pending';
      })
      .addCase(getMaAtCategories.fulfilled, (state, { payload }) => {
        state.MaAtCategoryStatus = 'idle';
        state.MaAtCategoriesList = payload;
      })
      .addCase(getMaAtCategories.rejected, (state, { payload }) => {
        state.MaAtCategoryStatus = 'idle';
        if (payload) {
          state.MaAtCategoryError = payload;
        }
      });

    builder
      .addCase(addMaAtCategory.pending, (state) => {
        state.MaAtCategoryStatus = 'pending';
        state.MaAtCategoryError = null;
      })
      .addCase(addMaAtCategory.fulfilled, (state, { payload }) => {
        state.MaAtCategoryStatus = 'idle';
        state.MaAtCategoriesList = [payload, ...state.MaAtCategoriesList];
      })
      .addCase(addMaAtCategory.rejected, (state, { payload }) => {
        state.MaAtCategoryStatus = 'idle';
        if (payload) {
          state.MaAtCategoryError = payload;
        }
      });

    builder
      .addCase(getMaAtCategoryById.pending, (state) => {
        state.MaAtCategoryStatus = 'pending';
        state.MaAtCategoryError = null;
      })
      .addCase(getMaAtCategoryById.fulfilled, (state, { payload }) => {
        state.MaAtCategoryStatus = 'idle';
        state.MaAtCategory = payload;
      })
      .addCase(getMaAtCategoryById.rejected, (state, { payload }) => {
        state.MaAtCategoryStatus = 'idle';
        if (payload) {
          state.MaAtCategoryError = payload;
        }
      });

    builder
      .addCase(updateMaAtCategory.pending, (state) => {
        state.MaAtCategoryStatus = 'pending';
        state.MaAtCategoryError = null;
      })
      .addCase(updateMaAtCategory.fulfilled, (state, { payload }) => {
        state.MaAtCategoryStatus = 'idle';
        state.MaAtCategoriesList = payload.MaAtCategoriesList;
        state.MaAtCategory = payload.MaAtCategory;
      })
      .addCase(updateMaAtCategory.rejected, (state, { payload }) => {
        state.MaAtCategoryStatus = 'idle';
        if (payload) {
          state.MaAtCategoryError = payload;
        }
      });

    builder
      .addCase(removeMaAtCategoryById.pending, (state) => {
        state.MaAtCategoryStatus = 'pending';
        state.MaAtCategoryError = null;
      })
      .addCase(removeMaAtCategoryById.fulfilled, (state, { payload }) => {
        state.MaAtCategoryStatus = 'idle';
        state.MaAtCategoriesList = payload;
      })
      .addCase(removeMaAtCategoryById.rejected, (state, { payload }) => {
        state.MaAtCategoryStatus = 'idle';
        if (payload) {
          state.MaAtCategoryError = payload;
        }
      });
  },
});

const MaAtCategoryReducer = MaAtCategorySlice.reducer;

export { MaAtCategoryReducer };
