import { createSlice } from '@reduxjs/toolkit';
import { getDashboard } from '@store/actions/dashboard';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { DashboardModel } from '@model/dashboard';

interface DashboardState {
  dashboardData: DashboardModel | null;
  dashboardStatus: LoadingStatus;
  dashboardError: string | null;
}

const initialState: DashboardState = {
  dashboardData: null,
  dashboardStatus: 'idle',
  dashboardError: null,
};

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.dashboardStatus = 'pending';
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.dashboardStatus = 'idle';
        state.dashboardData = action.payload;
      })
      .addCase(getDashboard.rejected, (state, { payload }) => {
        state.dashboardStatus = 'idle';
        if (payload) {
          state.dashboardError = payload;
        }
      });
  },
});

const DashboardReducer = DashboardSlice.reducer;

export { DashboardReducer };
