import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageSwitcher } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectPageInfo } from '@store/selectors/extra/Local';
import { Layout } from 'antd';

import { Actions, Profile } from './elements';

import './Header.scss';

const { Header: AntdHeader } = Layout;

const Header = () => {
  const {pageTitle} = useAppSelector(selectPageInfo);

  const {t} = useTranslation();

  return (
    <AntdHeader className="custom-layout-header">
      <h4 className="layout-header__title">{t(pageTitle)}</h4>
      <div className="layout-header__actions">
        <div className="layout-icons">
          <LanguageSwitcher className={'layout-header__switcher-language'}/>
          <Actions/>
        </div>
        <div className="layout-profile">
          <Profile/>
        </div>
      </div>
    </AntdHeader>
  );
};

export default memo(Header);
