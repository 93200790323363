import type { ActionFunction } from '@components/common/CheckStepItem/_utils';

export const onMoveStep: ActionFunction<{ sourceIdx: number; targetIdx: number }> = (
  { sourceIdx, targetIdx },
  { setState }
) => {
  setState((prevState) => {
    const step = prevState[sourceIdx];
    const copyState = [...prevState];
    copyState.splice(sourceIdx, 1);
    copyState.splice(targetIdx, 0, step);
    return copyState.map((s, idx) => ({ ...s, rowId: idx + 1 }));
  });
};
