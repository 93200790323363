import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormAssetModel } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletAssetModel } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateAsAtModel } from '@store/actions/properties/AsAt/Model';

import type { CreateAsAtModelDto } from '@model/properties/AsAt/Model';

function EditAssetModel() {
  const { initialValues, entity } = useOutletAssetModel();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { assetAttrAccess } = useGetAccess();
  const onFinish = (dto: CreateAsAtModelDto) => {
    dispatch(
      updateAsAtModel({
        ...dto,
        nonCurrAssetCardModelId: entity.nonCurrAssetCardModelId,
      })
    )
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.AsAtModel.name);
        navigate(paths.assetAttribute.tabs.model.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <CheckAccess hasAccess={assetAttrAccess.edit} redirectTo={paths.assetAttribute.tabs.model.default}>
      <FormAssetModel
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditAssetModel;
