import React from 'react';

const PartsIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.33333 9.33333H12.3333C12.5101 9.33333 12.6797 9.2631 12.8047 9.13807C12.9298 9.01305 13 8.84348 13 8.66667V1.66667C13 1.48986 12.9298 1.32029 12.8047 1.19526C12.6797 1.07024 12.5101 1 12.3333 1H5.33333C5.15652 1 4.98695 1.07024 4.86193 1.19526C4.7369 1.32029 4.66667 1.48986 4.66667 1.66667V4.66667" stroke="#374957" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.66667 4.66667H1.66667C1.48986 4.66667 1.32029 4.7369 1.19526 4.86193C1.07024 4.98695 1 5.15652 1 5.33333V12.3333C1 12.5101 1.07024 12.6797 1.19526 12.8047C1.32029 12.9298 1.48986 13 1.66667 13H8.66667C8.84348 13 9.01305 12.9298 9.13807 12.8047C9.2631 12.6797 9.33333 12.5101 9.33333 12.3333V9.33333" stroke="#374957" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.66667 4.66667H5.33333C4.96514 4.66667 4.66667 4.96514 4.66667 5.33333V8.66667C4.66667 9.03486 4.96514 9.33333 5.33333 9.33333H8.66667C9.03486 9.33333 9.33333 9.03486 9.33333 8.66667V5.33333C9.33333 4.96514 9.03486 4.66667 8.66667 4.66667Z" stroke="#374957" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);

export { PartsIcon };
