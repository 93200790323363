import React from 'react';

import { Tag } from 'antd';
import cl from 'classnames';

import type { TagProps } from 'antd';

import './Label.scss';

export interface LabelProps extends TagProps {}

const Label = (props: LabelProps) => {
  const { className, ...rest } = props;

  const labelClasses = cl('custom-label', className);

  return <Tag className={labelClasses} {...rest} />;
};

export { Label };
