import React from 'react';

const VendorIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 0H12.5C13.0304 0 13.5391 0.210714 13.9142 0.585786C14.2893 0.960859 14.5 1.46957 14.5 2V4H18.5C19.0304 4 19.5391 4.21071 19.9142 4.58579C20.2893 4.96086 20.5 5.46957 20.5 6V11.03C20 10.23 19.61 9.54 18.81 9V5.5H2.5V17.5H9C9.31 18.25 9.76 18.42 10.31 19H2.5C1.96957 19 1.46086 18.7893 1.08579 18.4142C0.710714 18.0391 0.5 17.5304 0.5 17V6C0.5 4.89 1.39 4 2.5 4H6.5V2C6.5 0.89 7.39 0 8.5 0ZM13 4V1.5H8V4H13ZM18.81 16.9L21.89 20L20.5 21.39L17.38 18.32C16.69 18.75 15.87 19 15 19C12.5 19 10.5 17 10.5 14.5C10.5 12 12.5 10 15 10C17.5 10 19.5 12 19.5 14.5C19.5 15.38 19.25 16.21 18.81 16.9ZM15 17C15.663 17 16.2989 16.7366 16.7678 16.2678C17.2366 15.7989 17.5 15.163 17.5 14.5C17.5 13.837 17.2366 13.2011 16.7678 12.7322C16.2989 12.2634 15.663 12 15 12C14.337 12 13.7011 12.2634 13.2322 12.7322C12.7634 13.2011 12.5 13.837 12.5 14.5C12.5 15.163 12.7634 15.7989 13.2322 16.2678C13.7011 16.7366 14.337 17 15 17Z"
      fill="white"
    />
  </svg>
);

export { VendorIcon };
