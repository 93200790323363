import React from 'react';

import type { SVGProps } from 'react';

const SigInRectangleRT = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="247"
      height="207"
      viewBox="0 0 247 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 243 1">
        <g id="figure_lights" filter="url(#filter0_f_1_2)">
          <path
            id="Vector"
            d="M210.513 60.6311C217.83 54.4833 218.778 43.5675 212.631 36.25C206.483 28.9325 195.567 27.9843 188.249 34.1321C180.932 40.2799 179.984 51.1957 186.131 58.5132C192.279 65.8307 203.195 66.779 210.513 60.6311Z"
            fill="#6EE8FD"
          />
        </g>
        <path
          id="Vector_2"
          opacity="0.5"
          d="M160.781 52.1957L74.8516 32.5537C66.2372 30.5846 57.6576 35.9716 55.6885 44.586L36.0465 130.516C34.0774 139.13 39.4645 147.71 48.0789 149.679L134.009 169.321C142.623 171.29 151.203 165.903 153.172 157.288L172.814 71.3587C174.783 62.7444 169.396 54.1648 160.781 52.1957Z"
          fill="url(#paint0_linear_1_2)"
        />
        <path
          id="Vector_3"
          opacity="0.7"
          d="M189.598 37.3378L54.2363 6.39654C45.8911 4.48898 37.5796 9.7077 35.6721 18.0529L4.73076 153.415C2.8232 161.76 8.04192 170.071 16.3871 171.979L151.749 202.92C160.094 204.828 168.405 199.609 170.313 191.264L201.254 55.9021C203.162 47.5569 197.943 39.2454 189.598 37.3378Z"
          stroke="url(#paint1_linear_1_2)"
        />
        <path
          id="Vector_4"
          d="M203.838 52.5443C206.277 50.495 206.593 46.8564 204.544 44.4173C202.494 41.9781 198.856 41.662 196.416 43.7113C193.977 45.7606 193.661 49.3992 195.711 51.8383C197.76 54.2775 201.398 54.5936 203.838 52.5443Z"
          fill="#6EE8FD"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1_2"
          x="152.076"
          y="0.0762024"
          width="94.6108"
          height="94.6108"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_1_2" />
        </filter>
        <linearGradient
          id="paint0_linear_1_2"
          x1="177.817"
          y1="42.3747"
          x2="141.0439"
          y2="130.499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#404A8F" />
          <stop offset="1" stopColor="#404A8F" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_2"
          x1="122.172"
          y1="21.3111"
          x2="68.9766"
          y2="113.626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6FEAFC" />
          <stop offset="0.8" stopColor="#6FEAFC" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { SigInRectangleRT };
