import React, { useCallback } from 'react';

import { List } from '@shared/UI';

import { useAssetListContext } from '../AssetListProvider';
import { Cards } from './Cards';

import type { AssetModel } from '@model/management/Asset';
import type { ListProps } from '@shared/UI';

import './ViewCard.scss';

interface ViewCardProps extends ListProps<AssetModel> {
  getAssetsByPage: (page: number) => void;
}

const ViewCard = (props: ViewCardProps) => {
  const { dataSource, loading, pagination, getAssetsByPage } = props;

  const { checkedAssets, handleChangeChecked } = useAssetListContext();

  const handleChangeCheckbox = (value: boolean, asset: AssetModel) => {
    if (value) {
      const newValue = [...checkedAssets, asset];
      handleChangeChecked(newValue);
    } else {
      const newValue = checkedAssets.filter(
        (currAsset) => currAsset.nonCurrAssetId !== asset.nonCurrAssetId
      );
      handleChangeChecked(newValue);
    }
  };

  const renderItem = useCallback(
    (item: AssetModel) => {
      const isChecked = checkedAssets.some((asset) => {
        return asset.nonCurrAssetId === item.nonCurrAssetId;
      });

      return <Cards checked={isChecked} onChangeCheckbox={handleChangeCheckbox} asset={item} />;
    },
    [dataSource, checkedAssets]
  );

  return (
    <List
      className="asset-card-list"
      split={false}
      grid={{ gutter: 0, xxl: 4, xl: 3, lg: 2, md: 2, xs: 1, sm: 1 }}
      loading={loading}
      dataSource={dataSource}
      pagination={{
        onChange: getAssetsByPage,
        ...pagination,
      }}
      renderItem={renderItem}
    />
  );
};

export { ViewCard };
