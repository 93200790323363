import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import './FilePreview.scss';

interface FilesPreviewProps {
  fileName: React.ReactNode;
  hiddenDelete?: boolean;
  onClickDelete?: () => void;
  href?: string;
}

const FilePreview = (props: FilesPreviewProps) => {
  const { fileName, hiddenDelete = false, onClickDelete, href } = props;

  const isDownload = !!href;

  return (
    <>
      <div className="file-item">
        <a href={href} download={isDownload && fileName}>
          {fileName}
        </a>
        {!hiddenDelete && (
          <a className="file-item__delete" onClick={onClickDelete}>
            <DeleteOutlined />
          </a>
        )}
      </div>
    </>
  );
};

export { FilePreview };
