import React from 'react';
import { Translation } from 'react-i18next';

import { CommonForm, SimpleTable } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectAssetParts } from '@store/selectors/management/AssetParts';

import type { ColumnsTableType } from '@components/common';
import type { AssetPartModel } from '@model/management/AssetParts';

import './PartsTable.scss';

const columns: ColumnsTableType<AssetPartModel & { idx: number }> = [
  {
    dataIndex: ['idx'],
    key: 'idx',
    width: '40px',
    render: (text) => {
      return (
        <span
          style={{
            paddingLeft: 10,
            color: 'var(--font-color-default)',
          }}
        >
          {text}.
        </span>
      );
    },
  },
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: ['partsAndInventories', 'partsAndInventoriesCard', 'partsAndInventoriesCardName'],
    key: 'paiName',
  },
];

const { CaseField } = CommonForm;

export const PartsTable = () => {
  const assetParts = useAppSelector(selectAssetParts);

  const assetPartsWithIdx = assetParts.map((part, idx) => ({
    ...part,
    idx: idx + 1,
  }));

  return (
    <CaseField md={20} className="asset-parts-preview-form-col">
      <SimpleTable
        dataSource={assetPartsWithIdx}
        columns={columns}
        rowKey={(row) => row.nonCurrAssetsPartsId}
        pagination={false}
      />
    </CaseField>
  );
};
