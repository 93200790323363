import { createSlice } from '@reduxjs/toolkit';
import {
  addDepartment,
  getDepartmentById,
  getDepartments,
  getDepartmentsByFilter,
  removeDepartmentById,
  updateDepartment,
  updateDepartmentParentRelations,
} from '@store/actions/properties/Department';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { DepartmentModel, FilterDepartmentDto } from '@model/properties/Department';

type DepartmentState = {
  departmentsList: DepartmentModel[];
  departmentsFilteredList: DepartmentModel[];
  departmentStatus: LoadingStatus;
  department: DepartmentModel | null;
  departmentError: string | null;
  departmentFilters: FilterDepartmentDto;
};

const initialState: DepartmentState = {
  departmentsList: [],
  departmentsFilteredList: [],
  departmentStatus: 'idle',
  department: null,
  departmentError: null,
  departmentFilters: {
    departmentCodeArray: [],
    nameArray: [],
  },
};

const DepartmentSlice = createSlice({
  name: 'Department',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartments.pending, (state) => {
        state.departmentError = null;
        state.departmentStatus = 'pending';
        state.departmentsFilteredList = [];
        state.departmentFilters = {
          departmentCodeArray: [],
          nameArray: [],
        };
      })
      .addCase(getDepartments.fulfilled, (state, { payload }) => {
        state.departmentStatus = 'idle';
        state.departmentsList = payload;
      })
      .addCase(getDepartments.rejected, (state) => {
        state.departmentStatus = 'idle';
      });
    builder
      .addCase(getDepartmentsByFilter.pending, (state) => {
        state.departmentError = null;
        state.departmentStatus = 'pending';
      })
      .addCase(getDepartmentsByFilter.fulfilled, (state, { payload }) => {
        state.departmentStatus = 'idle';
        state.departmentsFilteredList = payload.departments;
        state.departmentFilters = payload.departmentFilters;
      })
      .addCase(getDepartmentsByFilter.rejected, (state) => {
        state.departmentStatus = 'idle';
      });
    builder
      .addCase(addDepartment.pending, (state) => {
        state.departmentStatus = 'pending';
        state.departmentError = null;
      })
      .addCase(addDepartment.fulfilled, (state, { payload }) => {
        state.departmentStatus = 'idle';
        state.departmentsList = payload.departmentsList;
      })
      .addCase(addDepartment.rejected, (state) => {
        state.departmentStatus = 'idle';
      });
    builder
      .addCase(getDepartmentById.pending, (state) => {
        state.departmentStatus = 'pending';
        state.departmentError = null;
      })
      .addCase(getDepartmentById.fulfilled, (state, { payload }) => {
        state.departmentStatus = 'idle';
        state.department = payload;
      })
      .addCase(getDepartmentById.rejected, (state) => {
        state.departmentStatus = 'idle';
      });
    builder
      .addCase(updateDepartment.pending, (state) => {
        state.departmentStatus = 'pending';
        state.departmentError = null;
      })
      .addCase(updateDepartment.fulfilled, (state, { payload }) => {
        state.departmentStatus = 'idle';
        state.departmentsList = payload.departments;
        state.department = payload.department;
      })
      .addCase(updateDepartment.rejected, (state) => {
        state.departmentStatus = 'idle';
      });
    builder
      .addCase(removeDepartmentById.pending, (state) => {
        state.departmentStatus = 'pending';
        state.departmentError = null;
      })
      .addCase(removeDepartmentById.fulfilled, (state, { payload }) => {
        state.departmentStatus = 'idle';
        state.departmentsList = payload;
      })
      .addCase(removeDepartmentById.rejected, (state) => {
        state.departmentStatus = 'idle';
      });
    builder
      .addCase(updateDepartmentParentRelations.pending, (state) => {
        state.departmentStatus = 'pending';
        state.departmentError = null;
      })
      .addCase(updateDepartmentParentRelations.fulfilled, (state, { payload }) => {
        state.departmentStatus = 'idle';
        state.departmentsList = payload;
      })
      .addCase(updateDepartmentParentRelations.rejected, (state) => {
        state.departmentStatus = 'idle';
      });
  },
});

const DepartmentReducer = DepartmentSlice.reducer;

export { DepartmentReducer };
