import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getUserById } from '@store/actions/user/User';
import { selectUser } from '@store/selectors/users/User';

import type { IOutletUser } from '@constants/OutletContextEntityPages';
import type { CreateUserDto } from '@model/users/User';

function User() {
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();
  const user = useAppSelector(selectUser);

  const condition = !user || user.userId !== +decryptedId;

  useSetPageInfo({
    defaultPagePath: paths.users.default,
    pageTitle: 'pages_single.User',
  });

  useEffect(() => {
    if (condition) {
      dispatch(getUserById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateUserDto = {
    userId: user.userId,
    userCode: user.userCode,
    parentUserId: user.parentUserId,
    firstName: user.firstName,
    lastName: user.lastName,
    registrationNumber: user.registrationNumber,
    userTitleId: user.userTitleId,
    userTitle: user.userTitle,
    userMail: user.userMail,
    phoneNumber: user.phoneNumber,
    department: user.department,
    departmentId: user.departmentId,
    siteId: user.siteId,
    site: user.site,
    parentUser: user.parentUser,
    description: user.description || '',
    userRoles: user.userRoles,
    roleName: user.roleName,
    isAdUser: user.isAdUser,
    isSystemUser: user.isSystemUser,
    isValid: user.isValid,
    userGroups: user.userGroups,
    groupsId: user.userGroups.length ? user.userGroups[0].groups.groupsId : '',
    roleId: user.userRoles.length ? user.userRoles[0].role.roleId : '',
  };

  return <Outlet context={{ initialValues, entity: user } as IOutletUser} />;
}

export default User;
