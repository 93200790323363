import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@shared/UI';

import { checkStepKeys, useCheckStepContext } from '../../_utils';

import type { ChangeEvent } from 'react';

export const BaseField = () => {
  const { t } = useTranslation();

  const { step, actions, isActiveWorkStep } = useCheckStepContext();

  const validateDecimal = (value: string) => {
    const regex = /^\d+\.?\d*$/;

    if (value === '') {
      return true;
    }

    return value.match(regex);
  };

  const validateNumber = (value: string) => {
    const regex = /^\d+$/;
    if (value === '') {
      return true;
    }
    return value.match(regex);
  };

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (step.stepKey === checkStepKeys.decimal && validateDecimal(value)) {
      let val = value;

      if (value.endsWith('.')) {
        val += '0';
      }
      actions?.onChangeResultValue?.(step, val);
    }

    if (step.stepKey === checkStepKeys.integer && validateNumber(value)) {
      actions?.onChangeResultValue?.(step, value);
    }

    if (step.stepKey === checkStepKeys.text) {
      actions?.onChangeResultValue?.(step, value);
    }
  };

  const getPlaceholder = () => {
    switch (step.stepKey) {
      case 1:
        return t('titles.Text');
      case 3:
        return '0000';
      case 4:
        return '00.00';
      case 5:
        return t('titles.Inspection');
    }
  };

  return (
    <Input
      disabled={!isActiveWorkStep}
      placeholder={getPlaceholder()}
      value={step.value}
      onChange={handleValueChange}
    />
  );
};
