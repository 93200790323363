import { IterationMonthType } from '@constants/IterationMonthType';
import { IterationValue } from '@constants/iterationValue';
import { KindOfCheckStep } from '@constants/kindOfCheckStep';
import {
  ITERATION_ABSOLUTE_MONTHLY,
  ITERATION_RELATIVE_MONTHLY,
} from '@constants/patternIterationMonth';
import { onlyNumberRegex } from '@constants/regex';
import { basePathChildren } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import axios from 'axios';
import moment from 'moment';

import type { CheckStepValue } from '@constants/checkStepItems';
import type { LoadingStatus } from '@constants/loadingStatus';
import type { CSVConfig } from '@interfaces/interfaces';
import type { AuthorizationFunctionModel, AuthorizationModel } from '@model/extra/Authorization';
import type { CreateMaintenanceStepsDto, StepMcList } from '@model/management/Maintenance';
import type { PaIModel } from '@model/management/PaI';
import type { ApiError, ApiErrorType } from '@services/Axios';
import type { DayOfWeek } from '@shared/utils/types';
import type { Moment } from 'moment';
import type { MutableRefObject } from 'react';

/**
 * It compares two strings and returns a number
 * @param {string | number} a - The first string to compare.
 * @param {string | number} b - The second string to compare.
 * @returns A function that takes two strings and returns a number.
 */
export function compare(a: string, b: string): number;
export function compare(a: number, b: number): number;
export function compare(a: string | number, b: string | number) {
  if (!a || !b) {
    return 0;
  }
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
}

export function checkIsActive(statusActive: boolean | undefined) {
  return statusActive ? 'isActiveField' : null;
}

/**
 * It takes a list of objects, and returns a list of objects without the children
 * @param {T[]} list - T[] - the list of items to be lifted
 * @param {K} keyChild - The key of the child array
 * @returns A function that takes a list of objects and a key of a child property and returns a list of
 * objects without the child property.
 */
export function liftChildToTop<
  T extends Record<string, any> = Record<string, any>[],
  K extends keyof T = keyof T
>(list: T[], keyChild: K) {
  const listWithoutChildren: Omit<T, typeof keyChild>[] = [];

  const liftChildren = (list: T[]) => {
    list.forEach((item) => {
      const { [keyChild]: children, ...rest } = item;
      if (children?.length) {
        liftChildren(item[keyChild]);
        listWithoutChildren.push({ ...rest });
      } else {
        listWithoutChildren.push({ ...rest });
      }
    });
  };

  liftChildren(list);

  return listWithoutChildren;
}

/**
 * It reads a file and returns a promise that resolves to the file's contents
 * @param {File} file - The file to read.
 * @param [inputRef] - This is a ref to the input element. This is used to clear the input value after
 * the file has been read.
 * @returns A promise that resolves to a string.
 */
export function readFile(
  file: File,
  inputRef?: MutableRefObject<HTMLInputElement | null>
): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadstart = () => {
      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }
    };
    reader.onloadend = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export function createImageArray(files: File[] | null | undefined): number[] | File[];
export function createImageArray(
  files: string[] | number[] | null | undefined
): string[] | number[];
export function createImageArray(
  files: string[] | File[] | number[] | undefined | null
): string[] | number[] | File[] {
  if (!files) {
    return new Array(5).fill(0);
  }
  return new Array(5 - files.length)
    .fill(0)
    .concat(...files)
    .reverse();
}

/**
 * It takes a string, removes all non-numeric characters, and returns a number
 * @param {string} value - string - The value to be converted.
 * @returns A function that takes a string and returns a number.
 */
export function replaceStrToNum(value: string) {
  return +value.replace(onlyNumberRegex, '');
}

/**
 * It takes an object and returns a new object with all the undefined values replaced with null
 * @param {T} obj - T - The object that you want to normalize.
 */
export function normalizeOptionalValue<T extends Record<string, any>>(obj: T) {
  let normalizeObj = {} as T;

  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      normalizeObj = { ...normalizeObj, [key]: null };
    } else {
      normalizeObj = { ...normalizeObj, [key]: obj[key] };
    }
  });

  return normalizeObj;
}

export function checkEditPage(page: string) {
  return page.includes(basePathChildren.edit);
}

/**
 * If any of the values in the object are not undefined and have a length greater than 0, return true,
 * otherwise return false.
 * @param filterObj - The object that contains the filter values.
 * @returns A boolean value.
 */
export function checkFilterValues(filterObj: { [key: string]: any }) {
  if (!filterObj) return false;
  for (const [, value] of Object.entries(filterObj)) {
    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')
      continue;
    if (value && value !== undefined && value.length > 0) {
      return true;
    }
  }
  return false;
}

/**
 * It takes a base64 string and returns a new string with the data URI prefix added to it
 * @param {string} base64 - The base64 string of the image.
 * @returns A function that takes a base64 string and returns a string with the base64 string prefixed
 * with "data:image/png;base64,"
 */
export function addPrefixBase64(base64: string) {
  return `data:image/jpeg;base64,${base64}`;
}

// export const blobToBase64 = (blob: Blob, callback?: (base64: string) => void) => {
//   const reader = new FileReader();
//   reader.onloadend = () => {
//     const base64 = reader.result;
//
//     callback?.(base64 as string);
//   };
//   reader.readAsDataURL(blob);
//   return reader.result?.toString() || '';
// };

/**
 * It takes a file object and returns a URL object
 * @param {File} file - The file object that you want to create a URL for.
 * @returns A URL object
 */
export const createUrlObject = (file: File) => {
  return URL.createObjectURL(file);
};

export function createCsvConfig<T>(
  data: T[],
  headers?: CSVConfig['headers'],
  fileName?: string,
  onClick?: CSVConfig['onClick']
): CSVConfig<T> {
  return {
    data,
    headers,
    fileName,
    onClick,
  };
}

/**
 * Check if any of the statuses in the array are equal to 'pending'.
 * @param {LoadingStatus[]} statuses - LoadingStatus[] - an array of statuses to check
 * @returns A function that takes in an array of LoadingStatus and returns a boolean.
 */
export function checkStoreStatuses(statuses: LoadingStatus[]) {
  return statuses.some((status) => status === 'pending');
}

export function truncate(str: string, length: number) {
  return str.length > length ? `${str.substring(0, length)}...` : str;
}

// TODO: it need to delete

export function transformCheckValuesToWorkSteps(
  values: CheckStepValue[],
  id: number,
  userId: number
): CreateMaintenanceStepsDto[] {
  return values.map((step, idx) => {
    if (step.type === KindOfCheckStep.MultiplyChoice) {
      const mcList = Object.values(step.radios).reduce(
        (acc: Array<StepMcList>, radio, radioIdx) => {
          if (!radio.value || !radio.value.length) return acc;
          if (radio.id !== undefined) {
            const newRadio = {
              multipleCheckListOptionText: radio.value,
              multipleCheckListRowId: radioIdx + 1,
              multipleChoiceListForWorkStepsId: radio.id,
              workStepsId: step.entityId,
            };
            acc.push(newRadio);
          } else {
            const newRadio = {
              multipleCheckListOptionText: radio.value,
              multipleCheckListRowId: radioIdx + 1,
            };
            acc.push(newRadio);
          }
          return acc;
        },
        []
      );

      if (step.entityId !== undefined) {
        return {
          workStepsId: step.entityId,
          stepTypeId: 2,
          stepName: step.title,
          mcList,
          rowId: idx + 1,
          maintenanceId: id,
          loggedUserId: userId,
        };
      }

      return {
        stepTypeId: 2,
        stepName: step.title,
        mcList,
        rowId: idx + 1,
        maintenanceId: id,
        loggedUserId: userId,
      };
    }

    if (step.entityId !== undefined) {
      return {
        workStepsId: step.entityId,
        stepTypeId:
          step.type === KindOfCheckStep.Text
            ? 1
            : step.type === KindOfCheckStep.Integer
            ? 3
            : step.type === KindOfCheckStep.Decimal
            ? 4
            : 5,
        stepName: step.title,
        rowId: idx + 1,
        maintenanceId: id,
        loggedUserId: userId,
      };
    }

    return {
      stepTypeId:
        step.type === KindOfCheckStep.Text
          ? 1
          : step.type === KindOfCheckStep.Integer
          ? 3
          : step.type === KindOfCheckStep.Decimal
          ? 4
          : 5,
      stepName: step.title,
      rowId: idx + 1,
      maintenanceId: id,
      loggedUserId: userId,
    };
  });
}

// TODO: it need to delete
export function transformCheckValuesToCheckSteps(values: CheckStepValue[], ownerId: number) {
  return values.map((step, idx) => {
    if (step.type === KindOfCheckStep.MultiplyChoice) {
      const multiplyType = 2;
      const mcList = Object.values(step.radios).map((radio, radioIdx) => {
        if (radio.id !== undefined) {
          return {
            multipleCheckListOptionText: radio.value,
            multipleCheckListRowId: radioIdx + 1,
            multipleChoiceListForWorkStepsId: radio.id,
            checklistId: ownerId,
            checkStepsId: step.entityId,
            multipleChoiceListId: radio.id,
          };
        }
        return {
          multipleCheckListOptionText: radio.value,
          multipleCheckListRowId: radioIdx + 1,
        };
      });

      if (step.entityId !== undefined) {
        return {
          checklistId: ownerId,
          checkStepsId: step.entityId,
          stepTypeId: multiplyType,
          stepName: step.title,
          rowId: idx + 1,
          mcList,
        };
      }
      return {
        stepTypeId: multiplyType,
        stepName: step.title,
        rowId: idx + 1,
        checklistId: ownerId,
        mcList,
      };
    }

    if (step.entityId !== undefined) {
      return {
        checklistId: ownerId,
        checkStepsId: step.entityId,
        stepTypeId:
          step.type === KindOfCheckStep.Text
            ? 1
            : step.type === KindOfCheckStep.Integer
            ? 3
            : step.type === KindOfCheckStep.Decimal
            ? 4
            : 5,
        stepName: step.title,
        rowId: idx + 1,
        mcList: [],
      };
    }

    return {
      checklistId: ownerId,
      stepTypeId:
        step.type === KindOfCheckStep.Text
          ? 1
          : step.type === KindOfCheckStep.Integer
          ? 3
          : step.type === KindOfCheckStep.Decimal
          ? 4
          : 5,
      stepName: step.title,
      rowId: idx + 1,
      mcList: [],
    };
  });
}

// TODO: it need to delete
export function handleErrorNotifications(error: unknown) {
  if (typeof error === 'string') {
    notificationController.error({
      description: error,
    });
    return;
  }

  if (typeof error === 'object' && error !== null && Object.hasOwn(error, 'message')) {
    notificationController.error({
      description: (error as any).message,
    });
  }
}

export function identifyError(error: ApiErrorType): string {
  if (
    typeof error === 'object' &&
    error !== null &&
    Object.hasOwn(error, 'data') &&
    (error as ApiError).data.languageKeyword
  ) {
    return (error as ApiError).data.languageKeyword;
  }

  if (axios.isAxiosError(error) && error !== null && error.response) {
    if (error.response.data && error.response.data.languageKeyword) {
      return error.response.data.languageKeyword;
    }
    if (error.response.data && error.response.data.title) {
      return error.response.data.title;
    }
    if (error.message) {
      return error.message;
    }
    return error.response.statusText;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  return 'Unknown error';
}

export function formatMomentDate(date: Moment | string | Date | null = moment(), format?: string) {
  if (format) {
    return moment(date).format(format);
  }

  return momentToISO(date);
}

export function momentToISO(date: string | Moment | Date | null = moment()) {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
}

export function weekOfMonth(value: string | Moment | null) {
  const date = moment(value);

  const day = date.date();
  const first = day <= 7;
  const second = day > 7 && day <= 14;
  const third = day > 14 && day <= 21;
  const fourth = day > 21 && day <= 28;
  const fifth = day > 28 && day <= 31;

  if (first) {
    return 1;
  }
  if (second) {
    return 2;
  }
  if (third) {
    return 3;
  }
  if (fourth) {
    return 4;
  }
  if (fifth) {
    return 5;
  }

  return 0;
}

export function weekStringOfMonth(date: string | Moment | null) {
  const num = weekOfMonth(date);
  switch (num) {
    case 1:
      return 'First';
    case 2:
      return 'Second';
    case 3:
      return 'Third';
    case 4:
      return 'Fourth';
    case 5:
      return 'Fifth';
    default:
      return 'Unknown';
  }
}

export const normalizeRoleFunctionNames = (roles: Array<AuthorizationModel>) => {
  return roles.reduce((acc: Array<AuthorizationFunctionModel>, currName) => {
    const value: AuthorizationFunctionModel = {
      roleFunctionNameId: currName.roleFunctionNameId,
      roleFunctionValue: currName.value === null ? false : currName.value,
    };

    acc.push(value);

    currName.childrens.forEach((child) => {
      const value: AuthorizationFunctionModel = {
        roleFunctionNameId: child.roleFunctionNameId,
        roleFunctionValue: child.value,
      };
      acc.push(value);
    });
    return acc;
  }, []);
};

export function formatIterationEndDate(endDate: string | Moment | null) {
  if (!endDate) return '';

  const day = moment(endDate).format('Do');
  const month = moment(endDate).format('MMMM');
  const year = moment(endDate).format('YYYY');

  return `on the ${day} ${month} ${year}`;
}

export function getByWeekOfMonthValue(date: string | Moment | null) {
  return `on the ${weekStringOfMonth(date).toLowerCase()} ${moment(date).format('dddd')}`;
}

export function getByDayValue(date: string | Moment | null) {
  return `on Day ${moment(date).date()}`;
}

export function getPatternIterationMonthly(
  pattern: typeof ITERATION_ABSOLUTE_MONTHLY | typeof ITERATION_RELATIVE_MONTHLY,
  date: string | Moment | null
) {
  if (pattern === ITERATION_ABSOLUTE_MONTHLY) {
    return `Occurs ${getByDayValue(date)} until`;
  }

  if (pattern === ITERATION_RELATIVE_MONTHLY) {
    return `Occurs ${getByWeekOfMonthValue(date)} until`;
  }
}

export function getActiveDaysList(daysOfWeek: Array<DayOfWeek>) {
  return structuredClone(daysOfWeek)
    .sort((currDay, nextDay) => compare(currDay.dayOfWeekIndex, nextDay.dayOfWeekIndex))
    .filter((day) => day.checked)
    .map((day) => day.fullName)
    .join(', ');
}

export function getIterationMonthly(monthType: IterationMonthType, startDate: Moment) {
  switch (monthType) {
    case IterationMonthType.ByDay:
      return `Occurs ${getByDayValue(startDate)} until:`;
    case IterationMonthType.ByWeek:
      return `Occurs ${getByWeekOfMonthValue(startDate)} until:`;
  }
}

export function createIterationDateLabel(
  iterationType: IterationValue,
  daysOfWeek: Array<DayOfWeek>,
  startDate: Moment,
  monthType: IterationMonthType
) {
  switch (iterationType) {
    case IterationValue.EVERY_DAY: {
      return 'Occurs every day until:';
    }
    case IterationValue.EVERY_WEEK: {
      const days = getActiveDaysList(daysOfWeek);
      return `Occurs every ${days} until:`;
    }
    case IterationValue.EVERY_MONTH: {
      return getIterationMonthly(monthType, startDate);
    }
  }
}

export function getOccurrenceByIteration(iteration: IterationValue) {
  switch (iteration) {
    case IterationValue.EVERY_DAY:
      return 1;
    case IterationValue.EVERY_WEEK:
      return 2;
    case IterationValue.EVERY_MONTH:
      return 3;
    default:
      return 0;
  }
}

export function getUserPictureUrl(pictureUrl: string | null) {
  if (!pictureUrl) return null;

  return `data:image/jpg;base64,${pictureUrl}`;
}

export function backAfterEdit() {
  const { pathname } = window.location;
  return pathname.replace(`/${basePathChildren.edit}`, '');
}

export function transformToPAINameObject(obj: PaIModel) {
  return {
    partsAndInventoriesId: obj.partsAndInventoriesId,
    partsAndInventoriesCardName: obj.partsAndInventoriesCard.partsAndInventoriesCardName,
    partsAndInventoriesCardId: obj.partsAndInventoriesCardId,
    partsAndInventoriesCategoryName:
      obj.partsAndInventoriesCard.partsAndInventoriesCategory.partsAndInventoriesCategoryName,
    partsAndInventoriesCategoryId: obj.partsAndInventoriesCategoryId,
    partsAndInventoriesSiteName: obj.partsAndInventoriesSite.name,
    partsAndInventoriesSiteId: obj.partsAndInventoriesSiteId,
    nonCurrAssetId: null,
    partsAndInventoriesUnitName:
      obj.partsAndInventoriesCard.partsAndInventoriesUnit.partsAndInventoriesUnitName,
    partsAndInventoriesUnitId: obj.partsAndInventoriesUnitId,
    sumQuantity: obj.quantity,
    remainingQuantity: obj.quantity,
    statusName: obj.statusName,
    lastLabel: obj.labels.length ? obj.labels[obj.labels.length - 1].label : '',
    lastLabelColor: obj.labels.length ? obj.labels[obj.labels.length - 1].labelColor : '',
    labelsCount: obj.labels.length,
  };
}

export function createRangeNumbers(start: number, end: number, step = 1) {
  const length = Math.ceil((end - start) / step);
  return Array.from({ length }, (_, i) => i * step + start);
}
