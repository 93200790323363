import React from 'react';
import { Navigate } from 'react-router-dom';

import type { ReactNode } from 'react';

interface CheckAccessProps {
  hasAccess: boolean;

  redirectTo: string | (() => string);

  children: ReactNode;
}

export const CheckAccess = (props: CheckAccessProps) => {
  const {children, hasAccess, redirectTo} = props;

  if (!hasAccess) {
    const to = typeof redirectTo === "string" ? redirectTo : redirectTo();

    return <Navigate to={to} replace/>
  }

  return <>{children}</>
};
