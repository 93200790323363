import React from 'react';

import cl from 'classnames';

import { BaseModal } from '../BaseModal';

import type { BaseModalProps } from '../BaseModal';

import './CreateableEntityModal.scss';

export interface CreateableEntityModalProps extends Omit<BaseModalProps, 'children'> {
  form: React.ReactElement;
}

const CreateableEntityModal = (props: CreateableEntityModalProps) => {
  const { form, closable = false, width = 485, className, ...rest } = props;

  const createableEntityModalClasses = cl('createable-entity-modal', className);

  return (
    <BaseModal
      className={createableEntityModalClasses}
      size="xl"
      footer={[]}
      {...rest}
      centered
      width={width}
      closable={closable}
    >
      {form}
    </BaseModal>
  );
};

export { CreateableEntityModal };
