import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { statusColor } from '@constants/statusColors';
import { useAppSelector } from '@hooks';
import { ColorPicker, Input } from '@shared/UI';
import { selectPaIStatusStatus } from '@store/selectors/properties/PaI/Status';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreatePaIStatusDto } from '@model/properties/PaI/Status';

const { CaseField, Item, useForm } = CommonForm;

function FormPartAndInventoryStatus(props: CommonFormProps<CreatePaIStatusDto>) {
  const [form] = useForm<CreatePaIStatusDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const statusPartAndInventoryStatus = useAppSelector(selectPaIStatusStatus);

  return (
    <CommonForm
      form={form}
      loading={statusPartAndInventoryStatus === 'pending'}
      isModal={isModal}
      additionalFooter={isModal ? <ModalFormFooter onCancel={rest.header?.onClickCancel} /> : null}
      name="formPaIStatus"
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="partsAndInventoriesStatusCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item name="color" label={t('titles.Color')}>
          <ColorPicker colors={statusColor} />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormPartAndInventoryStatus };
