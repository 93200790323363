import * as React from 'react';

import type { SVGProps } from 'react';

import './HubPicture.scss';

const HubPicture = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="463"
    height="438"
    viewBox="0 0 368 348"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group">
      <g id="figure_bottom">
        <path
          id="Vector"
          d="M204.98 334.97C201.15 334.97 197.5 334.2 194.77 332.62L75.51 263.77C72.84 262.23 71.37 260.09 71.37 257.74C71.37 254.92 73.49 252.08 77.18 249.95L193.1 183.03C200.03 179.03 210.57 178.59 216.58 182.07L335.84 250.93C338.51 252.47 339.98 254.61 339.98 256.96C339.98 259.78 337.86 262.62 334.17 264.75L218.25 331.67C214.46 333.85 209.59 334.97 204.98 334.97ZM206.37 180.16C201.84 180.16 197.05 181.26 193.32 183.42L77.4 250.34C73.85 252.39 71.82 255.09 71.82 257.74C71.82 259.92 73.21 261.92 75.73 263.38L194.99 332.24C200.88 335.64 211.21 335.21 218.03 331.28L333.95 264.36C337.5 262.31 339.53 259.61 339.53 256.96C339.53 254.78 338.14 252.78 335.62 251.32L216.36 182.46C213.69 180.91 210.11 180.16 206.37 180.16Z"
          fill="#6EE8FC"
        />
        <g id="Group_2">
          <path
            id="Vector_2"
            d="M178.72 193.03C178.72 194.45 177.57 195.6 176.15 195.6C174.73 195.6 173.58 194.45 173.58 193.03C173.58 191.61 174.73 190.46 176.15 190.46C177.57 190.47 178.72 191.62 178.72 193.03Z"
            fill="#6EE8FC"
          />
        </g>
        <g id="Group_3">
          <path
            id="Vector_3"
            d="M87.96 245.54C87.96 246.96 86.81 248.11 85.39 248.11C83.97 248.11 82.82 246.96 82.82 245.54C82.82 244.12 83.97 242.97 85.39 242.97C86.81 242.97 87.96 244.12 87.96 245.54Z"
            fill="#6EE8FC"
          />
        </g>
        <g id="Group_4">
          <path
            id="Vector_4"
            d="M282.39 296.94C283.809 296.94 284.96 295.789 284.96 294.37C284.96 292.951 283.809 291.8 282.39 291.8C280.971 291.8 279.82 292.951 279.82 294.37C279.82 295.789 280.971 296.94 282.39 296.94Z"
            fill="#6EE8FC"
          />
        </g>
        <g id="Group_5">
          <path
            id="Vector_5"
            d="M301.64 284.54C301.64 285.96 300.49 287.11 299.07 287.11C297.65 287.11 296.5 285.96 296.5 284.54C296.5 283.12 297.65 281.97 299.07 281.97C300.49 281.97 301.64 283.12 301.64 284.54Z"
            fill="#6EE8FC"
          />
        </g>
        <g id="Group_6">
          <path
            id="Vector_6"
            d="M318.33 274.85C318.33 276.27 317.18 277.42 315.76 277.42C314.34 277.42 313.19 276.27 313.19 274.85C313.19 273.43 314.34 272.28 315.76 272.28C317.18 272.29 318.33 273.44 318.33 274.85Z"
            fill="#6EE8FC"
          />
        </g>
        <g id="figure_lights">
          <path
            id="Vector_7"
            d="M85.39 300.85C132.55 300.85 170.78 276.087 170.78 245.54C170.78 214.993 132.55 190.23 85.39 190.23C38.2304 190.23 0 214.993 0 245.54C0 276.087 38.2304 300.85 85.39 300.85Z"
            fill="url(#paint0_radial_1_3)"
            fillOpacity="0.7"
          />
          <path
            id="Vector_8"
            d="M176.39 248.62C223.55 248.62 261.78 223.857 261.78 193.31C261.78 162.763 223.55 138 176.39 138C129.23 138 91 162.763 91 193.31C91 223.857 129.23 248.62 176.39 248.62Z"
            fill="url(#paint1_radial_1_3)"
          />
          <path
            id="Vector_9"
            d="M279.82 347.12C326.98 347.12 365.21 322.357 365.21 291.81C365.21 261.263 326.98 236.5 279.82 236.5C232.66 236.5 194.43 261.263 194.43 291.81C194.43 322.357 232.66 347.12 279.82 347.12Z"
            fill="url(#paint2_radial_1_3)"
            fillOpacity="0.7"
          />
        </g>
      </g>
      <path
        id="figure_shadow"
        d="M194.31 312.82L109.31 263.75C102.85 260.02 103.66 253.51 111.12 249.2L192.5 202.22C199.96 197.91 211.24 197.45 217.7 201.18L302.7 250.25C309.16 253.98 308.35 260.49 300.89 264.8L219.51 311.78C212.05 316.08 200.77 316.55 194.31 312.82Z"
        fill="#0A0E2D"
      />
      <g id="figure_top">
        <g id="Group_7">
          <g id="Group_8">
            <path
              id="Vector_10"
              d="M317.89 206.78V189.2H272.55L218.95 158.25C211.8 154.12 199.3 154.64 191.04 159.41L139.44 189.2H94.08V207.77H94.12C93.92 210.56 95.48 213.26 98.91 215.25L193.05 269.6C200.2 273.73 212.7 273.21 220.96 268.44L311.09 216.4C315.86 213.66 318.16 210.1 317.89 206.78Z"
              fill="#312A91"
            />
            <path
              id="Vector_11"
              opacity="0.5"
              d="M317.89 206.78V189.2H272.55L218.95 158.25C211.8 154.12 199.3 154.64 191.04 159.41L139.44 189.2H94.08V207.77H94.12C93.92 210.56 95.48 213.26 98.91 215.25L193.05 269.6C200.2 273.73 212.7 273.21 220.96 268.44L311.09 216.4C315.86 213.66 318.16 210.1 317.89 206.78Z"
              fill="url(#paint3_linear_1_3)"
            />
            <path
              id="Vector_12"
              d="M218.96 158.26C215.52 156.27 210.85 155.37 206 155.49V272.37C211.24 272.24 216.67 270.92 220.97 268.45L311.1 216.41C315.87 213.66 318.16 210.1 317.9 206.78V189.2H272.56L218.96 158.26Z"
              fill="url(#paint4_linear_1_3)"
            />
            <path
              id="Vector_13"
              d="M193.05 251.03L98.91 196.68C91.76 192.55 92.65 185.34 100.91 180.57L191.04 128.53C199.3 123.76 211.8 123.24 218.95 127.37L313.09 181.72C320.24 185.85 319.35 193.06 311.09 197.83L220.96 249.87C212.7 254.65 200.21 255.16 193.05 251.03Z"
              fill="#312A91"
            />
            <path
              id="Vector_14"
              opacity="0.5"
              d="M193.05 251.03L98.91 196.68C91.76 192.55 92.65 185.34 100.91 180.57L191.04 128.53C199.3 123.76 211.8 123.24 218.95 127.37L313.09 181.72C320.24 185.85 319.35 193.06 311.09 197.83L220.96 249.87C212.7 254.65 200.21 255.16 193.05 251.03Z"
              fill="url(#paint5_linear_1_3)"
            />
            <path
              id="Vector_15"
              d="M195.57 239.02L119.72 195.23C113.96 191.9 114.68 186.09 121.33 182.25L193.95 140.32C200.61 136.48 210.67 136.06 216.44 139.39L292.29 183.18C298.05 186.51 297.33 192.32 290.68 196.16L218.06 238.09C211.4 241.93 201.33 242.35 195.57 239.02Z"
              fill="#5CFFF3"
            />
            <path
              id="Vector_16"
              d="M121.33 187.93L193.95 146C200.61 142.16 210.67 141.74 216.44 145.07L292.29 188.86C293.71 189.68 294.74 190.66 295.38 191.71C297.05 188.64 296.09 185.37 292.29 183.18L216.44 139.39C210.68 136.06 200.61 136.48 193.95 140.32L121.33 182.25C116.32 185.14 114.67 189.15 116.63 192.38C117.5 190.78 119.06 189.24 121.33 187.93Z"
              fill="#42E8E0"
            />
            <path
              id="Vector_17"
              opacity="0.5"
              d="M196.21 251.03L102.07 196.68C94.92 192.55 93.21 186.48 104.07 180.57L194.2 128.53C198.26 126.18 203.35 124.87 208.32 124.63C202.41 124.36 195.97 125.68 191.04 128.53L100.91 180.57C92.65 185.34 91.75 192.55 98.91 196.68L193.05 251.03C196.69 253.13 201.7 254.02 206.84 253.77C202.85 253.6 199.1 252.7 196.21 251.03Z"
              fill="url(#paint6_linear_1_3)"
            />
            <path
              id="Vector_18"
              opacity="0.5"
              d="M311.09 205.69L220.96 257.73C212.7 262.5 200.2 263.02 193.05 258.89L98.91 204.54C95.47 202.56 93.91 199.86 94.12 197.06H94.08V200.91H94.12C93.92 203.7 95.48 206.4 98.91 208.39L193.05 262.74C200.2 266.87 212.7 266.35 220.96 261.58L311.09 209.54C315.86 206.79 318.15 203.23 317.89 199.91V196.06C318.16 199.38 315.86 202.94 311.09 205.69Z"
              fill="url(#paint7_linear_1_3)"
            />
            <g id="Group_9">
              <path
                id="Vector_19"
                d="M294.75 200.56C295.78 201.15 295.78 202.11 294.75 202.71C293.72 203.3 292.06 203.3 291.03 202.71C290 202.12 290 201.16 291.03 200.56C292.06 199.96 293.72 199.97 294.75 200.56Z"
                fill="#FCCC6B"
              />
              <path
                id="Vector_20"
                d="M300.54 197.22C301.57 197.81 301.57 198.77 300.54 199.37C299.51 199.97 297.85 199.96 296.82 199.37C295.79 198.78 295.79 197.82 296.82 197.22C297.85 196.63 299.52 196.63 300.54 197.22Z"
                fill="#FCCC6B"
              />
              <path
                id="Vector_21"
                d="M306.34 193.87C307.37 194.46 307.37 195.42 306.34 196.02C305.31 196.61 303.65 196.61 302.62 196.02C301.59 195.43 301.59 194.47 302.62 193.87C303.65 193.27 305.31 193.28 306.34 193.87Z"
                fill="#FCCC6B"
              />
            </g>
          </g>
          <g id="Group_10">
            <path
              id="Vector_22"
              opacity="0.5"
              d="M197.82 228.41L138.32 194.06C133.8 191.45 134.37 186.89 139.59 183.88L196.55 150.99C201.77 147.98 209.67 147.65 214.19 150.26L273.69 184.61C278.21 187.22 277.64 191.78 272.42 194.79L215.46 227.68C210.24 230.7 202.34 231.03 197.82 228.41Z"
              fill="#0B0244"
            />
            <path
              id="Vector_23"
              opacity="0.5"
              d="M317.89 189.2C317.91 188.87 317.92 188.53 317.89 188.21V139.12L239.9 139.47L218.95 127.38C211.8 123.25 199.3 123.77 191.04 128.54L171.57 139.78L94.08 140.12V189.21H94.12C93.92 192 95.48 194.7 98.91 196.69L193.05 251.04C200.2 255.17 212.7 254.65 220.96 249.88L311.09 197.84C315.39 195.36 317.67 192.23 317.89 189.2Z"
              fill="#5CFFF3"
            />
            <path
              id="Vector_24"
              d="M317.89 139.12V121.55H272.55L218.95 90.6C211.8 86.47 199.3 86.99 191.04 91.76L139.44 121.55H94.08V140.12H94.12C93.92 142.91 95.48 145.61 98.91 147.6L193.05 201.95C200.2 206.08 212.7 205.56 220.96 200.79L311.09 148.75C315.86 146 318.16 142.44 317.89 139.12Z"
              fill="#312A91"
            />
            <path
              id="Vector_25"
              opacity="0.5"
              d="M317.89 139.12V121.55H272.55L218.95 90.6C211.8 86.47 199.3 86.99 191.04 91.76L139.44 121.55H94.08V140.12H94.12C93.92 142.91 95.48 145.61 98.91 147.6L193.05 201.95C200.2 206.08 212.7 205.56 220.96 200.79L311.09 148.75C315.86 146 318.16 142.44 317.89 139.12Z"
              fill="url(#paint8_linear_1_3)"
            />
            <path
              id="Vector_26"
              d="M218.96 90.6C215.52 88.61 210.85 87.71 206 87.83V204.71C211.24 204.58 216.67 203.27 220.97 200.79L311.1 148.75C315.87 146 318.16 142.44 317.9 139.12V121.55H272.56L218.96 90.6Z"
              fill="url(#paint9_linear_1_3)"
            />
            <path
              id="Vector_27"
              d="M193.05 183.38L98.91 129.03C91.76 124.9 92.65 117.69 100.91 112.92L191.04 60.88C199.3 56.11 211.8 55.59 218.95 59.72L313.09 114.07C320.24 118.2 319.35 125.41 311.09 130.18L220.96 182.22C212.7 186.99 200.21 187.51 193.05 183.38Z"
              fill="#312A91"
            />
            <path
              id="Vector_28"
              opacity="0.5"
              d="M193.05 183.38L98.91 129.03C91.76 124.9 92.65 117.69 100.91 112.92L191.04 60.88C199.3 56.11 211.8 55.59 218.95 59.72L313.09 114.07C320.24 118.2 319.35 125.41 311.09 130.18L220.96 182.22C212.7 186.99 200.21 187.51 193.05 183.38Z"
              fill="url(#paint10_linear_1_3)"
            />
            <path
              id="Vector_29"
              opacity="0.5"
              d="M196.21 183.38L102.07 129.03C94.92 124.9 93.21 118.83 104.07 112.92L194.2 60.88C198.26 58.53 203.35 57.22 208.32 56.98C202.41 56.71 195.97 58.03 191.04 60.88L100.91 112.92C92.65 117.69 91.75 124.9 98.91 129.03L193.05 183.38C196.69 185.48 201.7 186.37 206.84 186.12C202.85 185.94 199.1 185.04 196.21 183.38Z"
              fill="url(#paint11_linear_1_3)"
            />
            <path
              id="Vector_30"
              opacity="0.5"
              d="M311.09 138.03L220.96 190.07C212.7 194.84 200.2 195.36 193.05 191.23L98.91 136.88C95.47 134.9 93.91 132.2 94.12 129.4H94.08V133.25H94.12C93.92 136.04 95.48 138.74 98.91 140.73L193.05 195.08C200.2 199.21 212.7 198.69 220.96 193.92L311.09 141.88C315.86 139.13 318.15 135.57 317.89 132.25V128.4C318.16 131.72 315.86 135.28 311.09 138.03Z"
              fill="url(#paint12_linear_1_3)"
            />
          </g>
          <g id="Group_11">
            <path
              id="figure_circle_1"
              d="M198.52 125.48C200.48 126.61 200.48 128.44 198.52 129.57C196.56 130.7 193.4 130.7 191.44 129.57C189.48 128.44 189.48 126.61 191.44 125.48C193.39 124.35 196.56 124.35 198.52 125.48Z"
              fill="#5CFFF3"
            />
            <path
              id="figure_circle_2"
              d="M209.54 119.12C211.5 120.25 211.5 122.08 209.54 123.21C207.58 124.34 204.42 124.34 202.46 123.21C200.5 122.08 200.5 120.25 202.46 119.12C204.42 117.99 207.59 117.99 209.54 119.12Z"
              fill="#5CFFF3"
            />
            <path
              id="figure_circle_3"
              d="M220.57 112.75C222.53 113.88 222.53 115.71 220.57 116.84C218.61 117.97 215.45 117.97 213.49 116.84C211.53 115.71 211.53 113.88 213.49 112.75C215.45 111.62 218.62 111.62 220.57 112.75Z"
              fill="#5CFFF3"
            />
          </g>
          <path
            id="Vector_31"
            opacity="0.5"
            d="M205.39 170.02C201.9 170.02 198.56 169.31 196.08 167.88L126.47 127.69C123.94 126.23 122.54 124.17 122.54 121.9C122.54 119.21 124.51 116.53 127.95 114.55L194.59 76.07C200.88 72.44 210.46 72.05 215.93 75.21L285.54 115.4C288.07 116.86 289.47 118.92 289.47 121.19C289.47 123.88 287.5 126.56 284.06 128.54L217.42 167.02C213.99 169.01 209.58 170.02 205.39 170.02ZM196.78 166.65C201.86 169.59 210.8 169.2 216.71 165.79L283.35 127.31C286.33 125.59 288.05 123.35 288.05 121.19C288.05 119.45 286.91 117.83 284.84 116.63L215.23 76.44C210.15 73.5 201.21 73.89 195.3 77.3L128.66 115.78C125.67 117.5 123.96 119.73 123.96 121.9C123.96 123.64 125.1 125.26 127.18 126.46L196.78 166.65Z"
            fill="#0B0244"
          />
        </g>
        <g id="Group_12">
          <path
            id="Vector_32"
            d="M204.98 197.43C201.15 197.43 197.5 196.66 194.77 195.08L75.51 126.22C72.84 124.68 71.37 122.54 71.37 120.19C71.37 117.37 73.49 114.53 77.18 112.4L193.1 45.48C200.03 41.48 210.57 41.04 216.58 44.52L335.84 113.38C338.51 114.92 339.98 117.06 339.98 119.41C339.98 122.23 337.86 125.07 334.17 127.2L218.25 194.12C214.46 196.3 209.59 197.42 204.98 197.43ZM206.37 42.61C201.84 42.61 197.05 43.71 193.32 45.87L77.4 112.79C73.85 114.84 71.82 117.54 71.82 120.19C71.82 122.37 73.21 124.37 75.73 125.83L194.99 194.69C200.88 198.09 211.22 197.66 218.03 193.73L333.95 126.81C337.5 124.76 339.53 122.06 339.53 119.41C339.53 117.23 338.14 115.23 335.62 113.77L216.35 44.91C213.69 43.37 210.11 42.61 206.37 42.61Z"
            fill="#6EE8FC"
          />
          <g id="Group_13">
            <path
              id="Vector_33"
              d="M178.72 55.49C178.72 56.91 177.57 58.06 176.15 58.06C174.73 58.06 173.58 56.91 173.58 55.49C173.58 54.07 174.73 52.92 176.15 52.92C177.57 52.92 178.72 54.07 178.72 55.49Z"
              fill="#6EE8FC"
            />
          </g>
          <g id="Group_14">
            <path
              id="Vector_34"
              d="M85.39 110.56C86.8094 110.56 87.96 109.409 87.96 107.99C87.96 106.571 86.8094 105.42 85.39 105.42C83.9706 105.42 82.82 106.571 82.82 107.99C82.82 109.409 83.9706 110.56 85.39 110.56Z"
              fill="#6EE8FC"
            />
          </g>
          <g id="Group_15">
            <path
              id="Vector_35"
              d="M284.96 156.83C284.96 158.25 283.81 159.4 282.39 159.4C280.97 159.4 279.82 158.25 279.82 156.83C279.82 155.41 280.97 154.26 282.39 154.26C283.81 154.26 284.96 155.41 284.96 156.83Z"
              fill="#6EE8FC"
            />
          </g>
          <g id="Group_16">
            <path
              id="Vector_36"
              d="M299.08 149.56C300.499 149.56 301.65 148.409 301.65 146.99C301.65 145.571 300.499 144.42 299.08 144.42C297.661 144.42 296.51 145.571 296.51 146.99C296.51 148.409 297.661 149.56 299.08 149.56Z"
              fill="#6EE8FC"
            />
          </g>
          <g id="Group_17">
            <path
              id="Vector_37"
              d="M318.33 137.31C318.33 138.73 317.18 139.88 315.76 139.88C314.34 139.88 313.19 138.73 313.19 137.31C313.19 135.89 314.34 134.74 315.76 134.74C317.18 134.74 318.33 135.89 318.33 137.31Z"
              fill="#6EE8FC"
            />
          </g>
          <g id="figure_lights_2">
            <path
              id="Vector_38"
              d="M85.39 163.3C132.55 163.3 170.78 138.537 170.78 107.99C170.78 77.4431 132.55 52.68 85.39 52.68C38.2304 52.68 0 77.4431 0 107.99C0 138.537 38.2304 163.3 85.39 163.3Z"
              fill="url(#paint13_radial_1_3)"
              fillOpacity="0.7"
            />
            <path
              id="Vector_39"
              d="M176.15 110.8C223.31 110.8 261.54 86.0369 261.54 55.49C261.54 24.9431 223.31 0.179993 176.15 0.179993C128.99 0.179993 90.76 24.9431 90.76 55.49C90.76 86.0369 128.99 110.8 176.15 110.8Z"
              fill="url(#paint14_radial_1_3)"
              fillOpacity="0.7"
            />
            <path
              id="Vector_40"
              d="M282.39 212.14C329.55 212.14 367.78 187.377 367.78 156.83C367.78 126.283 329.55 101.52 282.39 101.52C235.23 101.52 197 126.283 197 156.83C197 187.377 235.23 212.14 282.39 212.14Z"
              fill="url(#paint15_radial_1_3)"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_1_3"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(85.3898 245.534) scale(83.9548 55.3087)"
      >
        <stop stopColor="#6EE8FC" stopOpacity="0.5" />
        <stop offset="0.0548" stopColor="#5EC1DA" stopOpacity="0.4726" />
        <stop offset="0.1224" stopColor="#4D98B7" stopOpacity="0.4388" />
        <stop offset="0.1964" stopColor="#3E7497" stopOpacity="0.4018" />
        <stop offset="0.276" stopColor="#32567D" stopOpacity="0.362" />
        <stop offset="0.363" stopColor="#283D67" stopOpacity="0.3185" />
        <stop offset="0.4603" stopColor="#202A56" stopOpacity="0.2698" />
        <stop offset="0.5738" stopColor="#1A1D4B" stopOpacity="0.2131" />
        <stop offset="0.7185" stopColor="#171544" stopOpacity="0.1408" />
        <stop offset="1" stopColor="#161342" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1_3"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(176.39 193.304) scale(83.9548 55.3087)"
      >
        <stop stopColor="#6EE8FC" stopOpacity="0.5" />
        <stop offset="0.0548" stopColor="#5EC1DA" stopOpacity="0.4726" />
        <stop offset="0.1224" stopColor="#4D98B7" stopOpacity="0.4388" />
        <stop offset="0.1964" stopColor="#3E7497" stopOpacity="0.4018" />
        <stop offset="0.276" stopColor="#32567D" stopOpacity="0.362" />
        <stop offset="0.363" stopColor="#283D67" stopOpacity="0.3185" />
        <stop offset="0.4603" stopColor="#202A56" stopOpacity="0.2698" />
        <stop offset="0.5738" stopColor="#1A1D4B" stopOpacity="0.2131" />
        <stop offset="0.7185" stopColor="#171544" stopOpacity="0.1408" />
        <stop offset="1" stopColor="#161342" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1_3"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(279.82 291.8) scale(83.9548 55.3087)"
      >
        <stop stopColor="#6EE8FC" stopOpacity="0.5" />
        <stop offset="0.0548" stopColor="#5EC1DA" stopOpacity="0.4726" />
        <stop offset="0.1224" stopColor="#4D98B7" stopOpacity="0.4388" />
        <stop offset="0.1964" stopColor="#3E7497" stopOpacity="0.4018" />
        <stop offset="0.276" stopColor="#32567D" stopOpacity="0.362" />
        <stop offset="0.363" stopColor="#283D67" stopOpacity="0.3185" />
        <stop offset="0.4603" stopColor="#202A56" stopOpacity="0.2698" />
        <stop offset="0.5738" stopColor="#1A1D4B" stopOpacity="0.2131" />
        <stop offset="0.7185" stopColor="#171544" stopOpacity="0.1408" />
        <stop offset="1" stopColor="#161342" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_1_3"
        x1="44.6053"
        y1="213.931"
        x2="268.436"
        y2="213.931"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1022" stopColor="#3CCACE" />
        <stop offset="0.3445" stopColor="#2F87A5" />
        <stop offset="0.564" stopColor="#255385" />
        <stop offset="0.7534" stopColor="#1E2D6E" />
        <stop offset="0.905" stopColor="#1A1660" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_3"
        x1="205.997"
        y1="213.926"
        x2="317.912"
        y2="213.926"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2B237C" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1_3"
        x1="94.0817"
        y1="189.204"
        x2="317.928"
        y2="189.204"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1022" stopColor="#3CCACE" />
        <stop offset="0.3445" stopColor="#2F87A5" />
        <stop offset="0.564" stopColor="#255385" />
        <stop offset="0.7534" stopColor="#1E2D6E" />
        <stop offset="0.905" stopColor="#1A1660" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1_3"
        x1="94.0817"
        y1="189.207"
        x2="208.328"
        y2="189.207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1022" stopColor="#3CCACE" />
        <stop offset="0.3445" stopColor="#2F87A5" />
        <stop offset="0.564" stopColor="#255385" />
        <stop offset="0.7534" stopColor="#1E2D6E" />
        <stop offset="0.905" stopColor="#1A1660" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1_3"
        x1="124.527"
        y1="254.339"
        x2="311.885"
        y2="131.876"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1022" stopColor="#3CCACE" />
        <stop offset="0.3445" stopColor="#2F87A5" />
        <stop offset="0.564" stopColor="#255385" />
        <stop offset="0.7534" stopColor="#1E2D6E" />
        <stop offset="0.905" stopColor="#1A1660" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1_3"
        x1="44.6053"
        y1="146.274"
        x2="268.436"
        y2="146.274"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1022" stopColor="#3CCACE" />
        <stop offset="0.3445" stopColor="#2F87A5" />
        <stop offset="0.564" stopColor="#255385" />
        <stop offset="0.7534" stopColor="#1E2D6E" />
        <stop offset="0.905" stopColor="#1A1660" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1_3"
        x1="205.997"
        y1="146.269"
        x2="317.912"
        y2="146.269"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2B237C" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1_3"
        x1="94.0817"
        y1="121.547"
        x2="317.928"
        y2="121.547"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1022" stopColor="#3CCACE" />
        <stop offset="0.3445" stopColor="#2F87A5" />
        <stop offset="0.564" stopColor="#255385" />
        <stop offset="0.7534" stopColor="#1E2D6E" />
        <stop offset="0.905" stopColor="#1A1660" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1_3"
        x1="94.0817"
        y1="121.551"
        x2="208.328"
        y2="121.551"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1022" stopColor="#3CCACE" />
        <stop offset="0.3445" stopColor="#2F87A5" />
        <stop offset="0.564" stopColor="#255385" />
        <stop offset="0.7534" stopColor="#1E2D6E" />
        <stop offset="0.905" stopColor="#1A1660" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1_3"
        x1="124.527"
        y1="186.682"
        x2="311.886"
        y2="64.2198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1022" stopColor="#3CCACE" />
        <stop offset="0.3445" stopColor="#2F87A5" />
        <stop offset="0.564" stopColor="#255385" />
        <stop offset="0.7534" stopColor="#1E2D6E" />
        <stop offset="0.905" stopColor="#1A1660" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <radialGradient
        id="paint13_radial_1_3"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(85.3898 107.991) scale(83.9548 55.3087)"
      >
        <stop stopColor="#6EE8FC" stopOpacity="0.5" />
        <stop offset="0.0548" stopColor="#5EC1DA" stopOpacity="0.4726" />
        <stop offset="0.1224" stopColor="#4D98B7" stopOpacity="0.4388" />
        <stop offset="0.1964" stopColor="#3E7497" stopOpacity="0.4018" />
        <stop offset="0.276" stopColor="#32567D" stopOpacity="0.362" />
        <stop offset="0.363" stopColor="#283D67" stopOpacity="0.3185" />
        <stop offset="0.4603" stopColor="#202A56" stopOpacity="0.2698" />
        <stop offset="0.5738" stopColor="#1A1D4B" stopOpacity="0.2131" />
        <stop offset="0.7185" stopColor="#171544" stopOpacity="0.1408" />
        <stop offset="1" stopColor="#161342" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_1_3"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(176.147 55.4878) scale(83.9548 55.3087)"
      >
        <stop stopColor="#6EE8FC" stopOpacity="0.5" />
        <stop offset="0.0548" stopColor="#5EC1DA" stopOpacity="0.4726" />
        <stop offset="0.1224" stopColor="#4D98B7" stopOpacity="0.4388" />
        <stop offset="0.1964" stopColor="#3E7497" stopOpacity="0.4018" />
        <stop offset="0.276" stopColor="#32567D" stopOpacity="0.362" />
        <stop offset="0.363" stopColor="#283D67" stopOpacity="0.3185" />
        <stop offset="0.4603" stopColor="#202A56" stopOpacity="0.2698" />
        <stop offset="0.5738" stopColor="#1A1D4B" stopOpacity="0.2131" />
        <stop offset="0.7185" stopColor="#171544" stopOpacity="0.1408" />
        <stop offset="1" stopColor="#161342" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_1_3"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(282.386 156.823) scale(83.9548 55.3087)"
      >
        <stop stopColor="#6EE8FC" stopOpacity="0.5" />
        <stop offset="0.0548" stopColor="#5EC1DA" stopOpacity="0.4726" />
        <stop offset="0.1224" stopColor="#4D98B7" stopOpacity="0.4388" />
        <stop offset="0.1964" stopColor="#3E7497" stopOpacity="0.4018" />
        <stop offset="0.276" stopColor="#32567D" stopOpacity="0.362" />
        <stop offset="0.363" stopColor="#283D67" stopOpacity="0.3185" />
        <stop offset="0.4603" stopColor="#202A56" stopOpacity="0.2698" />
        <stop offset="0.5738" stopColor="#1A1D4B" stopOpacity="0.2131" />
        <stop offset="0.7185" stopColor="#171544" stopOpacity="0.1408" />
        <stop offset="1" stopColor="#161342" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export { HubPicture };
