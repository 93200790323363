import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getAsAtModelById } from '@store/actions/properties/AsAt/Model';
import { selectAsAtModel } from '@store/selectors/properties/AsAt/Model';

import type { IOutletAssetModel } from '@constants/OutletContextEntityPages';
import type { CreateAsAtModelDto } from '@model/properties/AsAt/Model';

function AssetModel() {
  const assetModel = useAppSelector(selectAsAtModel);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !assetModel || assetModel.nonCurrAssetCardModelId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getAsAtModelById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateAsAtModelDto = {
    nonCurrAssetCardModelCode: assetModel.nonCurrAssetCardModelCode,
    name: assetModel.name,
    nonCurrAssetCardBrandId: assetModel.nonCurrAssetCardBrandId,
  };

  return <Outlet context={{ initialValues, entity: assetModel } as IOutletAssetModel} />;
}

export default AssetModel;
