import React from 'react';

import { Row } from 'antd';

import type { RowProps } from 'antd';

export interface ContainerProps extends RowProps {}

const Container = (props: ContainerProps) => {
  const { justify = 'space-between', gutter = [30, 0], style, wrap = true, ...rest } = props;

  return (
    <Row
      justify={justify}
      gutter={gutter}
      style={{
        maxWidth: 919,
        ...style,
      }}
      wrap={wrap}
      {...rest}
    />
  );
};

export default Container;
