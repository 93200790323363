import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getPaIProperties } from '@store/actions/properties/PaI/Property';
import { selectPaIPropertiesList } from '@store/selectors/properties/PaI/Property';

function EntryPartAndInventoryProperty() {
  useSetPageInfo(
    {
      pageTitle: 'pages_single.PaI_Attr',
      defaultPagePath: paths.partAndInventoryAttributes.tabs.property.default,
    },
    {
      copyTabs: true,
    }
  );

  const partAndInventoryPropertyList = useAppSelector(selectPaIPropertiesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!partAndInventoryPropertyList.length) {
      dispatch(getPaIProperties());
    }
  }, []);

  return <Outlet />;
}

export default EntryPartAndInventoryProperty;
