import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { statusColor } from '@constants/statusColors';
import { useAppSelector } from '@hooks';
import { ColorPicker, Input } from '@shared/UI';
import { selectAsAtStatusStatus } from '@store/selectors/properties/AsAt/Status';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateAsAtStatusDto } from '@model/properties/AsAt/Status';

const { CaseField, Item, useForm } = CommonForm;

function FormAssetStatus(props: CommonFormProps<CreateAsAtStatusDto>) {
  const [form] = useForm<CreateAsAtStatusDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const assetStatusStatus = useAppSelector(selectAsAtStatusStatus);

  return (
    <CommonForm 
      form={form} 
      loading={assetStatusStatus === 'pending'} 
      isModal={isModal}
      name='formAssetStatus'
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onCancel={rest.header?.onClickCancel} /> : null
      }
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="nonCurrAssetStatusCode"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item name="color" label={t('titles.Color')}>
          <ColorPicker colors={statusColor} />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormAssetStatus };
