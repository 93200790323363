/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppDispatch } from '@hooks';
import { Checkbox, Input, Select } from '@shared/UI';
import { getAssetsByCardId } from '@store/actions/management/Asset';

import { DateBlock } from '../DateBlock';
import { OccurrenceField } from '../OccurrenceField';

import type { AssetModel } from '@model/management/Asset';
import type { CreateMaintenanceGeneralV2Dto } from '@model/management/Maintenance';
import type { AsAtCardModel } from '@model/properties/AsAt/Card';
import type { AsAtCategoryModel } from '@model/properties/AsAt/Category';
import type { MaAtRequestReasonModel } from '@model/properties/MaAt/RequestReason';
import type { IterationModalState } from '../_util';

interface RightSectionFieldsProps {
  iterationState: IterationModalState;

  assetCategoriesList: Array<Omit<AsAtCategoryModel, 'childNonCurrAssetCardCategories'>>;

  maiReasonsList: Array<MaAtRequestReasonModel>;

  assetCardsList: Array<AsAtCardModel>;

  selectOccurrence: (type: number) => void;

  validateOccurrenceSelect: () => Promise<any>;

  onOpenModal: () => void;

  onMaiReasonOpenModal: () => void;

  onAssetCardOpenModal: () => void;

  onAssetCategoryOpenModal: () => void;
}

const { Item, CaseField, useWatch, useFormInstance } = CommonForm;

const assetCategoryRules = [{ required: true }];

const assetCardRules = [{ required: true }];

// region --- Select field names

const requestRulesIdNames = { label: 'reasonName', value: 'requestReasonId' };

const assetCardNames = { label: 'name', value: 'nonCurrAssetCardId' };

const assetBarcodeNames = { label: 'barcode', value: 'barcode' };

const assetCategoryNames = { label: 'name', value: 'nonCurrAssetCardCategoryId' };

// endregion --- Select field names

export const RightSectionFields = memo((props: RightSectionFieldsProps) => {
  const {
    assetCategoriesList,
    maiReasonsList,
    assetCardsList,
    selectOccurrence,
    validateOccurrenceSelect,
    iterationState,
    onOpenModal,
    onMaiReasonOpenModal,
    onAssetCardOpenModal,
    onAssetCategoryOpenModal,
  } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const form = useFormInstance<CreateMaintenanceGeneralV2Dto>();

  const [assetsByCardId, setAssetsByCardId] = useState<Array<AssetModel>>([]);

  const [loadingAssetBarcode, setLoadingAssetBarcode] = useState(false);

  // region --- Observed values
  const assetCardId = useWatch('nonCurrAssetCardId', form);

  const assetCategoryId = useWatch('nonCurrAssetCardCategoryId', form);
  // endregion --- Observed values

  const getAssetBarcodes = (cardId: number) => {
    setLoadingAssetBarcode(true);
    dispatch(getAssetsByCardId(cardId))
      .unwrap()
      .then((res) => {
        setAssetsByCardId(res);
      })
      .catch(() => {
        setAssetsByCardId([]);
      })
      .finally(() => {
        setLoadingAssetBarcode(false);
      });
  };

  const assetCardOptions = assetCardsList.filter((card) => {
    if (!assetCategoryId) return false;

    return card.nonCurrAssetCardCategoryId === assetCategoryId;
  });

  const notFoundBarcode = !assetCardId
    ? t('titles.You_First_Need_To_Select', { name: t('titles.Asset_Card') })
    : t('titles.Not_Found');

  const notFoundCard = !assetCategoryId
    ? t('titles.You_First_Need_To_Select', { name: t('titles.Card_Category') })
    : t('titles.Not_Found');

  const handleCardValue = (value: number | null) => {
    if (typeof value === 'number') {
      getAssetBarcodes(value);
    } else {
      setAssetsByCardId([]);
    }

    form.setFieldValue('barcode', null);

    return value;
  };

  const handleBarCodeValue = (value: string | null) => {
    const selectedItem = assetsByCardId.find((item) => item.barcode === value);
    form.setFieldValue('nonCurrAssetId', selectedItem?.nonCurrAssetId);
    return value;
  };

  const handleCategoryValue = (value: number | null) => {
    form.setFieldValue('nonCurrAssetCardId', null);
    form.setFieldValue('barcode', null);
    setAssetsByCardId([]);
    return value;
  };

  return (
    <CaseField>
      <Item valuePropName="checked" name="isRequest">
        <Checkbox>{t('titles.Request')}</Checkbox>
      </Item>

      <DateBlock />

      <OccurrenceField
        onOpenModal={onOpenModal}
        iterationState={iterationState}
        onSelect={selectOccurrence}
        validator={validateOccurrenceSelect}
      />
      <Item name="requestReasonId" label={t('titles.Reason')}>
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Reason') })}
          options={maiReasonsList}
          fieldNames={requestRulesIdNames}
          isCreatable
          creatableAction={onMaiReasonOpenModal}
        />
      </Item>
      <Item
        label={t('titles.Asset_Category')}
        name="nonCurrAssetCardCategoryId"
        getValueFromEvent={handleCategoryValue}
        rules={assetCategoryRules}
      >
        <Select
          options={assetCategoriesList}
          fieldNames={assetCategoryNames}
          placeholder={t('titles.Choose_Name', { name: t('titles.Card_Category') })}
          isCreatable
          creatableAction={onAssetCategoryOpenModal}
        />
      </Item>

      <Item
        label={t('titles.Asset_Card')}
        name="nonCurrAssetCardId"
        getValueFromEvent={handleCardValue}
        rules={assetCardRules}
      >
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Asset_Card') })}
          options={assetCardOptions}
          fieldNames={assetCardNames}
          isCreatable
          creatableAction={onAssetCardOpenModal}
          notFoundContent={notFoundCard}
        />
      </Item>

      <Item name="barcode" label={t('titles.Asset_Barcode')} getValueFromEvent={handleBarCodeValue}>
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Barcode') })}
          options={assetsByCardId}
          fieldNames={assetBarcodeNames}
          loading={loadingAssetBarcode}
          notFoundContent={notFoundBarcode}
        />
      </Item>
      <Item name="nonCurrAssetId">
        <Input type="hidden" />
      </Item>
    </CaseField>
  );
});
