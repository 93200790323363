import * as React from 'react';
import { memo } from 'react';

import type { SVGProps } from 'react';

export const FieldSearchIcon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.614 3a7.364 7.364 0 1 0 0 14.727 7.364 7.364 0 0 0 0-14.727Z"
      stroke="#697288"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="m16.11 15.855 5.142 5.143"
      stroke="#697288"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
));
