import { useEffect } from 'react';

import { getCities, getCountries } from '@store/actions/extra/Definition';
import {
  selectCitiesList,
  selectCountriesList,
  selectDefinitionError,
  selectDefinitionStatus,
} from '@store/selectors/extra/Definition';

import { useAppDispatch, useAppSelector } from '../redux-hooks';

function useGetDefinition() {
  const cities = useAppSelector(selectCitiesList);
  const countries = useAppSelector(selectCountriesList);
  const status = useAppSelector(selectDefinitionStatus);
  const error = useAppSelector(selectDefinitionError);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!cities.length) {
      dispatch(getCities());
    }
    if (!countries.length) {
      dispatch(getCountries());
    }
  }, []);

  return { cities, countries, status, error };
}

export default useGetDefinition;
