import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getUserAlerts } from '@store/actions/extra/Alert';

function Notifications() {
  useSetPageInfo(
    {
      pageTitle: 'pages_single.Profile',
      defaultPagePath: paths.profile.tabs.notifications.default,
    },
    { copyTabs: true }
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserAlerts());
  }, []);

  return <Outlet />;
}

export default Notifications;
