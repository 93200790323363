import { createSelector } from '@reduxjs/toolkit';
import { liftChildToTop } from '@shared/utils/functions';

import type { RootState } from '@store/rootReducer';

export const selectAsAtCategoryState = (state: RootState) => state.AsAtCategoryReducer;

export const selectAsAtCategoriesList = (state: RootState) =>
  selectAsAtCategoryState(state).AsAtCategoriesList;

export const selectAsAtCategory = (state: RootState) => selectAsAtCategoryState(state).AsAtCategory;

export const selectAsAtCategoryStatus = (state: RootState) =>
  selectAsAtCategoryState(state).AsAtCategoryStatus;

export const selectAsAtCategoryError = (state: RootState) =>
  selectAsAtCategoryState(state).AsAtCategoryError;

export const selectAsAtCategoriesListWithoutChildren = createSelector(
  selectAsAtCategoriesList,
  (departmentsList) => {
    return liftChildToTop(departmentsList, 'childNonCurrAssetCardCategories');
  }
);
