import React from 'react';

import { checkStepKeys, useCheckStepContext } from '../_utils';
import { BaseField } from './BaseField';
import { MultipleChoice } from './MultipleChoice';

import styles from './Checklist.module.scss';

export const Checklist = () => {
  const { step } = useCheckStepContext();

  const renderContent = () => {
    switch (step.stepKey) {
      case checkStepKeys.multiple:
        return <MultipleChoice />;
      default:
        return <BaseField />;
    }
  };

  return <div className={styles.checklist}>{renderContent()}</div>;
};
