import { api } from '@services/Axios';

import type {
  CreateMaintenanceRequestReasonDto,
  MaAtRequestReasonModel,
  UpdateMaintenanceRequestReasonDto,
} from '@model/properties/MaAt/RequestReason';
import type { ApiResponse } from '@services/Axios';

const MaAtRequestReasonEndpoint = '/RequestReasons';

const MaAtRequestReasonRepository = {
  async fetchAll() {
    const response: ApiResponse<MaAtRequestReasonModel[]> = await api.get(
      `${MaAtRequestReasonEndpoint}/GetRequestReasons`
    );

    return response.data;
  },
  async add(dto: CreateMaintenanceRequestReasonDto) {
    const response: ApiResponse<MaAtRequestReasonModel> = await api.post(
      `${MaAtRequestReasonEndpoint}/AddRequestReasons`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<MaAtRequestReasonModel> = await api.get(
      `${MaAtRequestReasonEndpoint}/GetRequestReasonsById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdateMaintenanceRequestReasonDto) {
    const response: ApiResponse<MaAtRequestReasonModel> = await api.post(
      `${MaAtRequestReasonEndpoint}/UpdateRequestReasons`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(
      `${MaAtRequestReasonEndpoint}/RemoveByIdList`,
      {
        RequestReasonIds: ids,
      }
    );

    return response.data;
  },
};

export { MaAtRequestReasonRepository };
