import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getPaIModelById } from '@store/actions/properties/PaI/Model';
import { selectPaIModel } from '@store/selectors/properties/PaI/Model';

import type { IOutletPartAndInventoryModel } from '@constants/OutletContextEntityPages';
import type { CreatePaIModelDto } from '@model/properties/PaI/Model';

function PartAndInventoryModel() {
  const partAndInventoryModel = useAppSelector(selectPaIModel);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition =
    !partAndInventoryModel || partAndInventoryModel.partsAndInventoriesCardModelId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getPaIModelById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreatePaIModelDto = {
    partsAndInventoriesCardModelCode: partAndInventoryModel.partsAndInventoriesCardModelCode,
    name: partAndInventoryModel.name,
    partsAndInventoriesCardBrandId: partAndInventoryModel.partsAndInventoriesCardBrandId,
  };

  return (
    <Outlet
      context={{ initialValues, entity: partAndInventoryModel } as IOutletPartAndInventoryModel}
    />
  );
}

export default PartAndInventoryModel;
