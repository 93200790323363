import React from 'react';

import { useClickOutside, useModalState } from '@hooks';
import cl from 'classnames';

import { SquarePlusButton } from '../SquarePlusButton';
import { StepTypes } from './StepTypes';

import type { StepTypesProps } from './StepTypes';

import styles from './StepTypesList.module.scss';

export type StepTypesListProps = {} & Pick<StepTypesProps, 'items' | 'onClick'>;

export const StepTypesList = (props: StepTypesListProps) => {
  const { items, onClick } = props;

  const popupHandler = useModalState();

  const handleClickOutside = () => {
    popupHandler.actions.onClose();
  };

  const divRef = useClickOutside<HTMLDivElement>(handleClickOutside);

  const handleButtonClick = () => {
    popupHandler.actions.onToggle();
  };

  const classes = cl(styles.stepTypesList, { [styles.stepTypesListActive]: popupHandler.isOpen });

  return (
    <div className={classes} ref={divRef}>
      <SquarePlusButton className={styles.button} onClick={handleButtonClick} />
      <StepTypes active={popupHandler.isOpen} items={items} onClick={onClick} />
    </div>
  );
};
