import React from 'react';

import { PreviewFormMaintenanceParts } from '@components/views/Previews/management';
import { useOutletMaintenance } from '@constants/OutletContextEntityPages';

const PreviewMaintenanceParts = () => {
  const { entity } = useOutletMaintenance();

  return <PreviewFormMaintenanceParts entity={entity} />;
};

export default PreviewMaintenanceParts;
