import { dateDash, dateWithTimeDash } from '@constants/Date';
import { IterationMonthType } from '@constants/IterationMonthType';
import { IterationValue } from '@constants/iterationValue';
import { formatMomentDate, weekStringOfMonth } from '@shared/utils/functions';

import { getDaysOfWeekDto } from './getDaysOfWeekDto';
import { getIterationTypeDto } from './getIterationTypeDto';

import type { DateSelectorDto, RecurrenceRangeDto } from '@model/management/Maintenance';
import type { TimeSlot } from '@model/management/Maintenance/dto/create-maintenance-general-V2.dto';
import type { Moment } from 'moment';
import type { IterationModalState } from './types';

export function createDateSelectorDto(iterationState: IterationModalState): DateSelectorDto {
  switch (iterationState.repeatType) {
    case IterationValue.EVERY_DAY:
      return everyDay(iterationState);
    case IterationValue.EVERY_WEEK:
      return everyWeek(iterationState);
    case IterationValue.EVERY_MONTH:
      return everyMonth(iterationState);
    default:
      return {} as DateSelectorDto;
  }
}

const staticValues = {
  locationProvider: 0,
  attendees: [null],
  resolveLocationsAvailability: true,
  meetingDuration: '',
  bingMarket: '',
  queryConstraint: {
    query: '',
  },
  maxCandidates: 0,
};

function createTimeSlots(startDate: Moment): Array<TimeSlot> {
  return [
    {
      start: {
        dateTime: formatMomentDate(startDate, dateWithTimeDash),
        timeZone: 'Turkey Standard Time',
      },
      end: {
        dateTime: formatMomentDate(startDate, dateWithTimeDash),
        timeZone: 'Turkey Standard Time',
      },
    },
  ];
}

function createRange(startDate: Moment, endDate: Moment, startTime: Moment): RecurrenceRangeDto {
  return {
    type: 'endDate',
    startDate: formatMomentDate(startDate, dateDash),
    endDate: formatMomentDate(endDate, dateDash),
    startTime: formatMomentDate(startTime).replace('Z', ''),
    recurrenceTimeZone: 'Turkey Standard Time',
  };
}

function everyDay(iterationState: IterationModalState): DateSelectorDto {
  return {
    ...staticValues,
    timeConstraint: {
      timeslots: createTimeSlots(iterationState.startDate),
      recurrence: {
        range: createRange(
          iterationState.startDate,
          iterationState.endDate,
          iterationState.startTime
        ),
        pattern: {
          type: getIterationTypeDto(iterationState.repeatType, iterationState.monthType),
          interval: iterationState.repeatNumber,
        },
      },
    },
  };
}

function everyWeek(iterationState: IterationModalState): DateSelectorDto {
  return {
    ...staticValues,
    timeConstraint: {
      timeslots: createTimeSlots(iterationState.startDate),
      recurrence: {
        range: createRange(
          iterationState.startDate,
          iterationState.endDate,
          iterationState.startTime
        ),
        pattern: {
          type: getIterationTypeDto(iterationState.repeatType, iterationState.monthType),
          interval: iterationState.repeatNumber,
          daysOfWeek: getDaysOfWeekDto(iterationState.daysOfWeek),
          firstDayofWeek: 'Monday',
        },
      },
    },
  };
}

function everyMonth(iterationState: IterationModalState): DateSelectorDto {
  const byDay = {
    dayOfMonth: iterationState.startDate.clone().date(),
  };

  const byWeek = {
    weekIndex: weekStringOfMonth(iterationState.startDate),
    daysOfWeek: [1],
  };

  const monthPattern = iterationState.monthType === IterationMonthType.ByDay ? byDay : byWeek;

  return {
    ...staticValues,
    timeConstraint: {
      timeslots: createTimeSlots(iterationState.startDate),
      recurrence: {
        range: createRange(
          iterationState.startDate,
          iterationState.endDate,
          iterationState.startTime
        ),
        pattern: {
          type: getIterationTypeDto(iterationState.repeatType, iterationState.monthType),
          interval: iterationState.repeatNumber,
          ...monthPattern,
        },
      },
    },
  };
}
