import React from 'react';
import { Translation } from 'react-i18next';

import { MaintenanceViewType } from '@constants/MaintenanceViewType';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Segment } from '@shared/UI';
import { updateMaintenanceViewType } from '@store/actions/management/Maintenance';
import { selectMaintenanceViewType } from '@store/selectors/management/Maintenance';
import cl from 'classnames';

import type { SegmentProps } from '@shared/UI';

import './MaintenanceSwithcer.scss';

interface MaintenanceSwitcherProps {
  styles?: React.CSSProperties;
  className?: string;
}

const options = [
  {
    label: <Translation>{(t) => t('titles.List')}</Translation>,
    value: MaintenanceViewType.List,
  },
  {
    label: <Translation>{(t) => t('titles.Board')}</Translation>,
    value: MaintenanceViewType.Board,
  },
  {
    label: <Translation>{(t) => t('titles.Calendar')}</Translation>,
    value: MaintenanceViewType.Calendar,
  },
];

const MaintenanceSwitcher = (props: MaintenanceSwitcherProps) => {
  const { styles, className } = props;
  const dispatch = useAppDispatch();
  const { viewType } = useAppSelector(selectMaintenanceViewType);

  const defaultClasses = cl('maintenance-view-switcher', className);

  const onChangeView = (view: MaintenanceViewType) => {
    dispatch(updateMaintenanceViewType(view));
  };

  return (
    <Segment
      className={defaultClasses}
      value={viewType}
      options={options}
      onChange={onChangeView as SegmentProps['onChange']}
      style={styles}
    ></Segment>
  );
};

export { MaintenanceSwitcher };
