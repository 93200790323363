import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getPaIStatusById } from '@store/actions/properties/PaI/Status';
import { selectPaIStatus } from '@store/selectors/properties/PaI/Status';

import type { IOutletPartAndInventoryStatus } from '@constants/OutletContextEntityPages';
import type { CreatePaIStatusDto } from '@model/properties/PaI/Status';

function PartAndInventoryStatus() {
  const partAndInventoryStatus = useAppSelector(selectPaIStatus);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition =
    !partAndInventoryStatus || partAndInventoryStatus.partsAndInventoriesStatusId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getPaIStatusById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreatePaIStatusDto = {
    partsAndInventoriesStatusCode: partAndInventoryStatus.partsAndInventoriesStatusCode,
    name: partAndInventoryStatus.name,
    color: partAndInventoryStatus?.color,
  };

  return (
    <Outlet
      context={{ initialValues, entity: partAndInventoryStatus } as IOutletPartAndInventoryStatus}
    />
  );
}

export default PartAndInventoryStatus;
