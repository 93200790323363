import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormExitType } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addExitType } from '@store/actions/properties/ExitType';

import type { CreateExitTypeDto } from '@model/properties/ExitType';
import type { FormInstance } from 'antd';

function CreateExitType() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { exitTypeAccess } = useGetAccess();

  const onFinish = async (dto: CreateExitTypeDto, form?: FormInstance<CreateExitTypeDto>) => {
    try {
      const response = await dispatch(addExitType(dto)).unwrap();
      ApiNotifications.create(response.name);
      navigate(paths.exitType.default);
      form?.resetFields();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={exitTypeAccess.create} redirectTo={paths.exitType.default}>
      <FormExitType
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.Exit_Type') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreateExitType;
