import { Translation } from 'react-i18next';

import { Notifications } from '@services/Notifications';
import { identifyError } from '@shared/utils/functions';

import type { ApiErrorType } from '@services/Axios';
import type { NotificationProps } from '@services/Notifications';
import type { ReactNode } from 'react';

class ApiNotificationsAdapter {
  create(name: ReactNode, config?: NotificationProps) {
    Notifications.success({
      description: <Translation>{(t) => t('notifications.Created', { name })}</Translation>,
      message: <Translation>{(t) => t('notifications.Success')}</Translation>,
      ...config,
    });
  }

  update(name: ReactNode, config?: NotificationProps) {
    Notifications.success({
      description: <Translation>{(t) => t('notifications.Updated', { name })}</Translation>,
      message: <Translation>{(t) => t('notifications.Success')}</Translation>,
      ...config,
    });
  }

  remove(name: ReactNode, config?: NotificationProps) {
    Notifications.success({
      description: <Translation>{(t) => t('notifications.Removed', { name })}</Translation>,
      message: <Translation>{(t) => t('notifications.Success')}</Translation>,
      ...config,
    });
  }

  error(error: ApiErrorType, config?: NotificationProps) {
    const err = identifyError(error);
    Notifications.error({
      description: <Translation>{(t) => t(`errors.${err}`)}</Translation>,
      message: <Translation>{(t) => t('notifications.Error')}</Translation>,
      ...config,
    });
  }

  info(description: ReactNode, config?: NotificationProps) {
    Notifications.info({
      description,
      message: <Translation>{(t) => t('notifications.Info')}</Translation>,
      ...config,
    });
  }
}

const Instance = new ApiNotificationsAdapter();

export { Instance as ApiNotifications };
