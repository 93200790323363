import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMaintenanceContext } from '../../../MaintenanceContext';

export const CheckedCount = memo(() => {
  const { t } = useTranslation();

  const { checkedItemsNumber } = useMaintenanceContext();

  return (
    <span className="maintenance-checked-items-number">
      {t('titles.Selected_Records_Count', {
        name: '',
        number: checkedItemsNumber,
      })}
    </span>
  );
});
