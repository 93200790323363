import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { statusColor } from '@constants/statusColors';
import { useAppSelector } from '@hooks';
import { ColorPicker } from '@shared/UI';
import { selectCompanyStatus } from '@store/selectors/users/Company';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { CompanyModel } from '@model/users/Company';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormCompany(props: CommonPreviewProps<CompanyModel>) {
  const { entity, onDelete } = props;

  const { t } = useTranslation();

  const companyStatus = useAppSelector(selectCompanyStatus);

  return (
    <CommonForm
      isPreview
      loading={companyStatus === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
      }}
    >
      <CaseField title={t('titles.Summary')}>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.companyCode}</PreviewField>
        </Item>
        <Item label={t('titles.Color')}>
          <ColorPicker colors={statusColor} value={entity.color ?? statusColor[0].value} readonly />
        </Item>
      </CaseField>

      <CaseField>
        <Item label={t('titles.Tax_Office')}>
          <PreviewField>{entity.taxOffice}</PreviewField>
        </Item>
        <Item label={t('titles.TXN')}>
          <PreviewField>{entity.taxNumber}</PreviewField>
        </Item>
      </CaseField>

      <CaseField title={t('titles.Location')}>
        <Item label={t('titles.Country')}>
          <PreviewField>{entity.city.country.name}</PreviewField>
        </Item>
        <Item label={t('titles.City')}>
          <PreviewField>{entity.city.name}</PreviewField>
        </Item>
        <Item label={t('titles.Address')}>
          <PreviewField>{entity.address}</PreviewField>
        </Item>
      </CaseField>

      <CaseField title={t('titles.Contacts')}>
        <Item label={t('titles.Email')}>
          <PreviewField>{entity.contactName}</PreviewField>
        </Item>
        <Item label={t('titles.Phone_Num')}>
          <PreviewField>{entity.phone}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormCompany };
