import React from 'react';

import type { SVGProps } from 'react';

function TrashCan(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5078 22.6992H7.50781C6.40324 22.6992 5.50781 21.8038 5.50781 20.6992V7.69922H3.50781V5.69922H7.50781V4.69922C7.50781 3.59465 8.40324 2.69922 9.50781 2.69922H15.5078C16.6124 2.69922 17.5078 3.59465 17.5078 4.69922V5.69922H21.5078V7.69922H19.5078V20.6992C19.5078 21.8038 18.6124 22.6992 17.5078 22.6992ZM7.50781 7.69922V20.6992H17.5078V7.69922H7.50781ZM9.50781 4.69922V5.69922H15.5078V4.69922H9.50781Z"
        fill={props.fill ? props.fill : '#BDD4ED'}
      />
    </svg>
  );
}

export { TrashCan };
