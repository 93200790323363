import type { DayOfWeek } from './types';

export const daysOfWeekList: Array<DayOfWeek> = [
  {
    label: 'M',
    fullName: 'Monday',
    checked: false,
    dayOfWeekIndex: 1,
  },
  {
    label: 'T',
    fullName: 'Tuesday',
    checked: false,
    dayOfWeekIndex: 2,
  },
  {
    label: 'W',
    fullName: 'Wednesday',
    checked: false,
    dayOfWeekIndex: 3,
  },
  {
    label: 'T',
    fullName: 'Thursday',
    checked: false,
    dayOfWeekIndex: 4,
  },
  {
    label: 'F',
    fullName: 'Friday',
    checked: false,
    dayOfWeekIndex: 5,
  },
  {
    label: 'S',
    fullName: 'Saturday',
    checked: false,
    dayOfWeekIndex: 6,
  },
  {
    label: 'S',
    fullName: 'Sunday',
    checked: false,
    dayOfWeekIndex: 7,
  },
];
