import React from 'react';

import { AppRouter } from '@components/Routes';
import { ConfigProvider } from 'antd';

function App() {
  return (
    <ConfigProvider>
      <AppRouter />
    </ConfigProvider>
  );
}

export default App;
