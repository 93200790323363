import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { CommonForm } from '@components/common';
import { createInitialIterationState } from '@components/views/Forms/managment/Maintenance/GeneralV2/_util/createInitialIterationState';
import { MaintenanceSteps } from '@components/views/Forms/managment/Maintenance/MaintenanceSteps';
import { IterationValue } from '@constants/iterationValue';
import { useAppDispatch, useAppSelector, useIsCreatePage, useModalState } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { checkStoreStatuses } from '@shared/utils/functions';
import { addAsAtCard, getAsAtCards } from '@store/actions/properties/AsAt/Card';
import { addAsAtCategory, getAsAtCategories } from '@store/actions/properties/AsAt/Category';
import { addMaAtCategory, getMaAtCategories } from '@store/actions/properties/MaAt/Category';
import { addMaAtRequestReason, getMaAtRequestReasons } from '@store/actions/properties/MaAt/RequestReason';
import { addSite, getSites } from '@store/actions/properties/Site';
import { getUsers } from '@store/actions/user/User';
import { selectMaintenanceStatus } from '@store/selectors/management/Maintenance';
import { selectAsAtCardsList } from '@store/selectors/properties/AsAt/Card';
import {
  selectAsAtCategoriesListWithoutChildren,
  selectAsAtCategoryStatus,
} from '@store/selectors/properties/AsAt/Category';
import {
  selectMaAtCategoriesList,
  selectMaAtCategoryStatus,
} from '@store/selectors/properties/MaAt/Category';
import {
  selectMaAtRequestReasonsList,
  selectMaAtRequestReasonStatus,
} from '@store/selectors/properties/MaAt/RequestReason';
import { selectSitesList, selectSiteStatus } from '@store/selectors/properties/Site';
import { selectUsersList, selectUserStatus } from '@store/selectors/users/User';

import {
  createInitialValues,
  defaultIterationState,
  getIterationByOccurrence,
  handleDaysOfWeek,
  isRequestFalseDto,
  isRequestTrueDto,
  isValidState,
} from './_util';
import { CreateableEntityModals } from './CreateableEntityModals';
import { IterationModal } from './IterationModal';
import { LeftSectionFields } from './LeftSectionFields';
import { RightSectionFields } from './RightSectionFields';

import type { CommonFormProps } from '@components/common';
import type { CreateMaintenanceGeneralV2Dto } from '@model/management/Maintenance';
import type { CreateAsAtCardDto } from '@model/properties/AsAt/Card';
import type { CreateAsAtCategoryDto } from '@model/properties/AsAt/Category';
import type { CreateMaAtCategoryDto } from '@model/properties/MaAt/Category';
import type { CreateMaintenanceRequestReasonDto } from '@model/properties/MaAt/RequestReason';
import type  { CreateSiteDto } from '@model/properties/Site';
import type { FormInstance } from 'antd';
import type { FormFinishInfo, FormProviderProps } from 'rc-field-form/lib/FormContext';
import type { ChangeIterationStateAction, IterationModalState } from './_util';

interface GeneralV2Props extends CommonFormProps<CreateMaintenanceGeneralV2Dto> {}

const { useForm, useWatch, Provider } = CommonForm;

export const FormGeneralV2 = memo((props: GeneralV2Props) => {
  const { initialValues: initialValuesProps, onFinish, ...restProps } = props;

  const [form] = useForm<CreateMaintenanceGeneralV2Dto>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isCreate = useIsCreatePage();

  type FormsFinish = {
    formAssetCard: FormInstance<CreateAsAtCardDto>;

    formAssetCategory: FormInstance<CreateAsAtCategoryDto>;

    formMaintenanceCategory: FormInstance<CreateMaAtCategoryDto>;

    formRequestReason: FormInstance<CreateMaintenanceRequestReasonDto>;

    formSite: FormInstance<CreateSiteDto>;
  };

  // region --- State selectors

  const sitesList = useAppSelector(selectSitesList);
  const usersList = useAppSelector(selectUsersList);
  const assetCardsList = useAppSelector(selectAsAtCardsList);
  const maiReasonsList = useAppSelector(selectMaAtRequestReasonsList);
  const maiCategoriesList = useAppSelector(selectMaAtCategoriesList);
  const assetCategoriesList = useAppSelector(selectAsAtCategoriesListWithoutChildren);

  const siteListStatus = useAppSelector(selectSiteStatus);
  const userListStatus = useAppSelector(selectUserStatus);
  const maiReasonListStatus = useAppSelector(selectMaAtRequestReasonStatus);
  const maiCategoriesListStatus = useAppSelector(selectMaAtCategoryStatus);
  const assetCategoriesListStatus = useAppSelector(selectAsAtCategoryStatus);
  const maintenanceStatus = useAppSelector(selectMaintenanceStatus);

  const isLoadingLists = checkStoreStatuses([
    siteListStatus,
    userListStatus,
    maiReasonListStatus,
    maiCategoriesListStatus,
    assetCategoriesListStatus,
    maintenanceStatus,
  ]);

  // endregion --- State selectors

  const { dateSelector, ...restInitialValuesProps } = initialValuesProps || {};

  const initialValues = createInitialValues(restInitialValuesProps);

  // Modals State
  const assetCardModal = useModalState();
  const assetCategoryModal = useModalState();
  const maintenanceCategoryModal = useModalState();
  const requestReasonModal = useModalState();
  const siteModal = useModalState();

  // region --- Iteration states

  const iterationModal = useModalState();

  const [iterationState, setIterationState] = useState<IterationModalState>(() => {
    const initialState = createInitialIterationState(dateSelector);
    if (initialState) {
      return initialState;
    }
    return defaultIterationState;
  });

  // endregion --- Iteration states

  // region --- Observed values
  const isRequest = useWatch('isRequest', form);

  // endregion --- Observed values

  // region --- Fetch states data
  useEffect(() => {
    batch(() => {
      if (sitesList.length === 0) {
        dispatch(getSites());
      }

      if (usersList.length === 0) {
        dispatch(getUsers());
      }

      if (assetCardsList.length === 0) {
        dispatch(getAsAtCards());
      }

      if (maiReasonsList.length === 0) {
        dispatch(getMaAtRequestReasons());
      }

      if (maiCategoriesList.length === 0) {
        dispatch(getMaAtCategories());
      }

      if (assetCategoriesList.length === 0) {
        dispatch(getAsAtCategories());
      }
    });
  }, []);
  // endregion --- Fetch states data

  // region --- Handle iteration modal state

  const changeIterationState: ChangeIterationStateAction = useCallback((value) => {
    if (typeof value === 'function') {
      setIterationState((prevState) => ({
        ...value(prevState),
      }));
    } else {
      setIterationState((prevState) => ({
        ...prevState,
        ...value,
      }));
    }
  }, []);

  const selectOccurrenceId = useCallback((occurrence: number) => {
    const repeatType = getIterationByOccurrence(occurrence);
    setIterationState((prevState) => {
      if (repeatType === prevState.repeatType) {
        return prevState;
      }
      if (repeatType === IterationValue.NEVER) {
        return defaultIterationState;
      }

      let daysOfWeek = prevState.daysOfWeek;

      switch (repeatType) {
        case IterationValue.EVERY_DAY:
          daysOfWeek = handleDaysOfWeek.selectAllDaysOfWeek(prevState.daysOfWeek);
          break;
        case IterationValue.EVERY_WEEK:
          daysOfWeek = handleDaysOfWeek.selectOnlyFirstDayOfWeek(prevState.daysOfWeek);
          break;
        case IterationValue.EVERY_MONTH:
          daysOfWeek = handleDaysOfWeek.deselectAllDaysOfWeek(prevState.daysOfWeek);
      }

      return {
        ...defaultIterationState,
        repeatType,
        daysOfWeek,
      };
    });

    if (repeatType !== IterationValue.NEVER) {
      iterationModal.actions.onOpen();
    } else {
      form.setFieldValue('isRequest', true);
    }
  }, []);

  // endregion --- Handle iteration modal state

  const validateOccurrenceSelect = useCallback(() => {
    if (isRequest) {
      return Promise.resolve();
    }
    if (!isValidState(iterationState) || iterationState.repeatType === IterationValue.NEVER) {
      return Promise.reject(t('errors.Error_Iteration_Modal_Fields'));
    }
    return Promise.resolve();
  }, [isRequest, iterationState]);

  const finishForm = (dto: CreateMaintenanceGeneralV2Dto) => {
    if (isRequest) {
      onFinish?.(isRequestTrueDto(dto));
    } else {
      onFinish?.(isRequestFalseDto(dto, iterationState));
    }
  };

  const onFinishAssetCard = (assetCardForm: FormInstance<CreateAsAtCardDto>) => {
    const dto = assetCardForm.getFieldsValue();

    dispatch(addAsAtCard(dto))
      .unwrap()
      .then(({ nonCurrAssetCardId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Asset_Attr'),
          })
        });

        assetCardForm.resetFields();
        form.setFieldValue('nonCurrAssetCardId', nonCurrAssetCardId);
        assetCardModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishAssetCategory = (assetCategoryForm: FormInstance<CreateAsAtCategoryDto>) => {
    const dto = assetCategoryForm.getFieldsValue();

    dispatch(addAsAtCategory(dto))
      .unwrap()
      .then(({ AsAtCategory: { nonCurrAssetCardCategoryId, name } }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Asset_Attr'),
          })
        });

        assetCategoryForm.resetFields();
        form.setFieldValue('nonCurrAssetCardCategoryId', nonCurrAssetCardCategoryId);
        assetCategoryModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishMaintenanceCategory = (maintenanceCategoryForm: FormInstance<CreateMaAtCategoryDto>) => {
    const dto = maintenanceCategoryForm.getFieldsValue();

    dispatch(addMaAtCategory(dto))
      .unwrap()
      .then(({ maintinanceCategoryId, categoryName }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: categoryName,
            section: t('pages_single.Maintenance_Attr'),
          })
        });

        maintenanceCategoryForm.resetFields();
        form.setFieldValue('maintinanceCategoryId', maintinanceCategoryId);
        maintenanceCategoryModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishRequestReason = (requestReasonForm: FormInstance<CreateMaintenanceRequestReasonDto>) => {
    const dto = requestReasonForm.getFieldsValue();

    dispatch(addMaAtRequestReason(dto))
      .unwrap()
      .then(({ requestReasonId, reasonName }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: reasonName,
            section: t('pages_single.Maintenance_Attr'),
          })
        });

        requestReasonForm.resetFields();
        form.setFieldValue('requestReasonId', requestReasonId);
        requestReasonModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishSite = (siteForm: FormInstance<CreateSiteDto>) => {
    const dto = siteForm.getFieldsValue();

    dispatch(addSite(dto))
      .unwrap()
      .then(({createdSite: { siteId, name }}) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Site'),
          })
        });

        siteForm.resetFields();
        form.setFieldValue('siteId', siteId);
        siteModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFormsFinish = (
    name: string,
    { forms }: Omit<FormFinishInfo, 'forms'> & { forms: FormsFinish }
  ) => {
    switch (name) {
      case 'formAssetCard': {
        onFinishAssetCard(forms.formAssetCard);
        break;
      }
      case 'formAssetCategory': {
        onFinishAssetCategory(forms.formAssetCategory);
        break;
      }
      case 'formMaintenanceCategory': {
        onFinishMaintenanceCategory(forms.formMaintenanceCategory);
        break;
      }
      case 'formRequestReason': {
        onFinishRequestReason(forms.formRequestReason);
        break;
      }
      case 'formSite': {
        onFinishSite(forms.formSite);
        break
      }
    }
  };
  
  const additionalHeader = isCreate ? <MaintenanceSteps /> : null;

  return (
    <Provider onFormFinish={onFormsFinish as FormProviderProps['onFormFinish']}>
      <CommonForm
        alignAdditionalHeader="left"
        additionalHeader={additionalHeader}
        form={form}
        name="generalForm"
        loading={isLoadingLists}
        initialValues={initialValues}
        onFinish={finishForm}
        {...restProps}
      >
        <LeftSectionFields
          usersList={usersList}
          maiCategoriesList={maiCategoriesList}
          sitesList={sitesList}
          onSiteOpenModal={siteModal.actions.onOpen}
          onMaiCategoryOpenModal={maintenanceCategoryModal.actions.onOpen}
        />
        <RightSectionFields
          onOpenModal={iterationModal.actions.onOpen}
          validateOccurrenceSelect={validateOccurrenceSelect}
          iterationState={iterationState}
          assetCategoriesList={assetCategoriesList}
          onAssetCategoryOpenModal={assetCategoryModal.actions.onOpen}
          maiReasonsList={maiReasonsList}
          onMaiReasonOpenModal={requestReasonModal.actions.onOpen}
          assetCardsList={assetCardsList}
          onAssetCardOpenModal={assetCardModal.actions.onOpen}
          selectOccurrence={selectOccurrenceId}
        />

        <IterationModal
          iterationState={iterationState}
          isOpen={iterationModal.isOpen}
          onChangeState={changeIterationState}
          onClose={iterationModal.actions.onClose}
        />
      </CommonForm>

      <CreateableEntityModals 
        siteModal={siteModal}
        assetCardModal={assetCardModal}
        assetCategoryModal={assetCategoryModal}
        requestReasonModal={requestReasonModal}
        maintenanceCategoryModal={maintenanceCategoryModal}
      />
    </Provider>
  );
});
