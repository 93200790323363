import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { BaseForm, BaseModal, Input, Select } from '@shared/UI';
import { getCities, getCountries } from '@store/actions/extra/Definition';
import { addSite } from '@store/actions/properties/Site';
import { selectDefinitionState } from '@store/selectors/extra/Definition';
import { Col, Row } from 'antd';

import type { CreateSiteDto } from '@model/properties/Site';
import type { CreateUserDto } from '@model/users/User';
import type { FormInstance } from 'antd';

import './SiteModalForm.scss';

interface SiteModalFormProps {
  isOpen: boolean;

  onClose: () => void;

  formInstance: FormInstance<CreateUserDto>;
}

const { Item, useForm } = BaseForm;

// region --- ToolbarItems rules

const requiredRule = [
  {
    required: true,
  },
];

const barcodeRules = [
  ...requiredRule,
  {
    min: 1,
    max: 50,
  },
];

// endregion --- ToolbarItems rules

export const SiteModalForm = memo((props: SiteModalFormProps) => {
  const { isOpen, formInstance, onClose } = props;

  const [form] = useForm<CreateSiteDto>();

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { countriesList, citiesList, definitionStatus } = useAppSelector(selectDefinitionState);

  const { t } = useTranslation();

  useEffect(() => {
    if (countriesList.length === 0) {
      dispatch(getCountries());
    }
    if (citiesList.length === 0) {
      dispatch(getCities());
    }
  }, []);

  const optionsCountry = useMemo(
    () =>
      countriesList.map((country) => ({
        label: country.name,
        value: country.countryId,
        cities: country.cities,
      })),
    [countriesList]
  );
  const optionsCity = useMemo(
    () =>
      citiesList.map((city) => ({
        label: city.name,
        value: city.cityId,
        countryId: city.countryId,
      })),
    [citiesList]
  );

  const onChangeCountryID = (
    value: number,
    option: typeof optionsCountry | typeof optionsCountry[number]
  ) => {
    form.setFieldsValue({
      countryId: value,
      cityId: (option as typeof optionsCountry[number])?.cities[0]?.cityId,
    });
    form.validateFields(['cityId', 'countryId']);
  };

  const handleFinish = (dto: CreateSiteDto) => {
    setIsLoading(true);
    dispatch(addSite(dto))
      .unwrap()
      .then(({ createdSite }) => {
        formInstance.setFieldValue('siteId', createdSite.siteId);
        ApiNotifications.create(createdSite.name);
      })
      .then(() => {
        onClose();
        form.resetFields();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <BaseModal
      onOk={handleOk}
      open={isOpen}
      title={t('titles.New_Item', { name: t('pages_single.Department') })}
      size="small"
      closable={false}
      onCancel={onClose}
      className="site-modal-form"
      okButtonProps={{
        disabled: isLoading,
        loading: isLoading,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
    >
      <BaseForm form={form} onFinish={handleFinish} name="departmentForm">
        <Row>
          <Col span={24}>
            <Item label={t('titles.Name')} name="name" rules={requiredRule}>
              <Input placeholder={t('titles.Name')} />
            </Item>
          </Col>

          <Col span={24}>
            <Item label={t('titles.Code')} name="siteCode" rules={requiredRule}>
              <Input placeholder={t('titles.Code')} />
            </Item>
          </Col>

          <Col span={24}>
            <Item label={t('titles.Barcode')} name="barcode" rules={barcodeRules}>
              <Input placeholder={t('titles.Barcode')} />
            </Item>
          </Col>

          <Col span={24}>
            <Item label={t('titles.Country')} name="countryId" rules={requiredRule}>
              <Select
                options={optionsCountry}
                loading={definitionStatus === 'pending'}
                showSearch={false}
                placeholder={t('titles.Choose_Name', { name: t('titles.Country') })}
                onChange={onChangeCountryID}
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item
              shouldUpdate={(prevValue: CreateSiteDto, nextValue: CreateSiteDto) =>
                prevValue.cityId !== nextValue.cityId
              }
              noStyle
            >
              {({ getFieldValue }) => {
                const selectedCountry = getFieldValue('countryId');
                let cities = optionsCity;

                if (selectedCountry) {
                  cities = cities.filter((city) => {
                    return city.countryId === selectedCountry;
                  });
                }

                return (
                  <Item label={t('titles.City')} name="cityId" rules={requiredRule}>
                    <Select
                      options={cities}
                      loading={definitionStatus === 'pending'}
                      showSearch={false}
                      placeholder={t('titles.Choose_Name', { name: t('titles.City') })}
                    />
                  </Item>
                );
              }}
            </Item>
          </Col>
        </Row>
      </BaseForm>
    </BaseModal>
  );
});
