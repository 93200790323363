import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { CheckStepDnDContainer } from '@components/common';

import { CheckStepItem } from '../CheckStepItem';

import type { CheckStepsMapperProps } from '../_utils';

export const CheckStepsMapper = (props: CheckStepsMapperProps) => {
  const { steps, isDraggable = true, actions, ...restProps } = props;

  const mapSteps = steps.map((step, idx) => {
    return (
      <CheckStepDnDContainer
        key={step.uuid}
        step={step}
        isDraggable={isDraggable}
        stepIndex={idx}
        onMoveStep={actions?.onMoveStep}
      >
        <CheckStepItem
          stepKey={step.stepKey}
          step={step}
          isDraggable={isDraggable}
          actions={actions}
          {...restProps}
        />
      </CheckStepDnDContainer>
    );
  });

  return <DndProvider backend={HTML5Backend}>{mapSteps}</DndProvider>;
};
