import React from 'react';

import { Card as AntdCard } from 'antd';
import cl from 'classnames';

import type { CardProps as AntdCardProps } from 'antd';

export interface CardProps extends AntdCardProps {}

const Card = (props: CardProps) => {
  const { className, ...rest } = props;

  const cardClasses = cl(className, 'custom-card');

  return <AntdCard className={cardClasses} {...rest} />;
};

export { Card };
