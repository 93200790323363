import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserNotification } from '@components/common';
import { useAppDispatch } from '@hooks';
import { TwoTicks } from '@icon/icon-components';
import { markAlertAsRead } from '@store/actions/extra/Alert';
import cl from 'classnames';

import type { AlertModel } from '@model/extra/Alert';

import './ContentNotifications.scss';

interface ContentNotificationsProps {
  unreadAlerts: AlertModel[];
}

function ContentNotifications(props: ContentNotificationsProps) {
  const { unreadAlerts } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onReadAll = useCallback(() => {
    dispatch(markAlertAsRead());
  }, []);

  const partOfAlerts = unreadAlerts.slice(0, 5);

  const numberOfUnread = unreadAlerts.length - partOfAlerts.length;

  const defaultClass = 'content-notifications';

  return (
    <div
      className={cl(defaultClass, {
        [`${defaultClass}--empty`]: numberOfUnread <= 0,
      })}
    >
      <div className={`${defaultClass}__header`}>
        <h6 className={`${defaultClass}__title`}>{t('pages_plural.Notifications')}</h6>

        <a className={`${defaultClass}__read-all`} onClick={onReadAll}>
          <span className="read-all__icon">
            <TwoTicks />
          </span>
          {t('titles.Mark_As_Read')}
        </a>
      </div>
      {unreadAlerts.length > 0 ? (
        <>
          <div className={`${defaultClass}__notifications`}>
            {partOfAlerts.map((alert) => (
              <div key={alert.alertId} className={`${defaultClass}__notification-wrapper`}>
                <div className={`${defaultClass}__notification`}>
                  <UserNotification
                    type="compact"
                    description={alert.alertHeader}
                    notificationDate={alert.createdDate}
                    isRead={alert.isRead}
                  />
                </div>
              </div>
            ))}
          </div>
          {numberOfUnread > 0 && (
            <div className={`${defaultClass}__other-info`}>
              <span className={`${defaultClass}__other-info-text`}>
                {t('titles.Number_Of_Unread_Notifications', { number: numberOfUnread })}
              </span>
            </div>
          )}
        </>
      ) : (
        <div className="no-notifications--message">
          <span>{t('titles.No_Unread_Notifications')}</span>
        </div>
      )}
    </div>
  );
}

export { ContentNotifications };
