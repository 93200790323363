import React, { useState } from 'react';
import { HuePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

import { Popover } from '@shared/UI';
import cl from 'classnames';

import type { ColorResult} from 'react-color';

import './MulticolorPicker.scss';

export interface MulticolorPickerProps {
  defaultColor?: string | null;
  readonly?: boolean;
  randomColor?: boolean;
  onChange?: (color: string) => void;
}

const MulticolorPicker = (props: MulticolorPickerProps) => {
  const { onChange, defaultColor, readonly, randomColor } = props;

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const [color, setColor] = useState(defaultColor || undefined);

  function handleOpen(open: boolean) {
    if (readonly) {
      return;
    }
    
    setIsOpen(open);
  }
  
  function handleClickColor(selectColor: ColorResult) {
      setColor(selectColor.hex)
      onChange?.(selectColor.hex);
  }

  function getRandomColor() {
    const randomColor = ((Math.random() * 0xfffff * 1000000).toString(16)).slice(0, 6);

    setColor(`#${randomColor}`)
    onChange?.(`#${randomColor}`);
  }

  const popoverContent = <HuePicker color={color} onChange={handleClickColor} />;

  return (
    <div className="ui-multicolor-picker-wrapper">
    <Popover
      className="ui-multicolor-picker"
      content={popoverContent}
      open={isOpen}
      onOpenChange={handleOpen}
      trigger="click"
      placement="bottomLeft"
    >
      <button
        className={cl('ui-multicolor-picker-button', {
          'ui-multicolor-picker-button--active': isOpen,
        })}
        type="button"
      >
        <span
          className={'ui-multicolor-picker-multicolor-mark'}
          style={{
            backgroundColor: color ? color : '#D2D2D2',
          }}
        />{t('titles.Default')}
      </button>
    </Popover>
    { (!readonly && randomColor) && 
      <button 
        className='ui-multicolor-picker-random-button' 
        type='button' 
        onClick={getRandomColor}>
      {t('titles.Random_Color')}</button> }
    </div>
  );
};

export { MulticolorPicker };
