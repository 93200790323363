import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';

import { SimpleTable } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { notificationController } from '@services/Notifications';
import { BaseModal, Button, Radio } from '@shared/UI';
import { getCountPlanResults, updateCountPlanResult } from '@store/actions/management/CountPlan';
import {
  selectCountPlanResultFilterOptions,
  selectCountPlanResultSettings,
  selectCountPlanResultsList,
  selectCountPlanStatus,
} from '@store/selectors/management/CountPlan';

import type { ColumnsTableType } from '@components/common/SimpleTable/SimpleTable';
import type { AssetModel } from '@model/management/Asset';
import type { CountPlanResultModel } from '@model/management/CountPlan';
import type { RadioChangeEvent } from 'antd';

import './CountingResultsTable.scss';

type CountingResultsTableProps = {
  prdCountPlanId: number;
};

const columns = [
  {
    title: <Translation>{(t) => t('titles.Barcode')}</Translation>,
    dataIndex: 'barcode',
    key: 'barcode',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Asset_Name')}</Translation>,
    dataIndex: 'nonCurrAssetCardName',
    key: 'nonCurrAssetCardName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: ['planSite', 'name'],
    key: 'planSite.name',
    ellipsis: true,
  },
];

const columnsNotFound = [
  {
    title: <Translation>{(t) => t('titles.Barcode')}</Translation>,
    dataIndex: 'barcode',
    key: 'barcode',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Asset_Name')}</Translation>,
    dataIndex: ['nonCurrAssetCard', 'name'],
    key: 'nonCurrAssetCard.name',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: ['site', 'name'],
    key: 'site.name',
    ellipsis: true,
  },
];

const columnsSiteDifferences = [
  {
    title: <Translation>{(t) => t('titles.Select_All')}</Translation>,
    width: '14%',
    className: 'select-cell',
  },
  ...columns,
];

const options = [
  {
    label: <Translation>{(t) => t('titles.Total_Count')}</Translation>,
    value: '',
  },
  {
    label: <Translation>{(t) => t('titles.Not_Registered')}</Translation>,
    value: 'unKnownBarcodeList',
  },
  {
    label: <Translation>{(t) => t('titles.Not_Found')}</Translation>,
    value: 'notFoundDuringTheCounting',
  },
  {
    label: <Translation>{(t) => t('titles.Site_Differences')}</Translation>,
    value: 'showDifferentSitesNonCurrAssets',
  },
];

const CountingResultsTable = (props: CountingResultsTableProps) => {
  const { prdCountPlanId } = props;
  const dispatch = useAppDispatch();

  const countPlanResultList = useAppSelector(selectCountPlanResultsList);
  const settingsCountResultList = useAppSelector(selectCountPlanResultSettings);
  const filtersCountResultList = useAppSelector(selectCountPlanResultFilterOptions);
  const countResultStatus = useAppSelector(selectCountPlanStatus);

  const [modalMode, setModalMode] = useState(false);
  const [radioBtnValue, setRadioBtnValue] = useState('');

  const onChangeRadioBtn = ({ target: { value } }: RadioChangeEvent) => {
    setRadioBtnValue(value);
  };

  const isNotRegistered = radioBtnValue === 'unKnownBarcodeList';
  const isNotFound = radioBtnValue === 'notFoundDuringTheCounting';
  const isSiteDifferences = radioBtnValue === 'showDifferentSitesNonCurrAssets';

  useEffect(() => {
    dispatch(
      getCountPlanResults({
        prdCountPlanId: prdCountPlanId,
        unKnownBarcodeList: isNotRegistered,
        notFoundDuringTheCounting: isNotFound,
        showDifferentSitesNonCurrAssets: isSiteDifferences,
        page: 1,
      })
    );
  }, [radioBtnValue]);

  const onChangePage = (page: number) => {
    dispatch(
      getCountPlanResults({
        page: page,
      })
    );
  };

  const [selectedRows, setSelectedRows] = useState<(AssetModel | CountPlanResultModel)[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: (AssetModel | CountPlanResultModel)[]
  ) => {
    setSelectedRows(newSelectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRows,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const selectedAssetNames = selectedRows
    .map((row) => {
      const siteDifferences = row as CountPlanResultModel;
      return `"${siteDifferences.nonCurrAssetCardName}"`;
    })
    .join(', ');

  const update = () => {
    if (selectedRows.length) {
      dispatch(
        updateCountPlanResult({
          barcodes: selectedRowKeys as string[],
          prdCountPlanId: prdCountPlanId,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(
            getCountPlanResults({
              prdCountPlanId: prdCountPlanId,
              showDifferentSitesNonCurrAssets: true,
            })
          );
          notificationController.success({
            description: 'Update operation successful',
          });
        })
        .catch((err) => {
          if (typeof err === 'string') {
            notificationController.error({
              description: err,
            });
          }
        })
        .finally(() => setModalMode(false));
    }
  };

  const currentColumns: ColumnsTableType<AssetModel | CountPlanResultModel> = isNotFound
    ? columnsNotFound
    : isSiteDifferences
    ? columnsSiteDifferences
    : columns;    

  return (
    <div className="counting-results-table">
      <div className="counting-results-table-buttons">
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          value={radioBtnValue}
          onChange={onChangeRadioBtn}
          className="counting-radio-btn"
        >
          {options.map((option) => {
            return (
              <Radio.Button value={option.value} key={option.value}>
                {option.label}
                <h6>{radioBtnValue === option.value ? settingsCountResultList.totalRecords : 0}</h6>
              </Radio.Button>
            );
          })}
        </Radio.Group>

        {isSiteDifferences ? (
          <Button
            type="primary"
            disabled={!selectedRowKeys.length}
            onClick={() => setModalMode(true)}
          >
            Replace with Found Site
          </Button>
        ) : null}
      </div>

      <SimpleTable
        dataSource={countPlanResultList}
        rowKey={(row) => row.barcode}
        columns={currentColumns}
        loading={countResultStatus === 'pending'}
        rowSelection={isSiteDifferences ? rowSelection : undefined}
        pagination={{
          current: filtersCountResultList.page,
          onChange: onChangePage,
          total: settingsCountResultList.totalRecords ? settingsCountResultList.totalRecords : undefined,
          position: ['bottomCenter'],
        }}
      />

      <BaseModal
        open={modalMode}
        onOk={update}
        onCancel={() => setModalMode(false)}
        title="Movement of asset"
        className="modal-movement"
      >
        <span>
          Are you sure you want to move the selected asset:
          <br />
          {selectedAssetNames} ?
        </span>
      </BaseModal>
    </div>
  );
};
export { CountingResultsTable };
