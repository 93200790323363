import React from 'react';

const SiteIcon = () => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 8.80009C8.89556 8.80009 9.28224 8.68279 9.61114 8.46303C9.94004 8.24326 10.1964 7.93091 10.3478 7.56545C10.4991 7.2 10.5387 6.79787 10.4616 6.40991C10.3844 6.02195 10.1939 5.66558 9.91421 5.38587C9.63451 5.10617 9.27814 4.91569 8.89018 4.83852C8.50222 4.76135 8.10009 4.80095 7.73463 4.95233C7.36918 5.1037 7.05682 5.36005 6.83706 5.68895C6.6173 6.01785 6.5 6.40453 6.5 6.80009C6.5 7.33052 6.71071 7.83923 7.08579 8.2143C7.46086 8.58937 7.96957 8.80009 8.5 8.80009ZM7.79 15.7101C7.88296 15.8038 7.99356 15.8782 8.11542 15.929C8.23728 15.9797 8.36799 16.0059 8.5 16.0059C8.63201 16.0059 8.76272 15.9797 8.88458 15.929C9.00644 15.8782 9.11704 15.8038 9.21 15.7101L13.3 11.6101C14.25 10.6606 14.897 9.45078 15.1593 8.13355C15.4216 6.81632 15.2873 5.45089 14.7735 4.20998C14.2597 2.96906 13.3894 1.9084 12.2727 1.16215C11.156 0.415894 9.84309 0.0175781 8.5 0.0175781C7.15691 0.0175781 5.844 0.415894 4.72731 1.16215C3.61062 1.9084 2.74033 2.96906 2.22652 4.20998C1.7127 5.45089 1.57845 6.81632 1.84073 8.13355C2.10301 9.45078 2.75005 10.6606 3.7 11.6101L7.79 15.7101ZM3.73 6.34009C3.79833 5.62711 4.02502 4.93838 4.39352 4.3242C4.76203 3.71002 5.26305 3.1859 5.86 2.79009C6.6442 2.27519 7.56186 2.00085 8.5 2.00085C9.43814 2.00085 10.3558 2.27519 11.14 2.79009C11.733 3.18454 12.2311 3.70549 12.5987 4.31547C12.9663 4.92546 13.1942 5.60932 13.2659 6.31787C13.3376 7.02643 13.2514 7.74208 13.0135 8.41334C12.7756 9.08461 12.3919 9.69482 11.89 10.2001L8.5 13.5901L5.11 10.2001C4.60752 9.69972 4.22293 9.09362 3.98421 8.42589C3.74548 7.75816 3.65864 7.04561 3.73 6.34009ZM15.5 18.0001H1.5C1.23478 18.0001 0.98043 18.1054 0.792893 18.293C0.605357 18.4805 0.5 18.7349 0.5 19.0001C0.5 19.2653 0.605357 19.5197 0.792893 19.7072C0.98043 19.8947 1.23478 20.0001 1.5 20.0001H15.5C15.7652 20.0001 16.0196 19.8947 16.2071 19.7072C16.3946 19.5197 16.5 19.2653 16.5 19.0001C16.5 18.7349 16.3946 18.4805 16.2071 18.293C16.0196 18.1054 15.7652 18.0001 15.5 18.0001Z"
      fill="white"
    />
  </svg>
);

export { SiteIcon };
