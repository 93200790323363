import type { ActionFunction, CheckStepItem, CheckStepItemOption } from '../../types';

export const onChangeOptionTitle: ActionFunction<{
  step: CheckStepItem;
  option: CheckStepItemOption;
  value: string;
}> = ({ option, step, value }, { setState }) => {
  setState((prevState) => {
    return prevState.map((s) =>
      s.uuid === step.uuid
        ? {
            ...s,
            optionsList: s.optionsList.map((o) =>
              o.uuid === option.uuid
                ? {
                    ...o,
                    title: value,
                    isUpdated: true,
                    hasError: false,
                  }
                : o
            ),
          }
        : s
    );
  });
};
