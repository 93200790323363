import { checkStepKeys } from '@components/common/CheckStepItem/_utils';

import type { ActionFunction, CheckStepItem } from '../../types';

export const validateWorkStepResult: ActionFunction<CheckStepItem, boolean> = (
  step,
  { setState }
) => {
  setState((prevState) => {
    return prevState.map((s) => {
      if (s.stepKey === checkStepKeys.photo || s.uuid !== step.uuid) return s;

      return {
        ...s,
        hasError: step.value.length === 0,
        isCompleted: step.value.length > 0,
      };
    });
  });

  if (step.stepKey === checkStepKeys.photo) return true;

  return step.value.length > 0;
};
