import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getUserAuthorizationById } from '@store/actions/user/UserAuthorization';
import { selectUserAuth } from '@store/selectors/users/UserAuthorization';

import type { IOutletUserAuthorization } from '@constants/OutletContextEntityPages';
import type { CreateUserAuthorizationDto } from '@model/users/UserAuthorization';

function UserAuthorization() {
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();
  const user = useAppSelector(selectUserAuth);

  const condition = !user || user.userId !== +decryptedId;

  useSetPageInfo(
    {
      pageTitle: 'pages_single.Authorization',
      defaultPagePath: paths.userauth.default,
    },
    { copyTabs: true }
  );

  useEffect(() => {
    if (condition) {
      dispatch(getUserAuthorizationById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const companyIds = user.userAuthorizedCompanies?.map((company) => company.company.companyId);
  const siteIds = user.userAuthorizedSites?.map((site) => site.site.siteId);

  const initialValues: CreateUserAuthorizationDto = {
    companyIds: companyIds,
    siteIds: siteIds,
    authorizationType: 1,
    userId: user.userId,
    groupsId: 1,
    isUserAreAuthorizedForTheAllCompanies: user.isUserAreAuthorizedForTheAllCompanies,
    isUserAreAuthorizedForTheAllSites: user.isUserAreAuthorizedForTheAllSites,
  };
  return <Outlet context={{ initialValues, entity: user } as IOutletUserAuthorization} />;
}

export default UserAuthorization;
