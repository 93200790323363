import type { RootState } from '@store/rootReducer';

export const selectMaAtChecklistState = (state: RootState) => state.MaAtChecklistReducer;

export const selectMaAtChecklistsList = (state: RootState) =>
  selectMaAtChecklistState(state).MaAtChecklistsList;

export const selectMaAtChecklist = (state: RootState) =>
  selectMaAtChecklistState(state).MaAtChecklist;

export const selectMaAtChecklistStatus = (state: RootState) =>
  selectMaAtChecklistState(state).MaAtChecklistStatus;

export const selectMaAtChecklistError = (state: RootState) =>
  selectMaAtChecklistState(state).MaAtChecklistError;

export const selectMaAtChecklistOptions = (state: RootState) =>
  selectMaAtChecklistState(state).filterOptions;

export const selectMaAtChecklistSettings = (state: RootState) =>
  selectMaAtChecklistState(state).MaAtChecklistSettings;

export const selectMaAtChecklistsSummary = (state: RootState) =>
  selectMaAtChecklistState(state).MaAtChecklistsSummaryList;
