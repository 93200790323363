import React from 'react';

import type { SVGProps } from 'react';

const Bell = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5655 14.8592C17.186 13.7072 16.9921 12.5022 16.991 11.2892V8.29225C17.0186 6.39323 16.3409 4.55145 15.089 3.12325C13.8414 1.69279 12.1098 0.773035 10.226 0.540246C9.185 0.432246 8.126 0.540246 7.121 0.880746C6.116 1.20325 5.201 1.74175 4.43 2.46025C3.65515 3.16021 3.03757 4.01655 2.618 4.97275C2.19635 5.92857 1.97626 6.96106 1.9715 8.00575V11.3057C1.9693 12.5137 1.77539 13.7136 1.397 14.8607L0.5 17.5142L1.2185 18.5012H6.476C6.476 19.2902 6.7985 20.0612 7.355 20.6192C7.91 21.1742 8.6825 21.4982 9.473 21.4982C10.262 21.4982 11.033 21.1757 11.5895 20.6192C12.146 20.0627 12.47 19.2902 12.47 18.5012H17.7275L18.4445 17.5142L17.5655 14.8592ZM10.5305 19.5797C10.3925 19.7205 10.228 19.8326 10.0465 19.9096C9.86504 19.9865 9.67013 20.0269 9.473 20.0282C9.27551 20.0269 9.08025 19.9864 8.89849 19.9092C8.71673 19.8319 8.55206 19.7195 8.414 19.5782C8.27296 19.4404 8.16059 19.2759 8.08336 19.0944C8.00613 18.913 7.96557 18.718 7.964 18.5207H10.9625C10.9688 18.7175 10.9337 18.9135 10.8595 19.0959C10.7854 19.2783 10.6738 19.4431 10.532 19.5797H10.5305ZM2.258 17.0147L2.8145 15.3437C3.24847 14.042 3.47277 12.6795 3.479 11.3072V8.00575C3.479 7.16125 3.659 6.33625 3.9995 5.58175C4.34 4.81075 4.8245 4.12825 5.453 3.57175C6.0815 2.99875 6.818 2.56675 7.607 2.31625C8.414 2.04625 9.257 1.95625 10.082 2.04625C11.594 2.24506 12.9803 2.9924 13.9775 4.14625C14.975 5.30153 15.5112 6.78418 15.4835 8.31025V11.3252C15.4835 12.6887 15.6995 14.0522 16.148 15.3617L16.7045 17.0312H2.2595V17.0132L2.258 17.0147Z"
      fill="#032F5C"
    />
  </svg>
);

export { Bell };
