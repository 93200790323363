import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getUserGroups } from '@store/actions/user/UserGroup';
import { selectUserGroupsList } from '@store/selectors/users/UserGroup';

function EntryUserGroup() {
  useSetPageInfo({
    pageTitle: 'pages_single.User_Group',
    defaultPagePath: paths.usergroups.default,
  });

  const groups = useAppSelector(selectUserGroupsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!groups.length) {
      dispatch(getUserGroups());
    }
  }, []);

  return <Outlet />;
}

export default EntryUserGroup;
