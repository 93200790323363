import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassPrefix } from '@hooks';

import { useAssetListContext } from '../AssetListProvider';
import { AssetToolbar } from './AssetToolbar';
import { RootHeader } from './RootHeader';

import type { RootHeaderProps } from './RootHeader';

import './ListHeader.scss';

type HeaderProps = RootHeaderProps & {};

export const ListHeader = memo((props: HeaderProps) => {
  const { ...restProps } = props;

  const { t } = useTranslation();

  const { prefix, withPrefix } = useClassPrefix('asset-list-header');

  const { checkedAssetsLength, total } = useAssetListContext();

  const renderHeader = () => {
    if (checkedAssetsLength > 0) {
      return <AssetToolbar />;
    }
    return <RootHeader {...restProps} />;
  };

  return (
    <>
    <div className={prefix}>
      {renderHeader()}

      <div className={withPrefix('-total-count')}>
        <span>
          {t('titles.Total')}: {total}
        </span>
      </div>
    </div>
    </>
  );
});
