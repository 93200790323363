import { createSlice } from '@reduxjs/toolkit';
import {
  addUser,
  getUserById,
  getUsers,
  getUsersByFilter,
  removeUserById,
  updateUser,
  updateUserParentRelations,
} from '@store/actions/user/User';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { FilterUserDto, UserModel } from '@model/users/User';

interface UserState {
  usersList: UserModel[];
  usersFilteredList: UserModel[];
  user: null | UserModel;
  userStatus: LoadingStatus;
  userError: string | null;
  userFilters: FilterUserDto;
}

const initialState: UserState = {
  usersList: [],
  usersFilteredList: [],
  user: null,
  userStatus: 'idle',
  userError: null,
  userFilters: {
    userCodesArray: [],
    registrationNumberArray: [],
    firstNamesArray: [],
    lastNamesArray: [],
    userMailsArray: [],
  },
};

const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.userStatus = 'pending';
        state.userError = null;
        state.usersFilteredList = [];
        state.userFilters = {
          userCodesArray: [],
          registrationNumberArray: [],
          firstNamesArray: [],
          lastNamesArray: [],
          userMailsArray: [],
        };
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.userStatus = 'idle';
        state.usersList = payload;
      })
      .addCase(getUsers.rejected, (state, { payload }) => {
        state.userStatus = 'failed';
        if (payload) {
          state.userError = payload;
        }
      });
    builder
      .addCase(getUsersByFilter.pending, (state) => {
        state.userError = null;
        state.userStatus = 'pending';
      })
      .addCase(getUsersByFilter.fulfilled, (state, { payload }) => {
        state.userStatus = 'idle';
        state.usersFilteredList = payload.users;
        state.userFilters = payload.userFilters;
      })
      .addCase(getUsersByFilter.rejected, (state) => {
        state.userStatus = 'idle';
      });
    builder
      .addCase(addUser.pending, (state) => {
        state.userError = null;
        state.userStatus = 'pending';
      })
      .addCase(addUser.fulfilled, (state, { payload }) => {
        state.userStatus = 'idle';
        state.usersList = payload.usersList;
      })
      .addCase(addUser.rejected, (state) => {
        state.userStatus = 'idle';
      });
    builder
      .addCase(getUserById.pending, (state) => {
        state.userError = null;
        state.userStatus = 'pending';
      })
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        state.userStatus = 'idle';
        state.user = payload;
      })
      .addCase(getUserById.rejected, (state) => {
        state.userStatus = 'idle';
      });
    builder
      .addCase(updateUser.pending, (state) => {
        state.userError = null;
        state.userStatus = 'pending';
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.userStatus = 'idle';
        state.user = payload.user;
        state.usersList = payload.users;
      })
      .addCase(updateUser.rejected, (state) => {
        state.userStatus = 'idle';
      });
    builder
      .addCase(removeUserById.pending, (state) => {
        state.userError = null;
        state.userStatus = 'pending';
      })
      .addCase(removeUserById.fulfilled, (state, { payload }) => {
        state.userStatus = 'idle';
        state.usersList = payload;
      })
      .addCase(removeUserById.rejected, (state) => {
        state.userStatus = 'idle';
      });
    builder
      .addCase(updateUserParentRelations.pending, (state) => {
        state.userStatus = 'pending';
        state.userError = null;
      })
      .addCase(updateUserParentRelations.fulfilled, (state, { payload }) => {
        state.userStatus = 'idle';
        state.usersList = payload;
      })
      .addCase(updateUserParentRelations.rejected, (state) => {
        state.userStatus = 'idle';
      });
  },
});

const UserReducer = UserSlice.reducer;

export { UserReducer };
