import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToolbarDropdownItem } from '@components/common';
import { useModalState } from '@hooks';
import { CheckCheckboxIcon } from '@icon/icon-components';

import { useAssetListContext } from '../../../../AssetListProvider';
import { CheckOutModal } from './CheckOutModal';

export const CheckOutDropdown = memo(() => {
  const checkOutDropdown = useModalState();

  const { t } = useTranslation();

  const checkOutModal = useModalState();

  const { listCheckOuts } = useAssetListContext();

  const [checkOutId, setCheckOutId] = useState<null | number>(null);

  const handleOpenChange = () => {
    checkOutDropdown.actions.onToggle();
  };

  const handleClickItem = (key: string) => {
    setCheckOutId(+key);

    checkOutDropdown.actions.onClose();

    checkOutModal.actions.onOpen();
  };

  return (
    <>
      <ToolbarDropdownItem
        isOpen={checkOutDropdown.isOpen}
        label={t('pages_single.Check_Out')}
        icon={<CheckCheckboxIcon stroke={checkOutDropdown.isOpen ? '#74AAEA' : '#fff'} />}
        items={listCheckOuts}
        fieldNames={{ label: 'name', key: 'checkFactorId' }}
        onOpenChange={handleOpenChange}
        onClickItem={handleClickItem}
        showSearchItem={false}
      />
      <CheckOutModal
        isOpen={checkOutModal.isOpen}
        onClose={checkOutModal.actions.onClose}
        checkOutId={checkOutId}
      />
    </>
  );
});
