/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { batch } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@hooks';
import { checkStoreStatuses } from '@shared/utils/functions';
import { getLabels } from '@store/actions/extra/Label';
import { getAssetAllRecords } from '@store/actions/management/Asset';
import { getAsAtBrands } from '@store/actions/properties/AsAt/Brand';
import { getAsAtCards } from '@store/actions/properties/AsAt/Card';
import { getAsAtCategories } from '@store/actions/properties/AsAt/Category';
import { getAsAtModels } from '@store/actions/properties/AsAt/Model';
import { getAsAtStatuses } from '@store/actions/properties/AsAt/Status';
import { getCheckOuts } from '@store/actions/properties/CheckOut';
import { getContracts } from '@store/actions/properties/Contract';
import { getCostCenters } from '@store/actions/properties/CostCenter';
import { getDepartments } from '@store/actions/properties/Department';
import { getSites } from '@store/actions/properties/Site';
import { getVendors } from '@store/actions/properties/Vendor';
import { getUsers } from '@store/actions/user/User';
import {
  selectLabelsList,
  selectLabelStatus,
  selectLabelTypesList,
} from '@store/selectors/extra/Label';
import { selectAssetsAllRecords, selectAssetStatus } from '@store/selectors/management/Asset';
import {
  selectAsAtBrandsList,
  selectAsAtBrandStatus,
} from '@store/selectors/properties/AsAt/Brand';
import { selectAsAtCardsList, selectAsAtCardStatus } from '@store/selectors/properties/AsAt/Card';
import {
  selectAsAtCategoriesListWithoutChildren,
  selectAsAtCategoryStatus,
} from '@store/selectors/properties/AsAt/Category';
import {
  selectAsAtModelsList,
  selectAsAtModelStatus,
} from '@store/selectors/properties/AsAt/Model';
import {
  selectAsAtStatusesList,
  selectAsAtStatusStatus,
} from '@store/selectors/properties/AsAt/Status';
import { selectCheckOutsList, selectCheckOutStatus } from '@store/selectors/properties/CheckOut';
import { selectContractsList, selectContractStatus } from '@store/selectors/properties/Contract';
import {
  selectCostCentersList,
  selectCostCenterStatus,
} from '@store/selectors/properties/CostCenter';
import {
  selectDepartmentsListWithoutChildren,
  selectDepartmentStatus,
} from '@store/selectors/properties/Department';
import { selectSitesListWithoutChildren, selectSiteStatus } from '@store/selectors/properties/Site';
import { selectVendorsList, selectVendorStatus } from '@store/selectors/properties/Vendor';
import { selectUsersListWithoutChildren, selectUserStatus } from '@store/selectors/users/User';

export function useGetAssetData() {
  const dispatch = useAppDispatch();

  const allAssetRecord = useAppSelector(selectAssetsAllRecords);
  const statusAsset = useAppSelector(selectAssetStatus);

  const listAssetCategory = useAppSelector(selectAsAtCategoriesListWithoutChildren);
  const statusAssetCategory = useAppSelector(selectAsAtCategoryStatus);

  const listAssetBrand = useAppSelector(selectAsAtBrandsList);
  const statusAssetBrand = useAppSelector(selectAsAtBrandStatus);

  const listAssetModel = useAppSelector(selectAsAtModelsList);
  const statusAssetModel = useAppSelector(selectAsAtModelStatus);

  const listAssetNames = useAppSelector(selectAsAtCardsList);
  const statusAssetNames = useAppSelector(selectAsAtCardStatus);

  const listAssetStatuses = useAppSelector(selectAsAtStatusesList);
  const statusAssetStatus = useAppSelector(selectAsAtStatusStatus);

  const listContracts = useAppSelector(selectContractsList);
  const statusContract = useAppSelector(selectContractStatus);

  const listCostCenter = useAppSelector(selectCostCentersList);
  const statusCostCenter = useAppSelector(selectCostCenterStatus);

  const listVendor = useAppSelector(selectVendorsList);
  const statusVendor = useAppSelector(selectVendorStatus);

  const listDepartments = useAppSelector(selectDepartmentsListWithoutChildren);
  const statusDepartment = useAppSelector(selectDepartmentStatus);

  const listLabel = useAppSelector(selectLabelsList);
  const typeLabelList = useAppSelector(selectLabelTypesList);
  const statusLabel = useAppSelector(selectLabelStatus);

  const listUsers = useAppSelector(selectUsersListWithoutChildren);
  const statusUser = useAppSelector(selectUserStatus);

  const listSites = useAppSelector(selectSitesListWithoutChildren);
  const statusSite = useAppSelector(selectSiteStatus);

  const listCheckOuts = useAppSelector(selectCheckOutsList);
  const statusCheckOut = useAppSelector(selectCheckOutStatus);

  useEffect(() => {
    batch(() => {
      // if (!allAssetRecord.length) {
      //   dispatch(getAssetAllRecords());
      // }
      if (!listAssetBrand.length && statusAssetBrand !== 'pending') {
        dispatch(getAsAtBrands());
      }
      if (!listContracts.length && statusContract !== 'pending') {
        dispatch(getContracts());
      }

      if (!listCostCenter.length && statusCostCenter !== 'pending') {
        dispatch(getCostCenters());
      }
      if (!listVendor.length && statusVendor !== 'pending') {
        dispatch(getVendors());
      }
      if (!listAssetCategory.length && statusAssetCategory !== 'pending') {
        dispatch(getAsAtCategories());
      }
      if (!listAssetModel.length && statusAssetModel !== 'pending') {
        dispatch(getAsAtModels());
      }
      if (!listAssetNames.length && statusAssetNames !== 'pending') {
        dispatch(getAsAtCards());
      }

      if ((typeLabelList === 2 || !listLabel.length) && statusLabel !== 'pending') {
        dispatch(getLabels(1));
      }

      if (!listDepartments.length && statusDepartment !== 'pending') {
        dispatch(getDepartments());
      }

      if (!listAssetStatuses.length && statusAssetStatus !== 'pending') {
        dispatch(getAsAtStatuses());
      }

      if (!listUsers.length && statusUser !== 'pending') {
        dispatch(getUsers());
      }

      if (!listSites.length && statusSite !== 'pending') {
        dispatch(getSites());
      }

      if (!listCheckOuts.length && statusCheckOut !== 'pending') {
        dispatch(getCheckOuts());
      }
    });
  }, []);

  const statuses = [
    statusAsset,
    statusAssetCategory,
    statusAssetBrand,
    statusContract,
    statusCostCenter,
    statusVendor,
    statusAssetModel,
    statusAssetNames,
    statusLabel,
    statusDepartment,
    statusAssetStatus,
    statusUser,
    statusSite,
    statusCheckOut,
  ];
  const isLoading = checkStoreStatuses(statuses);

  return {
    allAssetRecord,
    listAssetCategory,
    listAssetBrand,
    listContracts,
    listCostCenter,
    listVendor,
    listAssetModel,
    listAssetNames,
    listDepartments,
    listLabel,
    listAssetStatuses,
    listUsers,
    listSites,
    listCheckOuts,
    isLoading,
  };
}
