import { PreviewFormUserAuthorization } from '@components/views/Previews/users/UserAuthorization';
import { useOutletUserAuthorization } from '@constants/OutletContextEntityPages';

function PreviewUserAuthorization() {
  const { entity } = useOutletUserAuthorization();

  const onDelete = () => {};

  return <PreviewFormUserAuthorization entity={entity} onDelete={onDelete}  />;
}

export default PreviewUserAuthorization;
