import React from 'react';
import { useTranslation } from 'react-i18next';

import { AssetPropertyFields, CommonForm, ModalFormFooter } from '@components/common';
import { useAppSelector } from '@hooks';
import { Checkbox, Input } from '@shared/UI';
import { selectAsAtPropertyStatus } from '@store/selectors/properties/AsAt/Property';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateAsAtPropertyDto } from '@model/properties/AsAt/Property';

const { CaseField, Item, useForm } = CommonForm;

function FormAssetProperty(props: CommonFormProps<CreateAsAtPropertyDto>) {
  const [form] = useForm<CreateAsAtPropertyDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const assetPropertyStatus = useAppSelector(selectAsAtPropertyStatus);

  return (
    <CommonForm
      form={form}
      loading={assetPropertyStatus === 'pending'}
      isModal={isModal}
      {...rest}
      initialValues={{
        ...rest.initialValues,
        isUnique: rest.initialValues?.isUnique || false,
        nonCurrAssetPropertyValues: rest.initialValues?.nonCurrAssetPropertyValues || [],
      }}
      name='formAssetProperty'
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onCancel={rest.header?.onClickCancel} /> : null
      }
    >
      <CaseField hiddenTitle isModal={isModal}>
        <Item name="isUnique" valuePropName="checked">
          <Checkbox>{t('titles.Is_Unique')}</Checkbox>
        </Item>

        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item
          label={t('titles.Code')}
          name="nonCurrAssetCardPropertyCode"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <AssetPropertyFields initialNonCurrAssetTypeId={rest.initialValues?.nonCurrAssetTypeId} />
      </CaseField>
    </CommonForm>
  );
}

export { FormAssetProperty };
