import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormPartAndInventoryBrand } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addPaIBrand } from '@store/actions/properties/PaI/Brand';

import type { CreatePaIBrandDto } from '@model/properties/PaI/Brand';
import type { FormInstance } from 'antd';

function CreatePartAndInventoryBrand() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onFinish = async (dto: CreatePaIBrandDto, form?: FormInstance<CreatePaIBrandDto>) => {
    try {
      const response = await dispatch(addPaIBrand(dto)).unwrap();
      ApiNotifications.create(response.name);
      navigate(paths.partAndInventoryAttributes.tabs.brand.default);
      form?.resetFields();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess
      hasAccess={paiAttrAccess.create}
      redirectTo={paths.partAndInventoryAttributes.tabs.brand.default}
    >
      <FormPartAndInventoryBrand
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Brand') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreatePartAndInventoryBrand;
