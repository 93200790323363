import { createAsyncThunk } from '@reduxjs/toolkit';
import { MaAtRequestReasonRepository } from '@repositories';

import type {
  CreateMaintenanceRequestReasonDto,
  MaAtRequestReasonModel,
  UpdateMaintenanceRequestReasonDto,
} from '@model/properties/MaAt/RequestReason';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getMaAtRequestReasons = createAsyncThunk<
  MaAtRequestReasonModel[],
  void,
  { rejectValue: string }
>('MaAtRequestReason/getMaAtRequestReasons', async (_, { rejectWithValue }) => {
  try {
    const response = await MaAtRequestReasonRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getMaAtRequestReasonById = createAsyncThunk<
  MaAtRequestReasonModel,
  string,
  { rejectValue: string }
>('MaAtRequestReason/getMaAtRequestReasonById', async (id, { rejectWithValue }) => {
  try {
    const response = await MaAtRequestReasonRepository.fetchById(id);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addMaAtRequestReason = createAsyncThunk<
  MaAtRequestReasonModel,
  CreateMaintenanceRequestReasonDto,
  { rejectValue: string }
>('MaAtRequestReason/addMaAtRequestReason', async (dto, { rejectWithValue }) => {
  try {
    const response = await MaAtRequestReasonRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateMaAtRequestReason = createAsyncThunk<
  {
    MaAtRequestReasonsList: MaAtRequestReasonModel[];
    MaAtRequestReason: MaAtRequestReasonModel;
  },
  UpdateMaintenanceRequestReasonDto,
  { rejectValue: string }
>('MaAtRequestReason/updateMaAtRequestReason', async (dto, { rejectWithValue }) => {
  try {
    const response = await MaAtRequestReasonRepository.update(dto);

    const responseList = await MaAtRequestReasonRepository.fetchAll();

    return {
      MaAtRequestReasonsList: responseList.resultObject,
      MaAtRequestReason: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeMaAtRequestReasonById = createAsyncThunk<
  MaAtRequestReasonModel[],
  number[],
  { rejectValue: string }
>('MaAtRequestReason/removeMaintenanceRequestReason', async (ids, { rejectWithValue }) => {
  try {
    await MaAtRequestReasonRepository.removeById(ids);
    const response = await MaAtRequestReasonRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
