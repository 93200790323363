import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getAsAtBrands } from '@store/actions/properties/AsAt/Brand';
import { selectAsAtBrandsList } from '@store/selectors/properties/AsAt/Brand';

function EntryAssetBrand() {
  useSetPageInfo(
    {
      defaultPagePath: paths.assetAttribute.tabs.brand.default,
      pageTitle: 'pages_single.Asset_Attr',
    },
    {
      copyTabs: true,
    }
  );
  const assetBrandList = useAppSelector(selectAsAtBrandsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!assetBrandList.length) {
      dispatch(getAsAtBrands());
    }
  }, []);

  return <Outlet />;
}

export default EntryAssetBrand;
