import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';

import { CommonForm, SimpleTable } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { getPaIsTypeName } from '@store/actions/management/PaI';
import { selectPaIsTypeNameListAllRecords } from '@store/selectors/management/PaI';

import type {
  ColumnsTableType,
  CommonPreviewProps,
  DefaultCommonFormProps,
} from '@components/common';
import type { MaintenancePartModel, MaintenanceV2Model } from '@model/management/Maintenance';

import './Parts.scss';

const columns: ColumnsTableType<MaintenancePartModel & { paiName: null | string }> = [
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'paiName',
    key: 'paiName',
  },
  {
    title: <Translation>{(t) => t('titles.Quantity')}</Translation>,
    dataIndex: 'quantity',
    key: 'quantity',
  },
];

const { CaseField } = CommonForm;

const PreviewFormMaintenanceParts = (props: CommonPreviewProps<MaintenanceV2Model>) => {
  const { entity } = props;
  const { maintenancesParts } = entity;

  const dispatch = useAppDispatch();

  const listPAIs = useAppSelector(selectPaIsTypeNameListAllRecords);

  const header: DefaultCommonFormProps['header'] = {
    title: entity.title,
    hiddenDeleteButton: true,
    hiddenEditButton: !entity.buttonPermit,
  };

  useEffect(() => {
    if (!listPAIs.length) {
      dispatch(getPaIsTypeName());
    }
  }, []);

  const parts = maintenancesParts.map((part) => {
    const paiName = listPAIs.find(
      (pai) => pai.partsAndInventoriesId === part.partsAndInventoriesId
    )?.partsAndInventoriesCardName;
    return {
      ...part,
      paiName: paiName || null,
    };
  });

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      header={header}
      alignAdditionalHeader="left"
      className="maintenance-parts-form"
    >
      <CaseField md={20} className="maintenance-parts-table-case">
        <SimpleTable
          dataSource={parts}
          rowKey={(row) => row.maintenancesPartsId}
          columns={columns}
          pagination={false}
        />
      </CaseField>
    </CommonForm>
  );
};

export { PreviewFormMaintenanceParts };
