import { useOutletContext } from 'react-router-dom';

/* ---------------------------------- Asset --------------------------------- */
import type { AssetModel, CreateAssetDto } from '@model/management/Asset';
/* ---------------------------------- CountPlan --------------------------------- */
import type { CountPlanModel, CreateCountPlanDto } from '@model/management/CountPlan';
import type { MaintenanceV2Model } from '@model/management/Maintenance';
import type {
  CreateMaintenanceGeneralV2Dto,
  MaintenanceWorkStepModel,
  MpModelDto,
} from '@model/management/Maintenance';
import type { CreatePaIDto, PaIModel } from '@model/management/PaI';
import type { ProfileModel, UpdateProfileDto } from '@model/profile/Profile';
/* ------------------------------- Asset Attributes Brand ------------------------------ */
import type { AsAtBrandModel, CreateAsAtBrandDto } from '@model/properties/AsAt/Brand';
/* ----------------------------- Asset Attributes Name ------------------------------- */
import type { AsAtCardModel, CreateAsAtCardDto } from '@model/properties/AsAt/Card';
/* ----------------------------- Asset Attributes Category ----------------------------- */
import type { AsAtCategoryModel, CreateAsAtCategoryDto } from '@model/properties/AsAt/Category';
/* ----------------------------- Asset Attributes Model ------------------------------- */
import type { AsAtModelModel, CreateAsAtModelDto } from '@model/properties/AsAt/Model';
/* ----------------------------- Asset Attributes Property ------------------------------- */
import type { AsAtPropertyModel, CreateAsAtPropertyDto } from '@model/properties/AsAt/Property';
/* ----------------------------- Asset Attributes Status ------------------------------- */
import type { AsAtStatusModel, CreateAsAtStatusDto } from '@model/properties/AsAt/Status';
/* -------------------------------- Check Out ------------------------------- */
import type { CheckOutModel, CreateCheckOutDto } from '@model/properties/CheckOut';
/* -------------------------------- Contract -------------------------------- */
import type { ContractModel, CreateContractDto } from '@model/properties/Contract';
/* ------------------------------- Cost Center ------------------------------ */
import type { CostCenterModel, CreateCostCenterDto } from '@model/properties/CostCenter';
/* ------------------------------- Department ------------------------------- */
import type { CreateDepartmentDto, DepartmentModel } from '@model/properties/Department';
/* -------------------------------- Exit Type ------------------------------- */
import type { CreateExitTypeDto, ExitTypeModel } from '@model/properties/ExitType';
/* ---------------------------------- Maintenance Category --------------------------------- */
import type { CreateMaAtCategoryDto, MaAtCategoryModel } from '@model/properties/MaAt/Category';
import type {
  CreateChecklistCheckStepsDto,
  MaAtChecklistModel,
} from '@model/properties/MaAt/Checklist';
/* ---------------------------------- Maintenance Request Reason --------------------------------- */
import type {
  CreateMaintenanceRequestReasonDto,
  MaAtRequestReasonModel,
} from '@model/properties/MaAt/RequestReason';
/* ---------------------------------- Part And Inventory Brand --------------------------------- */
import type { CreatePaIBrandDto, PaIBrandModel } from '@model/properties/PaI/Brand';
/* ---------------------------------- Part And Inventory Name --------------------------------- */
import type { CreatePaICardDto, PaICardModel } from '@model/properties/PaI/Card';
/* ---------------------------------- Part And Inventory Category --------------------------------- */
import type { CreatePaICategoryDto, PaICategoryModel } from '@model/properties/PaI/Category';
/* ---------------------------------- Part And Inventory Model --------------------------------- */
import type { CreatePaIModelDto, PaIModelModel } from '@model/properties/PaI/Model';
/* ---------------------------------- Part And Inventory Property --------------------------------- */
import type { CreatePaIPropertyDto, PaIPropertyModel } from '@model/properties/PaI/Property';
import type { CreatePaIStatusDto, PaIStatusModel } from '@model/properties/PaI/Status';
/* ---------------------------------- Part And Inventory Unit --------------------------------- */
import type { CreatePaIUnitDto, PaIUnitModel } from '@model/properties/PaI/Unit';
/* ---------------------------------- Site ---------------------------------- */
import type { CreateSiteDto, SiteModel } from '@model/properties/Site';
/* --------------------------------- Vendor --------------------------------- */
import type { CreateVendorDto, VendorModel } from '@model/properties/Vendor';
/* --------------------------------- Company -------------------------------- */
import type { CompanyModel, CreateCompanyDto } from '@model/users/Company';
/* ---------------------------------- Role --------------------------------- */
import type { CreateRoleFormDto, RoleModel } from '@model/users/Role';
/* ---------------------------------- Title --------------------------------- */
import type { CreateTitleDto, TitleModel } from '@model/users/Title';
/* ---------------------------------- User --------------------------------- */
import type { CreateUserDto, UserModel } from '@model/users/User';
/* -------------------------- User Authorization --------------------------------- */
import type { CreateUserAuthorizationDto } from '@model/users/UserAuthorization';
/* ---------------------------------- User Group --------------------------------- */
import type { CreateUserGroupDto, UserGroupModel } from '@model/users/UserGroup';

interface IOutlet<CT, DT> {
  initialValues: CT;
  entity: DT;
}

/* --------------------------------- Vendor --------------------------------- */
export interface IOutletVendor extends IOutlet<CreateVendorDto, VendorModel> {}

export const useOutletVendor = () => useOutletContext<IOutletVendor>();

/* --------------------------------- Company -------------------------------- */
export interface IOutletCompany extends IOutlet<CreateCompanyDto, CompanyModel> {}

export const useOutletCompany = () => useOutletContext<IOutletCompany>();

/* ---------------------------------- Site ---------------------------------- */
export interface IOutletSite extends IOutlet<CreateSiteDto, SiteModel> {}

export const useOutletSite = () => useOutletContext<IOutletSite>();

/* -------------------------------- Contract -------------------------------- */
export interface IOutletContract extends IOutlet<CreateContractDto, ContractModel> {}

export const useOutletContract = () => useOutletContext<IOutletContract>();

/* ---------------------------------- Title --------------------------------- */
export interface IOutletTitle extends IOutlet<CreateTitleDto, TitleModel> {}

export const useOutletTitle = () => useOutletContext<IOutletTitle>();

/* ---------------------------------- Role --------------------------------- */
export interface IOutletRole extends IOutlet<CreateRoleFormDto, RoleModel> {}

export const useOutletRole = () => useOutletContext<IOutletRole>();

/* ---------------------------------- User --------------------------------- */
export interface IOutletUser extends IOutlet<CreateUserDto, UserModel> {}

export const useOutletUser = () => useOutletContext<IOutletUser>();

/* ---------------------------------- User Groups--------------------------------- */
export interface IOutletUserGroup extends IOutlet<CreateUserGroupDto, UserGroupModel> {}

export const useOutletUserGroup = () => useOutletContext<IOutletUserGroup>();

/* --------------------------- User Authorization--------------------------------- */
export interface IOutletUserAuthorization extends IOutlet<CreateUserAuthorizationDto, UserModel> {}

export const useOutletUserAuthorization = () => useOutletContext<IOutletUserAuthorization>();

/* ------------------------------- Department ------------------------------- */
export interface IOutletDepartment extends IOutlet<CreateDepartmentDto, DepartmentModel> {}

export const useOutletDepartment = () => useOutletContext<IOutletDepartment>();

/* -------------------------------- Exit Type ------------------------------- */
export interface IOutletExitType extends IOutlet<CreateExitTypeDto, ExitTypeModel> {}

export const useOutletExitType = () => useOutletContext<IOutletExitType>();

/* ------------------------------- Cost Center ------------------------------ */
export interface IOutletCostCenter extends IOutlet<CreateCostCenterDto, CostCenterModel> {}

export const useOutletCostCenter = () => useOutletContext<IOutletCostCenter>();

/* -------------------------------- Check Out ------------------------------- */
export interface IOutletCheckOut extends IOutlet<CreateCheckOutDto, CheckOutModel> {}

export const useOutletCheckOut = () => useOutletContext<IOutletCheckOut>();

/* ------------------------------- Asset Attributes Brand ------------------------------ */
export interface IOutletAssetBrand extends IOutlet<CreateAsAtBrandDto, AsAtBrandModel> {}

export const useOutletAssetBrand = () => useOutletContext<IOutletAssetBrand>();

/* ----------------------------- Asset Attributes Category ----------------------------- */
export interface IOutletAssetCategory extends IOutlet<CreateAsAtCategoryDto, AsAtCategoryModel> {}

export const useOutletAssetCategory = () => useOutletContext<IOutletAssetCategory>();

/* ------------------------------- Asset Attributes Model ------------------------------ */
export interface IOutletAssetModel extends IOutlet<CreateAsAtModelDto, AsAtModelModel> {}

export const useOutletAssetModel = () => useOutletContext<IOutletAssetModel>();

/* ------------------------------- Asset Attributes Name ------------------------------- */
export interface IOutletAssetName extends IOutlet<CreateAsAtCardDto, AsAtCardModel> {}

export const useOutletAssetName = () => useOutletContext<IOutletAssetName>();

/* ----------------------------- Asset Attributes Property ----------------------------- */
export interface IOutletAssetProperty extends IOutlet<CreateAsAtPropertyDto, AsAtPropertyModel> {}

export const useOutletAssetProperty = () => useOutletContext<IOutletAssetProperty>();

/* ------------------------------ Asset Attributes Status ------------------------------ */
export interface IOutletAssetStatus extends IOutlet<CreateAsAtStatusDto, AsAtStatusModel> {}

export const useOutletAssetStatus = () => useOutletContext<IOutletAssetStatus>();

/* ---------------------------------- Asset --------------------------------- */
export interface IOutletAsset extends IOutlet<CreateAssetDto, AssetModel> {}

export const useOutletAsset = () => useOutletContext<IOutletAsset>();

/* ---------------------------------- Maintenance Category --------------------------------- */
export interface IOutletMaintenanceCategory
  extends IOutlet<CreateMaAtCategoryDto, MaAtCategoryModel> {}

export const useOutletMaintenanceCategory = () => useOutletContext<IOutletMaintenanceCategory>();

/* ---------------------------------- Maintenance Request Reason --------------------------------- */
export interface IOutletMaintenanceRequestReason
  extends IOutlet<CreateMaintenanceRequestReasonDto, MaAtRequestReasonModel> {}

export const useOutletMaintenanceRequestReason = () =>
  useOutletContext<IOutletMaintenanceRequestReason>();

/* ---------------------------------- Maintenance Checklist --------------------------------- */
export interface IOutletMaintenanceChecklist
  extends IOutlet<CreateChecklistCheckStepsDto, MaAtChecklistModel> {}

export const useOutletMaintenanceChecklist = () => useOutletContext<IOutletMaintenanceChecklist>();

/* ---------------------------------- Part And Inventory Name --------------------------------- */
export interface IOutletPartAndInventoryName extends IOutlet<CreatePaICardDto, PaICardModel> {}

export const useOutletPartAndInventoryName = () => useOutletContext<IOutletPartAndInventoryName>();

/* ---------------------------------- Part And Inventory Brand --------------------------------- */
export interface IOutletPartAndInventoryBrand extends IOutlet<CreatePaIBrandDto, PaIBrandModel> {}

export const useOutletPartAndInventoryBrand = () =>
  useOutletContext<IOutletPartAndInventoryBrand>();

/* ---------------------------------- Part And Inventory Unit --------------------------------- */
export interface IOutletPartAndInventoryUnit extends IOutlet<CreatePaIUnitDto, PaIUnitModel> {}

export const useOutletPartAndInventoryUnit = () => useOutletContext<IOutletPartAndInventoryUnit>();

/* ---------------------------------- Part And Inventory Property --------------------------------- */
export interface IOutletPartAndInventoryProperty
  extends IOutlet<CreatePaIPropertyDto, PaIPropertyModel> {}

export const useOutletPartAndInventoryProperty = () =>
  useOutletContext<IOutletPartAndInventoryProperty>();

/* ---------------------------------- Part And Inventory Model --------------------------------- */
export interface IOutletPartAndInventoryModel extends IOutlet<CreatePaIModelDto, PaIModelModel> {}

export const useOutletPartAndInventoryModel = () =>
  useOutletContext<IOutletPartAndInventoryModel>();

/* ---------------------------------- Part And Inventory Status --------------------------------- */
export interface IOutletPartAndInventoryStatus
  extends IOutlet<CreatePaIStatusDto, PaIStatusModel> {}

export const useOutletPartAndInventoryStatus = () =>
  useOutletContext<IOutletPartAndInventoryStatus>();

/* ---------------------------------- Part And Inventory Category --------------------------------- */
export interface IOutletPartAndInventoryCategory
  extends IOutlet<CreatePaICategoryDto, PaICategoryModel> {}

export const useOutletPartAndInventoryCategory = () =>
  useOutletContext<IOutletPartAndInventoryCategory>();

export interface IOutletPartAndInventory extends IOutlet<CreatePaIDto, PaIModel> {}

export const useOutletPartAndInventory = () => useOutletContext<IOutletPartAndInventory>();

/* --------------------------------- CountPlan -------------------------------- */
export interface IOutletCountPlan extends IOutlet<CreateCountPlanDto, CountPlanModel> {}

export const useOutletCountPlan = () => useOutletContext<IOutletCountPlan>();

/* --------------------------------- Profile -------------------------------- */
export interface IOutletProfile
  extends Omit<IOutlet<UpdateProfileDto, ProfileModel>, 'initialValues'> {}

export const useOutletProfile = () => useOutletContext<IOutletProfile>();

/* --------------------------------- Maintenance -------------------------------- */
export interface IOutletMaintenance extends IOutlet<null, MaintenanceV2Model> {
  initialValuesGeneral: CreateMaintenanceGeneralV2Dto;
  initialValuesParts: MpModelDto[];
  initialValuesWorkOrders: MaintenanceWorkStepModel[];
}

export const useOutletMaintenance = () => useOutletContext<IOutletMaintenance>();
