import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';


interface EmptyProps {}

export const Empty = memo((props: EmptyProps) => {
  const {} = props;

  const { t } = useTranslation();

  return (
    <div className="empty-data-page-description">
      <h6>
        <Trans i18nKey="common.empty_description">
          You don't have {{ name: t('pages_single.Asset') }} yet <br /> Click the button and
          create a{{ buttonName: t('titles.New_Item', { name: t('pages_single.Asset') }) }}
        </Trans>
      </h6>
    </div>
  );
});
