import { useEffect, useState } from 'react';

import { checkStepKeys } from '@components/common/CheckStepItem/_utils';

import {
  addNewStep,
  getNewWorkStepOptions,
  getNewWorkSteps,
  mapChecklistsToCheckSteps,
  mapCheckStepsToWorkStepResults,
  mapCheckStepsToWorkStepsDto,
  mapCheckStepToWorkStepResult,
  mapWorkStepsResultToCheckSteps,
  mapWorkStepsToCheckSteps,
  onAddNewOption,
  onChangeOptionTitle,
  onChangeResultOptionValue,
  onChangeResultValue,
  onChangeStepActive,
  onChangeStepImages,
  onChangeTitle,
  onCopyStep,
  onMoveStep,
  onRemoveCheckStepImage,
  onRemoveOption,
  onRemoveStep,
  setSteps,
  validateSteps,
  validateWorkStepResult,
} from './actions';

import type { ChecklistStepTypeKeys } from '@components/common';
import type { MaAtCheckStepModel } from '@model/properties/MaAt/Checklist';
import type { CheckStepActions, CheckStepItem, CheckStepState, UseCheckStepProps } from '../types';

export const useCheckStep = (props: UseCheckStepProps) => {
  const { removeCb, removeImageCb, type, initialSteps, maintenanceId, userId } = props;

  const [state, setState] = useState<CheckStepState>([]);

  const actionAPI = {
    state,
    setState,
  };

  useEffect(() => {
    if (initialSteps && initialSteps.length) {
      if (type === 'maintenanceWorkStep') {
        const initialValue = mapWorkStepsToCheckSteps(initialSteps);

        setState(() => initialValue);
      }
      if (type === 'maintenanceResult') {
        const initialValue = mapWorkStepsResultToCheckSteps(initialSteps);

        setState(() => initialValue);
      }

      if (type === 'maintenanceAttrChecklist') {
        const initialValue = mapChecklistsToCheckSteps(initialSteps);

        setState(() => initialValue);
      }
    }
  }, [initialSteps, type]);

  const isCompleteSteps = () => {
    return state.every((step) => {
      if (step.stepKey === checkStepKeys.photo) return true;
      return step.value.length > 0;
    });
  };

  const cleanSteps = () => {
    setState(() => []);
  };

  const handleSetSteps = (val: CheckStepItem[]) => {
    setSteps(val, actionAPI);
  };

  // --------------- Step handlers
  const handleAddNewStep = (key: ChecklistStepTypeKeys) => {
    addNewStep(state, setState, key);
  };

  const handleChangeTitle: CheckStepActions['onChangeTitle'] = (uuid: string, value: string) => {
    onChangeTitle({ uuid, value }, actionAPI);
  };

  const handleCopyStep: CheckStepActions['onCopyStep'] = (step) => {
    onCopyStep(state, setState, step);
  };

  const handleRemoveStep: CheckStepActions['onRemoveStep'] = (step) => {
    onRemoveStep(state, setState, step, removeCb);
  };

  const handleResultValueChange: CheckStepActions['onChangeResultValue'] = (step, value) => {
    onChangeResultValue({ step, value }, actionAPI);
  };

  const handleStepImagesChange: CheckStepActions['onChangeStepImages'] = (step, images) => {
    onChangeStepImages({ step, images }, actionAPI);
  };

  const handleStepImageRemove: CheckStepActions['onRemoveCheckStepImage'] = (step, uuid) => {
    onRemoveCheckStepImage({ step, uuid, cb: removeImageCb }, actionAPI);
  };

  // --------------- Option handlers
  const handleAddNewOption: CheckStepActions['onAddNewOption'] = (step) => {
    onAddNewOption(state, setState, step);
  };

  const handleOptionTitleChange: CheckStepActions['onChangeOptionTitle'] = (
    step,
    option,
    value
  ) => {
    onChangeOptionTitle({ step, option, value }, actionAPI);
  };

  const handleResultOptionValue: CheckStepActions['onChangeResultOptionValue'] = (step, option) => {
    onChangeResultOptionValue({ step, option }, actionAPI);
  };

  const handleRemoveOption: CheckStepActions['onRemoveOption'] = (step, option) => {
    onRemoveOption({ step, option }, actionAPI);
  };

  // --------------- Other handlers

  const handleValidateSteps = () => {
    return validateSteps(null, actionAPI);
  };

  const handleStepMove: CheckStepActions['onMoveStep'] = (sourceIdx, targetIdx) => {
    onMoveStep({ sourceIdx, targetIdx }, actionAPI);
  };

  const handleValidateWorkStepResult = (step: CheckStepItem) => {
    return validateWorkStepResult(step, actionAPI);
  };

  const handleStepActiveChange: CheckStepActions['onChangeStepActive'] = (uuid) => {
    onChangeStepActive(uuid, actionAPI);
  };

  // --------------- Map handlers

  const handleCheckStepsToWorkOrders = () => {
    return mapCheckStepsToWorkStepsDto({ maintenanceId, userId }, actionAPI);
  };

  const handleCheckStepToWorkStepResult = (step: CheckStepItem) => {
    if (
      type === 'maintenanceResult' &&
      'scheduleId' in props &&
      props.scheduleId &&
      props.executeStatusId
    ) {
      return mapCheckStepToWorkStepResult(
        step,
        maintenanceId,
        props.scheduleId,
        userId,
        props.executeStatusId
      );
    }
  };

  const handleCheckStepsToWorkStepResults = () => {
    if (
      type === 'maintenanceResult' &&
      'scheduleId' in props &&
      props.scheduleId &&
      props.executeStatusId
    ) {
      return mapCheckStepsToWorkStepResults(
        state,
        maintenanceId,
        props.scheduleId,
        userId,
        props.executeStatusId
      );
    }
  };

  const handleChecklistToCheckSteps = (steps: MaAtCheckStepModel[]) => {
    return mapChecklistsToCheckSteps(steps);
  };

  // --------------- Get handlers

  const handleGetNewWorkSteps = () => {
    return getNewWorkSteps({ maintenanceId, userId }, actionAPI);
  };

  const handleGetNewWorkStepsOptions = () => {
    return getNewWorkStepOptions(null, actionAPI);
  };

  const actions: CheckStepActions = {
    onChangeTitle: handleChangeTitle,
    onCopyStep: handleCopyStep,
    onRemoveStep: handleRemoveStep,
    onAddNewOption: handleAddNewOption,
    onChangeOptionTitle: handleOptionTitleChange,
    onRemoveOption: handleRemoveOption,
    onChangeResultOptionValue: handleResultOptionValue,
    onChangeResultValue: handleResultValueChange,
    onChangeStepImages: handleStepImagesChange,
    onRemoveCheckStepImage: handleStepImageRemove,
    onMoveStep: handleStepMove,
    onChangeStepActive: handleStepActiveChange,
  };

  const workStepApi = {
    getOnlyNewWorkSteps: handleGetNewWorkSteps,
    getOnlyNewWorkStepsOptions: handleGetNewWorkStepsOptions,
  };

  const workStepResultApi = {
    mapCheckStepsToWorkStepResults: handleCheckStepsToWorkStepResults,
    mapCheckStepToWorkStepResult: handleCheckStepToWorkStepResult,
    validateWorkStepResult: handleValidateWorkStepResult,
  };

  const checklistApi = {
    mapChecklistToCheckSteps: handleChecklistToCheckSteps,
  };

  return {
    steps: state,
    addNewStep: handleAddNewStep,
    validateSteps: handleValidateSteps,
    mapCheckStepsToWorkSteps: handleCheckStepsToWorkOrders,
    workStepApi,
    workStepResultApi,
    checklistApi,
    setter: handleSetSteps,
    cleanSteps,
    isCompleteSteps,
    actions,
  };
};
