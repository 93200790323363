import { createSelector } from '@reduxjs/toolkit';
import { liftChildToTop } from '@shared/utils/functions';

import type { RootState } from '@store/rootReducer';

export const selectDepartmentState = (state: RootState) => state.DepartmentReducer;

export const selectDepartmentsList = (state: RootState) =>
  selectDepartmentState(state).departmentsList;

export const selectDepartmentsFilteredList = (state: RootState) =>
  selectDepartmentState(state).departmentsFilteredList;

export const selectDepartment = (state: RootState) => selectDepartmentState(state).department;

export const selectDepartmentStatus = (state: RootState) =>
  selectDepartmentState(state).departmentStatus;

export const selectDepartmentError = (state: RootState) =>
  selectDepartmentState(state).departmentError;

export const selectDepartmentsListWithoutChildren = createSelector(
  selectDepartmentsList,
  (departmentsList) => {
    return liftChildToTop(departmentsList, 'childDepartment');
  }
);

export const selectDepartmentFilters = (state: RootState) =>
  selectDepartmentState(state).departmentFilters;
