import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { IterationEndDate } from '@components/common/IterationEndDate';
import { IterationValue } from '@constants/iterationValue';
import { useIsEditPage } from '@hooks';
import { Select } from '@shared/UI';
import { getOccurrenceByIteration } from '@shared/utils/functions';

import { getIterationByOccurrence, occurrenceOptions } from '../_util';

import type { CreateMaintenanceGeneralV2Dto } from '@model/management/Maintenance';
import type { IterationModalState } from '../_util';

import './OccurrenceField.scss';

interface IterationFieldProps {
  iterationState: IterationModalState;

  onSelect: (type: number) => void;

  validator: () => Promise<any>;

  onOpenModal: () => void;
}

const { Item, useFormInstance, useWatch } = CommonForm;

export const OccurrenceField = memo((props: IterationFieldProps) => {
  const { onSelect, validator, iterationState, onOpenModal } = props;

  const { t } = useTranslation();

  const isEdit = useIsEditPage();

  const form = useFormInstance<CreateMaintenanceGeneralV2Dto>();

  // region --- Observed values

  const isRequest = useWatch('isRequest', form);

  // endregion --- Observed values

  const isDisabled = isRequest;

  const isRequired = !isRequest;

  useEffect(() => {
    if (isRequest) {
      form.setFieldValue('maintenanceOccurrenceId', 0);
      form.validateFields(['maintenanceOccurrenceId']);
    }
  }, [isRequest]);

  useEffect(() => {
    const { maintenanceOccurrenceId } = form.getFieldsValue();

    if (maintenanceOccurrenceId) {
      const isSameValue =
        getIterationByOccurrence(maintenanceOccurrenceId) === iterationState.repeatType;

      if (!isSameValue) {
        form.setFieldValue(
          'maintenanceOccurrenceId',
          getOccurrenceByIteration(iterationState.repeatType)
        );
      }
    }
  }, [iterationState.repeatType]);

  const onClickLabel = useCallback(() => {
    onOpenModal();
  }, []);

  const renderIterationEndDate =
    !isRequest && iterationState.repeatType !== IterationValue.NEVER ? (
      <IterationEndDate
        type="preview"
        onClickPreviewLabel={onClickLabel}
        startDate={iterationState.startDate}
        endDate={iterationState.endDate}
        iterationType={iterationState.repeatType}
        daysOfWeek={iterationState.daysOfWeek}
        monthType={iterationState.monthType}
      />
    ) : null;

  return (
    <>
      <Item
        hidden={isEdit && iterationState.repeatType !== IterationValue.NEVER}
        name="maintenanceOccurrenceId"
        label={t('titles.Iteration')}
        disabledItem={isDisabled}
        className={'general-occurrence-item'}
        rules={[
          {
            validateTrigger: 'onSubmit',
            required: isRequired,
            validator,
          },
        ]}
      >
        <Select
          className={'general-occurrence-field'}
          options={occurrenceOptions}
          allowClear={false}
          onSelect={onSelect}
          disabled={isDisabled}
        />
      </Item>
      {renderIterationEndDate}
    </>
  );
});
