import React from 'react';

import { Col } from 'antd';

import type { ColProps } from 'antd';

interface CaseFieldProps extends ColProps {
  title?: string;
  hiddenTitle?: boolean;
  extra?: React.ReactNode;
  isModal?: boolean;
}

const CaseField = (props: CaseFieldProps) => {
  const { children, md = 10, xs = 24, title = '', extra, hiddenTitle = false, isModal, ...rest } = props;

  const mdValue = isModal ? 25 : md;

  return (
    <Col {...rest} md={mdValue} xs={xs}>
      <div
        style={
          extra
            ? {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }
            : undefined
        }
      >
        {!hiddenTitle && !isModal ? (
          <h6
            style={{
              color: 'var(--font-color-primary)',
              marginBottom: '3rem',
              height: '3.77rem',
            }}
          >
            {title}
          </h6>
        ) : null}
        {extra && extra}
      </div>
      {children}
    </Col>
  );
};

export default CaseField;
