import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaICardRepository } from '@repositories';

import type { CreatePaICardDto, PaICardModel, UpdatePaICardDto } from '@model/properties/PaI/Card';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getPaICards = createAsyncThunk<PaICardModel[], void, { rejectValue: string }>(
  'PaICard/getPaICards',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PaICardRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getPaICardById = createAsyncThunk<PaICardModel, string, { rejectValue: string }>(
  'PaICard/getPaICardById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await PaICardRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addPaICard = createAsyncThunk<PaICardModel, CreatePaICardDto, { rejectValue: string }>(
  'PaICard/addPaICard',
  async (dto, { rejectWithValue }) => {
    try {
      const response = await PaICardRepository.add(dto);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Add Actions

// region --- Update Actions

export const updatePaICard = createAsyncThunk<
  { names: PaICardModel[]; particularName: PaICardModel },
  UpdatePaICardDto,
  { rejectValue: string }
>('PaICard/updatePaICard', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaICardRepository.update(dto);
    const responseList = await PaICardRepository.fetchAll();

    return { names: responseList.resultObject, particularName: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removePaICardById = createAsyncThunk<
  PaICardModel[],
  number[],
  { rejectValue: string }
>('PaICard/removePaICardById', async (ids, { rejectWithValue }) => {
  try {
    await PaICardRepository.removeById(ids);
    const response = await PaICardRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
