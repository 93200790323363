import { createSlice } from '@reduxjs/toolkit';
import {
  addPaIProperty,
  getPaIProperties,
  getPaIPropertyById,
  getPaIPropertyTypes,
  removePaIPropertyById,
  updatePaIProperty,
} from '@store/actions/properties/PaI/Property';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { PaIPropertyModel, PaIPropertyTypeModel } from '@model/properties/PaI/Property';

interface PaIPropertyState {
  PaIPropertiesList: PaIPropertyModel[];
  PaIPropertyTypesList: PaIPropertyTypeModel[];
  PaIPropertyStatus: LoadingStatus;
  PaIProperty: null | PaIPropertyModel;
  PaIPropertyError: null | string;
}

const initialState: PaIPropertyState = {
  PaIPropertiesList: [],
  PaIPropertyTypesList: [],
  PaIPropertyStatus: 'idle',
  PaIProperty: null,
  PaIPropertyError: null,
};

const PaIPropertySlice = createSlice({
  name: 'PaIProperty',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaIProperties.pending, (state) => {
        state.PaIPropertyError = null;
        state.PaIPropertyStatus = 'pending';
      })
      .addCase(getPaIProperties.fulfilled, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        state.PaIPropertiesList = payload;
      })
      .addCase(getPaIProperties.rejected, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        if (payload) {
          state.PaIPropertyError = payload;
        }
      });

    builder
      .addCase(getPaIPropertyTypes.pending, (state) => {
        state.PaIPropertyError = null;
        state.PaIPropertyStatus = 'pending';
      })
      .addCase(getPaIPropertyTypes.fulfilled, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        state.PaIPropertyTypesList = payload;
      })
      .addCase(getPaIPropertyTypes.rejected, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        if (payload) {
          state.PaIPropertyError = payload;
        }
      });

    builder
      .addCase(addPaIProperty.pending, (state) => {
        state.PaIPropertyStatus = 'pending';
        state.PaIPropertyError = null;
      })
      .addCase(addPaIProperty.fulfilled, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        state.PaIPropertiesList = [payload, ...state.PaIPropertiesList];
      })
      .addCase(addPaIProperty.rejected, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        if (payload) {
          state.PaIPropertyError = payload;
        }
      });

    builder
      .addCase(getPaIPropertyById.pending, (state) => {
        state.PaIPropertyStatus = 'pending';
        state.PaIPropertyError = null;
      })
      .addCase(getPaIPropertyById.fulfilled, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        state.PaIProperty = payload;
      })
      .addCase(getPaIPropertyById.rejected, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        if (payload) {
          state.PaIPropertyError = payload;
        }
      });

    builder
      .addCase(updatePaIProperty.pending, (state) => {
        state.PaIPropertyStatus = 'pending';
        state.PaIPropertyError = null;
      })
      .addCase(updatePaIProperty.fulfilled, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        state.PaIPropertiesList = payload.properties;
        state.PaIProperty = payload.particularProperty;
      })
      .addCase(updatePaIProperty.rejected, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        if (payload) {
          state.PaIPropertyError = payload;
        }
      });

    builder
      .addCase(removePaIPropertyById.pending, (state) => {
        state.PaIPropertyStatus = 'pending';
        state.PaIPropertyError = null;
      })
      .addCase(removePaIPropertyById.fulfilled, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        state.PaIPropertiesList = payload;
      })
      .addCase(removePaIPropertyById.rejected, (state, { payload }) => {
        state.PaIPropertyStatus = 'idle';
        if (payload) {
          state.PaIPropertyError = payload;
        }
      });
  },
});

const PaIPropertyReducer = PaIPropertySlice.reducer;

export { PaIPropertyReducer };
