import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import {
  selectAsAtStatusesList,
  selectAsAtStatusStatus,
} from '@store/selectors/properties/AsAt/Status';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { AsAtStatusModel } from '@model/properties/AsAt/Status';

const columns: ColumnsTableType<AsAtStatusModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'nonCurrAssetStatusCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.nonCurrAssetStatusCode, b.nonCurrAssetStatusCode),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

const AssetStatuses = () => {
  const {t} = useTranslation();

  const listAssetStatus = useAppSelector(selectAsAtStatusesList);
  const statusAssetStatus = useAppSelector(selectAsAtStatusStatus);

  const {assetAttrAccess} = useGetAccess();

  return (
    <BasicTablePage
      table={{
        loading: statusAssetStatus === 'pending',
        dataSource: listAssetStatus,
        columns: columns,
        rowKey: (record) => record.nonCurrAssetStatusId,
        dataSourceName: t('pages_plural.Asset_Attr_Statuses'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', {name: t('pages_single.Asset_Attr_Status')}),
        csvConfig: {
          data: listAssetStatus,
          fileName: 'asset-statuses.csv',
          headers: [
            {label: 'code', key: 'nonCurrAssetStatusCode'},
            {label: 'name', key: 'name'},
          ],
        },

        newButtonProps: {
          disabled: !assetAttrAccess.create
        }
      }}
    />
  );
};

export {AssetStatuses};
