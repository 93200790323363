import { checkStepKeys } from '@components/common/CheckStepItem/_utils';

import type { ActionFunction } from '@components/common/CheckStepItem/_utils';

export const validateSteps: ActionFunction<null, boolean> = (_, { setState }) => {
  let isValid: boolean = true;

  setState((prevState) => {
    return prevState.map((step) => {
      if (step.stepKey === checkStepKeys.photo) return step;
      const optionsList = step.optionsList.map((option) => {
        if (option.title.length === 0) {
          isValid = false;
          return {
            ...option,
            hasError: true,
          };
        }

        return option;
      });

      if (step.title.length === 0) {
        isValid = false;
        return {
          ...step,
          hasError: true,
          optionsList,
        };
      }

      return {
        ...step,
        optionsList,
      };
    });
  });

  return isValid;
};
