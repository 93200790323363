/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useUploadFile} from '@hooks';

import {useCheckStepContext} from '../../../_utils';

import styles from './Uploader.module.scss';

type UploaderProps = {};

export const Uploader = (props: UploaderProps) => {
  const {} = props;

  const { t } = useTranslation();

  const { step, actions } = useCheckStepContext();

  const { onChange, inputRef } = useUploadFile({
    cb: (files) => {
      actions?.onChangeStepImages?.(step, files);
    },
    currentFilesLength: step.imagesList.length,
  });

  return (
    <div className={styles.uploaderWrapper}>
      <div className={styles.uploader}>
        <label htmlFor={step.uuid}>
          {t('titles.Upload_Image')}
          <input
            id={step.uuid}
            type="file"
            onChange={onChange}
            ref={inputRef}
            accept=".png, .jpg, .jpeg, .webp"
            multiple
          />
        </label>
      </div>
      <span className={styles.info}>{t('common.placeholder_image_size', { number: 5 })}</span>
    </div>
  );
};
