import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '@shared/UI';

import './ModeSelect.scss';

export type CalendarMode = 'weeks' | 'months';

interface ModeSelectProps {
  value: CalendarMode;
  onChange?: (mode: CalendarMode) => void;
}

const ModeSelect = (props: ModeSelectProps) => {
  const { value, onChange: onChangeProps } = props;

  const { t } = useTranslation();

  const options: { label: string; value: CalendarMode }[] = useMemo(
    () => [
      {
        label: t('titles.Month_View'),
        value: 'months',
      },
      {
        label: t('titles.Week_View'),
        value: 'weeks',
      },
    ],
    []
  );

  const onChange = (mode: CalendarMode) => {
    onChangeProps?.(mode);
  };

  return (
    <Select
      showSearch={false}
      allowClear={false}
      className="calendar-mode-select"
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

const Memoized = memo(ModeSelect);

export { Memoized as ModeSelect };
