import { createSlice } from '@reduxjs/toolkit';
import {
  addVendor,
  getVendorById,
  getVendors,
  getVendorsByFilter,
  removeVendorById,
  updateVendor,
} from '@store/actions/properties/Vendor';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { FilterVendorDto, VendorModel } from '@model/properties/Vendor';

type VendorState = {
  vendorsList: VendorModel[];
  vendorsFilteredList: VendorModel[];
  vendorStatus: LoadingStatus;
  vendor: null | VendorModel;
  vendorError: null | string;
  vendorFilters: FilterVendorDto;
};

const initialState: VendorState = {
  vendorsList: [],
  vendorsFilteredList: [],
  vendorStatus: 'idle',
  vendor: null,
  vendorError: null,
  vendorFilters: {
    partnerNamesArray: [],
    partnerCodesArray: [],
    partnerEmailsArray: [],
    partnerCityIdsArray: [],
  },
};

const VendorSlice = createSlice({
  name: 'Vendor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVendors.pending, (state) => {
        state.vendorStatus = 'pending';
        state.vendorError = null;
        state.vendorsFilteredList = [];
        state.vendorFilters = {
          partnerNamesArray: [],
          partnerCodesArray: [],
          partnerEmailsArray: [],
          partnerCityIdsArray: [],
        };
      })
      .addCase(getVendors.fulfilled, (state, { payload }) => {
        state.vendorStatus = 'idle';
        state.vendorsList = payload;
      })
      .addCase(getVendors.rejected, (state, { payload }) => {
        state.vendorStatus = 'failed';
        state.vendorError = payload as string;
      });
    builder
      .addCase(getVendorsByFilter.pending, (state) => {
        state.vendorError = null;
        state.vendorStatus = 'pending';
      })
      .addCase(getVendorsByFilter.fulfilled, (state, { payload }) => {
        state.vendorStatus = 'idle';
        state.vendorsFilteredList = payload.vendors;
        state.vendorFilters = payload.vendorFilters;
      })
      .addCase(getVendorsByFilter.rejected, (state, action) => {
        state.vendorStatus = 'idle';
        if (action.payload) {
          state.vendorError = action.payload;
        }
      });
    builder
      .addCase(addVendor.pending, (state) => {
        state.vendorError = null;
        state.vendorStatus = 'pending';
      })
      .addCase(addVendor.fulfilled, (state, { payload }) => {
        state.vendorStatus = 'idle';
        state.vendorsList = [payload, ...state.vendorsList];
      })
      .addCase(addVendor.rejected, (state) => {
        state.vendorStatus = 'idle';
      });
    builder
      .addCase(getVendorById.pending, (state) => {
        state.vendorError = null;
        state.vendorStatus = 'pending';
      })
      .addCase(getVendorById.fulfilled, (state, { payload }) => {
        state.vendorStatus = 'idle';
        state.vendor = payload;
      })
      .addCase(getVendorById.rejected, (state) => {
        state.vendorStatus = 'idle';
      });
    builder
      .addCase(updateVendor.pending, (state) => {
        state.vendorError = null;
        state.vendorStatus = 'pending';
      })
      .addCase(updateVendor.fulfilled, (state, { payload }) => {
        state.vendorStatus = 'idle';
        state.vendor = payload.vendor;
        state.vendorsList = payload.vendors;
      })
      .addCase(updateVendor.rejected, (state) => {
        state.vendorStatus = 'idle';
      });
    builder
      .addCase(removeVendorById.pending, (state) => {
        state.vendorError = null;
        state.vendorStatus = 'pending';
      })
      .addCase(removeVendorById.fulfilled, (state, { payload }) => {
        state.vendorStatus = 'idle';
        state.vendorsList = payload;
      })
      .addCase(removeVendorById.rejected, (state) => {
        state.vendorStatus = 'idle';
      });
  },
});

const VendorReducer = VendorSlice.reducer;

export { VendorReducer };
