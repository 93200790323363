import { createSlice } from '@reduxjs/toolkit';
import {
  addPaIModel,
  getPaIModelById,
  getPaIModels,
  removePaIModelById,
  updatePaIModel,
} from '@store/actions/properties/PaI/Model';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { PaIModelModel } from '@model/properties/PaI/Model';

interface PaIModelState {
  PaIModelsList: PaIModelModel[];
  PaIModelStatus: LoadingStatus;
  PaIModel: null | PaIModelModel;
  PaIModelError: null | string;
}

const initialState: PaIModelState = {
  PaIModelsList: [],
  PaIModelStatus: 'idle',
  PaIModel: null,
  PaIModelError: null,
};

const PaIModelSlice = createSlice({
  name: 'PaIModel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaIModels.pending, (state) => {
        state.PaIModelError = null;
        state.PaIModelStatus = 'pending';
      })
      .addCase(getPaIModels.fulfilled, (state, { payload }) => {
        state.PaIModelStatus = 'idle';
        state.PaIModelsList = payload;
      })
      .addCase(getPaIModels.rejected, (state, { payload }) => {
        state.PaIModelStatus = 'idle';
        if (payload) {
          state.PaIModelError = payload;
        }
      });

    builder
      .addCase(addPaIModel.pending, (state) => {
        state.PaIModelStatus = 'pending';
        state.PaIModelError = null;
      })
      .addCase(addPaIModel.fulfilled, (state, { payload }) => {
        state.PaIModelStatus = 'idle';
        state.PaIModelsList = [payload, ...state.PaIModelsList];
      })
      .addCase(addPaIModel.rejected, (state, { payload }) => {
        state.PaIModelStatus = 'idle';
        if (payload) {
          state.PaIModelError = payload;
        }
      });

    builder
      .addCase(getPaIModelById.pending, (state) => {
        state.PaIModelStatus = 'pending';
        state.PaIModelError = null;
      })
      .addCase(getPaIModelById.fulfilled, (state, { payload }) => {
        state.PaIModelStatus = 'idle';
        state.PaIModel = payload;
      })
      .addCase(getPaIModelById.rejected, (state, { payload }) => {
        state.PaIModelStatus = 'idle';
        if (payload) {
          state.PaIModelError = payload;
        }
      });

    builder
      .addCase(updatePaIModel.pending, (state) => {
        state.PaIModelStatus = 'pending';
        state.PaIModelError = null;
      })
      .addCase(updatePaIModel.fulfilled, (state, { payload }) => {
        state.PaIModelStatus = 'idle';
        state.PaIModelsList = payload.models;
        state.PaIModel = payload.particularModel;
      })
      .addCase(updatePaIModel.rejected, (state, { payload }) => {
        state.PaIModelStatus = 'idle';
        if (payload) {
          state.PaIModelError = payload;
        }
      });

    builder
      .addCase(removePaIModelById.pending, (state) => {
        state.PaIModelStatus = 'pending';
        state.PaIModelError = null;
      })
      .addCase(removePaIModelById.fulfilled, (state, { payload }) => {
        state.PaIModelStatus = 'idle';
        state.PaIModelsList = payload;
      })
      .addCase(removePaIModelById.rejected, (state, { payload }) => {
        state.PaIModelStatus = 'idle';
        if (payload) {
          state.PaIModelError = payload;
        }
      });
  },
});

const PaIModelReducer = PaIModelSlice.reducer;

export { PaIModelReducer };
