import React, { memo } from 'react';

import { useClickOutside } from '@hooks';
import { DragHandle } from '@icon/icon-components';
import cl from 'classnames';

import { checkStepKeys, useCheckStepContext } from '../_utils';

import type { WrapperProps } from '../_utils';

import styles from './Wrapper.module.scss';

export const Wrapper = memo((props: WrapperProps) => {
  const { children, dragRef } = props;

  const { onDeactivate, step, isDraggable, type } = useCheckStepContext();

  const wrapperRef = useClickOutside<HTMLDivElement>(onDeactivate);

  const classes = cl(styles.wrapper, {
    [styles.wrapperActive]: step.isActive,
    [styles.wrapperDraggable]: isDraggable,
    [styles.wrapperTakePhoto]: step.stepKey === checkStepKeys.photo,
    [styles.wrapperWithError]: step.hasError && type === 'workStep',
  });

  const dragHandlerClasses = cl(styles.dragHandler, {
    [styles.dragHandlerDragging]: false,
    [styles.dragHandlerHidden]: !isDraggable || step.isActive,
  });

  return (
    <div className={classes} ref={wrapperRef}>
      <div ref={dragRef} className={dragHandlerClasses}>
        <DragHandle onClick={(e) => e.stopPropagation()} />
      </div>

      {children}
    </div>
  );
});
