import type { RootState } from '@store/rootReducer';

export const selectPaIModelState = (state: RootState) => state.PaIModelReducer;

export const selectPaIModelsList = (state: RootState) => selectPaIModelState(state).PaIModelsList;

export const selectPaIModel = (state: RootState) => selectPaIModelState(state).PaIModel;

export const selectPaIModelStatus = (state: RootState) => selectPaIModelState(state).PaIModelStatus;

export const selectPaIModelError = (state: RootState) => selectPaIModelState(state).PaIModelError;
