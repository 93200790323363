import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsAtCardRepository } from '@repositories';

import type {
  AsAtCardModel,
  CreateAsAtCardDto,
  UpdateAsAtCardDto,
} from '@model/properties/AsAt/Card';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getAsAtCards = createAsyncThunk<AsAtCardModel[], void, { rejectValue: string }>(
  'AsAtCard/getAsAtCards',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AsAtCardRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);
export const getAsAtCardById = createAsyncThunk<AsAtCardModel, string, { rejectValue: string }>(
  'AsAtCard/getAsAtCardById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await AsAtCardRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addAsAtCard = createAsyncThunk<
  AsAtCardModel,
  CreateAsAtCardDto,
  { rejectValue: string }
>('AsAtCard/addAsAtCard', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtCardRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateAsAtCard = createAsyncThunk<
  { AsAtCardsList: AsAtCardModel[]; AsAtCard: AsAtCardModel },
  UpdateAsAtCardDto,
  { rejectValue: string }
>('AsAtCard/updateAsAtCard', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtCardRepository.update(dto);

    const responseList = await AsAtCardRepository.fetchAll();

    return {
      AsAtCardsList: responseList.resultObject,
      AsAtCard: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeAsAtCardById = createAsyncThunk<
  AsAtCardModel[],
  number[],
  { rejectValue: string }
>('AsAtCard/removeAsAtCardById', async (ids, { rejectWithValue }) => {
  try {
    await AsAtCardRepository.removeById(ids);
    const response = await AsAtCardRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
