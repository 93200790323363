import React from 'react';

import { Tooltip as AntTooltip } from 'antd';
import cl from 'classnames';

import type { TooltipProps as AntTooltipProps } from 'antd';

export type TooltipProps = AntTooltipProps & {};

export const Tooltip = (props: TooltipProps) => {
  const { className, ...restProps } = props;

  const classes = cl('custom-tooltip', className);

  return <AntTooltip className={classes} {...restProps} />;
};
