import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getCountPlans } from '@store/actions/management/CountPlan';
import { selectCountPlansList } from '@store/selectors/management/CountPlan';

function EntryCountPlan() {
  useSetPageInfo({
    defaultPagePath: paths.countPlan.default,
    pageTitle: 'pages_single.Task',
  });

  const listCountPlan = useAppSelector(selectCountPlansList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listCountPlan.length) {
      dispatch(getCountPlans());
    }
  }, []);

  return <Outlet />;
}

export default EntryCountPlan;
