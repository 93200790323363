import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { PreviewFormAssetParts } from '@components/views/Previews/management';

import type { AssetPartsOutlet } from '../_utils';

export const PreviewAssetParts = () => {
  const { entity } = useOutletContext<AssetPartsOutlet>();

  return <PreviewFormAssetParts entity={entity} />;
};
