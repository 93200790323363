import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PreviewFormVendor } from '@components/views/Previews/properties';
import { useOutletVendor } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { removeVendorById } from '@store/actions/properties/Vendor';

import type { CommonPreviewProps } from '@components/common';

function PreviewVendor() {
  const { entity } = useOutletVendor();

  const { vendorAccess } = useGetAccess();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = async () => {
    try {
      await dispatch(removeVendorById([entity.partnerId])).unwrap();
      ApiNotifications.remove(entity.name);
      navigate(paths.vendor.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !vendorAccess.edit,
    },
    deleteButtonProps: {
      disabled: !vendorAccess.delete,
    },
  };

  return <PreviewFormVendor entity={entity} onDelete={onDelete} header={headerProps} />;
}

export default PreviewVendor;
