import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { CurrencyBlock } from '@components/views/Forms/properties/Contract/CurrencyBlock';
import { Input } from '@shared/UI';

import type { CurrencyModel } from '@model/extra/Currency';

interface LeftSectionProps {
  currencyList: Array<CurrencyModel>;
  isModal?: boolean;
  hidden?: boolean; 
}

const { Item, CaseField } = CommonForm;

// endregion --- Item rules

export const LeftSection = memo((props: LeftSectionProps) => {
  const { currencyList, isModal, hidden } = props;

  const { t } = useTranslation();

  return (
    <CaseField title={t('titles.Summary')} isModal={isModal} hidden={hidden}>
      <Item 
        label={t('titles.Code')} 
        name="contractCode" 
        rules={[
          {
            required: true,
          },
          {
            max: 50,
          }
        ]}
      >
        <Input placeholder={t('titles.Code')} />
      </Item>
      <Item 
        label={t('titles.Name')} 
        name="name" 
        rules={[
          {
            required: true,
          },
          {
            max: 50,
          }
        ]}
      >
        <Input placeholder={t('titles.Name')} />
      </Item>
      <Item 
        label={t('titles.Contract_No')} 
        name="no" 
        rules={[
          {
            required: true,
          },
          {
            max: 50,
          }
        ]}
      >
        <Input placeholder={t('titles.Contract_No')} />
      </Item>
      <CurrencyBlock currencyList={currencyList} />
    </CaseField>
  );
});
