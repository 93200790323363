import React, { useCallback } from 'react';

interface StepsProviderProps {
  children: React.ReactNode;

  initialStep?: number;
}

interface StepsContextValue {
  step: number;
  onNextStep: () => void;
  onPrevStep: () => void;
  onChangeStep: (step: number) => void;
}

export const StepsContext = React.createContext<StepsContextValue>(null!);

const StepsProvider = (props: StepsProviderProps) => {
  const {children, initialStep} = props;

  const [step, setStep] = React.useState(() => typeof initialStep === 'number' ? initialStep : 0);

  const onNextStep = useCallback(() => {
    setStep((prevStep) => prevStep + 1);
  }, []);

  const onPrevStep = useCallback(() => {
    setStep((prevStep) => {
      if (prevStep > 0) {
        return prevStep - 1;
      }
      return prevStep;
    });
  }, []);

  const onChangeStep = useCallback((step: number) => {
    setStep(() => step);
  }, []);

  return (
    <StepsContext.Provider value={{step, onNextStep, onPrevStep, onChangeStep}}>
      {children}
    </StepsContext.Provider>
  );
};

export {StepsProvider};
