import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import { selectPaIModelsList, selectPaIModelStatus } from '@store/selectors/properties/PaI/Model';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { PaIModelModel } from '@model/properties/PaI/Model';

const columns: ColumnsTableType<PaIModelModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'partsAndInventoriesCardModelCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) =>
      compare(a.partsAndInventoriesCardModelCode, b.partsAndInventoriesCardModelCode),
  },
  {
    title: <Translation>{(t) => t('titles.Brand')}</Translation>,
    dataIndex: ['partsAndInventoriesCardBrand', 'name'],
    key: 'brandName',
    ellipsis: true,
    sorter: (a, b) =>
      compare(a.partsAndInventoriesCardBrand.name, b.partsAndInventoriesCardBrand.name),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

const PartAndInventoryModels = () => {
  const { t } = useTranslation();

  const listPartAndInventoryModel = useAppSelector(selectPaIModelsList);
  const statusPartAndInventoryModel = useAppSelector(selectPaIModelStatus);

  const { paiAttrAccess } = useGetAccess();

  const csvData = useMemo(
    () =>
      listPartAndInventoryModel.map((model) => ({
        name: model.name,
        code: model.partsAndInventoriesCardModelCode,
        brandName: model.partsAndInventoriesCardBrand.name,
      })),
    [listPartAndInventoryModel]
  );

  return (
    <BasicTablePage
      table={{
        loading: statusPartAndInventoryModel === 'pending',
        dataSource: listPartAndInventoryModel,
        columns: columns,
        rowKey: (record) => record.partsAndInventoriesCardModelId,
        dataSourceName: t('pages_plural.PaI_Attr_Models'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Model') }),
        csvConfig: {
          data: csvData,
          fileName: 'part-and-inventory-models',
          headers: [
            { label: 'code', key: 'code' },
            { label: 'name', key: 'name' },
            { label: 'brand', key: 'brandName' },
          ],
        },
        newButtonProps: {
          disabled: !paiAttrAccess.create,
        },
      }}
    />
  );
};

export { PartAndInventoryModels };
