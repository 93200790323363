import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormCountPlan } from '@components/views/Forms/managment';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addCountPlan } from '@store/actions/management/CountPlan';

import type { CreateCountPlanDto } from '@model/management/CountPlan';
import type { FormInstance } from 'antd';

const CreateCountPlan = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { t } = useTranslation();

  const { countPlanAccess } = useGetAccess();

  const onFinish = (dto: CreateCountPlanDto, form?: FormInstance<any>) => {
    dispatch(addCountPlan(dto))
      .unwrap()
      .then((payload) => {
        ApiNotifications.create(payload.taskName);
        navigate(paths.countPlan.default);
        form?.resetFields();
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <CheckAccess hasAccess={countPlanAccess.create} redirectTo={paths.countPlan.default}>
      <FormCountPlan
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.Count_Plan') }),
        }}
      />
    </CheckAccess>
  );
};

export default CreateCountPlan;
