import * as React from 'react';

import type { SVGProps } from 'react';

const Inspection = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.894 11.525C18.154 7.85 15.525 6 12 6c-3.527 0-6.154 1.85-7.894 5.527a1.113 1.113 0 0 0 0 .948C5.846 16.15 8.475 18 12 18c3.527 0 6.154-1.85 7.894-5.527a1.113 1.113 0 0 0 0-.948ZM12 16.675c-2.96 0-5.127-1.506-6.655-4.675C6.873 8.83 9.04 7.325 12 7.325c2.96 0 5.127 1.506 6.655 4.675-1.526 3.17-3.693 4.675-6.655 4.675Zm-.073-7.914A3.234 3.234 0 0 0 8.697 12a3.234 3.234 0 0 0 3.23 3.24c1.783 0 3.23-1.451 3.23-3.24a3.234 3.234 0 0 0-3.23-3.24Zm0 5.3A2.057 2.057 0 0 1 9.87 12c0-1.14.92-2.061 2.056-2.061 1.135 0 2.055.922 2.055 2.061 0 1.14-.92 2.061-2.055 2.061Z"
      fill={props.fill || '#E0E0E0'}
    />
  </svg>
);

export { Inspection };
