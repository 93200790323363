import React from 'react';

import type { SVGProps } from 'react';

const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0781 24L8.07812 19.5V14.38L0.078125 5.38V3C0.078125 2.20435 0.394195 1.44129 0.956805 0.87868C1.51941 0.31607 2.28248 0 3.07812 0L19.0781 0C19.8738 0 20.6368 0.31607 21.1994 0.87868C21.7621 1.44129 22.0781 2.20435 22.0781 3V5.38L14.0781 14.38V24ZM10.0781 18.5L12.0781 20V13.62L20.0781 4.62V3C20.0781 2.73478 19.9728 2.48043 19.7852 2.29289C19.5977 2.10536 19.3433 2 19.0781 2H3.07812C2.81291 2 2.55855 2.10536 2.37102 2.29289C2.18348 2.48043 2.07812 2.73478 2.07812 3V4.62L10.0781 13.62V18.5Z"
      fill="#032F5C"
    />
  </svg>
);

export { FilterIcon };
