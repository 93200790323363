import { defaultFormatDate } from '@constants/Date';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import type { RootState } from '@store/rootReducer';

export const selectContractState = (state: RootState) => state.ContractReducer;

export const selectContractsList = (state: RootState) => selectContractState(state).contractsList;

export const selectContractsListWithFormattedDate = createSelector(
  selectContractsList,
  (contractsList) =>
    contractsList.map((contract) => ({
      ...contract,
      endDate: moment(contract.endDate).format(defaultFormatDate),
      startDate: moment(contract.startDate).format(defaultFormatDate),
    }))
);
export const selectContractsFilteredList = (state: RootState) =>
  selectContractState(state).contractsFilteredList;

export const selectContractFilters = (state: RootState) =>
  selectContractState(state).departmentFilters;

export const selectContract = (state: RootState) => selectContractState(state).contract;

export const selectCurrentContractFiles = (state: RootState) =>
  selectContractState(state).contractFiles;

export const selectContractWithFormatDate = createSelector(selectContract, (contract) => {
  if (!contract) return null;
  return {
    ...contract,
    endDate: moment(contract.endDate).format(defaultFormatDate),
    startDate: moment(contract.startDate).format(defaultFormatDate),
  };
});

export const selectContractStatus = (state: RootState) => selectContractState(state).contractStatus;

export const selectContractError = (state: RootState) => selectContractState(state).contractError;
