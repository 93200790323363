import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '@store/rootReducer';

import type { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/rootReducer';

const store = configureStore({
  reducer: (state, action) => rootReducer(state, action),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<any, RootState, any, AnyAction>;

export default store;
