import React from 'react';

import { Checkbox as AntdCheckbox } from 'antd';
import cl from 'classnames';

import type { CheckboxProps as AntdCheckboxProps } from 'antd';

import './Checkbox.scss';

export interface CheckboxProps extends Omit<AntdCheckboxProps, 'className'> {
  className?: string | string[];
}

export interface CheckboxInterface {
  (props: CheckboxProps): React.ReactElement<CheckboxProps>;
  Group: typeof AntdCheckbox.Group;
}

const Checkbox: CheckboxInterface = (props) => {
  const { className, ...rest } = props;

  const checkboxClasses = cl('custom-checkbox', className);

  return <AntdCheckbox className={checkboxClasses} {...rest} />;
};

Checkbox.Group = AntdCheckbox.Group;

export { Checkbox };
