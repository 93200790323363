import * as React from 'react';

import type { SVGProps } from 'react';

const EditingPen = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.8 6.786a1.96 1.96 0 0 0 .55-1.37 1.96 1.96 0 0 0-.55-1.371l-1.487-1.538a1.832 1.832 0 0 0-2.65 0L4.695 12.78v4.28h4.137L18.8 6.786Zm-2.813-2.908 1.488 1.536-1.49 1.536-1.487-1.537 1.49-1.535ZM6.57 15.122v-1.536l6.6-6.804 1.487 1.538-6.599 6.802H6.57ZM4.695 19h15v1.939h-15V19Z"
      fill="#BDD4ED"
    />
  </svg>
);

export { EditingPen };
