import { createSlice } from '@reduxjs/toolkit';
import { getCurrencies } from '@store/actions/extra/Currency';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { CurrencyModel } from '@model/extra/Currency';

type CurrencyState = {
  listCurrency: CurrencyModel[];
  statusCurrency: LoadingStatus;
  errorCurrency: null | string;
};

const initialState: CurrencyState = {
  listCurrency: [],
  errorCurrency: null,
  statusCurrency: 'idle',
};

const CurrencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencies.pending, (state) => {
        state.statusCurrency = 'pending';
        state.errorCurrency = null;
      })
      .addCase(getCurrencies.fulfilled, (state, { payload }) => {
        state.statusCurrency = 'idle';
        state.listCurrency = payload;
      })
      .addCase(getCurrencies.rejected, (state, action) => {
        state.statusCurrency = 'idle';
        if (action.payload) {
          state.errorCurrency = action.payload;
        }
      });
  },
});
const CurrencyReducer = CurrencySlice.reducer;

export { CurrencyReducer };
