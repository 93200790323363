import { checkStepKeys } from '@components/common/CheckStepItem/_utils';

import { mapCheckStepOptionsToWorkStepOptions } from './mapCheckStepOptionsToWorkStepOptions';

import type { CreateMaintenanceStepsDto } from '@model/management/Maintenance';
import type { ActionFunction } from '../../types';

export const mapCheckStepsToWorkStepsDto: ActionFunction<
  {
    maintenanceId: number;
    userId: number;
  },
  CreateMaintenanceStepsDto[]
> = ({ maintenanceId, userId }, { state, setState }) => {
  const dto: CreateMaintenanceStepsDto[] = state.map((step) => {
    let baseStep: CreateMaintenanceStepsDto = {
      stepTypeId: step.stepKey,
      stepName: step.title,
      rowId: step.rowId,
      maintenanceId,
      loggedUserId: userId,
      mcList: [],
      workStepsId: step.externalId,
    };

    if (step.stepKey === checkStepKeys.multiple) {
      baseStep = {
        ...baseStep,
        mcList: mapCheckStepOptionsToWorkStepOptions(
          {
            options: step.optionsList,
            stepExternalId: step.externalId,
          },
          {
            state,
            setState,
          }
        ),
      };
    }

    return baseStep;
  });

  return dto;
};
