import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { adaptMaintenanceAttrCheckStepsToCheckSteps } from '@adapters';
import { CommonForm, DraggableLining, MultiplyCheckStep, OtherCheckStep } from '@components/common';
import { KindOfCheckStep } from '@constants/kindOfCheckStep';
import { useAppSelector } from '@hooks';
import { selectMaAtChecklistStatus } from '@store/selectors/properties/MaAt/Checklist';

import type { CommonPreviewProps, DefaultCommonFormProps } from '@components/common';
import type { MaAtChecklistModel } from '@model/properties/MaAt/Checklist';

const { CaseField, PreviewField, Item } = CommonForm;

const PreviewFormMaintenanceChecklist = (props: CommonPreviewProps<MaAtChecklistModel>) => {
  const { entity, onDelete, header: headerProps } = props;

  const { t } = useTranslation();

  const statusMaintenanceChecklist = useAppSelector(selectMaAtChecklistStatus);

  const header: CommonPreviewProps['header'] = {
    title: entity.checklistName,
    onClickDelete: onDelete,
    ...headerProps,
  };

  const container: DefaultCommonFormProps['container'] = {
    style: {
      maxWidth: '100%',
    },
  };

  const checkSteps = entity.checkListCheckSteps.length
    ? adaptMaintenanceAttrCheckStepsToCheckSteps(entity.checkListCheckSteps)
    : [];

  const loading = statusMaintenanceChecklist === 'pending';

  return (
    <CommonForm
      isPreview
      isOutsidePreviewBack={false}
      loading={loading}
      header={header}
      container={container}
    >
      <CaseField md={12}>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.checklistName}</PreviewField>
        </Item>
      </CaseField>
      <CaseField md={12}>
        <Item label={t('titles.Description')}>
          <PreviewField>{entity.description}</PreviewField>
        </Item>
      </CaseField>

      <DndProvider backend={HTML5Backend}>
        {checkSteps &&
          checkSteps.map((checkStep, idx) => {
            return (
              <DraggableLining
                key={checkStep.entityId}
                index={idx}
                count={`${idx + 1}.`}
                hiddenEditAction
                hiddenDeleteAction
                hiddenCopyAction
                isDraggable={false}
              >
                {checkStep.type === KindOfCheckStep.MultiplyChoice ? (
                  <MultiplyCheckStep multiplyCheckStep={checkStep} />
                ) : null}
                {checkStep.type !== KindOfCheckStep.MultiplyChoice &&
                checkStep.type !== KindOfCheckStep.Photo ? (
                  <OtherCheckStep otherCheckStep={checkStep} />
                ) : null}
              </DraggableLining>
            );
          })}
      </DndProvider>
    </CommonForm>
  );
};

export { PreviewFormMaintenanceChecklist };
