import React from 'react';

import cl from 'classnames';

import './StatusBadge.scss';

export interface StatusBadgeProps {
  color: string;
  label?: React.ReactNode;
  className?: string | string[];
}

const StatusBadge = (props: StatusBadgeProps) => {
  const { color, label, className } = props;

  const statusBadgeClasses = cl('status-badge', className);

  return (
    <div className={statusBadgeClasses}>
      <div className="status-badge__badge">
        <span style={{ backgroundColor: color }} />
      </div>
      {label && <span className="status-badge__label">{label}</span>}
    </div>
  );
};

export { StatusBadge };
