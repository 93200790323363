import * as React from 'react';

import type { SVGProps } from 'react';

const FlagTurkeyCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg" 
    {...props}
  >
  <g clipPath="url(#clip0_5789_150017)">
    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#E30917"/>
    <path d="M15.666 14.5938L15.7051 12.4844L13.5957 11.8594L15.7051 11.2344L15.666 9.125L16.9551 10.8047L19.0645 10.1797L17.7754 11.8594L19.0645 13.5391L16.9551 12.9141L15.666 14.5938Z" fill="white"/>
    <path d="M12.502 16.5469C9.92383 16.5469 7.85352 14.4375 7.85352 11.8594C7.85352 9.28125 9.92383 7.17188 12.502 7.17188C13.4785 7.17188 14.377 7.48438 15.1582 7.99219C14.1035 6.78125 12.541 6 10.7832 6C7.58008 6 5.00195 8.61719 5.00195 11.8594C5.00195 15.1016 7.58008 17.7188 10.7832 17.7188C12.541 17.7188 14.1035 16.9375 15.1582 15.7266C14.416 16.2344 13.5176 16.5469 12.502 16.5469Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_5789_150017">
    <rect width="24" height="24" fill="white"/>
  </clipPath>
  </defs>
  </svg>
);

export { FlagTurkeyCircle };
