import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseCheckStepTitle } from '@components/common/CheckStepItems/BaseCheckStepTitle';
import { CrossIcon, MultiplyChoice } from '@icon/icon-components';
import { truncate } from '@shared/utils/functions';
import cl from 'classnames';

import type { MultiplyCheckStepItem } from '@constants/checkStepItems';

import './MultiplyCheckStep.scss';

export interface MultiplyCheckStepProps {
  multiplyCheckStep: MultiplyCheckStepItem;
  isErrorTitle?: boolean;
  onChangeTitle?: (title: string, uuid: string) => void;
  onChangeRadioTitle?: (value: string, parentId: string, key: string) => void;
  onAddNewRadio?: (parentId: string) => void;
  onRemoveOption?: (parentId: string, key: string, id?: number) => void;
}

const defaultClassPrefix = 'multiply-check-step';

const MultiplyCheckStep = (props: MultiplyCheckStepProps) => {
  const {
    multiplyCheckStep,
    isErrorTitle,
    onChangeRadioTitle: onChangeRadioTitleProps,
    onChangeTitle: onChangeTitleProps,
    onAddNewRadio: onAddNewRadioProps,
    onRemoveOption: onRemoveOptionProps,
  } = props;

  const { isFocus, radios, uuid, title } = multiplyCheckStep;

  const { t } = useTranslation();

  const defaultClasses = cl(defaultClassPrefix, {
    [`${defaultClassPrefix}--active`]: isFocus,
    [`${defaultClassPrefix}--disabled`]: !isFocus,
  });

  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeTitleProps?.(e.target.value, uuid);
    },
    [onChangeTitleProps, uuid]
  );

  const onChangeRadioTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeRadioTitleProps?.(e.target.value, uuid, e.target.name);
    },
    [onChangeRadioTitleProps]
  );

  const onAddNewRadio = useCallback(() => {
    onAddNewRadioProps?.(uuid);
  }, [onAddNewRadioProps, uuid]);

  const onRemoveOption = useCallback(
    (uuid: string, key: string, id?: number) => {
      return () => {
        onRemoveOptionProps?.(uuid, key, id);
      };
    },
    [uuid, onRemoveOptionProps]
  );

  return (
    <div className={defaultClasses}>
      <div className={cl(`${defaultClassPrefix}__input-title-wrapper`)}>
        <BaseCheckStepTitle
          isError={isErrorTitle}
          title={title}
          onChangeTitle={onChangeTitle}
          isFocus={isFocus}
        />
      </div>
      <div className={cl(`${defaultClassPrefix}__radios-wrapper`)}>
        {Object.entries(radios).map(([key, val]) => (
          <div key={key} className={cl(`${defaultClassPrefix}__radio-input`)}>
            <MultiplyChoice />
            {isFocus ? (
              <input
                onChange={onChangeRadioTitle}
                name={key}
                type="text"
                value={val.value}
                placeholder={val.placeholder}
              />
            ) : (
              <span>{truncate(val.value || '', 200)}</span>
            )}
            {isFocus ? (
              <span
                onClick={onRemoveOption(uuid, key, val.id)}
                className={cl(`${defaultClassPrefix}__radio-input_close`)}
              >
                <CrossIcon />
              </span>
            ) : null}
          </div>
        ))}
        {isFocus && (
          <div className={cl(`${defaultClassPrefix}__add-radio`)}>
            <MultiplyChoice />
            <span onClick={onAddNewRadio}>{t('titles.Add_Option')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export { MultiplyCheckStep };
