import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getPaIBrands } from '@store/actions/properties/PaI/Brand';
import { selectPaIBrandsList } from '@store/selectors/properties/PaI/Brand';

function EntryPartAndInventoryBrand() {
  useSetPageInfo(
    {
      pageTitle: 'pages_single.PaI_Attr',
      defaultPagePath: paths.partAndInventoryAttributes.tabs.brand.default,
    },
    {
      copyTabs: true,
    }
  );

  const partAndInventoryBrandList = useAppSelector(selectPaIBrandsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!partAndInventoryBrandList.length) {
      dispatch(getPaIBrands());
    }
  }, []);

  return <Outlet />;
}

export default EntryPartAndInventoryBrand;
