import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormPartAndInventoryStatus } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addPaIStatus } from '@store/actions/properties/PaI/Status';

import type { CreatePaIStatusDto } from '@model/properties/PaI/Status';
import type { FormInstance } from 'antd';

function CreatePartAndInventoryModel() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onFinish = async (dto: CreatePaIStatusDto, form?: FormInstance<CreatePaIStatusDto>) => {
    try {
      const response = await dispatch(addPaIStatus(dto)).unwrap();
      ApiNotifications.create(response.name);
      navigate(paths.partAndInventoryAttributes.tabs.status.default);
      form?.resetFields();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess
      hasAccess={paiAttrAccess.create}
      redirectTo={paths.partAndInventoryAttributes.tabs.status.default}
    >
      <FormPartAndInventoryStatus
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Status') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreatePartAndInventoryModel;
