import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getDepartmentById } from '@store/actions/properties/Department';
import { selectDepartment } from '@store/selectors/properties/Department';

import type { IOutletDepartment } from '@constants/OutletContextEntityPages';
import type { CreateDepartmentDto } from '@model/properties/Department';

function Department() {
  const department = useAppSelector(selectDepartment);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !department || department.departmentId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getDepartmentById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateDepartmentDto = {
    departmentCode: department.departmentCode,
    name: department.name,
    siteId: department.siteId,
    parentDepartmentId: department.parentDepartmentId,
  };

  return <Outlet context={{ initialValues, entity: department } as IOutletDepartment} />;
}

export default Department;
