import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PreviewFormRole } from '@components/views/Previews/users';
import { useOutletRole } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeRoleById } from '@store/actions/user/Role';

function PreviewRole() {
  const { entity } = useOutletRole();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onDelete = () => {
    dispatch(removeRoleById([entity.roleId]))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t(`notifications.Success`),
          description: t('notifications.Deleted', { name: entity.name }),
        });
        navigate(paths.roles.default);
      })
      .catch((error) => {
        notificationController.error({
          message: t(`notifications.Error`),
          description: t(`errors.${error}`),
        });
      });
  };

  return <PreviewFormRole entity={entity} onDelete={onDelete} />;
}

export default PreviewRole;
