import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSite } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addSite } from '@store/actions/properties/Site';

import type { CreateSiteDto } from '@model/properties/Site';
import type { FormInstance } from 'antd';

const CreateSite = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { siteAccess } = useGetAccess();

  const onFinish = async (dto: CreateSiteDto, form?: FormInstance<CreateSiteDto>) => {
    try {
      const response = await dispatch(addSite(dto)).unwrap();
      ApiNotifications.create(response.createdSite.name);
      navigate(paths.site.default);
      form?.resetFields();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={siteAccess.create} redirectTo={paths.site.default}>
      <FormSite
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.Site') }),
        }}
      />
    </CheckAccess>
  );
};

export default CreateSite;
