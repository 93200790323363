import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultFormatDate } from '@constants/Date';
import { Col, DatePicker, Row } from '@shared/UI';

import { CommonForm } from '../../CommonForm';

import type { DatePickerProps, ItemProps} from '@shared/UI';
import type { Moment } from 'moment/moment';

interface DatesBlockProps {
  startDate: Moment | null;

  endDate: Moment | null;

  startDateItemProps?: ItemProps;

  endDateItemProps?: ItemProps;

  startDateSelectProps?: DatePickerProps;

  endDateSelectProps?: DatePickerProps;
}

const { Item } = CommonForm;
export const DatesBlock = (props: DatesBlockProps) => {
  const {
    startDate,
    endDate,
    startDateItemProps,
    endDateItemProps,
    startDateSelectProps,
    endDateSelectProps,
  } = props;

  const { t } = useTranslation();

  const disabledEndDate = useCallback(
    (currentDate: Moment) => {
      if (!startDate) return false;

      return currentDate <= startDate.clone();
    },
    [startDate]
  );

  const disabledStartDate = useCallback(
    (currentDate: Moment) => {
      if (!endDate) return false;

      return currentDate >= endDate.clone();
    },
    [endDate]
  );

  return (
    <Row gutter={[10, 0]} justify="space-between">
      <Col md={10} xs={24}>
        <Item label={t('titles.Start_Date')} name="startDate" {...startDateItemProps}>
          <DatePicker
            textAlign="center"
            placeholder={defaultFormatDate}
            disabledDate={disabledStartDate}
            allowClear={false}
            {...startDateSelectProps}
          />
        </Item>
      </Col>
      <Col md={10} xs={24}>
        <Item label={t('titles.End_Date')} name="endDate" {...endDateItemProps}>
          <DatePicker
            textAlign="center"
            placeholder={defaultFormatDate}
            disabledDate={disabledEndDate}
            allowClear={false}
            {...endDateSelectProps}
          />
        </Item>
      </Col>
    </Row>
  );
};
