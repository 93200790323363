import React, { useEffect, useRef } from 'react';

import { useAppDispatch } from '@hooks';
import { notificationController } from '@services/Notifications';
import { getUserAlerts, markAlertAsRead } from '@store/actions/extra/Alert';

import type { AlertModel } from '@model/extra/Alert';
import type { NotificationProps } from '@services/Notifications';

interface UserAlertsProps {
  children: React.ReactNode;
}

function showAlert(alerts: AlertModel[], onClose: (id: number) => void) {
  if (Array.isArray(alerts)) {
    alerts.forEach((message, idx) => {
      const config: NotificationProps = {
        description: message.alertHeader,
        onClose: () => onClose(message.alertId),
        onClick: () => onClose(message.alertId),
      };

      if (!message.isRead) {
        setTimeout(() => notificationController.message(config), idx * 500);
      }
    });
  }
}

const UserAlerts = (props: UserAlertsProps) => {
  const { children } = props;

  const dispatch = useAppDispatch();

  const ref = useRef<NodeJS.Timer | null>(null);

  const onClose = (id: number) => {
    dispatch(markAlertAsRead(id));
  };

  const getAlerts = () => {
    dispatch(getUserAlerts())
      .unwrap()
      .then((res) => showAlert(res, onClose));
  };

  useEffect(() => {
    ref.current = setInterval(getAlerts, 60 * 1000);

    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export { UserAlerts };
