import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getMaintenances } from '@store/actions/management/Maintenance';
import { selectMaintenancesList } from '@store/selectors/management/Maintenance';

function EntryMaintenance() {
  const dispatch = useAppDispatch();

  const listMaintenances = useAppSelector(selectMaintenancesList);

  useSetPageInfo({
    defaultPagePath: paths.maintenance.default,
    pageTitle: 'pages_single.Maintenance',
  });

  useEffect(() => {
    if (!listMaintenances.length) {
      dispatch(getMaintenances());
    }
  }, []);

  return <Outlet />;
}

export default EntryMaintenance;
