import React from 'react';

import { MaintenanceCalendar } from '@components/views/Calendar';
import { MaintenanceKanban } from '@components/views/Kanban';
import { Maintenances } from '@components/views/Tables/managment';
import { MaintenanceViewType } from '@constants/MaintenanceViewType';
import { useAppSelector } from '@hooks';
import { selectMaintenanceViewType } from '@store/selectors/management/Maintenance';

const TableCompany = () => {
  const { viewType } = useAppSelector(selectMaintenanceViewType);

  const renderPage = () => {
    switch (viewType) {
      case MaintenanceViewType.Calendar:
        return <MaintenanceCalendar />;
      case MaintenanceViewType.List:
        return <Maintenances />;
      case MaintenanceViewType.Board:
        return <MaintenanceKanban />;
      default:
        return null;
    }
  };

  const page = renderPage();

  return <>{page}</>;
};

export default TableCompany;
