import React from 'react';

import { Divider as AntdDivider } from 'antd';
import cl from 'classnames';

import type { DividerProps as AntdDividerProps } from 'antd';

import './Divider.scss';

export interface DividerProps extends Omit<AntdDividerProps, 'className'> {
  className?: string | string[];
}

function Divider(props: DividerProps) {
  const { className, ...rest } = props;
  return <AntdDivider {...rest} className={cl('custom-divider', className)} />;
}

export default Divider;
