import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, SelectSite, SelectUser } from '@components/common';
import { SelectCompany } from '@components/common/form-fields/SelectCompany';
import { useAppSelector } from '@hooks';
import { CheckRound } from '@icon/icon-components';
import { Checkbox } from '@shared/UI';
import { selectUserAuthStatus } from '@store/selectors/users/UserAuthorization';
import { Col, Row } from 'antd';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateUserAuthorizationDto } from '@model/users/UserAuthorization';

const { CaseField, Item, useForm } = CommonForm;

function FormUserAuthorization(props: CommonFormProps<CreateUserAuthorizationDto>) {
  const { initialValues, onFinish, ...rest } = props;
  const [form] = useForm<CreateUserAuthorizationDto>();

  const { t } = useTranslation();

  const statusUserAuth = useAppSelector(selectUserAuthStatus);

  const [disableCompanySelect, setDisabled] = useState(
    initialValues?.isUserAreAuthorizedForTheAllCompanies || false
  );
  const [disableSiteSelect, setDisabled1] = useState(
    initialValues?.isUserAreAuthorizedForTheAllSites || false
  );

  const userAdd = (userId: number) => {
    return userId == 0 ? (
      <>
        <SelectUser
          label={t('titles.User_Name')}
          rules={[
            {
              required: true,
            },
          ]}
        />
      </>
    ) : null;
  };

  const checkAll = (value: boolean, name: string) => {
    return value ? (
      <Row>
        <Col span={3}>
          <CheckRound />
        </Col>
        <Col span={20}>{t(`titles.All_${name}_Authorized`)} </Col>
      </Row>
    ) : null;
  };

  const isAll = (value: boolean, name: string) => {
    if (name === 'Companies') {
      setDisabled(value);
      form.resetFields(['companyIds']);
    }
    if (name === 'Sites') {
      setDisabled1(value);
      form.resetFields(['siteIds']);
    }
    checkAll(value, name);
  };

  const init = (name: string) => {
    if (name === 'Companies')
      return initialValues?.isUserAreAuthorizedForTheAllCompanies
        ? initialValues?.isUserAreAuthorizedForTheAllCompanies
        : false;
    if (name === 'Sites')
      return initialValues?.isUserAreAuthorizedForTheAllSites
        ? initialValues?.isUserAreAuthorizedForTheAllSites
        : false;
  };

  return (
    <CommonForm
      form={form}
      loading={statusUserAuth === 'pending'}
      onFinish={onFinish}
      initialValues={{
        isUserAreAuthorizedForTheAllCompanies: false,
        isUserAreAuthorizedForTheAllSites: false,
        ...initialValues,
      }}
      {...rest}
    >
      <CaseField md={24} lg={12}>
        {userAdd(initialValues?.userId || 0)}
        <Item name="isUserAreAuthorizedForTheAllCompanies" valuePropName="checked">
          <Row wrap gutter={[10, 0]} justify="space-between">
            <Col span={12}>
              <Checkbox
                onChange={(checked) => isAll(checked.target.checked, 'Companies')}
                defaultChecked={init('Companies')}
              >
                {t('titles.All_Companies')}
              </Checkbox>
            </Col>
            <Col span={12}>{checkAll(disableCompanySelect, 'Companies')}</Col>
          </Row>
        </Item>
        <SelectCompany
          name="companyIds"
          mode="multiple"
          restict={false}
          disabled={disableCompanySelect}
          rules={[
            {
              required: !disableCompanySelect,
            },
          ]}
        />
        <Item name="isUserAreAuthorizedForTheAllSites" valuePropName="checked">
          <Row wrap gutter={[10, 0]} justify="space-between">
            <Col span={12}>
              <Checkbox
                onChange={(checked) => isAll(checked.target.checked, 'Sites')}
                defaultChecked={init('Sites')}
              >
                {t('titles.All_Sites')}
              </Checkbox>
            </Col>
            <Col span={12}>{checkAll(disableSiteSelect, 'Sites')}</Col>
          </Row>
        </Item>
        <SelectSite
          name="siteIds"
          mode="multiple"
          disabled={disableSiteSelect}
          rules={[
            {
              required: !disableSiteSelect,
            },
          ]}
        />
      </CaseField>
    </CommonForm>
  );
}

export { FormUserAuthorization };
