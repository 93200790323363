import { createAsyncThunk } from '@reduxjs/toolkit';
import { MaAtCategoryRepository } from '@repositories';

import type {
  CreateMaAtCategoryDto,
  MaAtCategoryModel,
  UpdateMaAtCategoryDto,
} from '@model/properties/MaAt/Category';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getMaAtCategories = createAsyncThunk<
  MaAtCategoryModel[],
  void,
  { rejectValue: string }
>('MaAtCategory/getMaAtCategories', async (_, { rejectWithValue }) => {
  try {
    const response = await MaAtCategoryRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});
export const getMaAtCategoryById = createAsyncThunk<
  MaAtCategoryModel,
  string,
  { rejectValue: string }
>('MaAtCategory/getMaAtCategoryById', async (id, { rejectWithValue }) => {
  try {
    const response = await MaAtCategoryRepository.fetchById(id);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addMaAtCategory = createAsyncThunk<
  MaAtCategoryModel,
  CreateMaAtCategoryDto,
  { rejectValue: string }
>('MaAtCategory/addMaAtCategory', async (dto, { rejectWithValue }) => {
  try {
    const response = await MaAtCategoryRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateMaAtCategory = createAsyncThunk<
  {
    MaAtCategoriesList: MaAtCategoryModel[];
    MaAtCategory: MaAtCategoryModel;
  },
  UpdateMaAtCategoryDto,
  { rejectValue: string }
>('MaAtCategory/updateMaAtCategory', async (dto, { rejectWithValue }) => {
  try {
    const response = await MaAtCategoryRepository.update(dto);

    // TODO: need to change this to a better way (update current state, not a request to the server) [this update need to do for all actions]
    const responseList = await MaAtCategoryRepository.fetchAll();

    return {
      MaAtCategoriesList: responseList.resultObject,
      MaAtCategory: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeMaAtCategoryById = createAsyncThunk<
  MaAtCategoryModel[],
  number[],
  { rejectValue: string }
>('MaAtCategory/removeMaAtCategoryById', async (ids, { rejectWithValue }) => {
  try {
    await MaAtCategoryRepository.removeById(ids);
    const response = await MaAtCategoryRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
