import { api } from '@services/Axios';

import type { CompanyModel, CreateCompanyDto, FilterCompanyDto, UpdateCompanyDto } from '@model/users/Company';
import type { ApiResponse } from '@services/Axios';

const CompanyEndpoint = '/Company';

const CompanyRepository = {
  async fetchAll() {
    const response: ApiResponse<CompanyModel[]> = await api.get(
      `${CompanyEndpoint}/GetCompaniesList`
    );
    return response.data;
  },
  async fetchAllByFilter(dto: FilterCompanyDto) {
    const response: ApiResponse<CompanyModel[]> = await api.post(
      `${CompanyEndpoint}/GetCompaniesListByFilter`,
      dto
    );
    return response.data;
  },
  async fetchAllWithoutRestiction() {
    const response: ApiResponse<CompanyModel[]> = await api.get(
      `${CompanyEndpoint}/GetAllCompaniesList`
    );
    return response.data;
  },
  async add(dto: CreateCompanyDto) {
    const response: ApiResponse<CompanyModel> = await api.post(
      `${CompanyEndpoint}/AddCompany`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<CompanyModel> = await api.get(
      `${CompanyEndpoint}/GetCompanyById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdateCompanyDto) {
    const response: ApiResponse<CompanyModel> = await api.post(
      `${CompanyEndpoint}/UpdateFirm`,
      dto
    );

    return response.data;
  },

  async updateCompanySession(id: number) {
    const response = await api.post<{
      expiration: string;
      token: string;
      resultStatus: boolean;
    }>(`${CompanyEndpoint}/ChangeCompanieSession`, { companyId: id });

    return response.data;
  },

  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${CompanyEndpoint}/RemoveByIdList`, {
      companyIds: ids,
    });

    return response.data;
  },
};
export { CompanyRepository };
