import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getAsAtStatuses } from '@store/actions/properties/AsAt/Status';
import { selectAsAtStatusesList } from '@store/selectors/properties/AsAt/Status';

function EntryAssetStatus() {
  useSetPageInfo(
    {
      defaultPagePath: paths.assetAttribute.tabs.status.default,
      pageTitle: 'pages_single.Asset_Attr',
    },
    {
      copyTabs: true,
    }
  );
  const assetStatusList = useAppSelector(selectAsAtStatusesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!assetStatusList.length) {
      dispatch(getAsAtStatuses());
    }
  }, []);

  return <Outlet />;
}

export default EntryAssetStatus;
