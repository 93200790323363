import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Input, Select } from '@shared/UI';
import { getPaIBrands } from '@store/actions/properties/PaI/Brand';
import { selectPaIBrandsList, selectPaIBrandStatus } from '@store/selectors/properties/PaI/Brand';
import { selectPaIModelStatus } from '@store/selectors/properties/PaI/Model';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreatePaIModelDto } from '@model/properties/PaI/Model';

const { CaseField, Item, useForm } = CommonForm;

function FormPartAndInventoryModel(props: CommonFormProps<CreatePaIModelDto>) {
  const [form] = useForm<CreatePaIModelDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const statusPartAndInventoryModel = useAppSelector(selectPaIModelStatus);

  const statusPartAndInventoryBrand = useAppSelector(selectPaIBrandStatus);
  const listPartAndInventoryBrand = useAppSelector(selectPaIBrandsList);

  useEffect(() => {
    if (!listPartAndInventoryBrand.length) {
      dispatch(getPaIBrands());
    }
  }, []);

  return (
    <CommonForm 
      form={form} 
      loading={statusPartAndInventoryModel === 'pending'} 
      isModal={isModal}
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onCancel={rest.header?.onClickCancel}
        /> : null
      }
      name='formPaIModel' 
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="partsAndInventoriesCardModelCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item
          label={t('titles.Brand')}
          name="partsAndInventoriesCardBrandId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={listPartAndInventoryBrand}
            loading={statusPartAndInventoryBrand === 'pending'}
            fieldNames={{
              label: 'name',
              value: 'partsAndInventoriesCardBrandId',
            }}
            placeholder={t('titles.Choose_Name', { name: t('titles.Brand') })}
          />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormPartAndInventoryModel };
