/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StepsProvider, useStepsContext } from '@components/providers/StepsProvider';
import { FormAsset, FormAssetParts } from '@components/views/Forms/managment';
import { useAppNavigate } from '@hooks';
import { paths } from '@routes/paths';

import type { CommonFormProps } from '@components/common';
import type { CreateAssetDto } from '@model/management/Asset';
import type { CreateAssetPartDto } from '@model/management/AssetParts';

type EntryFormAssetProps = {
  onFinishGeneral?: (dto: CreateAssetDto) => Promise<number>;

  onFinishParts?: (dto: CreateAssetPartDto) => Promise<void>;
};

export const EntryFormAssetComponent = (props: EntryFormAssetProps) => {
  const { onFinishGeneral, onFinishParts } = props;

  const [createAssetID, setCreateAssetID] = useState<number | null>(null);

  const { t } = useTranslation();
  const navigate = useAppNavigate();

  const { step, onNextStep, onChangeStep } = useStepsContext();

  const handleGeneralFinish = async (dto: CreateAssetDto) => {
    try {
      const assetId = await onFinishGeneral?.(dto);
      if (assetId) {
        setCreateAssetID(assetId);
      }
      onNextStep();
    } catch (e) {}
  };

  const handlePartsFinish = async (dto: CreateAssetPartDto) => {
    if (createAssetID) {
      onFinishParts?.({ ...dto, nonCurrAssetId: createAssetID }).then(() => {
        onChangeStep(0);
        navigate(paths.asset.default);
      });
    }
  };

  const onClickCancel = () => {
    if (step === 0 || step === 1) {
      navigate(paths.asset.default);
      return;
    }
  };

  const headerConfigurations: CommonFormProps['header'] = {
    textSaveButton: step === 1 ? t('titles.Save') : t('titles.Save_And_Next'),
    textCancelButton: step === 0 ? t('titles.Cancel') : t('titles.Skip'),
    onClickCancel,
    title: t('titles.New_Item', { name: t('pages_single.Asset') }),
  };

  if (step === 1) {
    return <FormAssetParts onFinish={handlePartsFinish} header={headerConfigurations} />;
  }

  return <FormAsset onFinish={handleGeneralFinish} header={headerConfigurations} />;
};

export const EntryFormAsset = (props: EntryFormAssetProps) => {
  return (
    <StepsProvider>
      <EntryFormAssetComponent {...props} />
    </StepsProvider>
  );
};
