import React, { memo } from 'react';

import { Content, Header, Navbar } from '@components/layouts';
import { Layout } from 'antd';

import './Main.scss';

const Main = () => {
  return (
    <Layout className="main-layout" hasSider>
      <Navbar />
      <Layout className="content-layout">
        <Header />
        <Content />
      </Layout>
    </Layout>
  );
};

export default memo(Main);
