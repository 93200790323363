import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { BaseForm, Button } from '@shared/UI';
import { passwordRecovery } from '@store/actions/profile/Auth';
import { selectAuthStatus } from '@store/selectors/profile/Auth';
import { Input } from 'antd';

import type { ForgotPasswordDto } from '@model/profile/Auth';

const ForgotPasswordForm = () => {
  const [form] = BaseForm.useForm<ForgotPasswordDto>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const passwordRecoveryStatus = useAppSelector(selectAuthStatus);

  const [error, setError] = useState(false);

  const redirectToSignIn = () => {
    navigate(-1);
  };

  const onFinish = (dto: ForgotPasswordDto) => {
    dispatch(passwordRecovery(dto))
      .unwrap()
      .then(() => {
        setError(false);
        redirectToSignIn();
        ApiNotifications.info(t('notifications.Sent_Password_To_Email'));
      })
      .catch(() => {
        setError(true);
      });
  };

  const rules = useMemo(
    () => [
      { required: true, message: t('errors.Is_Required', { name: t('titles.Email_Address') }) },
      {
        pattern: new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/),
        message: t('errors.Not_Valid', { name: t('titles.Email_Address') }),
      },
    ],
    [t]
  );

  return (
    <BaseForm form={form} className="actions" onFinish={onFinish}>
      <BaseForm.Item
        rules={rules}
        label={t('titles.Email_Address')}
        name="userMail"
        validateStatus={error ? 'error' : undefined}
        help={error ? 'Your mail is not registered in the system' : undefined}
      >
        <Input className={'input'} />
      </BaseForm.Item>
      <BaseForm.Item>
        <Button loading={passwordRecoveryStatus === 'pending'} htmlType="submit" className="button">
          Send
        </Button>
        <Button type="ghost" className="button" onClick={redirectToSignIn}>
          Back
        </Button>
      </BaseForm.Item>
    </BaseForm>
  );
};

export { ForgotPasswordForm };
