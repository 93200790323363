import { createAsyncThunk } from '@reduxjs/toolkit';
import { DefinitionRepository } from '@repositories';

// region --- Get Actions

export const getCities = createAsyncThunk(
  'definition/getCities',
  async (_, { rejectWithValue }) => {
    try {
      const response = await DefinitionRepository.fetchCities();

      return response.resultObject;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const getCountries = createAsyncThunk(
  'definition/getCountries',
  async (_, { rejectWithValue }) => {
    try {
      const response = await DefinitionRepository.fetchCountries();

      return response.resultObject;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

// endregion --- Get Actions
