import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectPaIModelStatus } from '@store/selectors/properties/PaI/Model';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { PaIModelModel } from '@model/properties/PaI/Model';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormPartAndInventoryModel(props: CommonPreviewProps<PaIModelModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusPartAndInventoryModel = useAppSelector(selectPaIModelStatus);

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={statusPartAndInventoryModel === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.partsAndInventoriesCardModelCode}</PreviewField>
        </Item>
        <Item label={t('titles.Brand')}>
          <PreviewField>{entity.partsAndInventoriesCardBrand.name}</PreviewField>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormPartAndInventoryModel };
