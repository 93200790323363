import React from 'react';

import { PreviewFormMaintenanceCategory } from '@components/views/Previews/properties';
import { useOutletMaintenanceCategory } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { removeMaAtCategoryById } from '@store/actions/properties/MaAt/Category';

import type { CommonPreviewProps } from '@components/common';

function PreviewMaintenanceCategory() {
  const { entity } = useOutletMaintenanceCategory();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { maintenanceAttrAccess } = useGetAccess();

  const onDelete = async () => {
    try {
      await dispatch(removeMaAtCategoryById([entity.maintinanceCategoryId]));
      ApiNotifications.remove(entity.categoryName);
      navigate(paths.maintenanceAttributes.tabs.category.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !maintenanceAttrAccess.edit,
    },
    deleteButtonProps: {
      disabled: !maintenanceAttrAccess.delete,
    },
  };

  return (
    <PreviewFormMaintenanceCategory entity={entity} onDelete={onDelete} header={headerProps} />
  );
}

export default PreviewMaintenanceCategory;
