import React from 'react';
import { Translation } from 'react-i18next';

import { IterationValue } from '@constants/iterationValue';

export const iterationOptions = [
  {
    label: <Translation>{(t) => t('titles.Day')}</Translation>,
    value: IterationValue.EVERY_DAY,
  },
  {
    label: <Translation>{(t) => t('titles.Week')}</Translation>,
    value: IterationValue.EVERY_WEEK,
  },
  {
    label: <Translation>{(t) => t('titles.Month')}</Translation>,
    value: IterationValue.EVERY_MONTH,
  },
];
