import React from 'react';

import { SignIn as SignInComponent } from '@components/views/Auth';
// import { SignInTest } from '@components/views/Auth';

// <SignInTest />
const SignIn = () => {
  return <SignInComponent />;
};

export default SignIn;
