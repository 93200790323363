import * as React from 'react';

import type { SVGProps } from 'react';

export const PortfolioIcon = (props: SVGProps<SVGSVGElement>) => {
  const { stroke = '#fff', ...restProps } = props;
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M21 13.255A23.93 23.93 0 0 1 12 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2m4 6h.01M5 20h14a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2Z"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
