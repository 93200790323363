import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Label, Select } from '@shared/UI';
import { getLabels } from '@store/actions/extra/Label';
import { selectLabelsList, selectLabelTypesList } from '@store/selectors/extra/Label';

import type { SelectProps } from '@shared/UI';

const { Item } = CommonForm;

interface SelectLabelsProps extends Omit<SelectProps, 'options'> {
  nameItem?: string;
  labelItem?: string;
  enumLabelPlace: 1 | 2;
}

const SelectLabels = (props: SelectLabelsProps) => {
  const { enumLabelPlace, nameItem, labelItem, ...restProps } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const listLabel = useAppSelector(selectLabelsList);
  const typeLabelList = useAppSelector(selectLabelTypesList);

  useEffect(() => {
    if (!listLabel.length || typeLabelList !== enumLabelPlace) {
      dispatch(getLabels(enumLabelPlace));
    }
  }, []);

  const labelOptions = useMemo(
    () =>
      listLabel
        .map((label) => ({
          label: label.label,
          value: label.label,
          labelColor: label.labelColor,
        }))
        .reduce((acc: { label: string; value: string; labelColor: string }[], curr) => {
          return acc.some((val) => val.label === curr.label) ? acc : [...acc, curr];
        }, []),
    [listLabel]
  );

  const normalizeLabelValues = (value: string[]) => {
    if (value.length > 0) {
      return value.map((label) => {
        const option = labelOptions.find((option) => option.value === label)!;
        return {
          label: option.value,
          labelColor: option.labelColor,
          value: option.value,
        };
      });
    }

    return value;
  };

  return (
    <>
      <Item
        label={labelItem ? labelItem : t('titles.Label')}
        name={nameItem ? nameItem : 'labelsList'}
        normalize={normalizeLabelValues}
      >
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Label') })}
          options={labelOptions}
          mode="multiple"
          showArrow
          maxTagCount="responsive"
          tagRender={(option) => {
            const color = listLabel.find((label) => option.label === label.label)?.labelColor;
            return <Label color={color ? color : 'default'}>{option.label}</Label>;
          }}
          {...restProps}
        />
      </Item>
    </>
  );
};

export { SelectLabels };
