import React, { useState } from 'react';

import { useModalState, useUploadFile } from '@hooks';
import { ImageCropperModal } from '@shared/UI';

import EmptyAvatarSVG from '../../../../assets/icons/empty-avatar.svg';

import './UploaderAvatar.scss';

export interface UploaderAvatarProps {
  avatar: string | null;
  onChange?: (avatar: string | null) => void;
  style?: React.CSSProperties;
}

function UploaderAvatar(props: UploaderAvatarProps) {
  const { avatar, onChange: onChangeForm, style } = props;
  const [image, setImage] = useState<string | null>(() => {
    if (avatar) {
      return avatar;
    }
    return null;
  });
  const {
    isOpen,
    actions: { onClose, onOpen },
  } = useModalState();

  const [imageSrc, setImageSrc] = React.useState('');

  const { inputRef, onChange } = useUploadFile({
    cb: (f, base64) => {
      setImageSrc(base64[0]);
      onOpen();
    },
  });

  return (
    <>
      <div 
        className="uploader-avatar"
        style={style}
      >
        <input
          onChange={onChange}
          ref={inputRef}
          type="file"
          accept=".png, .jpg, .jpeg, .webp"
          id="uploader"
          className="uploader-avatar__input"
        />
        {typeof image === 'string' ? (
          <img src={image} alt="avatar" width={176} height={176} />
        ) : (
          <img src={EmptyAvatarSVG} alt="avatar" width={176} height={176} />
        )}
        <label htmlFor="uploader" className="uploader-avatar__label" />
      </div>

      <ImageCropperModal
        imageSrc={imageSrc}
        open={isOpen}
        onClose={onClose}
        onCompleteCrop={(img) => {
          if (img) {
            setImage(img);
            onChangeForm?.(img);
          }
        }}
      />
    </>
  );
}

export { UploaderAvatar };
