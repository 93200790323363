import type { DayOfWeek } from './types';

const deselectAllDaysOfWeek = (daysOfWeek: Array<DayOfWeek>): Array<DayOfWeek> => {
  return daysOfWeek.map((day) => ({ ...day, checked: false }));
};

const selectOnlyFirstDayOfWeek = (daysOfWeek: Array<DayOfWeek>): Array<DayOfWeek> => {
  return daysOfWeek.map((day) => {
    if (day.dayOfWeekIndex === 1) {
      return {
        ...day,
        checked: true,
      };
    }
    return {
      ...day,
      checked: false,
    };
  });
};

const selectAllDaysOfWeek = (daysOfWeek: Array<DayOfWeek>): Array<DayOfWeek> => {
  return daysOfWeek.map((day) => ({ ...day, checked: true }));
};

export const handleDaysOfWeek = {
  selectAllDaysOfWeek,
  selectOnlyFirstDayOfWeek,
  deselectAllDaysOfWeek,
};
