import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector } from '@hooks';
import { compare } from '@shared/utils/functions';
import { selectUserGroupsList, selectUserGroupStatus } from '@store/selectors/users/UserGroup';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { UserGroupModel } from '@model/users/UserGroup';

const columns: ColumnsTableType<UserGroupModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'groupCode',
    key: 'groupCode',
    ellipsis: true,
    sorter: (a, b) => compare(a.groupCode, b.groupCode),
  },
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'groupName',
    key: 'groupName',
    ellipsis: true,
    sorter: (a, b) => compare(a.groupName, b.groupName),
  },
];

function UserGroups() {
  const { t } = useTranslation();

  const listGroups = useAppSelector(selectUserGroupsList);
  const statusGroup = useAppSelector(selectUserGroupStatus);

  return (
    <BasicTablePage
      table={{
        loading: statusGroup === 'pending',
        dataSource: listGroups,
        columns,
        rowKey: (record) => record.groupsId,
        dataSourceName: t('pages_plural.User_Group'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.User_Group') }),
        csvConfig: {
          data: listGroups,
          fileName: 'groups.csv',
          headers: [
            {
              label: 'Group code',
              key: 'groupCode',
            },
            {
              label: 'Name',
              key: 'groupName',
            },
          ],
        },
      }}
    />
  );
}

export { UserGroups };
