import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExitTypeRepository } from '@repositories';
import { identifyError } from '@shared/utils/functions';

import type { CreateExitTypeDto, ExitTypeModel, FilterExitTypeDto, UpdateExitTypeDto } from '@model/properties/ExitType';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getExitTypes = createAsyncThunk<ExitTypeModel[], void, { rejectValue: string }>(
  'ExitType/getExitTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ExitTypeRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getExitTypesByFilter = createAsyncThunk<
  {
    exitTypes: ExitTypeModel[];
    exitTypesFilters: FilterExitTypeDto;
  },
  FilterExitTypeDto,
  { rejectValue: string }
>('ExitType/getExitTypesByFilter', async (dto, { rejectWithValue }) => {
  try {
    const response = await ExitTypeRepository.fetchAllByFilter(dto);
    return { exitTypes: response.resultObject, exitTypesFilters: dto };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getExitTypeById = createAsyncThunk<ExitTypeModel, string, { rejectValue: string }>(
  'ExitType/getExitTypeById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ExitTypeRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addExitType = createAsyncThunk<
  ExitTypeModel,
  CreateExitTypeDto,
  { rejectValue: string }
>('ExitType/addExitType', async (dto, { rejectWithValue }) => {
  try {
    const response = await ExitTypeRepository.add(dto);

    return response.resultObject;
  } catch (err) {
    const error = identifyError(err);

    return rejectWithValue(error);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateExitType = createAsyncThunk<
  { exitTypes: ExitTypeModel[]; exitType: ExitTypeModel },
  UpdateExitTypeDto,
  { rejectValue: string }
>('ExitType/updateExitType', async (dto, { rejectWithValue }) => {
  try {
    const response = await ExitTypeRepository.update(dto);
    const responseList = await ExitTypeRepository.fetchAll();

    return { exitTypes: responseList.resultObject, exitType: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeExitTypeById = createAsyncThunk<
  ExitTypeModel[],
  number[],
  { rejectValue: string }
>('ExitType/removeExitTypeById', async (ids, { rejectWithValue }) => {
  try {
    await ExitTypeRepository.removeById(ids);
    const response = await ExitTypeRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
