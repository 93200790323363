import { useContext } from 'react';

import { AssetListContext } from './AssetListContext';

export function useAssetListContext() {
  const ctx = useContext(AssetListContext);

  if (ctx === undefined || ctx === null) {
    throw new Error('useAssetListContext must be inside a Provider with a value');
  }
  return ctx;
}
