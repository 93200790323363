import { IterationValue } from '@constants/iterationValue';

import type { IterationMonthType } from '@constants/IterationMonthType';

export function getIterationTypeDto(iterationType: IterationValue, monthType: IterationMonthType) {
  switch (iterationType) {
    case IterationValue.NEVER:
      return IterationValue.NEVER;
    case IterationValue.EVERY_DAY:
      return IterationValue.EVERY_DAY;
    case IterationValue.EVERY_WEEK:
      return IterationValue.EVERY_WEEK;
    case IterationValue.EVERY_MONTH:
      return monthType;
  }
}
