import { createSlice } from '@reduxjs/toolkit';
import { getUserAlerts } from '@store/actions/extra/Alert';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AlertModel } from '@model/extra/Alert';

type AlertState = {
  alertsList: AlertModel[];
  alertStatus: LoadingStatus;
  alertError: null | string;
};

const initialState: AlertState = {
  alertsList: [],
  alertError: null,
  alertStatus: 'idle',
};

const AlertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserAlerts.pending, (state) => {
        state.alertStatus = 'pending';
        state.alertError = null;
      })
      .addCase(getUserAlerts.fulfilled, (state, { payload }) => {
        state.alertStatus = 'idle';
        state.alertsList = payload;
      })
      .addCase(getUserAlerts.rejected, (state, { payload }) => {
        state.alertStatus = 'idle';
        if (payload) {
          state.alertError = payload;
        }
      });
  },
});

const AlertReducer = AlertSlice.reducer;

export { AlertReducer };
