import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ProfileAvatar } from '@components/common';
import { useOutletProfile } from '@constants/OutletContextEntityPages';
import { phoneNumberPattern } from '@constants/regex';
import { useAppSelector } from '@hooks';
import { Input, MulticolorPicker } from '@shared/UI';
import { selectProfileLoading } from '@store/selectors/profile/Profile';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { UpdateProfileDto } from '@model/profile/Profile';

const { CaseField, Item, PreviewField, useForm, useWatch } = CommonForm;

function FormProfile(props: CommonFormProps<UpdateProfileDto>) {
  const [form] = useForm<UpdateProfileDto>();
  const { t } = useTranslation();
  const errEmailRequired = t('errors.Is_Required', { name: t('titles.Email_Address') });

  const { entity } = useOutletProfile();
  const profileStatus = useAppSelector(selectProfileLoading);

  const color = useWatch('color', form);
  
  return (
    <CommonForm
      loading={profileStatus}
      form={form}
      container={{
        style: { maxWidth: 1086 },
      }}
      initialValues={{
        firstName: entity?.firstName,
        lastName: entity?.lastName,
        phoneNumber: entity?.phoneNumber,
        userMail: entity?.userMail,
        color: entity?.color,
      }}
      {...props}
    >
      <CaseField md={24} lg={7} push={1} style={{ marginBottom: 10 }}>
        <Item
          name="profileImage"
          normalize={(value) => {
            return value;
          }}
        >
          <ProfileAvatar 
            activeUploader 
            avatar={entity.picture || null} 
            style={{
              border: `2px solid ${color}`
            }}
          />
        </Item>
      </CaseField>

      <CaseField title={t('titles.General_Information')} md={24} lg={7}>
        <Item label={t('titles.First_Name')} name="firstName">
          <Input />
        </Item>
        <Item 
          label={t('titles.Phone_Num')} 
          name="phoneNumber"
          rules={[
            {
              required: true,
            },
            {
              pattern: phoneNumberPattern,
              message: t('errors.Not_Valid', { name: t('titles.Phone_Num') }),
            },
            {
              max: 15,
            },
            {
              min: 12,
            }
          ]}
        >

          <Input />
        </Item>
      </CaseField>
      <CaseField md={24} lg={7}>
        <Item label={t('titles.Last_Name')} name="lastName">
          <Input />
        </Item>
        <Item
          label={t('titles.Email')}
          name="userMail"
          rules={[
            { required: true, message: errEmailRequired },
            {
              pattern: new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/),
              message: 'Email address is not valid ',
            },
          ]}
        >
          <Input />
      </Item>
    </CaseField>
    <CaseField md={24} lg={7} />
    <CaseField title={t('titles.Company_Info')} md={24} lg={7}>
        <Item disabledItem label={t('titles.Job_Title')}>
          <PreviewField>{entity?.userTitle?.title}</PreviewField>
        </Item>
      </CaseField>
      <CaseField md={24} lg={7}>
        <Item disabledItem label={t('titles.Role')}>
          <PreviewField>{entity?.userRoles[0]?.role?.name}</PreviewField>
        </Item>
      </CaseField>

      <CaseField md={24} lg={7} />
      <CaseField md={24} lg={7} title={t('titles.Color_Theme')}>
        <Item name="color">
          <MulticolorPicker defaultColor={entity.color} randomColor />
        </Item>
      </CaseField>
      <CaseField md={24} lg={7} />
    </CommonForm>
  );
}

export { FormProfile };
