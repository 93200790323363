import React from 'react';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { checkStoreStatuses } from '@shared/utils/functions';
import { selectAssetStatus } from '@store/selectors/management/Asset';
import { selectAssetPartsStatuses } from '@store/selectors/management/AssetParts';

import { PartsTable } from './PartsTable';

import type { CommonPreviewProps } from '@components/common';
import type { AssetModel } from '@model/management/Asset';

import './AssetParts.scss';

export function PreviewFormAssetParts(props: CommonPreviewProps<AssetModel>) {
  const { entity } = props;

  const assetStatus = useAppSelector(selectAssetStatus);
  const { status } = useAppSelector(selectAssetPartsStatuses);

  const isLoading = checkStoreStatuses([status, assetStatus]);

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={isLoading}
      container={{
        style: {
          maxWidth: '100%',
        },
        className: 'asset-parts-preview-form-container',
      }}
      header={{
        title: entity.barcode,
        hiddenDeleteButton: true,
      }}
      className="asset-parts-preview-form"
    >
      <PartsTable />
    </CommonForm>
  );
}
