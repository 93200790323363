import {createAsyncThunk} from '@reduxjs/toolkit';
import {CurrencyRepository} from '@repositories';

import type {CurrencyModel} from '@model/extra/Currency';
import type {ApiError} from '@services/Axios';

// region --- Get Actions

export const getCurrencies = createAsyncThunk<CurrencyModel[], void, { rejectValue: string }>(
  'Currency/getCurrencies',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CurrencyRepository.fetchCurrencies();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions
