import { defaultFormatDate } from '@constants/Date';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import type { RootState } from '@store/rootReducer';

export const selectCountPlanState = (state: RootState) => state.CountPlanReducer;

export const selectCountPlansList = (state: RootState) =>
  selectCountPlanState(state).countPlansList;

export const selectCountPlansListWithFormattedDate = createSelector(
  selectCountPlansList,
  (countPlansList) =>
    countPlansList.map((countPlan) => ({
      ...countPlan,
      endTime: moment(countPlan.endTime).format(defaultFormatDate),
      startTime: moment(countPlan.startTime).format(defaultFormatDate),
    }))
);

export const selectCountPlan = (state: RootState) => selectCountPlanState(state).countPlan;

export const selectCountPlanWithFormatDate = createSelector(selectCountPlan, (countPlan) => {
  if (!countPlan) return null;
  return {
    ...countPlan,
    endTime: moment(countPlan.endTime).format(defaultFormatDate),
    startTime: moment(countPlan.startTime).format(defaultFormatDate),
  };
});

export const selectCountPlanStatus = (state: RootState) =>
  selectCountPlanState(state).countPlanStatus;

export const selectCountPlanError = (state: RootState) =>
  selectCountPlanState(state).countPlanError;

export const selectCountPlanResultsList = (state: RootState) =>
  selectCountPlanState(state).countPlanResultsList;

export const selectCountPlanResultSettings = (state: RootState) =>
  selectCountPlanState(state).countPlanResultSettings;

export const selectCountPlanResultFilterOptions = (state: RootState) =>
  selectCountPlanState(state).filterOptions;
