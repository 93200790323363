import React, { memo } from 'react';

import { DataFilter } from '@components/common/DataFilter';
import { Toolbar } from '@shared/UI';

import { LeftItems } from './LeftItems';

import type { DataFilterProps } from '@components/common/DataFilter';

import './PaIToolbar.scss';

type ToolbarProps = {
  filterProps: DataFilterProps;
};

export const PaIToolbar = memo((props: ToolbarProps) => {
  const { filterProps } = props;

  const items = [
    {
      label: <LeftItems />,
      key: 'left-items',
    },
    {
      label: <DataFilter {...filterProps} />,
      key: 'filter',
    },
  ];

  return <Toolbar items={items} className="pai-toolbar" />;
});
