import { createSlice } from '@reduxjs/toolkit';
import { getCities, getCountries } from '@store/actions/extra/Definition';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { CityModel, CountryModel } from '@model/extra/Definition';

type DefinitionState = {
  citiesList: CityModel[];
  countriesList: CountryModel[];
  definitionStatus: LoadingStatus;
  error: null | string;
};

const initialState: DefinitionState = {
  citiesList: [],
  countriesList: [],
  error: null,
  definitionStatus: 'idle',
};

const DefinitionSlice = createSlice({
  name: 'definition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCities.pending, (state) => {
        state.definitionStatus = 'pending';
        state.error = null;
      })
      .addCase(getCities.fulfilled, (state, { payload }) => {
        state.definitionStatus = 'idle';
        state.citiesList = payload;
      })
      .addCase(getCities.rejected, (state) => {
        state.definitionStatus = 'idle';
      });
    builder
      .addCase(getCountries.pending, (state) => {
        state.definitionStatus = 'pending';
        state.error = null;
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        state.definitionStatus = 'idle';
        state.countriesList = payload;
      })
      .addCase(getCountries.rejected, (state) => {
        state.definitionStatus = 'idle';
      });
  },
});

const DefinitionReducer = DefinitionSlice.reducer;

export { DefinitionReducer };
