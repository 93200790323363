import React from 'react';

const CompanyIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 22.5L6.17301 15.0382C6.08509 14.9262 5.99809 14.8135 5.91201 14.7C4.83124 13.2763 4.24739 11.5374 4.25001 9.75C4.25001 7.56196 5.1192 5.46354 6.66638 3.91637C8.21355 2.36919 10.312 1.5 12.5 1.5C14.688 1.5 16.7865 2.36919 18.3336 3.91637C19.8808 5.46354 20.75 7.56196 20.75 9.75C20.7526 11.5366 20.169 13.2747 19.0888 14.6978L19.088 14.7C19.088 14.7 18.863 14.9955 18.8293 15.0353L12.5 22.5ZM7.10901 13.7963C7.11051 13.7963 7.28451 14.0272 7.32426 14.0767L12.5 20.181L17.6825 14.0685C17.7155 14.0272 17.891 13.7948 17.8918 13.794C18.7746 12.6308 19.2517 11.2103 19.25 9.75C19.25 7.95979 18.5388 6.2429 17.273 4.97703C16.0071 3.71116 14.2902 3 12.5 3C10.7098 3 8.99291 3.71116 7.72704 4.97703C6.46117 6.2429 5.75001 7.95979 5.75001 9.75C5.74844 11.2112 6.22609 12.6326 7.10976 13.7963H7.10901Z"
      fill="white"
    />
    <path
      d="M16.25 13.5H14.75V7.5H10.25V13.5H8.75V7.5C8.7504 7.1023 8.90856 6.721 9.18978 6.43978C9.471 6.15856 9.8523 6.0004 10.25 6H14.75C15.1477 6.0004 15.529 6.15856 15.8102 6.43978C16.0914 6.721 16.2496 7.1023 16.25 7.5V13.5Z"
      fill="white"
    />
    <path d="M11.75 12H13.25V13.5H11.75V12Z" fill="white" />
    <path d="M11.75 9H13.25V10.5H11.75V9Z" fill="white" />
  </svg>
);

export { CompanyIcon };
