import React, { memo } from 'react';

import { Toolbar } from '@shared/UI';

import { LeftItems } from './LeftItems';

import type { ToolbarItem } from '@shared/UI';

import './MaintenanceToolbar.scss';

export const MaintenanceToolbar = memo(() => {
  const items: ToolbarItem[] = [
    {
      label: <LeftItems />,
      key: 'left-items',
    },
  ];

  return <Toolbar items={items} className="maintenance-toolbar" />;
});
