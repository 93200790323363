/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import {Radio} from '@shared/UI';

import {useCheckStepContext} from '../../_utils';
import {MultipleVariant} from './MultipleVariant';

import type {RadioChangeEvent} from 'antd';
import type {CheckStepItemOption} from '../../_utils';

import styles from './MultipleChoice.module.scss';

export const MultipleChoice = () => {
  const { step, actions, isActiveWorkStep } = useCheckStepContext();

  const options = step.optionsList.map((option) => {
    return <MultipleVariant key={option.uuid} option={option} />;
  });

  const value = step.optionsList.reduce((acc: CheckStepItemOption | null, option) => {
    if (option.checked && option.value.length) {
      return option;
    }
    return acc;
  }, null);

  const handleValueChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    actions?.onChangeResultOptionValue?.(step, value);
  };

  return (
    <div>
      <Radio.Group
        disabled={!isActiveWorkStep}
        className={styles.radioGroup}
        value={value}
        onChange={handleValueChange}
      >
        {options}
      </Radio.Group>
    </div>
  );
};
