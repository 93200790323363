import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormRole } from '@components/views/Forms/users/Role';
import { useOutletRole } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { normalizeRoleFunctionNames } from '@shared/utils/functions';
import { updateRole } from '@store/actions/user/Role';

import type { CreateRoleFormDto } from '@model/users/Role';

function EditRole() {
  const { initialValues, entity } = useOutletRole();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (dto: CreateRoleFormDto) => {
    const functionList = normalizeRoleFunctionNames(dto.functionList);
    dispatch(updateRole({ ...dto, roleId: entity.roleId, functionList }))
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.role.name);
        navigate(paths.roles.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <FormRole
      initialValues={initialValues}
      onFinish={onFinish}
      header={{
        title: entity.name,
      }}
    />
  );
}

export default EditRole;
