import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectAsAtCardStatus } from '@store/selectors/properties/AsAt/Card';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { AsAtCardModel } from '@model/properties/AsAt/Card';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormAssetName(props: CommonPreviewProps<AsAtCardModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const assetNameStatus = useAppSelector(selectAsAtCardStatus);

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={assetNameStatus === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.nonCurrAssetCardCode}</PreviewField>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Category')}>
          <PreviewField>{entity.nonCurrAssetCardCategory.name}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormAssetName };
