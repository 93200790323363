import { FormContract } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletContract } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { formatMomentDate } from '@shared/utils/functions';
import { addContractFiles, updateContract } from '@store/actions/properties/Contract';

import type { FileWithID } from '@interfaces/interfaces';
import type { CreateContractDto } from '@model/properties/Contract';

function Contract() {
  const { initialValues, entity } = useOutletContract();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { contractAccess } = useGetAccess();

  const onFinish = async (dto: CreateContractDto & { files?: FileWithID[], cent?: number }) => {
    const { files, startDate, endDate, price, cent, ...restDto } = dto;
    const normalizeDate = {
      ...restDto,
      startDate: formatMomentDate(startDate),
      endDate: formatMomentDate(endDate),
      price: cent ? +`${price}.${cent}` : price,
    };

    const originalFiles = files?.map((file) => file.file);

    try {
      const { contract } = await dispatch(
        updateContract({ ...normalizeDate, contractId: entity.contractId })
      ).unwrap();

      try {
        await dispatch(
          addContractFiles({
            contractId: entity.contractId,
            files: originalFiles,
            isEdit: true,
          })
        );
      } catch (error) {
        ApiNotifications.error(error);
      }

      ApiNotifications.update(contract.name);
      navigate(paths.contract.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={contractAccess.edit} redirectTo={paths.contract.default}>
      <FormContract
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default Contract;
