import React, { memo } from 'react';

import { ListViewType } from '@constants/listViewType';
import { useAppDispatch, useAppSelector } from '@hooks';
import { getAssets } from '@store/actions/management/Asset';
import {
  selectAssetSettings,
  selectAssetsList,
  selectAssetStatus,
  selectAssetViewType,
} from '@store/selectors/management/Asset';

import { AssetListProvider, useAssetListContext } from './AssetListProvider';
import { Empty } from './Empty';
import { ListHeader } from './ListHeader';
import { NotFoundByFilter } from './NotFoundByFilter';
import { ViewCard } from './ViewCard';
import { ViewTable } from './ViewTable';

import './AssetView.scss';

const AssetsViewComponent = () => {
  // region --- State selectors

  const dispatch = useAppDispatch();

  const listAsset = useAppSelector(selectAssetsList);

  const statusAsset = useAppSelector(selectAssetStatus);

  const settingsAsset = useAppSelector(selectAssetSettings);

  // endregion --- State selectors

  const viewType = useAppSelector(selectAssetViewType);

  const { hasFilterValue, page, perPage, labels, searchKeyword } = useAssetListContext();

  const getAssetsByPage = (page: number) => {
    dispatch(getAssets({ page }));
  };

  const pagination = {
    total: settingsAsset.totalRecords,
    current: page,
    pageSize: perPage,
  };

  const viewConfig = {
    dataSource: listAsset,
    pagination: pagination,
    getAssetsByPage: getAssetsByPage,
    loading: statusAsset === 'pending',
  };

  const isEmpty = listAsset.length === 0;

  const isNotFound = isEmpty && (
    hasFilterValue || searchKeyword?.length || labels?.length
  );

  const renderComponent = () => {
    if (isNotFound) {
      return <NotFoundByFilter />;
    }

    if (isEmpty) {
      return <Empty />;
    }
    if (viewType === ListViewType.List) {
      return <ViewTable {...viewConfig} />
    }
    return <ViewCard {...viewConfig} />
  };

  return <div className="asset-view">
    <ListHeader 
      disabledSwitcher={isEmpty}
      disabledDropdown={isEmpty}
      disabledFilter={isEmpty && !hasFilterValue}
      disabledSearch={isEmpty && !searchKeyword?.length}
      disabledLabelFilter={isEmpty && !labels?.length}
    />
    {renderComponent()}
  </div>;
};

export const AssetsView = memo(() => {
  return (
    <AssetListProvider>
      <AssetsViewComponent />
    </AssetListProvider>
  );
});
