import React, { memo } from 'react';

import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import cl from 'classnames';

import type { SpinProps } from 'antd';

import './Loader.scss';

export type LoaderProps = {
  sizeIcon?: number;
  middle?: boolean;
  className?: string | string[];
  color?: 'primary';
} & SpinProps;

const Loader = (props: LoaderProps) => {
  const { style, color, sizeIcon = 40, indicator, middle, className, ...rest } = props;

  const middleClass = cl(
    'custom-loader',
    {
      ['loader-position-middle']: middle,
    },
    className
  );

  const colorLoader = color === 'primary' ? 'var(--main-blue)' : 'var(--main-default)';

  return (
    <Spin
      className={middleClass}
      style={{
        color: colorLoader,
        ...style,
      }}
      indicator={
        indicator ? indicator : <Loading3QuartersOutlined style={{ fontSize: sizeIcon }} spin />
      }
      {...rest}
    />
  );
};

export default memo(Loader);
