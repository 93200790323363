/* eslint-disable @typescript-eslint/no-unused-vars */
import {v4 as uuidv4} from 'uuid';

import {
  mapWorkStepResultOptionToCheckStepOptions
} from './mapWorkStepResultOptionToCheckStepOptions';

import type {
  ChecklistStepTypeKeys,
  CheckStepItem
} from '@components/common/CheckStepItem/_utils';
import type {MaintenanceResultVer2Model} from '@model/management/Maintenance';

export const mapWorkStepsResultToCheckSteps = (workSteps: MaintenanceResultVer2Model[]) => {
  return workSteps.map((step) => {
    const { workSteps } = step;

    const value = workSteps.maintenancesResults[0]
      ? workSteps.maintenancesResults[0].resultText
      : '';

    const s: CheckStepItem = {
      stepKey: workSteps.stepTypeId as ChecklistStepTypeKeys,
      hasError: false,
      isNew: false,
      isUpdate: false,
      isRemoved: false,
      title: workSteps.stepName,
      rowId: workSteps.rowId,
      value,
      uuid: uuidv4(),
      imagesList: workSteps.maintenanceImageListResults.map((img) => ({
        uuid: uuidv4(),
        file: img.imageLink,
        externalId: img.maintenanceImageListResultsId,
      })),
      optionsList: mapWorkStepResultOptionToCheckStepOptions(
        workSteps.multipleChoiceListForWorkSteps,
        workSteps.maintenancesResults
      ),
      externalId: workSteps.workStepsId,
      isCompleted: value.length > 0,
      isActive: false,
    };

    return s;
  });
};
