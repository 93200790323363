import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PreviewFormPartAndInventoryCategory } from '@components/views/Previews/properties';
import { useOutletPartAndInventoryCategory } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { removePaICategoryById } from '@store/actions/properties/PaI/Category';

import type { CommonPreviewProps } from '@components/common';

function PreviewPartAndInventoryCategory() {
  const { entity } = useOutletPartAndInventoryCategory();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onDelete = async () => {
    try {
      await dispatch(removePaICategoryById([entity.partsAndInventoriesCategoryId])).unwrap();
      ApiNotifications.remove(entity.partsAndInventoriesCategoryId);
      navigate(paths.partAndInventoryAttributes.tabs.category.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !paiAttrAccess.edit,
    },
    deleteButtonProps: {
      disabled: !paiAttrAccess.delete,
    },
  };

  return (
    <PreviewFormPartAndInventoryCategory entity={entity} onDelete={onDelete} header={headerProps} />
  );
}

export default PreviewPartAndInventoryCategory;
