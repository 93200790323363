import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@shared/UI';

import { useCheckStepContext } from '../_utils';

import type { WorkStepActionProps } from '../_utils';

import styles from './WorkStepAction.module.scss';

export const WorkStepAction = (props: WorkStepActionProps) => {
  const { isDoneWorkStep, onSave, onUpdate } = props;

  const { step } = useCheckStepContext();

  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (!isDoneWorkStep) {
      onSave?.(step);
    } else {
      onUpdate?.(step);
    }
  };

  return (
    <div className={styles.workStepAction}>
      <Button
        type="text"
        onClick={handleButtonClick}
        disabled={step.hasError}
        className={styles.workStepActionButton}
      >
        {!isDoneWorkStep ? t('titles.Save').toUpperCase() : t('titles.Update').toUpperCase()}
      </Button>
    </div>
  );
};
