import { IterationValue } from '@constants/iterationValue';

import { daysOfWeekList } from './daysOfWeekList';

import type { DayOfWeek } from '@shared/utils/types';

export function getDaysOfWeekByDto(
  daysOfWeek: Array<number>,
  repeatType: IterationValue
): Array<DayOfWeek> {
  if (repeatType === IterationValue.EVERY_DAY) {
    return daysOfWeekList.map((day) => ({ ...day, checked: true }));
  }
  return daysOfWeekList.map((day) => {
    const isExist = daysOfWeek.includes(day.dayOfWeekIndex);

    return isExist ? { ...day, checked: true } : day;
  });
}
