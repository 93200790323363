import React from 'react';
import { useTranslation } from 'react-i18next';

import type { MaintenanceModel, MaintenanceScheduleModel } from '@model/management/Maintenance';

import './WeekCell.scss';

interface WeekCellProps {
  record: MaintenanceScheduleModel & {
    title: string;
    priority: string;
    site: string | null;
    assignTo: string | null;
    maintenance: MaintenanceModel | null;
  };
}

const WeekCell = (props: WeekCellProps) => {
  const { record } = props;

  const { t } = useTranslation();

  const defClass = 'maintenance-week-cell';

  const isRouting = (nonCurrAssetId: any) => {
    if (!nonCurrAssetId)
      return <span className="routing"> {t('titles.Maintenance_Routing')} </span>;
  };

  return (
    <div className={defClass}>
      <span className={`${defClass}-title`}>
        {record.title}
        <br />
        {isRouting(record.maintenance?.nonCurrAssetId)}
      </span>

      <div className={`${defClass}-descr`}>
        <div className={`${defClass}-info`}>
          <span>{t('titles.Priority')}:</span>
          <span>{record.priority}</span>
        </div>
        <div className={`${defClass}-info`}>
          <span>{t('titles.Site')}:</span>
          <span>{record.site}</span>
        </div>
        <div className={`${defClass}-info`}>
          <span>{t('titles.Assign_To')}:</span>
          <span>{record.assignTo}</span>
        </div>
      </div>
    </div>
  );
};

export { WeekCell };
