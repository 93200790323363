import React from 'react';
import { Translation } from 'react-i18next';

import { AssetRepository } from '@repositories';
import { AsyncSelect, Select } from '@shared/UI';

import type { DataFilterProps } from '@components/common/DataFilter';
import type {
  AssetModel,
  AssetPropertyDetailModel,
  AssetSearchBarcodeResponseModel,
  FilterAssetDto,
} from '@model/management/Asset';
import type { AsAtBrandModel } from '@model/properties/AsAt/Brand';
import type { AsAtCardModel } from '@model/properties/AsAt/Card';
import type { AsAtCategoryModel } from '@model/properties/AsAt/Category';
import type { AsAtModelModel } from '@model/properties/AsAt/Model';
import type { AsAtStatusModel } from '@model/properties/AsAt/Status';
import type { DepartmentModel } from '@model/properties/Department';
import type { SiteModel } from '@model/properties/Site';
import type { UserModel } from '@model/users/User';
import type { AsyncSelectFields, AsyncSelectReturnData } from '@shared/UI';

type FilterFields = {
  allAssetRecords: Array<AssetModel>;

  assetCardsList: Array<AsAtCardModel>;

  assetCategoriesList: Array<Omit<AsAtCategoryModel, 'childNonCurrAssetCardCategories'>>;

  departmentsList: Array<Omit<DepartmentModel, 'childDepartment'>>;

  assetBrandsList: Array<AsAtBrandModel>;

  assetStatusesList: Array<AsAtStatusModel>;

  sitesList: Array<SiteModel>;

  assetModelsList: Array<AsAtModelModel>;

  usersList: Array<Omit<UserModel, 'childUser'>>;
};

export function getFilterItems(fields: FilterFields): DataFilterProps['items'] {
  const maxTagCount = 4;
  const mode = 'multiple';

  const handleAssetData = async (
    current: number,
    pageSize: number
  ): AsyncSelectReturnData<AssetSearchBarcodeResponseModel> => {
    try {
      let filter: FilterAssetDto = {
        page: current,
        perPage: pageSize,
      };

      const { resultObject, totalRecords } = await AssetRepository.fetchAll(filter);

      return {
        data: resultObject,
        total: totalRecords,
      };
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  const handlePropertyValueData = async (
    current: number,
    pageSize: number
  ): AsyncSelectReturnData<AssetPropertyDetailModel> => {
    try {
      let filter = {
        page: current,
        perPage: pageSize,
      };

      const { resultObject, totalRecords } = await AssetRepository.fetchAllPropertyValue(filter);

      return {
        data: resultObject,
        total: totalRecords,
      };
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  const handleSearchBarcode = async (query: string) => {
    try {
      const { resultObject } = await AssetRepository.searchByBarcode(query);

      return {
        data: resultObject,
        total: resultObject.length,
      };
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  const handleSearchPropertyValue = async (query: string) => {
    try {
      const { resultObject } = await AssetRepository.searchByPropertyValue(query);

      return {
        data: resultObject,
        total: resultObject.length,
      };
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  const fieldNames: AsyncSelectFields<AssetSearchBarcodeResponseModel> = {
    value: 'barcode',
    label: 'barcode',
    key: 'barcode',
  };

  const fieldPropertyValueNames: AsyncSelectFields<AssetPropertyDetailModel> = {
    value: 'value',
    label: 'value',
    key: 'value',
  };

  const serialNumbers = getSerialNumbers(fields.allAssetRecords);

  const users = getUsers(fields.usersList);

  return [
    {
      label: <Translation>{(t) => t('titles.Barcode')}</Translation>,
      name: 'barcodes',
      item: (
        <AsyncSelect
          fetchData={handleAssetData}
          searchData={handleSearchBarcode}
          fieldNames={fieldNames as any}
          maxTagCount={maxTagCount}
          mode={mode}
        />
      ),
    },
    {
      label: <Translation>{(t) => t('titles.Asset_Name')}</Translation>,
      name: 'nonCurrAssetCardsIds',
      item: (
        <Select
          options={fields.assetCardsList}
          fieldNames={{
            label: 'name',
            value: 'nonCurrAssetCardId',
          }}
          maxTagCount={maxTagCount}
          mode={mode}
        />
      ),
    },
    {
      label: <Translation>{(t) => t('titles.Category')}</Translation>,
      name: 'nonCurrAssetCardCategoriesIds',
      item: (
        <Select
          options={fields.assetCategoriesList}
          fieldNames={{
            label: 'name',
            value: 'nonCurrAssetCardCategoryId',
          }}
          maxTagCount={maxTagCount}
          mode={mode}
        />
      ),
    },
    {
      label: <Translation>{(t) => t('titles.Serial_Number')}</Translation>,
      name: 'serialNumbers',
      item: <Select options={serialNumbers} maxTagCount={maxTagCount} mode={mode} />,
    },
    {
      label: <Translation>{(t) => t('pages_single.Department')}</Translation>,
      name: 'departmentsIds',
      item: (
        <Select
          options={fields.departmentsList}
          fieldNames={{
            label: 'name',
            value: 'departmentId',
          }}
          maxTagCount={maxTagCount}
          mode={mode}
        />
      ),
    },
    {
      label: <Translation>{(t) => t('titles.Brand')}</Translation>,
      name: 'brandsIds',
      item: (
        <Select
          options={fields.assetBrandsList}
          fieldNames={{ label: 'name', value: 'nonCurrAssetCardBrandId' }}
          maxTagCount={maxTagCount}
          mode={mode}
        />
      ),
    },
    {
      label: <Translation>{(t) => t('titles.Status')}</Translation>,
      name: 'statusesIds',
      item: (
        <Select
          options={fields.assetStatusesList}
          fieldNames={{ label: 'name', value: 'nonCurrAssetStatusId' }}
          maxTagCount={maxTagCount}
          mode={mode}
        />
      ),
    },
    {
      label: <Translation>{(t) => t('titles.Site')}</Translation>,
      name: 'sitesIds',
      item: (
        <Select
          options={fields.sitesList}
          fieldNames={{ label: 'name', value: 'siteId' }}
          maxTagCount={maxTagCount}
          mode={mode}
        />
      ),
    },
    {
      label: <Translation>{(t) => t('titles.Model')}</Translation>,
      name: 'nonCurrAssetCardModelsIds',
      item: (
        <Select
          options={fields.assetModelsList}
          fieldNames={{ label: 'name', value: 'nonCurrAssetCardModelId' }}
          maxTagCount={maxTagCount}
          mode={mode}
        />
      ),
    },
    {
      label: <Translation>{(t) => t('titles.Assign_To')}</Translation>,
      name: 'usersIds',
      item: <Select options={users} maxTagCount={maxTagCount} mode={mode} />,
    },
    {
      label: <Translation>{(t) => t('titles.Property_Value')}</Translation>,
      name: 'nonCurrAssetCardPropertyValues',
      item: (
        <AsyncSelect
          fetchData={handlePropertyValueData}
          searchData={handleSearchPropertyValue}
          fieldNames={fieldPropertyValueNames as any}
          maxTagCount={maxTagCount}
          mode={mode}
        />
      ),
    },
  ];
}

// function getBarcodes(allAssetRecords: Array<AssetModel>) {
//   const hashMap = new Map<string, string>();
//
//   allAssetRecords.forEach((asset) => {
//     if (asset.barcode) {
//       hashMap.set(asset.barcode, asset.barcode);
//     }
//   });
//
//   return Array.from(hashMap).map(([, value]) => ({
//     value,
//     label: value,
//   }));
// }

function getSerialNumbers(allAssetRecords: Array<AssetModel>) {
  const hashMap = new Map<string, string>();

  allAssetRecords.forEach((asset) => {
    if (asset.serialNumber) {
      hashMap.set(asset.serialNumber, asset.serialNumber);
    }
  });

  return Array.from(hashMap).map(([, value]) => ({
    value,
    label: value,
  }));
}

function getUsers(usersList: Array<Omit<UserModel, 'childUser'>>) {
  return usersList.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.userId,
  }));
}
