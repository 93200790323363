import { createSlice } from '@reduxjs/toolkit';
import {
  addMaAtRequestReason,
  getMaAtRequestReasonById,
  getMaAtRequestReasons,
  removeMaAtRequestReasonById,
  updateMaAtRequestReason,
} from '@store/actions/properties/MaAt/RequestReason';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { MaAtRequestReasonModel } from '@model/properties/MaAt/RequestReason';

interface MaAtRequestReasonState {
  MaAtRequestReasonsList: MaAtRequestReasonModel[];
  MaAtRequestReason: MaAtRequestReasonModel | null;
  MaAtRequestReasonStatus: LoadingStatus;
  MaAtRequestReasonError: string | null;
}

const initialState: MaAtRequestReasonState = {
  MaAtRequestReasonsList: [],
  MaAtRequestReason: null,
  MaAtRequestReasonStatus: 'idle',
  MaAtRequestReasonError: null,
};

const MaAtRequestReasonSlice = createSlice({
  name: 'MaAtRequestReason',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMaAtRequestReasons.pending, (state) => {
        state.MaAtRequestReasonError = null;
        state.MaAtRequestReasonStatus = 'pending';
      })
      .addCase(getMaAtRequestReasons.fulfilled, (state, { payload }) => {
        state.MaAtRequestReasonStatus = 'idle';
        state.MaAtRequestReasonsList = payload;
      })
      .addCase(getMaAtRequestReasons.rejected, (state, { payload }) => {
        state.MaAtRequestReasonStatus = 'idle';
        if (payload) {
          state.MaAtRequestReasonError = payload;
        }
      });

    builder
      .addCase(addMaAtRequestReason.pending, (state) => {
        state.MaAtRequestReasonStatus = 'pending';
        state.MaAtRequestReasonError = null;
      })
      .addCase(addMaAtRequestReason.fulfilled, (state, { payload }) => {
        state.MaAtRequestReasonStatus = 'idle';
        state.MaAtRequestReasonsList = [payload, ...state.MaAtRequestReasonsList];
      })
      .addCase(addMaAtRequestReason.rejected, (state, { payload }) => {
        state.MaAtRequestReasonStatus = 'idle';
        if (payload) {
          state.MaAtRequestReasonError = payload;
        }
      });

    builder
      .addCase(getMaAtRequestReasonById.pending, (state) => {
        state.MaAtRequestReasonStatus = 'pending';
        state.MaAtRequestReasonError = null;
      })
      .addCase(getMaAtRequestReasonById.fulfilled, (state, { payload }) => {
        state.MaAtRequestReasonStatus = 'idle';
        state.MaAtRequestReason = payload;
      })
      .addCase(getMaAtRequestReasonById.rejected, (state, { payload }) => {
        state.MaAtRequestReasonStatus = 'idle';
        if (payload) {
          state.MaAtRequestReasonError = payload;
        }
      });

    builder
      .addCase(updateMaAtRequestReason.pending, (state) => {
        state.MaAtRequestReasonStatus = 'pending';
        state.MaAtRequestReasonError = null;
      })
      .addCase(updateMaAtRequestReason.fulfilled, (state, { payload }) => {
        state.MaAtRequestReasonStatus = 'idle';
        state.MaAtRequestReasonsList = payload.MaAtRequestReasonsList;
        state.MaAtRequestReason = payload.MaAtRequestReason;
      })
      .addCase(updateMaAtRequestReason.rejected, (state, { payload }) => {
        state.MaAtRequestReasonStatus = 'idle';
        if (payload) {
          state.MaAtRequestReasonError = payload;
        }
      });

    builder
      .addCase(removeMaAtRequestReasonById.pending, (state) => {
        state.MaAtRequestReasonStatus = 'pending';
        state.MaAtRequestReasonError = null;
      })
      .addCase(removeMaAtRequestReasonById.fulfilled, (state, { payload }) => {
        state.MaAtRequestReasonStatus = 'idle';
        state.MaAtRequestReasonsList = payload;
      })
      .addCase(removeMaAtRequestReasonById.rejected, (state, { payload }) => {
        state.MaAtRequestReasonStatus = 'idle';
        if (payload) {
          state.MaAtRequestReasonError = payload;
        }
      });
  },
});

const MaAtRequestReasonReducer = MaAtRequestReasonSlice.reducer;

export { MaAtRequestReasonReducer };
