import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import {
  selectAsAtModelsList,
  selectAsAtModelStatus,
} from '@store/selectors/properties/AsAt/Model';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { AsAtModelModel } from '@model/properties/AsAt/Model';

const columns: ColumnsTableType<AsAtModelModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'nonCurrAssetCardModelCode',
    key: 'nonCurrAssetCardCode',
    ellipsis: true,
    sorter: (a, b) => compare(a.nonCurrAssetCardModelCode, b.nonCurrAssetCardModelCode),
  },

  {
    title: <Translation>{(t) => t('titles.Brand')}</Translation>,
    dataIndex: ['nonCurrAssetCardBrand', 'name'],
    key: 'brand',
    ellipsis: true,
    sorter: (a, b) => compare(a.nonCurrAssetCardBrand.name, b.nonCurrAssetCardBrand.name),
  },
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

const AssetModels = () => {
  const { t } = useTranslation();

  const listAssetModel = useAppSelector(selectAsAtModelsList);
  const statusAssetModel = useAppSelector(selectAsAtModelStatus);

  const { assetAttrAccess } = useGetAccess();

  const csvData = useMemo(() => {
    return listAssetModel.map((model) => ({
      ...model,
      brand: model.nonCurrAssetCardBrand.name,
    }));
  }, [listAssetModel]);

  return (
    <BasicTablePage
      table={{
        loading: statusAssetModel === 'pending',
        dataSource: listAssetModel,
        columns: columns,
        rowKey: (record) => record.nonCurrAssetCardModelId,
        dataSourceName: t('pages_plural.PaI_Attr_Models'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Model') }),
        csvConfig: {
          data: csvData,
          fileName: 'asset-models.csv',
          headers: [
            {
              label: 'Code',
              key: 'nonCurrAssetCardModelCode',
            },
            {
              label: 'Brand',
              key: 'brand',
            },
            {
              label: 'Name',
              key: 'name',
            },
          ],
        },
        newButtonProps: {
          disabled: !assetAttrAccess.create,
        },
      }}
    />
  );
};

export { AssetModels };
