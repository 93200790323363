import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormUserGroup } from '@components/views/Forms/users';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addUserGroup } from '@store/actions/user/UserGroup';

import type { CreateUserGroupDto } from '@model/users/UserGroup';
import type { FormInstance } from 'antd';

function CreateUserGroup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = (dto: CreateUserGroupDto, form?: FormInstance<CreateUserGroupDto>) => {
    dispatch(addUserGroup(dto))
      .unwrap()
      .then((response) => {
        ApiNotifications.create(response.createdUserGroup.groupName);
        form?.resetFields();
        navigate(paths.usergroups.default);
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <FormUserGroup
      onFinish={onFinish}
      header={{
        title: t('titles.New_Item', { name: t('pages_single.User_Group') }),
      }}
    />
  );
}

export default CreateUserGroup;
