/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {useTranslation} from 'react-i18next';

import {CommonForm, useCheckStep} from '@components/common';
import {useAppDispatch} from '@hooks';
import {ApiNotifications} from '@services/Notifications/adapters';
import {Button, Label} from '@shared/UI';
import {removeWorkStepImage} from '@store/actions/management/Maintenance';

import {WorkSteps} from './WorkSteps';

import type {DefaultCommonFormProps} from '@components/common';
import type {
  CreateMaintenanceResultDto,
  MaintenanceResultVer2Model,
  MaintenanceV2Model,
  UpdateMaintenanceResultDto,
} from '@model/management/Maintenance';

interface FormMaintenanceResultProps {
  maintenanceResult: {
    result: MaintenanceResultVer2Model[];
    executeStatusId: number;
    resultType: number;
  };
  maintenance: MaintenanceV2Model;
  onSubmit: (value: CreateMaintenanceResultDto) => void;
  onComplete: (dto: CreateMaintenanceResultDto[]) => void;
  onUpdate: (value: UpdateMaintenanceResultDto) => void;
  userId: number;
  scheduleId: number;
  loading?: boolean;
  onClick?: () => void;
}

const { PreviewField, CaseField, Item } = CommonForm;

const FormMaintenanceResult = (props: FormMaintenanceResultProps) => {
  const {
    maintenanceResult,
    maintenance,
    loading,
    userId,
    scheduleId,
    onSubmit: onSubmitProps,
    onClick,
    onComplete,
    onUpdate,
  } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { executeStatusId, result } = maintenanceResult;

  const statusColors = {
    open: '#DDEEFF',
    inProgress: '#D6FFAD',
    complete: '#FBE3D1',
  };

  const formContainer: DefaultCommonFormProps['container'] = {
    style: {
      maxWidth: 1200,
    },
  };

  const removeImage = async (id: number) => {
    dispatch(removeWorkStepImage([id]));
  };

  const { steps, actions, workStepResultApi, isCompleteSteps } = useCheckStep({
    type: 'maintenanceResult',
    maintenanceId: maintenance.maintenanceId,
    userId: userId,
    initialSteps: maintenanceResult.result,
    executeStatusId,
    scheduleId,
    removeImageCb: removeImage,
  });

  const handleComplete = () => {
    const isValid = steps.map((step) => workStepResultApi.validateWorkStepResult(step));

    const res = workStepResultApi.mapCheckStepsToWorkStepResults();

    if (!isCompleteSteps() || isValid.includes(false)) {
      ApiNotifications.info(t('errors.All_Steps_Must_Completed'));
      return;
    }

    console.log(res);

    if (res) {
      onComplete(res);
    }
  };

  const formHeader: DefaultCommonFormProps['header'] = {
    title: maintenance.title,
    extra: [
      <Button
        disabled={executeStatusId === 3}
        loading={loading}
        key={1}
        type="primary"
        onClick={maintenanceResult.executeStatusId === 1 ? onClick : handleComplete}
      >
        {executeStatusId === 1 ? t('titles.Start') : t('titles.Complete')}
      </Button>,
    ],
    tags: <Label color={getStatusColor()}>{labelText()}</Label>,
  };

  function getStatusColor() {
    if (executeStatusId === 1) {
      return statusColors.open;
    }
    if (executeStatusId === 2) {
      return statusColors.inProgress;
    }
    if (executeStatusId === 3) {
      return statusColors.complete;
    }
  }

  function labelText() {
    switch (executeStatusId) {
      case 1:
        return t('titles.Open');
      case 2:
        return t('titles.In_Progress');
      case 3:
        return t('titles.Complete');
      default:
        return null;
    }
  }

  return (
    <CommonForm loading={loading} showBackIcon header={formHeader} container={formContainer}>
      <CaseField md={8}>
        <Item label={t('titles.Title')}>
          <PreviewField>{maintenance.title}</PreviewField>
        </Item>
        <Item label={t('titles.Category')}>
          <PreviewField>{maintenance.maintinanceCategories?.categoryName}</PreviewField>
        </Item>
      </CaseField>
      <CaseField md={8}>
        <Item label={t('titles.Site')}>
          <PreviewField>{maintenance.site?.name}</PreviewField>
        </Item>
        <Item label={t('titles.Priority')}>
          <PreviewField>{maintenance.maintenancePriority.priorityName}</PreviewField>
        </Item>
      </CaseField>
      <CaseField md={4}>
        <Item label={t('titles.Estimated_Duration')}>
          <PreviewField inputTextCenter>{maintenance.estimatedDuration}</PreviewField>
        </Item>
      </CaseField>
      <CaseField hiddenTitle md={24} lg={24} xl={24} xxl={24}>
        <WorkSteps
          initialWorkSteps={maintenanceResult.result}
          executeStatusId={executeStatusId}
          onSave={onSubmitProps}
          onUpdate={onUpdate}
          actions={actions}
          chestStepsToMaintenanceResult={workStepResultApi.mapCheckStepToWorkStepResult}
          validateWorkStepResult={workStepResultApi.validateWorkStepResult}
          steps={steps}
        />
      </CaseField>
    </CommonForm>
  );
};

export { FormMaintenanceResult };
