import type { RootState } from '@store/rootReducer';

export const selectPaIState = (state: RootState) => state.PaIReducer;

export const selectPaIsList = (state: RootState) => selectPaIState(state).listPartAndInventory;

export const selectPaIsTableList = (state: RootState) => 
  selectPaIState(state).listPartAndInventory.map( obj => ({
    quantity: obj.quantity,
    statusName: obj.status.name,
    purchaseNumber: obj.purchaseNumber,
    partsAndInventoriesId: obj.partsAndInventoriesId,
    partsAndInventoriesSiteName: obj.partsAndInventoriesSite.name,
    partsAndInventoriesCardName: obj.partsAndInventoriesCard.partsAndInventoriesCardName,
    partsAndInventoriesUnitName: obj.partsAndInventoriesCard.partsAndInventoriesUnit.partsAndInventoriesUnitName,
    partsAndInventoriesCategoryName: obj.partsAndInventoriesCard.partsAndInventoriesCategory.partsAndInventoriesCategoryName,
    labels: obj.labels,
  })
);

export const selectPaIsTypeNameList = (state: RootState) =>
  selectPaIState(state).listPartAndInventoryTypeName;

export const selectPaIsTypeNameListAllRecords = (state: RootState) =>
  selectPaIState(state).listPartAndInventoryTypeNameAllRecords;

export const selectPaIsTypePOListAllRecords = (state: RootState) =>
  selectPaIState(state).listPartAndInventoryTypePOAllRecords;

export const selectPaIStatus = (state: RootState) => selectPaIState(state).statusPartAndInventory;

export const selectPaIError = (state: RootState) => selectPaIState(state).errorPartAndInventory;

export const selectPaIRecordNotFound = (state: RootState) => selectPaIState(state).recordNotFound;

export const selectPaI = (state: RootState) => selectPaIState(state).particularPartAndInventory;

export const selectPaIFilterOptions = (state: RootState) => selectPaIState(state).filterOptions;

export const selectPaISettings = (state: RootState) =>
  selectPaIState(state).settingsPartAndInventory;

export const selectPaIConsumeName = (state: RootState) => selectPaIState(state).consumeNAME;

export const selectPaIConsumePO = (state: RootState) => selectPaIState(state).consumePO;
