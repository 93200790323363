import { createAsyncThunk } from '@reduxjs/toolkit';
import { CountPlanRepository } from '@repositories';

import type { AssetModel } from '@model/management/Asset';
import type {
  CountPlanModel,
  CountPlanResultModel,
  CreateCountPlanDto,
  FilterCountPlanDto,
  UpdateCountPlanDto,
  UpdateCountPlanResultDto,
} from '@model/management/CountPlan';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer';

// region --- Get Actions

export const getCountPlans = createAsyncThunk(
  'CountPlan/getCountPlans',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CountPlanRepository.fetchAll();
      return response.resultObject;
    } catch (err: any) {
      return rejectWithValue(err.data);
    }
  }
);

export const getCountPlanById = createAsyncThunk<CountPlanModel, string, { rejectValue: string }>(
  'CountPlan/getCountPlanById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await CountPlanRepository.fetchById(id);

      return { ...response.resultObject };
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getCountPlanResults = createAsyncThunk<
  {
    countPlanResults: CountPlanResultModel[] | AssetModel[];
    countPlanResultSettings: {
      totalPage: number;
      totalRecords: number;
    };
    options: Partial<FilterCountPlanDto> | null;
  },
  Partial<FilterCountPlanDto> | void,
  {
    state: RootState;
    rejectValue: string;
  }
>('CountPlan/getCountPlanResults', async (filterOptions, { rejectWithValue, getState }) => {
  try {
    const stateFilterOptions = getState().CountPlanReducer.filterOptions;

    const response = await CountPlanRepository.fetchAllCountPlanResults({
      ...stateFilterOptions,
      ...filterOptions,
    });

    return {
      countPlanResults: response.resultObject,
      countPlanResultSettings: {
        totalPage: response.totalPage,
        totalRecords: response.totalRecords,
      },
      options: filterOptions || null,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addCountPlan = createAsyncThunk<
  CountPlanModel,
  CreateCountPlanDto,
  { rejectValue: string }
>('CountPlan/addCountPlan', async (dto, { rejectWithValue }) => {
  try {
    const response = await CountPlanRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (error.data) {
      return rejectWithValue(error.data.languageKeyword);
    }
    if (err.response && err.response.status === 400 && err.response.data.languageKeyword) {
      return rejectWithValue(err.response.data.languageKeyword);
    }

    throw err;
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateCountPlan = createAsyncThunk<
  { countPlansList: CountPlanModel[]; countPlan: CountPlanModel },
  UpdateCountPlanDto,
  { rejectValue: string }
>('CountPlan/updateCountPlan', async (dto, { rejectWithValue }) => {
  try {
    const response = await CountPlanRepository.update(dto);

    const responseList = await CountPlanRepository.fetchAll();

    return {
      countPlansList: responseList.resultObject,
      countPlan: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const updateCountPlanResult = createAsyncThunk<
  {},
  UpdateCountPlanResultDto,
  { rejectValue: string }
>('CountPlan/updateCountPlanResult', async (dto, { rejectWithValue }) => {
  try {
    const response = await CountPlanRepository.updateCountPlanResult(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeCountPlanById = createAsyncThunk<
  CountPlanModel[],
  number[],
  { rejectValue: string }
>('CountPlan/removeCountPlanById', async (ids, { rejectWithValue }) => {
  try {
    await CountPlanRepository.removeById(ids);
    const response = await CountPlanRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
