import React from 'react';
import { Outlet } from 'react-router-dom';

import { useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';

const EntryDashboard = () => {
  useSetPageInfo({
    defaultPagePath: paths.dashboard.default,
    pageTitle: 'pages_single.Dashboard',
  });

  return <Outlet />;
};

export default EntryDashboard;
