export const CSVHeaders = [
  { label: 'Barcode', key: 'barcode' },
  { label: 'Category', key: 'category' },
  { label: 'Vendor', key: 'vendor' },
  { label: 'Status', key: 'status' },
  { label: 'Serial Number', key: 'serialNumber' },
  { label: 'Brand', key: 'assetBrand' },
  { label: 'Site', key: 'site' },
  { label: 'Card', key: 'assetCard' },
  { label: 'Model', key: 'assetModel' },
  { label: 'Invoice Date', key: 'invoiceDate' },
  { label: 'Invoice Number', key: 'invoiceNumber' },
  { label: 'Contracts', key: 'contracts' },
  { label: 'Invoice Amount', key: 'invoiceAmount' },
  { label: 'Cost Center', key: 'costCenter' },
  { label: 'Warranty Start Date', key: 'warrantyStartDate' },
  { label: 'Warranty End Date', key: 'warrantyEndDate' },
  { label: 'User', key: 'user' },
];
