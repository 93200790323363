import React from 'react';

import { Radio } from '@shared/UI';
import cl from 'classnames';

import { useCheckStepContext } from '../../../_utils';

import type { CheckStepItemOption } from '../../../_utils';

import styles from './MultipleVariant.module.scss';

type MultipleVariantProps = {
  option: CheckStepItemOption;
};

export const MultipleVariant = (props: MultipleVariantProps) => {
  const { option } = props;

  const { step } = useCheckStepContext();

  const classes = cl(styles.multipleVariant, {
    [styles.multipleVariantActive]: step.isActive,
  });

  return (
    <div className={classes}>
      <Radio name={option.uuid} value={option}>
        <span>{option.title}</span>
      </Radio>
    </div>
  );
};
