import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getVendors } from '@store/actions/properties/Vendor';
import { selectVendorsList } from '@store/selectors/properties/Vendor';

const EntryVendor = () => {
  useSetPageInfo({
    pageTitle: 'pages_single.Vendor',
    defaultPagePath: paths.vendor.default,
  });

  const vendors = useAppSelector(selectVendorsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!vendors.length) {
      dispatch(getVendors());
    }
  }, []);

  return <Outlet />;
};

export default EntryVendor;
