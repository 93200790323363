import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppDispatch } from '@hooks';
import { notificationController } from '@services/Notifications';
import { ApiNotifications } from '@services/Notifications/adapters';
import { BaseModal } from '@shared/UI';
import { batchUpdateMaintenances } from '@store/actions/management/Maintenance';

import { getBatchMaintenanceDTO } from '../../../../_utils';
import { useMaintenanceContext } from '../../../../MaintenanceContext';

import type { BatchUpdateMaintenancesDto } from '@model/management/Maintenance';

import './AssigneeModal.scss';

interface AssigneeModalProps {
  isOpen: boolean;

  onClose: () => void;

  assigneeID: number | null;
}

export const AssigneeModal = memo((props: AssigneeModalProps) => {
  const { isOpen, onClose, assigneeID } = props;

  const { toolbarDescription, checkedMaintenances, clearChecked } = useMaintenanceContext();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleOk = async () => {
    const DTO: BatchUpdateMaintenancesDto = getBatchMaintenanceDTO(checkedMaintenances, {
      assigneeUserId: assigneeID,
    });

    try {
      onClose();
      await dispatch(batchUpdateMaintenances(DTO)).unwrap();
      clearChecked();

      notificationController.success({
        description: t('notifications.Changed', { name: t('titles.Assignee') }),
      }); 
    } catch (e) {
      ApiNotifications.error(e);
    } finally {
    }
  };

  return (
    <BaseModal
      centered
      className="assignee-change-confirm"
      open={isOpen}
      onCancel={onClose}
      onOk={handleOk}
      title={t('titles.Change_With_Name', { name: t('titles.Assignee') })}
      okText={t('titles.Confirm')}
      size="small"
      closable={false}
    >
      <span>
        <Trans i18nKey="common.Modal_Change_Assignee">
          Do you want to change an assignee for
          <span className="assignee-change-confirm-name">{{ name: toolbarDescription }}</span>?
        </Trans>
      </span>
    </BaseModal>
  );
});
