/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { defaultFormatDate } from '@constants/Date';
import { REQUIRED_RULE } from '@constants/formRules';
import { Col, DatePicker, Row, Select } from '@shared/UI';

import type { AsAtBrandModel } from '@model/properties/AsAt/Brand';
import type { AsAtCardModel } from '@model/properties/AsAt/Card';
import type { AsAtModelModel } from '@model/properties/AsAt/Model';
import type { ItemRules, SelectFieldNames } from '@shared/utils/types';

import './RightSection.scss';

interface RightSectionProps {
  assetCardsList: Array<AsAtCardModel>;

  assetBrandList: Array<AsAtBrandModel>;

  onAssetCardOpenModal: () => void;

  assetModelsList: Array<AsAtModelModel>;

  onAssetModelOpenModal: () => void;
}

const { Item, CaseField, useWatch } = CommonForm;

// region --- Item rules

const assetCardRules: ItemRules = [REQUIRED_RULE];

const invoiceDateRules: ItemRules = [REQUIRED_RULE];

// endregion --- Item rules

// region --- Field Names

const assetCardNames: SelectFieldNames<AsAtCardModel> = {
  label: 'name',
  value: 'nonCurrAssetCardId',
};

const assetModelNames: SelectFieldNames<AsAtModelModel> = {
  label: 'name',
  value: 'nonCurrAssetCardModelId',
};

// endregion --- Field Names
export const RightSection = memo((props: RightSectionProps) => {
  const { assetCardsList, assetModelsList, onAssetCardOpenModal, onAssetModelOpenModal } = props;

  const { t } = useTranslation();

  const brandId = useWatch<number | null>('nonCurrAssetCardBrandId');

  const assetCategoryId = useWatch<number | null>('nonCurrAssetCardCategoryId');

  const modelOptions = assetModelsList.filter((model) => {
    if (!brandId) return false;

    return model.nonCurrAssetCardBrandId === brandId;
  });

  const assetCardOption = assetCardsList.filter((card) => {
    if (!assetCategoryId) return false;

    return card.nonCurrAssetCardCategoryId === assetCategoryId;
  });

  const notFoundModelText = !brandId
    ? t('titles.Please_Choose_Before', { name: t('titles.Brand') })
    : t('titles.Not_Found');

  const notFoundCardText = !assetCategoryId
    ? t('titles.Please_Choose_Before', { name: t('pages_single.Asset_Attr_Category') })
    : t('titles.Not_Found');

  return (
    <CaseField md={24} lg={7}>
      <Item label={t('titles.Asset_Card')} name="nonCurrAssetCardId" rules={assetCardRules}>
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Asset_Card') })}
          options={assetCardOption}
          fieldNames={assetCardNames}
          allowClear={false}
          isCreatable
          creatableAction={onAssetCardOpenModal}
          notFoundContent={notFoundCardText}
        />
      </Item>
      <Item label={t('titles.Model')} name="nonCurrAssetCardModelId">
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Model') })}
          options={modelOptions}
          fieldNames={assetModelNames}
          isCreatable
          creatableAction={onAssetModelOpenModal}
          notFoundContent={notFoundModelText}
        />
      </Item>

      <Row>
        <Col lg={12} md={24} xs={24}>
          <Item label={t('titles.Invoice_Date')} name="invoiceDate" rules={invoiceDateRules}>
            <DatePicker
              placeholder={defaultFormatDate}
              textAlign="center"
              allowClear={false}
            />
          </Item>
        </Col>
      </Row>
    </CaseField>
  );
});
