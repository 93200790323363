import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, SelectUser } from '@components/common';
import { baseFormatDate, defaultFormatDate } from '@constants/Date';
import { useAppDispatch, useAppSelector } from '@hooks';
import { DatePicker, Input, Select, TextArea } from '@shared/UI';
import { getSites } from '@store/actions/properties/Site';
import { selectCountPlanStatus } from '@store/selectors/management/CountPlan';
import { selectSitesListWithoutChildren, selectSiteStatus } from '@store/selectors/properties/Site';
import { Col, Row } from 'antd';
import { format } from 'date-fns';
import moment from 'moment';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateCountPlanDto } from '@model/management/CountPlan';

const { CaseField, Item, useForm } = CommonForm;

function FormCountPlan(props: CommonFormProps<CreateCountPlanDto>) {
  const { initialValues, ...rest } = props;

  const [form] = useForm<CreateCountPlanDto>();

  const { t } = useTranslation();

  const countPlanStatus = useAppSelector(selectCountPlanStatus);

  const siteStatus = useAppSelector(selectSiteStatus);
  const siteList = useAppSelector(selectSitesListWithoutChildren);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!siteList.length) {
      dispatch(getSites());
    }
  }, []);

  const siteOptions = useMemo(
    () =>
      siteList.map((site) => ({
        value: site.siteId,
        label: site.name,
      })),
    [siteList]
  );

  return (
    <CommonForm
      form={form}
      loading={countPlanStatus === 'pending'}
      header={{
        title: initialValues?.taskName,
        subTitle: t('titles.Date_Completed', {
          date: initialValues?.endTime
            ? format(new Date(initialValues.endTime), baseFormatDate)
            : '',
        }),
      }}
      initialValues={{
        ...initialValues,
        startTime: initialValues?.startTime ? (moment(initialValues.startTime) as any) : undefined,
        endTime: initialValues?.endTime ? (moment(initialValues.endTime) as any) : undefined,
      }}
      {...rest}
    >
      <CaseField>
        <Item
          label={t('titles.Plan_No')}
          name="prdCountPlanNo"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Plan_No')} />
        </Item>

        <Item
          label={t('titles.Name')}
          name="taskName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>

        <Row gutter={[10, 0]} justify="space-between">
          <Col span={10}>
            <Item
              label={t('titles.Start_Date')}
              name="startTime"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker placeholder={defaultFormatDate} />
            </Item>
          </Col>
          <Col span={10}>
            <Item
              noStyle
              shouldUpdate={(prevValue: CreateCountPlanDto, nextValue: CreateCountPlanDto) => {
                return (
                  prevValue.startTime !== nextValue.startTime ||
                  prevValue.endTime !== nextValue.endTime
                );
              }}
            >
              {({ getFieldValue }) => {
                const startTime = getFieldValue('startTime');
                const endTime = getFieldValue('endTime');

                return (
                  <Item
                    label={t('titles.End_Date')}
                    name="endTime"
                    dependencies={['startTime']}
                    rules={[
                      {
                        required: true,
                      },
                      () => ({
                        validator: () => {
                          if (startTime && startTime > endTime) {
                            return Promise.reject(
                              new Error('End date must be greater than start date')
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      disabledDate={(curr) => startTime > curr}
                      placeholder={defaultFormatDate}
                    />
                  </Item>
                );
              }}
            </Item>
          </Col>
        </Row>

        <Item
          label={t('titles.Site')}
          name="siteIds"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            maxTagCount="responsive"
            options={siteOptions}
            loading={siteStatus === 'pending'}
            placeholder={t('titles.Choose_Name', { name: t('titles.Site') })}
          />
        </Item>
      </CaseField>

      <CaseField>
        <SelectUser
          label={t('titles.Assign_To')}
          name="assignTo"
          rules={[
            {
              required: true,
            },
          ]}
        />
        <Item label={t('titles.Description')} name="description">
          <TextArea placeholder={t('titles.Enter_Descr')} />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormCountPlan };
