import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormUserGroup } from '@components/views/Forms/users';
import { useOutletUserGroup } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateUserGroup } from '@store/actions/user/UserGroup';

import type { CreateUserGroupDto } from '@model/users/UserGroup';

function EditUserGroup() {
  const { entity, initialValues } = useOutletUserGroup();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (dto: CreateUserGroupDto) => {
    dispatch(updateUserGroup({ ...dto, groupsId: entity.groupsId }))
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.group.groupName);
        navigate(paths.usergroups.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <FormUserGroup
      initialValues={initialValues}
      onFinish={onFinish}
      header={{
        title: entity.groupName,
      }}
    />
  );
}

export default EditUserGroup;
