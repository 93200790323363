import React, { memo } from 'react';

import { PaICount } from './PaICount';
import { SiteDropdown } from './SiteDropdown';
import { StatusDropdown } from './StatusDropdown';
import { VendorDropdown } from './VendorDropdown';

import './LeftItems.scss';

export const LeftItems = memo(() => {
  return (
    <div className="pai-toolbar-left-side">
      <PaICount />
      <StatusDropdown />
      <SiteDropdown />
      <VendorDropdown />
    </div>
  );
});
