import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { Divider } from '@shared/UI';
import { selectVendorStatus } from '@store/selectors/properties/Vendor';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { VendorModel } from '@model/properties/Vendor';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormVendor(props: CommonPreviewProps<VendorModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusVendor = useAppSelector(selectVendorStatus);

  return (
    <CommonForm
      isPreview
      loading={statusVendor === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField title={t('titles.Summary')}>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.partnerCode}</PreviewField>
        </Item>
      </CaseField>

      <CaseField>
        <Item label={t('titles.Tax_Office')}>
          <PreviewField>{entity.taxOffice}</PreviewField>
        </Item>
        <Item label={t('titles.TXN')}>
          <PreviewField>{entity.taxNumber}</PreviewField>
        </Item>
      </CaseField>
      <Divider />
      <CaseField title={t('titles.Location')}>
        <Item label={t('titles.Country')}>
          <PreviewField>{entity.city.country.name}</PreviewField>
        </Item>
        <Item label={t('titles.City')}>
          <PreviewField>{entity.city.name}</PreviewField>
        </Item>
        <Item label={t('titles.Address')}>
          <PreviewField>{entity.address}</PreviewField>
        </Item>
      </CaseField>

      <CaseField title={t('titles.Contacts')}>
        <Item label={t('titles.Email')}>
          <PreviewField>{entity.email}</PreviewField>
        </Item>
        <Item label={t('titles.Phone_Num')}>
          <PreviewField>{entity.phone}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormVendor };
