import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, SelectCountryAndCity } from '@components/common';
import { statusColor } from '@constants/statusColors';
import { useAppSelector } from '@hooks';
import { ColorPicker, Input } from '@shared/UI';
import { selectCompanyStatus } from '@store/selectors/users/Company';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateCompanyDto } from '@model/users/Company';

const { CaseField, Item, useForm } = CommonForm;

const FormCompany = (props: CommonFormProps<CreateCompanyDto>) => {
  const [form] = useForm<CreateCompanyDto>();

  const { t } = useTranslation();

  const statusCompany = useAppSelector(selectCompanyStatus);

  return (
    <CommonForm form={form} loading={statusCompany === 'pending'} {...props}>
      <CaseField title={t('titles.Summary')}>
        <Item
          label={t('titles.Name')}
          name={'name'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item
          label={t('titles.Code')}
          name="companyCode"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item name="color" label={t('titles.Color')}>
          <ColorPicker colors={statusColor} />
        </Item>
      </CaseField>

      <CaseField>
        <Item label={t('titles.Tax_Office')} name={'taxOffice'}>
          <Input placeholder={t('titles.Tax_Office')} />
        </Item>
        <Item label={t('titles.TXN')} name={'taxNumber'}>
          <Input placeholder={t('titles.TXN')} />
        </Item>
      </CaseField>

      <CaseField title={t('titles.Location')}>
        <SelectCountryAndCity />
        <Item
          label={t('titles.Address')}
          name={'address'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Address')} />
        </Item>
      </CaseField>
      <CaseField title={t('titles.Contacts')}>
        <Item label={t('titles.Email')} name={'contactName'}>
          <Input placeholder={t('titles.Email')} />
        </Item>
        <Item
          label={t('titles.Phone_Num')}
          name={'phone'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Phone_Num')} />
        </Item>
      </CaseField>
    </CommonForm>
  );
};

export { FormCompany };
