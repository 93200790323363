export interface IModalSizes {
  small: string;
  medium: string;
  large: string;
  xl: string;
}

export const modalSizes: IModalSizes = {
  small: '500px',
  medium: '650px',
  large: '800px',
  xl: '1000px',
};
