import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormUserAuthorization } from '@components/views/Forms/users';
import { useOutletUserAuthorization } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateUserAuthorization } from '@store/actions/user/UserAuthorization';

import type { CreateUserAuthorizationDto } from '@model/users/UserAuthorization';

function EditUserAuthorization() {
  const { initialValues, entity } = useOutletUserAuthorization();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (dto: CreateUserAuthorizationDto) => {
    dispatch(updateUserAuthorization({ ...dto, userId: entity.userId }))
      .unwrap()
      .then((response) => {
        ApiNotifications.update(
          `${response.editedAuthorization.firstName} ${response.editedAuthorization.lastName} `
        );
        navigate(paths.userauth.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <FormUserAuthorization
      initialValues={initialValues}
      onFinish={onFinish}
      header={{
        title: `${entity.firstName} ${entity.lastName} `,
      }}
    />
  );
}

export default EditUserAuthorization;
