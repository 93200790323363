import React from 'react';

const ContractIcon = () => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 6.5V20H15V6.5H1.5ZM0.75 5H15.75C15.9489 5 16.1397 5.07902 16.2803 5.21967C16.421 5.36032 16.5 5.55109 16.5 5.75V20.75C16.5 20.9489 16.421 21.1397 16.2803 21.2803C16.1397 21.421 15.9489 21.5 15.75 21.5H0.75C0.551088 21.5 0.360322 21.421 0.21967 21.2803C0.0790176 21.1397 0 20.9489 0 20.75V5.75C0 5.55109 0.0790176 5.36032 0.21967 5.21967C0.360322 5.07902 0.551088 5 0.75 5ZM21 1.25V17.75C21 17.9489 20.921 18.1397 20.7803 18.2803C20.6397 18.421 20.4489 18.5 20.25 18.5H18V17H19.5V2H7.5V3.5H6V1.25C6 1.05109 6.07902 0.860322 6.21967 0.71967C6.36032 0.579018 6.55109 0.5 6.75 0.5H20.25C20.4489 0.5 20.6397 0.579018 20.7803 0.71967C20.921 0.860322 21 1.05109 21 1.25ZM4.5 14.75H12V16.25H4.5V14.75ZM4.5 10.25H12V11.75H4.5V10.25Z"
      fill="white"
    />
  </svg>
);

export { ContractIcon };
