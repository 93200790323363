import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BasicTablePage,
  PAIExtraTableHeader,
} from '@components/common';
import { DataFilter } from '@components/common/DataFilter';
import { useAppDispatch, useAppNavigate, useAppSelector, useGetAccess } from '@hooks';
import { PaIRepository } from '@repositories';
import { paths } from '@routes/paths';
import { AsyncSelect, Button, Select } from '@shared/UI';
import { checkFilterValues } from '@shared/utils/functions';
import { getPaIs, getPaIsTypePO } from '@store/actions/management/PaI';
import { getPaICards } from '@store/actions/properties/PaI/Card';
import { getPaIStatuses } from '@store/actions/properties/PaI/Status';
import { getSites } from '@store/actions/properties/Site';
import { getVendors } from '@store/actions/properties/Vendor';
import {
  selectPaIFilterOptions,
  selectPaIRecordNotFound,
  selectPaISettings,
  selectPaIsTableList,
  selectPaIStatus,
  selectPaIsTypeNameList,
  selectPaIsTypePOListAllRecords,
} from '@store/selectors/management/PaI';
import { selectPaICardsList } from '@store/selectors/properties/PaI/Card';
import { selectPaIStatusesList } from '@store/selectors/properties/PaI/Status';
import { selectSitesList } from '@store/selectors/properties/Site';
import { selectVendorsList } from '@store/selectors/properties/Vendor';

import { columnsByPO } from './_utils';
import { PaIToolbar } from './PaIToolbar';
import { PaIToolbarProvider } from './PaIToolbarContext';

import type {
  ColumnsTableType} from '@components/common';
import type { FilterPaIDto, PaITableModel } from '@model/management/PaI';
import type { PartAndInvetoryPropertyValueModel } from '@model/properties/PaI/Property';
import type { AsyncSelectFields, AsyncSelectReturnData } from '@shared/UI';
import type { TablePaginationConfig } from 'antd';
import type { SorterResult } from 'antd/lib/table/interface';
import type { Key } from 'react';

import './PartAndInventories.scss';

function PartAndInventoriesV2() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const [selectedPaIs, setSelectedPaIs] = useState<Array<PaITableModel>>([]);

  const selectedPaIIds = selectedPaIs.map((PaI) => PaI.partsAndInventoriesId);

  const { paiAccess } = useGetAccess();

  const listPartAndInventoryTypeName = useAppSelector(selectPaIsTypeNameList);
  const listPartAndInventory = useAppSelector(selectPaIsTableList);
  const statusPartAndInventory = useAppSelector(selectPaIStatus);
  const settingsPartAndInventory = useAppSelector(selectPaISettings);
  const paIFilters = useAppSelector(selectPaIFilterOptions);
  const paIRecordNotFound = useAppSelector(selectPaIRecordNotFound);
  const listPartAndInventoryAllRecords = useAppSelector(selectPaIsTypePOListAllRecords);
  const siteList = useAppSelector(selectSitesList);
  const vendorsList = useAppSelector(selectVendorsList);
  const nameList = useAppSelector(selectPaICardsList);

  const listPartAndInventoryStatuses = useAppSelector(selectPaIStatusesList);


  const isEmptyLists =
    listPartAndInventory.length === 0 && listPartAndInventoryTypeName.length === 0;

  const hasFilterValue =
    !!paIFilters.partsAndInventoriesCardsIds?.length ||
    !!paIFilters.partsAndInventoriesSitesIds?.length ||
    !!paIFilters.purchaseNumbers?.length ||
    !!paIFilters.partsAndInventoriesPropertyValues?.length;

  useEffect(() => {
    if (!listPartAndInventoryAllRecords.length) {
      dispatch(getPaIsTypePO());
    }
    if (!siteList.length) {
      dispatch(getSites());
    }
    if (!nameList.length) {
      dispatch(getPaICards());
    }

    if (!vendorsList.length) {
      dispatch(getVendors());
    }

    if (!listPartAndInventoryStatuses.length) {
      dispatch(getPaIStatuses());
    }
  }, []);

  const clearSelected = useCallback(() => {
    setSelectedPaIs([]);
  }, []);

  const onChangePage = (page: number) => {
    dispatch(getPaIs({ page: page }));
  };


  const isSelected = selectedPaIs.length > 0;

  const paiSwitcherTable = (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'end',
        justifyContent: 'space-between',
      }}
    >

      <span
        style={{
          color: '#374957',
          marginLeft: 'auto',
        }}
      >
        Total: {settingsPartAndInventory.totalRecords}
      </span>
    </div>
  );

  const redirectToConsume = () => navigate(paths.partAndInventory.consume);

  const onSubmitFilter = (values: FilterPaIDto) => {
    dispatch(getPaIs(values));
  };

  const getDefaultList = () => {
    if (!checkFilterValues(paIFilters)) return;

    dispatch(
      getPaIs({
        ...paIFilters,
        partsAndInventoriesCardsIds: [],
        purchaseNumbers: [],
        partsAndInventoriesSitesIds: [],
        partsAndInventoriesPropertyValues: [],
      })
    );
  };

  const handleChangeSelection = (_: Array<Key>, selectedRecords: Array<PaITableModel>) => {
    setSelectedPaIs(selectedRecords);
  };

  const handlePropertyValueData = async (
    current: number,
    pageSize: number
  ): AsyncSelectReturnData<PartAndInvetoryPropertyValueModel> => {
    try {
      let filter = {
        page: current,
        perPage: pageSize,
      };

      const { resultObject, totalRecords } = await PaIRepository.fetchAllPropertyValue(filter);

      return {
        data: resultObject,
        total: totalRecords,
      };
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  const handleSearchPropertyValue = async (query: string) => {
    try {
      const { resultObject } = await PaIRepository.searchByPropertyValue(query);

      return {
        data: resultObject,
        total: resultObject.length,
      };
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  const fieldPropertyValueNames: AsyncSelectFields<PartAndInvetoryPropertyValueModel> = {
    value: 'value',
    label: 'value',
    key: 'value',
  };

  const itemsByName = [
    {
      label: t('titles.Name'),
      name: 'partsAndInventoriesCardsIds',
      item: (
        <Select
          options={nameList}
          maxTagCount={4}
          mode="tags"
          fieldNames={{
            label: 'partsAndInventoriesCardName',
            value: 'partsAndInventoriesCardId',
          }}
        />
      ),
    },
    {
      label: t('titles.Site'),
      name: 'partsAndInventoriesSitesIds',
      item: (
        <Select
          options={siteList}
          maxTagCount={4}
          mode="tags"
          fieldNames={{ label: 'name', value: 'siteId' }}
        />
      ),
    },
    {
      label: t('titles.Property_Value'),
      name: 'partsAndInventoriesPropertyValues',
      item: (
        <AsyncSelect
          fetchData={handlePropertyValueData}
          searchData={handleSearchPropertyValue}
          fieldNames={fieldPropertyValueNames as any}
          maxTagCount={4}
          mode="multiple"
        />
      ),
    },
  ];

  const itemsByPO = [
    {
      label: t('titles.PO'),
      name: 'purchaseNumbers',
      item: (
        <Select
          options={listPartAndInventoryAllRecords}
          maxTagCount={4}
          mode="tags"
          fieldNames={{ label: 'purchaseNumber', value: 'purchaseNumber' }}
        />
      ),
    },
    ...itemsByName,
  ];
  const filterProps = {
    items: itemsByPO,
    initialValuesForm: paIFilters,
    applyAction: onSubmitFilter,
    resetAction: getDefaultList,
    isFiltered: hasFilterValue,
    isDisabled: (paIRecordNotFound && !hasFilterValue) || isEmptyLists,
  };

  const headerProps = {
    hiddenSearch: true,
    className: 'pai-table-header',
    titleRedirectButton: t('titles.New_Item', { name: t('titles.PAI_Short') }),
    customExtraActions: (
      <>
        <DataFilter {...filterProps} />
        <Button
          type="default"
          disabled={!paiAccess.consume || paIRecordNotFound || isEmptyLists}
          onClick={redirectToConsume}
        >
          {t('titles.Consume')}
        </Button>
      </>
    ),
    newButtonProps: {
      disabled: !paiAccess.create,
    },
    title: (
      <PAIExtraTableHeader
        paIRecordNotFound={paIRecordNotFound}
        isSearch={!!paIFilters.searchKeyword?.length}
        isLabel={!!paIFilters.labelArray?.length}
        isEmptyLists={isEmptyLists}
      />
    ),
  };

  const handleSort = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    const { order, columnKey } = sorter as SorterResult<PaITableModel>;

    const sortDirection = 
      (order === "ascend") ? 1 :
      (order === "descend") ? 2 : 3;

    const sortProps = {
      sortDirection, 
      sortBy: columnKey as number, 
      page: pagination.current, 
      perPage: pagination.pageSize
    }

    dispatch(getPaIs(sortProps));
  };

  const tableProps = {
    columns: (columnsByPO as ColumnsTableType<any>),
    dataSource:(listPartAndInventory as any),
    loading: statusPartAndInventory === 'pending',
    rowKey: (record: PaITableModel) => record.partsAndInventoriesId,
    dataSourceName: t('pages_plural.PaIs'),
    recordNotFound: paIRecordNotFound,
    onChange: handleSort,
    pagination: {
      current: paIFilters.page,
      total: settingsPartAndInventory.totalRecords,
      onChange: onChangePage,
    },
    rowSelection: {
      selectedRowKeys: selectedPaIIds,
      onChange: handleChangeSelection,
    },
  };

  const customHeader = isSelected
    ? () => (
        <PaIToolbarProvider
          vendorsList={vendorsList}
          sitesList={siteList}
          clearSelected={clearSelected}
          selectedPaIs={selectedPaIs}
          statusesList={listPartAndInventoryStatuses}
        >
          <PaIToolbar filterProps={filterProps} />
        </PaIToolbarProvider>
      )
    : undefined;

  return (
    <BasicTablePage
      customHeader={customHeader}
      table={tableProps}
      header={headerProps}
      showEmptyPage={false}
      additionalChildren={paiSwitcherTable}
    />
  );
}

export { PartAndInventoriesV2 };
