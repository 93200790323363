import React from "react"
import { useTranslation } from "react-i18next";

import { 
  FormAssetCategory, 
  FormAssetName, 
  FormMaintenanceCategory, 
  FormMaintenanceRequestReason, 
  FormSite,
} from '@components/views/Forms/properties';
import { CreateableEntityModal } from "@shared/UI"

import type { UseModalStateReturn } from "@hooks";

interface CreateableEntityModalProps {
  assetCardModal: UseModalStateReturn;
  assetCategoryModal: UseModalStateReturn;
  maintenanceCategoryModal: UseModalStateReturn;
  requestReasonModal: UseModalStateReturn;
  siteModal: UseModalStateReturn;
}

const CreateableEntityModals = (props: CreateableEntityModalProps) => {
  const { 
    assetCardModal,
    assetCategoryModal, 
    maintenanceCategoryModal,
    requestReasonModal,
    siteModal,
  } = props;

  const { t } = useTranslation();

    return (
        <>
            <CreateableEntityModal
                open={assetCardModal.isOpen}
                onCancel={assetCardModal.actions.onClose}
                form={
                <FormAssetName
                    isModal
                    header={{
                    onClickCancel: assetCardModal.actions.onClose,
                    title: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Name') }),
                    hiddenCancelButton: true,
                    hiddenSaveButton: true,
                    }}
                />
                }
            />

            <CreateableEntityModal
                open={assetCategoryModal.isOpen}
                onCancel={assetCategoryModal.actions.onClose}
                form={
                <FormAssetCategory
                    isModal
                    header={{
                        onClickCancel: assetCategoryModal.actions.onClose,
                        title: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Category') }),
                        hiddenCancelButton: true,
                        hiddenSaveButton: true,
                    }}
                />
                }
            />

            <CreateableEntityModal
                open={maintenanceCategoryModal.isOpen}
                onCancel={maintenanceCategoryModal.actions.onClose}
                form={
                <FormMaintenanceCategory
                    isModal
                    header={{
                    onClickCancel: maintenanceCategoryModal.actions.onClose,
                    title: t('titles.New_Item', { 
                        name: `${t('pages_single.Maintenance')} ${t('pages_single.Maintenance_Attr_Category')}` 
                    }),
                    hiddenCancelButton: true,
                    hiddenSaveButton: true,
                    }}
                />
                }
            />

            <CreateableEntityModal
                open={requestReasonModal.isOpen}
                onCancel={requestReasonModal.actions.onClose}
                form={
                <FormMaintenanceRequestReason
                    isModal
                    header={{
                    onClickCancel: requestReasonModal.actions.onClose,
                    title: t('titles.New_Item', { name: t('pages_single.Maintenance_Attr_Reason') }),
                    hiddenCancelButton: true,
                    hiddenSaveButton: true,
                    }}
                />
                }
            />

            <CreateableEntityModal
                open={siteModal.isOpen}
                onCancel={siteModal.actions.onClose}
                form={
                <FormSite
                    isModal
                    header={{
                    onClickCancel: siteModal.actions.onClose,
                    title: t('titles.New_Item', { name: t('pages_single.Site') }),
                    }}
                />
                }
            />
        </>
    )
}

export { CreateableEntityModals };