import React from 'react';

import { addPrefixBase64 } from '@shared/utils/functions';

import EmptyAvatarSVG from '../../../assets/icons/empty-avatar.svg';
import { UploaderAvatar } from './UploaderAvatar';

import './ProfileAvatar.scss';

export interface ProfileAvatarProps {
  avatar: string | null;
  activeUploader?: boolean;
  onChange?: (avatar: string | null) => void;
  style?: React.CSSProperties;
}

function ProfileAvatar(props: ProfileAvatarProps) {
  const { avatar, activeUploader, onChange, style } = props;

  const normalizeAvatar = avatar ? addPrefixBase64(avatar) : null;

  const img = (
    <img
      alt="avatar"
      width={176}
      height={176}
      style={{ borderRadius: '50%' , ...style}}
      src={normalizeAvatar ? normalizeAvatar : EmptyAvatarSVG}
    />
  );

  return (
    <>{activeUploader ? <UploaderAvatar onChange={onChange} avatar={normalizeAvatar} style={style} /> : img}</>
  );
}

export { ProfileAvatar };
