import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getExitTypes } from '@store/actions/properties/ExitType';
import { selectExitTypesList } from '@store/selectors/properties/ExitType';

function EntryExitType() {
  useSetPageInfo({
    defaultPagePath: paths.exitType.default,
    pageTitle: 'pages_single.Exit_Type',
  });

  const listExitType = useAppSelector(selectExitTypesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listExitType.length) {
      dispatch(getExitTypes());
    }
  }, []);

  return <Outlet />;
}

export default EntryExitType;
