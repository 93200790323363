import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import {
  selectAsAtCategoriesListWithoutChildren,
  selectAsAtCategoryStatus,
} from '@store/selectors/properties/AsAt/Category';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { AsAtCategoryModel } from '@model/properties/AsAt/Category';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormAssetCategory(props: CommonPreviewProps<AsAtCategoryModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const assetCategoryStatus = useAppSelector(selectAsAtCategoryStatus);
  const listAssetCategory = useAppSelector(selectAsAtCategoriesListWithoutChildren);

  const parentAssetCategory = listAssetCategory.find(
    (category) => category.nonCurrAssetCardCategoryId === entity.parentNonCurrAssetCardCategoryId
  );

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={assetCategoryStatus === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.nonCurrAssetCardCategoryCode}</PreviewField>
        </Item>
        <Item label={t('titles.Parent_Category')}>
          <PreviewField>{parentAssetCategory?.name}</PreviewField>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormAssetCategory };
