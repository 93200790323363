import type { ActionFunction } from '@components/common/CheckStepItem/_utils';

export const onChangeStepActive: ActionFunction<string> = (uuid, { setState }) => {
  setState((prevState) =>
    prevState.map((step) => {
      if (step.uuid === uuid) {
        return {
          ...step,
          isActive: !step.isActive,
        };
      }
      return step;
    })
  );
};
