import * as React from 'react';

import type { SVGProps } from 'react';

const MultiplyChoice = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 21a8 8 0 1 1 8-8 8.01 8.01 0 0 1-8 8Zm0-13.714a5.714 5.714 0 1 0 0 11.428 5.714 5.714 0 0 0 0-11.428Z"
      fill={props.fill || '#E0E0E0'}
    />
  </svg>
);

export { MultiplyChoice };
