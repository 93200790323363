import { createSlice } from '@reduxjs/toolkit';
import {
  addUserAuthorization,
  getUserAuthorizationById,
  getUserAuthorizations,
  updateUserAuthorization,
} from '@store/actions/user/UserAuthorization';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { UserModel } from '@model/users/User';

interface UserAuthorizationState {
  userAuthList: UserModel[];
  userAuth: UserModel | null;
  userAuthStatus: LoadingStatus;
  userAuthError: string | null;
}

const initialState: UserAuthorizationState = {
  userAuthList: [],
  userAuth: null,
  userAuthStatus: 'idle',
  userAuthError: null,
};

const UserAuthorizationSlice = createSlice({
  name: 'User Authorization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserAuthorizations.pending, (state) => {
        state.userAuthStatus = 'pending';
        state.userAuthError = null;
      })
      .addCase(getUserAuthorizations.fulfilled, (state, { payload }) => {
        state.userAuthStatus = 'idle';
        state.userAuthList = payload;
      })
      .addCase(getUserAuthorizations.rejected, (state, { payload }) => {
        state.userAuthStatus = 'failed';
        if (payload) {
          state.userAuthError = payload;
        }
      });
    builder
      .addCase(addUserAuthorization.pending, (state) => {
        state.userAuthError = null;
        state.userAuthStatus = 'pending';
      })
      .addCase(addUserAuthorization.fulfilled, (state, { payload }) => {
        state.userAuthStatus = 'idle';
        state.userAuthList = payload.userAuthList;
      })
      .addCase(addUserAuthorization.rejected, (state) => {
        state.userAuthStatus = 'idle';
      });
    builder
      .addCase(getUserAuthorizationById.pending, (state) => {
        state.userAuthError = null;
        state.userAuthStatus = 'pending';
      })
      .addCase(getUserAuthorizationById.fulfilled, (state, { payload }) => {
        state.userAuthStatus = 'idle';
        state.userAuth = payload;
      })
      .addCase(getUserAuthorizationById.rejected, (state) => {
        state.userAuthStatus = 'idle';
      });
    builder
      .addCase(updateUserAuthorization.pending, (state) => {
        state.userAuthError = null;
        state.userAuthStatus = 'pending';
      })
      .addCase(updateUserAuthorization.fulfilled, (state, { payload }) => {
        state.userAuthStatus = 'idle';
        state.userAuth = payload.editedAuthorization;
        state.userAuthList = payload.userAuthList;
      })
      .addCase(updateUserAuthorization.rejected, (state) => {
        state.userAuthStatus = 'idle';
      });
  },
});

const UserAuthorizationReducer = UserAuthorizationSlice.reducer;

export { UserAuthorizationReducer };
