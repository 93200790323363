import {createAsyncThunk} from '@reduxjs/toolkit';
import {ProfileRepository, RoleRepository} from '@repositories';

import type {
  CreatePasswordDto,
  ProfileModel,
  UpdateNonCurrAssetUserApproveStatusDto,
  UpdateProfileDto,
  UpdateProfilePasswordDto} from '@model/profile/Profile';
import type {RoleModel} from '@model/users/Role';

// region --- Get Actions

export const getProfile = createAsyncThunk<
  { profile: ProfileModel; role: RoleModel },
  void,
  { rejectValue: string }
>('Profile/getProfile', async (_, { rejectWithValue }) => {
  try {
    const { resultObject } = await ProfileRepository.fetchProfile();

    const profileRoleId = resultObject.userRoles?.[0].roleId;

    const roleResponse = await RoleRepository.fetchById(profileRoleId);

    return { profile: resultObject, role: roleResponse.resultObject };
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response);
  }
});

// endregion --- Get Actions

// region --- Update Actions

export const updateProfile = createAsyncThunk<
  ProfileModel,
  UpdateProfileDto,
  { rejectValue: string }
>('Profile/updateProfile', async (dto, { rejectWithValue }) => {
  try {
    const { profileImage, ...restDto } = dto;
    await ProfileRepository.updateProfile(restDto);

    if (profileImage) {
      const image = await fetch(profileImage);
      const imageBuffer = await image.arrayBuffer();
      const type = image.headers.get('content-type');

      const file = new File([imageBuffer], 'profileImage.jpeg', { type: type || 'image/jpeg' });

      const formData = new FormData();

      formData.append('ProfileImage', file);

      await ProfileRepository.handleProfileImage(formData);
    }

    const response = await ProfileRepository.fetchProfile();

    return response.resultObject;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data.languageKeyword);
  }
});

export const updatePassword = createAsyncThunk<
  void,
  UpdateProfilePasswordDto,
  { rejectValue: string }
>('Profile/updatePassword', async (dto, { rejectWithValue }) => {
  try {
    await ProfileRepository.changePassword(dto);
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data.languageKeyword);
  }
});

export const createPassword = createAsyncThunk<void, CreatePasswordDto, { rejectValue: string }>(
  'Profile/createPassword',
  async (dto, { rejectWithValue }) => {
    try {
      await ProfileRepository.createPassword(dto);
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);


//region Assign To Section


//endregion --- Assign To Section

export const updateNonCurrAssetUsersApproveStatus = createAsyncThunk<
  void,
  UpdateNonCurrAssetUserApproveStatusDto,
  { rejectValue: string }
>('Profile/getProfile', async (dto, { rejectWithValue }) => {
  try {
    await ProfileRepository.UpdateNonCurrAssetUserApproveStatus(dto);
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data.languageKeyword);
  }
});
// endregion --- Update Actions
