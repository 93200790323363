import { api } from '@services/Axios';
import axios, { AxiosError } from 'axios';

import type { ForgotPasswordDto, SignInDto } from '@model/profile/Auth';
import type { ApiResponse } from '@services/Axios';
import type { AxiosResponse } from 'axios';

const AuthEndpoint = '/Auth';

export const AuthRepository = {
  async signIn(dto: SignInDto) {
    const response: AxiosResponse<{
      token: string;
      expiration: string;
      menu_auth: null | any;
      resultStatus?: boolean;
      languageKeyword?: string;
    }> = await axios.post(`${process.env.REACT_APP_API_URL}${AuthEndpoint}/token`, dto);

    if (
      response.data.resultStatus !== undefined &&
      !response.data.resultStatus &&
      response.status !== 401
    ) {
      const { data } = response;
      throw new AxiosError<{
        token: string;
        expiration: string;
        menu_auth: null | any;
        resultStatus?: boolean;
        languageKeyword?: string;
      }>(data.languageKeyword, '200', undefined, undefined, data as any);
    }
    return response;
  },

  async passwordRecovery(dto: ForgotPasswordDto) {
    const response: ApiResponse<null> = await api.post(`${AuthEndpoint}/forgotpassword`, dto);

    return response.data;
  },
};
