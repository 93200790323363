import React from 'react';

import { Col as AntCol } from 'antd';

import type { ColProps as AntColProps } from 'antd';

export type ColProps = AntColProps & {};

export const Col = (props: ColProps) => {
  const {...restProps} = props;

  return <AntCol {...restProps}/>;
};

