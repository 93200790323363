import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getPaIBrandById } from '@store/actions/properties/PaI/Brand';
import { selectPaIBrand } from '@store/selectors/properties/PaI/Brand';

import type { IOutletPartAndInventoryBrand } from '@constants/OutletContextEntityPages';
import type { CreatePaIBrandDto } from '@model/properties/PaI/Brand';

function PartAndInventoryBrand() {
  const partAndInventoryBrand = useAppSelector(selectPaIBrand);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition =
    !partAndInventoryBrand || partAndInventoryBrand.partsAndInventoriesCardBrandId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getPaIBrandById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreatePaIBrandDto = {
    partsAndInventoriesCardBrandCode: partAndInventoryBrand.partsAndInventoriesCardBrandCode,
    name: partAndInventoryBrand.name,
  };

  return (
    <Outlet
      context={{ initialValues, entity: partAndInventoryBrand } as IOutletPartAndInventoryBrand}
    />
  );
}

export default PartAndInventoryBrand;
