import type { RootState } from '@store/rootReducer';

export const selectUserGroupState = (state: RootState) => state.UserGroupReducer;

export const selectUserGroupsList = (state: RootState) => selectUserGroupState(state).groupList;

export const selectUserGroup = (state: RootState) => selectUserGroupState(state).group;

export const selectUserGroupStatus = (state: RootState) => selectUserGroupState(state).groupStatus;

export const selectUserGroupError = (state: RootState) => selectUserGroupState(state).groupError;
