import * as React from 'react';

import type { SVGProps } from 'react';

const TextIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m8.576 15.57 1.409-.406 8.482-8.584a.365.365 0 0 0-.002-.512l-.508-.514a.354.354 0 0 0-.506-.002l-8.46 8.561-.416 1.456h.001ZM18.962 4.537l.508.514c.701.71.708 1.855.013 2.557l-8.74 8.846-3.012.867a.721.721 0 0 1-.888-.498.732.732 0 0 1-.001-.405l.876-3.072 8.717-8.823a1.771 1.771 0 0 1 2.527.013Zm-9.215.922c.397 0 .719.326.719.727a.724.724 0 0 1-.719.728H6.874c-.794 0-1.437.65-1.437 1.453v8.724c0 .803.643 1.455 1.437 1.455h8.62c.794 0 1.438-.651 1.438-1.455v-2.908c0-.401.322-.727.718-.727.397 0 .719.326.719.728v2.907c0 1.607-1.287 2.909-2.875 2.909h-8.62C5.286 20 4 18.698 4 17.091V8.367C4 6.762 5.286 5.46 6.874 5.46h2.873Z"
      fill={props.fill || '#E0E0E0'}
    />
  </svg>
);

export { TextIcon };
