/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';

import { AssetImages, CommonForm, SelectUser } from '@components/common';
import { Steps } from '@components/views/Forms/managment/Asset/Steps';
import { useAppDispatch, useAppSelector, useIsCreatePage, useModalState } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { Col, Divider, Row } from '@shared/UI';
import { checkStoreStatuses, formatMomentDate } from '@shared/utils/functions';
import { getCurrencies } from '@store/actions/extra/Currency';
import { addLabel } from '@store/actions/extra/Label';
import { addAsAtBrand, getAsAtBrands } from '@store/actions/properties/AsAt/Brand';
import { addAsAtCard, getAsAtCards } from '@store/actions/properties/AsAt/Card';
import { addAsAtCategory, getAsAtCategories } from '@store/actions/properties/AsAt/Category';
import { addAsAtModel, getAsAtModels } from '@store/actions/properties/AsAt/Model';
import { addAsAtProperty } from '@store/actions/properties/AsAt/Property';
import { addAsAtStatus, getAsAtStatuses } from '@store/actions/properties/AsAt/Status';
import { addContract, addContractFiles, getContracts } from '@store/actions/properties/Contract';
import { addCostCenter, getCostCenters } from '@store/actions/properties/CostCenter';
import { addSite, getSites } from '@store/actions/properties/Site';
import { addVendor, getVendors } from '@store/actions/properties/Vendor';
import { selectCurrenciesList, selectCurrencyStatus } from '@store/selectors/extra/Currency';
import { selectAssetStatus } from '@store/selectors/management/Asset';
import {
  selectAsAtBrandsList,
  selectAsAtBrandStatus,
} from '@store/selectors/properties/AsAt/Brand';
import { selectAsAtCardsList, selectAsAtCardStatus } from '@store/selectors/properties/AsAt/Card';
import {
  selectAsAtCategoriesListWithoutChildren,
  selectAsAtCategoryStatus,
} from '@store/selectors/properties/AsAt/Category';
import {
  selectAsAtModelsList,
  selectAsAtModelStatus,
} from '@store/selectors/properties/AsAt/Model';
import {
  selectAsAtStatusesList,
  selectAsAtStatusStatus,
} from '@store/selectors/properties/AsAt/Status';
import { selectContractsList, selectContractStatus } from '@store/selectors/properties/Contract';
import {
  selectCostCentersList,
  selectCostCenterStatus,
} from '@store/selectors/properties/CostCenter';
import { selectSitesListWithoutChildren, selectSiteStatus } from '@store/selectors/properties/Site';
import { selectVendorsList, selectVendorStatus } from '@store/selectors/properties/Vendor';
import moment from 'moment';

import { CreateableEntityModals } from './CreateableEntityModals';
import { ExpandedLeftSection } from './ExpandedLeftSection';
import { ExpandedMiddleSection } from './ExpandedMiddleSection';
import { ExpandedRightSection } from './ExpandedRightSection';
import { LeftSection } from './LeftSection';
import { MiddleSection } from './MiddleSection';
import { RightSection } from './RightSection';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { FileWithID } from '@interfaces/interfaces';
import type { CreateLabelDto } from '@model/extra/Label';
import type { CreateAssetDto } from '@model/management/Asset';
import type { CreateAsAtBrandDto } from '@model/properties/AsAt/Brand';
import type { CreateAsAtCardDto } from '@model/properties/AsAt/Card';
import type { CreateAsAtCategoryDto } from '@model/properties/AsAt/Category';
import type { CreateAsAtModelDto } from '@model/properties/AsAt/Model';
import type { CreateAsAtPropertyDto } from '@model/properties/AsAt/Property';
import type { CreateAsAtStatusDto } from '@model/properties/AsAt/Status';
import type { CreateContractDto } from '@model/properties/Contract';
import type { CreateCostCenterDto } from '@model/properties/CostCenter';
import type { CreateSiteDto } from '@model/properties/Site';
import type { CreateVendorDto } from '@model/properties/Vendor';
import type { FormInstance } from 'antd';
import type { FormFinishInfo, FormProviderProps } from 'rc-field-form/lib/FormContext';

const { CaseField, Item, useForm, Provider, useWatch } = CommonForm;

type FormsFinish = {
  formAssetCategory: FormInstance<CreateAsAtCategoryDto>;

  formAssetBrand: FormInstance<CreateAsAtBrandDto>;

  formAssetCard: FormInstance<CreateAsAtCardDto>;

  formAssetModel: FormInstance<CreateAsAtModelDto>;

  formAssetStatus: FormInstance<CreateAsAtStatusDto>;

  formAssetProperty: FormInstance<CreateAsAtPropertyDto>;

  formCostCenter: FormInstance<CreateCostCenterDto>;

  formVendor: FormInstance<CreateVendorDto>;

  formContract: FormInstance<CreateContractDto>;

  formSite: FormInstance<CreateSiteDto>;

  formLabel: FormInstance<CreateLabelDto>;

  assetForm: FormInstance<CreateAssetDto>;
};

export function FormAsset(props: CommonFormProps<CreateAssetDto>) {
  const { initialValues, onFinish: onFinishProps, ...rest } = props;

  const isCreate = useIsCreatePage();

  const [form] = useForm<CreateAssetDto>();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // region --- State selectors
  const assetBrandsList = useAppSelector(selectAsAtBrandsList);
  const assetCategoriesList = useAppSelector(selectAsAtCategoriesListWithoutChildren);
  const vendorsList = useAppSelector(selectVendorsList);
  const assetStatusesList = useAppSelector(selectAsAtStatusesList);
  const sitesList = useAppSelector(selectSitesListWithoutChildren);
  const assetCardsList = useAppSelector(selectAsAtCardsList);
  const assetModelsList = useAppSelector(selectAsAtModelsList);
  const costCentersList = useAppSelector(selectCostCentersList);
  const contractsList = useAppSelector(selectContractsList);
  const currenciesList = useAppSelector(selectCurrenciesList);

  const assetStatus = useAppSelector(selectAssetStatus);
  const assetBrandStatus = useAppSelector(selectAsAtBrandStatus);
  const assetCategoryStatus = useAppSelector(selectAsAtCategoryStatus);
  const assetStatusStatus = useAppSelector(selectAsAtStatusStatus);
  const siteStatus = useAppSelector(selectSiteStatus);
  const assetNameStatus = useAppSelector(selectAsAtCardStatus);
  const assetModelStatus = useAppSelector(selectAsAtModelStatus);
  const costCenterStatus = useAppSelector(selectCostCenterStatus);
  const contractStatus = useAppSelector(selectContractStatus);
  const currencyStatus = useAppSelector(selectCurrencyStatus);
  const vendorStatus = useAppSelector(selectVendorStatus);
  // endregion --- State selectors

  // region --- observed values

  const images = useWatch('images', form);

  // endregion --- observed values

  const isLoading = checkStoreStatuses([
    assetStatus,
    assetBrandStatus,
    assetCategoryStatus,
    assetStatusStatus,
    siteStatus,
    assetNameStatus,
    assetModelStatus,
    costCenterStatus,
    contractStatus,
    currencyStatus,
    vendorStatus,
  ]);

  const [canRedirect, setCanRedirect] = useState(false);

  /* -------------------------------  Modals State ------------------------------ */
  const assetCategoryModal = useModalState();
  const assetCardModal = useModalState();
  const assetModelModal = useModalState();
  const assetBrandModal = useModalState();
  const assetStatusModal = useModalState();
  const assetPropertyModal = useModalState();
  const costCenterModal = useModalState();
  const labelModal = useModalState();
  const vendorModal = useModalState();
  const siteModal = useModalState();
  const contractModal = useModalState();

  useEffect(() => {
    batch(() => {
      if (!assetBrandsList.length) {
        dispatch(getAsAtBrands());
      }
      if (!assetCategoriesList.length) {
        dispatch(getAsAtCategories());
      }
      if (!vendorsList.length) {
        dispatch(getVendors());
      }
      if (!assetStatusesList.length) {
        dispatch(getAsAtStatuses());
      }
      if (!sitesList.length) {
        dispatch(getSites());
      }
      if (!assetCardsList.length) {
        dispatch(getAsAtCards());
      }
      if (!assetModelsList.length) {
        dispatch(getAsAtModels());
      }
      if (!costCentersList.length) {
        dispatch(getCostCenters());
      }
      if (!contractsList.length) {
        dispatch(getContracts());
      }
      if (!currenciesList.length) {
        dispatch(getCurrencies());
      }
    });
  }, []);

  const onFinishAssetCategory = (assetCategoryForm: FormInstance<CreateAsAtCategoryDto>) => {
    const dto = assetCategoryForm.getFieldsValue();

    dispatch(addAsAtCategory(dto))
      .unwrap()
      .then(({ AsAtCategory: { nonCurrAssetCardCategoryId, name } }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Asset_Attr'),
          }),
        });

        assetCategoryForm.resetFields();
        form.setFieldValue('nonCurrAssetCardCategoryId', nonCurrAssetCardCategoryId);
        form.setFieldValue('nonCurrAssetCardId', null);
        assetCategoryModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishAssetCard = (assetCardForm: FormInstance<CreateAsAtCardDto>) => {
    const dto = assetCardForm.getFieldsValue();

    dispatch(addAsAtCard(dto))
      .unwrap()
      .then(({ nonCurrAssetCardId, name, nonCurrAssetCardCategoryId }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Asset_Attr'),
          }),
        });

        assetCardForm.resetFields();

        form.setFieldValue('nonCurrAssetCardId', nonCurrAssetCardId);
        form.setFieldValue('nonCurrAssetCardCategoryId', nonCurrAssetCardCategoryId);
        assetCardModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishAssetModel = (assetModelForm: FormInstance<CreateAsAtModelDto>) => {
    const dto = assetModelForm.getFieldsValue();

    dispatch(addAsAtModel(dto))
      .unwrap()
      .then(({ nonCurrAssetCardModelId, nonCurrAssetCardBrandId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Asset_Attr'),
          }),
        });

        assetModelForm.resetFields();

        form.setFieldValue('nonCurrAssetCardBrandId', nonCurrAssetCardBrandId);
        form.setFieldValue('nonCurrAssetCardModelId', nonCurrAssetCardModelId);

        assetModelModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishAssetProperty = (assetPropertyForm: FormInstance<CreateAsAtPropertyDto>) => {
    const dto = assetPropertyForm.getFieldsValue();

    dispatch(addAsAtProperty(dto))
      .unwrap()
      .then(({ nonCurrAssetCardPropertyId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Asset_Attr'),
          }),
        });

        assetPropertyForm.resetFields();
        form.setFieldValue('selectedProperty', nonCurrAssetCardPropertyId);
        assetPropertyModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishAssetBrand = (assetBrandForm: FormInstance<CreateAsAtBrandDto>) => {
    const dto = assetBrandForm.getFieldsValue();

    dispatch(addAsAtBrand(dto))
      .unwrap()
      .then(({ nonCurrAssetCardBrandId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Asset_Attr'),
          }),
        });

        assetBrandForm.resetFields();
        const modelId = form.getFieldValue('nonCurrAssetCardModelId');
        if (modelId) {
          form.setFieldValue('nonCurrAssetCardModelId', null);
        }
        form.setFieldValue('nonCurrAssetCardBrandId', nonCurrAssetCardBrandId);
        assetBrandModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishAssetStatus = (assetStatusForm: FormInstance<CreateAsAtStatusDto>) => {
    const dto = assetStatusForm.getFieldsValue();

    dispatch(addAsAtStatus(dto))
      .unwrap()
      .then(({ nonCurrAssetStatusId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Asset_Attr'),
          }),
        });

        assetStatusForm.resetFields();
        form.setFieldValue('statusId', nonCurrAssetStatusId);
        assetStatusModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishCostCenter = (costCenterForm: FormInstance<CreateCostCenterDto>) => {
    const dto = costCenterForm.getFieldsValue();

    dispatch(addCostCenter(dto))
      .unwrap()
      .then(({ costCenterId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Asset_Attr'),
          }),
        });

        costCenterForm.resetFields();
        form.setFieldValue('costCenterId', costCenterId);
        costCenterModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishLabel = (labelForm: FormInstance<CreateLabelDto>) => {
    const dto = labelForm.getFieldsValue();

    dispatch(addLabel({ label: dto, enumLabelPlace: 1 }))
      .unwrap()
      .then(({ createdLabel }) => {
        labelForm.resetFields();
        const newLabel = {
          label: createdLabel.label,
          value: createdLabel.label,
          labelColor: createdLabel.labelColor,
        };
        const labelList = form.getFieldValue('labelsList');

        form.setFieldValue('labelsList', [...labelList, newLabel]);
        labelModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };
  const onFinishVendor = (vendorForm: FormInstance<CreateVendorDto>) => {
    const dto = vendorForm.getFieldsValue();

    dispatch(addVendor(dto))
      .unwrap()
      .then(({ partnerId, name }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Vendor'),
          }),
        });

        vendorForm.resetFields();
        form.setFieldValue('partnerId', partnerId);
        vendorModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishSite = (siteForm: FormInstance<CreateSiteDto>) => {
    const dto = siteForm.getFieldsValue();

    dispatch(addSite(dto))
      .unwrap()
      .then(({ createdSite: { siteId, name } }) => {
        ApiNotifications.create(null, {
          description: t('titles.Modal_Form_Created_Message', {
            name: name,
            section: t('pages_single.Site'),
          }),
        });

        siteForm.resetFields();
        form.setFieldValue('siteId', siteId);
        siteModal.actions.onClose();
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  const onFinishContract = async (
    contractForm: FormInstance<CreateContractDto & { files?: FileWithID[] }>
  ) => {
    const dto = contractForm?.getFieldsValue();

    const { files, startDate, endDate, ...restDto } = dto;

    const normalizeDate = {
      ...restDto,
      startDate: formatMomentDate(startDate),
      endDate: formatMomentDate(endDate),
    };

    const originalFiles = files?.map((file) => file.file as File);

    try {
      const response = await dispatch(addContract(normalizeDate)).unwrap();

      try {
        await dispatch(
          addContractFiles({
            contractId: response.contractId,
            files: originalFiles,
          })
        );
      } catch (error) {
        ApiNotifications.error(error);
      }
      ApiNotifications.create(null, {
        description: t('titles.Modal_Form_Created_Message', {
          name: response.name,
          section: t('pages_single.Contract'),
        }),
      });

      contractForm.resetFields();
      const prevValues = form.getFieldValue('contractIds');
      form.setFieldValue('contractIds', [...prevValues, response.contractId]);
      contractModal.actions.onClose();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  const onFinish = (dto: CreateAssetDto) => {
    setCanRedirect(true);
    onFinishProps?.(dto, form);
  };

  const onFormsFinish = (
    name: string,
    { forms }: Omit<FormFinishInfo, 'forms'> & { forms: FormsFinish }
  ) => {
    switch (name) {
      case 'formAssetCategory': {
        onFinishAssetCategory(forms.formAssetCategory);
        break;
      }
      case 'formAssetBrand': {
        onFinishAssetBrand(forms.formAssetBrand);
        break;
      }
      case 'formAssetModel': {
        onFinishAssetModel(forms.formAssetModel);
        break;
      }
      case 'formAssetCard': {
        onFinishAssetCard(forms.formAssetCard);
        break;
      }
      case 'formAssetStatus': {
        onFinishAssetStatus(forms.formAssetStatus);
        break;
      }
      case 'formAssetProperty': {
        onFinishAssetProperty(forms.formAssetProperty);
        break;
      }
      case 'formCostCenter': {
        onFinishCostCenter(forms.formCostCenter);
        break;
      }
      case 'formVendor': {
        onFinishVendor(forms.formVendor);
        break;
      }
      case 'formSite': {
        onFinishSite(forms.formSite);
        break;
      }
      case 'formContract': {
        onFinishContract(forms.formContract);
        break;
      }
      case 'formLabel': {
        onFinishLabel(forms.formLabel);
      }
    }
  };

  const initialValuesForm: Partial<CreateAssetDto> = {
    ...initialValues,
    warrantyEndDate: initialValues?.warrantyEndDate
      ? (moment(initialValues?.warrantyEndDate) as any)
      : null,
    warrantyStartDate: initialValues?.warrantyStartDate
      ? (moment(initialValues?.warrantyStartDate) as any)
      : null,
    invoiceDate: initialValues?.invoiceDate
      ? moment(initialValues?.invoiceDate) as any
      : null,
    labelsList: initialValues?.labelsList ? initialValues?.labelsList : [],
    nonCurrAssetPropertyDetalisModelList: initialValues?.nonCurrAssetPropertyDetalisModelList
      ? initialValues?.nonCurrAssetPropertyDetalisModelList
      : [],

    contractIds: initialValues?.contractIds ? initialValues?.contractIds : [],
  };

  const additionalHeader = isCreate ? <Steps /> : null;

  return (
    <Provider onFormFinish={onFormsFinish as FormProviderProps['onFormFinish']}>
      <CommonForm
        canRedirect={canRedirect}
        form={form}
        loading={isLoading}
        container={{
          style: { width: '100%', maxWidth: '100%' },
        }}
        initialValues={initialValuesForm}
        onFinish={onFinish}
        alignAdditionalHeader="left"
        additionalHeader={additionalHeader}
        {...rest}
      >
        <LeftSection
          assetCategoriesList={assetCategoriesList}
          onAssetCategoryOpenModal={assetCategoryModal.actions.onOpen}
          assetStatusesList={assetStatusesList}
          onAssetStatusOpenModal={assetStatusModal.actions.onOpen}
          vendorsList={vendorsList}
          onVendorOpenModal={vendorModal.actions.onOpen}
        />

        <MiddleSection
          assetBrandsList={assetBrandsList}
          sitesList={sitesList}
          onSiteOpenModal={siteModal.actions.onOpen}
          onAssetBrandOpenModal={assetBrandModal.actions.onOpen}
        />

        <RightSection
          assetCardsList={assetCardsList}
          onAssetCardOpenModal={assetCardModal.actions.onOpen}
          assetModelsList={assetModelsList}
          assetBrandList={assetBrandsList}
          onAssetModelOpenModal={assetModelModal.actions.onOpen}
        />

        <Divider />
        <ExpandedLeftSection
          contractsList={contractsList}
          initialPropertyValues={initialValues?.nonCurrAssetPropertyDetalisModelList}
          onAssetPropertyOpenModal={assetPropertyModal.actions.onOpen}
          onContractOpenModal={contractModal.actions.onOpen}
        />
        <ExpandedMiddleSection
          costCentersList={costCentersList}
          onCostCenterOpenModal={costCenterModal.actions.onOpen}
        />
        <ExpandedRightSection
          currenciesList={currenciesList}
          onOpenLabelModal={labelModal.actions.onOpen}
        />

        <Divider />

        <CaseField md={24} hiddenTitle>
          <Row justify="space-between" gutter={[20, 0]}>
            <Col md={15} sm={24} xs={24}>
              <Item
                label={t('titles.Uploaded_Images')}
                shouldUpdate={(prevValue: CreateAssetDto, nextValue: CreateAssetDto) => {
                  return prevValue.images !== nextValue.images;
                }}
              >
                <AssetImages hiddenLabel images={images} />
              </Item>
            </Col>
            <Col md={6} sm={24} xs={24}>
              <SelectUser
                label={t('titles.Assign_To')}
                placeholder={t('titles.Assign_To')}
                name="assignedUserId"
              />
            </Col>
          </Row>
        </CaseField>
        <CreateableEntityModals
          assetCategoryModal={assetCategoryModal}
          assetCardModal={assetCardModal}
          assetModelModal={assetModelModal}
          assetBrandModal={assetBrandModal}
          assetStatusModal={assetStatusModal}
          assetPropertyModal={assetPropertyModal}
          costCenterModal={costCenterModal}
          labelModal={labelModal}
          vendorModal={vendorModal}
          siteModal={siteModal}
          contractModal={contractModal}
        />
      </CommonForm>
    </Provider>
  );
}
