import React, { useCallback, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Table as TableUI } from '@shared/UI';
import { updateActivePage } from '@store/actions/extra/Local';
import { selectTableActivePage } from '@store/selectors/extra/Local';

import { DraggableRow } from './elements';
import { dragType } from './elements/DraggableRow';

import type { DragEnd } from '@interfaces/interfaces';
import type { TableProps as TableUIProps } from '@shared/UI';
import type { GetRowKey } from 'antd/lib/table/interface';
import type { DraggableRowProps } from './elements';

import './Table.scss';

export interface TableProps<T extends Record<string, any>>
  extends Omit<TableUIProps<T>, 'rowKey'>,
    DraggableRowProps<T> {
  draggable?: boolean;
  rowKey: GetRowKey<T>;
  recordNotFound?: boolean;
  selectedRecords?: Array<T>;

  onChangeSelectedRecords?: (records: Array<T>) => void;
}

export const Table = <T extends Record<string, any>>(props: TableProps<T>) => {
  const {
    rowKey,
    draggable,
    dropAction,
    pagination,
    rowSelection: rowSelectionProps,
    selectedRecords: selectedRecordsProps,
    ...rest
  } = props;
  const { redirect } = useHandleParamId();
  const dispatch = useAppDispatch();
  const activePage = useAppSelector(selectTableActivePage);

  /* -------------------------------- Selected -------------------------------- */
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  }, []);

  const rowSelection: TableProps<T>['rowSelection'] = {
    selectedRowKeys,
    onChange: onSelectChange,
    ...rowSelectionProps,
  };

  /* ------------------------- Table components with custom DraggableRow ------------------------ */
  const components = {
    body: {
      row: DraggableRow,
    },
  };

  const onChangePage = useCallback(
    (page: number) => {
      if (activePage !== page) {
        dispatch(updateActivePage(page));
      }
    },
    [activePage]
  );

  /* -------------------------------- onRow  ------------------------------- */
  const onClick = (key: string) => {
    return () => {
      redirect(key);
    };
  };

  /* ------------------------------ Drop ----------------------------- */
  const tableRef = useRef(null);

  const [, drop] = useDrop({
    accept: dragType,
    drop: (items: { draggingRecords: T[]; draggingRecordIds: number[] }): DragEnd<T> => ({
      ...items,
      dropRecord: 0,
      dropId: 0,
    }),
    canDrop: (item, monitor) => {
      return monitor.isOver({ shallow: true });
    },
  });

  drop(tableRef);

  return (
    <div ref={tableRef} className="reusable-table-page__table">
      <TableUI
        {...rest}
        rowSelection={rowSelectionProps ? rowSelection : undefined}
        components={draggable ? components : undefined}
        pagination={{
          current: activePage,
          onChange: onChangePage,
          position: ['bottomCenter'],
          ...pagination,
        }}
        rowKey={rowKey}
        onRow={(record, rowIndex) => {
          const key = rowKey(record, rowIndex).toString();

          if (draggable) {
            return {
              onClick: onClick(key),
              record,
              recordKey: +key,
              dropAction,
            };
          }

          return {
            onClick: onClick(key),
          };
        }}
      />
    </div>
  );
};
