import React, { useState } from 'react';

import { useSwiper } from 'swiper/react/swiper-react';

import './Navigation.scss';

type NavigationProps = {
  lengthImages: number;

  initialSlide?: number;
};

const Navigation = (props: NavigationProps) => {
  const { lengthImages, initialSlide = 0 } = props;
  const [currentIndex, setCurrentIndex] = useState(() => initialSlide);
  const swiper = useSwiper();

  const onNextSlide = () => {
    swiper.slideNext();
    setCurrentIndex((prev) => prev + 1);
  };
  const onPrevSlide = () => {
    swiper.slidePrev();
    setCurrentIndex((prev) => prev - 1);
  };

  return (
    <div className="navigation-carousel">
      <button
        className="navigation-carousel__action navigation-carousel__action-prev"
        disabled={currentIndex === 0}
        onClick={onPrevSlide}
      >
        <span />
      </button>
      <span className="navigation-carousel__pagination">
        {currentIndex + 1} / {lengthImages}
      </span>
      <button
        className="navigation-carousel__action navigation-carousel__action-next"
        disabled={currentIndex === lengthImages - 1}
        onClick={onNextSlide}
      >
        <span />
      </button>
    </div>
  );
};

export { Navigation };
