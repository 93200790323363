import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PreviewFormUserGroup } from '@components/views/Previews/users';
import { useOutletUserGroup } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeUserGroupById } from '@store/actions/user/UserGroup';

function PreviewUserGroup() {
  const { entity } = useOutletUserGroup();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onDelete = () => {
    dispatch(removeUserGroupById([entity.groupsId]))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t(`notifications.Success`),
          description: t('notifications.Deleted', { name: entity.groupCode }),
        });
        navigate(paths.usergroups.default);
      })
      .catch((error) => {
        notificationController.error({
          message: t(`notifications.Error`),
          description: t(`errors.${error}`),
        });
      });
  };

  return <PreviewFormUserGroup entity={entity} onDelete={onDelete} />;
}

export default PreviewUserGroup;
