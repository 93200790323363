import type { ActionFunction, CheckStepItem } from '@components/common/CheckStepItem/_utils';

export const onRemoveCheckStepImage: ActionFunction<{
  step: CheckStepItem;
  uuid: string;
  cb?: (id: number) => void;
}> = ({ step, uuid, cb }, { setState }) => {
  setState((prevState) =>
    prevState.map((s) => {
      if (s.uuid === step.uuid) {
        return {
          ...s,
          imagesList: step.imagesList.filter((image) => {
            if (image.uuid === uuid && cb && typeof image.externalId === 'number') {
              cb(image.externalId);
            }

            return image.uuid !== uuid;
          }),
        };
      }
      return s;
    })
  );
};
