import React from 'react';

import { baseFormatDate } from '@constants/Date';
import cl from 'classnames';
import { addDays, format, isToday, isWeekend, startOfWeek } from 'date-fns';

import type { GetKey } from '@constants/GetKey';

import './WeekMode.scss';

export type WeekCellConfig<T = any> = (record: T) => {
  date: Date;
  id: number | string;
};

export type WeekCellContentRender<T = any> = (record: T, date: Date) => React.ReactNode;

interface WeekModeProps<T = any> {
  value: Date;
  data: T[];
  getCellKey: GetKey<T>;
  cellConfig: WeekCellConfig<T>;
  cellContentRender?: WeekCellContentRender<T>;
  additionalHeaderElementRender?: () => React.ReactNode;
  headerElementRender?: () => React.ReactNode;
  onClickCell?: (record: T) => void;
}

const WeekMode = <T,>(props: WeekModeProps<T>) => {
  const { value, data, cellContentRender, getCellKey, cellConfig, onClickCell } = props;

  const weekStart = startOfWeek(value);

  const defClass = 'weekly-calendar';
  const classNames = cl(defClass);

  const renderCellContent = (day: Date) => {
    return data.map((record) => {
      const key = getCellKey(record);
      const config = cellConfig(record);
      const formattedDate = format(day, baseFormatDate);
      const configDate = format(config.date, baseFormatDate);

      if (formattedDate !== configDate) return null;

      return (
        <div key={key} onClick={() => onClickCell?.(record)} className={`${defClass}-day-cell`}>
          {cellContentRender?.(record, day)}
        </div>
      );
    });
  };

  const renderDays = () => {
    const days = Array(7)
      .fill(0)
      .map((_, i) => addDays(weekStart, i));

    return days.map((day) => {
      return (
        <div
          key={day.toString()}
          className={cl(`${defClass}-day`, {
            [`${defClass}-day--weekend`]: isWeekend(day),
            [`${defClass}-day--today`]: isToday(day),
          })}
        >
          <div className={`${defClass}-day-date`}>
            <span>{format(day, 'EEEEEE')}</span>
            <span>{format(day, baseFormatDate)}</span>
          </div>
          <div className={`${defClass}-day-content`}>{renderCellContent(day)}</div>
        </div>
      );
    });
  };

  return (
    <div className={classNames}>
      <div className={`${defClass}-days`}>{renderDays()}</div>
    </div>
  );
};

export { WeekMode };
