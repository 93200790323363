import { api } from '@services/Axios';

import type { UserModel } from '@model/users/User';
import type {
  CreateUserAuthorizationDto,
  UpdateUserAuthorizationDto,
} from '@model/users/UserAuthorization';
import type { ApiResponse } from '@services/Axios';

const UsAuEndpoint = '/UserAuthorization';

export const UserAuthorizationRepository = {
  async fetchAll(type: number) {
    const response: ApiResponse<UserModel[]> = await api.get(
      `${UsAuEndpoint}/GetAuthorizationList/${type}`
    );
    return response.data;
  },
  async add(dto: CreateUserAuthorizationDto) {
    const response: ApiResponse<UserModel> = await api.post(
      `${UsAuEndpoint}/AddUserAuthorization`,
      dto
    );
    return response.data;
  },
  async fetchById(type: string, userId: string, groupsId: string) {
    const response: ApiResponse<UserModel> = await api.get(
      `${UsAuEndpoint}/GetAuthorizationById/${type}/${userId}/${groupsId}`
    );
    return response.data;
  },
  async update(dto: UpdateUserAuthorizationDto) {
    const response: ApiResponse<UserModel> = await api.post(
      `${UsAuEndpoint}/EditUserAuthorization/`,
      dto
    );
    return response.data;
  },
};
