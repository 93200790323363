import { CheckStepContext } from './CheckStepContext';

import type { CheckStepContextValue, CheckStepProviderProps } from '../types';

export const CheckStepProvider = (props: CheckStepProviderProps) => {
  const {
    children,
    stepKey,
    step,
    actions,
    canActivate,
    isDraggable,
    isVisibleActions,
    type,
    isActiveWorkStep,
  } = props;

  const handleActivate = () => {};

  const handleDeactivate = () => {
    if (step.isActive) {
      actions?.onChangeStepActive?.(step.uuid);
    }
  };

  const value: CheckStepContextValue = {
    canActivate: !!canActivate,
    handleActiveChange: handleActivate,
    onDeactivate: handleDeactivate,
    stepKey,
    step,
    actions,
    isDraggable,
    isVisibleActions,
    type,
    isActiveWorkStep,
  };

  return <CheckStepContext.Provider value={value}>{children}</CheckStepContext.Provider>;
};
