import { createSlice } from '@reduxjs/toolkit';
import {
  addMaAtChecklist,
  addMaAtCheckStep,
  addMaAtMultipleChoiceList,
  getMaAtChecklistById,
  getMaAtChecklists,
  getMaAtChecklistsSummary,
  removeMaAtChecklistByIds,
  removeMaAtCheckStepByIds,
  removeMaAtMultipleChoiceListByIds,
  updateMaAtChecklist,
  updateMaAtCheckStep,
  updateMaAtMultipleChoiceList,
} from '@store/actions/properties/MaAt/Checklist';

import type { LoadingStatus } from '@constants/loadingStatus';
import type {
  FilterMaintenanceCheckListDto,
  MaAtChecklistModel,
  MaAtChecklistSummaryModel,
} from '@model/properties/MaAt/Checklist';

interface MaAtChecklistState {
  MaAtChecklistsList: MaAtChecklistModel[];
  MaAtChecklist: MaAtChecklistModel | null;
  MaAtChecklistStatus: LoadingStatus;
  MaAtChecklistError: string | null;
  filterOptions: FilterMaintenanceCheckListDto;
  MaAtChecklistSettings: {
    totalPage: number;
    totalRecords: number;
  };
  MaAtChecklistsSummaryList: MaAtChecklistSummaryModel[];
}

const initialState: MaAtChecklistState = {
  MaAtChecklistsList: [],
  MaAtChecklistsSummaryList: [],
  MaAtChecklist: null,
  MaAtChecklistStatus: 'idle',
  MaAtChecklistError: null,
  filterOptions: {
    page: 1,
    perPage: 10,
  },
  MaAtChecklistSettings: {
    totalPage: 0,
    totalRecords: 0,
  },
};

const MaAtChecklistSlice = createSlice({
  name: 'MaAtChecklist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET
    builder
      .addCase(getMaAtChecklists.pending, (state) => {
        state.MaAtChecklistError = null;
        state.MaAtChecklistStatus = 'pending';
      })
      .addCase(getMaAtChecklists.fulfilled, (state, { payload }) => {
        if (payload.options) {
          state.filterOptions = {
            ...state.filterOptions,
            ...payload.options,
          };
        }
        state.MaAtChecklistStatus = 'idle';
        state.MaAtChecklistsList = payload.MaAtChecklistResults;
      })
      .addCase(getMaAtChecklists.rejected, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });

    builder
      .addCase(getMaAtChecklistById.pending, (state) => {
        state.MaAtChecklistStatus = 'pending';
        state.MaAtChecklistError = null;
      })
      .addCase(getMaAtChecklistById.fulfilled, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        state.MaAtChecklist = payload[0];
      })
      .addCase(getMaAtChecklistById.rejected, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });
    builder
      .addCase(getMaAtChecklistsSummary.pending, (state) => {
        state.MaAtChecklistStatus = 'pending';
        state.MaAtChecklistError = null;
      })
      .addCase(getMaAtChecklistsSummary.fulfilled, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        state.MaAtChecklistsSummaryList = payload;
      })
      .addCase(getMaAtChecklistsSummary.rejected, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });

    // CREATE
    builder
      .addCase(addMaAtChecklist.pending, (state) => {
        state.MaAtChecklistStatus = 'pending';
        state.MaAtChecklistError = null;
      })
      .addCase(addMaAtChecklist.fulfilled, (state) => {
        state.MaAtChecklistStatus = 'idle';
      })
      .addCase(addMaAtChecklist.rejected, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });

    builder
      .addCase(addMaAtCheckStep.pending, (state) => {
        state.MaAtChecklistStatus = 'pending';
        state.MaAtChecklistError = null;
      })
      .addCase(addMaAtCheckStep.fulfilled, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (state.MaAtChecklist) {
          state.MaAtChecklist = payload;
        }
      })
      .addCase(addMaAtCheckStep.rejected, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });

    builder
      .addCase(addMaAtMultipleChoiceList.pending, (state) => {
        state.MaAtChecklistStatus = 'pending';
        state.MaAtChecklistError = null;
      })
      .addCase(addMaAtMultipleChoiceList.fulfilled, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        state.MaAtChecklist = payload;
      })
      .addCase(addMaAtMultipleChoiceList.rejected, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });

    // UPDATE
    builder
      .addCase(updateMaAtChecklist.pending, (state) => {
        state.MaAtChecklistStatus = 'pending';
        state.MaAtChecklistError = null;
      })
      .addCase(updateMaAtChecklist.fulfilled, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        state.MaAtChecklist = payload;
      })
      .addCase(updateMaAtChecklist.rejected, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });
    builder
      .addCase(updateMaAtCheckStep.pending, (state) => {
        state.MaAtChecklistStatus = 'pending';
        state.MaAtChecklistError = null;
      })
      .addCase(updateMaAtCheckStep.fulfilled, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        state.MaAtChecklist = payload;
      })
      .addCase(updateMaAtCheckStep.rejected, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });
    builder
      .addCase(updateMaAtMultipleChoiceList.pending, (state) => {
        state.MaAtChecklistStatus = 'pending';
        state.MaAtChecklistError = null;
      })
      .addCase(updateMaAtMultipleChoiceList.fulfilled, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        state.MaAtChecklist = payload;
      })
      .addCase(updateMaAtMultipleChoiceList.rejected, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });
    // DELETE
    builder
      .addCase(removeMaAtChecklistByIds.pending, (state) => {
        state.MaAtChecklistStatus = 'pending';
        state.MaAtChecklistError = null;
      })
      .addCase(removeMaAtChecklistByIds.fulfilled, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        state.MaAtChecklistsList = payload;
      })
      .addCase(removeMaAtChecklistByIds.rejected, (state, { payload }) => {
        state.MaAtChecklistStatus = 'idle';
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });
    builder
      .addCase(removeMaAtCheckStepByIds.pending, (state) => {
        state.MaAtChecklistError = null;
      })
      .addCase(removeMaAtCheckStepByIds.fulfilled, (state, { payload }) => {
        state.MaAtChecklist = payload;
      })
      .addCase(removeMaAtCheckStepByIds.rejected, (state, { payload }) => {
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });
    builder
      .addCase(removeMaAtMultipleChoiceListByIds.pending, (state) => {
        state.MaAtChecklistError = null;
      })
      .addCase(removeMaAtMultipleChoiceListByIds.fulfilled, (state, { payload }) => {
        state.MaAtChecklist = payload;
      })
      .addCase(removeMaAtMultipleChoiceListByIds.rejected, (state, { payload }) => {
        if (payload) {
          state.MaAtChecklistError = payload;
        }
      });
  },
});

const MaAtChecklistReducer = MaAtChecklistSlice.reducer;

export { MaAtChecklistReducer };
