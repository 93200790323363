import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { adaptMaintenanceAttrCheckStepsToCheckSteps } from '@adapters';
import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getMaAtChecklistById } from '@store/actions/properties/MaAt/Checklist';
import { selectMaAtChecklist } from '@store/selectors/properties/MaAt/Checklist';

import type { CheckStepValue } from '@constants/checkStepItems';
import type { IOutletMaintenanceChecklist } from '@constants/OutletContextEntityPages';
import type { CreateChecklistCheckStepsDto } from '@model/properties/MaAt/Checklist';

const MaintenanceChecklist = () => {
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();
  const maintenanceChecklist = useAppSelector(selectMaAtChecklist);

  const condition = !maintenanceChecklist || maintenanceChecklist.checkListId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getMaAtChecklistById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialCheckSteps: CheckStepValue[] = adaptMaintenanceAttrCheckStepsToCheckSteps(
    maintenanceChecklist.checkListCheckSteps
  );

  const initialValues: CreateChecklistCheckStepsDto = {
    checklistName: maintenanceChecklist.checklistName,
    description: maintenanceChecklist.description,
    checkSteps: initialCheckSteps,
  };

  const outletContext: IOutletMaintenanceChecklist = {
    initialValues,
    entity: maintenanceChecklist,
  };

  return <Outlet context={outletContext} />;
};

export default MaintenanceChecklist;
