import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToolbarDropdownItem } from '@components/common';
import { useModalState } from '@hooks';
import { PortfolioIcon } from '@icon/icon-components';

import { usePaIToolbarContext } from '../../../PaIToolbarContext';
import { VendorModal } from './VendorModal';

export const VendorDropdown = memo(() => {
  const vendorDropdown = useModalState();

  const { t } = useTranslation();

  const statusModal = useModalState();

  const { vendorsList } = usePaIToolbarContext();

  const [partnerId, setPartnerId] = useState<null | number>(null);

  const handleOpenChange = () => {
    vendorDropdown.actions.onToggle();
  };

  const handleClickItem = (key: string) => {
    setPartnerId(+key);

    vendorDropdown.actions.onClose();

    statusModal.actions.onOpen();
  };

  return (
    <>
      <ToolbarDropdownItem
        isOpen={vendorDropdown.isOpen}
        label={t('titles.Change_With_Name', { name: t('titles.Vendor') })}
        icon={<PortfolioIcon stroke={vendorDropdown.isOpen ? '#74AAEA' : '#fff'} />}
        items={vendorsList}
        fieldNames={{ label: 'name', key: 'partnerId' }}
        onOpenChange={handleOpenChange}
        onClickItem={handleClickItem}
      />
      <VendorModal
        isOpen={statusModal.isOpen}
        onClose={statusModal.actions.onClose}
        partnerId={partnerId}
      />
    </>
  );
});
