import React from 'react';

import { PreviewFormMaintenanceWorkOrders } from '@components/views/Previews/management';
import { useOutletMaintenance } from '@constants/OutletContextEntityPages';

const PreviewMaintenanceWorkOrders = () => {
  const { entity } = useOutletMaintenance();

  return <PreviewFormMaintenanceWorkOrders entity={entity} />;
};

export default PreviewMaintenanceWorkOrders;
