import { api } from '@services/Axios';

import type { CreateLabelDto, LabelModel } from '@model/extra/Label';
import type { ApiResponse } from '@services/Axios';

const LabelUrl = '/Label';

export const LabelRepository = {
  async fetchAll(enumLabelPlace: 1 | 2) {
    const response: ApiResponse<LabelModel[]> = await api.post(`${LabelUrl}/GetLabels`, {
      enumLabelPlace,
    });

    return response.data;
  },

  async fetchAllByPoOrName(switchPoOrName: 1 | 2) {
    const response: ApiResponse<LabelModel[]> = await api.post(`${LabelUrl}/GetLabelsPOOrName`, {
      switchPoOrName, enumLabelPlace: 2
    });

    return response.data;
  },

  async add(dto: CreateLabelDto) {
    const response: ApiResponse<LabelModel> = await api.post(`${LabelUrl}/AddLabel`, dto);

    return response.data;
  },
};
