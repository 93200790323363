import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormUserAuthorization } from '@components/views/Forms/users/UserAuthorization';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addUserAuthorization } from '@store/actions/user/UserAuthorization';

import type { CreateUserAuthorizationDto } from '@model/users/UserAuthorization';
import type { FormInstance } from 'antd';

function CreateUserAuthorization() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = (
    dto: CreateUserAuthorizationDto,
    form?: FormInstance<CreateUserAuthorizationDto>
  ) => {
    dispatch(addUserAuthorization(dto))
      .unwrap()
      .then((response) => {
        ApiNotifications.create(
          `${response.createdAuthorization.firstName} ${response.createdAuthorization.lastName} `
        );
        form?.resetFields();
        navigate(paths.userauth.default);
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <FormUserAuthorization
      onFinish={onFinish}
      header={{
        title: t('titles.New_Item', { name: t('pages_single.Authorization') }),
      }}
    />
  );
}

export default CreateUserAuthorization;
