import React from 'react';
import { Navigate } from 'react-router-dom';

import { VersionApp } from '@components/common';
import { useAppSelector } from '@hooks';
import { SigInRectangleLM, SigInRectangleRB, SigInRectangleRT } from '@icon/icon-components';
import { selectAuthIsAuth } from '@store/selectors/profile/Auth';
import cl from 'classnames';

import { Form } from './Form';
import { Static } from './Static';

import './Signin.scss';

const SignIn = () => {
  const isAuth = useAppSelector(selectAuthIsAuth);

  if (isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <div className="signIn">
      <div className="signIn__wrapper">
        <div className="static">
          <Static />
        </div>
        <div className={'form'}>
          <span className={cl('form_rectangle', 'rectangle_top_right')}>
            <SigInRectangleRT />
          </span>
          <span className={cl('form_rectangle', 'rectangle_bottom_right')}>
            <SigInRectangleRB />
          </span>
          <span className={cl('form_rectangle', 'rectangle_left_middle')}>
            <SigInRectangleLM />
          </span>
          <div className="form__wrapper">
            <Form />
          </div>
        </div>
      </div>
      <VersionApp className="signIn__version" />
    </div>
  );
};

export default SignIn;
