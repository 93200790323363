import React from 'react';

import { FormUser } from '@components/views/Forms/users';
import { CheckAccess } from '@components/wrappers';
import { useOutletUser } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateUser } from '@store/actions/user/User';

import type { CreateUserDto } from '@model/users/User';

function EditUser() {
  const { initialValues, entity } = useOutletUser();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { userAccess } = useGetAccess();

  const onFinish = (dto: CreateUserDto) => {
    dispatch(updateUser({ ...dto, userId: entity.userId }))
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.user.userCode);
        navigate(paths.users.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <CheckAccess hasAccess={userAccess.edit} redirectTo={paths.users.default}>
      <FormUser
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: `${entity.firstName} ${entity.lastName}`,
        }}
      />
    </CheckAccess>
  );
}

export default EditUser;
