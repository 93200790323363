import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getAsAtProperties } from '@store/actions/properties/AsAt/Property';
import { selectAsAtPropertiesList } from '@store/selectors/properties/AsAt/Property';

function EntryAssetProperty() {
  useSetPageInfo(
    {
      defaultPagePath: paths.assetAttribute.tabs.property.default,
      pageTitle: 'pages_single.Asset_Attr',
    },
    {
      copyTabs: true,
    }
  );
  const assetPropertyList = useAppSelector(selectAsAtPropertiesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!assetPropertyList.length) {
      dispatch(getAsAtProperties());
    }
  }, []);

  return <Outlet />;
}

export default EntryAssetProperty;
