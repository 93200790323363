import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import {
  selectAsAtPropertiesList,
  selectAsAtPropertyStatus,
} from '@store/selectors/properties/AsAt/Property';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { AsAtPropertyModel } from '@model/properties/AsAt/Property';

const columns: ColumnsTableType<AsAtPropertyModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'nonCurrAssetCardPropertyCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.nonCurrAssetCardPropertyCode, b.nonCurrAssetCardPropertyCode),
  },

  {
    title: <Translation>{(t) => t('titles.Property_Type')}</Translation>,
    dataIndex: ['nonCurrAssetType', 'name'],
    key: 'propertyType',
    ellipsis: true,
    sorter: (a, b) => compare(a.nonCurrAssetType.name, b.nonCurrAssetType.name),
  },
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

const AssetProperties = () => {
  const {t} = useTranslation();

  const listAssetProperty = useAppSelector(selectAsAtPropertiesList);
  const statusAssetProperty = useAppSelector(selectAsAtPropertyStatus);

  const {assetAttrAccess} = useGetAccess();

  const csvData = useMemo(
    () =>
      listAssetProperty.map((property) => {
        return {
          name: property.name,
          code: property.nonCurrAssetCardPropertyCode,
          propertyType: property.nonCurrAssetType.name,
          isUnique: property.isUnique ? 'Yes' : 'No',
        };
      }),
    [listAssetProperty]
  );

  return (
    <BasicTablePage
      table={{
        loading: statusAssetProperty === 'pending',
        dataSource: listAssetProperty,
        columns: columns,
        rowKey: (record) => record.nonCurrAssetCardPropertyId,
        dataSourceName: t('pages_plural.Asset_Attr_Properties'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', {name: t('pages_single.Asset_Attr_Property')}),
        csvConfig: {
          data: csvData,
          fileName: 'asset-properties.csv',
          headers: [
            {label: 'Code', key: 'code'},
            {label: 'Name', key: 'name'},
            {label: 'Property Type', key: 'propertyType'},
            {label: 'Is Unique', key: 'isUnique'},
          ],
        },
        newButtonProps: {
          disabled: !assetAttrAccess.create
        }
      }}
    />
  );
};

export {AssetProperties};
