import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { useAppSelector } from '@hooks';
import { Input } from '@shared/UI';
import { selectPaIBrandStatus } from '@store/selectors/properties/PaI/Brand';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreatePaIBrandDto } from '@model/properties/PaI/Brand';

const { CaseField, Item, useForm } = CommonForm;

function FormPartAndInventoryBrand(props: CommonFormProps<CreatePaIBrandDto>) {
  const [form] = useForm<CreatePaIBrandDto>();

  const { isModal, ...rest } = props;

  const {t} = useTranslation();

  const statusPartAndInventoryBrand = useAppSelector(selectPaIBrandStatus);

  return (
    <CommonForm 
      form={form} 
      loading={statusPartAndInventoryBrand === 'pending'} 
      isModal={isModal}
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onCancel={rest.header?.onClickCancel}
        /> : null
      }
      name='formPaIBrand'
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="partsAndInventoriesCardBrandCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')}/>
        </Item>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')}/>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export {FormPartAndInventoryBrand};
