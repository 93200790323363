import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormPartAndInventoryName } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletPartAndInventoryName } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updatePaICard } from '@store/actions/properties/PaI/Card';

import type { CreatePaICardDto } from '@model/properties/PaI/Card';

function EditPartAndInventoryName() {
  const { initialValues, entity } = useOutletPartAndInventoryName();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onFinish = async (dto: CreatePaICardDto) => {
    try {
      const response = await dispatch(
        updatePaICard({
          ...dto,
          partsAndInventoriesCardId: entity.partsAndInventoriesCardId,
        })
      ).unwrap();
      ApiNotifications.update(response.particularName.partsAndInventoriesCardName);
      navigate(paths.partAndInventoryAttributes.tabs.name.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={paiAttrAccess.edit} redirectTo={paths.partAndInventoryAttributes.tabs.name.default}>
      <FormPartAndInventoryName
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.partsAndInventoriesCardName,
        }}
      />
    </CheckAccess>
  );
}

export default EditPartAndInventoryName;
