import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BaseModal } from '@shared/UI';
import cl from 'classnames';

import type { BaseModalProps } from '@shared/UI';

import './DeleteModal.scss';

export interface DeleteModalProps extends BaseModalProps {
  customBody?: React.ReactNode;
  deletedName?: React.ReactNode;
}

function DeleteModal(props: DeleteModalProps) {
  const { customBody, deletedName, className, ...rest } = props;

  const { t } = useTranslation();

  const deleteModalClass = cl('modal-delete', className);

  return (
    <BaseModal
      className={deleteModalClass}
      title={t('titles.Delete')}
      okText={t('titles.Delete')}
      size="small"
      closable={false}
      {...rest}
    >
      {customBody ? (
        customBody
      ) : (
        <span>
          <Trans i18nKey="common.Modal_Delete_Description">
            Do you really want to delete the
            <span className="modal-delete-deleted-name">{{ name: deletedName }}</span>?
          </Trans>
        </span>
      )}
    </BaseModal>
  );
}

export { DeleteModal };
