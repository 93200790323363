import React from 'react';

import { PreviewFormPartAndInventoryModel } from '@components/views/Previews/properties';
import { useOutletPartAndInventoryModel } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { removePaIModelById } from '@store/actions/properties/PaI/Model';

import type { CommonPreviewProps } from '@components/common';

function PreviewPartAndInventoryModel() {
  const { entity } = useOutletPartAndInventoryModel();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onDelete = async () => {
    try {
      await dispatch(removePaIModelById([entity.partsAndInventoriesCardModelId])).unwrap();
      ApiNotifications.remove(entity.name);
      navigate(paths.partAndInventoryAttributes.tabs.model.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !paiAttrAccess.edit,
    },
    deleteButtonProps: {
      disabled: !paiAttrAccess.delete,
    },
  };

  return (
    <PreviewFormPartAndInventoryModel entity={entity} onDelete={onDelete} header={headerProps} />
  );
}

export default PreviewPartAndInventoryModel;
