import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector } from '@hooks';
import { compare } from '@shared/utils/functions';
import { selectRoleList, selectRoleStatus } from '@store/selectors/users/Role';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { RoleModel } from '@model/users/Role';

const columns: ColumnsTableType<RoleModel> = [
  {
    title: <Translation>{(t) => t('titles.Role')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
  {
    title: <Translation>{(t) => t('titles.Description')}</Translation>,
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    sorter: (a, b) => compare(a.description, b.description),
  },
];

function Roles() {
  const { t } = useTranslation();

  const listRoles = useAppSelector(selectRoleList);
  const statusRole = useAppSelector(selectRoleStatus);

  return (
    <BasicTablePage
      table={{
        loading: statusRole === 'pending',
        dataSource: listRoles,
        columns,
        rowKey: (record) => record.roleId,
        dataSourceName: t('pages_plural.Roles'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('titles.Role') }),
        csvConfig: {
          data: listRoles,
          fileName: 'roles.csv',
          headers: [
            {
              label: 'Name',
              key: 'name',
            },
            {
              label: 'Description',
              key: 'description',
            },
          ],
        },
      }}
    />
  );
}

export { Roles };
