import React, { useCallback, useState } from 'react';

import { Loader } from '@shared/UI';
import cl from 'classnames';
import { startOfMonth } from 'date-fns';

import { DateSwitcher } from './DateSwitcher';
import { ModeSelect } from './ModeSelect';
import { MonthMode } from './MonthMode';
import { WeekMode } from './WeekMode';

import type { GetKey } from '@constants/GetKey';
import type { CalendarMode} from './ModeSelect';
import type { MonthCellConfig } from './MonthMode';
import type { WeekCellConfig, WeekCellContentRender } from './WeekMode';

import './Calendar.scss';

export interface CalendarProps<T = any> {
  className?: string;
  mode?: CalendarMode;
  addHeaderElements?: () => React.ReactNode;
  headerRender?: () => void;
  onChangeMode?: (mode: CalendarMode) => void;
  onChangeDate?: (date: Date, mode: CalendarMode) => void;
  onClickCell?: (record: T) => void;
  weekCellContentRender?: WeekCellContentRender<T>;
  data: T[];
  getCellKey: GetKey<T>;
  monthCellConfig: MonthCellConfig<T>;
  weekCellConfig: WeekCellConfig<T>;
  loading?: boolean;
}

const Calendar = <T,>(props: CalendarProps<T>) => {
  const {
    className,
    data,
    getCellKey,
    onChangeMode: onChangeModeProps,
    onChangeDate: onChangeDateProps,
    weekCellContentRender,
    addHeaderElements,
    monthCellConfig,
    weekCellConfig,
    loading = false,
    onClickCell,
  } = props;

  const [date, setDate] = useState(startOfMonth(new Date()));
  const [mode, setMode] = useState<CalendarMode>('months');

  const defClass = 'calendar';
  const classNames = cl(defClass, className);

  /* ------------------------------- Actions ------------------------------ */
  const onChangeDate = useCallback(
    (val: Date) => {
      setDate(val);
      onChangeDateProps?.(val, mode);
    },
    [onChangeDateProps, mode]
  );

  const onChangeMode = useCallback(
    (val: CalendarMode) => {
      setMode(() => val);
      onChangeModeProps?.(val);
    },
    [onChangeModeProps]
  );

  /* ------------------------------- Render ------------------------------ */
  const renderCalendar = () => {
    if (mode === 'months') {
      return (
        <MonthMode
          value={date}
          data={data}
          getCellKey={getCellKey}
          cellConfig={monthCellConfig}
          onChange={onChangeDate}
          onClickCellItem={onClickCell}
        />
      );
    }
    if (mode === 'weeks') {
      return (
        <WeekMode
          cellContentRender={weekCellContentRender}
          cellConfig={weekCellConfig}
          getCellKey={getCellKey}
          data={data}
          value={date}
          onClickCell={onClickCell}
        />
      );
    }

    return null;
  };

  return (
    <div className={classNames}>
      <div className={`${defClass}-header`}>
        {addHeaderElements?.()}
        <ModeSelect value={mode} onChange={onChangeMode} />
        <DateSwitcher value={date} mode={mode} onChange={onChangeDate} />
      </div>

      <div className={`${defClass}-body`}>
        <Loader spinning={loading} middle>
          {renderCalendar()}
        </Loader>
      </div>
    </div>
  );
};

export { Calendar };
