import React, { useCallback, useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppDispatch, useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import { updatePaICategoryParentRelations } from '@store/actions/properties/PaI/Category';
import {
  selectPaICategoriesList,
  selectPaICategoriesListWithoutChildren,
  selectPaICategoryStatus,
} from '@store/selectors/properties/PaI/Category';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { DragEnd } from '@components/common/BasicTablePage/BasicTablePage';
import type { PaICategoryModel } from '@model/properties/PaI/Category';

const columns: ColumnsTableType<PaICategoryModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'partsAndInventoriesCategoryCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.partsAndInventoriesCategoryCode, b.partsAndInventoriesCategoryCode),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'partsAndInventoriesCategoryName',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.partsAndInventoriesCategoryName, b.partsAndInventoriesCategoryName),
  },
];

const PartAndInventoryCategories = () => {
  const { t } = useTranslation();

  const listPartAndInventoryCategory = useAppSelector(selectPaICategoriesList);
  const listPartAndInventoryCategoryWithoutChildren = useAppSelector(
    selectPaICategoriesListWithoutChildren
  );
  const statusPartAndInventoryCategory = useAppSelector(selectPaICategoryStatus);

  const dispatch = useAppDispatch();

  const { paiAttrAccess } = useGetAccess();

  const data = useCallback(
    (sites: PaICategoryModel[]) => {
      return sites.map(
        ({ childPartsAndInventoriesCategories, ...site }): PaICategoryModel => ({
          ...site,
          childPartsAndInventoriesCategories: childPartsAndInventoriesCategories?.length
            ? data(childPartsAndInventoriesCategories)
            : null,
        })
      );
    },
    [listPartAndInventoryCategory]
  );

  const list = useMemo(() => data(listPartAndInventoryCategory), [listPartAndInventoryCategory]);

  const updateParent = (data: DragEnd<PaICategoryModel>) => {
    dispatch(
      updatePaICategoryParentRelations({
        fromPartsAndInventoriesCategoryIds: [data.draggingRecordId],
        toPartsAndInventoriesCategoryId: data.dropId,
      })
    );
  };

  const csvData = useMemo(
    () =>
      listPartAndInventoryCategoryWithoutChildren.map((category) => {
        const parentName = listPartAndInventoryCategoryWithoutChildren.find(
          (c) => category.partsAndInventoriesParentCategoryId === c.partsAndInventoriesCategoryId
        )?.partsAndInventoriesCategoryName;

        return {
          code: category.partsAndInventoriesCategoryCode,
          name: category.partsAndInventoriesCategoryName,
          parent: parentName ? parentName : '----',
        };
      }),
    [listPartAndInventoryCategoryWithoutChildren]
  );

  return (
    <BasicTablePage
      table={{
        loading: statusPartAndInventoryCategory === 'pending',
        dataSource: list,
        columns: columns,
        rowKey: (record) => record.partsAndInventoriesCategoryId,
        dataSourceName: t('pages_plural.PaI_Attr_Categories'),
        draggable: true,
        dropAction: updateParent,
        expandable: {
          childrenColumnName: 'childPartsAndInventoriesCategories',
        },
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Category') }),
        csvConfig: {
          data: csvData,
          fileName: 'part-and-inventory-categories',
          headers: [
            { label: 'Code', key: 'code' },
            { label: 'Name', key: 'name' },
            { label: 'Parent', key: 'parent' },
          ],
        },
        newButtonProps: {
          disabled: !paiAttrAccess.create,
        },
      }}
    />
  );
};

export { PartAndInventoryCategories };
