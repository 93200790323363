import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PreviewFormCheckOut } from '@components/views/Previews/properties';
import { useOutletCheckOut } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeCheckOutById } from '@store/actions/properties/CheckOut';

function PreviewCheckOut() {
  const { entity } = useOutletCheckOut();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = () => {
    dispatch(removeCheckOutById([entity.checkFactorId]))
      .unwrap()
      .then(() => {
        notificationController.success({
          description: `${entity.name} deleted`,
        });
        navigate(paths.checkOut.default);
      })
      .catch((err) => {
        if (typeof err === 'string') {
          notificationController.error({
            description: err,
          });
        }
      });
  };

  return <PreviewFormCheckOut entity={entity} onDelete={onDelete} />;
}

export default PreviewCheckOut;
