import React from 'react';
import { useTranslation } from 'react-i18next';

import { checkStepTypes, StepTypesList } from '@components/common';
import {
  DecimalIcon,
  IntegerIcon,
  MultiplyChoice,
  TakePhotoIcon,
  TextIcon,
} from '@icon/icon-components';
import { Inspection } from '@icon/icon-components/Inspection';

import type { ChecklistStepTypeKeys, StepTypeItem } from '@components/common';

type AddNewButtonProps = {
  addNewStep: (stepKey: ChecklistStepTypeKeys) => void;
};

export const AddNewButton = (props: AddNewButtonProps) => {
  const { addNewStep } = props;

  const { t } = useTranslation();

  const handleStepTypeClick = (stepKey: number) => {
    addNewStep(stepKey as ChecklistStepTypeKeys);
  };

  const stepTypesList: StepTypeItem[] = checkStepTypes.map((stepItem) => {
    let baseStepType = {
      typeKey: stepItem.key,
      type: stepItem.type,
    };

    switch (stepItem.key) {
      case 1:
        return {
          ...baseStepType,
          label: t('titles.Text'),
          icon: <TextIcon />,
        };
      case 2:
        return {
          ...baseStepType,
          label: t('titles.Multiply_Choice'),
          icon: <MultiplyChoice />,
        };
      case 3:
        return {
          ...baseStepType,
          label: t('titles.Integer'),
          icon: <IntegerIcon />,
        };
      case 4:
        return {
          ...baseStepType,
          label: t('titles.Decimal'),
          icon: <DecimalIcon />,
        };
      case 5:
        return {
          ...baseStepType,
          label: t('titles.Inspection'),
          icon: <Inspection />,
        };
      case 7:
        return {
          ...baseStepType,
          label: t('titles.Take_Photo'),
          icon: <TakePhotoIcon />,
        };
    }
  });

  return <StepTypesList items={stepTypesList} onClick={handleStepTypeClick} />;
};
