import React from 'react';

import { CommonForm } from '@components/common';
import { Fields } from '@components/views/Forms/managment/AssetParts/PartsList/FIleds';
import { useAppDispatch } from '@hooks';
import { PlusButton } from '@shared/UI';
import { deleteAssetParts } from '@store/actions/management/AssetParts';

import type { CreateAssetPartDto, CreatePartDto, UpdatePartDto } from '@model/management/AssetParts';
import type { FormListFieldData, FormListOperation } from 'antd';

const { List, Item, useFormInstance } = CommonForm;

interface PartsListProps {}

export const PartsList = (props: PartsListProps) => {
  const {} = props;

  const dispatch = useAppDispatch();

  const form = useFormInstance<CreateAssetPartDto>();

  const deleteCreatedPart = (id: number, remove: () => void) => {
    dispatch(deleteAssetParts([id]))
      .unwrap()
      .then(() => remove());
  };

  const handleDelete = (part: CreatePartDto | UpdatePartDto, remove: () => void) => {
    if ('nonCurrAssetsPartsId' in part) {
      deleteCreatedPart(part.nonCurrAssetsPartsId as unknown as number, remove);
    } else {
      remove();
    }
  };

  const renderFields = (fields: FormListFieldData[], remove: FormListOperation['remove']) => {
    return fields.map(({ name, key }) => {
      const current = form.getFieldValue(['mpmodel', name]) as CreatePartDto | UpdatePartDto;
      return (
        <Fields
          key={key}
          name={name}
          onDelete={() => {
            handleDelete(current, () => remove(name));
          }}
        />
      );
    });
  };

  const renderList = (fields: FormListFieldData[], { add, remove }: FormListOperation) => {
    return (
      <div>
        {renderFields(fields, remove)}
        <Item>
          <PlusButton onClick={() => add({ partsAndInventoriesId: undefined })} />
        </Item>
      </div>
    );
  };

  return <List name="mpmodel">{(fields, actions) => renderList(fields, actions)}</List>;
};
