import { api } from '@services/Axios';

import type {
  CreateDepartmentDto,
  DepartmentModel,
  FilterDepartmentDto,
  UpdateDepartmentDto,
  UpdateDepartmentParentDto,
} from '@model/properties/Department';
import type { ApiResponse } from '@services/Axios';

const DepartmentEndpoint = '/Department';

export const DepartmentRepository = {
  async fetchAll() {
    const response: ApiResponse<DepartmentModel[]> = await api.get(
      `${DepartmentEndpoint}/GetDepartmentsList`
    );

    return response.data;
  },

  async fetchByFilter(dto: FilterDepartmentDto) {
    const response: ApiResponse<DepartmentModel[]> = await api.post(
      `${DepartmentEndpoint}/GetDepartmentsListByFilter`,
      dto
    );

    return response.data;
  },

  async add(dto: CreateDepartmentDto) {
    const response: ApiResponse<DepartmentModel> = await api.post(
      `${DepartmentEndpoint}/AddDepartment`,
      dto
    );

    return response.data;
  },

  async fetchById(id: string) {
    const response: ApiResponse<DepartmentModel[]> = await api.get(
      `${DepartmentEndpoint}/GetDepartmentById/${id}`
    );

    return response.data;
  },

  async update(dto: UpdateDepartmentDto) {
    const response: ApiResponse<DepartmentModel> = await api.post(
      `${DepartmentEndpoint}/UpdateDepartment`,
      dto
    );

    return response.data;
  },

  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${DepartmentEndpoint}/RemoveByIdList`, {
      departmentIds: ids,
    });

    return response.data;
  },

  async updateParentRelations(dto: UpdateDepartmentParentDto) {
    const response: ApiResponse<null> = await api.post(
      `${DepartmentEndpoint}/ChangeDepartmentParentReleations`,
      dto
    );

    return response.data;
  },
};
