import React from 'react';

import type { SVGProps } from 'react';

const SigInRectangleLM = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="193"
      height="184"
      viewBox="0 0 193 184"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group" opacity="0.4">
        <path
          id="Vector"
          opacity="0.6"
          d="M43.5769 88.8739L81.9175 38.1582C87.2465 31.1093 97.2807 29.715 104.33 35.0439L155.045 73.3846C162.094 78.7135 163.489 88.7477 158.16 95.7966L119.819 146.512C114.49 153.561 104.456 154.956 97.4069 149.627L46.6912 111.286C39.6423 105.957 38.2479 95.9228 43.5769 88.8739Z"
          fill="url(#paint0_linear_4_9)"
        />
        <path
          id="Vector_2"
          opacity="01.7"
          d="M19.0465 92.3226L78.9648 13.0647C84.1272 6.23609 93.8479 4.88533 100.677 10.0477L179.934 69.9661C186.763 75.1285 188.114 84.8491 182.951 91.6778L123.033 170.936C117.871 177.764 108.15 179.115 101.321 173.953L22.0635 114.034C15.2349 108.872 13.8841 99.1512 19.0465 92.3226Z"
          stroke="url(#paint1_linear_4_9)"
        />
        <path
          id="Vector_3"
          opacity="0.4"
          d="M39.9542 132.235C38.0196 132.235 36.4513 130.667 36.4513 128.732C36.4513 126.797 38.0196 125.229 39.9542 125.229C41.8888 125.229 43.4571 126.797 43.4571 128.732C43.4571 130.667 41.8888 132.235 39.9542 132.235Z"
          fill="#6EE8FD"
        />
        <g id="figure_lights" filter="url(#filter0_f_4_9)">
          <path
            id="Vector_4"
            d="M47.132 137.511C51.8984 133.507 52.516 126.397 48.5115 121.63C44.507 116.864 37.3967 116.246 32.6303 120.251C27.8639 124.255 27.2463 131.366 31.2508 136.132C35.2553 140.898 42.3656 141.516 47.132 137.511Z"
            fill="#6EE8FD"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_4_9"
          x="-1.39105"
          y="87.6089"
          width="82.5444"
          height="82.5444"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_4_9" />
        </filter>
        <linearGradient
          id="paint0_linear_4_9"
          x1="42.7472"
          y1="63.516"
          x2="138.989"
          y2="121.154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#404A8F" />
          <stop offset="0.5" stopColor="#404A8F" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4_9"
          x1="48.2042"
          y1="2.5924"
          x2="125.173"
          y2="10.6261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6FEAFC" />
          <stop offset="0.8" stopColor="#6FEAFC" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { SigInRectangleLM };
