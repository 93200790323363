import { combineReducers } from '@reduxjs/toolkit';
/* Extra reducers */
import { DashboardReducer } from '@store/slices/dashboard';
/* Extra reducers */
import {
  AlertReducer,
  AuthorizationReducer,
  CurrencyReducer,
  DefinitionReducer,
  LabelReducer,
  LocalReducer,
  VersionReducer,
} from '@store/slices/extra';
/* Management reducers */
import {
  AssetPartsReducer,
  AssetReducer,
  CountPlanReducer,
  MaintenanceReducer,
  MaintenanceResultReducer,
  PaIReducer,
} from '@store/slices/management';
/* Profile reducers */
import { AuthReducer, ProfileReducer } from '@store/slices/profile';
/* Property reducers */
import {
  AsAtBrandReducer,
  AsAtCardReducer,
  AsAtCategoryReducer,
  AsAtModelReducer,
  AsAtPropertyReducer,
  AsAtStatusReducer,
  CheckOutReducer,
  ContractReducer,
  CostCenterReducer,
  DepartmentReducer,
  ExitTypeReducer,
  MaAtCategoryReducer,
  MaAtChecklistReducer,
  MaAtRequestReasonReducer,
  PaIBrandReducer,
  PaICardReducer,
  PaICategoryReducer,
  PaIModelReducer,
  PaIPropertyReducer,
  PaIStatusReducer,
  PaIUnitReducer,
  SiteReducer,
  VendorReducer,
} from '@store/slices/properties';
/* User reducers */
import {
  CompanyReducer,
  RoleReducer,
  TitleReducer,
  UserAuthorizationReducer,
  UserGroupReducer,
  UserReducer,
} from '@store/slices/users';

import type { AnyAction} from '@reduxjs/toolkit';

const appReducer = combineReducers({
  DashboardReducer,
  AssetReducer,
  AssetPartsReducer,
  AsAtCardReducer,
  AsAtBrandReducer,
  AsAtCategoryReducer,
  AsAtModelReducer,
  AsAtPropertyReducer,
  AsAtStatusReducer,
  MaintenanceReducer,
  MaintenanceResultReducer,
  MaAtCategoryReducer,
  MaAtChecklistReducer,
  MaAtRequestReasonReducer,
  PaIReducer,
  PaIBrandReducer,
  PaICardReducer,
  PaICategoryReducer,
  PaIModelReducer,
  PaIPropertyReducer,
  PaIUnitReducer,
  PaIStatusReducer,
  CountPlanReducer,
  AlertReducer,
  CurrencyReducer,
  DefinitionReducer,
  LabelReducer,
  LocalReducer,
  ContractReducer,
  CompanyReducer,
  ProfileReducer,
  AuthReducer,
  VendorReducer,
  SiteReducer,
  TitleReducer,
  DepartmentReducer,
  ExitTypeReducer,
  CostCenterReducer,
  CheckOutReducer,
  UserReducer,
  UserGroupReducer,
  UserAuthorizationReducer,
  RoleReducer,
  AuthorizationReducer,
  VersionReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (typeof action.type === 'string' && action.type.includes('Auth/signOut')) {
    return appReducer(undefined, { type: 'Auth/signOut' });
  }

  return appReducer(state, action);
};

export default rootReducer;
