import React from 'react';

import { FormMaintenanceRequestReason } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletMaintenanceRequestReason } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateMaAtRequestReason } from '@store/actions/properties/MaAt/RequestReason';

import type { CreateMaintenanceRequestReasonDto } from '@model/properties/MaAt/RequestReason';

function EditMaintenanceRequestReason() {
  const { initialValues, entity } = useOutletMaintenanceRequestReason();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { maintenanceAttrAccess } = useGetAccess();

  const onFinish = (dto: CreateMaintenanceRequestReasonDto) => {
    dispatch(
      updateMaAtRequestReason({
        ...dto,
        requestReasonId: entity.requestReasonId,
      })
    )
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.MaAtRequestReason.reasonName);
        navigate(paths.maintenanceAttributes.tabs.requestReason.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <CheckAccess hasAccess={maintenanceAttrAccess.edit} redirectTo={paths.maintenanceAttributes.tabs.requestReason.default}>
      <FormMaintenanceRequestReason
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.reasonName,
        }}
      />
    </CheckAccess>
  );
}

export default EditMaintenanceRequestReason;
