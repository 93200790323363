import React from 'react';

import type { SVGProps } from 'react';

const AsetlyBigLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="250" height="76" viewBox="0 0 250 76" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M21.5225 58.268C17.3173 58.268 13.5877 57.3694 10.3337 55.5722C7.12973 53.7236 4.62664 51.2075 2.82442 48.0238C1.0222 44.8402 0.121094 41.2457 0.121094 37.2405C0.121094 33.1325 1.07227 29.5124 2.97461 26.3801C4.87695 23.1964 7.48016 20.706 10.7842 18.9087C14.0883 17.1115 17.8429 16.2129 22.0481 16.2129C24.6513 16.2129 27.0543 16.5467 29.257 17.2142C31.5098 17.8818 33.4371 18.7804 35.0391 19.9101C36.6911 21.0397 37.9927 22.2721 38.9439 23.6072C39.8951 24.9423 40.4207 26.303 40.5209 27.6895L38.4183 27.5354V17.0602H46.2279V57.4978H38.4183V46.8685L40.0703 47.0995C39.9702 48.3319 39.3945 49.6157 38.3432 50.9507C37.2919 52.2345 35.9152 53.4412 34.2131 54.5709C32.511 55.6492 30.5586 56.5478 28.3559 57.2667C26.2032 57.9342 23.9254 58.268 21.5225 58.268ZM23.3998 50.9507C26.4035 50.9507 29.0567 50.3602 31.3596 49.1792C33.6624 47.9468 35.4646 46.3036 36.7662 44.2496C38.0678 42.1443 38.7186 39.8079 38.7186 37.2405C38.7186 34.6216 38.0678 32.2852 36.7662 30.2313C35.4646 28.1773 33.6624 26.5598 31.3596 25.3788C29.0567 24.1977 26.4035 23.6072 23.3998 23.6072C20.3961 23.6072 17.7428 24.1977 15.44 25.3788C13.1371 26.5598 11.3099 28.1773 9.95821 30.2313C8.65661 32.2852 8.00581 34.6216 8.00581 37.2405C8.00581 39.8079 8.65661 42.1443 9.95821 44.2496C11.3099 46.3036 13.1371 47.9468 15.44 49.1792C17.7428 50.3602 20.3961 50.9507 23.3998 50.9507Z" fill="white"/>
    <path d="M74.042 58.268C69.937 58.268 66.1573 57.6775 62.7031 56.4965C59.2989 55.3154 56.4454 53.4155 54.1425 50.7967L59.399 45.8671C61.2513 47.7671 63.3789 49.2049 65.7818 50.1805C68.1848 51.1561 70.8381 51.644 73.7417 51.644C74.8931 51.644 76.0695 51.5669 77.271 51.4129C78.4725 51.2588 79.5738 50.9764 80.5751 50.5656C81.6264 50.1548 82.4524 49.6413 83.0531 49.0251C83.7039 48.3576 84.0293 47.536 84.0293 46.5604C84.0293 44.9685 82.953 43.7875 80.8003 43.0173C79.699 42.6065 78.4224 42.2213 76.9706 41.8619C75.5188 41.4511 73.8918 41.0917 72.0896 40.7836C69.0859 40.1674 66.4326 39.4742 64.1298 38.7039C61.827 37.9337 59.9246 36.9324 58.4228 35.7C57.3715 34.7757 56.5455 33.6717 55.9447 32.3879C55.3941 31.1042 55.1187 29.6151 55.1187 27.9206C55.1187 26.1233 55.5693 24.5315 56.4704 23.1451C57.3715 21.7073 58.623 20.4749 60.225 19.4479C61.877 18.3696 63.7794 17.5737 65.932 17.0602C68.1348 16.4953 70.4876 16.2129 72.9907 16.2129C75.1434 16.2129 77.3711 16.444 79.674 16.9061C81.9768 17.3683 84.1545 18.0872 86.207 19.0628C88.3096 19.9871 90.0618 21.1938 91.4635 22.6829L87.2583 27.9976C86.0068 26.9706 84.555 26.072 82.9029 25.3017C81.301 24.4801 79.6489 23.864 77.9468 23.4532C76.2447 22.991 74.6428 22.7599 73.1409 22.7599C71.9394 22.7599 70.738 22.837 69.5365 22.991C68.335 23.1451 67.2086 23.4275 66.1573 23.8383C65.1561 24.1977 64.3551 24.6855 63.7544 25.3017C63.1536 25.9179 62.8532 26.6882 62.8532 27.6125C62.8532 28.2286 63.0034 28.8192 63.3038 29.384C63.6542 29.8975 64.1298 30.3596 64.7306 30.7704C65.7318 31.3866 67.0835 31.9515 68.7856 32.465C70.4876 32.9271 72.415 33.3636 74.5677 33.7744C77.2209 34.2879 79.674 34.9041 81.9267 35.623C84.2296 36.2905 86.182 37.1634 87.784 38.2418C89.0355 39.1147 89.9867 40.1417 90.6375 41.3227C91.3383 42.5038 91.6888 43.8902 91.6888 45.482C91.6888 48.1522 90.8878 50.4629 89.2858 52.4142C87.6838 54.3141 85.5562 55.7776 82.9029 56.8046C80.2997 57.7802 77.3461 58.268 74.042 58.268Z" fill="white"/>
    <path d="M119.519 58.268C114.763 58.268 110.508 57.3951 106.754 55.6492C103.049 53.852 100.145 51.4129 98.0429 48.3319C95.9403 45.1996 94.889 41.6565 94.889 37.7026C94.889 34.3649 95.5148 31.3866 96.7663 28.7678C98.0178 26.149 99.7199 23.9153 101.873 22.0667C104.075 20.1668 106.628 18.7033 109.532 17.6764C112.486 16.6494 115.589 16.1359 118.843 16.1359C121.897 16.1359 124.751 16.6237 127.404 17.5993C130.057 18.575 132.385 19.9357 134.388 21.6816C136.39 23.4275 137.942 25.5071 139.043 27.9206C140.145 30.2826 140.67 32.9014 140.62 35.777L140.545 39.6282H101.572L99.8451 33.3122H133.862L132.736 34.1595V32.3109C132.485 30.565 131.684 29.0246 130.333 27.6895C128.981 26.3544 127.304 25.3274 125.301 24.6085C123.299 23.8383 121.146 23.4532 118.843 23.4532C115.69 23.4532 112.886 23.941 110.433 24.9166C107.98 25.8409 106.053 27.3044 104.651 29.307C103.249 31.3096 102.548 33.9284 102.548 37.1634C102.548 39.9363 103.324 42.3754 104.876 44.4807C106.428 46.5347 108.556 48.1265 111.259 49.2562C114.013 50.3859 117.191 50.9507 120.796 50.9507C124.1 50.9507 126.903 50.4886 129.206 49.5643C131.509 48.5887 133.537 47.4846 135.289 46.2523L138.818 52.1061C137.416 53.2358 135.664 54.2628 133.562 55.1871C131.509 56.1113 129.281 56.8559 126.878 57.4208C124.475 57.9856 122.022 58.268 119.519 58.268Z" fill="white"/>
    <path d="M155.912 57.4978V5.27549H163.647V57.4978H155.912ZM144.874 24.7626V17.0602H176.713V24.7626H144.874Z" fill="white"/>
    <path d="M186.112 57.4978V0.5H193.846V57.4978H186.112Z" fill="white"/>
    <path d="M213.157 75.2133L222.844 56.4194V62.5043L199.715 17.0602H208.801L220.666 41.9389C221.968 44.3523 223.069 46.586 223.97 48.64C224.921 50.694 225.572 52.5682 225.922 54.2628L224.571 54.4168C225.172 52.7736 225.897 50.8994 226.748 48.7941C227.65 46.6374 228.701 44.2753 229.902 41.7079L240.866 17.0602H249.877L229.827 57.8059L221.417 75.2133H213.157Z" fill="white"/>
  </svg>
);

export { AsetlyBigLogo };
