import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Input, Select } from '@shared/UI';
import { replaceStrToNum } from '@shared/utils/functions';
import { getAssetAllRecords } from '@store/actions/management/Asset';
import { getPaIsTypeName } from '@store/actions/management/PaI';
import { getExitTypes } from '@store/actions/properties/ExitType';
//import { getPaICards } from '@store/actions/properties/PaI/Card';
import { selectAssetsAllRecords, selectAssetStatus } from '@store/selectors/management/Asset';
import { selectPaIsTypeNameListAllRecords } from '@store/selectors/management/PaI';
import { selectExitTypesList, selectExitTypeStatus } from '@store/selectors/properties/ExitType';
import { selectPaICardStatus } from '@store/selectors/properties/PaI/Card';
import { selectSiteStatus } from '@store/selectors/properties/Site';
import { Col, Row } from 'antd';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { ConsumePaIDto } from '@model/management/PaI';

const { CaseField, Item, useForm, useWatch } = CommonForm;

function FormPartAndInventoryConsume(props: CommonFormProps<ConsumePaIDto>) {
  const [form] = useForm<ConsumePaIDto>();

  const isExitType = !!useWatch('exitTypeId', form);
  const isUsedFor = !!useWatch('nonCurrAssetId', form);  

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [availableQuantity, setAvailableQuantity] = useState<number>(0);

  const paiCardId = useWatch('partsAndInventoriesId', form);
  const quantity = useWatch('quantity', form);
  
  const statusPartAndInventory = useAppSelector(selectSiteStatus);

  //const listPartAndInventoryName = useAppSelector(selectPaICardsList);

  const statusPartAndInventoryName = useAppSelector(selectPaICardStatus);

  const listExitType = useAppSelector(selectExitTypesList);
  const statusExitType = useAppSelector(selectExitTypeStatus);

  const listAssetAllRecords = useAppSelector(selectAssetsAllRecords);
  const statusAsset = useAppSelector(selectAssetStatus);

  const listPAITypeNameAllRecords = useAppSelector(selectPaIsTypeNameListAllRecords);
  
  const quantityTotal = useMemo(
    () =>
      listPAITypeNameAllRecords
        .filter((item) => item.partsAndInventoriesId === paiCardId)
        .reduce((acc, current) => {
          if (current.sumQuantity) {
            const sum = acc + current.sumQuantity;
            return sum;
          }
          return acc;
        }, 0),
    [paiCardId]
  );

  useEffect(() => {
  
    if (!listExitType.length) {
      dispatch(getExitTypes());
    }
    if (!listAssetAllRecords.length) {
      dispatch(getAssetAllRecords());
    }
      dispatch(getPaIsTypeName());
  }, []);

  useEffect(() => {
    setAvailableQuantity((prevValue) => {
      if (quantity && quantityTotal >= 0) {
        return quantityTotal - quantity;
      }
      if (quantityTotal >= 0) {
        return quantityTotal;
      }
      return prevValue;
    });
  }, [quantityTotal, quantity, paiCardId]);

  const assetNameLabels = useMemo(
    () =>
      listAssetAllRecords.reduce((acc, asset) => {
        const checkName = acc.includes(asset.nonCurrAssetCard.name);

        if (!checkName) {
          return [...acc, asset.nonCurrAssetCard.name];
        }

        return acc;
      }, [] as string[]),
    [listAssetAllRecords]
  );

  const assetOptions = useMemo(() => {
    return assetNameLabels.map((name) => ({
      label: name,
      options: listAssetAllRecords.reduce((acc, itemAsset) => {
        if (itemAsset.nonCurrAssetCard.name === name) {
          return [
            ...acc,
            {
              value: itemAsset.nonCurrAssetId,
              label: itemAsset.barcode,
            },
          ];
        }

        return acc;
      }, [] as { value: number; label: string }[]),
    }));
  }, [listAssetAllRecords, assetNameLabels]);

  return (
    <CommonForm form={form} loading={statusPartAndInventory === 'pending'} {...props}>
      <CaseField>
        <Item
          label={t('titles.Name')}
          name="partsAndInventoriesId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            loading={statusPartAndInventoryName === 'pending'}
            options={listPAITypeNameAllRecords}
            placeholder={t('titles.Name')}
            fieldNames={{
              label: 'partsAndInventoriesCardName',
              value: 'partsAndInventoriesId',
            }}
          />
        </Item>
        <Row align="middle" justify="space-between">
          <Col span={14}>
            <Item
              label={t('titles.Quantity')}
              name="quantity"
              rules={[
                {
                  required: true,
                },
                {
                  validator(_, value) {
                    if (!paiCardId) {
                      return Promise.reject(new Error('Choose Name'));
                    }
                    if (value > quantityTotal) {
                      return Promise.reject(new Error('Enter available quantity'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              normalize={(value) => {
                return replaceStrToNum(value);
              }}
            >
              <Input placeholder="0" />
            </Item>
          </Col>
          <Col span={6}>
            <span style={{ color: 'var(--disabled)', fontSize: '1.7rem' }}>
              / {availableQuantity >= 0 ? availableQuantity : 0}
            </span>
          </Col>
        </Row>
        <Item 
          label={t('titles.Used_For')} 
          name="nonCurrAssetId" 
          disabledItem={isExitType}
        >
          <Select
            loading={statusAsset === 'pending'}
            options={assetOptions}
            placeholder={t('titles.Choose_Name', { name: t('titles.Used_For') })}
            disabled={isExitType}
          />
        </Item>
        <Item
          label={t('pages_single.Exit_Type')}
          name="exitTypeId"
          disabledItem={isUsedFor}
        >
          <Select
            loading={statusExitType === 'pending'}
            options={listExitType}
            placeholder={t('titles.Choose_Name', { name: t('pages_single.Exit_Type') })}
            disabled={isUsedFor}
            fieldNames={{
              label: 'name',
              value: 'checkFactorId',
            }}
          />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormPartAndInventoryConsume };
