import type { RootState } from '@store/rootReducer';

export const selectMaAtRequestReasonState = (state: RootState) => state.MaAtRequestReasonReducer;

export const selectMaAtRequestReasonsList = (state: RootState) =>
  selectMaAtRequestReasonState(state).MaAtRequestReasonsList;

export const selectMaAtRequestReason = (state: RootState) =>
  selectMaAtRequestReasonState(state).MaAtRequestReason;

export const selectMaAtRequestReasonStatus = (state: RootState) =>
  selectMaAtRequestReasonState(state).MaAtRequestReasonStatus;

export const selectMaAtRequestReasonError = (state: RootState) =>
  selectMaAtRequestReasonState(state).MaAtRequestReasonError;
