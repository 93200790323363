import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '@components/providers/AuthProvider';
import { useAppSelector } from '@hooks';
import { paths } from '@routes/paths';
import { BaseForm, Button } from '@shared/UI';
import { selectErrorProfile, selectProfileLoading } from '@store/selectors/profile/Profile';
import { Input } from 'antd';

import type { SignInDto } from '@model/profile/Auth/dto/SignIn.dto';


const FormAction = () => {
  const [form] = BaseForm.useForm<SignInDto>();
  const { t } = useTranslation();
  const { signIn } = useAuth();
  const profileStatus = useAppSelector(selectProfileLoading);
  const profileError = useAppSelector(selectErrorProfile);
  const location = useLocation();
  const navigate = useNavigate();

  const from = (location?.state as { from?: { pathname?: string } })?.from?.pathname || '/';

  const onFinish = (dto: SignInDto) => {
    signIn(dto, () => {
      navigate(from, { replace: true });
    });
  };

  const errEmailRequired = t('errors.Is_Required', { name: t('titles.Email_Address') });
  const errPassRequired = t('errors.Is_Required', { name: t('titles.Password') });

  return (
    <BaseForm form={form} className={'actions'} onFinish={onFinish}>
      <BaseForm.Item
        rules={[
          { required: true, message: errEmailRequired },
          {
            pattern: new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/),
            message: 'Email address is not valid ',
          },
        ]}
        label={t('titles.Email_Address')}
        name="userMail"
        validateStatus={profileError ? 'error' : undefined}
        help={profileError ? t('errors.Email_Or_Password') : undefined}
      >
        <Input className={'input'} />
      </BaseForm.Item>
      <BaseForm.Item
        rules={[{ required: true, message: errPassRequired }]}
        label={t('titles.Password')}
        name="password"
        validateStatus={profileError ? 'error' : undefined}
        help={profileError ? t('errors.Email_Or_Password') : undefined}
      >
        <Input.Password className={'input'} />
      </BaseForm.Item>
      <div className={'forgot'}>
        <Link to={paths.login.recover}>
          <span className="label">{t('titles.Forgot_Password')}?</span>
        </Link>
      </div>
      <BaseForm.Item>
        <Button
          loading={profileStatus}
          htmlType="submit"
          style={{ margin: '0 auto' }}
          className={'button'}
        >
          {t('titles.Sign_In')}
        </Button>
      </BaseForm.Item>
    </BaseForm>
  );
};

export { FormAction };
