import React from 'react';

import type { SVGProps } from 'react';

const FilterIconFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22" 
    height="25" 
    viewBox="0 0 22 25" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path 
        d="M14 24.5L8 20V14.88L0 5.88V3.5C0 2.70435 0.31607 1.94129 0.87868 1.37868C1.44129 0.81607 2.20435 0.5 3 0.5L19 0.5C19.7956 0.5 20.5587 0.81607 21.1213 1.37868C21.6839 1.94129 22 2.70435 22 3.5V5.88L14 14.88V24.5Z" fill="#032F5C"
    />

  </svg>
);

export { FilterIconFilled };