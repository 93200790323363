import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, FilePreview } from '@components/common';
import { defaultFormatDate } from '@constants/Date';
import { useAppSelector } from '@hooks';
import { Divider } from '@shared/UI';
import {
  selectContractStatus,
  selectCurrentContractFiles,
} from '@store/selectors/properties/Contract';
import { Col, Row } from 'antd';
import moment from 'moment';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { ContractModel } from '@model/properties/Contract';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormContract(props: CommonPreviewProps<ContractModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusContract = useAppSelector(selectContractStatus);
  const particularContractFiles = useAppSelector(selectCurrentContractFiles);

  return (
    <CommonForm
      isPreview
      loading={statusContract === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField title={t('titles.Summary')}>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.contractCode}</PreviewField>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Contract_No')}>
          <PreviewField>{entity.no}</PreviewField>
        </Item>
        <Item label={t('titles.Agreement_Price')}>
          <Row gutter={[10, 0]} justify="space-between">
            <Col span={16}>
              <PreviewField>{entity.price}</PreviewField>
            </Col>
            <Col span={8}>
              <PreviewField>{entity.currency.name}</PreviewField>
            </Col>
          </Row>
        </Item>
      </CaseField>
      <CaseField>
        <Item label={t('titles.Vendor')}>
          <PreviewField>{entity.partner.name}</PreviewField>
        </Item>
        <Row gutter={[10, 0]} justify="space-between">
          <Col md={10} xs={24}>
            <Item label={t('titles.Start_Date')}>
              <PreviewField inputTextCenter>
                {moment(entity.startDate).format(defaultFormatDate)}
              </PreviewField>
            </Item>
          </Col>
          <Col md={10} xs={24}>
            <Item label={t('titles.End_Date')}>
              <PreviewField inputTextCenter>
                {moment(entity.endDate).format(defaultFormatDate)}
              </PreviewField>
            </Item>
          </Col>
        </Row>
        <Item label={t('titles.Description')}>
          <PreviewField typeField="textarea">{entity.description}</PreviewField>
        </Item>
      </CaseField>
      <Divider />
      <CaseField md={24} title={t('titles.Documents')}>
        <Item>
          {particularContractFiles &&
            particularContractFiles.map((file) => {
              return (
                <FilePreview
                  hiddenDelete
                  fileName={`${file.originalFileNameForDisplay}.${file.fileType}`}
                  key={file.contractFilesId}
                  href={file.filePath}
                />
              );
            })}
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormContract };
