import React, { useState } from 'react';

import { Popover } from '@shared/UI';
import cl from 'classnames';

import './ColorPicker.scss';

type ColorOption = {
  value: string;
  label: string;
  [key: string]: any;
};

export interface ColorPickerProps {
  colors: ColorOption[];
  withColorLabel?: boolean;
  defaultValue?: string;
  onChange?: (color: string) => void;
  readonly?: boolean;

  value?: string;
}

const ColorPicker = (props: ColorPickerProps) => {
  const { colors, withColorLabel = true, readonly = false, onChange, value } = props;

  const [isOpen, setIsOpen] = useState(false);

  const [activeColor, setActiveColor] = useState<ColorOption>(() => {
    if (value) {
      const currentColor = colors.find((color) => color.value === value);
      if (currentColor) {
        return currentColor;
      }
    }
    return colors[0];
  });

  const itemPopover = colors.map((color) => (
    <li key={color.value} className="ui-color-picker-list-item" onClick={handleClickColor(color)}>
      <span
        className="ui-color-picker-list-item-mark"
        style={{
          backgroundColor: color.value,
        }}
      />
      {withColorLabel && <span>{color.label}</span>}
    </li>
  ));

  const contentPopover = <ul className="ui-color-picker-list">{itemPopover}</ul>;

  function handleClickColor(color: ColorOption) {
    return () => {
      setActiveColor(color);
      onChange?.(color.value);
      setIsOpen(false);
    };
  }

  function handleOpen(open: boolean) {
    if (readonly) {
      setIsOpen(false);
      return;
    }
    setIsOpen(open);
  }

  return (
    <Popover
      className="ui-color-picker"
      content={contentPopover}
      open={isOpen}
      onOpenChange={handleOpen}
      trigger="click"
      placement="bottomLeft"
    >
      <button
        className={cl('ui-color-picker-button', {
          'ui-color-picker-button--active': isOpen,
        })}
        type="button"
      >
        <span
          className={'ui-color-picker-color-mark'}
          style={{
            backgroundColor: activeColor.value,
          }}
        />
        {withColorLabel && (
          <span className={'ui-color-picker-color-label'}>{activeColor.label}</span>
        )}
      </button>
    </Popover>
  );
};

export { ColorPicker };
