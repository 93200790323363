import React from "react"
import { useTranslation } from "react-i18next";

import { FormLabel } from "@components/views/Forms/extra";
import { 
  FormAssetBrand, 
  FormAssetCategory, 
  FormAssetModel, 
  FormAssetName, 
  FormAssetProperty, 
  FormAssetStatus, 
  FormContract, 
  FormCostCenter,
  FormSite,
  FormVendor,
} from '@components/views/Forms/properties';
import { CreateableEntityModal } from "@shared/UI"

import type { UseModalStateReturn } from "@hooks";

interface CreateableEntityModalProps {
  assetCategoryModal: UseModalStateReturn;
  assetCardModal: UseModalStateReturn;
  assetModelModal: UseModalStateReturn;
  assetBrandModal: UseModalStateReturn;
  assetStatusModal: UseModalStateReturn;
  assetPropertyModal: UseModalStateReturn;
  labelModal: UseModalStateReturn;
  vendorModal: UseModalStateReturn;
  siteModal: UseModalStateReturn;
  contractModal: UseModalStateReturn;
  costCenterModal: UseModalStateReturn;
}

const CreateableEntityModals = (props: CreateableEntityModalProps) => {
  const { 
    assetCategoryModal, 
    assetBrandModal, 
    assetCardModal, 
    assetModelModal, 
    assetPropertyModal, 
    assetStatusModal, 
    labelModal, 
    vendorModal, 
    siteModal, 
    contractModal, 
    costCenterModal 
  } = props;

  const { t } = useTranslation();

    return (
      <>
        <CreateableEntityModal
          open={assetCategoryModal.isOpen}
          onCancel={assetCategoryModal.actions.onClose}
          form={
            <FormAssetCategory
              isModal
              header={{
                onClickCancel: assetCategoryModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Category') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={assetBrandModal.isOpen}
          onCancel={assetBrandModal.actions.onClose}
          form={
            <FormAssetBrand
              isModal
              header={{
                onClickCancel: assetBrandModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Brand') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={assetModelModal.isOpen}
          onCancel={assetModelModal.actions.onClose}
          form={
            <FormAssetModel
              isModal
              header={{
                onClickCancel: assetModelModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Model') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={assetCardModal.isOpen}
          onCancel={assetCardModal.actions.onClose}
          form={
            <FormAssetName
              isModal
              header={{
                onClickCancel: assetCardModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Name') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={assetPropertyModal.isOpen}
          onCancel={assetPropertyModal.actions.onClose}
          form={
            <FormAssetProperty
              isModal
              header={{
                onClickCancel: assetPropertyModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Property') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={assetStatusModal.isOpen}
          onCancel={assetStatusModal.actions.onClose}
          form={
            <FormAssetStatus
              isModal
              header={{
                onClickCancel: assetStatusModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Status') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={costCenterModal.isOpen}
          onCancel={costCenterModal.actions.onClose}
          form={
            <FormCostCenter
              isModal
              header={{
                onClickCancel: costCenterModal.actions.onClose,    
                title: t('titles.New_Item', { name: t('pages_single.Cost_Center') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
        <CreateableEntityModal
          open={vendorModal.isOpen}
          onCancel={vendorModal.actions.onClose}
          form={
            <FormVendor
              isModal
              header={{
                onClickCancel: vendorModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Vendor') }),
              }}
            />
          }
        />
        <CreateableEntityModal
          open={siteModal.isOpen}
          onCancel={siteModal.actions.onClose}
          form={
            <FormSite
              isModal
              header={{
                onClickCancel: siteModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Site') }),
              }}
            />
          }
        />
        <CreateableEntityModal
          open={contractModal.isOpen}
          onCancel={contractModal.actions.onClose}
          form={
            <FormContract
              isModal
              header={{
                onClickCancel: contractModal.actions.onClose,
                title: t('titles.New_Item', { name: t('pages_single.Contract') }),
              }}
            />
          }
        />
        <CreateableEntityModal
          open={labelModal.isOpen}
          onCancel={labelModal.actions.onClose}
          form={
            <FormLabel
              isModal
              header={{
                onClickCancel: labelModal.actions.onClose,
                title: t('titles.New_Item', { name: t('titles.Label') }),
                hiddenCancelButton: true,
                hiddenSaveButton: true,
              }}
            />
          }
        />
      </>
    )
}

export { CreateableEntityModals };