import React from 'react';
import { useTranslation } from 'react-i18next';

import { AsetlyBigLogo } from '@icon/icon-components';

import { CreatePasswordForm } from './Form';

import './CreatePassword.scss';

const CreatePassword = () => {
  const { t } = useTranslation();

  return (
    <div className="create-pass_container">
      <AsetlyBigLogo />
      <h4>{t('titles.Create_Password')}</h4>
      <div className="create-pass__description">
        <p>{t('titles.Confirm_Password_Details')}:</p>
        <ul>
          <li>{t('titles.Latin_Alphabet')}</li>
          <li>{t('titles.Password_Characters', { number: 6 })}</li>
        </ul>
      </div>

      <CreatePasswordForm />

    </div>
  );
};

export { CreatePassword };
