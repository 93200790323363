import { IterationValue } from '@constants/iterationValue';

export function getIterationByOccurrence(occurrenceId: number) {
  switch (occurrenceId) {
    case 1:
      return IterationValue.EVERY_DAY;
    case 2:
      return IterationValue.EVERY_WEEK;
    case 3:
      return IterationValue.EVERY_MONTH;
    default:
      return IterationValue.NEVER;
  }
}
