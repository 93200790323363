import {createAsyncThunk} from '@reduxjs/toolkit';
import {ContractRepository} from '@repositories';
import {identifyError} from '@shared/utils/functions';

import type {
  ContractModel,
  CreateContractDto,
  FilterContractDto,
  UpdateContractDto,
} from '@model/properties/Contract';
import type {ContractFileModel} from '@model/properties/Contract/ContractFile.model';
import type {ApiError} from '@services/Axios';
import type {RootState} from '@store/rootReducer';

// region --- Get Actions

export const getContracts = createAsyncThunk<ContractModel[], void, { rejectValue: string }>(
  'Contract/getContracts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await ContractRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getContractsByFilter = createAsyncThunk<
  { contracts: ContractModel[]; departmentFilters: FilterContractDto },
  FilterContractDto,
  { rejectValue: string }
>('Contract/getContractsByFilter', async (dto, { rejectWithValue }) => {
  try {
    const response = await ContractRepository.fetchAllByFilter(dto);

    return { contracts: response.resultObject, departmentFilters: dto };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getContractById = createAsyncThunk<ContractModel, string, { rejectValue: string }>(
  'Contract/getContractById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await ContractRepository.fetchById(id);

      return { ...response.resultObject };
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getContractFilesById = createAsyncThunk<
  ContractFileModel[],
  number | string,
  { rejectValue: string }
>('Contract/ContractFilesById', async (id, { rejectWithValue }) => {
  try {
    const response = await ContractRepository.fetchContractFilesById(`${id}`);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addContract = createAsyncThunk<
  ContractModel,
  CreateContractDto,
  { rejectValue: string }
>('Contract/addContract', async (dto, { rejectWithValue }) => {
  try {
    const response = await ContractRepository.add(dto);

    // const formData = await createFilesFormData(dto.files);

    // const id = response.resultObject.contractId;

    // if (formData) {
    //   await ContractRepository.addContractFiles(formData, id);
    // }

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (error.data) {
      return rejectWithValue(error.data.languageKeyword);
    }
    if (err.response && err.response.status === 400 && err.response.data.languageKeyword) {
      return rejectWithValue(err.response.data.languageKeyword);
    }

    throw err;
  }
});

export const addContractFiles = createAsyncThunk<
  { files: ContractFileModel[]; isEdit?: boolean },
  { files?: File[]; contractId: number; isEdit?: boolean },
  { rejectValue: string }
>('Contract/addContractFiles', async (dto, { rejectWithValue }) => {
  try {
    if (!dto.files || !dto.files.length) {
      throw new Error('No files to upload');
    }

    const formData = new FormData();
    dto.files.forEach((file) => {
      formData.append('formFiles', file);
    });

    await ContractRepository.addContractFiles(formData, dto.contractId);
    const response = await ContractRepository.fetchContractFilesById(`${dto.contractId}`);

    return { files: response.resultObject, isEdit: dto.isEdit };
  } catch (err: any) {
    const error: ApiError = err;
    if (error.data) {
      return rejectWithValue(error.data.languageKeyword);
    }
    if (err.response && err.response.status === 400 && err.response.data.languageKeyword) {
      return rejectWithValue(err.response.data.languageKeyword);
    }

    throw err;
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateContract = createAsyncThunk<
  {
    contractsList: ContractModel[];
    contract: ContractModel;
  },
  UpdateContractDto,
  { rejectValue: string }
>('Contract/updateContract', async (dto, { rejectWithValue }) => {
  try {
    const response = await ContractRepository.update(dto);

    const responseList = await ContractRepository.fetchAll();

    return {
      contractsList: responseList.resultObject,
      contract: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeContractById = createAsyncThunk<
  ContractModel[],
  number[],
  { rejectValue: string }
>('Contract/removeContract', async (ids, { rejectWithValue }) => {
  try {
    await ContractRepository.removeById(ids);
    const response = await ContractRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const removeContractFileById = createAsyncThunk<
  Array<ContractFileModel> | null,
  number | string,
  { rejectValue: string; state: RootState }
>('Contract/removeContractFileById', async (id, { rejectWithValue, getState }) => {
  try {
    const {} = await ContractRepository.removeFileById(id);

    const { contractFiles } = getState().ContractReducer;

    return contractFiles
      ? contractFiles.filter((file) => file.contractFilesId !== Number(id))
      : null;
  } catch (err) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

// endregion --- Remove Actions

// TODO: Need to change the logic of this function
export const downloadContractFileById = createAsyncThunk<
  ContractFileModel[],
  number,
  { rejectValue: string; state: RootState }
>('Contract/downloadContractFileById', async (id, { rejectWithValue, getState }) => {
  try {
    const currentFiles: ContractFileModel[] | null = getState().ContractReducer.contractFiles;
    const includedFile = currentFiles?.find((file) => file?.contractFilesId === id);

    if (currentFiles && includedFile && includedFile.blobDataFiles !== null) {
      return currentFiles;
    }
    const responseFile = await ContractRepository.downloadContractFileById(id);

    const filteredFiles = currentFiles
      ? currentFiles.filter((file) => file.contractFilesId !== id)
      : null;

    return filteredFiles
      ? [...filteredFiles, responseFile.resultObject]
      : [responseFile.resultObject];
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});
