import type { RootState } from '@store/rootReducer';

export const selectLabelState = (state: RootState) => state.LabelReducer;

export const selectLabelsList = (state: RootState) => selectLabelState(state).labelsList;

export const selectLabel = (state: RootState) => selectLabelState(state).label;

export const selectLabelStatus = (state: RootState) => selectLabelState(state).labelStatus;

export const selectLabelError = (state: RootState) => selectLabelState(state).labelError;

export const selectLabelTypesList = (state: RootState) => selectLabelState(state).labelListType;
