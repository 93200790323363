import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { Input, Select, TextArea } from '@shared/UI';
import { replaceStrToNum } from '@shared/utils/functions';

import type { CostCenterModel } from '@model/properties/CostCenter';
import type { SelectFieldNames } from '@shared/utils/types';

interface ExpandedMiddleSectionProps {
  costCentersList: Array<CostCenterModel>;
  
  onCostCenterOpenModal: () => void;
}

const { CaseField, Item } = CommonForm;

// region --- Field names

const costCenterNames: SelectFieldNames<CostCenterModel> = {
  label: 'name',
  value: 'costCenterId',
};

// endregion --- Field names

export const ExpandedMiddleSection = (props: ExpandedMiddleSectionProps) => {
  const { costCentersList, onCostCenterOpenModal } = props;

  const { t } = useTranslation();

  return (
    <CaseField hiddenTitle md={24} lg={7}>
      <Item label={t('titles.Invoice_Amount')} name="invoiceAmount" normalize={replaceStrToNum}>
        <Input placeholder={t('titles.Invoice_Amount')} type="tel" />
      </Item>

      <Item label={t('pages_single.Cost_Center')} name="costCenterId">
        <Select
          placeholder={t('titles.Choose_Name', {
            name: t('pages_single.Cost_Center'),
          })}
          options={costCentersList}
          fieldNames={costCenterNames}
          isCreatable
          creatableAction={onCostCenterOpenModal}
        />
      </Item>

      <Item label={t('titles.Description')} name="description">
        <TextArea placeholder={t('titles.Enter_Descr')} />
      </Item>
    </CaseField>
  );
};
