class LocalStorageInstance {
  private readonly token_key: string;

  private readonly list_view_key: string;

  constructor() {
    this.token_key = 'auth_token';
    this.list_view_key = 'list_view';
  }

  /**
   * It takes a key and a value, converts the value to a string, and stores it in localStorage
   * @param {string} key - The key to store the value under.
   * @param {any} value - The value to be stored in the local storage.
   */
  setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify({ value }));
  }

  /**
   * It takes a key, gets the value from localStorage, parses it, and returns the value
   * @param {string} key - The key of the item to get.
   * @returns The value of the key in localStorage.
   */
  getItem<T>(key: string): T | null {
    const data: string | null = localStorage.getItem(key);
    if (data !== null) {
      return JSON.parse(data).value;
    }
    return null;
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * It clears the local storage.
   */
  clear() {
    localStorage.clear();
  }

  handleAuthToken(): string | null;
  handleAuthToken(value: string): void;
  /**
   * If the value is undefined, return the value of the token_key, otherwise set the token_key to the value
   * @param {string} [value] - The value to set the token to. If this is not provided, the function will return the current
   * token.
   * @returns The value of the token_key in the local storage.
   */
  handleAuthToken(value?: string) {
    if (!value) {
      return this.getItem<string>(this.token_key);
    }

    this.setItem(this.token_key, value);
  }

  setToken(token: string) {
    this.setItem(this.token_key, token);
  }

  /**
   * It removes the token from the local storage
   */
  removeAuthToken() {
    this.removeItem(this.token_key);
  }

  handleListView(): string | null;
  handleListView(view: string): void;
  /**
   * If the view parameter is not passed in, return the value of the list_view_key key from the local storage. If the view
   * parameter is passed in, set the value of the list_view_key key in the local storage to the value of the view parameter
   * @param {string} [view] - The view to set.
   * @returns The value of the key 'list_view_key'
   */
  handleListView(view?: string) {
    if (!view) {
      return this.getItem<string>(this.list_view_key);
    }

    this.setItem(this.list_view_key, view);
  }
}

const LocalStorageService = new LocalStorageInstance();

export default LocalStorageService;
