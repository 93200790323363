import { useContext } from 'react';

import { CheckStepContext } from './CheckStepContext';

export function useCheckStepContext() {
  const ctx = useContext(CheckStepContext);

  if (!ctx) {
    throw new Error('useCheckStepContext must be used within a CheckStepProvider');
  }

  return ctx;
}
