import { api } from '@services/Axios';

import type {
  CreatePasswordDto,
  ProfileModel,
  UpdateNonCurrAssetUserApproveStatusDto,
  UpdateProfileDto,
  UpdateProfilePasswordDto} from '@model/profile/Profile';
import type { ApiResponse } from '@services/Axios';

const ProfileEndpoint = '/Profile';

const AssetEndpoint = '/NonCurrAsset';

export const ProfileRepository = {
  /* ----------------------------- Fetch Profile ----------------------------- */
  async fetchProfile() {
    const response: ApiResponse<ProfileModel> = await api.get(`${ProfileEndpoint}/GetMyProfile`);

    return response.data;
  },

  /* ----------------------- Update Profile Information ----------------------- */
  async updateProfile(dto: Omit<UpdateProfileDto, 'profileImage'>) {
    const response: ApiResponse<ProfileModel> = await api.post(
      `${ProfileEndpoint}/UpdateMyProfile`,
      dto
    );

    return response.data;
  },

  /* ----------------------- Update Profile Image ----------------------- */
  /**
   * Adding or editing profile image
   * @param {FormData} formData have to include image file
   *
   */
  async handleProfileImage(formData: FormData) {
    const response: ApiResponse<ProfileModel> = await api.post(
      `${ProfileEndpoint}/AddEditMyProfileImage`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  },

  /* ----------------------------- Change Password ---------------------------- */
  async changePassword(dto: UpdateProfilePasswordDto) {
    const response: ApiResponse<null> = await api.post(`${ProfileEndpoint}/ChangeMyPassword`, dto);

    return response.data;
  },

  /* ----------------------------- Create Password ---------------------------- */
  async createPassword(dto: CreatePasswordDto) {
    const response: ApiResponse<null> = await api.post(`${ProfileEndpoint}/CreatePassword`, dto);

    return response.data;
  },

    /* ----------------------------- User Asset Approve ---------------------------- */
    async UpdateNonCurrAssetUserApproveStatus(dto: UpdateNonCurrAssetUserApproveStatusDto) {
      const response: ApiResponse<null> = await api.post(`${AssetEndpoint}/ChangeNonCurrAssetUsersApproveStatus`, dto);
  
      return response.data;
    },
};
