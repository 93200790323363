import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { useAppSelector } from '@hooks';
import { Input } from '@shared/UI';
import { selectCostCenterStatus } from '@store/selectors/properties/CostCenter';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateCostCenterDto } from '@model/properties/CostCenter';

const { CaseField, Item, useForm } = CommonForm;

function FormCostCenter(props: CommonFormProps<CreateCostCenterDto>) {
  const [form] = useForm<CreateCostCenterDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const costCenterStatus = useAppSelector(selectCostCenterStatus);

  return (
    <CommonForm
      form={form}
      loading={costCenterStatus === 'pending'}
      isModal={isModal}
      additionalFooter={isModal ? <ModalFormFooter onCancel={rest.header?.onClickCancel} /> : null}
      name="formCostCenter"
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="costCenterCode"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormCostCenter };
