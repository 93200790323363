import { createSlice } from '@reduxjs/toolkit';
import {
  addExitType,
  getExitTypeById,
  getExitTypes,
  getExitTypesByFilter,
  removeExitTypeById,
  updateExitType,
} from '@store/actions/properties/ExitType';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { ExitTypeModel, FilterExitTypeDto } from '@model/properties/ExitType';

type ExitTypeState = {
  exitTypesList: ExitTypeModel[];
  exitTypesFilteredList: ExitTypeModel[];
  exitType: ExitTypeModel | null;
  exitTypeStatus: LoadingStatus;
  exitTypeError: string | null;
  exitTypeFilters: FilterExitTypeDto;
};

const initialState: ExitTypeState = {
  exitTypesList: [],
  exitTypesFilteredList: [],
  exitType: null,
  exitTypeStatus: 'idle',
  exitTypeError: null,
  exitTypeFilters: {
    codeArray: [],
    nameArray: [],
  },
};

const ExitTypeSlice = createSlice({
  name: 'ExitType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExitTypes.pending, (state) => {
        state.exitTypeError = null;
        state.exitTypeStatus = 'pending';
        state.exitTypesFilteredList = [];
        state.exitTypeFilters = {
          codeArray: [],
          nameArray: [],
        };
      })
      .addCase(getExitTypes.fulfilled, (state, { payload }) => {
        state.exitTypeStatus = 'idle';
        state.exitTypesList = payload;
      })
      .addCase(getExitTypes.rejected, (state) => {
        state.exitTypeStatus = 'idle';
      });
    builder
      .addCase(getExitTypesByFilter.pending, (state) => {
        state.exitTypeError = null;
        state.exitTypeStatus = 'pending';
      })
      .addCase(getExitTypesByFilter.fulfilled, (state, { payload }) => {
        state.exitTypeStatus = 'idle';
        state.exitTypesFilteredList = payload.exitTypes;
        state.exitTypeFilters = payload.exitTypesFilters;
      })
      .addCase(getExitTypesByFilter.rejected, (state, action) => {
        state.exitTypeStatus = 'idle';
        if (action.payload) {
          state.exitTypeError = action.payload;
        }
      });
    builder
      .addCase(addExitType.pending, (state) => {
        state.exitTypeStatus = 'pending';
        state.exitTypeError = null;
      })
      .addCase(addExitType.fulfilled, (state, { payload }) => {
        state.exitTypeStatus = 'idle';
        state.exitTypesList = [payload, ...state.exitTypesList];
      })
      .addCase(addExitType.rejected, (state, { payload }) => {
        state.exitTypeStatus = 'idle';
        if (payload) {
          state.exitTypeError = payload;
        }
      });

    builder
      .addCase(getExitTypeById.pending, (state) => {
        state.exitTypeStatus = 'pending';
        state.exitTypeError = null;
      })
      .addCase(getExitTypeById.fulfilled, (state, { payload }) => {
        state.exitTypeStatus = 'idle';
        state.exitType = payload;
      })
      .addCase(getExitTypeById.rejected, (state, { payload }) => {
        state.exitTypeStatus = 'idle';
        if (payload) {
          state.exitTypeError = payload;
        }
      });

    builder
      .addCase(updateExitType.pending, (state) => {
        state.exitTypeStatus = 'pending';
        state.exitTypeError = null;
      })
      .addCase(updateExitType.fulfilled, (state, { payload }) => {
        state.exitTypeStatus = 'idle';
        state.exitTypesList = payload.exitTypes;
        state.exitType = payload.exitType;
      })
      .addCase(updateExitType.rejected, (state, { payload }) => {
        state.exitTypeStatus = 'idle';
        if (payload) {
          state.exitTypeError = payload;
        }
      });

    builder
      .addCase(removeExitTypeById.pending, (state) => {
        state.exitTypeStatus = 'pending';
        state.exitTypeError = null;
      })
      .addCase(removeExitTypeById.fulfilled, (state, { payload }) => {
        state.exitTypeStatus = 'idle';
        state.exitTypesList = payload;
      })
      .addCase(removeExitTypeById.rejected, (state, { payload }) => {
        state.exitTypeStatus = 'idle';
        if (payload) {
          state.exitTypeError = payload;
        }
      });
  },
});

const ExitTypeReducer = ExitTypeSlice.reducer;

export { ExitTypeReducer };
