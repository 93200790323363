import React from 'react';

import { FormPartAndInventoryUnit } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletPartAndInventoryUnit } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updatePaIUnit } from '@store/actions/properties/PaI/Unit';

import type { CreatePaIUnitDto } from '@model/properties/PaI/Unit';

function EditPartAndInventoryUnit() {
  const { initialValues, entity } = useOutletPartAndInventoryUnit();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onFinish = async (dto: CreatePaIUnitDto) => {
    try {
      const response = await dispatch(
        updatePaIUnit({
          ...dto,
          partsAndInventoriesUnitId: entity.partsAndInventoriesUnitId,
        })
      ).unwrap();
      ApiNotifications.update(response.particularUnit.partsAndInventoriesUnitName);
      navigate(paths.partAndInventoryAttributes.tabs.unit.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={paiAttrAccess.edit} redirectTo={paths.partAndInventoryAttributes.tabs.unit.default}>
      <FormPartAndInventoryUnit
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.partsAndInventoriesUnitName,
        }}
      />
    </CheckAccess>
  );
}

export default EditPartAndInventoryUnit;
