import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCheckStepContext } from '@components/common/CheckStepItem/_utils';
import { Select } from '@shared/UI';

import styles from './InspectionField.module.scss';

const options = [
  {
    value: 'True',
    label: 'Yes',
  },
  {
    value: 'False',
    label: 'No',
  },
];

export const InspectionField = memo(() => {
  const { t } = useTranslation();

  const { step, actions, isActiveWorkStep } = useCheckStepContext();

  const handleValueChange = (value: string) => {
    actions?.onChangeResultValue?.(step, value);
  };

  return (
    <Select
      disabled={!isActiveWorkStep}
      value={step.value}
      className={styles.inspectionField}
      placeholder={t('titles.Inspection')}
      options={options}
      onChange={handleValueChange}
      allowClear={false}
    />
  );
});
