import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getPaIUnitById } from '@store/actions/properties/PaI/Unit';
import { selectPaIUnit } from '@store/selectors/properties/PaI/Unit';

import type { IOutletPartAndInventoryUnit } from '@constants/OutletContextEntityPages';
import type { CreatePaIUnitDto } from '@model/properties/PaI/Unit';

function PartAndInventoryUnit() {
  const partAndInventoryUnit = useAppSelector(selectPaIUnit);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition =
    !partAndInventoryUnit || partAndInventoryUnit.partsAndInventoriesUnitId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getPaIUnitById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreatePaIUnitDto = {
    partsAndInventoriesUnitName: partAndInventoryUnit.partsAndInventoriesUnitName,
    partsAndInventoriesUnitShortName: partAndInventoryUnit.partsAndInventoriesUnitShortName,
  };

  return (
    <Outlet
      context={{ initialValues, entity: partAndInventoryUnit } as IOutletPartAndInventoryUnit}
    />
  );
}

export default PartAndInventoryUnit;
