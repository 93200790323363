import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Radio, Select } from '@shared/UI';
import {
  getMaAtChecklistById,
  getMaAtChecklistsSummary,
} from '@store/actions/properties/MaAt/Checklist';
import { selectMaAtChecklistsSummary } from '@store/selectors/properties/MaAt/Checklist';

import { workOrderRadioType } from '../_utils';

import type { CheckStepItem } from '@components/common/CheckStepItem/_utils';
import type { MaAtCheckStepModel } from '@model/properties/MaAt/Checklist';
import type { MapObjectValues } from '@shared/utils/types';
import type { RadioChangeEvent } from 'antd';

interface RadiosSectionProps {
  setCheckSteps: (steps: CheckStepItem[]) => void;

  mapChecklist: (steps: MaAtCheckStepModel[]) => CheckStepItem[];

  cleanStepsState: () => void;
}

const { CaseField, Item } = CommonForm;

const fieldNamesSummaryChecklist = {
  value: 'checkListId',
  label: 'checklistName',
};

export const RadiosSection = (props: RadiosSectionProps) => {
  const { setCheckSteps, mapChecklist, cleanStepsState } = props;

  const [currentChecklistId, setCurrentChecklistId] = useState<number | null>(null);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [currentType, setCurrentType] = useState<MapObjectValues<typeof workOrderRadioType>>(1);

  const maintenanceChecklist = useAppSelector(selectMaAtChecklistsSummary);

  const handleRadioChange = (event: RadioChangeEvent) => {
    const val = event.target.value as MapObjectValues<typeof workOrderRadioType>;

    cleanStepsState();
    setCurrentChecklistId(() => null);

    setCurrentType(val);
  };

  const handleSelectChange = (value: number) => {
    cleanStepsState();
    dispatch(getMaAtChecklistById(value))
      .unwrap()
      .then((val) => {
        if (val && val[0]) {
          const checklist = val[0].checkListCheckSteps;
          const checkSteps = mapChecklist(checklist);

          setCheckSteps(checkSteps);
        }
      });

    setCurrentChecklistId(value);
  };

  useEffect(() => {
    dispatch(getMaAtChecklistsSummary());
  }, []);

  return (
    <CaseField
      span={24}
      md={24}
      style={{
        marginBottom: 20,
      }}
    >
      <Radio.Group value={currentType} onChange={handleRadioChange}>
        <Radio value={workOrderRadioType.step}>Step</Radio>
        <Radio value={workOrderRadioType.checklist}>Checklist</Radio>
      </Radio.Group>

      {currentType === workOrderRadioType.checklist && (
        <Item
          label={t('titles.Select_From')}
          style={{
            marginTop: 20,
          }}
        >
          <Select
            style={{
              maxWidth: 400,
            }}
            value={currentChecklistId}
            onChange={handleSelectChange}
            placeholder={t('titles.Select_From')}
            options={maintenanceChecklist}
            fieldNames={fieldNamesSummaryChecklist}
          />
        </Item>
      )}
    </CaseField>
  );
};
