import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@hooks';
import { getVersionApi } from '@store/actions/extra/Version';
import { selectVersionApi, selectVersionApp } from '@store/selectors/extra/Version';
import cl from 'classnames';

import './VersionApp.scss';

interface VersionAppProps {
  className?: string;
  style?: React.CSSProperties;
}

const VersionApp = (props: VersionAppProps) => {
  const { className, style } = props;

  const dispatch = useAppDispatch();

  const versionApp = useAppSelector(selectVersionApp);
  const versionApi = useAppSelector(selectVersionApi);

  const classes = cl('version-app', className);

  useEffect(() => {
    if (!versionApi) {
      dispatch(getVersionApi());
    }
  }, []);

  return (
    <div className={classes} style={style}>
      <span>
        Ver. {versionApp} - {versionApi}
      </span>
    </div>
  );
};

export { VersionApp };
