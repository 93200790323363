import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Select } from '@shared/UI';
import { getUsers } from '@store/actions/user/User';
import { selectUsersListWithoutChildren, selectUserStatus } from '@store/selectors/users/User';

import type { ItemProps } from '@shared/UI';

interface SelectUserProps extends ItemProps {
  placeholder?: string;
  disabled?: boolean;
}

const { Item } = CommonForm;

const SelectUser = (props: SelectUserProps) => {
  const { rules: rulesProps, name, disabled, label, placeholder, ...restItem } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const listUsers = useAppSelector(selectUsersListWithoutChildren);

  const statusUser = useAppSelector(selectUserStatus);

  useEffect(() => {
    if (!listUsers.length) {
      dispatch(getUsers());
    }
  }, []);

  const options = useMemo(() => {
    return listUsers.map((user) => ({
      value: user.userId,
      label: `${user.firstName} ${user.lastName}`,
    }));
  }, [listUsers]);

  const rules = useMemo(() => rulesProps, [rulesProps]);

  return (
    <Item
      name={name || 'userId'}
      label={label || t('titles.User_Name')}
      rules={rules}
      {...restItem}
    >
      <Select
        options={options}
        disabled={disabled}
        placeholder={placeholder || t('titles.Choose')}
        loading={statusUser === 'pending'}
      />
    </Item>
  );
};

export { SelectUser };
