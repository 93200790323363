import { createAsyncThunk } from '@reduxjs/toolkit';
import { VersionRepository } from '@repositories';
import { identifyError } from '@shared/utils/functions';

export const getVersionApi = createAsyncThunk<string, void, { rejectValue: string }>(
  'Version/getVersionApi',
  async (_, { rejectWithValue }) => {
    try {
      const response = await VersionRepository.fetch();

      return response.resultObject;
    } catch (err) {
      const error = identifyError(err);

      return rejectWithValue(error);
    }
  }
);
