import type { LabelModel } from '@model/extra/Label';

export function getLabelOptions(labelsList: Array<LabelModel>) {
  return labelsList
    .map((label) => ({
      label: label.label,
      value: label.label,
      color: label.labelColor,
    }))
    .reduce((acc: { label: string; value: string; color: string }[], curr) => {
      return acc.some((val) => val.label === curr.label) ? acc : [...acc, curr];
    }, []);
}
