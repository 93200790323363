import React from 'react';

const DashboardIcon = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="11.2656"
      y="0.90332"
      width="6.5"
      height="6.5"
      rx="1.25"
      stroke="white"
      strokeWidth="1.5"
    />
    <rect
      x="11.2656"
      y="10.5967"
      width="6.5"
      height="6.5"
      rx="1.25"
      stroke="white"
      strokeWidth="1.5"
    />
    <rect
      x="1.23047"
      y="0.90332"
      width="6.5"
      height="6.5"
      rx="1.25"
      stroke="white"
      strokeWidth="1.5"
    />
    <rect
      x="1.23047"
      y="10.5967"
      width="6.5"
      height="6.5"
      rx="1.25"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
);

export { DashboardIcon };
