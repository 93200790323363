import React from 'react';

import { Badge as AntBadge } from 'antd';
import cl from 'classnames';

import type { BadgeProps as AntBadgeProps } from 'antd';

import './Badge.scss';

export interface BadgeProps extends AntBadgeProps {}

function Badge(props: BadgeProps) {
  const { className, overflowCount, ...rest } = props;

  const badgeClasses = cl('custom-badge', className);

  return (
    <AntBadge
      overflowCount={overflowCount ? overflowCount : 10}
      className={badgeClasses}
      {...rest}
    />
  );
}

export { Badge };
