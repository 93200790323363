import type { RootState } from '@store/rootReducer';

export const selectUserAuthState = (state: RootState) => state.UserAuthorizationReducer;

export const selectUserAuthList = (state: RootState) => selectUserAuthState(state).userAuthList;

export const selectUserAuth = (state: RootState) => selectUserAuthState(state).userAuth;

export const selectUserAuthStatus = (state: RootState) => selectUserAuthState(state).userAuthStatus;

export const selectUserAuthError = (state: RootState) => selectUserAuthState(state).userAuthError;
