import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { labelColors } from '@constants/labelColors';
import { useAppSelector } from '@hooks';
import { ColorPicker, Input } from '@shared/UI';
import { selectLabelStatus } from '@store/selectors/extra/Label';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateLabelDto } from '@model/extra/Label';

const { CaseField, Item, useForm } = CommonForm;

function FormLabel(props: CommonFormProps<CreateLabelDto>) {
  const [form] = useForm<CreateLabelDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const statusLabel = useAppSelector(selectLabelStatus);

  return (
    <CommonForm 
      form={form} 
      loading={statusLabel === 'pending'} 
      isModal={isModal}
      name="formLabel" 
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onCancel={rest.header?.onClickCancel} /> : null
      }
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Name')}
          name="label"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item
          name="labelColor"
          label={t('titles.Color')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ColorPicker colors={labelColors} withColorLabel={false} />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormLabel };
