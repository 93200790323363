import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormAssetBrand } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletAssetBrand } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateAsAtBrand } from '@store/actions/properties/AsAt/Brand';

import type { CreateAsAtBrandDto } from '@model/properties/AsAt/Brand';

function EditAssetBrand() {
  const { initialValues, entity } = useOutletAssetBrand();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { assetAttrAccess } = useGetAccess();

  const onFinish = (dto: CreateAsAtBrandDto) => {
    dispatch(
      updateAsAtBrand({
        ...dto,
        nonCurrAssetCardBrandId: entity.nonCurrAssetCardBrandId,
      })
    )
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.AsAtBrand.name);

        navigate(paths.assetAttribute.tabs.brand.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <CheckAccess hasAccess={assetAttrAccess.edit} redirectTo={paths.assetAttribute.tabs.brand.default}>
      <FormAssetBrand
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditAssetBrand;
