import React from 'react';

import { FormPartAndInventoryProperty } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletPartAndInventoryProperty } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updatePaIProperty } from '@store/actions/properties/PaI/Property';

import type { CreatePaIPropertyDto } from '@model/properties/PaI/Property';

function EditPartAndInventoryProperty() {
  const { initialValues, entity } = useOutletPartAndInventoryProperty();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onFinish = async (dto: CreatePaIPropertyDto) => {
    try {
      const response = await dispatch(
        updatePaIProperty({
          ...dto,
          partsAndInventoriesCardPropertyId: entity.partsAndInventoriesCardPropertyId,
        })
      ).unwrap();

      ApiNotifications.update(response.particularProperty.name);
      navigate(paths.partAndInventoryAttributes.tabs.property.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess
      hasAccess={paiAttrAccess.edit}
      redirectTo={paths.partAndInventoryAttributes.tabs.property.default}
    >
      <FormPartAndInventoryProperty
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditPartAndInventoryProperty;
