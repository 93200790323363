import React from 'react';
import { useTranslation } from 'react-i18next';

import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { NoResults } from '../../NoResults';

import type { WorkOrderTotal } from '@model/dashboard';

import './WorkOrdersBarChart.scss';

interface WorkOrdersBarChartProps {
  data: WorkOrderTotal[] | undefined
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getPath = (x: number, y: number, width: number, height: number) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width},
          ${y + height}Z`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TriangleBar = (props: any) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const WorkOrdersBarChart = (props: WorkOrdersBarChartProps) => {
  const { data } = props;

  const { t } = useTranslation();

  const getTotalMonthInfo = (objKey: string) => {
    const monthInfo = data && data?.map((obj) => {
      const newArr = [];

      for (let key in obj){
        if(key.includes(objKey)){
          newArr.push(obj[key as keyof typeof obj])
        }
      }

      return newArr as number[]
    })

    const totalMonthInfo = monthInfo?.length ? 
      monthInfo[0].map((_, i) => monthInfo?.reduce((a, b) => a + b[i], 0)) : []

    return totalMonthInfo
  }

  const total =  getTotalMonthInfo('opened');
  const closed = getTotalMonthInfo('closed');

  const dataoter = months.map((month, i) => ({
    name: month,
    total:  total[i],
    closed: closed[i],
  }));

  const translationsMonth =  dataoter.map((value) => ({
    ...value,
    name: t(`short_months.${value.name}`),
  }));

  return (
    <div className="work-order-chart bar-chart">
      <div className="bar-chart__header">
        <h6>{t('titles.Work_Orders_By_Month')}</h6>
      </div>
      <div className="bar-chart__content">
      {(data && !data.length) ?
        <NoResults />
        :
        <>
        <div className="bar-chart__content-header">
          <h6>{t('titles.Top_Work_Orders', { number: 5 })}</h6>
        </div>
        <div className='bar-chart__content-box'>
          <div className="bar-chart__info">
            {data && data.map((item, index) => {
              return (
                <div className="bar-chart__info-item" key={index}>
                  <span className="item-count">{index + 1}</span>
                  <span className="item-title">{item.sitename}</span>
                  <span className="item-value">{item.totalcount}</span>
                </div>
              );
            })}
          </div>
          <div className="bar-chart__chart">
          <div className='bar-chart__chart-labels'>
            <span className='bar-chart__total-label'>{t('titles.Total')}</span>
            <span className='bar-chart__opened-label'>{t('titles.Opened')}</span>
          </div>
          <ResponsiveContainer width={"100%"} height={185}>
            <BarChart
              data={translationsMonth}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} strokeDasharray="0" />
              <XAxis dataKey="name" />
              <YAxis hide />
              <Bar barSize={7} dataKey="total" stackId="a" fill=" #00458A" radius={[50, 50, 0, 0]} />
              <Bar barSize={7} dataKey="closed" stackId="a" fill="#DDEEFF" radius={[50, 50, 0, 0]} />
            </BarChart>
            </ResponsiveContainer>
        </div>
        </div>
        </>
      }
      </div>
    </div>
  );
};

export { WorkOrdersBarChart };
