import React from 'react';

import { defaultFormatDate } from '@constants/Date';
import cl from 'classnames';
import moment from 'moment';

import './UserNotification.scss';

export interface UserNotificationProps extends React.HTMLProps<HTMLDivElement> {
  description: string;

  isRead?: boolean;

  notificationDate?: string;

  type?: 'compact';

  hiddenMarker?: boolean;
}

const formatDate = (date: string | undefined) => {
  if (!date) return;

  const dateNow = moment(new Date());

  const propsDate = moment(date);

  const compareDate = dateNow.diff(moment(date), 'minutes');

  if (compareDate < 60) {
    return `${compareDate} min ago`;
  }

  if (compareDate === 0) {
    return 'Just now';
  }

  return propsDate.format(defaultFormatDate);
};

function UserNotification(props: UserNotificationProps) {
  const { className, description, type, isRead, notificationDate } = props;

  const defaultClass = 'user-notification';

  const userNotificationClasses = cl(defaultClass, className, {
    [`${defaultClass}--compact`]: type === 'compact',
  });

  const markerColor = isRead ? '#D2D2D2' : '#00458A';

  return (
    <div className={userNotificationClasses}>
      <span
        className={`${defaultClass}__marker`}
        style={{
          opacity: props.hiddenMarker ? '0' : '1',
        }}
      >
        <svg width="10" height="10" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="4" fill={markerColor} />
        </svg>
      </span>
      <div className={`${defaultClass}__wrapper-info`}>
        <p className={`${defaultClass}__description`}>{description}</p>
        <span className={`${defaultClass}__date`}>{formatDate(notificationDate)}</span>
      </div>
    </div>
  );
}

export { UserNotification };
