import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getMaAtRequestReasons } from '@store/actions/properties/MaAt/RequestReason';
import { selectMaAtRequestReasonsList } from '@store/selectors/properties/MaAt/RequestReason';

function EntryMaintenanceRequestReason() {
  useSetPageInfo(
    {
      defaultPagePath: paths.maintenanceAttributes.tabs.requestReason.default,
      pageTitle: 'pages_single.Maintenance_Attr',
    },
    {
      copyTabs: true,
    }
  );
  const maintenanceRequestReasonList = useAppSelector(selectMaAtRequestReasonsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!maintenanceRequestReasonList.length) {
      dispatch(getMaAtRequestReasons());
    }
  }, []);

  return <Outlet />;
}

export default EntryMaintenanceRequestReason;
