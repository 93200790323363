import React, { useCallback, useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppDispatch, useAppSelector, useGetAccess } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { Select } from '@shared/UI';
import { checkFilterValues, compare } from '@shared/utils/functions';
import {
  getSites,
  getSitesByFilter,
  updateSiteParentRelations,
} from '@store/actions/properties/Site';
import {
  selectSiteFilters,
  selectSitesFilteredList,
  selectSitesList,
  selectSitesListWithoutChildren,
  selectSiteStatus,
} from '@store/selectors/properties/Site';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { DragEnd } from '@components/common/BasicTablePage/BasicTablePage';
import type { FilterSiteDto, SiteModel } from '@model/properties/Site';

const columns: ColumnsTableType<SiteModel> = [
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'siteCode',
    key: 'siteCode',
    ellipsis: true,
    sorter: (a, b) => compare(a.siteCode, b.siteCode),
  },
  {
    title: <Translation>{(t) => t('titles.Barcode')}</Translation>,
    dataIndex: 'barcode',
    key: 'barcode',
    ellipsis: true,
    sorter: (a, b) => compare(a.barcode, b.barcode),
  },
  {
    title: <Translation>{(t) => t('titles.Country')}</Translation>,
    dataIndex: ['city', 'country', 'name'],
    key: 'countryName',
    ellipsis: true,
    sorter: (a, b) => compare(a.city.country.name, b.city.country.name),
  },
  {
    title: <Translation>{(t) => t('titles.City')}</Translation>,
    dataIndex: ['city', 'name'],
    key: 'cityName',
    ellipsis: true,
    sorter: (a, b) => compare(a.city.name, b.city.name),
  },
];

const Sites = () => {
  const { t } = useTranslation();

  const sites = useAppSelector(selectSitesList);
  const statusSite = useAppSelector(selectSiteStatus);
  const sitesWithoutChildren = useAppSelector(selectSitesListWithoutChildren);
  const filteredListSite = useAppSelector(selectSitesFilteredList);
  const filters = useAppSelector(selectSiteFilters);
  const dispatch = useAppDispatch();

  const { siteAccess } = useGetAccess();

  const data = useCallback(
    (sites: SiteModel[]) => {
      return sites.map(
        ({ childSite, ...site }): SiteModel => ({
          ...site,
          childSite: childSite?.length ? data(childSite) : undefined,
        })
      );
    },
    [sites]
  );

  const filterTownOptions = useMemo(
    () => sitesWithoutChildren.filter((site) => site.town !== '' && site.town !== null),
    [sitesWithoutChildren]
  );

  const filterAddressOptions = useMemo(
    () => sitesWithoutChildren.filter((site) => site.address !== '' && site.address !== null),
    [sitesWithoutChildren]
  );

  const listSite = useMemo(() => data(sites), [sites]);

  const updateParent = (data: DragEnd<SiteModel>) => {
    dispatch(
      updateSiteParentRelations({
        fromSiteIds: [data.draggingRecordId],
        toSiteId: data.dropId,
      })
    );
  };

  const csvData = useMemo(
    () =>
      sitesWithoutChildren.map((site) => {
        const parentName = sites.find((s) => s.siteId === site.parentSiteId)?.name;
        return {
          name: site.name,
          siteCode: site.siteCode,
          barcode: site.barcode,
          parentName: parentName || '----',
          city: site.city?.name || '----',
          town: site.town !== '' || !site.town ? site.town : '----',
          area: site.area !== '' || !site.area ? site.area : '----',
        };
      }),
    [sitesWithoutChildren]
  );

  const dataSource = useMemo(
    () => (Boolean(filteredListSite.length) ? filteredListSite : listSite),
    [filteredListSite, listSite]
  );

  const getDefaultList = useCallback(async () => {
    try {
      await dispatch(getSites()).unwrap();
    } catch (error) {
      ApiNotifications.error(error);
    }
  }, []);

  const onSubmitFilter = async (values: FilterSiteDto) => {
    const checkedValues = checkFilterValues(values);

    if (!checkedValues) {
      await getDefaultList();
    } else {
      try {
        await dispatch(getSitesByFilter(values)).unwrap();
      } catch (e) {
        ApiNotifications.error(e);
      }
    }
  };

  return (
    <BasicTablePage
      table={{
        loading: statusSite === 'pending',
        dataSource: dataSource,
        columns: columns,
        rowKey: (record) => record.siteId,
        dataSourceName: t('pages_plural.Sites'),
        draggable: true,
        dropAction: updateParent,
        expandable: {
          childrenColumnName: 'childSite',
        },
      }}
      header={{
        csvConfig: {
          data: csvData,
          headers: [
            { label: 'Name', key: 'name' },
            { label: 'Site Code', key: 'siteCode' },
            { label: 'Barcode', key: 'barcode' },
            { label: 'Parent Site', key: 'parentName' },
            { label: 'City', key: 'city' },
            { label: 'Town', key: 'town' },
            { label: 'Area', key: 'area' },
          ],
          fileName: 'Sites.csv',
        },
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Site') }),
        filterProps: {
          items: [
            {
              label: t('titles.Code'),
              name: 'siteCodesArray',
              item: (
                <Select
                  options={sitesWithoutChildren}
                  maxTagCount={4}
                  mode="tags"
                  fieldNames={{ label: 'siteCode', value: 'siteCode' }}
                />
              ),
            },
            {
              label: t('titles.Name'),
              name: 'namesArray',
              item: (
                <Select
                  options={sitesWithoutChildren}
                  maxTagCount={4}
                  mode="tags"
                  fieldNames={{ label: 'name', value: 'name' }}
                />
              ),
            },
            {
              label: t('titles.Barcode'),
              name: 'barcodesArray',
              item: (
                <Select
                  options={sitesWithoutChildren}
                  maxTagCount={4}
                  mode="tags"
                  fieldNames={{ label: 'barcode', value: 'barcode' }}
                />
              ),
            },
            {
              label: t('titles.Address'),
              name: 'addressesArray',
              item: (
                <Select
                  options={filterAddressOptions}
                  maxTagCount={4}
                  mode="tags"
                  fieldNames={{ label: 'address', value: 'address' }}
                />
              ),
            },
            {
              label: t('titles.Town'),
              name: 'townsArray',
              item: (
                <Select
                  options={filterTownOptions}
                  maxTagCount={4}
                  mode="tags"
                  tokenSeparators={[',']}
                  fieldNames={{ label: 'town', value: 'town' }}
                />
              ),
            },
          ],
          initialValuesForm: filters,
          applyAction: onSubmitFilter,
          resetAction: async () => {
            if (checkFilterValues(filters)) {
              await getDefaultList();
            }
          },
        },
        newButtonProps: {
          disabled: !siteAccess.create,
        },
      }}
    />
  );
};

export { Sites };
