import React from 'react';

import { FormSite } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletSite } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateSite } from '@store/actions/properties/Site';

import type { CreateSiteDto } from '@model/properties/Site';

function EditSite() {
  const { initialValues, entity } = useOutletSite();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { siteAccess } = useGetAccess();

  const onFinish = async (dto: CreateSiteDto) => {
    try {
      const { site } = await dispatch(
        updateSite({
          ...dto,
          siteId: entity.siteId,
        })
      ).unwrap();
      ApiNotifications.update(site.name);
      navigate(paths.site.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={siteAccess.edit} redirectTo={paths.site.default}>
      <FormSite
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditSite;
