import React, { memo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { DownloadOutlined } from '@ant-design/icons';
import { Dropdown as DefaultDropdown, Space } from 'antd';

import { getAssetCSVConfig } from '../../_utils';
import { useAssetListContext } from '../../AssetListProvider';

import type { MenuProps } from '@shared/UI';

export type DropdownProps = {
  disabledDropdown?: boolean;
};

export const Dropdown = memo((props: DropdownProps) => {
  const { disabledDropdown = false } = props;

  const { t } = useTranslation();

  const {
    allAssetRecord,
    listAssetCategory,
    listAssetBrand,
    listAssetModel,
    listCostCenter,
    listVendor,
  } = useAssetListContext();

  const { data, headers, fileName } = getAssetCSVConfig({
    allAssetRecord,
    listAssetCategory,
    listAssetBrand,
    listAssetModel,
    listCostCenter,
    listVendor,
  });

  const items: MenuProps['items'] = [
    {
      icon: <DownloadOutlined />,
      className: 'table-dropdown',
      key: 0,
      label:
        <CSVLink
          data={data || []}
          headers={headers}
          filename={fileName}
        >
          <span style={{color: 'black'}}>{t('titles.Export_Excel')}</span>
        </CSVLink>
    },
  ];

  return (
    <DefaultDropdown menu={{ items }} trigger={['click']} overlayClassName='table-dropdown' disabled={disabledDropdown}>
      <Space className='table-dropdown-icon'>
        <span>...</span>
      </Space>
    </DefaultDropdown>
  );
});
