import React from 'react';

import { PreviewFormDepartment } from '@components/views/Previews/properties';
import { useOutletDepartment } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeDepartmentById } from '@store/actions/properties/Department';

import type { CommonPreviewProps } from '@components/common';

function PreviewDepartment() {
  const { entity } = useOutletDepartment();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { departmentAccess } = useGetAccess();

  const onDelete = () => {
    dispatch(removeDepartmentById([entity.departmentId]))
      .unwrap()
      .then(() => {
        notificationController.success({
          description: `${entity.name} deleted`,
        });
        navigate(paths.department.default);
      });
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !departmentAccess.edit,
    },
    deleteButtonProps: {
      disabled: !departmentAccess.delete,
    },
  };

  return <PreviewFormDepartment entity={entity} onDelete={onDelete} header={headerProps} />;
}

export default PreviewDepartment;
