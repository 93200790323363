import { defaultInitialForm } from '@components/views/Forms/managment/Maintenance/GeneralV2/_util/defaultInitialForm';
import moment from 'moment';

import type { CreateMaintenanceGeneralV2Dto } from '@model/management/Maintenance';

export function createInitialValues(
  initialValues?: Partial<CreateMaintenanceGeneralV2Dto>
): Partial<CreateMaintenanceGeneralV2Dto> {
  return {
    ...defaultInitialForm,
    ...initialValues,
    startDate: initialValues?.startDate
      ? (moment(initialValues?.startDate) as unknown as string)
      : null,
    maintenanceOccurrenceId: initialValues?.maintenanceOccurrenceId,
  };
}
