import { createAsyncThunk } from '@reduxjs/toolkit';
import { RoleRepository } from '@repositories';

import type { CreateRoleDto, RoleModel, UpdateRoleDto } from '@model/users/Role';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer';

// region --- Get Actions

export const getRoles = createAsyncThunk<RoleModel[], void, { rejectValue: string }>(
  'Roles/getRoles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await RoleRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getRoleById = createAsyncThunk<RoleModel, string>(
  'Role/getRoleById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await RoleRepository.fetchById(id);

      return res.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addRole = createAsyncThunk<
  { roleList: RoleModel[]; createdRole: RoleModel },
  CreateRoleDto
>('Roles/createRole', async (dto, { rejectWithValue }) => {
  try {
    const createdRole = await RoleRepository.add(dto);
    const response = await RoleRepository.fetchAll();

    return {
      roleList: response.resultObject,
      createdRole: createdRole.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateRole = createAsyncThunk<
  { roles: RoleModel[]; role: RoleModel },
  UpdateRoleDto,
  { state: RootState }
>('Role/updateRole', async (dto, { rejectWithValue, getState }) => {
  try {
    const res = await RoleRepository.update(dto);

    const { resultObject } = res;

    const { roleList } = getState().RoleReducer;

    const roles = roleList.map((role) =>
      role.roleId === resultObject.roleId ? resultObject : role
    );

    return { roles, role: resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeRoleById = createAsyncThunk<RoleModel[], number[], { state: RootState }>(
  'Roles/removeRoleById',
  async (ids, { rejectWithValue }) => {
    try {
      await RoleRepository.removeById(ids);
      const response = await RoleRepository.fetchAll();
      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Remove Actions
