import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@hooks';
import { Loader } from '@shared/UI';
import { getDashboard } from '@store/actions/dashboard';
import { selectDashboardData, selectDashboardStatus } from '@store/selectors/dashboard';

import { AssetLineCharts } from './AssetLineCharts';
import { BarCharts } from './BarCharts';
import { PieCharts } from './PieCharts';

const DashboardView = () => {
  const dispatch = useAppDispatch();
  const dashboardData = useAppSelector(selectDashboardData);
  const dashboardStatus = useAppSelector(selectDashboardStatus);

  useEffect(() => {
    if(!dashboardData) {
      dispatch(getDashboard());
    }
  }, []);
  
  if(dashboardStatus === 'pending') {
    return <Loader middle size='large' />
  }

  return (
    <>
      <AssetLineCharts 
        data={dashboardData}
      />
      <PieCharts 
        data={dashboardData}
      />
      <BarCharts
        data={dashboardData}
      />
    </>
  );
};

export { DashboardView };
