import type { RootState } from '@store/rootReducer';

export const selectTitleState = (state: RootState) => state.TitleReducer;

export const selectTitlesList = (state: RootState) => selectTitleState(state).titlesList;

export const selectTitle = (state: RootState) => selectTitleState(state).title;

export const selectTitleStatus = (state: RootState) => selectTitleState(state).titleStatus;

export const selectTitleError = (state: RootState) => selectTitleState(state).titleError;
