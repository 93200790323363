import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PreviewFormAssetStatus } from '@components/views/Previews/properties';
import { useOutletAssetStatus } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeAsAtStatusById } from '@store/actions/properties/AsAt/Status';

import type { CommonPreviewProps } from '@components/common';

function PreviewAssetStatus() {
  const { entity } = useOutletAssetStatus();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { assetAttrAccess } = useGetAccess();

  const onDelete = () => {
    dispatch(removeAsAtStatusById([entity.nonCurrAssetStatusId]))
      .unwrap()
      .then(() => {
        notificationController.success({
          description: `${entity.name} deleted`,
        });
        navigate(paths.assetAttribute.tabs.status.default);
      })
      .catch((err) => {
        if (typeof err === 'string') {
          notificationController.error({
            description: err,
          });
        }
      });
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !assetAttrAccess.edit,
    },
    deleteButtonProps: {
      disabled: !assetAttrAccess.delete,
    },
  };

  return <PreviewFormAssetStatus entity={entity} onDelete={onDelete} header={headerProps} />;
}

export default PreviewAssetStatus;
