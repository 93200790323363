import { createSelector } from '@reduxjs/toolkit';
import { liftChildToTop } from '@shared/utils/functions';

import type { RootState } from '@store/rootReducer';

export const selectPaICategoryState = (state: RootState) => state.PaICategoryReducer;

export const selectPaICategoriesList = (state: RootState) =>
  selectPaICategoryState(state).PaICategoriesList;

export const selectPaICategory = (state: RootState) => selectPaICategoryState(state).PaICategory;

export const selectPaICategoryStatus = (state: RootState) =>
  selectPaICategoryState(state).PaICategoryStatus;

export const selectPaICategoryError = (state: RootState) =>
  selectPaICategoryState(state).PaICategoryError;

export const selectPaICategoriesListWithoutChildren = createSelector(
  selectPaICategoriesList,
  (listPartAndInventoryCategory) => {
    return liftChildToTop(listPartAndInventoryCategory, 'childPartsAndInventoriesCategories');
  }
);
