import { useContext } from 'react';

import { MaintenanceContext } from './MaintenanceContext';

export function useMaintenanceContext() {
  const ctx = useContext(MaintenanceContext);

  if (ctx === undefined || ctx === null) {
    throw new Error('useMaintenanceContext must be inside a Provider with a value');
  }
  return ctx;
}
