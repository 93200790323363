import React from 'react';

import { StepsProvider } from '@components/providers/StepsProvider';
import { FormMaintenance } from '@components/views/Forms/managment';
import { useAppDispatch, useAppSelector } from '@hooks';
import { notificationController } from '@services/Notifications';
import { ApiNotifications } from '@services/Notifications/adapters';
import {
  addMaintenanceGeneral,
  addMaintenanceParts,
  addMaintenanceSteps,
} from '@store/actions/management/Maintenance';
import { selectProfile } from '@store/selectors/profile/Profile';

import type {
  CreateMaintenanceGeneralV2Dto,
  CreateMaintenancePartsDto,
  CreateMaintenanceStepsDto,
  MpModelDto,
} from '@model/management/Maintenance';
import type { ProfileModel } from '@model/profile/Profile';

const CreateMaintenance = () => {
  const dispatch = useAppDispatch();

  const [createdId, setCreatedId] = React.useState<number | null>(null);

  const profile = useAppSelector(selectProfile) as ProfileModel;

  const onFinishGeneral = async (dto: CreateMaintenanceGeneralV2Dto) => {
    try {
      const { maintenanceId } = await dispatch(addMaintenanceGeneral(dto)).unwrap();
      setCreatedId(maintenanceId);
    } catch (error) {
      ApiNotifications.error(error);
      return Promise.reject();
    }
  };

  const handleWorkStepsFinish = async (dto: CreateMaintenanceStepsDto[]) => {
    try {
      await dispatch(addMaintenanceSteps(dto));
    } catch (error) {
      if (typeof error === 'string') {
        notificationController.error({
          description: error,
        });
      }
    }
  };

  const onFinishParts = async (dto: MpModelDto[]) => {
    if (createdId) {
      const normalizeDto: CreateMaintenancePartsDto = {
        maintenanceId: createdId,
        mpmodel: dto,
      };

      try {
        await dispatch(addMaintenanceParts(normalizeDto));
      } catch (error) {
        if (typeof error === 'string') {
          notificationController.error({
            description: error,
          });
        }
      }
    }
  };

  return (
    <StepsProvider>
      <FormMaintenance
        onFinishGeneral={onFinishGeneral}
        onFinishSteps={handleWorkStepsFinish}
        onFinishPart={onFinishParts}
        maintenanceId={createdId}
        userId={profile?.userId}
      />
    </StepsProvider>
  );
};

export default CreateMaintenance;
