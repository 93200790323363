import React from 'react';

import { Steps as AntdSteps } from 'antd';
import cl from 'classnames';

import type { StepsProps as AntdStepsProps } from 'antd';

import './Steps.scss';

export interface StepsProps extends Omit<AntdStepsProps, 'progressDot'> {
  progressDot?: boolean;
}

export interface StepsInterface {
  (props: StepsProps): JSX.Element;
  Step: typeof AntdSteps.Step;
}

const defaultClassPrefix = 'custom-steps';

const Steps: StepsInterface = (props) => {
  const { className, progressDot, labelPlacement = 'horizontal', ...rest } = props;

  const defaultClasses = cl(defaultClassPrefix, className, {
    [`${defaultClassPrefix}-progress-dot`]: progressDot,
  });

  return (
    <AntdSteps size="small" className={defaultClasses} labelPlacement={labelPlacement} {...rest} />
  );
};

Steps.Step = AntdSteps.Step;

export { Steps };
