import React, { memo, useCallback } from 'react';

import { useClassPrefix } from '@hooks';

import type { ChangeEvent,ReactNode } from 'react';

import './DayOfWeek.scss';

interface DayOfWeekProps {
  id: string | number;

  label: ReactNode;

  checked: boolean;

  onChange: (checked: boolean, id: string | number) => void;
}

export const DayOfWeek = memo((props: DayOfWeekProps) => {
  const { id, label, checked, onChange } = props;

  const { prefix, withPrefix } = useClassPrefix('dayOfMonth-checkbox');

  const changeChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      onChange(checked, id);
    },
    [onChange, id]
  );

  return (
    <label htmlFor={id.toString()} className={prefix}>
      <input
        className={withPrefix('field')}
        id={id.toString()}
        type="checkbox"
        checked={checked}
        onChange={changeChecked}
      />
      <span className={withPrefix('label')}>{label}</span>
    </label>
  );
});
