import type { ActionFunction, CheckStepItem, CheckStepItemOption } from '../../types';

export const onChangeResultOptionValue: ActionFunction<{
  step: CheckStepItem;
  option: CheckStepItemOption;
}> = ({ step, option }, { setState }) => {
  setState((prevState) => {
    return prevState.map((s) => {
      if (s.uuid === step.uuid) {
        let value = '';
        const optionsList = s.optionsList.map((o) => {
          if (o.uuid === option.uuid) {
            value = o.title;
            return {
              ...o,
              checked: true,
              hasError: false,
            };
          }
          return {
            ...o,
            checked: false,
          };
        });
        return {
          ...s,
          hasError: false,
          optionsList,
          value,
        };
      }

      return s;
    });
  });
};
