import { createSlice } from '@reduxjs/toolkit';
import {
  addPaICard,
  getPaICardById,
  getPaICards,
  removePaICardById,
  updatePaICard,
} from '@store/actions/properties/PaI/Card';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { PaICardModel } from '@model/properties/PaI/Card';

interface PaICardState {
  PaICardsList: PaICardModel[];
  PaICardStatus: LoadingStatus;
  PaICard: null | PaICardModel;
  PaICardError: null | string;
}

const initialState: PaICardState = {
  PaICardsList: [],
  PaICardStatus: 'idle',
  PaICard: null,
  PaICardError: null,
};

const PaICardSlice = createSlice({
  name: 'PaICard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaICards.pending, (state) => {
        state.PaICardError = null;
        state.PaICardStatus = 'pending';
      })
      .addCase(getPaICards.fulfilled, (state, { payload }) => {
        state.PaICardStatus = 'idle';
        state.PaICardsList = payload;
      })
      .addCase(getPaICards.rejected, (state, { payload }) => {
        state.PaICardStatus = 'idle';
        if (payload) {
          state.PaICardError = payload;
        }
      });

    builder
      .addCase(addPaICard.pending, (state) => {
        state.PaICardStatus = 'pending';
        state.PaICardError = null;
      })
      .addCase(addPaICard.fulfilled, (state, { payload }) => {
        state.PaICardStatus = 'idle';
        state.PaICardsList = [payload, ...state.PaICardsList];
      })
      .addCase(addPaICard.rejected, (state, { payload }) => {
        state.PaICardStatus = 'idle';
        if (payload) {
          state.PaICardError = payload;
        }
      });

    builder
      .addCase(getPaICardById.pending, (state) => {
        state.PaICardStatus = 'pending';
        state.PaICardError = null;
      })
      .addCase(getPaICardById.fulfilled, (state, { payload }) => {
        state.PaICardStatus = 'idle';
        state.PaICard = payload;
      })
      .addCase(getPaICardById.rejected, (state, { payload }) => {
        state.PaICardStatus = 'idle';
        if (payload) {
          state.PaICardError = payload;
        }
      });

    builder
      .addCase(updatePaICard.pending, (state) => {
        state.PaICardStatus = 'pending';
        state.PaICardError = null;
      })
      .addCase(updatePaICard.fulfilled, (state, { payload }) => {
        state.PaICardStatus = 'idle';
        state.PaICardsList = payload.names;
        state.PaICard = payload.particularName;
      })
      .addCase(updatePaICard.rejected, (state, { payload }) => {
        state.PaICardStatus = 'idle';
        if (payload) {
          state.PaICardError = payload;
        }
      });

    builder
      .addCase(removePaICardById.pending, (state) => {
        state.PaICardStatus = 'pending';
        state.PaICardError = null;
      })
      .addCase(removePaICardById.fulfilled, (state, { payload }) => {
        state.PaICardStatus = 'idle';
        state.PaICardsList = payload;
      })
      .addCase(removePaICardById.rejected, (state, { payload }) => {
        state.PaICardStatus = 'idle';
        if (payload) {
          state.PaICardError = payload;
        }
      });
  },
});

const PaICardReducer = PaICardSlice.reducer;

export { PaICardReducer };
