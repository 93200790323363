import React, { useRef } from 'react';

import { CheckStepsMapper, CommonForm, useCheckStep } from '@components/common';
import { useIsCreatePage } from '@hooks';

import { MaintenanceSteps } from '../MaintenanceSteps';
import { AddNewButton } from './AddNewButton';
import { RadiosSection } from './RadiosSection';

import type {
  ChecklistStepTypeKeys,
  CommonFormProps,
  DefaultCommonFormProps,
} from '@components/common';
import type {
  CreateMaintenanceStepsDto,
  MaintenanceWorkStepModel,
} from '@model/management/Maintenance';

type WorkOrdersProps = CommonFormProps<MaintenanceWorkStepModel[], CreateMaintenanceStepsDto[]> & {
  maintenanceId: number;

  userId: number;
};

const { useForm } = CommonForm;

export const FormWorkOrders = (props: WorkOrdersProps) => {
  const { initialValues, onFinish, maintenanceId, userId, ...restProps } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [form] = useForm<MaintenanceWorkStepModel>();

  const isCreate = useIsCreatePage();

  const {
    steps,
    addNewStep,
    validateSteps,
    mapCheckStepsToWorkSteps,
    setter,
    checklistApi,
    actions,
    cleanSteps,
  } = useCheckStep({
    type: 'maintenanceWorkStep',
    initialSteps: initialValues as MaintenanceWorkStepModel[],
    maintenanceId,
    userId,
  });

  const handleFormFinish = () => {
    const isValidSteps = validateSteps();

    if (isValidSteps) {
      const dto = mapCheckStepsToWorkSteps();

      onFinish?.(dto);
    }
  };

  const handleAddNewStep = (key: ChecklistStepTypeKeys) => {
    addNewStep(key);

    setTimeout(() => {
      wrapperRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    });
  };

  const renderAdditionalHeader = isCreate ? <MaintenanceSteps /> : null;

  const container: DefaultCommonFormProps['container'] = {
    style: {
      maxWidth: '100%',
    },
  };

  return (
    <div ref={wrapperRef}>
      <CommonForm
        form={form}
        alignAdditionalHeader="left"
        onFinish={handleFormFinish}
        additionalHeader={renderAdditionalHeader}
        container={container}
        {...restProps}
      >
        {(!initialValues || !initialValues.length) && (
          <RadiosSection
            mapChecklist={checklistApi.mapChecklistToCheckSteps}
            setCheckSteps={setter}
            cleanStepsState={cleanSteps}
          />
        )}

        <CheckStepsMapper steps={steps} actions={actions} />
        <AddNewButton addNewStep={handleAddNewStep} />
      </CommonForm>
    </div>
  );
};
