import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MinusCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Button, Input, Option, Select } from '@shared/UI';
import { getPaIProperties, getPaIPropertyTypes } from '@store/actions/properties/PaI/Property';
import {
  selectPaIPropertiesList,
  selectPaIPropertyStatus,
  selectPaIPropertyTypesList,
} from '@store/selectors/properties/PaI/Property';
import { Col, Row } from 'antd';

import { CommonForm } from '../CommonForm';

import type { CreatePaIDto } from '@model/management/PaI';
import type { ChangeEvent} from 'react';

import './PAIPropertyValues.scss';

const { Item, List, useFormInstance } = CommonForm;

type PropertyTypeValueOption = {
  label: string;
  value: number;
  propertyType: string;
  propertyName: string;
  isUnique: boolean;
};

type PropertyValueOption = {
  label: string;
  value: number;
};

type PropertyValue = {
  value: string;
  partsAndInventoriesCardPropertyId: number;
};

interface PAIPropertyValuesProps {
  initialValues?: PropertyValue[] | null;
  isCreatable?: boolean;
  creatableAction?: () => void; 
}

const PAIPropertyValues = (props: PAIPropertyValuesProps) => {
  const { initialValues, isCreatable, creatableAction } = props;

  const { t } = useTranslation();

  const form = useFormInstance<CreatePaIDto>();
  const listPartAndInventoryProperty = useAppSelector(selectPaIPropertiesList);
  const statusPartAndInventoryProperty = useAppSelector(selectPaIPropertyStatus);
  const listPartAndInventoryPropertyType = useAppSelector(selectPaIPropertyTypesList);

  const [propertyTypeValue, setPropertyTypeValue] = React.useState<PropertyTypeValueOption | null>(
    null
  );
  const [propertyValue, setPropertyValue] = React.useState<PropertyValue | null>(null);

  const [submittedValues, setSubmittedValues] = React.useState<PropertyValue[]>([]);

  const [fieldError, setFieldError] = useState(false);

  const dispatch = useAppDispatch();

  const fieldPropertyId = form.getFieldValue('partsAndInventoriesCardPropertyId');
  /* It checks if the property is added via a form instance */
  const isFormInstancePropertyId = fieldPropertyId && fieldPropertyId !== propertyTypeValue?.value;

  useEffect(() => {
    if (!listPartAndInventoryProperty.length) {
      dispatch(getPaIProperties());
    }

    if (!listPartAndInventoryPropertyType.length) {
      dispatch(getPaIPropertyTypes());
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      partsAndInventoriesPropertyDetalisModelList: initialValues ? initialValues : [],
    });
  }, []);

  /**
   * It checks if the submittedValues array contains an object with a property of
   * partsAndInventoriesCardPropertyId that matches the id passed in as an argument
   * @param {number} id - number - the id of the property
   * @returns Boolean
   */
  const checkSubmittedValues = (id: number) => {
    const result = submittedValues.find((value) => value.partsAndInventoriesCardPropertyId === id);
    return Boolean(result);
  };

  /* ------------------------- Asset Property Options ------------------------- */
  const partAndInventoryPropertyOptions = useMemo(() => {
    return listPartAndInventoryProperty.reduce((acc: PropertyTypeValueOption[], property) => {
          if ((!property.isUnique || !submittedValues.some(
              (value) =>
                value.partsAndInventoriesCardPropertyId ===
                property.partsAndInventoriesCardPropertyId
            ))
          ) {
            return [
              ...acc,
              {
                label: property.name,
                value: property.partsAndInventoriesCardPropertyId,
                propertyType: property.partsAndInventoriesPropertyType.name,
                isUnique: property.isUnique,
              },
            ] as PropertyTypeValueOption[];
          }
          return acc;
        }, [])
  }, [listPartAndInventoryProperty, listPartAndInventoryPropertyType, submittedValues]);

  const propertyTypeValueOptions = useCallback(
    (PropertyID: number) => {
      const foundProperty = listPartAndInventoryProperty.find(
        (property) => property.partsAndInventoriesCardPropertyId === PropertyID
      );
      if (foundProperty) {
        return foundProperty.partsAndInventoriesPropertyValues.map(
          (value): PropertyValueOption => ({
            label: value.value,
            value: value.partsAndInventoriesPropertyValueId,
          })
        );
      }
    },
    [listPartAndInventoryProperty, propertyTypeValue]
  );

  /* --------------------------- Handle fields value  -------------------------- */
  function handleFieldValue(value: ChangeEvent<HTMLInputElement>): void;
  function handleFieldValue(
    value: number,
    option: PropertyValueOption | PropertyValueOption[]
  ): void;
  function handleFieldValue(
    value?: number | ChangeEvent<HTMLInputElement>,
    option?: PropertyValueOption | PropertyValueOption[]
  ): void {
    if (typeof value === 'number' && option && !Array.isArray(option)) {
      const newOptionValue = {
        value: option.label,
        partsAndInventoriesCardPropertyId: propertyTypeValue?.value || 0,
      };

      setPropertyValue(newOptionValue);
      return;
    }

    if(value) {
      const {
        target: { value: inputValue },
      } = value as ChangeEvent<HTMLInputElement>;
  
      const newInputValue = {
        value: inputValue,
        partsAndInventoriesCardPropertyId: propertyTypeValue?.value || 0,
      };
      setPropertyValue(newInputValue);
    }
  }

  /* ------------------- Addition function for property value ------------------- */
  /* If we added a parameter via form instance */
  /* Sets the correct parameter to be able to add a Property Value */
  function getPropertyById(id: number) {
    const property = listPartAndInventoryProperty.filter(obj => obj.partsAndInventoriesCardPropertyId === id)[0];

    return {
      label: property.name,
      value: property.partsAndInventoriesCardPropertyId,
      propertyType: property.partsAndInventoriesPropertyType.name,
      propertyName: property.name,
      isUnique: property.isUnique,
    } as PropertyTypeValueOption
  }

  useEffect(() => {
    if(listPartAndInventoryProperty.length > 0 && isFormInstancePropertyId) {
      const newProperty = getPropertyById(fieldPropertyId);
      
      setPropertyTypeValue(newProperty)
    }
  }, [isFormInstancePropertyId])

  /* ------------------------- Submitting fields value ------------------------ */

  const onClickOK = (add: (defValue: any) => void) => {
    setSubmittedValues((prevValue) => {
      if (propertyValue && propertyValue.value.length) {
        add(propertyValue);
        return [...prevValue, propertyValue];
      }
      return prevValue;
    });

    setPropertyTypeValue(null);
    setPropertyValue(null);

    if (propertyTypeValue?.isUnique) {      
      form.setFieldValue('partsAndInventoriesCardPropertyId', null);
    }
  };
  const buttonOK = (add: (defValue: any) => void) => {
    const btnValidation = (propertyValue && propertyValue.value.length > 50) || undefined;

    return (
      <Button 
        onClick={() => onClickOK(add)} 
        className="asset-property-values__button-ok" 
        disabled={btnValidation || fieldError}
      >
        {t('titles.Ok')}
      </Button>
    );
  };

  const isSubmittedValue = checkSubmittedValues(fieldPropertyId);         
  
  const valueFiled = (add: (defValue: any) => void) => {
    if (propertyTypeValue?.propertyType === 'List') {
      const options = propertyTypeValueOptions(propertyTypeValue.value);

      return (
        <Item 
          label="Property value"
          name="selectValue"
          required={!isSubmittedValue}
          rules={[
            () => ({
              validator: () => {
                if (!isSubmittedValue) {
                  return Promise.reject(
                    new Error('Please confirm the value')
                  );
                }
                return Promise.resolve();
              },
              validateTrigger: 'onSubmit'
            })
          ]}
        >
          <Row align="middle">
            <Col span={20}>
              <Select
                showSearch={false}
                onChange={handleFieldValue}
                className="asset-property-values__property-value-select"
                options={options}
                placeholder="Choose property value"
              />
            </Col>
            <Col span={4}>{buttonOK(add)}</Col>
          </Row>
        </Item>
      );
    }
    if (propertyTypeValue && propertyTypeValue?.propertyType !== 'List') {
      return (
        <Item 
          label={t('titles.Property_Value')}
          name="inputValue"
          rules={[
            {
              max: 50,
              required: !isSubmittedValue,
            },
            () => ({
              validator: (_, value) => {                
                if (!isSubmittedValue && value) {
                  return Promise.reject(
                    new Error(t('titles.Confirm_Validate_Message'))
                  );
                }
                return Promise.resolve();
              },
              validateTrigger: 'onSubmit'
            }),
            () => ({
              validator: (_, value:string) => {                
                if (value && propertyTypeValue?.propertyType === 'Decimal' && !value.match(/\-?\d+\.\d+/)) {
                  setFieldError(true);

                  return Promise.reject(
                    new Error(t('titles.Decimal_Property_Validate_Message'))
                  );
                }
                if (value && propertyTypeValue?.propertyType === 'Number' && !value.match(/^(\+|-)?\d+$/)) {
                  setFieldError(true);

                  return Promise.reject(
                    new Error(t('titles.Number_Property_Validate_Message'))
                  );
                }
                return Promise.resolve(
                  setFieldError(false)
                );
              },
            }),
          ]}
        >
          <Row align="middle">
            <Col span={20}>
              <Input
                onChange={handleFieldValue}
                className="asset-property-values__property-value-input"
                placeholder={t('titles.Property_Value')}
              />
            </Col>
            <Col span={4}>{buttonOK(add)}</Col>
          </Row>
        </Item>
      );
    }

    return (
      <Item
        label={t('titles.Property_Value')}
        help={t('titles.Choose_Name', { name: t('titles.Property') })}
      >
        <Input readOnly disabled placeholder={t('titles.Property_Value')} />
      </Item>
    );
  };

  /* ------------------------- handle Property select ------------------------- */
  function onChangeProperty(value: number, option: any) {
    setPropertyTypeValue(option);
  }

  return (
    <>
    <Item 
      label="Property" 
      name='partsAndInventoriesCardPropertyId' 
      extra={propertyTypeValue?.isUnique ? t('titles.Property_Is_Unique') : null}
    >
      <Select
        value={propertyTypeValue?.value}
        onChange={onChangeProperty}
        loading={statusPartAndInventoryProperty === 'pending'}
        placeholder={t('titles.Property')}
        isCreatable={isCreatable}
        creatableAction={creatableAction}
      >
        {partAndInventoryPropertyOptions.map(option => {
          return (
            <Option key={option.value} {...option}>
              {option.label}
              <span className='property-values__option-type'>
                {option.propertyType}
              </span>
            </Option>
          )})}
      </Select>
    </Item>
      <List name="partsAndInventoriesPropertyDetalisModelList">
        {(fields, { add, remove }) => {
          return (
            <div>
              <div>{valueFiled(add)}</div>
              <div className="asset-property-values__list-items-wrapper">
                {fields.map(({ key, name, ...restFields }) => {
                  const value = form.getFieldValue([
                    'partsAndInventoriesPropertyDetalisModelList',
                    name,
                  ]) as PropertyValue;

                  const fieldName = listPartAndInventoryProperty.find(
                    (property) =>
                      property.partsAndInventoriesCardPropertyId ===
                      value.partsAndInventoriesCardPropertyId
                  );

                  return (
                    <div key={key}>
                      <Item {...restFields} hidden name={[name, 'value']}>
                        <Input />
                      </Item>
                      <Item
                        {...restFields}
                        hidden
                        name={[name, 'partsAndInventoriesCardPropertyId']}
                      >
                        <Input />
                      </Item>

                      <Row className="asset-property-values__list-item" justify="space-between">
                        <Col span={22}>
                          <p className="asset-property-values__list-item-value">
                            <span>{fieldName?.name}:</span> {value.value}
                          </p>
                        </Col>
                        <Col>
                          <MinusCircleOutlined
                            onClick={() => {
                              setSubmittedValues(
                                submittedValues.filter(
                                  (val) =>
                                    val.partsAndInventoriesCardPropertyId !==
                                    value.partsAndInventoriesCardPropertyId
                                )
                              );
                              remove(name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      </List>
    </>
  );
};

export { PAIPropertyValues };
