import { createSlice } from '@reduxjs/toolkit';
import {
  addPaIStatus,
  getPaIStatusById,
  getPaIStatuses,
  removePaIStatusById,
  updatePaIStatus,
} from '@store/actions/properties/PaI/Status';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { PaIStatusModel } from '@model/properties/PaI/Status';

interface PaIStatusState {
  PaIStatusesList: PaIStatusModel[];
  PaIStatusStatus: LoadingStatus;
  PaIStatus: null | PaIStatusModel;
  PaIStatusError: null | string;
}

const initialState: PaIStatusState = {
  PaIStatusesList: [],
  PaIStatusStatus: 'idle',
  PaIStatus: null,
  PaIStatusError: null,
};

const PaIStatusSlice = createSlice({
  name: 'PaIStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaIStatuses.pending, (state) => {
        state.PaIStatusError = null;
        state.PaIStatusStatus = 'pending';
      })
      .addCase(getPaIStatuses.fulfilled, (state, { payload }) => {
        state.PaIStatusStatus = 'idle';
        state.PaIStatusesList = payload;
      })
      .addCase(getPaIStatuses.rejected, (state, { payload }) => {
        state.PaIStatusStatus = 'idle';
        if (payload) {
          state.PaIStatusError = payload;
        }
      });

    builder
      .addCase(addPaIStatus.pending, (state) => {
        state.PaIStatusStatus = 'pending';
        state.PaIStatusError = null;
      })
      .addCase(addPaIStatus.fulfilled, (state, { payload }) => {
        state.PaIStatusStatus = 'idle';
        state.PaIStatusesList = [payload, ...state.PaIStatusesList];
      })
      .addCase(addPaIStatus.rejected, (state, { payload }) => {
        state.PaIStatusStatus = 'idle';
        if (payload) {
          state.PaIStatusError = payload;
        }
      });

    builder
      .addCase(getPaIStatusById.pending, (state) => {
        state.PaIStatusStatus = 'pending';
        state.PaIStatusError = null;
      })
      .addCase(getPaIStatusById.fulfilled, (state, { payload }) => {
        state.PaIStatusStatus = 'idle';
        state.PaIStatus = payload;
      })
      .addCase(getPaIStatusById.rejected, (state, { payload }) => {
        state.PaIStatusStatus = 'idle';
        if (payload) {
          state.PaIStatusError = payload;
        }
      });

    builder
      .addCase(updatePaIStatus.pending, (state) => {
        state.PaIStatusStatus = 'pending';
        state.PaIStatusError = null;
      })
      .addCase(updatePaIStatus.fulfilled, (state, { payload }) => {
        state.PaIStatusStatus = 'idle';
        state.PaIStatusesList = payload.models;
        state.PaIStatus = payload.particularModel;
      })
      .addCase(updatePaIStatus.rejected, (state, { payload }) => {
        state.PaIStatusStatus = 'idle';
        if (payload) {
          state.PaIStatusError = payload;
        }
      });

    builder
      .addCase(removePaIStatusById.pending, (state) => {
        state.PaIStatusStatus = 'pending';
        state.PaIStatusError = null;
      })
      .addCase(removePaIStatusById.fulfilled, (state, { payload }) => {
        state.PaIStatusStatus = 'idle';
        state.PaIStatusesList = payload;
      })
      .addCase(removePaIStatusById.rejected, (state, { payload }) => {
        state.PaIStatusStatus = 'idle';
        if (payload) {
          state.PaIStatusError = payload;
        }
      });
  },
});

const PaIStatusReducer = PaIStatusSlice.reducer;

export { PaIStatusReducer };
