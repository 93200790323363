import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormUser } from '@components/views/Forms/users';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addUser } from '@store/actions/user/User';

import type { CreateUserDto } from '@model/users/User';
import type { FormInstance } from 'antd';

const CreateUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { t } = useTranslation();

  const { userAccess } = useGetAccess();

  const onFinish = (dto: CreateUserDto, form?: FormInstance<CreateUserDto>) => {
    dispatch(addUser(dto))
      .unwrap()
      .then((response) => {
        ApiNotifications.create(response.createdUser.userCode);
        form?.resetFields();
        navigate(paths.users.default);
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <CheckAccess hasAccess={userAccess.create} redirectTo={paths.users.default}>
      <FormUser
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.User') }),
        }}
      />
    </CheckAccess>
  );
};

export default CreateUser;
