import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassPrefix } from '@hooks';

import { RoleAuthProvider } from './RoleAuthProvider';
import { RolesList } from './RolesList';

import type { RoleFunctionNameModel } from '@model/users/Role';

import './RoleAuthorizationsList.scss';

interface RoleAuthorizationsListProps {
  isPreview?: boolean;
  onChange?: (value: Array<RoleFunctionNameModel>) => void;

  value?: Array<RoleFunctionNameModel>;
}

export const RoleAuthorizationsList = memo((props: RoleAuthorizationsListProps) => {
  const { isPreview = false, onChange, value: authList = [] } = props;

  const { t } = useTranslation();

  const { prefix, withPrefix } = useClassPrefix('role-auth-names');

  const handleChange = (value: RoleFunctionNameModel) => {
    const newList = authList.map((authItem) => {
      return authItem.roleFunctionNameId === value.roleFunctionNameId ? value : authItem;
    });

    onChange?.(newList);
  };

  return (
    <RoleAuthProvider isPreview={isPreview} onChange={handleChange}>
      <div className={prefix}>
        <div className={withPrefix('header')}>
          <h5>{t('pages_single.List_Of_Functions')}</h5>
        </div>
        <RolesList authorizationsList={authList} />
      </div>
    </RoleAuthProvider>
  );
});
