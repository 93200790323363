import React from 'react';

import { baseFormatDate } from '@constants/Date';
import generatePicker from 'antd/es/date-picker/generatePicker';
import cl from 'classnames';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import 'antd/es/date-picker/style/index';

import type { RangePickerDateProps } from 'antd/es/date-picker/generatePicker';

import './RangePicker.scss';

interface RangePickerProps extends RangePickerDateProps<Date> {}

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

const {RangePicker: AntRangePicker} = DatePicker;

const RangePicker = (props: RangePickerProps) => {
  const {format = baseFormatDate, className, ...restProps} = props;

  const separator = '-';

  const classNames = cl('custom-range-picker', className);

  return (
    <AntRangePicker
      popupClassName="custom-range-picker-dropdown"
      className={classNames}
      separator={separator}
      format={format}
      {...restProps}
    />
  );
};

export {RangePicker};
