import React from 'react';

import { FormDepartment } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletDepartment } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateDepartment } from '@store/actions/properties/Department';

import type { CreateDepartmentDto } from '@model/properties/Department';

function EditDepartment() {
  const { initialValues, entity } = useOutletDepartment();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { maintenanceAttrAccess } = useGetAccess();

  const onFinish = (dto: CreateDepartmentDto) => {
    dispatch(updateDepartment({ ...dto, departmentId: entity.departmentId }))
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.department.name);
        navigate(paths.department.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <CheckAccess hasAccess={maintenanceAttrAccess.edit} redirectTo={paths.department.default}>
      <FormDepartment
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditDepartment;
