import * as React from 'react';
import { memo } from 'react';

import type { SVGProps } from 'react';

export const LocationIcon = memo((props: SVGProps<SVGSVGElement>) => {
  const { fill = '#fff', ...restProps } = props;
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M18.75 9.75a6.75 6.75 0 0 0-13.5 0c0 2.769 2.215 6.378 6.75 10.701 4.535-4.323 6.75-7.932 6.75-10.701ZM12 22.5c-5.5-5-8.25-9.25-8.25-12.75a8.25 8.25 0 1 1 16.5 0c0 3.5-2.75 7.75-8.25 12.75Z"
        fill={fill}
      />
      <path
        d="M12 12a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Zm0 1.5A3.75 3.75 0 1 1 12 6a3.75 3.75 0 0 1 0 7.5Z"
        fill={fill}
      />
    </svg>
  );
});
