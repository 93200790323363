import * as React from 'react';

import type { SVGProps } from 'react';

const DecimalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.75 11.25h1.5v1.5h-1.5v-1.5Z" fill={props.fill || '#E0E0E0'} />
    <path
      d="M18 17.25h-3a1.502 1.502 0 0 1-1.5-1.5v-7.5a1.502 1.502 0 0 1 1.5-1.5h3a1.501 1.501 0 0 1 1.5 1.5v7.5a1.502 1.502 0 0 1-1.5 1.5Zm-3-9v7.5h3v-7.5h-3Zm-6.75 3h1.5v1.5h-1.5v-1.5Z"
      fill={props.fill || '#E0E0E0'}
    />
    <path
      d="M10.5 17.25h-3a1.502 1.502 0 0 1-1.5-1.5v-7.5a1.502 1.502 0 0 1 1.5-1.5h3a1.501 1.501 0 0 1 1.5 1.5v7.5a1.502 1.502 0 0 1-1.5 1.5Zm-3-9v7.5h3v-7.5h-3ZM3 15.75h1.5v1.5H3v-1.5Z"
      fill={props.fill || '#E0E0E0'}
    />
  </svg>
);

export { DecimalIcon };
