import { formatMomentDate } from '@shared/utils/functions';

import type { CreateMaintenanceGeneralV2Dto, DateSelectorDto } from '@model/management/Maintenance';

export function isRequestTrueDto(
  dto: CreateMaintenanceGeneralV2Dto
): CreateMaintenanceGeneralV2Dto {
  const { maintenanceOccurrenceId, ...restDto } = dto;
  return {
    ...restDto,
    isRequest: true,
    // maintenanceOccurrenceId: 0,
    // frequanceNumeric: 1,
    startDate: formatMomentDate(dto.startDate),
    dateSelector: {} as DateSelectorDto,
  } as CreateMaintenanceGeneralV2Dto;
}
