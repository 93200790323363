import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getPaIUnits } from '@store/actions/properties/PaI/Unit';
import { selectPaIUnitsList } from '@store/selectors/properties/PaI/Unit';

function EntryPartAndInventoryUnit() {
  useSetPageInfo(
    {
      pageTitle: 'pages_single.PaI_Attr',
      defaultPagePath: paths.partAndInventoryAttributes.tabs.unit.default,
    },
    {
      copyTabs: true,
    }
  );

  const partAndInventoryUnitList = useAppSelector(selectPaIUnitsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!partAndInventoryUnitList.length) {
      dispatch(getPaIUnits());
    }
  }, []);

  return <Outlet />;
}

export default EntryPartAndInventoryUnit;
