import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Input, Select } from '@shared/UI';
import { getAsAtCategories } from '@store/actions/properties/AsAt/Category';
import {
  selectAsAtCategoriesListWithoutChildren,
  selectAsAtCategoryStatus,
} from '@store/selectors/properties/AsAt/Category';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateAsAtCategoryDto } from '@model/properties/AsAt/Category';

const { CaseField, Item, useForm } = CommonForm;

function FormAssetCategory(props: CommonFormProps<CreateAsAtCategoryDto>) {
  const [form] = useForm<CreateAsAtCategoryDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const assetCategoryStatus = useAppSelector(selectAsAtCategoryStatus);
  const assetCategoryListWithoutChildren = useAppSelector(selectAsAtCategoriesListWithoutChildren);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!assetCategoryListWithoutChildren.length) {
      dispatch(getAsAtCategories());
    }
  }, []);

  const categoryOptions = useMemo(() => {
    return assetCategoryListWithoutChildren
      .map((category) => ({
        value: category.nonCurrAssetCardCategoryId,
        label: category.name,
      }))
      .filter((option) => {
        if (rest.initialValues) {
          return option.label !== rest.initialValues.name;
        }
        return option;
      });
  }, [assetCategoryListWithoutChildren]);

  return (
    <CommonForm 
      form={form} 
      loading={assetCategoryStatus === 'pending'} 
      isModal={isModal}
      name="formAssetCategory"
      additionalFooter={
        isModal ? 
        <ModalFormFooter
          onCancel={rest.header?.onClickCancel}
        /> : null
      }
      {...rest}
    >
      <CaseField isModal={isModal}>
        <Item
          label={t('titles.Code')}
          name="nonCurrAssetCardCategoryCode"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item label={t('titles.Parent_Category')} name="parentNonCurrAssetCardCategoryId">
          <Select
            options={categoryOptions}
            placeholder={t('titles.Choose_Name', { name: t('titles.Parent_Category') })}
            loading={assetCategoryStatus === 'pending'}
          />
        </Item>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormAssetCategory };
