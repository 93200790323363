import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, SelectSite } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Input, Select } from '@shared/UI';
import { getDepartments } from '@store/actions/properties/Department';
import {
  selectDepartmentsListWithoutChildren,
  selectDepartmentStatus,
} from '@store/selectors/properties/Department';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateDepartmentDto } from '@model/properties/Department';

const { CaseField, Item, useForm } = CommonForm;

function FormDepartment(props: CommonFormProps<CreateDepartmentDto>) {
  const [form] = useForm<CreateDepartmentDto>();

  const { t } = useTranslation();

  const departmentStatus = useAppSelector(selectDepartmentStatus);
  const listDepartmentWithoutChildren = useAppSelector(selectDepartmentsListWithoutChildren);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listDepartmentWithoutChildren.length) {
      dispatch(getDepartments());
    }
  }, []);

  const optionsParentDepartment = useMemo(() => {
    return listDepartmentWithoutChildren
      .map((department) => ({
        value: department.departmentId,
        label: department.name,
      }))
      .filter((department) => {
        if (props.initialValues) {
          return department.label !== props.initialValues.name;
        }
        return department;
      });
  }, [listDepartmentWithoutChildren]);

  const rulesSite = useMemo(() => [{ required: true }], []);

  return (
    <CommonForm form={form} loading={departmentStatus === 'pending'} {...props}>
      <CaseField>
        <Item
          label={t('titles.Name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item
          label={t('titles.Code')}
          name="departmentCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
      </CaseField>
      <CaseField>
        <Item label={t('titles.Parent_Department')} name="parentDepartmentId">
          <Select
            placeholder={t('titles.Choose_Name', { name: t('titles.Parent_Department') })}
            loading={departmentStatus === 'pending'}
            options={optionsParentDepartment}
          />
        </Item>
        <SelectSite rules={rulesSite} />
      </CaseField>
    </CommonForm>
  );
}

export { FormDepartment };
