import { createSlice } from '@reduxjs/toolkit';
import {
  addPaIBrand,
  getPaIBrandById,
  getPaIBrands,
  removePaIBrandById,
  updatePaIBrand,
} from '@store/actions/properties/PaI/Brand';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { PaIBrandModel } from '@model/properties/PaI/Brand';

interface PaIBrandState {
  PaIBrandsList: PaIBrandModel[];
  PaIBrandStatus: LoadingStatus;
  PaIBrand: null | PaIBrandModel;
  PaIBrandError: null | string;
}

const initialState: PaIBrandState = {
  PaIBrandsList: [],
  PaIBrandStatus: 'idle',
  PaIBrand: null,
  PaIBrandError: null,
};

const PaIBrandSlice = createSlice({
  name: 'PaIBrand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaIBrands.pending, (state) => {
        state.PaIBrandError = null;
        state.PaIBrandStatus = 'pending';
      })
      .addCase(getPaIBrands.fulfilled, (state, { payload }) => {
        state.PaIBrandStatus = 'idle';
        state.PaIBrandsList = payload;
      })
      .addCase(getPaIBrands.rejected, (state, { payload }) => {
        state.PaIBrandStatus = 'idle';
        if (payload) {
          state.PaIBrandError = payload;
        }
      });

    builder
      .addCase(addPaIBrand.pending, (state) => {
        state.PaIBrandStatus = 'pending';
        state.PaIBrandError = null;
      })
      .addCase(addPaIBrand.fulfilled, (state, { payload }) => {
        state.PaIBrandStatus = 'idle';
        state.PaIBrandsList = [payload, ...state.PaIBrandsList];
      })
      .addCase(addPaIBrand.rejected, (state, { payload }) => {
        state.PaIBrandStatus = 'idle';
        if (payload) {
          state.PaIBrandError = payload;
        }
      });

    builder
      .addCase(getPaIBrandById.pending, (state) => {
        state.PaIBrandStatus = 'pending';
        state.PaIBrandError = null;
      })
      .addCase(getPaIBrandById.fulfilled, (state, { payload }) => {
        state.PaIBrandStatus = 'idle';
        state.PaIBrand = payload;
      })
      .addCase(getPaIBrandById.rejected, (state, { payload }) => {
        state.PaIBrandStatus = 'idle';
        if (payload) {
          state.PaIBrandError = payload;
        }
      });

    builder
      .addCase(updatePaIBrand.pending, (state) => {
        state.PaIBrandStatus = 'pending';
        state.PaIBrandError = null;
      })
      .addCase(updatePaIBrand.fulfilled, (state, { payload }) => {
        state.PaIBrandStatus = 'idle';
        state.PaIBrandsList = payload.brands;
        state.PaIBrand = payload.particularBrand;
      })
      .addCase(updatePaIBrand.rejected, (state, { payload }) => {
        state.PaIBrandStatus = 'idle';
        if (payload) {
          state.PaIBrandError = payload;
        }
      });

    builder
      .addCase(removePaIBrandById.pending, (state) => {
        state.PaIBrandStatus = 'pending';
        state.PaIBrandError = null;
      })
      .addCase(removePaIBrandById.fulfilled, (state, { payload }) => {
        state.PaIBrandStatus = 'idle';
        state.PaIBrandsList = payload;
      })
      .addCase(removePaIBrandById.rejected, (state, { payload }) => {
        state.PaIBrandStatus = 'idle';
        if (payload) {
          state.PaIBrandError = payload;
        }
      });
  },
});

const PaIBrandReducer = PaIBrandSlice.reducer;

export { PaIBrandReducer };
