import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage, MaintenanceSwitcher } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { compare } from '@shared/utils/functions';
import { getSites } from '@store/actions/properties/Site';
import { getUsers } from '@store/actions/user/User';
import {
  selectMaintenancesListWithFormattedDate,
  selectMaintenanceStatus,
} from '@store/selectors/management/Maintenance';
import { selectSitesList } from '@store/selectors/properties/Site';
import { selectUsersList } from '@store/selectors/users/User';

import { MaintenanceProvider } from './MaintenanceContext';
import { MaintenanceToolbar } from './MaintenanceToolbar';

import type { BasicTablePageProps} from '@components/common';
import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { MaintenanceModel } from '@model/management/Maintenance';
import type { TableProps } from '@shared/UI';
import type { Key} from 'react';

import './Maintenances.scss';

const columns: ColumnsTableType<MaintenanceModel & { assignedName: string }> = [
  {
    title: <Translation>{(t) => t('titles.Title')}</Translation>,
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    sorter: (a, b) => compare(a.title, b.title),
  },
  {
    title: <Translation>{(t) => t('titles.Priority')}</Translation>,
    dataIndex: ['maintenancePriority', 'priorityName'],
    key: 'priority',
    ellipsis: true,
    sorter: (a, b) =>
      compare(a.maintenancePriority.priorityName, b.maintenancePriority.priorityName),
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: ['site', 'name'],
    key: 'site',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Assign_To')}</Translation>,
    dataIndex: 'assignedName',
    key: 'assignedName',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Due_Date')}</Translation>,
    dataIndex: 'dueDate',
    key: 'dueDate',
    ellipsis: true,
    sorter: (a, b) => compare(a.dueDate || '', b.dueDate || ''),
  },
];

const Maintenances = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [checkedMaintenances, setCheckedMaintenances] = useState<MaintenanceModel[]>([]);

  const listMaintenances = useAppSelector(selectMaintenancesListWithFormattedDate);
  const statusMaintenance = useAppSelector(selectMaintenanceStatus);

  const listUsers = useAppSelector(selectUsersList);

  const listSites = useAppSelector(selectSitesList);

  useEffect(() => {
    if (!listUsers.length) {
      dispatch(getUsers());
    }

    if (!listSites.length) {
      dispatch(getSites());
    }
  }, []);

  const dataSource = useMemo(
    () =>
      listMaintenances.map((maintenance) => {
        const assignedUser = listUsers.find((user) => user.userId === maintenance.assignToId);
        const userName = assignedUser ? `${assignedUser.firstName} ${assignedUser.lastName}` : '';

        return {
          ...maintenance,
          assignedName: userName,
        };
      }),
    [listMaintenances, listUsers]
  );

  const handleCheckedChange = useCallback((_: Key[], items: MaintenanceModel[]) => {
    setCheckedMaintenances(items);
  }, []);

  const clearChecked = useCallback(() => {
    setCheckedMaintenances(() => []);
  }, []);

  const selectedRowKeys = useMemo(
    () => checkedMaintenances.map((m) => m.maintenanceId),
    [checkedMaintenances]
  );

  const rowSelectionProps: TableProps<MaintenanceModel>['rowSelection'] = {
    selectedRowKeys,
    onChange: handleCheckedChange,
  };

  const table: BasicTablePageProps<MaintenanceModel & { assignedName: string }>['table'] = {
    loading: statusMaintenance === 'pending',
    dataSource: dataSource,
    columns: columns,
    rowKey: (record) => record.maintenanceId,
    dataSourceName: t('pages_plural.Maintenances'),
    rowSelection: rowSelectionProps,
  };

  const header: BasicTablePageProps['header'] = {
    titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Maintenance') }),
    title: <MaintenanceSwitcher styles={{ marginLeft: 50 }} />,
  };

  const customHeader = () => <MaintenanceToolbar />;

  return (
    <MaintenanceProvider checkedMaintenances={checkedMaintenances} clearChecked={clearChecked}>
      <BasicTablePage
        table={table}
        header={header}
        customHeader={checkedMaintenances.length ? customHeader : undefined}
        additionalChildren={
          <div className="maintenance-total-number">
            <span>
              {t('titles.Total')}: {listMaintenances.length}
            </span>
          </div>
        }
      />
    </MaintenanceProvider>
  );
};

export { Maintenances };
