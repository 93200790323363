import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { Checkbox } from '@shared/UI';
import { selectPaIPropertyStatus } from '@store/selectors/properties/PaI/Property';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { PaIPropertyModel } from '@model/properties/PaI/Property';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormPartAndInventoryProperty(props: CommonPreviewProps<PaIPropertyModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusPartAndInventoryProperty = useAppSelector(selectPaIPropertyStatus);

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={statusPartAndInventoryProperty === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField hiddenTitle>
        <Item>
          <Checkbox checked={entity.isUnique}>{t('titles.Is_Unique')}</Checkbox>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.partsAndInventoriesCardPropertyCode}</PreviewField>
        </Item>
        <Item label={t('titles.Property_Type')}>
          <PreviewField>{entity.partsAndInventoriesPropertyType.name}</PreviewField>
        </Item>

        {entity.partsAndInventoriesPropertyValues.length
          ? entity.partsAndInventoriesPropertyValues.map((propertyValue) => (
              <PreviewField
                typeField="property-value"
                key={propertyValue.partsAndInventoriesPropertyValueId}
              >
                {propertyValue.value}
              </PreviewField>
            ))
          : null}
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormPartAndInventoryProperty };
