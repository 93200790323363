import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsAtBrandRepository } from '@repositories';

import type {
  AsAtBrandModel,
  CreateAsAtBrandDto,
  UpdateAsAtBrandDto,
} from '@model/properties/AsAt/Brand';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getAsAtBrands = createAsyncThunk<AsAtBrandModel[], void, { rejectValue: string }>(
  'AsAtBrand/getAsAtBrands',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AsAtBrandRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getAsAtBrandById = createAsyncThunk<AsAtBrandModel, string, { rejectValue: string }>(
  'AsAtBrand/getAsAtBrandById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await AsAtBrandRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addAsAtBrand = createAsyncThunk<
  AsAtBrandModel,
  CreateAsAtBrandDto,
  { rejectValue: string }
>('AsAtBrand/addAsAtBrand', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtBrandRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateAsAtBrand = createAsyncThunk<
  { AsAtBrandsList: AsAtBrandModel[]; AsAtBrand: AsAtBrandModel },
  UpdateAsAtBrandDto,
  { rejectValue: string }
>('AsAtBrand/updateAsAtBrand', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtBrandRepository.update(dto);

    const responseList = await AsAtBrandRepository.fetchAll();

    return {
      AsAtBrandsList: responseList.resultObject,
      AsAtBrand: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeAsAtBrandById = createAsyncThunk<
  AsAtBrandModel[],
  number[],
  { rejectValue: string }
>('AsAtBrand/removeAsAtBrandById', async (ids, { rejectWithValue }) => {
  try {
    await AsAtBrandRepository.removeById(ids);
    const response = await AsAtBrandRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
