import type { RootState } from '@store/rootReducer';

export const selectAsAtStatusState = (state: RootState) => state.AsAtStatusReducer;

export const selectAsAtStatusesList = (state: RootState) =>
  selectAsAtStatusState(state).AsAtStatusesList;

export const selectAsAtStatus = (state: RootState) => selectAsAtStatusState(state).AsAtStatus;

export const selectAsAtStatusStatus = (state: RootState) =>
  selectAsAtStatusState(state).AsAtStatusStatus;

export const selectAsAtStatusError = (state: RootState) =>
  selectAsAtStatusState(state).AsAtStatusError;
