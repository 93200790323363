import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';

import cl from 'classnames';

import { DraggableItem } from '../DraggableItem';

import type { DropModel } from '@constants/DragAndDrop';
import type { GetKey } from '@constants/GetKey';
import type { ReactNode} from 'react';

import './Column.scss';

export type DragEnd<T = any> = DropModel<T> & {
  dragType: string;
  targetColumn: string;
};

export interface ColumnProps<T = any> {
  accept: string | string[];
  type: string;
  data: T[];
  dataKey: GetKey<T>;
  onDrop?: (record: DragEnd<T>) => void;
  className?: string;
  style?: React.CSSProperties;
  title?: ReactNode;
  headerColor?: React.CSSProperties['backgroundColor'];
  cellContentRender?: (record: T) => ReactNode;
  onClickCell?: (record: T) => void;
}

const Column = <T,>(props: ColumnProps<T>) => {
  const {
    type,
    data,
    accept,
    title,
    style,
    className,
    headerColor,
    dataKey,
    onDrop,
    cellContentRender,
    onClickCell,
  } = props;

  const defClass = 'kanban-column';
  const classNames = cl(defClass, className);
  const ref = useRef<HTMLDivElement>(null);

  const headerStyles: React.CSSProperties = {
    backgroundColor: headerColor,
  };

  /* ------------------------------- Render Item ------------------------------ */
  const renderRecords = () => {
    return data.map((record) => {
      const key = dataKey(record);

      return (
        <DraggableItem
          key={key}
          type={type}
          record={record}
          cellContentRender={cellContentRender}
          onClickCell={onClickCell}
        />
      );
    });
  };

  /* ------------------------------- DnD Logic ------------------------------ */
  const [{}, drop] = useDrop({
    accept,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),

    drop: (item: DragEnd<T>) => {
      onDrop?.({ ...item, targetColumn: type });
    },
  });

  drop(ref);

  return (
    <div ref={ref} style={style} className={classNames}>
      <div style={headerStyles} className={`${defClass}-header`}>
        <span>{title}</span>
      </div>
      <div className={`${defClass}-content`}>{renderRecords()}</div>
    </div>
  );
};

export { Column };
