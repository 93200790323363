import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getCostCenters } from '@store/actions/properties/CostCenter';
import { selectCostCentersList } from '@store/selectors/properties/CostCenter';

function EntryCostCenter() {
  const { t } = useTranslation();

  useSetPageInfo({
    defaultPagePath: paths.costCenter.default,
    pageTitle: t('pages_single.Cost_Center'),
  });

  const listCostCenter = useAppSelector(selectCostCentersList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listCostCenter.length) {
      dispatch(getCostCenters());
    }
  }, []);

  return <Outlet />;
}

export default EntryCostCenter;
