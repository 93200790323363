import { AccessKey } from '@constants/AccessKey';
import { createSelector } from '@reduxjs/toolkit';

import type {
  AssetAccess,
  AssetAttrAccess,
  ContractAccess,
  CostCenterAccess,
  CountPlanAccess,
  DepartmentAccess,
  ExitTypeAccess,
  MaintenanceAttrAccess,
  PaIAccess,
  PaIAttrAccess,
  SiteAccess,
  UserAccess,
  VendorAccess,
} from '@model/profile/Profile';
import type { RoleFunctionNameModel, RoleModel } from '@model/users/Role';
import type { RootState } from '@store/rootReducer';

export const selectProfileState = (state: RootState) => state.ProfileReducer;

export const selectProfile = (state: RootState) => selectProfileState(state).profile;

export const selectProfileRole = (state: RootState) => selectProfileState(state).profileRole;

export const selectProfileLoading = (state: RootState) => selectProfileState(state).profileStatus;

export const selectErrorProfile = (state: RootState) => selectProfileState(state).errorProfile;

export const selectPaIRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: PaIAccess = {
    view: false,

    consume: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.PaI,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_parts_and_inventories') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_part_and_inventory') {
      accesses = {
        ...accesses,
        view: value,
      };
    }

    if (roleFunctionKeywordName === 'role_func_edit_part_and_inventory_list') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_part_and_inventory') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_part_and-inventory') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_consume_part_and_inventory') {
      accesses = {
        ...accesses,
        consume: value,
      };
    }
  });

  return accesses;
});

export const selectAssetAttrRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: AssetAttrAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.AssetAttributes,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_asset_attributes') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_asset_attributes') {
      accesses = {
        ...accesses,
        view: value,
      };
    }

    if (roleFunctionKeywordName === 'role_func_edit_asset_attributes') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_asset_attributes') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_asset_attributes') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
  });

  return accesses;
});

export const selectPaIAttrRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: PaIAttrAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.PaIAttributes,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_pai_attributes') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_pai_attributes') {
      accesses = {
        ...accesses,
        view: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_edit_pai_attributes') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_pai_attributes') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_pai_attributes') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
  });

  return accesses;
});

export const selectMaintenanceAttrRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: MaintenanceAttrAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.MaintenanceAttributes,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_maintenance_attributes') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_maintenance_attributes') {
      accesses = {
        ...accesses,
        view: value,
      };
    }

    if (roleFunctionKeywordName === 'role_func_edit_maintenance_attributes') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_maintenance_attributes') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_maintenance_attributes') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
  });

  return accesses;
});

export const selectVendorRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: VendorAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.Vendor,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_vendor') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_vendors') {
      accesses = {
        ...accesses,
        view: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_edit_vendor') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_vendor') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_vendor') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
  });

  return accesses;
});

export const selectDepartmentRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: DepartmentAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.Department,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_department') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_departments') {
      accesses = {
        ...accesses,
        view: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_edit_department') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_department') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_department') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
  });

  return accesses;
});

export const selectSiteRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: SiteAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.Site,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_site') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_sites') {
      accesses = {
        ...accesses,
        view: value,
      };
    }

    if (roleFunctionKeywordName === 'role_func_edit_site') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_site') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_site') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
  });

  return accesses;
});

export const selectContractRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: ContractAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.Contract,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_contract') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_contracts') {
      accesses = {
        ...accesses,
        view: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_edit_contract') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_contract') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_contract') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
  });

  return accesses;
});

export const selectExitTypeRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: ExitTypeAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.ExitType,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_exit_type') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_exit_types') {
      accesses = {
        ...accesses,
        view: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_edit_exit_type') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_exit_type') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_exit_type') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
  });

  return accesses;
});

export const selectCostCenterRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: CostCenterAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.CostCenter,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_cost_center') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_cost_centers') {
      accesses = {
        ...accesses,
        view: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_edit_cost_center') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_cost_center') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_cost_center') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
  });

  return accesses;
});

export const selectUserRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: UserAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    accessKey: AccessKey.User,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_user') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_user') {
      accesses = {
        ...accesses,
        view: value,
      };
    }

    if (roleFunctionKeywordName === 'role_func_edit_user') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_user') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_user') {
      accesses = {
        ...accesses,
        create: value,
      };
    }
  });

  return accesses;
});

export const selectAssetRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: AssetAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    export: false,

    accessKey: AccessKey.Asset,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_assets') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_assets') {
      accesses = {
        ...accesses,
        view: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_edit_assets') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_assets') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_new_assets') {
      accesses = {
        ...accesses,
        create: value,
      };
    }

    if (roleFunctionKeywordName === 'role_func_export_data') {
      accesses = {
        ...accesses,
        export: value,
      };
    }
  });

  return accesses;
});

export const selectCountPlanRoleAccess = createSelector([selectProfileRole], (role) => {
  let accesses: CountPlanAccess = {
    view: false,

    create: false,

    delete: false,

    edit: false,

    execute: false,

    accessKey: AccessKey.CountPlan,
  };

  const children = getFunctionAccessChildren(role, 'role_menu_count_plans') || [];

  children.forEach(({ roleFunctionKeywordName, value }) => {
    if (roleFunctionKeywordName === 'role_func_view_count_plans') {
      accesses = {
        ...accesses,
        view: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_edit_count_plans') {
      accesses = {
        ...accesses,
        edit: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_delete_count_plans') {
      accesses = {
        ...accesses,
        delete: value,
      };
    }
    if (roleFunctionKeywordName === 'role_func_create_count_plans') {
      accesses = {
        ...accesses,
        create: value,
      };
    }

    if (roleFunctionKeywordName === 'role_func_execute_count_plans') {
      accesses = {
        ...accesses,
        execute: value,
      };
    }
  });

  return accesses;
});

function getFunctionAccessChildren(role: RoleModel, searchName: string) {
  return role.roleFunctionNameObject?.reduce((acc: Array<RoleFunctionNameModel>, roleFunction) => {
    if (roleFunction.roleFunctionKeywordName === searchName) {
      roleFunction.childrens.forEach((child) => {
        acc.push(child);
      });
    }

    return acc;
  }, []);
}
