import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import {
  selectPaICategoriesListWithoutChildren,
  selectPaICategoryStatus,
} from '@store/selectors/properties/PaI/Category';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { PaICategoryModel } from '@model/properties/PaI/Category';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormPartAndInventoryCategory(props: CommonPreviewProps<PaICategoryModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusPartAndInventoryCategory = useAppSelector(selectPaICategoryStatus);

  const listPartAndInventoryCategory = useAppSelector(selectPaICategoriesListWithoutChildren);

  const parentPartAndInventoryCategory = listPartAndInventoryCategory.find(
    (category) =>
      category.partsAndInventoriesCategoryId === entity.partsAndInventoriesParentCategoryId
  );

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={statusPartAndInventoryCategory === 'pending'}
      header={{
        title: entity.partsAndInventoriesCategoryName,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.partsAndInventoriesCategoryCode}</PreviewField>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.partsAndInventoriesCategoryName}</PreviewField>
        </Item>
        <Item label={t('titles.Parent_Category')}>
          <PreviewField>
            {parentPartAndInventoryCategory?.partsAndInventoriesCategoryName}
          </PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormPartAndInventoryCategory };
