import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaIModelRepository } from '@repositories';

import type {
  CreatePaIModelDto,
  PaIModelModel,
  UpdatePaIModelDto,
} from '@model/properties/PaI/Model';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getPaIModels = createAsyncThunk<PaIModelModel[], void, { rejectValue: string }>(
  'PaIModel/getPaIModels',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PaIModelRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getPaIModelById = createAsyncThunk<PaIModelModel, string, { rejectValue: string }>(
  'PaIModel/getPaIModelById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await PaIModelRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addPaIModel = createAsyncThunk<
  PaIModelModel,
  CreatePaIModelDto,
  { rejectValue: string }
>('PaIModel/addPaIModel', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaIModelRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updatePaIModel = createAsyncThunk<
  { models: PaIModelModel[]; particularModel: PaIModelModel },
  UpdatePaIModelDto,
  { rejectValue: string }
>('PaIModel/updatePaIModel', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaIModelRepository.update(dto);
    const responseList = await PaIModelRepository.fetchAll();

    return { models: responseList.resultObject, particularModel: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removePaIModelById = createAsyncThunk<
  PaIModelModel[],
  number[],
  { rejectValue: string }
>('PaIModel/removePaIModelById', async (ids, { rejectWithValue }) => {
  try {
    await PaIModelRepository.removeById(ids);
    const response = await PaIModelRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
