import * as React from 'react';

import type { SVGProps } from 'react';

const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m12 12 5.242 5.244m-10.484 0L12 12l-5.242 5.243ZM17.242 6.758 12 12l5.243-5.243ZM12 12 6.758 6.758 11.999 12Z"
      stroke={props.fill ? props.fill : '#D2D2D2'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { CrossIcon };
