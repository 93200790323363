import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaIBrandRepository } from '@repositories';

import type {
  CreatePaIBrandDto,
  PaIBrandModel,
  UpdatePaIBrandDto,
} from '@model/properties/PaI/Brand';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getPaIBrands = createAsyncThunk<PaIBrandModel[], void, { rejectValue: string }>(
  'PaIBrand/getPaIBrands',
  async (_, { rejectWithValue }) => {
    try {
      const response = await PaIBrandRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getPaIBrandById = createAsyncThunk<PaIBrandModel, string, { rejectValue: string }>(
  'PaIBrand/getPaIBrandById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await PaIBrandRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addPaIBrand = createAsyncThunk<
  PaIBrandModel,
  CreatePaIBrandDto,
  { rejectValue: string }
>('PaIBrand/addPaIBrand', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaIBrandRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updatePaIBrand = createAsyncThunk<
  { brands: PaIBrandModel[]; particularBrand: PaIBrandModel },
  UpdatePaIBrandDto,
  { rejectValue: string }
>('PaIBrand/updatePaIBrand', async (dto, { rejectWithValue }) => {
  try {
    const response = await PaIBrandRepository.update(dto);
    const responseList = await PaIBrandRepository.fetchAll();

    return { brands: responseList.resultObject, particularBrand: response.resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removePaIBrandById = createAsyncThunk<
  PaIBrandModel[],
  number[],
  { rejectValue: string }
>('PaIBrand/removePaIBrandById', async (ids, { rejectWithValue }) => {
  try {
    await PaIBrandRepository.removeById(ids);
    const response = await PaIBrandRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
