import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormMaintenanceCategory } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addMaAtCategory } from '@store/actions/properties/MaAt/Category';

import type { CreateMaAtCategoryDto } from '@model/properties/MaAt/Category';
import type { FormInstance } from 'antd';

function CreateMaintenanceCategory() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { maintenanceAttrAccess } = useGetAccess();

  const onFinish = async (
    dto: CreateMaAtCategoryDto,
    form?: FormInstance<CreateMaAtCategoryDto>
  ) => {
    try {
      const response = await dispatch(addMaAtCategory(dto)).unwrap();
      ApiNotifications.create(response.categoryName);
      navigate(paths.maintenanceAttributes.tabs.category.default);
      form?.resetFields();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess
      hasAccess={maintenanceAttrAccess.create}
      redirectTo={paths.maintenanceAttributes.tabs.category.default}
    >
      <FormMaintenanceCategory
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.Maintenance_Attr_Category') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreateMaintenanceCategory;
