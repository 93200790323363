import { api } from '@services/Axios';

import type { CreatePaIUnitDto, PaIUnitModel, UpdatePaIUnitDto } from '@model/properties/PaI/Unit';
import type { ApiResponse } from '@services/Axios';

const PaIUnitEndpoint = '/PartsAndInventoriesUnits';

export const PaIUnitRepository = {
  async fetchAll() {
    const response: ApiResponse<PaIUnitModel[]> = await api.get(
      `${PaIUnitEndpoint}/PartsAndInventoriesUnitList`
    );

    return response.data;
  },
  async add(dto: CreatePaIUnitDto) {
    const response: ApiResponse<PaIUnitModel> = await api.post(
      `${PaIUnitEndpoint}/AddPartsAndInventoriesUnit`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<PaIUnitModel> = await api.get(
      `${PaIUnitEndpoint}/GetPartsAndInventoriesUnitById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdatePaIUnitDto) {
    const response: ApiResponse<PaIUnitModel> = await api.post(
      `${PaIUnitEndpoint}/UpdatePartsAndInventoriesUnit`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${PaIUnitEndpoint}/RemoveByIdList`, {
      partsAndInventoriesUnitIds: ids,
    });

    return response.data;
  },
};
