import React, { memo } from 'react';

export type ToolbarItem = {
  label: React.ReactNode;

  key: React.Key;

  className?: string;
};

export interface ToolbarItemsProps {
  items: Array<ToolbarItem>
}

export const ToolbarItems = memo((props: ToolbarItemsProps) => {
  const {items} = props;

  const renderItems = items.map((item) => <div
    key={item.key} className={item.className}>{item.label}</div>)

  return <>
    {renderItems}
  </>;
});
