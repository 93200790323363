import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { MaintenanceRepository } from '@repositories';
import { identifyError } from '@shared/utils/functions';

import type { MaintenanceViewType } from '@constants/MaintenanceViewType';
import type {
  BatchUpdateMaintenancesDto,
  CreateMaintenanceGeneralV2Dto,
  CreateMaintenancePartsDto,
  CreateMaintenanceStepsDto,
  CreateMultipleChoiceWorkStepDto,
  FilterHistoryDto,
  MaintenanceHistoryModel,
  MaintenanceModel,
  MaintenanceOccurrenceModel,
  MaintenancePartModel,
  MaintenanceV2Model,
  MaintenanceWorkStepModel,
  MultiChoiceForWorkStepModel,
  UpdateMaintenanceGeneralV2Dto,
  UpdateWorkStepOptionTitleDto,
  UpdateWorkStepTitleDto,
} from '@model/management/Maintenance';
import type { UpdateMaintenancePartDto } from '@model/management/Maintenance/dto/update-maintenance-part.dto';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer';
import type { AppDispatch } from '@store/store';

// region --- Get Actions
export const getMaintenances = createAsyncThunk<MaintenanceModel[], void, { rejectValue: string }>(
  'Maintenance/getMaintenances',
  async (_, { rejectWithValue }) => {
    try {
      const response = await MaintenanceRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error = identifyError(err);
      return rejectWithValue(error);
    }
  }
);

export const getMaintenanceById = createAsyncThunk<
  MaintenanceV2Model,
  number | string,
  { rejectValue: string }
>('Maintenance/getMaintenanceById', async (id, { rejectWithValue }) => {
  try {
    const response = await MaintenanceRepository.fetchByIdV2(id);
    const responseWorkSteps = await MaintenanceRepository.fetchWorkStepsByMaintenanceId(id);

    if (responseWorkSteps.resultObject && responseWorkSteps.resultObject.length > 0) {
      const sortedWorkSteps = [...responseWorkSteps.resultObject].sort(
        (a: MaintenanceWorkStepModel, b: MaintenanceWorkStepModel) =>
          a.workSteps.rowId - b.workSteps.rowId
      );
      const maintenance: MaintenanceV2Model = {
        ...response.resultObject,
        maintenancesWorkSteps: sortedWorkSteps,
      };

      return maintenance;
    }

    return response.resultObject;
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

export const getMaintenanceOccurrencesList = createAsyncThunk<
  MaintenanceOccurrenceModel[],
  void,
  { rejectValue: string }
>('Maintenance/getMaintenanceOccurrencesList', async (_, { rejectWithValue }) => {
  try {
    const response = await MaintenanceRepository.fetchOccurrencesList();

    return response.resultObject;
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

export const getMaintenanceHistoryList = createAsyncThunk<
  {
    historyList: MaintenanceHistoryModel[];
    totalHistoryPage: number;
    totalHistoryRecords: number;
    filterHistoryListOptions: FilterHistoryDto;
  },
  FilterHistoryDto,
  {
    rejectValue: string;
  }
>(
  'Maintenance/getMaintenanceHistoryList',
  async (filterHistoryListOptions, { rejectWithValue }) => {
    try {
      const response = await MaintenanceRepository.fetchHistoryList(filterHistoryListOptions);

      return {
        historyList: response.resultObject,
        totalHistoryPage: response.totalPage,
        totalHistoryRecords: response.totalRecords,
        filterHistoryListOptions: filterHistoryListOptions,
      };
    } catch (err: any) {
      const error = identifyError(err);
      return rejectWithValue(error);
    }
  }
);

export const getMaintenanceWorkStepsByMaintenanceId = createAsyncThunk<
  MaintenanceWorkStepModel[],
  string | number,
  { rejectValue: string }
>('Maintenance/getMaintenanceWorkStepsByMaintenanceId', async (id, { rejectWithValue }) => {
  try {
    const response = await MaintenanceRepository.fetchWorkStepsByMaintenanceId(id);

    return response.resultObject;
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addMaintenanceGeneral = createAsyncThunk<
  MaintenanceModel,
  CreateMaintenanceGeneralV2Dto,
  { rejectValue: string; dispatch: AppDispatch }
>('Maintenance/addMaintenanceGeneral', async (dto, { rejectWithValue, dispatch }) => {
  try {
    const response = await MaintenanceRepository.addGeneralV2(dto);
    await dispatch(getMaintenances());

    return response.resultObject;
  } catch (err) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

export const addMaintenanceSteps = createAsyncThunk<
  void,
  CreateMaintenanceStepsDto[],
  { rejectValue: string; dispatch: AppDispatch }
>('Maintenance/addMaintenanceSteps', async (dto, { rejectWithValue, dispatch }) => {
  try {
    await MaintenanceRepository.addWorkSteps(dto);

    await dispatch(getMaintenanceById(dto[0].maintenanceId));
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const addMaintenanceParts = createAsyncThunk<
  MaintenancePartModel[],
  CreateMaintenancePartsDto,
  { rejectValue: string; dispatch: AppDispatch }
>('Maintenance/addMaintenanceParts', async (dto, { rejectWithValue, dispatch }) => {
  try {
    const response = await MaintenanceRepository.addMaintenanceParts(dto);

    if (response.resultObject.length) {
      await dispatch(getMaintenanceById(response.resultObject[0].maintenanceId));
    }
    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const addMaintenanceMultipleChoiceWorkStep = createAsyncThunk<
  MaintenanceV2Model,
  CreateMultipleChoiceWorkStepDto,
  { rejectValue: string; state: RootState }
>(
  'Maintenance/addMaintenanceMultipleChoiceWorkStep',
  async (dto, { rejectWithValue, getState }) => {
    try {
      const { resultObject } = await MaintenanceRepository.addMultipleChoiceWorkStep(dto);

      const maintenance = getState().MaintenanceReducer.maintenance as MaintenanceV2Model;

      const ownerOfMultipleChoice = maintenance.maintenancesWorkSteps.find(
        (step) => step.workStepsId === dto.workStepsId
      );

      if (ownerOfMultipleChoice) {
        const updatedWorkSteps = maintenance.maintenancesWorkSteps.map((step) =>
          step.workStepsId === ownerOfMultipleChoice.workStepsId
            ? {
                ...ownerOfMultipleChoice,
                workSteps: {
                  ...ownerOfMultipleChoice.workSteps,
                  multipleChoiceListForWorkSteps: resultObject,
                },
              }
            : step
        );

        return {
          ...maintenance,
          maintenancesWorkSteps: updatedWorkSteps,
        };
      }

      return maintenance;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Add Actions

// region --- Update Actions

export const batchUpdateMaintenances = createAsyncThunk<
  void,
  BatchUpdateMaintenancesDto,
  { rejectValue: string; dispatch: AppDispatch }
>('Maintenance/batchUpdateMaintenances', async (dto, { rejectWithValue, dispatch }) => {
  try {
    await MaintenanceRepository.batchUpdate(dto);

    dispatch(getMaintenances());
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

export const updateMaintenanceGeneral = createAsyncThunk<
  MaintenanceModel,
  UpdateMaintenanceGeneralV2Dto,
  { rejectValue: string; dispatch: AppDispatch }
>('Maintenance/updateMaintenanceGeneral', async (dto, { rejectWithValue, dispatch }) => {
  try {
    const response = await MaintenanceRepository.updateMaintenanceGeneralV2(dto);

    await dispatch(getMaintenanceById(response.resultObject.maintenanceId));
    await dispatch(getMaintenances());

    return response.resultObject;
  } catch (e) {
    const error = identifyError(e);

    return rejectWithValue(error);
  }
});

export const updateMaintenanceWorkStepTitle = createAsyncThunk<
  MaintenanceV2Model,
  UpdateWorkStepTitleDto,
  { rejectValue: string; state: RootState }
>('Maintenance/updateMaintenanceWorkStepTitle', async (dto, { rejectWithValue, getState }) => {
  try {
    const { resultObject } = await MaintenanceRepository.updateWorkStepTitle(dto);

    const maintenance = getState().MaintenanceReducer.maintenance as MaintenanceV2Model;

    const updatedWorkSteps: MaintenanceWorkStepModel[] = maintenance.maintenancesWorkSteps.map(
      (workStep) => {
        if (workStep.workStepsId === resultObject.workStepsId) {
          return {
            ...workStep,
            workSteps: {
              ...workStep.workSteps,
              stepName: resultObject.stepName,
              rowId: resultObject.rowId,
            },
          };
        }
        return workStep;
      }
    );

    const updatedMaintenance: MaintenanceV2Model = {
      ...maintenance,
      maintenancesWorkSteps: updatedWorkSteps,
    };

    return updatedMaintenance;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const updateWorkStepOptionText = createAsyncThunk<
  MaintenanceV2Model,
  UpdateWorkStepOptionTitleDto,
  { rejectValue: string; state: RootState }
>('Maintenance/updateWorkStepOptionText', async (dto, { rejectWithValue, getState }) => {
  try {
    const { resultObject } = await MaintenanceRepository.updateWorkStepOptionTitle(dto);

    const maintenance = getState().MaintenanceReducer.maintenance as MaintenanceV2Model;

    const ownerOfMultipleChoice = maintenance.maintenancesWorkSteps.find(
      (workStep) => workStep.workStepsId === resultObject.workStepsId
    );

    if (ownerOfMultipleChoice) {
      const updatedMultipleChoiceLists: MultiChoiceForWorkStepModel[] =
        ownerOfMultipleChoice.workSteps.multipleChoiceListForWorkSteps.map((option) => {
          if (
            option.multipleChoiceListForWorkStepsId ===
            resultObject.multipleChoiceListForWorkStepsId
          ) {
            return {
              ...option,
              optionText: resultObject.optionText,
              rowId: resultObject.rowId,
            };
          }
          return option;
        });

      const updatedWorkSteps: MaintenanceWorkStepModel[] = maintenance.maintenancesWorkSteps.map(
        () => {
          if (ownerOfMultipleChoice.workStepsId === resultObject.workStepsId) {
            return {
              ...ownerOfMultipleChoice,
              workSteps: {
                ...ownerOfMultipleChoice.workSteps,
                multipleChoiceListForWorkSteps: updatedMultipleChoiceLists,
              },
            };
          }
          return ownerOfMultipleChoice;
        }
      );

      return { ...maintenance, maintenancesWorkSteps: updatedWorkSteps };
    }

    return maintenance;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const updateMaintenanceViewType = createAction(
  'Maintenance/updateMaintenanceViewType',
  (viewType: MaintenanceViewType) => ({
    payload: {
      viewType,
    },
  })
);

export const updateMaintenanceParts = createAsyncThunk<
  void,
  UpdateMaintenancePartDto,
  { rejectValue: string }
>('Maintenance/updateMaintenanceParts', async (dto, { rejectWithValue }) => {
  try {
    await MaintenanceRepository.updateMaintenancePart(dto);

    // await dispatch(getMaintenanceById(dto.maintenanceId));
  } catch (error) {
    return rejectWithValue(identifyError(error));
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeWorkStepByIds = createAsyncThunk<
  MaintenanceV2Model,
  number[],
  { rejectValue: string; state: RootState }
>('Maintenance/removeWorkStepByIds', async (ids, { rejectWithValue, getState }) => {
  try {
    await MaintenanceRepository.removeWorkStepByIds(ids);

    const maintenance = getState().MaintenanceReducer.maintenance as MaintenanceV2Model;

    const updatedWorkSteps = maintenance.maintenancesWorkSteps.filter(
      (workStep) => !ids.includes(workStep.workStepsId)
    );

    return { ...maintenance, maintenancesWorkSteps: updatedWorkSteps };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const removeWorkStepsOptionByIds = createAsyncThunk<
  MaintenanceV2Model,
  { stepId: number; optionIds: number[] },
  { rejectValue: string; state: RootState }
>('Maintenance/removeWorkStepsOption', async (payload, { rejectWithValue, getState }) => {
  try {
    await MaintenanceRepository.removeWorkStepsOptionByIds(payload.optionIds);

    const maintenance = getState().MaintenanceReducer.maintenance as MaintenanceV2Model;

    const ownerOfMultipleChoice = maintenance.maintenancesWorkSteps.find(
      (workStep) => workStep.workStepsId === payload.stepId
    );

    if (ownerOfMultipleChoice) {
      const updatedMultipleChoiceLists: MultiChoiceForWorkStepModel[] =
        ownerOfMultipleChoice.workSteps.multipleChoiceListForWorkSteps.filter(
          (option) => !payload.optionIds.includes(option.multipleChoiceListForWorkStepsId)
        );

      const updatedWorkSteps: MaintenanceWorkStepModel[] = maintenance.maintenancesWorkSteps.map(
        (workStep) => {
          if (workStep.workStepsId === ownerOfMultipleChoice.workStepsId) {
            return {
              ...workStep,
              workSteps: {
                ...workStep.workSteps,
                multipleChoiceListForWorkSteps: updatedMultipleChoiceLists,
              },
            };
          }
          return workStep;
        }
      );

      return { ...maintenance, maintenancesWorkSteps: updatedWorkSteps };
    }

    return maintenance;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const removeMaintenanceParts = createAsyncThunk<
  MaintenanceV2Model | null,
  number[],
  { rejectValue: string; state: RootState; dispatch: AppDispatch }
>('Maintenance/removeMaintenanceParts', async (ids, { rejectWithValue, getState }) => {
  try {
    const { resultStatus } = await MaintenanceRepository.removeMaintenanceParts(ids);
    const currentMaintenance = getState().MaintenanceReducer.maintenance;
    if (resultStatus && currentMaintenance) {
      const parts = currentMaintenance.maintenancesParts.filter(
        (part) => !ids.includes(part.maintenancesPartsId)
      );
      return {
        ...currentMaintenance,
        maintenancesParts: parts,
      };
    }

    return null;
  } catch (e) {
    const err = identifyError(e);

    return rejectWithValue(err);
  }
});

export const removeWorkStepImage = createAsyncThunk<void, number[], { rejectValue: string }>(
  'Maintenance/removeWorkStepImage',
  async (ids, { rejectWithValue }) => {
    try {
      await MaintenanceRepository.removeWorkStepImage(ids);
    } catch (e) {
      return rejectWithValue(identifyError(e));
    }
  }
);

// endregion --- Remove Actions
