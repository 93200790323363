import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Main as MainLayout } from '@components/layouts';
import { AuthProvider } from '@components/providers/AuthProvider';
import { ProtectedRouter } from '@components/Routes';
import { UserAlerts } from '@components/wrappers';
import { ForgotPassword, SignIn } from '@pages/auth/Login';
import { EntryDashboard } from '@pages/dashboard';
import Dashboard from '@pages/dashboard/Dashboard';
import {
  Asset,
  CreateAsset,
  EditAsset,
  ListAsset,
  PreviewAsset,
  PreviewAssetParts,
  ReportAsset,
} from '@pages/management/Assets';
import { AssetParts, EditAssetParts } from '@pages/management/Assets/Asset/AssetParts';
import EntryAsset from '@pages/management/Assets/EntryAsset';
import {
  CountPlan,
  CreateCountPlan,
  EditCountPlan,
  EntryCountPlan,
  PreviewCountPlan,
  TableCountPlan,
} from '@pages/management/CountPlan';
import {
  CreateMaintenance,
  EditMaintenanceGeneral,
  EditMaintenanceParts,
  EditMaintenanceWorkOrders,
  EntryMaintenance,
  Maintenance,
  MaintenanceResult,
  PreviewMaintenanceGeneral,
  PreviewMaintenanceHistory,
  PreviewMaintenanceParts,
  PreviewMaintenanceWorkOrders,
  TableMaintenances,
} from '@pages/management/Maintenances';
import {
  ConsumePartAndInventory,
  CreatePartAndInventory,
  EditPartAndInventory,
  EntryPartAndInventory,
  PartAndInventory,
  PreviewPartAndInventory,
  TablePartAndInventory,
} from '@pages/management/PartsAndInventoriesV2';
import {
  ChangePassword,
  CreatePassword,
  EditProfile,
  EntryProfile,
  ListNotifications,
  Notifications,
  PreviewProfile,
  Profile,
} from '@pages/profile';
/* ----------------------------- Property Pages ----------------------------- */
import { EntryAssetAttributes } from '@pages/properties/AssetAttributes';
import {
  AssetBrand,
  CreateAssetBrand,
  EditAssetBrand,
  EntryAssetBrand,
  PreviewAssetBrand,
  TableAssetBrand,
} from '@pages/properties/AssetAttributes/AssetBrands';
import {
  AssetCategory,
  CreateAssetCategory,
  EditAssetCategory,
  EntryAssetCategory,
  PreviewAssetCategory,
  TableAssetCategory,
} from '@pages/properties/AssetAttributes/AssetCategories';
import {
  AssetModel,
  CreateAssetModel,
  EditAssetModel,
  EntryAssetModel,
  PreviewAssetModel,
  TableAssetModel,
} from '@pages/properties/AssetAttributes/AssetModels';
import {
  AssetName,
  CreateAssetName,
  EditAssetName,
  EntryAssetName,
  PreviewAssetName,
  TableAssetName,
} from '@pages/properties/AssetAttributes/AssetNames';
import {
  AssetProperty,
  CreateAssetProperty,
  EditAssetProperty,
  EntryAssetProperty,
  PreviewAssetProperty,
  TableAssetProperty,
} from '@pages/properties/AssetAttributes/AssetProperties';
import {
  AssetStatus,
  CreateAssetStatus,
  EditAssetStatus,
  EntryAssetStatus,
  PreviewAssetStatus,
  TableAssetStatus,
} from '@pages/properties/AssetAttributes/AssetStatuses';
import {
  CheckOut,
  CreateCheckOut,
  EditCheckOut,
  EntryCheckOut,
  PreviewCheckOut,
  TableCheckOut,
} from '@pages/properties/CheckOuts';
import {
  Contract,
  CreateContract,
  EditContract,
  EntryContract,
  PreviewContract,
  TableContract,
} from '@pages/properties/Contracts';
import {
  CostCenter,
  CreateCostCenter,
  EditCostCenter,
  EntryCostCenter,
  PreviewCostCenter,
  TableCostCenter,
} from '@pages/properties/CostCenters';
import {
  CreateDepartment,
  Department,
  EditDepartment,
  EntryDepartment,
  PreviewDepartment,
  TableDepartment,
} from '@pages/properties/Departments';
import {
  CreateExitType,
  EditExitType,
  EntryExitType,
  ExitType,
  PreviewExitType,
  TableExitType,
} from '@pages/properties/ExitTypes';
import EntryMaintenanceAttributes from '@pages/properties/MaintenanceAttributes/EntryMaintenanceAttributes';
import {
  CreateMaintenanceCategory,
  EditMaintenanceCategory,
  EntryMaintenanceCategory,
  MaintenanceCategory,
  PreviewMaintenanceCategory,
  TableMaintenanceCategory,
} from '@pages/properties/MaintenanceAttributes/MaintenanceCategories';
import {
  CreateMaintenanceChecklist,
  EditMaintenanceChecklist,
  EntryMaintenanceChecklist,
  MaintenanceChecklist,
  PreviewMaintenanceChecklist,
  TableMaintenanceChecklist,
} from '@pages/properties/MaintenanceAttributes/MaintenanceChecklists';
import {
  CreateMaintenanceRequestReason,
  EditMaintenanceRequestReason,
  EntryMaintenanceRequestReason,
  MaintenanceRequestReason,
  PreviewMaintenanceRequestReason,
  TableMaintenanceRequestReason,
} from '@pages/properties/MaintenanceAttributes/MaintenanceRequestReasons';
import EntryPartAndInventories from '@pages/properties/PartAndInventories/EntryPartAndInventories';
import {
  CreatePartAndInventoryBrand,
  EditPartAndInventoryBrand,
  EntryPartAndInventoryBrand,
  PartAndInventoryBrand,
  PreviewPartAndInventoryBrand,
  TablePartAntInventoryBrand,
} from '@pages/properties/PartAndInventories/PartAndInventoryBrands';
import {
  CreatePartAndInventoryCategory,
  EditPartAndInventoryCategory,
  EntryPartAndInventoryCategory,
  PartAndInventoryCategory,
  PreviewPartAndInventoryCategory,
  TablePartAntInventoryCategory,
} from '@pages/properties/PartAndInventories/PartAndInventoryCategories';
import {
  CreatePartAndInventoryModel,
  EditPartAndInventoryModel,
  EntryPartAndInventoryModel,
  PartAndInventoryModel,
  PreviewPartAndInventoryModel,
  TablePartAntInventoryModel,
} from '@pages/properties/PartAndInventories/PartAndInventoryModels';
import {
  CreatePartAndInventoryName,
  EditPartAndInventoryName,
  EntryPartAndInventoryName,
  PartAndInventoryName,
  PreviewPartAndInventoryName,
  TablePartAntInventoryName,
} from '@pages/properties/PartAndInventories/PartAndInventoryNames';
import {
  CreatePartAndInventoryProperty,
  EditPartAndInventoryProperty,
  EntryPartAndInventoryProperty,
  PartAndInventoryProperty,
  PreviewPartAndInventoryProperty,
  TablePartAntInventoryProperty,
} from '@pages/properties/PartAndInventories/PartAndInventoryProperties';
import {
  CreatePartAndInventoryStatus,
  EditPartAndInventoryStatus,
  EntryPartAndInventoryStatus,
  PartAndInventoryStatus,
  PreviewPartAndInventoryStatus,
  TablePartAntInventoryStatus,
} from '@pages/properties/PartAndInventories/PartAndInventoryStatus';
import {
  CreatePartAndInventoryUnit,
  EditPartAndInventoryUnit,
  EntryPartAndInventoryUnit,
  PartAndInventoryUnit,
  PreviewPartAndInventoryUnit,
  TablePartAntInventoryUnit,
} from '@pages/properties/PartAndInventories/PartAndInventoryUnits';
import {
  CreateSite,
  EditSite,
  EntrySite,
  PreviewSite,
  Site,
  TableSite,
} from '@pages/properties/Sites';
import {
  CreateVendor,
  EditVendor,
  EntryVendor,
  PreviewVendor,
  TableVendor,
  Vendor,
} from '@pages/properties/Vendors';
/* ------------------------------- User Pages ------------------------------- */
import {
  Company,
  CreateCompany,
  EditCompany,
  EntryCompany,
  PreviewCompany,
  TableCompany,
} from '@pages/users/Companies';
import { CreateRole, EditRole, EntryRole, PreviewRole, Role, TableRole } from '@pages/users/Roles';
import {
  CreateTitle,
  EditTitle,
  EntryTitle,
  PreviewTitle,
  TableTitle,
  Title,
} from '@pages/users/Titles';
import {
  CreateUserAuthorization,
  EditUserAuthorization,
  EntryUserAuthorization,
  PreviewUserAuthorization,
  TableUserAuthorization,
  UserAuthorization,
} from '@pages/users/UserAuthorizations';
import {
  CreateUserGroup,
  EditUserGroup,
  EntryUserGroup,
  PreviewUserGroup,
  TableUserGroup,
  UserGroup,
} from '@pages/users/UserGroups';
import { CreateUser, EditUser, EntryUser, PreviewUser, TableUser, User } from '@pages/users/Users';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';

function AppRouter() {
  return (
    <AuthProvider>
      <Routes>
        <Route path={paths.createPassword.default}>
          <Route
            index
            element={
              <React.Suspense fallback={<Loader size="large" middle />}>
                <CreatePassword />
              </React.Suspense>
            }
          />
        </Route>
        <Route path={paths.login.default}>
          <Route
            index
            element={
              <React.Suspense fallback={<Loader size="large" middle />}>
                <SignIn />
              </React.Suspense>
            }
          />
          <Route
            path={paths.login.recover}
            element={
              <React.Suspense fallback={<Loader size="large" middle />}>
                <ForgotPassword />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path={'/'}
          element={
            <ProtectedRouter>
              <UserAlerts>
                <React.Suspense fallback={<Loader />}>
                  <MainLayout />
                </React.Suspense>
              </UserAlerts>
            </ProtectedRouter>
          }
        >
          /* ----------------------------- Dashboard Pages ---------------------------- */
          <Route path="/" element={<EntryDashboard />}>
            <Route index element={<Dashboard />} />
          </Route>
          /* --------------------------------- Profile -------------------------------- */
          <Route path={paths.profile.default} element={<EntryProfile />}>
            <Route path={paths.profile.tabs.general.default} element={<Profile />}>
              <Route path={paths.profile.tabs.general.edit} element={<EditProfile />} />
              <Route index element={<PreviewProfile />} />
              <Route
                path={paths.profile.tabs.general.changePassword}
                element={<ChangePassword />}
              />
            </Route>
            <Route path={paths.profile.tabs.notifications.default} element={<Notifications />}>
              <Route index element={<ListNotifications />} />
            </Route>
          </Route>
          /* ---------------------------- MANAGEMENT PAGES ---------------------------- */
          /*---------------------------------- Asset --------------------------------- */
          <Route path={paths.asset.default} element={<EntryAsset />}>
            <Route index element={<ListAsset />} />
            <Route path={paths.asset.create} element={<CreateAsset />} />
            <Route path={paths.asset.tabs.general.default} element={<Asset />}>
              <Route index element={<PreviewAsset />} />
              <Route path={paths.asset.edit} element={<EditAsset />} />
              <Route path={paths.asset.tabs.reports.default} element={<ReportAsset />} />
              <Route path={paths.asset.tabs.parts.default} element={<AssetParts />}>
                <Route index element={<PreviewAssetParts />} />
                <Route path={paths.asset.tabs.parts.edit} element={<EditAssetParts />} />
              </Route>
            </Route>
          </Route>
          /* --------------------------- Maintenance --------------------------- */
          <Route path={paths.maintenance.default} element={<EntryMaintenance />}>
            <Route index element={<TableMaintenances />} />
            <Route path={paths.maintenance.create} element={<CreateMaintenance />} />
            <Route path={paths.maintenance.particular} element={<Maintenance />}>
              <Route
                path={paths.maintenance.tabs.general.default}
                element={<PreviewMaintenanceGeneral />}
              />
              <Route
                path={paths.maintenance.tabs.general.edit}
                element={<EditMaintenanceGeneral />}
              />
              <Route
                path={paths.maintenance.tabs.workOrders.default}
                element={<PreviewMaintenanceWorkOrders />}
              />
              <Route
                path={paths.maintenance.tabs.workOrders.edit}
                element={<EditMaintenanceWorkOrders />}
              />
              <Route
                path={paths.maintenance.tabs.parts.default}
                element={<PreviewMaintenanceParts />}
              />
              <Route path={paths.maintenance.tabs.parts.edit} element={<EditMaintenanceParts />} />
              <Route
                path={paths.maintenance.tabs.history.default}
                element={<PreviewMaintenanceHistory />}
              />
            </Route>
            <Route path={paths.maintenance.result} element={<MaintenanceResult />} />
          </Route>
          --------------------------- Part And Inventory --------------------------- */
          <Route path={paths.partAndInventory.default} element={<EntryPartAndInventory />}>
            <Route index element={<TablePartAndInventory />} />
            <Route path={paths.partAndInventory.create} element={<CreatePartAndInventory />} />
            <Route path={paths.partAndInventory.consume} element={<ConsumePartAndInventory />} />
            <Route path={paths.partAndInventory.particular} element={<PartAndInventory />}>
              <Route index element={<PreviewPartAndInventory />} />
              <Route path={paths.partAndInventory.edit} element={<EditPartAndInventory />} />
            </Route>
          </Route>
          --------------------------- Part And Inventory V2 --------------------------- */
          <Route path={paths.partAndInventoryV2.default} element={<EntryPartAndInventory />}>
            <Route index element={<TablePartAndInventory />} />
            <Route path={paths.partAndInventoryV2.create} element={<CreatePartAndInventory />} />
            <Route path={paths.partAndInventoryV2.consume} element={<ConsumePartAndInventory />} />
            <Route path={paths.partAndInventoryV2.particular} element={<PartAndInventory />}>
              <Route index element={<PreviewPartAndInventory />} />
              <Route path={paths.partAndInventoryV2.edit} element={<EditPartAndInventory />} />
            </Route>
          </Route>
          /* --------------------------------- Count Plan -------------------------------- */
          <Route path={paths.countPlan.default} element={<EntryCountPlan />}>
            <Route index element={<TableCountPlan />} />
            <Route path={paths.countPlan.create} element={<CreateCountPlan />} />
            <Route path={paths.countPlan.particular} element={<CountPlan />}>
              <Route index element={<PreviewCountPlan />} />
              <Route path={paths.countPlan.edit} element={<EditCountPlan />} />
            </Route>
          </Route>
          /* ----------------------------- PROPERTY PAGES ----------------------------- */ /*
          ---------------------------- Asset Attributes ---------------------------- */
          <Route path={paths.assetAttribute.default} element={<EntryAssetAttributes />}>
            // Asset Name
            <Route path={paths.assetAttribute.tabs.name.default} element={<EntryAssetName />}>
              <Route index element={<TableAssetName />} />
              <Route path={paths.assetAttribute.tabs.name.create} element={<CreateAssetName />} />
              <Route path={paths.assetAttribute.tabs.name.particular} element={<AssetName />}>
                <Route index element={<PreviewAssetName />} />
                <Route path={paths.assetAttribute.tabs.name.edit} element={<EditAssetName />} />
              </Route>
            </Route>
            // Asset Brand
            <Route path={paths.assetAttribute.tabs.brand.default} element={<EntryAssetBrand />}>
              <Route index element={<TableAssetBrand />} />
              <Route path={paths.assetAttribute.tabs.brand.create} element={<CreateAssetBrand />} />
              <Route path={paths.assetAttribute.tabs.brand.particular} element={<AssetBrand />}>
                <Route index element={<PreviewAssetBrand />} />
                <Route path={paths.assetAttribute.tabs.brand.edit} element={<EditAssetBrand />} />
              </Route>
            </Route>
            // Asset Model
            <Route path={paths.assetAttribute.tabs.model.default} element={<EntryAssetModel />}>
              <Route index element={<TableAssetModel />} />
              <Route path={paths.assetAttribute.tabs.model.create} element={<CreateAssetModel />} />
              <Route path={paths.assetAttribute.tabs.model.particular} element={<AssetModel />}>
                <Route index element={<PreviewAssetModel />} />
                <Route path={paths.assetAttribute.tabs.model.edit} element={<EditAssetModel />} />
              </Route>
            </Route>
            // Asset Category
            <Route
              path={paths.assetAttribute.tabs.category.default}
              element={<EntryAssetCategory />}
            >
              <Route index element={<TableAssetCategory />} />
              <Route
                path={paths.assetAttribute.tabs.category.create}
                element={<CreateAssetCategory />}
              />
              <Route
                path={paths.assetAttribute.tabs.category.particular}
                element={<AssetCategory />}
              >
                <Route index element={<PreviewAssetCategory />} />
                <Route
                  path={paths.assetAttribute.tabs.category.edit}
                  element={<EditAssetCategory />}
                />
              </Route>
            </Route>
            // Asset Property
            <Route
              path={paths.assetAttribute.tabs.property.default}
              element={<EntryAssetProperty />}
            >
              <Route index element={<TableAssetProperty />} />
              <Route
                path={paths.assetAttribute.tabs.property.create}
                element={<CreateAssetProperty />}
              />
              <Route
                path={paths.assetAttribute.tabs.property.particular}
                element={<AssetProperty />}
              >
                <Route index element={<PreviewAssetProperty />} />
                <Route
                  path={paths.assetAttribute.tabs.property.edit}
                  element={<EditAssetProperty />}
                />
              </Route>
            </Route>
            // Asset Status
            <Route path={paths.assetAttribute.tabs.status.default} element={<EntryAssetStatus />}>
              <Route index element={<TableAssetStatus />} />
              <Route
                path={paths.assetAttribute.tabs.status.create}
                element={<CreateAssetStatus />}
              />
              <Route path={paths.assetAttribute.tabs.status.particular} element={<AssetStatus />}>
                <Route index element={<PreviewAssetStatus />} />
                <Route path={paths.assetAttribute.tabs.status.edit} element={<EditAssetStatus />} />
              </Route>
            </Route>
          </Route>
          /* --------------------------- Part And Inventory Attributes ---------------------------
          */
          <Route
            path={paths.partAndInventoryAttributes.default}
            element={<EntryPartAndInventories />}
          >
            // Part and Inventory Name
            <Route
              path={paths.partAndInventoryAttributes.tabs.name.default}
              element={<EntryPartAndInventoryName />}
            >
              <Route index element={<TablePartAntInventoryName />} />
              <Route
                path={paths.partAndInventoryAttributes.tabs.name.create}
                element={<CreatePartAndInventoryName />}
              />
              <Route
                path={paths.partAndInventoryAttributes.tabs.name.particular}
                element={<PartAndInventoryName />}
              >
                <Route index element={<PreviewPartAndInventoryName />} />
                <Route
                  path={paths.partAndInventoryAttributes.tabs.name.edit}
                  element={<EditPartAndInventoryName />}
                />
              </Route>
            </Route>
            // Part and Inventory Brand
            <Route
              path={paths.partAndInventoryAttributes.tabs.brand.default}
              element={<EntryPartAndInventoryBrand />}
            >
              <Route index element={<TablePartAntInventoryBrand />} />
              <Route
                path={paths.partAndInventoryAttributes.tabs.brand.create}
                element={<CreatePartAndInventoryBrand />}
              />
              <Route
                path={paths.partAndInventoryAttributes.tabs.brand.particular}
                element={<PartAndInventoryBrand />}
              >
                <Route index element={<PreviewPartAndInventoryBrand />} />
                <Route
                  path={paths.partAndInventoryAttributes.tabs.brand.edit}
                  element={<EditPartAndInventoryBrand />}
                />
              </Route>
            </Route>
            // Part and Inventory Category
            <Route
              path={paths.partAndInventoryAttributes.tabs.category.default}
              element={<EntryPartAndInventoryCategory />}
            >
              <Route index element={<TablePartAntInventoryCategory />} />
              <Route
                path={paths.partAndInventoryAttributes.tabs.category.create}
                element={<CreatePartAndInventoryCategory />}
              />
              <Route
                path={paths.partAndInventoryAttributes.tabs.category.particular}
                element={<PartAndInventoryCategory />}
              >
                <Route index element={<PreviewPartAndInventoryCategory />} />
                <Route
                  path={paths.partAndInventoryAttributes.tabs.category.edit}
                  element={<EditPartAndInventoryCategory />}
                />
              </Route>
            </Route>
            // Part and Inventory Model
            <Route
              path={paths.partAndInventoryAttributes.tabs.model.default}
              element={<EntryPartAndInventoryModel />}
            >
              <Route index element={<TablePartAntInventoryModel />} />
              <Route
                path={paths.partAndInventoryAttributes.tabs.model.create}
                element={<CreatePartAndInventoryModel />}
              />
              <Route
                path={paths.partAndInventoryAttributes.tabs.model.particular}
                element={<PartAndInventoryModel />}
              >
                <Route index element={<PreviewPartAndInventoryModel />} />
                <Route
                  path={paths.partAndInventoryAttributes.tabs.model.edit}
                  element={<EditPartAndInventoryModel />}
                />
              </Route>
            </Route>
            // Part and Inventory Property
            <Route
              path={paths.partAndInventoryAttributes.tabs.property.default}
              element={<EntryPartAndInventoryProperty />}
            >
              <Route index element={<TablePartAntInventoryProperty />} />
              <Route
                path={paths.partAndInventoryAttributes.tabs.property.create}
                element={<CreatePartAndInventoryProperty />}
              />
              <Route
                path={paths.partAndInventoryAttributes.tabs.property.particular}
                element={<PartAndInventoryProperty />}
              >
                <Route index element={<PreviewPartAndInventoryProperty />} />
                <Route
                  path={paths.partAndInventoryAttributes.tabs.property.edit}
                  element={<EditPartAndInventoryProperty />}
                />
              </Route>
            </Route>
            // Part and Inventory Unit
            <Route
              path={paths.partAndInventoryAttributes.tabs.unit.default}
              element={<EntryPartAndInventoryUnit />}
            >
              <Route index element={<TablePartAntInventoryUnit />} />
              <Route
                path={paths.partAndInventoryAttributes.tabs.unit.create}
                element={<CreatePartAndInventoryUnit />}
              />
              <Route
                path={paths.partAndInventoryAttributes.tabs.unit.particular}
                element={<PartAndInventoryUnit />}
              >
                <Route index element={<PreviewPartAndInventoryUnit />} />
                <Route
                  path={paths.partAndInventoryAttributes.tabs.unit.edit}
                  element={<EditPartAndInventoryUnit />}
                />
              </Route>
            </Route>
            <Route
              path={paths.partAndInventoryAttributes.tabs.status.default}
              element={<EntryPartAndInventoryStatus />}
            >
              <Route index element={<TablePartAntInventoryStatus />} />
              <Route
                path={paths.partAndInventoryAttributes.tabs.status.create}
                element={<CreatePartAndInventoryStatus />}
              />
              <Route
                path={paths.partAndInventoryAttributes.tabs.status.particular}
                element={<PartAndInventoryStatus />}
              >
                <Route index element={<PreviewPartAndInventoryStatus />} />
                <Route
                  path={paths.partAndInventoryAttributes.tabs.status.edit}
                  element={<EditPartAndInventoryStatus />}
                />
              </Route>
            </Route>
          </Route>
          /* --------------------------- Maintenance Attributes --------------------------- */
          <Route
            path={paths.maintenanceAttributes.default}
            element={<EntryMaintenanceAttributes />}
          >
            // Maintenance Category
            <Route
              path={paths.maintenanceAttributes.tabs.category.default}
              element={<EntryMaintenanceCategory />}
            >
              <Route index element={<TableMaintenanceCategory />} />
              <Route
                path={paths.maintenanceAttributes.tabs.category.create}
                element={<CreateMaintenanceCategory />}
              />
              <Route
                path={paths.maintenanceAttributes.tabs.category.particular}
                element={<MaintenanceCategory />}
              >
                <Route index element={<PreviewMaintenanceCategory />} />
                <Route
                  path={paths.maintenanceAttributes.tabs.category.edit}
                  element={<EditMaintenanceCategory />}
                />
              </Route>
            </Route>
            // Maintenance Checklist
            <Route
              path={paths.maintenanceAttributes.tabs.checklist.default}
              element={<EntryMaintenanceChecklist />}
            >
              <Route index element={<TableMaintenanceChecklist />} />
              <Route
                path={paths.maintenanceAttributes.tabs.checklist.create}
                element={<CreateMaintenanceChecklist />}
              />
              <Route
                path={paths.maintenanceAttributes.tabs.checklist.particular}
                element={<MaintenanceChecklist />}
              >
                <Route index element={<PreviewMaintenanceChecklist />} />
                <Route
                  path={paths.maintenanceAttributes.tabs.checklist.edit}
                  element={<EditMaintenanceChecklist />}
                />
              </Route>
            </Route>
            // Maintenance Request Reason
            <Route
              path={paths.maintenanceAttributes.tabs.requestReason.default}
              element={<EntryMaintenanceRequestReason />}
            >
              <Route index element={<TableMaintenanceRequestReason />} />
              <Route
                path={paths.maintenanceAttributes.tabs.requestReason.create}
                element={<CreateMaintenanceRequestReason />}
              />
              <Route
                path={paths.maintenanceAttributes.tabs.requestReason.particular}
                element={<MaintenanceRequestReason />}
              >
                <Route index element={<PreviewMaintenanceRequestReason />} />
                <Route
                  path={paths.maintenanceAttributes.tabs.requestReason.edit}
                  element={<EditMaintenanceRequestReason />}
                />
              </Route>
            </Route>
          </Route>
          /* --------------------------------- Company -------------------------------- */
          <Route path={paths.companies.default} element={<EntryCompany />}>
            <Route index element={<TableCompany />} />
            <Route path={paths.companies.create} element={<CreateCompany />} />
            <Route path={paths.companies.particular} element={<Company />}>
              <Route index element={<PreviewCompany />} />
              <Route path={paths.companies.edit} element={<EditCompany />} />
            </Route>
          </Route>
          /* --------------------------------- Vendor --------------------------------- */
          <Route path={paths.vendor.default} element={<EntryVendor />}>
            <Route index element={<TableVendor />} />
            <Route path={paths.vendor.create} element={<CreateVendor />} />
            <Route path={paths.vendor.particular} element={<Vendor />}>
              <Route index element={<PreviewVendor />} />
              <Route path={paths.vendor.edit} element={<EditVendor />} />
            </Route>
          </Route>
          /* ---------------------------------- Site ---------------------------------- */
          <Route path={paths.site.default} element={<EntrySite />}>
            <Route index element={<TableSite />} />
            <Route path={paths.site.create} element={<CreateSite />} />
            <Route path={paths.site.particular} element={<Site />}>
              <Route index element={<PreviewSite />} />
              <Route path={paths.site.edit} element={<EditSite />} />
            </Route>
          </Route>
          /* ---------------------------------- Title --------------------------------- */
          <Route path={paths.title.default} element={<EntryTitle />}>
            <Route index element={<TableTitle />} />
            <Route path={paths.title.create} element={<CreateTitle />} />
            <Route path={paths.title.particular} element={<Title />}>
              <Route index element={<PreviewTitle />} />
              <Route path={paths.title.edit} element={<EditTitle />} />
            </Route>
          </Route>
          /* ---------------------------------- Users --------------------------------- */
          <Route path={paths.users.default} element={<EntryUser />}>
            <Route index element={<TableUser />} />
            <Route path={paths.users.create} element={<CreateUser />} />
            <Route path={paths.users.particular} element={<User />}>
              <Route index element={<PreviewUser />} />
              <Route path={paths.users.edit} element={<EditUser />} />
            </Route>
          </Route>
          /* ---------------------------------- User Groups --------------------------------- */
          <Route path={paths.usergroups.default} element={<EntryUserGroup />}>
            <Route index element={<TableUserGroup />} />
            <Route path={paths.usergroups.create} element={<CreateUserGroup />} />
            <Route path={paths.usergroups.particular} element={<UserGroup />}>
              <Route index element={<PreviewUserGroup />} />
              <Route path={paths.usergroups.edit} element={<EditUserGroup />} />
            </Route>
          </Route>
          /* ---------------------------------- Roles --------------------------------- */
          <Route path={paths.roles.default} element={<EntryRole />}>
            <Route index element={<TableRole />} />
            <Route path={paths.roles.create} element={<CreateRole />} />
            <Route path={paths.roles.particular} element={<Role />}>
              <Route index element={<PreviewRole />} />
              <Route path={paths.roles.edit} element={<EditRole />} />
            </Route>
          </Route>
          /* ------------------------------User Authorizations --------------------------------- */
          <Route path={paths.userauth.default} element={<EntryUserAuthorization />}>
            <Route index element={<TableUserAuthorization />} />
            <Route path={paths.userauth.create} element={<CreateUserAuthorization />} />
            <Route path={paths.userauth.particular} element={<UserAuthorization />}>
              <Route index element={<PreviewUserAuthorization />} />
              <Route path={paths.usergroups.edit} element={<EditUserAuthorization />} />
            </Route>
          </Route>
          /* ------------------------------- Department ------------------------------- */
          <Route path={paths.department.default} element={<EntryDepartment />}>
            <Route index element={<TableDepartment />} />
            <Route path={paths.department.create} element={<CreateDepartment />} />
            <Route path={paths.department.particular} element={<Department />}>
              <Route index element={<PreviewDepartment />} />
              <Route path={paths.department.edit} element={<EditDepartment />} />
            </Route>
          </Route>
          /* -------------------------------- Exit Type ------------------------------- */
          <Route path={paths.exitType.default} element={<EntryExitType />}>
            <Route index element={<TableExitType />} />
            <Route path={paths.exitType.create} element={<CreateExitType />} />
            <Route path={paths.exitType.particular} element={<ExitType />}>
              <Route index element={<PreviewExitType />} />
              <Route path={paths.exitType.edit} element={<EditExitType />} />
            </Route>
          </Route>
          /* ------------------------------- Cost Center ------------------------------ */
          <Route path={paths.costCenter.default} element={<EntryCostCenter />}>
            <Route index element={<TableCostCenter />} />
            <Route path={paths.costCenter.create} element={<CreateCostCenter />} />
            <Route path={paths.costCenter.particular} element={<CostCenter />}>
              <Route index element={<PreviewCostCenter />} />
              <Route path={paths.costCenter.edit} element={<EditCostCenter />} />
            </Route>
          </Route>
          /* -------------------------------- Check Out ------------------------------- */
          <Route path={paths.checkOut.default} element={<EntryCheckOut />}>
            <Route index element={<TableCheckOut />} />
            <Route path={paths.checkOut.create} element={<CreateCheckOut />} />
            <Route path={paths.checkOut.particular} element={<CheckOut />}>
              <Route index element={<PreviewCheckOut />} />
              <Route path={paths.checkOut.edit} element={<EditCheckOut />} />
            </Route>
          </Route>
          /* --------------------------------- Contract ------------------------------- */
          <Route path={paths.contract.default} element={<EntryContract />}>
            <Route index element={<TableContract />} />
            <Route path={paths.contract.create} element={<CreateContract />} />
            <Route path={paths.contract.particular} element={<Contract />}>
              <Route index element={<PreviewContract />} />
              <Route path={paths.contract.edit} element={<EditContract />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default AppRouter;
