import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectUserAuthList, selectUserAuthStatus } from '@store/selectors/users/UserAuthorization';

import type { BasicTablePageProps, ColumnsTableType } from '@components/common/BasicTablePage';
import type { UserModel } from '@model/users/User';

const columnsUser: ColumnsTableType<UserModel & { assignedName: string }> = [
  {
    title: <Translation>{(t) => t('titles.User_Name')}</Translation>,
    dataIndex: 'assignedName',
    key: 'assignedName',
    ellipsis: true,
  },
];

function UserAuthorizations() {

  const { t } = useTranslation();

  const listAuth = useAppSelector(selectUserAuthList);
  const statusAuth = useAppSelector(selectUserAuthStatus);

  const dataSource = useMemo(
    () =>
      listAuth.map((auth) => {
        const userName = `${auth?.firstName} ${auth?.lastName}`;
        return {
          ...auth,
          assignedName: userName,
        };
        ``;
      }),
    [listAuth]
  );

  const table: BasicTablePageProps<UserModel & { assignedName: string }>['table'] = {
    loading: statusAuth === 'pending',
    dataSource: dataSource,
    columns: columnsUser,
    rowKey: (record) => record.userId,
    dataSourceName: t('pages_plural.Authorizations'),
  };

  return (
    <BasicTablePage
      table={table}
      header={{
        onlyRedirectButton: true,
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Authorization') }),
      }}
    />
  );
}

export { UserAuthorizations };
