import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormCostCenter } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addCostCenter } from '@store/actions/properties/CostCenter';

import type { CreateCostCenterDto } from '@model/properties/CostCenter';
import type { FormInstance } from 'antd';

function CreateCostCenter() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { costCenterAccess } = useGetAccess();

  const onFinish = (dto: CreateCostCenterDto, form?: FormInstance<CreateCostCenterDto>) => {
    dispatch(addCostCenter(dto))
      .unwrap()
      .then((payload) => {
        ApiNotifications.create(payload.name);
        form?.resetFields();
        navigate(paths.costCenter.default);
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <CheckAccess hasAccess={costCenterAccess.create} redirectTo={paths.costCenter.default}>
      <FormCostCenter
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.Cost_Center') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreateCostCenter;
