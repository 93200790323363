import React, { memo } from 'react';

import { Col, Row } from 'antd';

import { DayOfWeek } from '../DayOfWeek';

import type { DayOfWeek as DayOfWeekType } from '@shared/utils/types';

import './DaysList.scss';

interface DaysProps {
  daysOfWeekList: Array<DayOfWeekType>;

  onChangeDay: (checked: boolean, id: string | number) => void;
}

export const DaysList = memo((props: DaysProps) => {
  const { daysOfWeekList, onChangeDay } = props;

  const list = daysOfWeekList.map((day) => (
    <Col key={day.dayOfWeekIndex}>
      <DayOfWeek
        id={day.dayOfWeekIndex}
        label={day.label}
        checked={day.checked}
        onChange={onChangeDay}
      />
    </Col>
  ));

  return (
    <Row justify="space-between" className="daysOfWeek-list">
      {list}
    </Row>
  );
});
