import React, { memo } from 'react';

import { useClassPrefix } from '@hooks';

import { Dropdown } from '../Dropdown';
import { Filter } from '../Filter';
import { LabelFilter } from '../LabelFilter';
import { NewButton } from '../NewButton';
import { Search } from '../Search';
import { ViewSwitcher } from '../ViewSwitcher';

import type { DropdownProps } from '../Dropdown';
import type { FilterProps } from '../Filter';
import type { LabelFilterProps } from '../LabelFilter';
import type { SearchProps } from '../Search';
import type { ViewSwitcherProps } from '../ViewSwitcher';

import './RootHeader.scss';

export type RootHeaderProps = LabelFilterProps &
  ViewSwitcherProps &
  SearchProps &
  FilterProps &
  DropdownProps & {
    showAssetsCount?: boolean;
  };

export const RootHeader = memo((props: RootHeaderProps) => {
  const {
    disabledFilter,
    disabledLabelFilter,
    disabledSwitcher,
    disabledDropdown,
    disabledSearch,
  } = props;

  const { prefix, withPrefix } = useClassPrefix('root-asset-header');

  return (
    <div className={prefix}>
      <div className={withPrefix('left-side')}>
        <div className={withPrefix('search')}>
          <Search disabledSearch={disabledSearch} />
        </div>
        <div className={withPrefix('switcher-view')}>
          <ViewSwitcher disabledSwitcher={disabledSwitcher} />
        </div>
        <div className={withPrefix('label-filter')}>
          <LabelFilter disabledLabelFilter={disabledLabelFilter} />
        </div>
      </div>
      <div className={withPrefix('right-side')}>
        <div className={withPrefix('filter')}>
          <Filter disabledFilter={disabledFilter} />
        </div>

        <div className={withPrefix('buttons')}>
          <Dropdown disabledDropdown={disabledDropdown} />
          <NewButton />
        </div>
      </div>
    </div>
  );
});
