import React from 'react';

import {
  PreviewFormPartAndInventoryByName,
  PreviewFormPartAndInventoryByPO,
} from '@components/views/Previews/management';
import { useOutletPartAndInventory } from '@constants/OutletContextEntityPages';
import { useAppSelector, useGetAccess } from '@hooks';
import { selectPaISettings } from '@store/selectors/management/PaI';

import type { CommonPreviewProps } from '@components/common';

function PreviewPartAndInventory() {
  const { entity } = useOutletPartAndInventory();

  const { consumeType } = useAppSelector(selectPaISettings);

  const { paiAccess } = useGetAccess();

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !paiAccess.edit,
    },
    deleteButtonProps: {
      disabled: !paiAccess.delete,
    },
  };

  return consumeType === 1 ? (
    <PreviewFormPartAndInventoryByPO entity={entity} header={headerProps} />
  ) : (
    <PreviewFormPartAndInventoryByName entity={entity} header={headerProps} />
  );
}

export default PreviewPartAndInventory;
