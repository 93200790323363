import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common/BasicTablePage';
import { useAppDispatch, useAppSelector, useGetAccess } from '@hooks';
import { Select } from '@shared/UI';
import { checkFilterValues, compare } from '@shared/utils/functions';
import { getExitTypes, getExitTypesByFilter } from '@store/actions/properties/ExitType';
import { 
  selectExitTypesFilteredList,
  selectExitTypesFilters,
  selectExitTypesList, 
  selectExitTypeStatus,
} from '@store/selectors/properties/ExitType';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { ExitTypeModel, FilterExitTypeDto } from '@model/properties/ExitType';

const columns: ColumnsTableType<ExitTypeModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'code',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.code, b.code),
  },
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

function ExitTypes() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const listExitType = useAppSelector(selectExitTypesList);
  const filteredListExitType = useAppSelector(selectExitTypesFilteredList);
  const statusExitType = useAppSelector(selectExitTypeStatus);
  const filters = useAppSelector(selectExitTypesFilters);

  const { exitTypeAccess } = useGetAccess();

  const dataSource = useMemo(
    () => (Boolean(filteredListExitType.length) ? filteredListExitType : listExitType),
    [filteredListExitType, listExitType]
  );

  const filterProps = {
    items: [
      {
        label: t('titles.Name'),
        name: 'nameArray',
        item: (
          <Select
            options={listExitType}
            maxTagCount={4}
            mode="tags"
            fieldNames={{ label: 'name', value: 'name' }}
          />
        ),
      },
      {
        label: t('titles.Code'),
        name: 'codeArray',
        item: (
          <Select
            options={listExitType}
            maxTagCount={4}
            mode="tags"
            fieldNames={{ label: 'code', value: 'code' }}
          />
        ),
      },
    ],
    applyAction: (values: FilterExitTypeDto) => {
      if (!checkFilterValues(values)) {
        getExitTypesList();
        return;
      }

      dispatch(getExitTypesByFilter(values));
    },
    resetAction: () => {
      if (checkFilterValues(filters)) {
        getExitTypesList();
      }
    },
    initialValuesForm: {
      nameArray: filters?.nameArray,
      codeArray: filters?.codeArray,
    },
  };

  function getExitTypesList() {
    dispatch(getExitTypes());
  }

  return (
    <BasicTablePage
      table={{
        columns,
        dataSource: dataSource,
        loading: statusExitType === 'pending',
        rowKey: (record) => record.checkFactorId,
        dataSourceName: t('pages_plural.Exit_Types'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Exit_Type') }),
        csvConfig: {
          data: listExitType,
          fileName: 'exit-types.csv',
          headers: [
            {
              label: 'Code',
              key: 'code',
            },
            {
              label: 'Name',
              key: 'name',
            },
          ],
        },
        filterProps,
        newButtonProps: {
          disabled: !exitTypeAccess.create,
        },
      }}
    />
  );
}

export { ExitTypes };
