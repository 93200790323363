import { createSlice } from '@reduxjs/toolkit';
import {
  addAsAtCategory,
  getAsAtCategories,
  getAsAtCategoryById,
  removeAsAtCategoryById,
  updateAsAtCategory,
  updateAsAtCategoryParentRelations,
} from '@store/actions/properties/AsAt/Category';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AsAtCategoryModel } from '@model/properties/AsAt/Category';

interface AsAtCategoryState {
  AsAtCategoriesList: AsAtCategoryModel[];
  AsAtCategory: AsAtCategoryModel | null;
  AsAtCategoryStatus: LoadingStatus;
  AsAtCategoryError: string | null;
}

const initialState: AsAtCategoryState = {
  AsAtCategoriesList: [],
  AsAtCategory: null,
  AsAtCategoryStatus: 'idle',
  AsAtCategoryError: null,
};

const AsAtCategorySlice = createSlice({
  name: 'AsAtCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsAtCategories.pending, (state) => {
        state.AsAtCategoryError = null;
        state.AsAtCategoryStatus = 'pending';
      })
      .addCase(getAsAtCategories.fulfilled, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        state.AsAtCategoriesList = payload;
      })
      .addCase(getAsAtCategories.rejected, (state) => {
        state.AsAtCategoryStatus = 'idle';
      });

    builder
      .addCase(addAsAtCategory.pending, (state) => {
        state.AsAtCategoryStatus = 'pending';
        state.AsAtCategoryError = null;
      })
      .addCase(addAsAtCategory.fulfilled, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        state.AsAtCategoriesList = payload.AsAtCategoriesList;
      })
      .addCase(addAsAtCategory.rejected, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        if (payload) {
          state.AsAtCategoryError = payload;
        }
      });

    builder
      .addCase(getAsAtCategoryById.pending, (state) => {
        state.AsAtCategoryStatus = 'pending';
        state.AsAtCategoryError = null;
      })
      .addCase(getAsAtCategoryById.fulfilled, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        state.AsAtCategory = payload;
      })
      .addCase(getAsAtCategoryById.rejected, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        if (payload) {
          state.AsAtCategoryError = payload;
        }
      });

    builder
      .addCase(updateAsAtCategory.pending, (state) => {
        state.AsAtCategoryStatus = 'pending';
        state.AsAtCategoryError = null;
      })
      .addCase(updateAsAtCategory.fulfilled, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        state.AsAtCategoriesList = payload.AsAtCategoriesList;
        state.AsAtCategory = payload.AsAtCategory;
      })
      .addCase(updateAsAtCategory.rejected, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        if (payload) {
          state.AsAtCategoryError = payload;
        }
      });

    builder
      .addCase(removeAsAtCategoryById.pending, (state) => {
        state.AsAtCategoryStatus = 'pending';
        state.AsAtCategoryError = null;
      })
      .addCase(removeAsAtCategoryById.fulfilled, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        state.AsAtCategoriesList = payload;
      })
      .addCase(removeAsAtCategoryById.rejected, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        if (payload) {
          state.AsAtCategoryError = payload;
        }
      });

    builder
      .addCase(updateAsAtCategoryParentRelations.pending, (state) => {
        state.AsAtCategoryError = null;
        state.AsAtCategoryStatus = 'pending';
      })
      .addCase(updateAsAtCategoryParentRelations.fulfilled, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        state.AsAtCategoriesList = payload;
      })
      .addCase(updateAsAtCategoryParentRelations.rejected, (state, { payload }) => {
        state.AsAtCategoryStatus = 'idle';
        if (payload) {
          state.AsAtCategoryError = payload;
        }
      });
  },
});

const AsAtCategoryReducer = AsAtCategorySlice.reducer;

export { AsAtCategoryReducer };
