import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { defaultFormatDate } from '@constants/Date';
import { REQUIRED_RULE } from '@constants/formRules';
import { Col, DatePicker, Row } from '@shared/UI';

import type { CreateContractDto } from '@model/properties/Contract';
import type { ItemRules } from '@shared/utils/types';
import type { Moment } from 'moment';

interface DateBlockProps {}

const { Item, useFormInstance } = CommonForm;

// region --- Item rules

const dateRules: ItemRules = [REQUIRED_RULE];

// endregion --- Item rules

const DateBlock = (props: DateBlockProps) => {
  const {} = props;

  const { t } = useTranslation();

  const form = useFormInstance<CreateContractDto>();

  const disabledEndDate = useCallback(
    (currentDate: Moment) => {
      const startDate = form.getFieldValue('startDate') as Moment;

      if (!startDate) return false;

      return currentDate <= startDate.clone();
    },
    [form]
  );

  const disabledStartDate = useCallback(
    (currentDate: Moment) => {
      const endDate = form.getFieldValue('endDate') as Moment;

      if (!endDate) return false;

      return currentDate >= endDate.clone();
    },
    [form]
  );

  return (
    <Row gutter={[10, 0]} justify="space-between">
      <Col md={10} xs={24}>
        <Item label={t('titles.Start_Date')} name="startDate" rules={dateRules}>
          <DatePicker
            textAlign="center"
            placeholder={defaultFormatDate}
            disabledDate={disabledStartDate}
            allowClear={false}
          />
        </Item>
      </Col>
      <Col md={10} xs={24}>
        <Item label={t('titles.End_Date')} name="endDate" rules={dateRules}>
          <DatePicker
            textAlign="center"
            placeholder={defaultFormatDate}
            disabledDate={disabledEndDate}
            allowClear={false}
          />
        </Item>
      </Col>
    </Row>
  );
};

export { DateBlock };
