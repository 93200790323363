import * as React from 'react';

import type { SVGProps } from 'react';

const DragHandle = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.008 10.178h-.01m4.01 0h-.01.01Zm4 0h-.01.01ZM8.008 13.822h-.01m4.01 0h-.01.01Zm4 0h-.01.01Z"
      stroke="#D2D2D2"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { DragHandle };
