import { createSlice } from '@reduxjs/toolkit';
import {
  addCostCenter,
  getCostCenterById,
  getCostCenters,
  getCostCentersByFilter,
  removeCostCenterById,
  updateCostCenter,
} from '@store/actions/properties/CostCenter';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { CostCenterModel, FilterCostCenterDto } from '@model/properties/CostCenter';

type CostCenterState = {
  CostCentersList: CostCenterModel[];
  CostCentersFilteredList: CostCenterModel[];
  CostCenter: null | CostCenterModel;
  CostCenterStatus: LoadingStatus;
  CostCenterError: null | string;
  CostCenterFilters: FilterCostCenterDto;
};

const initialState: CostCenterState = {
  CostCentersList: [],
  CostCentersFilteredList: [],
  CostCenter: null,
  CostCenterStatus: 'idle',
  CostCenterError: null,
  CostCenterFilters: {
    costCenterCodeArray: [],
    nameArray: [],
  },
};

const CostCenterSlice = createSlice({
  name: 'CostCenter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCostCenters.pending, (state) => {
        state.CostCenterStatus = 'pending';
        state.CostCenterError = null;
        state.CostCentersFilteredList = [];
        state.CostCenterFilters = {
          costCenterCodeArray: [],
          nameArray: [],
        };
      })
      .addCase(getCostCenters.fulfilled, (state, action) => {
        state.CostCentersList = action.payload;
        state.CostCenterStatus = 'idle';
      })
      .addCase(getCostCenters.rejected, (state, action) => {
        state.CostCenterStatus = 'idle';
        if (action.payload) {
          state.CostCenterError = action.payload;
        }
      });
    builder
      .addCase(getCostCentersByFilter.pending, (state) => {
        state.CostCenterError = null;
        state.CostCenterStatus = 'pending';
      })
      .addCase(getCostCentersByFilter.fulfilled, (state, { payload }) => {
        state.CostCenterStatus = 'idle';
        state.CostCentersFilteredList = payload.costCenters;
        state.CostCenterFilters = payload.costCentersFilters;
      })
      .addCase(getCostCentersByFilter.rejected, (state, action) => {
        state.CostCenterStatus = 'idle';
        if (action.payload) {
          state.CostCenterError = action.payload;
        }
      });
    builder
      .addCase(addCostCenter.pending, (state) => {
        state.CostCenterStatus = 'pending';
        state.CostCenterError = null;
      })
      .addCase(addCostCenter.fulfilled, (state, { payload }) => {
        state.CostCenterStatus = 'idle';
        state.CostCentersList = [payload, ...state.CostCentersList];
      })
      .addCase(addCostCenter.rejected, (state, { payload }) => {
        state.CostCenterStatus = 'idle';
        if (payload) {
          state.CostCenterError = payload;
        }
      });

    builder
      .addCase(getCostCenterById.pending, (state) => {
        state.CostCenterStatus = 'pending';
        state.CostCenterError = null;
      })
      .addCase(getCostCenterById.fulfilled, (state, { payload }) => {
        state.CostCenterStatus = 'idle';
        state.CostCenter = payload;
      })
      .addCase(getCostCenterById.rejected, (state, { payload }) => {
        state.CostCenterStatus = 'idle';
        if (payload) {
          state.CostCenterError = payload;
        }
      });

    builder
      .addCase(updateCostCenter.pending, (state) => {
        state.CostCenterStatus = 'pending';
        state.CostCenterError = null;
      })
      .addCase(updateCostCenter.fulfilled, (state, { payload }) => {
        state.CostCenterStatus = 'idle';
        state.CostCentersList = payload.CostCentersList;
        state.CostCenter = payload.CostCenter;
      })
      .addCase(updateCostCenter.rejected, (state, { payload }) => {
        state.CostCenterStatus = 'idle';
        if (payload) {
          state.CostCenterError = payload;
        }
      });

    builder
      .addCase(removeCostCenterById.pending, (state) => {
        state.CostCenterStatus = 'pending';
        state.CostCenterError = null;
      })
      .addCase(removeCostCenterById.fulfilled, (state, { payload }) => {
        state.CostCenterStatus = 'idle';
        state.CostCentersList = payload;
      })
      .addCase(removeCostCenterById.rejected, (state, { payload }) => {
        state.CostCenterStatus = 'idle';
        if (payload) {
          state.CostCenterError = payload;
        }
      });
  },
});

const CostCenterReducer = CostCenterSlice.reducer;

export { CostCenterReducer };
