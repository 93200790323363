import React from 'react';

import { PreviewFormMaintenanceRequestReason } from '@components/views/Previews/properties';
import { useOutletMaintenanceRequestReason } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeMaAtRequestReasonById } from '@store/actions/properties/MaAt/RequestReason';

import type { CommonPreviewProps } from '@components/common';

function PreviewMaintenanceRequestReason() {
  const { entity } = useOutletMaintenanceRequestReason();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { maintenanceAttrAccess } = useGetAccess();

  const onDelete = () => {
    dispatch(removeMaAtRequestReasonById([entity.requestReasonId]))
      .unwrap()
      .then(() => {
        notificationController.success({
          description: `${entity.reasonName} deleted`,
        });
        navigate(paths.maintenanceAttributes.tabs.requestReason.default);
      })
      .catch((err) => {
        if (typeof err === 'string') {
          notificationController.error({
            description: err,
          });
        }
      });
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !maintenanceAttrAccess.edit,
    },
    deleteButtonProps: {
      disabled: !maintenanceAttrAccess.delete,
    },
  };

  return (
    <PreviewFormMaintenanceRequestReason entity={entity} onDelete={onDelete} header={headerProps} />
  );
}

export default PreviewMaintenanceRequestReason;
