import React, { createContext, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppNavigate, useAppSelector } from '@hooks';
import { paths } from '@routes/paths';
import LocaleStorage from '@services/LocaleStorage/LocalStorage.service';
import { Loader } from '@shared/UI';
import { signIn as signInAction, signOut as signOutAction } from '@store/actions/profile/Auth';
import { getProfile } from '@store/actions/profile/Profile';
import { selectAuthIsAuth } from '@store/selectors/profile/Auth';
import { selectProfile, selectProfileLoading } from '@store/selectors/profile/Profile';

import type { SignInDto } from '@model/profile/Auth';

type AuthProviderProps = {
  children: React.ReactNode;
};

type AuthContextValue = {
  signIn: (dto: SignInDto, callback?: VoidFunction) => void;
  signOut: (callback?: VoidFunction) => void;
  isAuth: boolean;
};

export const AuthContext = createContext<AuthContextValue>(null!);

const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;

  const profile = useAppSelector(selectProfile);

  const isAuth = useAppSelector(selectAuthIsAuth);

  const profileStatus = useAppSelector(selectProfileLoading);
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const signIn = (dto: SignInDto, callback?: VoidFunction) => {
    dispatch(signInAction(dto))
      .unwrap()
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

  const signOut = (callback?: VoidFunction) => {
    dispatch(signOutAction());

    LocaleStorage.removeAuthToken();

    navigate(paths.login.default);

    callback && callback();
  };

  useEffect(() => {
    if (isAuth && !profile) {
      dispatch(getProfile());
    }
  }, [isAuth]);

  const value = useMemo(
    () => ({
      signIn,
      signOut,
      isAuth,
    }),
    [signIn, signOut, isAuth]
  );

  if (isAuth && !profile && profileStatus) {
    return (
      <Loader
        spinning
        size="large"
        sizeIcon={60}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          transform: 'translate(0,50%)',
        }}
      />
    );
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
