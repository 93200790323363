import { ListViewType } from '@constants/listViewType';
import { createSlice } from '@reduxjs/toolkit';
import LocalStorageService from '@services/LocaleStorage';
import {
  addAsset,
  addAssetImagesById,
  batchUpdateAssets,
  clearCheckedAssets,
  getAssetAllRecords,
  getAssetById,
  getAssets,
  getAssetsByCardId,
  handleCheckedAssets,
  removeAssetById,
  removeAssetImageById,
  resetAssetFilters,
  updateAsset,
  updateAssetFilterOptions,
  updateAssetView,
  updateOrderAssetPicture,
} from '@store/actions/management/Asset';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AssetFileModel, AssetModel, FilterAssetDto } from '@model/management/Asset';

interface AssetState {
  assetsList: AssetModel[];
  assetsAllRecordList: AssetModel[];
  assetStatus: LoadingStatus;
  asset: AssetModel | null;
  assetImages: AssetFileModel[] | null;
  assetError: string | null;
  filterOptions: FilterAssetDto;
  assetSettings: {
    totalPage: number;
    totalRecords: number;
  };

  assetListView: ListViewType;

  checked: {
    entities: AssetModel[];
    isCheckedAll: boolean;
  };
}

const getInitialView = () => {
  const view = LocalStorageService.handleListView();

  if (!view) {
    LocalStorageService.handleListView(ListViewType.List);
    return ListViewType.List;
  }

  return view as ListViewType;
};

const initialFilterData: FilterAssetDto = {
  page: 1,
  perPage: 10,
  barcodes: [],
  nonCurrAssetCardsIds: [],
  nonCurrAssetCardCategoriesIds: [],
  serialNumbers: [],
  departmentsIds: [],
  brandsIds: [],
  statusesIds: [],
  sitesIds: [],
  nonCurrAssetCardModelsIds: [],
  usersIds: [],
  nonCurrAssetCardPropertyValues: [],
  nonCurrAssetCardPropertyIds: [],
  labels: [],
  sortBy: null,
};

const initialAssetSettings = {
  totalPage: 0,
  totalRecords: 0,
};

const initialState: AssetState = {
  assetsList: [],
  assetsAllRecordList: [],
  assetStatus: 'idle',
  assetError: null,
  asset: null,
  assetImages: null,
  filterOptions: initialFilterData,
  assetSettings: initialAssetSettings,
  assetListView: getInitialView(),

  checked: {
    entities: [],
    isCheckedAll: false,
  },
};

export const AssetSlice = createSlice({
  name: 'Asset',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssets.pending, (state) => {
        state.assetStatus = 'pending';
        state.assetError = null;
      })
      .addCase(getAssets.fulfilled, (state, { payload }) => {
        state.assetsList = payload.assets;
        state.assetSettings = {
          totalPage: payload.totalPage,
          totalRecords: payload.totalRecords,
        };
        state.assetStatus = 'idle';
        state.filterOptions = payload.filterData;
      })
      .addCase(getAssets.rejected, (state, { payload }) => {
        state.assetStatus = 'idle';
        state.assetsList = [];
        if (payload) {
          state.assetError = payload.error;
          state.filterOptions = payload.filterData;
        }
      });

    builder
      .addCase(getAssetAllRecords.pending, (state) => {
        state.assetStatus = 'pending';
        state.assetError = null;
      })
      .addCase(getAssetAllRecords.fulfilled, (state, action) => {
        state.assetsAllRecordList = action.payload;
        state.assetStatus = 'idle';
      })
      .addCase(getAssetAllRecords.rejected, (state, action) => {
        state.assetStatus = 'idle';
        if (action.payload) {
          state.assetError = action.payload;
        }
      });

    builder
      .addCase(getAssetsByCardId.pending, (state) => {
        state.assetError = null;
        state.assetStatus = 'pending';
      })
      .addCase(getAssetsByCardId.fulfilled, (state) => {
        state.assetStatus = 'idle';
      })
      .addCase(getAssetsByCardId.rejected, (state, { payload }) => {
        state.assetStatus = 'idle';
        state.assetError = payload || null;
      });

    builder.addCase(updateAssetFilterOptions.fulfilled, (state, { payload }) => {
      state.filterOptions = { ...state.filterOptions, ...payload };
    });

    builder
      .addCase(addAsset.pending, (state) => {
        state.assetStatus = 'pending';
        state.assetError = null;
      })
      .addCase(addAsset.fulfilled, (state) => {
        state.assetStatus = 'idle';
      })
      .addCase(addAsset.rejected, (state, { payload }) => {
        state.assetStatus = 'idle';
        if (payload) {
          state.assetError = payload;
        }
      });

    builder
      .addCase(addAssetImagesById.pending, (state) => {
        state.assetError = null;
      })
      .addCase(addAssetImagesById.fulfilled, (state, { payload }) => {
        if (payload) {
          state.asset = payload;
        }
      })
      .addCase(addAssetImagesById.rejected, (state, { payload }) => {
        if (payload) {
          state.assetError = payload;
        }
      });

    builder
      .addCase(getAssetById.pending, (state) => {
        state.assetStatus = 'pending';
        state.assetError = null;
      })
      .addCase(getAssetById.fulfilled, (state, { payload }) => {
        state.assetStatus = 'idle';
        state.asset = payload;
      })
      .addCase(getAssetById.rejected, (state, { payload }) => {
        state.assetStatus = 'idle';
        if (payload) {
          state.assetError = payload;
        }
      });

    builder
      .addCase(updateAsset.pending, (state) => {
        state.assetStatus = 'pending';
        state.assetError = null;
      })
      .addCase(updateAsset.fulfilled, (state, { payload }) => {
        state.assetStatus = 'idle';
        state.assetsList = payload.assets;
        state.asset = payload.asset;
      })
      .addCase(updateAsset.rejected, (state, { payload }) => {
        state.assetStatus = 'idle';
        if (payload) {
          state.assetError = payload;
        }
      });

    builder
      .addCase(batchUpdateAssets.pending, (state) => {
        state.assetStatus = 'pending';
        state.assetError = null;
      })
      .addCase(batchUpdateAssets.fulfilled, (state, { payload }) => {
        state.assetStatus = 'idle';
        state.assetsList = payload;
        state.asset = null;
      })
      .addCase(batchUpdateAssets.rejected, (state, { payload }) => {
        state.assetStatus = 'idle';
        if (payload) {
          state.assetError = payload;
        }
      });

    builder.addCase(updateAssetView, (state, { payload }) => {
      state.assetListView = payload;
    });

    builder.addCase(resetAssetFilters, (state) => {
      const { labels, sortBy, ...restFilterData } = initialFilterData;
      state.filterOptions = {
        ...state.filterOptions,
        ...restFilterData,
      };
    });

    builder
      .addCase(updateOrderAssetPicture.pending, (state) => {
        state.assetError = null;
      })
      .addCase(updateOrderAssetPicture.fulfilled, (state, { payload }) => {
        if (payload.asset) {
          state.asset = payload.asset;
        }
        if (payload.assets) {
          state.assetsList = payload.assets;
        }
      })
      .addCase(updateOrderAssetPicture.rejected, (state, { payload }) => {
        if (payload) {
          state.assetError = payload;
        }
      });

    builder
      .addCase(removeAssetById.pending, (state) => {
        state.assetStatus = 'pending';
        state.assetError = null;
      })
      .addCase(removeAssetById.fulfilled, (state, { payload }) => {
        state.assetStatus = 'idle';
        state.assetsList = payload;
      })
      .addCase(removeAssetById.rejected, (state, { payload }) => {
        state.assetStatus = 'idle';
        if (payload) {
          state.assetError = payload;
        }
      });

    builder
      .addCase(removeAssetImageById.pending, (state) => {
        state.assetError = null;
      })
      .addCase(removeAssetImageById.fulfilled, (state, { payload }) => {
        if (payload) {
          state.asset = payload;
        }
      })
      .addCase(removeAssetImageById.rejected, (state, { payload }) => {
        if (payload) {
          state.assetError = payload;
        }
      });

    builder.addCase(handleCheckedAssets, (state, { payload }) => {
      state.checked.entities = payload.entities;
    });
    builder.addCase(clearCheckedAssets, (state) => {
      state.checked.entities = [];
      state.checked.isCheckedAll = false;
    });
  },
});

const AssetReducer = AssetSlice.reducer;

export { AssetReducer };
