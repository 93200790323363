import React from 'react';

import { Row as AntRow } from 'antd';

import type { RowProps as AntRowProps } from 'antd';

export type RowProps = AntRowProps & {};

export const Row = (props: RowProps) => {
  const { ...restProps } = props;

  return <AntRow {...restProps} />;
};
