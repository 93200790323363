import React from 'react';

import { adaptCheckStepsToMaintenanceAttrCheckSteps } from '@adapters';
import { FormMaintenanceChecklist } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletMaintenanceChecklist } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useAppSelector, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import {
  addMaAtCheckStep,
  addMaAtMultipleChoiceList,
  removeMaAtCheckStepByIds,
  removeMaAtMultipleChoiceListByIds,
  updateMaAtChecklist,
  updateMaAtCheckStep,
  updateMaAtMultipleChoiceList,
} from '@store/actions/properties/MaAt/Checklist';
import { selectProfile } from '@store/selectors/profile/Profile';

import type { ProfileModel } from '@model/profile/Profile';
import type {
  CreateChecklistCheckStepsDto,
  CreateCheckStepDto,
  CreateMultiplyChoiceListDto,
  UpdateCheckStepDto,
} from '@model/properties/MaAt/Checklist';

const EditMaintenanceChecklist = () => {
  const { initialValues, entity } = useOutletMaintenanceChecklist();

  const dispatch = useAppDispatch();

  const navigate = useAppNavigate();

  const { maintenanceAttrAccess } = useGetAccess();

  const profile = useAppSelector(selectProfile) as ProfileModel;

  const onFinish = (dto: CreateChecklistCheckStepsDto) => {
    const { checklistName, description } = dto;
    const { checkListId, checkListCheckSteps } = entity;
    // adapt check steps to maintenance attr check steps
    const checkSteps = adaptCheckStepsToMaintenanceAttrCheckSteps(
      dto.checkSteps,
      checkListId,
      profile.userId
    );

    const updateStatuses: ('success' | 'fail')[] = [];

    // filter steps which have id
    const stepsWithId = checkSteps.filter((step) => Object.hasOwn(step, 'checkStepsId'));
    // filter steps which don't have id
    const stepsWithoutId = checkSteps.filter((step) => !Object.hasOwn(step, 'checkStepsId'));

    // dispatch update action if name or description changed
    if (checklistName !== entity.checklistName || description !== entity.description) {
      dispatch(
        updateMaAtChecklist({
          checklistId: checkListId,
          checklistName,
          description,
        })
      )
        .unwrap()
        .catch(() => {
          updateStatuses.push('fail');
        });
    }

    // checking steps with id or it needs update
    stepsWithId.forEach((updateStep) => {
      const { mcList, ...restStep } = updateStep as UpdateCheckStepDto;
      const initialStep = checkListCheckSteps.find(
        (initStep) => restStep.checkStepsId === initStep.checkStepsId
      );

      // if step title changed dispatch update action
      if (
        initialStep &&
        (restStep.stepName !== initialStep.checkSteps.stepName ||
          restStep.rowId !== initialStep.checkSteps.rowId)
      ) {
        dispatch(updateMaAtCheckStep(restStep))
          .unwrap()
          .catch(() => {
            updateStatuses.push('fail');
          });
      }

      // if step type is multiple choice and options changed
      if (initialStep && restStep.stepTypeId === 2 && mcList && mcList.length > 0) {
        mcList.forEach((option) => {
          const initialOption = initialStep.checkSteps.multipleChoiceLists.find(
            (initOption) => option.multipleChoiceListId === initOption.multipleChoiceListId
          );
          // if option don't have multipleChoiceListId dispatch create action
          if (!initialOption) {
            const opt = option as CreateCheckStepDto['mcList'][number];
            const optionDto: CreateMultiplyChoiceListDto = {
              ...opt,
              checklistId: checkListId,
              checkStepsId: restStep.checkStepsId,
            };
            dispatch(addMaAtMultipleChoiceList(optionDto))
              .unwrap()
              .catch(() => {
                updateStatuses.push('fail');
              });
            return;
          }

          // if option title changed dispatch update action
          if (option.multipleCheckListOptionText !== initialOption.optionText) {
            dispatch(updateMaAtMultipleChoiceList(option))
              .unwrap()
              .catch(() => {
                updateStatuses.push('fail');
              });
          }
        });
      }
    });
    // checking steps without id or it needs create
    if (stepsWithoutId.length > 0) {
      const steps = stepsWithoutId as CreateCheckStepDto[];
      dispatch(addMaAtCheckStep(steps))
        .unwrap()
        .catch(() => {
          updateStatuses.push('fail');
        });
    }

    const hasFailStatus = updateStatuses.includes('fail');

    if (!hasFailStatus) {
      ApiNotifications.update(entity.checklistName);
      navigate(paths.maintenanceAttributes.tabs.checklist.default);
    }
  };

  const onRemoveStep = (stepId: number) => {
    dispatch(removeMaAtCheckStepByIds([stepId]));
  };

  const onRemoveStepOption = (stepId: number, optionId: number) => {
    dispatch(
      removeMaAtMultipleChoiceListByIds({
        stepId,
        optionIds: [optionId],
      })
    );
  };

  const header = {
    title: entity.checklistName,
  };

  return (
    <CheckAccess hasAccess={maintenanceAttrAccess.edit} redirectTo={paths.maintenanceAttributes.tabs.checklist.default}>
      <FormMaintenanceChecklist
        initialValues={initialValues}
        header={header}
        onFinish={onFinish}
        onRemoveOptionAction={onRemoveStepOption}
        onRemoveStepAction={onRemoveStep}
      />
    </CheckAccess>
  );
};

export default EditMaintenanceChecklist;
