import React from 'react';

import { Radio as AntdRadio } from 'antd';
import cl from 'classnames';

import type { RadioProps as AntdRadioProps } from 'antd';

import './Radio.scss';

export interface RadioProps extends AntdRadioProps {}

interface RadioInterface {
  (props: RadioProps): React.ReactElement;
  Group: typeof AntdRadio.Group;
  Button: typeof AntdRadio.Button;
}

const Radio: RadioInterface = (props) => {
  const { className, ...rest } = props;

  const radioClasses = cl(className, 'custom-radio');

  return <AntdRadio className={radioClasses} {...rest} />;
};

Radio.Button = AntdRadio.Button;
Radio.Group = AntdRadio.Group;

export { Radio };
