import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { CommonForm } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { basePathChildren } from '@routes/paths';
import { Select } from '@shared/UI';
import { getPaIPropertyTypes } from '@store/actions/properties/PaI/Property';
import {
  selectPaIPropertyStatus,
  selectPaIPropertyTypesList,
} from '@store/selectors/properties/PaI/Property';

import { CreatePropertyList } from '../CreatePropertyList';

import type { CreatePaIPropertyDto } from '@model/properties/PaI/Property';

const { Item, useFormInstance } = CommonForm;

interface PartAndInventoryPropertyFieldsProps {
  initialPartAndInventoryPropertyTypeId?: number;
}

/**
 *
 *  PartAndInventoryPropertyFields **should only be used** in a CommonForm or BaseForm or Form from Antd
 *
 * @param {typeof PartAndInventoryPropertyFieldsProps} props {
 *  initialNonCurrAssetTypeId?: number,
 * }
 *
 *
 */
const PartAndInventoryPropertyFields = (props: PartAndInventoryPropertyFieldsProps) => {
  const { initialPartAndInventoryPropertyTypeId } = props;

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const form = useFormInstance<CreatePaIPropertyDto>();
  const statusPartAndInventoryProperty = useAppSelector(selectPaIPropertyStatus);
  const listPartAndInventoryPropertyType = useAppSelector(selectPaIPropertyTypesList);

  const [removedIds, setRemovedIds] = useState<number[]>([]);

  const [assetTypeValue, setAssetTypeValue] = useState<string | null>(() => {
    if (initialPartAndInventoryPropertyTypeId === 1) {
      return 'List';
    }
    return null;
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listPartAndInventoryPropertyType.length) {
      dispatch(getPaIPropertyTypes());
    }
  }, []);

  /* ----------------------- Handle Type Property Select ---------------------- */
  const onChangeTypeProperty = (
    value: number,
    option:
      | typeof listPartAndInventoryPropertyType
      | typeof listPartAndInventoryPropertyType[number]
  ) => {
    if (!Array.isArray(option)) {
      setAssetTypeValue(option.name);
    }
  };

  /* ----------------------------- List Options ----------------------------- */
  /**
   *  If we located on edit page
   *  then we add nonCurrAssetCardPropertyId
   *  and if we have removed field we add isRemove option
   */
  const isEdit = pathname.includes(basePathChildren.edit);

  const editProperty = (valuesForm: CreatePaIPropertyDto, name: number) => {
    form.setFieldsValue({
      ...valuesForm,
      partsAndInventoriesCardPropertyValues: valuesForm.partsAndInventoriesCardPropertyValues.map(
        (value, i) => (i === name ? { ...value, isRemove: true } : value)
      ),
    });
  };

  return (
    <>
      <Item
        label={t('titles.Property_Type')}
        name="partsAndInventoriesPropertyTypeId"
        rules={[{ required: true }]}
      >
        <Select
          allowClear={false}
          options={listPartAndInventoryPropertyType}
          placeholder={t('titles.Choose_Name', { name: t('titles.Property_Type') })}
          fieldNames={{
            value: 'partsAndInventoriesPropertyTypeId',
            label: 'name',
          }}
          loading={statusPartAndInventoryProperty === 'pending'}
          onChange={onChangeTypeProperty}
        />
      </Item>
      <CreatePropertyList
        form={form}
        isEdit={isEdit}
        removedIds={removedIds}
        nameList="partsAndInventoriesCardPropertyValues"
        valueIdName="partsAndInventoriesPropertyValueId"
        editFormFunc={editProperty}
        setRemovedIds={setRemovedIds}
        typeValue={assetTypeValue}
      />
    </>
  );
};

export { PartAndInventoryPropertyFields };
