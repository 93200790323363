import { createSlice } from '@reduxjs/toolkit';
import {
  addPaICategory,
  getPaICategories,
  getPaICategoryById,
  removePaICategoryById,
  updatePaICategoryParentRelations,
  updateParICategory,
} from '@store/actions/properties/PaI/Category';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { PaICategoryModel } from '@model/properties/PaI/Category';

interface PaICategoryState {
  PaICategoriesList: PaICategoryModel[];
  PaICategoryStatus: LoadingStatus;
  PaICategory: null | PaICategoryModel;
  PaICategoryError: null | string;
}

const initialState: PaICategoryState = {
  PaICategoriesList: [],
  PaICategoryStatus: 'idle',
  PaICategory: null,
  PaICategoryError: null,
};

const PaICategorySlice = createSlice({
  name: 'PaICategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaICategories.pending, (state) => {
        state.PaICategoryError = null;
        state.PaICategoryStatus = 'pending';
      })
      .addCase(getPaICategories.fulfilled, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        state.PaICategoriesList = payload;
      })
      .addCase(getPaICategories.rejected, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        if (payload) {
          state.PaICategoryError = payload;
        }
      });

    builder
      .addCase(addPaICategory.pending, (state) => {
        state.PaICategoryStatus = 'pending';
        state.PaICategoryError = null;
      })
      .addCase(addPaICategory.fulfilled, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        state.PaICategoriesList = payload.categories;
      })
      .addCase(addPaICategory.rejected, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        if (payload) {
          state.PaICategoryError = payload;
        }
      });

    builder
      .addCase(getPaICategoryById.pending, (state) => {
        state.PaICategoryStatus = 'pending';
        state.PaICategoryError = null;
      })
      .addCase(getPaICategoryById.fulfilled, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        state.PaICategory = payload;
      })
      .addCase(getPaICategoryById.rejected, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        if (payload) {
          state.PaICategoryError = payload;
        }
      });

    builder
      .addCase(updateParICategory.pending, (state) => {
        state.PaICategoryStatus = 'pending';
        state.PaICategoryError = null;
      })
      .addCase(updateParICategory.fulfilled, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        state.PaICategoriesList = payload.categories;
        state.PaICategory = payload.particularCategory;
      })
      .addCase(updateParICategory.rejected, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        if (payload) {
          state.PaICategoryError = payload;
        }
      });

    builder
      .addCase(removePaICategoryById.pending, (state) => {
        state.PaICategoryStatus = 'pending';
        state.PaICategoryError = null;
      })
      .addCase(removePaICategoryById.fulfilled, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        state.PaICategoriesList = payload;
      })
      .addCase(removePaICategoryById.rejected, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        if (payload) {
          state.PaICategoryError = payload;
        }
      });

    builder
      .addCase(updatePaICategoryParentRelations.pending, (state) => {
        state.PaICategoryError = null;
        state.PaICategoryStatus = 'pending';
      })
      .addCase(updatePaICategoryParentRelations.fulfilled, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        state.PaICategoriesList = payload;
      })
      .addCase(updatePaICategoryParentRelations.rejected, (state, { payload }) => {
        state.PaICategoryStatus = 'idle';
        if (payload) {
          state.PaICategoryError = payload;
        }
      });
  },
});

const PaICategoryReducer = PaICategorySlice.reducer;

export { PaICategoryReducer };
