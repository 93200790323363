import React from 'react';

const AttributeIcon = () => (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 0.5C8.5 0.223858 8.72386 0 9 0H18C18.2761 0 18.5 0.223858 18.5 0.5V1.5C18.5 1.77614 18.2761 2 18 2H9C8.72386 2 8.5 1.77614 8.5 1.5V0.5ZM8.5 4.5C8.5 4.22386 8.72386 4 9 4H14C14.2761 4 14.5 4.22386 14.5 4.5V5.5C14.5 5.77614 14.2761 6 14 6H9C8.72386 6 8.5 5.77614 8.5 5.5V4.5ZM8.5 10.5C8.5 10.2239 8.72386 10 9 10H18C18.2761 10 18.5 10.2239 18.5 10.5V11.5C18.5 11.7761 18.2761 12 18 12H9C8.72386 12 8.5 11.7761 8.5 11.5V10.5ZM8.5 14.5C8.5 14.2239 8.72386 14 9 14H14C14.2761 14 14.5 14.2239 14.5 14.5V15.5C14.5 15.7761 14.2761 16 14 16H9C8.72386 16 8.5 15.7761 8.5 15.5V14.5ZM0.5 0.5C0.5 0.223858 0.723858 0 1 0H6C6.27614 0 6.5 0.223858 6.5 0.5V5.5C6.5 5.77614 6.27614 6 6 6H1C0.723858 6 0.5 5.77614 0.5 5.5V0.5ZM3 2C2.72386 2 2.5 2.22386 2.5 2.5V3.5C2.5 3.77614 2.72386 4 3 4H4C4.27614 4 4.5 3.77614 4.5 3.5V2.5C4.5 2.22386 4.27614 2 4 2H3ZM0.5 10.5C0.5 10.2239 0.723858 10 1 10H6C6.27614 10 6.5 10.2239 6.5 10.5V15.5C6.5 15.7761 6.27614 16 6 16H1C0.723858 16 0.5 15.7761 0.5 15.5V10.5ZM3 12C2.72386 12 2.5 12.2239 2.5 12.5V13.5C2.5 13.7761 2.72386 14 3 14H4C4.27614 14 4.5 13.7761 4.5 13.5V12.5C4.5 12.2239 4.27614 12 4 12H3Z"
      fill="white"
    />
  </svg>
);

export { AttributeIcon };
