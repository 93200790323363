import React, { memo } from 'react';

import cl from 'classnames';

import { ToolbarItems } from './ToolbarItems';

import type { ToolbarItemsProps } from './ToolbarItems';

import './Toolbar.scss';

export interface ToolbarProps extends ToolbarItemsProps {
  className?: string;
}

export const Toolbar = memo((props: ToolbarProps) => {
  const { className, items } = props;

  const classes = cl('toolbar', className);

  return (
    <div className={classes}>
      <ToolbarItems items={items} />
    </div>
  );
});
