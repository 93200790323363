import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PreviewFormTitle } from '@components/views/Previews/users';
import { useOutletTitle } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { removeTitleById } from '@store/actions/user/Title';

function PreviewTitle() {
  const { entity } = useOutletTitle();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = () => {
    dispatch(removeTitleById([entity.userTitleId]))
      .unwrap()
      .then(() => {
        navigate(paths.title.default);
      });
  };

  return <PreviewFormTitle entity={entity} onDelete={onDelete} />;
}

export default PreviewTitle;
