import React from 'react';

import { Tooltip } from '@shared/UI';
import { Typography } from 'antd';

import { useCheckStepContext } from '../_utils';

import styles from './StepCount.module.scss';

export const StepCount = () => {
  const { step } = useCheckStepContext();

  const isEllipsisCount = step.rowId.toString().length >= 3;

  const count = (
    <Typography.Text className={styles.count} ellipsis={isEllipsisCount}>
      {step.rowId}.
    </Typography.Text>
  );

  const countWithTooltip = <Tooltip title={step.rowId}>{count}</Tooltip>;

  return <>{!isEllipsisCount ? count : countWithTooltip}</>;
};
