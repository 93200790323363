import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useSetTabs } from '@hooks';
import { paths } from '@routes/paths';

function EntryProfile() {
  const { t } = useTranslation();

  const withTranslations = Object.values(paths.profile.tabs).map((value) => ({
    ...value,
    nameTab: t(value.keyTranslations),
  }));
  useSetTabs(Object.values(withTranslations));

  return <Outlet />;
}

export default EntryProfile;
