import { createSelector } from '@reduxjs/toolkit';
import { liftChildToTop } from '@shared/utils/functions';

import type { RootState } from '@store/rootReducer';

export const selectUserState = (state: RootState) => state.UserReducer;

export const selectUsersList = (state: RootState) => selectUserState(state).usersList;

export const selectUsersFilteredList = (state: RootState) =>selectUserState(state).usersFilteredList;

export const selectUser = (state: RootState) => selectUserState(state).user;

export const selectUserStatus = (state: RootState) => selectUserState(state).userStatus;

export const selectUserError = (state: RootState) => selectUserState(state).userError;

export const selectUsersListWithoutChildren = createSelector(
  selectUsersList,
  (usersList) => {
    return liftChildToTop(usersList, 'childUser');
  }
);

export const selectUserFilters = (state: RootState) =>
  selectUserState(state).userFilters;
