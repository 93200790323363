import React from 'react';
import { useDragLayer } from 'react-dnd';

import type { XYCoord } from 'react-dnd';

import './DraggableLayerRow.scss';

interface DraggableLayerRowProps {
  selectedRecordKeys: React.Key[];
}

function getTransform(currentOffset: XYCoord | null) {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y - 20}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const DraggableLayerRow = (props: DraggableLayerRowProps) => {
  const { selectedRecordKeys } = props;
  const { isDragging, currentOffset } = useDragLayer((monitor) => {
    return {
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getClientOffset(),
    };
  });

  if (!isDragging) {
    return null;
  }

  return (
    <div
      className="drag-layer-row"
      style={{
        ...getTransform(currentOffset),
      }}
    >
      <span>{selectedRecordKeys.length}</span>
    </div>
  );
};

export default DraggableLayerRow;
