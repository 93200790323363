import React from 'react';
import { useTranslation } from 'react-i18next';

import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Input } from '@shared/UI';

import { CommonForm } from '../CommonForm';

import type { FormInstance } from 'antd';

import './CreatePropertyList.scss';

const { Item, List } = CommonForm;

interface CreatePropertyListProps<FormType> {
  typeValue: string | null;
  nameList: string;
  isEdit: boolean;
  removedIds: number[];
  setRemovedIds: (removedIds: number[]) => void;
  form: FormInstance<FormType>;
  editFormFunc: (values: FormType, name: number) => void;
  valueIdName?: string;
}

const CreatePropertyList = <FormType,>(props: CreatePropertyListProps<FormType>) => {
  const {
    typeValue,
    nameList,
    isEdit,
    removedIds,
    setRemovedIds,
    form,
    editFormFunc,
    valueIdName,
  } = props;

  const { t } = useTranslation();

  return (
    <List name={nameList}>
      {(fields, { add, remove }) => {
        if (typeValue === 'List') {
          return (
            <div className="property-list__list-items">
              {fields.map(({ key, name, ...restFields }) => {
                const checkRemoved = removedIds.includes(name);

                if (checkRemoved && isEdit) return null;
                return (
                  <div key={key} className="property-list__list-wrapper-item">
                    <Item
                      {...restFields}
                      name={[name, 'value']}
                      rules={[
                        {
                          required: true,
                          message: t('errors.Empty_Field_Cant_Send'),
                        },
                      ]}
                      className="property-list__list-item"
                    >
                      <Input autoComplete="off" />
                    </Item>
                    {isEdit ? (
                      <Item
                        {...restFields}
                        name={[name, valueIdName || 'nonCurrAssetPropertyValueId']}
                        hidden
                      >
                        <Input />
                      </Item>
                    ) : null}
                    <MinusCircleOutlined
                      onClick={() => {
                        const valuesForm = form.getFieldsValue();

                        if (isEdit) {
                          editFormFunc(valuesForm, name);
                          setRemovedIds([...removedIds, name]);
                        } else {
                          remove(name);
                        }
                      }}
                    />
                  </div>
                );
              })}
              <Button className="property-list__button" onClick={() => add()} type="text">
                {t('titles.Add_New_Field')}
              </Button>
            </div>
          );
        }
      }}
    </List>
  );
};

export { CreatePropertyList };
