import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormCompany } from '@components/views/Forms/users';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addCompany } from '@store/actions/user/Company';

import type { CreateCompanyDto } from '@model/users/Company';
import type { FormInstance } from 'antd';

const CreateCompany = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (dto: CreateCompanyDto, form?: FormInstance<CreateCompanyDto>) => {
    dispatch(addCompany(dto))
      .unwrap()
      .then((payload) => {
        ApiNotifications.create(payload.name);
        navigate(paths.companies.default);
        form?.resetFields();
      })
      .catch((error) => {
        ApiNotifications.error(error);
      });
  };

  return (
    <FormCompany
      onFinish={onFinish}
      header={{
        title: t('titles.New_Item', { name: t('pages_single.Company') }),
      }}
    />
  );
};

export default CreateCompany;
