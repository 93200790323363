import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { notificationController } from '@services/Notifications';
import { readFile } from '@shared/utils/functions';

import type { ChangeEvent, MutableRefObject} from 'react';

export type UseUploadFileProps = {
  cb: (uploadedFiles: File[], uploadedFilesBase64: string[]) => Array<File> | void;
  maxSizeFile?: number;
  maxPiecesFiles?: number;
  currentFilesLength?: number;

  showErrorNotification?: boolean;
};

export type UseUploadFileReturn = {
  loading: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  files: File[];
};

const checkFileSize = (maxSize: number, fileSize: number) =>
  maxSize < +(fileSize / 1024 / 1024).toFixed(2);

function useUploadFile(props: UseUploadFileProps): UseUploadFileReturn {
  const {
    cb,
    maxSizeFile = 5,
    maxPiecesFiles = 5,
    currentFilesLength,
    showErrorNotification = true,
  } = props;
  const [loading, setLoading] = useState(false);
  const [stateFiles, setStateFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const onChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      const { files } = event.target;
      if (!files || !files.length) return;

      const picesCondition =
        maxPiecesFiles < files.length ||
        (currentFilesLength && currentFilesLength + files.length > maxPiecesFiles);

      if (picesCondition) {
        notificationController.error({
          description: `files max length is ${maxPiecesFiles}`,
        });

        return;
      }

      const filteredFiles = Array.from(files, (file) => {
        if (checkFileSize(maxSizeFile, file.size) && showErrorNotification) {
          notificationController.error({
            description: t('errors.File_Size_Large_Than', {
              name: file.name,
              size: maxSizeFile,
            }),
          });
        }
        return file;
      }).filter(
        (file, index) =>
          !checkFileSize(maxSizeFile, file.size) && index + 1 <= maxPiecesFiles && file
      );

      const blobFiles = await Promise.all(
        filteredFiles.map(async (file) => {
          const fileContent = await readFile(file, inputRef);
          return fileContent;
        })
      );
      const result = cb(filteredFiles, blobFiles);
      if (Array.isArray(result)) {
        setStateFiles(result);
      } else {
        setStateFiles(filteredFiles);
      }
      setLoading(false);
    },
    [cb, loading, maxSizeFile, maxPiecesFiles, inputRef, currentFilesLength]
  );

  return {
    loading,
    inputRef,
    onChange,
    files: stateFiles,
  };
}

export { useUploadFile };
