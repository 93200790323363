import React from 'react';
import { useTranslation } from 'react-i18next';

import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts';

import { NoResults } from '../../NoResults';

import './PiePAI.scss';

interface PiePAIProps {
  data: {
    totalQuantity: number;
    totalRemainingQuantity: number;
  }[] | undefined
}

const PiePAI = (props: PiePAIProps) => {
  const { data } = props
  const {t} = useTranslation();

  const circleSize = 220;

  const pieData = data?.map(obj => ({...obj, fill: '#00458A'}));  
  
  return (
    <div className="pie-pai-chart">
      <div className="pie-pai-chart__header">
        <h6>{t('titles.Remaining_PAI')}</h6>
      </div>
      <div className="pie-pai-chart__content">
      {((data && (!data[0].totalQuantity && !data[0].totalRemainingQuantity)) || !data || !data.length) ?
        <NoResults />
        :
        <>
        <div className="pie-pai-chart__info">
          <div className="pie-pai-chart__info-item">
            <span className="item-title">{t('titles.Total_Purchased_Quantity')}</span>
            <span className="item-value ">{data && data[0].totalQuantity}</span>
          </div>
          <div className="pie-pai-chart__info-item">
            <span className="item-title">{t('titles.Remaining_Quantity')}</span>
            <span className="item-value value-hold">{data && data[0].totalRemainingQuantity}</span>
          </div>
        </div>
        <div className="pie-pai-chart__chart">
          <RadialBarChart
            width={circleSize}
            height={circleSize}
            cx={circleSize / 2}
            cy={circleSize / 2}
            innerRadius={110}
            outerRadius={80}
            barSize={20}
            data={pieData}
            startAngle={40}
            endAngle={-320}
          >
            <PolarAngleAxis type="number" domain={[0, data[0].totalQuantity || 0]} angleAxisId={0} tick={false}/>
            <RadialBar
              background={{fill: '#AEB1F9'}}
              dataKey="totalRemainingQuantity"
              cornerRadius={circleSize / 2}
            />
            <text
              style={{
                fontSize: '3rem',
                fontWeight: '500',
              }}
              fill="#00458A"
              x={circleSize / 2}
              y={circleSize / 2}
              textAnchor="middle"
              dominantBaseline="middle"
            >
              {Math.round((data[0].totalRemainingQuantity / data[0].totalQuantity) * 100) || 0}%
            </text>
          </RadialBarChart>
        </div>
        </>
        }
      </div>
    </div>
  );
};

export {PiePAI};
