import type { ActionFunction } from '../../types';

export const onChangeTitle: ActionFunction<{ uuid: string; value: string }> = (
  { uuid, value },
  { setState }
) => {
  setState((prevState) =>
    prevState.map((step) =>
      step.uuid === uuid
        ? {
            ...step,
            title: value,
            isUpdate: true,
            hasError: false,
          }
        : step
    )
  );
};
