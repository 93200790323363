import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PreviewFormCompany } from '@components/views/Previews/users';
import { deleteNotificationSuccess } from '@constants/notificationMessages';
import { useOutletCompany } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { removeCompanyById } from '@store/actions/user/Company';

function PreviewCompany() {
  const { entity } = useOutletCompany();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = () => {
    dispatch(removeCompanyById([entity.companyId])).then(() => {
      notificationController.success({
        description: deleteNotificationSuccess(entity.name),
      });
      navigate(paths.companies.default);
    });
  };

  return <PreviewFormCompany entity={entity} onDelete={onDelete} />;
}

export default PreviewCompany;
