import React from 'react';

import { PageHeader as AntdPageHeader } from 'antd';
import cl from 'classnames';

import type { PageHeaderProps as AntdPageHeaderProps } from 'antd';

import './PageHeader.scss';

export type PageHeaderProps = {
  className?: string | string[];
} & Omit<AntdPageHeaderProps, 'className'>;

const PageHeader = (props: PageHeaderProps) => {
  const { className, ...rest } = props;

  return <AntdPageHeader {...rest} className={cl('custom-page-header', className)} />;
};

export default PageHeader;
