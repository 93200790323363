import { api } from '@services/Axios';

import type {
  CheckOutModel,
  CreateCheckOutDto,
  UpdateCheckOutDto,
} from '@model/properties/CheckOut';
import type { ApiResponse } from '@services/Axios';

const ChOusEndpoint = '/CheckFactors';

export const CheckOutRepository = {
  async fetchAll() {
    const response: ApiResponse<CheckOutModel[]> = await api.get(
      `${ChOusEndpoint}/GetCheckFactorsList`
    );

    return response.data;
  },

  async add(dto: CreateCheckOutDto) {
    const response: ApiResponse<CheckOutModel> = await api.post(
      `${ChOusEndpoint}/AddCheckFactors`,
      dto
    );

    return response.data;
  },

  async fetchById(id: string) {
    const response: ApiResponse<CheckOutModel> = await api.get(
      `${ChOusEndpoint}/GetSuspendedById/${id}`
    );

    return response.data;
  },

  async update(dto: UpdateCheckOutDto) {
    const response: ApiResponse<CheckOutModel> = await api.post(
      `${ChOusEndpoint}/UpdateCheckFactors`,
      dto
    );

    return response.data;
  },

  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${ChOusEndpoint}/RemoveByIdList`, {
      exitTypeIds: ids,
    });

    return response.data;
  },
};
