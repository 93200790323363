import React from 'react';

import { Tabs as AntdTabs } from 'antd';
import cl from 'classnames';

import type { TabsProps as AntdTabsProps } from 'antd';

import './Tabs.scss';

const { TabPane } = AntdTabs;

export interface TabsProps extends Omit<AntdTabsProps, 'className'> {
  className?: string | string[];
}

export interface TabsInerface {
  (props: TabsProps): React.ReactElement<TabsProps>;
  TabPane: typeof TabPane;
}

const Tabs: TabsInerface = (props: TabsProps) => {
  const { className, ...rest } = props;

  const tabsClass = cl('custom-tabs', className);

  return <AntdTabs {...rest} className={tabsClass} />;
};

Tabs.TabPane = TabPane;

export { Tabs };
