import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getUserGroupById } from '@store/actions/user/UserGroup';
import { selectUserGroup } from '@store/selectors/users/UserGroup';

import type { IOutletUserGroup } from '@constants/OutletContextEntityPages';
import type { CreateUserGroupDto } from '@model/users/UserGroup';

function UserGroup() {
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();
  const group = useAppSelector(selectUserGroup);

  const condition = !group || group.groupsId !== +decryptedId;

  useSetPageInfo({
    pageTitle: 'pages_single.User_Group',
    defaultPagePath: paths.usergroups.default,
  });

  useEffect(() => {
    if (condition) {
      dispatch(getUserGroupById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateUserGroupDto = {
    groupCode: group.groupCode,
    groupName: group.groupName,
  };

  return <Outlet context={{ initialValues, entity: group } as IOutletUserGroup} />;
}

export default UserGroup;
