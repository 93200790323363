import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormAssetName } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletAssetName } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateAsAtCard } from '@store/actions/properties/AsAt/Card';

import type { CreateAsAtCardDto } from '@model/properties/AsAt/Card';

function EditAssetName() {
  const { initialValues, entity } = useOutletAssetName();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { assetAttrAccess } = useGetAccess();

  const onFinish = (dto: CreateAsAtCardDto) => {
    dispatch(
      updateAsAtCard({
        ...dto,
        nonCurrAssetCardId: entity.nonCurrAssetCardId,
      })
    )
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.AsAtCard.name);
        navigate(paths.assetAttribute.tabs.name.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <CheckAccess hasAccess={assetAttrAccess.edit} redirectTo={paths.assetAttribute.tabs.name.default}>
      <FormAssetName
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditAssetName;
