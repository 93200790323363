import { api } from '@services/Axios';

import type {
  AsAtPropertyModel,
  AsAtPropertyTypeModel,
  CreateAsAtPropertyDto,
  UpdateAsAtPropertyDto,
} from '@model/properties/AsAt/Property';
import type { ApiResponse } from '@services/Axios';

const AsAtPropertyEndpoint = '/NonCurrAssetCardProperties';
const AssetPropertyTypeUrl = '/NonCurrAssetPropertyTypes';

export const AsAtPropertyRepository = {
  async fetchAll() {
    const response: ApiResponse<AsAtPropertyModel[]> = await api.get(
      `${AsAtPropertyEndpoint}/GetNonCurrAssetCardPropertiesList`
    );

    return response.data;
  },

  async fetchAsAtPropertyTypes() {
    const response: ApiResponse<AsAtPropertyTypeModel[]> = await api.get(
      `${AssetPropertyTypeUrl}/GetNonCurrAssetPropertyTypesList`
    );

    return response.data;
  },

  async add(dto: CreateAsAtPropertyDto) {
    const response: ApiResponse<AsAtPropertyModel> = await api.post(
      `${AsAtPropertyEndpoint}/AddNonCurrAssetCardProperty`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<AsAtPropertyModel> = await api.get(
      `${AsAtPropertyEndpoint}/GetNonCurrAssetCardPropertyById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdateAsAtPropertyDto) {
    const response: ApiResponse<AsAtPropertyModel> = await api.post(
      `${AsAtPropertyEndpoint}/UpdateNonCurrAssetCardProperties`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${AsAtPropertyEndpoint}/RemoveByIdList`, {
      nonCurrAssetCardPropertyIds: ids,
    });

    return response.data;
  },
};
