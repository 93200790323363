import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { Select } from '@shared/UI';
import { liftChildToTop } from '@shared/utils/functions';

import type { CreateMaintenanceGeneralV2Dto } from '@model/management/Maintenance';
import type { UserModel } from '@model/users/User';

interface AssignBlockProps {
  usersList: Array<UserModel>;
}

const { Item, useFormInstance, useWatch } = CommonForm;

const getAssignToOptions = (list: Array<UserModel>) => {
  const usersList = liftChildToTop(list, 'childUser');

  const uniqueUsers = Array.from(
    new Map(usersList.map((user) => [user.userId, user])),
    ([, value]) => value
  );

  return uniqueUsers.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.userId,
  }));
};

const getAdditionalAssignToOptions = (list: Array<UserModel>, assignUserId: number | null) => {
  const assignOptions = getAssignToOptions(list);

  return assignOptions.map((assignOption) => {
    if (assignUserId && assignUserId === assignOption.value) {
      return {
        ...assignOption,
        disabled: true,
      };
    }

    return assignOption;
  });
};

const AssignBlock = (props: AssignBlockProps) => {
  const { usersList } = props;

  const { t } = useTranslation();

  const form = useFormInstance<CreateMaintenanceGeneralV2Dto>();

  // region --- Observed values

  const observedAssignId = useWatch('assignToId', form);

  // endregion --- Observed values

  // region --- Select options

  const assignToOptions = useMemo(() => getAssignToOptions(usersList), [usersList]);

  const additionalAssignToOptions = useMemo(
    () => getAdditionalAssignToOptions(usersList, observedAssignId),
    [usersList, observedAssignId]
  );

  // endregion --- Select options

  useEffect(() => {
    const { additionalAssignToIds } = form.getFieldsValue();

    if (additionalAssignToIds && observedAssignId) {
      const isExistId = additionalAssignToIds.includes(observedAssignId);

      if (isExistId) {
        const updatedAdditionalValue = additionalAssignToIds.filter(
          (id) => id !== observedAssignId
        );

        form.setFieldValue('additionalAssignToIds', updatedAdditionalValue);
      }
    }
  }, [observedAssignId]);

  return (
    <>
      <Item name="assignToId" label={t('titles.Assign_To')}>
        <Select placeholder={t('titles.Assign_To')} options={assignToOptions} />
      </Item>

      <Item name="additionalAssignToIds" label={t('titles.Additional_Assign_To')}>
        <Select
          mode="multiple"
          showArrow
          simpleMultipleTag
          maxTagCount="responsive"
          placeholder={t('titles.Choose_Name', { name: t('titles.Additional_Assign_To') })}
          options={additionalAssignToOptions}
        />
      </Item>
    </>
  );
};

export { AssignBlock };
