import React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import { getMaAtChecklists } from '@store/actions/properties/MaAt/Checklist';
import {
  selectMaAtChecklistOptions,
  selectMaAtChecklistSettings,
  selectMaAtChecklistsList,
  selectMaAtChecklistStatus,
} from '@store/selectors/properties/MaAt/Checklist';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { MaAtChecklistModel } from '@model/properties/MaAt/Checklist';

const columns: ColumnsTableType<MaAtChecklistModel> = [
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'checklistName',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.checklistName, b.checklistName),
  },
  {
    title: <Translation>{(t) => t('titles.Description')}</Translation>,
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    sorter: (a, b) => compare(a.description, b.description),
  },
];

const MaintenanceChecklists = () => {
  const { t } = useTranslation();

  const maintenanceChecklistList = useAppSelector(selectMaAtChecklistsList);
  const maintenanceChecklistStatus = useAppSelector(selectMaAtChecklistStatus);
  const maintenanceChecklistFilter = useAppSelector(selectMaAtChecklistOptions);
  const maintenanceChecklistSettings = useAppSelector(selectMaAtChecklistSettings);

  const dispatch = useDispatch();

  const { maintenanceAttrAccess } = useGetAccess();

  const onChangePage = (page: number) => {
    dispatch(getMaAtChecklists({ page: page }));
  };

  return (
    <BasicTablePage
      table={{
        loading: maintenanceChecklistStatus === 'pending',
        dataSource: maintenanceChecklistList,
        columns: columns,
        rowKey: (record) => record.checkListId,
        dataSourceName: t('pages_plural.Maintenance_Attr_Checklist'),
        pagination: {
          current: maintenanceChecklistFilter.page,
          total: maintenanceChecklistSettings.totalRecords,
          onChange: onChangePage,
        },
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', {
          name: t('pages_single.Maintenance_Attr_Checklist'),
        }),
        csvConfig: {
          data: maintenanceChecklistList,
          fileName: 'maintenance-checklists.csv',
          headers: [
            {
              label: 'Name',
              key: 'checklistName',
            },
            {
              label: 'Description',
              key: 'description',
            },
          ],
        },
        newButtonProps: {
          disabled: !maintenanceAttrAccess.create,
        },
      }}
    />
  );
};

export { MaintenanceChecklists };
