import React, { useCallback, useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppDispatch, useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import { updateAsAtCategoryParentRelations } from '@store/actions/properties/AsAt/Category';
import {
  selectAsAtCategoriesList,
  selectAsAtCategoriesListWithoutChildren,
  selectAsAtCategoryStatus,
} from '@store/selectors/properties/AsAt/Category';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { DragEnd } from '@components/common/BasicTablePage/BasicTablePage';
import type { AsAtCategoryModel } from '@model/properties/AsAt/Category';

const columns: ColumnsTableType<AsAtCategoryModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'nonCurrAssetCardCategoryCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.nonCurrAssetCardCategoryCode, b.nonCurrAssetCardCategoryCode),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
];

const AssetCategories = () => {
  const { t } = useTranslation();

  const { assetAttrAccess } = useGetAccess();

  const assetCategoryList = useAppSelector(selectAsAtCategoriesList);
  const assetCategoryStatus = useAppSelector(selectAsAtCategoryStatus);
  const listAssetCategoriesWithoutChildren = useAppSelector(
    selectAsAtCategoriesListWithoutChildren
  );
  const dispatch = useAppDispatch();

  const data = useCallback(
    (assetCategories: AsAtCategoryModel[]) => {
      return assetCategories.map(
        ({ childNonCurrAssetCardCategories, ...assetCategory }): AsAtCategoryModel => ({
          ...assetCategory,
          childNonCurrAssetCardCategories: childNonCurrAssetCardCategories?.length
            ? data(childNonCurrAssetCardCategories)
            : null,
        })
      );
    },
    [assetCategoryList]
  );

  const dataSource = useMemo(() => data(assetCategoryList), [assetCategoryList]);

  const updateParent = useCallback(
    (data: DragEnd<AsAtCategoryModel>) => {
      dispatch(
        updateAsAtCategoryParentRelations({
          fromNonCurrAssetCardCategoriesIds: [data.draggingRecordId],
          toNonCurrAssetCardCategoryId: data.dropId,
        })
      );
    },
    [assetCategoryList]
  );

  const csvData = useMemo(
    () =>
      listAssetCategoriesWithoutChildren.map((categories) => {
        const parentName = listAssetCategoriesWithoutChildren.find(
          (c) => c.nonCurrAssetCardCategoryId === categories.parentNonCurrAssetCardCategoryId
        )?.name;
        return {
          ...categories,
          parentName,
        };
      }),
    [listAssetCategoriesWithoutChildren]
  );

  return (
    <BasicTablePage
      table={{
        loading: assetCategoryStatus === 'pending',
        dataSource,
        columns: columns,
        rowKey: (record) => record.nonCurrAssetCardCategoryId,
        dataSourceName: t('pages_plural.Asset_Attr_Categories'),
        draggable: true,
        dropAction: updateParent,
        expandable: {
          childrenColumnName: 'childNonCurrAssetCardCategories',
        },
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Asset_Attr_Category') }),
        csvConfig: {
          data: csvData,
          fileName: 'asset-categories.csv',
          headers: [
            {
              label: 'Code',
              key: 'nonCurrAssetCardCategoryCode',
            },
            {
              label: 'Name',
              key: 'name',
            },
            {
              label: 'Parent',
              key: 'parentName',
            },
          ],
        },
        newButtonProps: {
          disabled: !assetAttrAccess.create,
        },
      }}
    />
  );
};

export { AssetCategories };
