import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@hooks';
import { basePathChildren } from '@routes/paths';
import { Select } from '@shared/UI';
import { getAsAtPropertyTypes } from '@store/actions/properties/AsAt/Property';
import {
  selectAsAtPropertyStatus,
  selectAsAtPropertyTypesList,
} from '@store/selectors/properties/AsAt/Property';

import { CommonForm } from '../CommonForm';
import { CreatePropertyList } from '../CreatePropertyList';

import type { CreateAsAtPropertyDto } from '@model/properties/AsAt/Property';
import type { Rule } from 'antd/lib/form';
import type { NamePath } from 'antd/lib/form/interface';

interface AssetPropertyFieldsProps {
  initialNonCurrAssetTypeId?: number;
  customPropertyType?: {
    label?: string;
    name?: NamePath;
    rules?: Rule[];
  };
}

const { useFormInstance, Item } = CommonForm;

/**
 *
 *  AssetPropertyFields **should only be used** in a CommonForm or BaseForm or Form from Antd
 *
 * @param {typeof AssetPropertyFields} props {
 *  initialNonCurrAssetTypeId?: number,
 * }
 *
 *
 */
const AssetPropertyFields = (props: AssetPropertyFieldsProps) => {
  const { initialNonCurrAssetTypeId, customPropertyType } = props;

  const { t } = useTranslation();

  const form = useFormInstance<CreateAsAtPropertyDto>();
  const assetPropertyStatus = useAppSelector(selectAsAtPropertyStatus);
  const assetAssetPropertyTypeList = useAppSelector(selectAsAtPropertyTypesList);
  const { pathname } = useLocation();

  const [removedIds, setRemovedIds] = useState<number[]>([]);

  const [assetTypeValue, setAssetTypeValue] = useState<string | null>(() => {
    if (initialNonCurrAssetTypeId === 1) {
      return 'List';
    }
    return null;
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!assetAssetPropertyTypeList.length) {
      dispatch(getAsAtPropertyTypes());
    }
  }, []);

  /* ----------------------- Handle Type Property Select ---------------------- */
  const onChangeTypeProperty = (
    value: number,
    option: typeof assetAssetPropertyTypeList | typeof assetAssetPropertyTypeList[number]
  ) => {
    if (!Array.isArray(option)) {
      setAssetTypeValue(option.name);
    }
  };

  /* ----------------------------- List Options ----------------------------- */
  /**
   *  If we located on edit page
   *  then we add nonCurrAssetCardPropertyId
   *  and if we have removed field we add isRemove option
   */
  const isEdit = pathname.includes(basePathChildren.edit);

  const editProperty = (valuesForm: CreateAsAtPropertyDto, name: number) => {
    form.setFieldsValue({
      ...valuesForm,
      nonCurrAssetPropertyValues: valuesForm.nonCurrAssetPropertyValues.map((value, i) =>
        i === name ? { ...value, isRemove: true } : value
      ),
    });
  };

  return (
    <>
      <Item
        className="asset-property-fields__type-property"
        label={customPropertyType?.label ? customPropertyType?.label : t('titles.Property_Type')}
        name={customPropertyType?.name ? customPropertyType?.name : 'nonCurrAssetTypeId'}
        rules={customPropertyType?.rules ? customPropertyType?.rules : [{ required: true }]}
      >
        <Select
          allowClear={false}
          options={assetAssetPropertyTypeList}
          placeholder={t('titles.Choose_Name', { name: t('titles.Property_Type') })}
          loading={assetPropertyStatus === 'pending'}
          fieldNames={{ label: 'name', value: 'nonCurrAssetPropertyTypeId' }}
          onChange={onChangeTypeProperty}
        />
      </Item>
      <CreatePropertyList
        form={form}
        isEdit={isEdit}
        removedIds={removedIds}
        nameList="nonCurrAssetPropertyValues"
        editFormFunc={editProperty}
        setRemovedIds={setRemovedIds}
        typeValue={assetTypeValue}
      />
    </>
  );
};

export { AssetPropertyFields };
