import { api } from '@services/Axios';

import type {
  CreateUserDto,
  FilterUserDto,
  UpdateUserDto,
  UpdateUserParentDto,
  UserModel,
} from '@model/users/User';
import type { ApiResponse } from '@services/Axios';

const UsEndpoint = '/User';

export const UserRepository = {
  async fetchAll() {
    const response: ApiResponse<UserModel[]> = await api.get(`${UsEndpoint}/GetUserList`);
    return response.data;
  },
  async add(dto: CreateUserDto) {
    const response: ApiResponse<UserModel> = await api.post(`${UsEndpoint}/AddUser`, dto);
    return response.data;
  },
  async update(dto: UpdateUserDto) {
    const response: ApiResponse<UserModel> = await api.post(`${UsEndpoint}/UpdateUser`, dto);
    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<UserModel> = await api.get(`${UsEndpoint}/GetUserById/${id}`);
    return response.data;
  },
  async fetchByFilter(dto: FilterUserDto) {
    const response: ApiResponse<UserModel[]> = await api.post(
      `${UsEndpoint}/GetUserListByFilter`,
      dto
    );
    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${UsEndpoint}/RemoveByIdList`, {
      userIds: ids,
    });
    return response.data;
  },
  async updateParentRelations(dto: UpdateUserParentDto) {
    const response: ApiResponse<null> = await api.post(
      `${UsEndpoint}/ChangeUserParentReleations`,
      dto
    );
    return response.data;
  },
};
