import type { RootState } from '@store/rootReducer';

export const selectCompanyState = (state: RootState) => state.CompanyReducer;

export const selectCompaniesList = (state: RootState) => selectCompanyState(state).companiesList;

export const selectCompaniesFilteredList = (state: RootState) => 
    selectCompanyState(state).companysFilteredList;

export const selectCompaniesFilters = (state: RootState) => 
    selectCompanyState(state).companyFilters;

export const selectAllCompaniesList = (state: RootState) =>
  selectCompanyState(state).companiesAllList;

export const selectCompany = (state: RootState) => selectCompanyState(state).company;

export const selectCompanyStatus = (state: RootState) => selectCompanyState(state).companyStatus;

export const selectCompanyError = (state: RootState) => selectCompanyState(state).companyError;
