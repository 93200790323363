import React, { memo, useCallback, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { IterationEndDate } from '@components/common/IterationEndDate';
import { DaysList } from '@components/views/Forms/managment/Maintenance/GeneralV2/IterationModal/DaysList';
import { MonthTypeRadios } from '@components/views/Forms/managment/Maintenance/GeneralV2/MonthTypeRadios';
import { IterationValue } from '@constants/iterationValue';
import { useClassPrefix } from '@hooks';
import { CalendarIcon } from '@icon/icon-components';
import { BaseForm, BaseModal, DatePicker, Divider, Select, TimeMomentPicker } from '@shared/UI';
import { Col, Row } from 'antd';
import moment from 'moment';

import { handleDaysOfWeek, intervalOptions, iterationOptions } from '../_util';

import type { IterationMonthType } from '@constants/IterationMonthType';
import type { Moment } from 'moment';
import type {
  ChangeIterationStateAction,
  IterationModalErrors,
  IterationModalState,
} from '../_util';

import './IterationModal.scss';

interface IterationModalProps {
  iterationState: IterationModalState;

  isOpen: boolean;

  onClose: () => void;

  onChangeState: ChangeIterationStateAction;
}

const { Item, useForm } = BaseForm;

const isValidField = (
  state: IterationModalState,
  propertyName: keyof IterationModalState,
  name: string
): IterationModalErrors => {
  const value = state[propertyName];

  const status = value ? 'validating' : 'error';

  return {
    status,
    message:
      status === 'error' ? (
        <Translation>{(t) => t('errors.Is_Required', { name: `'${name}'` })}</Translation>
      ) : null,
  };
};

const defaultErrorsState = {
  startDate: {
    status: 'validating',
    message: null,
  },
  startTime: {
    status: 'validating',
    message: null,
  },
} as const;

export const IterationModal = memo((props: IterationModalProps) => {
  const { iterationState, isOpen, onClose, onChangeState } = props;

  const { t } = useTranslation();

  const [errors, setErrors] =
    useState<Record<'startDate' | 'startTime', IterationModalErrors>>(defaultErrorsState);

  const [form] = useForm();

  const { prefix, withPrefix } = useClassPrefix('general-iteration-modal');

  // region --- validations

  const validateFields = () => {
    const startDateValid = isValidField(iterationState, 'startDate', 'Start date');
    const startTimeValid = isValidField(iterationState, 'startTime', 'Start time');

    const statuses = [startDateValid.status, startTimeValid.status];

    setErrors((prevState) => ({
      ...prevState,
      startDate: startDateValid,
      startTime: startTimeValid,
    }));

    return !statuses.includes('error');
  };

  const clearError = (name: keyof typeof errors) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: defaultErrorsState[name],
    }));
  };

  // endregion --- validations

  // region --- Change Actions

  // Start Date
  const changeStartDate = useCallback(
    (startDate: Moment | null) => {
      if (startDate) {
        const endDate =
          startDate > iterationState.endDate
            ? moment(startDate).add(1, 'month')
            : iterationState.endDate;

        onChangeState({ startDate, endDate });
      }

      clearError('startDate');
    },
    [onChangeState, iterationState.endDate, iterationState.startDate]
  );

  // End Date
  const changeEndDate = useCallback(
    (endDate: Moment | null) => {
      if (endDate) {
        onChangeState({ endDate });
      }
    },
    [onChangeState]
  );

  // Start Time
  const changeStartTime = useCallback(
    (startTime: Moment | null) => {
      if (startTime) {
        onChangeState({ startTime });
      }

      clearError('startTime');
    },
    [onChangeState]
  );

  // Repeat Number
  const changeRepeatNumber = useCallback(
    (repeatNumber: number) => {
      onChangeState({ repeatNumber });
    },
    [onChangeState]
  );

  // Repeat Type
  const changeRepeatType = useCallback(
    (repeatType: IterationValue) => {
      onChangeState((prevValue) => {
        let daysOfWeek = prevValue.daysOfWeek;

        if (repeatType === IterationValue.EVERY_DAY) {
          daysOfWeek = handleDaysOfWeek.selectAllDaysOfWeek(prevValue.daysOfWeek);
        }
        if (repeatType === IterationValue.EVERY_WEEK) {
          daysOfWeek = handleDaysOfWeek.selectOnlyFirstDayOfWeek(prevValue.daysOfWeek);
        }
        if (repeatType === IterationValue.EVERY_MONTH) {
          daysOfWeek = handleDaysOfWeek.deselectAllDaysOfWeek(prevValue.daysOfWeek);
        }

        return {
          ...prevValue,
          daysOfWeek,
          repeatType,
        };
      });
    },
    [onChangeState]
  );

  // Days of Week
  const changeDay = useCallback(
    (checked: boolean, id: string | number) => {
      const daysOfWeek = iterationState.daysOfWeek.map((day) =>
        day.dayOfWeekIndex === id ? { ...day, checked } : day
      );
      const countOfCheckedDays = daysOfWeek.filter((day) => day.checked).length;

      if (countOfCheckedDays === 0) {
        return;
      }

      let repeatType = iterationState.repeatType;

      if (countOfCheckedDays === 7 && iterationState.repeatType === IterationValue.EVERY_WEEK) {
        repeatType = IterationValue.EVERY_DAY;
      }

      if (countOfCheckedDays < 7 && iterationState.repeatType === IterationValue.EVERY_DAY) {
        repeatType = IterationValue.EVERY_WEEK;
      }

      onChangeState({ daysOfWeek, repeatType });
    },
    [onChangeState, iterationState.daysOfWeek, iterationState.repeatType]
  );

  const changeMonthType = useCallback(
    (monthType: IterationMonthType) => {
      onChangeState({ monthType });
    },
    [onChangeState]
  );

  // endregion --- Change Actions

  const renderDaysOrRadios = () => {
    const isMonth = iterationState.repeatType === IterationValue.EVERY_MONTH;
    const isDayOrWeek =
      iterationState.repeatType === IterationValue.EVERY_WEEK ||
      iterationState.repeatType === IterationValue.EVERY_DAY;

    if (isDayOrWeek) {
      return <DaysList daysOfWeekList={iterationState.daysOfWeek} onChangeDay={changeDay} />;
    }

    if (isMonth) {
      return (
        <MonthTypeRadios
          value={iterationState.monthType}
          onChange={changeMonthType}
          startDate={iterationState.startDate}
        />
      );
    }

    return null;
  };

  const onOk = () => {
    const isValid = validateFields();

    if (isValid) {
      onClose();
    }
  };

  return (
    <BaseModal
      title={t('titles.Iteration')}
      size="small"
      closable={false}
      open={isOpen}
      onCancel={onClose}
      className={prefix}
      onOk={onOk}
    >
      <BaseForm form={form} validateTrigger={['onSubmit']}>
        <Row gutter={20}>
          <Col span={16}>
            <Item
              required
              label={t('titles.Start_Date')}
              validateStatus={errors.startDate.status}
              help={errors.startDate.message}
            >
              <DatePicker
                className={withPrefix('startDate')}
                value={iterationState.startDate}
                onChange={changeStartDate}
                suffixIcon={<CalendarIcon />}
                allowClear={false}
                fullWidth
              />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              required
              label={t('titles.Start_Time')}
              validateStatus={errors.startTime.status}
              help={errors.startTime.message}
            >
              <TimeMomentPicker
                className={withPrefix('startTime')}
                value={iterationState.startTime}
                onChange={changeStartTime}
                placeholder="00:00"
                allowClear={false}
              />
            </Item>
          </Col>
        </Row>

        <Item label="Repeat every" className={withPrefix('repeat')}>
          <Row gutter={16} align="middle">
            <Col span={12}>
              <Select
                className={withPrefix('repeat-picker')}
                value={iterationState.repeatNumber}
                onChange={changeRepeatNumber}
                options={intervalOptions}
                allowClear={false}
              />
            </Col>
            <Col span={12}>
              <Select
                className={withPrefix('repeat-picker')}
                options={iterationOptions}
                value={iterationState.repeatType}
                onChange={changeRepeatType}
                allowClear={false}
              />
            </Col>
          </Row>
        </Item>

        {renderDaysOrRadios()}

        <Divider className={withPrefix('divider')} />

        <IterationEndDate
          type="form"
          onChange={changeEndDate}
          startDate={iterationState.startDate}
          endDate={iterationState.endDate}
          iterationType={iterationState.repeatType}
          daysOfWeek={iterationState.daysOfWeek}
          monthType={iterationState.monthType}
        />
      </BaseForm>
    </BaseModal>
  );
});
