import React from 'react';

import { FormPartAndInventoryStatus } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletPartAndInventoryStatus } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updatePaIStatus } from '@store/actions/properties/PaI/Status';

import type { CreatePaIStatusDto } from '@model/properties/PaI/Status';

function EditPartAndInventoryModel() {
  const { initialValues, entity } = useOutletPartAndInventoryStatus();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onFinish = async (dto: CreatePaIStatusDto) => {
    try {
      const response = await dispatch(
        updatePaIStatus({
          ...dto,
          partsAndInventoriesStatusId: entity.partsAndInventoriesStatusId,
        })
      ).unwrap();
      ApiNotifications.update(response.particularModel.name);
      navigate(paths.partAndInventoryAttributes.tabs.status.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess
      hasAccess={paiAttrAccess.edit}
      redirectTo={paths.partAndInventoryAttributes.tabs.status.default}
    >
      <FormPartAndInventoryStatus
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditPartAndInventoryModel;
