import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getPaICategoryById } from '@store/actions/properties/PaI/Category';
import { selectPaICategory } from '@store/selectors/properties/PaI/Category';

import type { IOutletPartAndInventoryCategory } from '@constants/OutletContextEntityPages';
import type { CreatePaICategoryDto } from '@model/properties/PaI/Category';

function PartAndInventoryCategory() {
  const partAndInventoryCategory = useAppSelector(selectPaICategory);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition =
    !partAndInventoryCategory ||
    partAndInventoryCategory.partsAndInventoriesCategoryId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getPaICategoryById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreatePaICategoryDto = {
    partsAndInventoriesCategoryCode: partAndInventoryCategory.partsAndInventoriesCategoryCode,
    partsAndInventoriesCategoryName: partAndInventoryCategory.partsAndInventoriesCategoryName,
    partsAndInventoriesParentCategoryId:
      partAndInventoryCategory.partsAndInventoriesParentCategoryId,
  };

  return (
    <Outlet
      context={
        { initialValues, entity: partAndInventoryCategory } as IOutletPartAndInventoryCategory
      }
    />
  );
}

export default PartAndInventoryCategory;
