import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common/CommonForm';
import { checkSpace } from '@constants/regex';
import { useDebounce } from '@hooks';
import { Input } from '@shared/UI';
import cl from 'classnames';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { UpdateProfilePasswordDto } from '@model/profile/Profile';

import './ChangePassword.scss';

const { Item, CaseField, useForm } = CommonForm;

function FormChangePassword(
  props: CommonFormProps<Omit<UpdateProfilePasswordDto, 'userMail'> & { confirmPassword: string }>
) {
  const { header, onFinish: onFinishProps, ...rest } = props;
  const [form] = useForm<
    Omit<UpdateProfilePasswordDto, 'userMail'> & { confirmPassword: string }
  >();

  const { t } = useTranslation();

  const [equalPasswords, setEqualPasswords] = useState<null | boolean>(null);
  const debouncedPassword = useDebounce(equalPasswords, 100);

  const onFinish = (
    dto: Omit<UpdateProfilePasswordDto, 'userMail'> & { confirmPassword: string }
  ) => {
    setEqualPasswords(null);
    onFinishProps?.(dto, form);
  };

  return (
    <CommonForm
      form={form}
      onFinish={onFinish}
      header={{
        title: header?.title ? header?.title : t('titles.Change_Your_Password'),
        ...header,
      }}
      onFieldsChange={(_, allFields) => {
        const pass = allFields.find((f) => {
          if (Array.isArray(f.name)) {
            return f.name[0] === 'password';
          }
        })?.value;
        const confirmPass = allFields.find((f) => {
          if (Array.isArray(f.name)) {
            return f.name[0] === 'confirmPassword';
          }
        })?.value;

        if (pass?.length && confirmPass?.length) {
          setEqualPasswords(pass === confirmPass);
        }
      }}
      {...rest}
    >
      <CaseField>
        <Item
          className={cl({
            'is-success-item': debouncedPassword,
          })}
          required={false}
          name="password"
          label={t('titles.New_Password')}
          rules={[
            {
              required: true,
              min: 6,
            },
            () => ({
              validator(_, value) {
                const withoutSpace = value ? value.match(checkSpace) : null;
                if (withoutSpace) {
                  return Promise.reject(t('errors.Password_Contain_Spaces'));
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input type="password" />
        </Item>
        <Item
          className={cl({
            'is-success-item': debouncedPassword,
          })}
          required={false}
          name="confirmPassword"
          label={t('titles.Confirm_Password')}
          dependencies={['password']}
          rules={[
            {
              required: true,
              min: 6,
            },
            ({ getFieldValue, isFieldTouched }) => ({
              validator(_, value) {
                const pass = getFieldValue('password') as string;
                const touchedPass = isFieldTouched('password');
                const touchedConfirmPass = isFieldTouched('confirmPassword');
                const withoutSpace = value ? value.match(checkSpace) : null;

                if (withoutSpace) {
                  return Promise.reject(t('errors.Password_Contain_Spaces'));
                }

                if (touchedPass && touchedConfirmPass && pass !== value) {
                  return Promise.reject(t('errors.Passwords_Not_Match'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input type="password" />
        </Item>
      </CaseField>

      <CaseField
        hiddenTitle
        style={{
          height: '100%',
        }}
        className="rules-password"
      >
        <div className="rules-password__container">
          <h6>{t('titles.Password_Must_Contain')}</h6>
          <ul>
            <li>{t('titles.Latin_Alphabet')}</li>
            <li>{t('titles.Password_Characters', { number: 6 })}</li>
          </ul>
        </div>
      </CaseField>
    </CommonForm>
  );
}

export { FormChangePassword };
