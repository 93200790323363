import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormPartAndInventoryUnit } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { addPaIUnit } from '@store/actions/properties/PaI/Unit';

import type { CreatePaIUnitDto } from '@model/properties/PaI/Unit';
import type { FormInstance } from 'antd';

function CreatePartAndInventoryUnit() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onFinish = async (dto: CreatePaIUnitDto, form?: FormInstance<CreatePaIUnitDto>) => {
    try {
      const response = await dispatch(addPaIUnit(dto)).unwrap();

      ApiNotifications.create(response.partsAndInventoriesUnitName);
      navigate(paths.partAndInventoryAttributes.tabs.unit.default);
      form?.resetFields();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess
      hasAccess={paiAttrAccess.create}
      redirectTo={paths.partAndInventoryAttributes.tabs.unit.default}
    >
      <FormPartAndInventoryUnit
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.PaI_Attr_Unit') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreatePartAndInventoryUnit;
