import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter, ModalFormHeader } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Input, Radio, Select, TextArea, TreeSelect } from '@shared/UI';
import { replaceStrToNum } from '@shared/utils/functions';
import { getPaICategories } from '@store/actions/properties/PaI/Category';
import { getPaIUnits } from '@store/actions/properties/PaI/Unit';
import { selectPaICardStatus } from '@store/selectors/properties/PaI/Card';
import {
  selectPaICategoriesList,
  selectPaICategoryStatus,
} from '@store/selectors/properties/PaI/Category';
import { selectPaIUnitsList, selectPaIUnitStatus } from '@store/selectors/properties/PaI/Unit';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreatePaICardDto } from '@model/properties/PaI/Card';

const { CaseField, Item, useForm } = CommonForm;

function FormPartAndInventoryName(props: CommonFormProps<CreatePaICardDto>) {
  const [form] = useForm<CreatePaICardDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const partAndInventoryNameStatus = useAppSelector(selectPaICardStatus);

  /* ---------------------- Part and Inventory Form data ---------------------- */
  const listPartAndInventoryUnit = useAppSelector(selectPaIUnitsList);
  const statusPartAndInventoryUnit = useAppSelector(selectPaIUnitStatus);

  const listPartAndInventoryCategory = useAppSelector(selectPaICategoriesList);
  const statusPartAndInventoryCategory = useAppSelector(selectPaICategoryStatus);

  useEffect(() => {
    if (!listPartAndInventoryUnit.length) {
      dispatch(getPaIUnits());
    }
    if (!listPartAndInventoryCategory.length) {
      dispatch(getPaICategories());
    }
  }, []);

  const [ currentStep, setCurrentStep ] = useState(1);

  function onContinue() {
    form.validateFields([
      'partsAndInventoriesCardCode', 
      'partsAndInventoriesCardName',
      'minimumStockLevel',
      'enumTrackingMethode',
    ])
      .then(() => {
        setCurrentStep(2);
      })
      .catch(() => {})
  }

  function onSave() {
    form.submit()
  }

  function onBack() {
    setCurrentStep(1)
  }

  return (
    <CommonForm 
      form={form} 
      loading={partAndInventoryNameStatus === 'pending'} 
      name='formPaIName'
      isModal={isModal}
      hiddenHeader={isModal}
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onContinue={onContinue}
          onBack={onBack}
          onSave={onSave}
          onCancel={rest.header?.onClickCancel}
          currentStep={currentStep}
        /> : null
      }
      additionalHeader={
        isModal ?
        <ModalFormHeader
          step={currentStep}
          title={rest.header?.title as string} />
        : null
      }
      {...rest}
    >
      <CaseField isModal={isModal} hidden={currentStep === 2 && isModal}>
        <Item
          label={t('titles.Code')}
          name="partsAndInventoriesCardCode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
        <Item
          label={t('titles.Name')}
          name="partsAndInventoriesCardName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item
          label={t('titles.Min_Stock_Level')}
          name="minimumStockLevel"
          rules={[
            {
              required: true,
            },
          ]}
          normalize={(value) => {
            return replaceStrToNum(value);
          }}
        >
          <Input placeholder={t('titles.Min_Stock_Level')} />
        </Item>

        <Item
          label={t('titles.Method_Tracking')}
          requiredMark={true}
          name="enumTrackingMethode"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            <Radio value={1}>{t('titles.FIFO')}</Radio>
            <Radio value={2}>{t('titles.LIFO')}</Radio>
          </Radio.Group>
        </Item>
      </CaseField>
      <CaseField isModal={isModal} hidden={currentStep === 1 && isModal}>
        <Item
          label={t('titles.Unit')}
          name="partsAndInventoriesUnitId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            loading={statusPartAndInventoryUnit === 'pending'}
            placeholder={t('titles.Choose_Name', { name: t('titles.Unit') })}
            options={listPartAndInventoryUnit}
            fieldNames={{
              label: 'partsAndInventoriesUnitName',
              value: 'partsAndInventoriesUnitId',
            }}
          />
        </Item>
        <Item
          label={t('titles.Category')}
          name="partsAndInventoriesCategoryId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TreeSelect
            loading={statusPartAndInventoryCategory === 'pending'}
            placeholder={t('titles.Choose_Name', { name: t('titles.Category') })}
            treeData={listPartAndInventoryCategory}
            fieldNames={{
              label: 'partsAndInventoriesCategoryName',
              value: 'partsAndInventoriesCategoryId',
              children: 'childPartsAndInventoriesCategories',
            }}
          />
        </Item>
        <Item label={t('titles.Description')} name="description" hidden={isModal}> 
          <TextArea placeholder={t('titles.Enter_Descr')} />
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { FormPartAndInventoryName };
