import type { RootState } from '@store/rootReducer';

export const selectAsAtCardState = (state: RootState) => state.AsAtCardReducer;

export const selectAsAtCardsList = (state: RootState) => selectAsAtCardState(state).AsAtCardsList;

export const selectAsAtCard = (state: RootState) => selectAsAtCardState(state).AsAtCard;

export const selectAsAtCardStatus = (state: RootState) => selectAsAtCardState(state).AsAtCardStatus;

export const selectAsAtCardError = (state: RootState) => selectAsAtCardState(state).AsAtCardError;
