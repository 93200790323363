import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@hooks';
import { paths } from '@routes/paths';
import { selectAuthIsAuth } from '@store/selectors/profile/Auth';

type ProtectedRouterProps = {
  children: JSX.Element;
};

const ProtectedRouter = (props: ProtectedRouterProps) => {
  const { children } = props;
  const isAuth = useAppSelector(selectAuthIsAuth);
  const location = useLocation();

  if (!isAuth) {
    return <Navigate to={paths.login.default} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRouter;
