import { useParams } from 'react-router-dom';

import CryptographyService from '@services/Cryptography';

import { useAppNavigate } from '../useAppNavigate';

import type { NavigateOptions} from 'react-router-dom';

export interface UseHandleParamIdReturn {
  redirect: (id: string | number, config?: { path?: string; options?: NavigateOptions }) => void;
  encryptParamId: () => string | null;
  encryptedId: string | undefined;
  decryptedId: string;
}

function useHandleParamId(): UseHandleParamIdReturn {
  const params = useParams();
  const navigate = useAppNavigate();

  const encryptParamId = (id?: string | number) => {
    if (!id) {
      return params.id ? CryptographyService.encrypt(params.id) : '';
    }
    return CryptographyService.encrypt(`${id}`);
  };

  const redirect: UseHandleParamIdReturn['redirect'] = (id: string | number, config) => {
    const { path, options } = config || {};
    const encryptId = encryptParamId(id);

    const pathToRedirect = path ? `${path.replace(':id', encryptId)}` : encryptId;

    navigate(pathToRedirect, options);
  };

  const decryptedId = params.id ? CryptographyService.decrypt(params.id) : '';

  return { redirect, encryptParamId, decryptedId, encryptedId: params.id };
}

export default useHandleParamId;
