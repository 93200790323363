import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getAsAtModels } from '@store/actions/properties/AsAt/Model';
import { selectAsAtModelsList } from '@store/selectors/properties/AsAt/Model';

function EntryAssetModel() {
  useSetPageInfo(
    {
      defaultPagePath: paths.assetAttribute.tabs.model.default,
      pageTitle: 'pages_single.Asset_Attr',
    },
    {
      copyTabs: true,
    }
  );
  const assetModelList = useAppSelector(selectAsAtModelsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!assetModelList.length) {
      dispatch(getAsAtModels());
    }
  }, []);

  return <Outlet />;
}

export default EntryAssetModel;
