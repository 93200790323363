import {useCallback, useState} from 'react';

import {KindOfCheckStep} from '@constants/kindOfCheckStep';
import {v4 as uuidv4} from 'uuid';

import type {
  InspectionCheckStepItem,
  MultiplyCheckStepItem,
  NumberCheckStepItem,
  TextCheckStepItem,
} from '@constants/checkStepItems';
import type {
  CheckStepValue,
  PhotoCheckStepItem
} from '@constants/checkStepItems';

const useHandleCheckStepItem = (initialValue?: CheckStepValue[]) => {
  const [state, setState] = useState<CheckStepValue[]>(initialValue || []);

  const turnOffFocus = (): CheckStepValue[] => {
    return state.map((item) => ({...item, isFocus: false}));
  };
  const onAddNewItem = (type: KindOfCheckStep) => {
    if (type === KindOfCheckStep.MultiplyChoice) {
      const newItem: MultiplyCheckStepItem = {
        uuid: uuidv4(),
        title: '',
        isFocus: true,
        type: KindOfCheckStep.MultiplyChoice,
        radios: {
          [uuidv4()]: {
            value: '',
            placeholder: 'Option 1',
          },
          [uuidv4()]: {
            value: '',
            placeholder: 'Option 2',
          },
        },
      };
      const newValues = turnOffFocus();
      setState([...newValues, newItem]);
    }
    if (type === KindOfCheckStep.Text) {
      const newItem: TextCheckStepItem = {
        uuid: uuidv4(),
        title: '',
        isFocus: true,
        type: KindOfCheckStep.Text,
        value: '',
      };
      const newValues = turnOffFocus();
      setState([...newValues, newItem]);
    }
    if (type === KindOfCheckStep.Integer || type === KindOfCheckStep.Decimal) {
      const newItem: NumberCheckStepItem = {
        uuid: uuidv4(),
        title: '',
        isFocus: true,
        type,
        value: 0,
      };
      const newValues = turnOffFocus();
      setState([...newValues, newItem]);
    }
    if (type === KindOfCheckStep.Inspection) {
      const newItem: InspectionCheckStepItem = {
        uuid: uuidv4(),
        title: '',
        isFocus: true,
        type: KindOfCheckStep.Inspection,
        value: false,
      };
      const newValues = turnOffFocus();
      setState([...newValues, newItem]);
    }

    if (type === KindOfCheckStep.Photo) {
      const newItem: PhotoCheckStepItem = {
        uuid: uuidv4(),
        title: '',
        isFocus: true,
        type: KindOfCheckStep.Photo,
        value: [],
      }
      const newValues = turnOffFocus();
      setState([...newValues, newItem]);
    }
  };
  const onAddNewMultiplyOption = (parentId: string) => {
    const foundItem = state.find((item) => item.uuid === parentId);

    if (foundItem && foundItem.type === KindOfCheckStep.MultiplyChoice) {
      const newItem: MultiplyCheckStepItem = {
        ...foundItem,
        radios: {
          ...foundItem.radios,
          [uuidv4()]: {
            value: '',
            placeholder: `Option ${Object.keys(foundItem.radios).length + 1}`,
          },
        },
      };
      const newValues = state.map((item) => (item.uuid === parentId ? newItem : item));
      setState(() => newValues);
    }
  };
  const onMoveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setState((prevState) => {
      const dragItem = prevState[dragIndex];
      const copyState = [...prevState];
      copyState.splice(dragIndex, 1);
      copyState.splice(hoverIndex, 0, dragItem);
      return copyState;
    });
  }, []);

  const onCopyItem = (uuid: string) => {
    const newValues = turnOffFocus();
    // search item which need to copy
    const targetItem = newValues.find((item) => item.uuid === uuid);
    if (targetItem) {
      const itemClone = structuredClone(targetItem);
      // create new item with new uuid
      const newItem = {
        ...itemClone,
        uuid: uuidv4(),
      };
      // if item had entityId, remove it from new item
      delete newItem.entityId;
      // if item is type of multiple choice
      if (newItem.type === KindOfCheckStep.MultiplyChoice) {
        newItem.radios = Object.keys(newItem.radios).reduce((acc, cur) => {
          const newRadio = {
            ...newItem.radios[cur],
          };
          // if radio item had id remove it
          delete newRadio.id;
          return {
            ...acc,
            [uuidv4()]: newRadio,
          };
        }, {});
      }
      setState([...newValues, newItem]);
    }
  };
  const onRemoveItem = (uuid: string) => {
    const newValues = turnOffFocus();
    const newValuesWithoutItem = newValues.filter((item) => item.uuid !== uuid);
    setState(newValuesWithoutItem);
  };
  const onRemoveMultiplyOption = (parentId: string, uuid: string) => {
    setState((prevState) => {
      const copyState = [...prevState];
      const newValue = copyState.find((item) => item.uuid === parentId);
      if (newValue && newValue.type === KindOfCheckStep.MultiplyChoice) {
        newValue.radios = Object.keys(newValue.radios)
          .filter((key) => key !== uuid)
          .reduce((acc, key) => {
            acc[key] = newValue.radios[key];
            return acc;
          }, {} as { [key: string]: { value: string } });
      }
      return copyState;
    });
  };
  const onEditItem = (uuid: string) => {
    const newValues = turnOffFocus();
    const newValuesWithFocus = newValues.map((item) => ({
      ...item,
      isFocus: item.uuid === uuid,
    }));
    setState(newValuesWithFocus);
  };
  const onChangeItemTitle = (title: string, uuid: string) => {
    setState((prevValues) => {
      const newValues = [...prevValues];
      const newValue = newValues.find((value) => value.uuid === uuid);
      if (newValue) {
        newValue.title = title;
      }
      return newValues;
    });
  };
  const onChangeMultiplyItemRadioText = (text: string, parentId: string, uuid: string) => {
    setState((prevValues) => {
      const newValues = [...prevValues];
      const newValue = newValues.find((value) => value.uuid === parentId);
      if (newValue && newValue.type === KindOfCheckStep.MultiplyChoice) {
        newValue.radios[uuid].value = text;
      }
      return newValues;
    });
  };

  const clearState = useCallback(() => {
    setState([]);
  }, []);

  const setSteps = (steps: CheckStepValue[]) => {
    setState(steps);
  };

  const actions = {
    onAddNewItem,
    onAddNewMultiplyOption,
    onMoveItem,
    onCopyItem,
    onRemoveItem,
    onRemoveMultiplyOption,
    onEditItem,
    onChangeItemTitle,
    onChangeMultiplyItemRadioText,
    clearState,
    setSteps,
  };
  return {state, actions};
};

export {useHandleCheckStepItem};
