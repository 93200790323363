import { createDateSelectorDto } from '@components/views/Forms/managment/Maintenance/GeneralV2/_util/createDateSelectorDto';
import { formatMomentDate } from '@shared/utils/functions';

import type { CreateMaintenanceGeneralV2Dto } from '@model/management/Maintenance';
import type { IterationModalState } from './types';

export function isRequestFalseDto(
  dto: CreateMaintenanceGeneralV2Dto,
  iterationState: IterationModalState
): CreateMaintenanceGeneralV2Dto {
  return {
    ...dto,
    isRequest: false,
    frequanceNumeric: iterationState.repeatNumber,
    startDate: null,
    timeOfOccurrence: formatMomentDate(iterationState.startTime).replace('Z', ''),
    dateSelector: createDateSelectorDto(iterationState),
  };
}
