/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {
  FormWorkOrders
} from '@components/views/Forms/managment/Maintenance/FormWorkOrders';
import {useOutletMaintenance} from '@constants/OutletContextEntityPages';
import {useAppDispatch, useAppSelector} from '@hooks';
import {paths} from '@routes/paths';
import {
  addMaintenanceSteps,
  removeWorkStepByIds,
  removeWorkStepsOptionByIds,
} from '@store/actions/management/Maintenance';
import {selectProfile} from '@store/selectors/profile/Profile';

import type {CommonFormProps} from '@components/common';
import type {CreateMaintenanceStepsDto} from '@model/management/Maintenance';
import type {ProfileModel} from '@model/profile/Profile';

const EditMaintenanceWorkOrders = () => {
  const { entity, initialValuesWorkOrders } = useOutletMaintenance();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const profile = useAppSelector(selectProfile) as ProfileModel;

  const header: CommonFormProps['header'] = {
    title: entity.title,
  };

  const handleWorkStepsFinish = async (dto: CreateMaintenanceStepsDto[]) => {
    try {
      const workStepIDs = initialValuesWorkOrders.map((workStep) => workStep.workStepsId);
      if (workStepIDs.length) {
        await dispatch(removeWorkStepByIds(workStepIDs));
      }

      await dispatch(addMaintenanceSteps(dto));

      navigate(paths.maintenance.default);
    } catch (e) {}
  };

  const onRemoveItemAction = (id: number) => {
    dispatch(removeWorkStepByIds([id]));
  };

  const onRemoveOptionAction = (stepId: number, id: number) => {
    dispatch(removeWorkStepsOptionByIds({ stepId, optionIds: [id] }));
  };

  return (
    <FormWorkOrders
      header={header}
      maintenanceId={entity.maintenanceId}
      userId={profile?.userId}
      initialValues={initialValuesWorkOrders}
      onFinish={handleWorkStepsFinish}
    />
  );
};

export default EditMaintenanceWorkOrders;
