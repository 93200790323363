import { createSlice } from '@reduxjs/toolkit';
import { signIn } from '@store/actions/profile/Auth';
import { createPassword, getProfile, updateProfile } from '@store/actions/profile/Profile';

import type { ProfileModel } from '@model/profile/Profile/profile.model';
import type { RoleModel } from '@model/users/Role';

type ProfileState = {
  profile: ProfileModel | null;

  profileRole: RoleModel;
  profileStatus: boolean;
  errorProfile: string | null;
};

const initialState: ProfileState = {
  profile: null,
  profileRole: {} as RoleModel,
  profileStatus: false,
  errorProfile: null,
};

const ProfileSlice = createSlice({
  name: 'Profile',
  initialState,
  reducers: {
    clearProfile: (state) => {
      state.profile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.errorProfile = null;
        state.profileStatus = true;
      })
      .addCase(signIn.fulfilled, (state) => {
        state.errorProfile = null;
        state.profileStatus = false;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.profileStatus = false;
        state.errorProfile = action.payload ? action.payload : null;
      });
    builder
      .addCase(getProfile.pending, (state) => {
        state.errorProfile = null;
        state.profileStatus = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.errorProfile = null;
        state.profileStatus = false;
        state.profile = action.payload.profile;
        state.profileRole = action.payload.role;
      })
      .addCase(getProfile.rejected, (state) => {
        state.profileStatus = false;
      });
    builder
      .addCase(updateProfile.pending, (state) => {
        state.errorProfile = null;
        state.profileStatus = true;
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.errorProfile = null;
        state.profileStatus = false;
        state.profile = payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.profileStatus = false;
        state.errorProfile = action.payload ? action.payload : null;
      });
    builder
      .addCase(createPassword.pending, (state) => {
        state.errorProfile = null;
        state.profileStatus = true;
      })
      .addCase(createPassword.fulfilled, (state) => {
        state.errorProfile = null;
        state.profileStatus = false;
      })
      .addCase(createPassword.rejected, (state) => {
        state.profileStatus = false;
      });
  },
});

export const { clearProfile } = ProfileSlice.actions;
const ProfileReducer = ProfileSlice.reducer;

export { ProfileReducer };
