import { createAsyncThunk } from '@reduxjs/toolkit';
import { CostCenterRepository } from '@repositories';

import type {
  CostCenterModel,
  CreateCostCenterDto,
  FilterCostCenterDto,
  UpdateCostCenterDto,
} from '@model/properties/CostCenter';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getCostCenters = createAsyncThunk<CostCenterModel[], void, { rejectValue: string }>(
  'CostCenter/getCostCenters',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CostCenterRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getCostCentersByFilter = createAsyncThunk<
  {
    costCenters: CostCenterModel[];
    costCentersFilters: FilterCostCenterDto;
  },
  FilterCostCenterDto,
  { rejectValue: string }
>('CostCenter/getCostCentersByFilter', async (dto, { rejectWithValue }) => {
  try {
    const response = await CostCenterRepository.fetchAllByFilter(dto);
    return { costCenters: response.resultObject, costCentersFilters: dto };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getCostCenterById = createAsyncThunk<CostCenterModel, string, { rejectValue: string }>(
  'CostCenter/getCostCenterById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await CostCenterRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addCostCenter = createAsyncThunk<
  CostCenterModel,
  CreateCostCenterDto,
  { rejectValue: string }
>('CostCenter/addCostCenter', async (dto, { rejectWithValue }) => {
  try {
    const response = await CostCenterRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateCostCenter = createAsyncThunk<
  { CostCentersList: CostCenterModel[]; CostCenter: CostCenterModel },
  UpdateCostCenterDto,
  { rejectValue: string }
>('CostCenter/updateCostCenter', async (dto, { rejectWithValue }) => {
  try {
    const response = await CostCenterRepository.update(dto);

    const responseList = await CostCenterRepository.fetchAll();

    return {
      CostCentersList: responseList.resultObject,
      CostCenter: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeCostCenterById = createAsyncThunk<
  CostCenterModel[],
  number[],
  { rejectValue: string }
>('CostCenter/removeCostCenterById', async (ids, { rejectWithValue }) => {
  try {
    await CostCenterRepository.removeById(ids);
    const response = await CostCenterRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
