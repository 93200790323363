import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthRepository } from '@repositories';
import LocaleStorage from '@services/LocaleStorage/LocalStorage.service';
import { ApiNotifications } from '@services/Notifications/adapters';
import { identifyError } from '@shared/utils/functions';

import type { ForgotPasswordDto, SignInDto } from '@model/profile/Auth';

export const signIn = createAsyncThunk<void, SignInDto, { rejectValue: string }>(
  'Auth/signIn',
  async (dto, { rejectWithValue }) => {
    try {
      const response = await AuthRepository.signIn(dto);
      LocaleStorage.handleAuthToken(response.data.token);
    } catch (err: any) {
      const error = identifyError(err);

      if (err.response.status === 401) {
        return rejectWithValue(error);
      }

      if (err.response && !err.response.resultStatus) {
        ApiNotifications.error(error);
        throw new Error(error);
      }

      return rejectWithValue(error);
    }
  }
);

// export const signOut = createAsyncThunk('Auth/signOut', async () => {
//   try {
//     await LocaleStorage.removeAuthToken();
//   } catch (e) {}
// });

export const signOut = createAction('Auth/signOut', () => {
  return {
    payload: {
      isAuth: false,
    },
  };
});

export const passwordRecovery = createAsyncThunk<void, ForgotPasswordDto, { rejectValue: string }>(
  'Auth/forgotpassword',
  async (dto, { rejectWithValue }) => {
    try {
      await AuthRepository.passwordRecovery(dto);
    } catch (err) {
      const error = identifyError(err);
      return rejectWithValue(error);
    }
  }
);
