import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { defaultFormatDate } from '@constants/Date';
import { useIsFirstRender } from '@hooks';
import { DatePicker, Select } from '@shared/UI';
import { createRangeNumbers } from '@shared/utils/functions';
import { Col, Row } from 'antd';
import moment from 'moment';

import type { CreateMaintenanceGeneralV2Dto } from '@model/management/Maintenance';
import type { DatePickerProps} from '@shared/UI';
import type { Rule } from 'antd/lib/form';

const { Item, useFormInstance, useWatch } = CommonForm;

// region --- Field rules

const getDateRules = (isRequest: boolean) => {
  return [{ required: isRequest }];
};

const estimateDurationRules: Array<Rule> = [
  {
    type: 'number',
    max: 24,
    min: 1,
  },
];

// endregion --- Field rules

const estimatedDurationOptions = createRangeNumbers(1, 25).map((num) => ({ value: num }));

export const DateBlock = memo(() => {
  const { t } = useTranslation();

  const form = useFormInstance<CreateMaintenanceGeneralV2Dto>();

  const isFirstRender = useIsFirstRender();

  // region --- Observed values

  const isRequest = useWatch('isRequest', form);

  // endregion --- Observed values

  const dateRules = getDateRules(isRequest);

  const isDisabledDates = !isRequest;

  useEffect(() => {
    if (!isRequest && !isFirstRender) {
      form.validateFields(['startDate', 'estimatedDuration']);
    }
  }, [isRequest]);

  const disabledDates:DatePickerProps['disabledDate'] = (current) => {
    const today = moment().subtract(1, 'days');

    return current && current < today;
  };

  return (
    <>
      <Row justify="space-between">
        <Col lg={11} md={24}>
          <Item
            name="startDate"
            label={t('titles.Start_Date')}
            rules={dateRules}
            disabledItem={isDisabledDates}
          >
            <DatePicker
              placeholder={defaultFormatDate}
              disabled={isDisabledDates}
              disabledDate={disabledDates}
              className="date-block-date-picker"
              fullWidth
              textAlign="center"
            />
          </Item>
        </Col>
        <Col lg={11} md={24}>
          <Item
            name="estimatedDuration"
            label={t('titles.Estimated_Duration')}
            rules={[...dateRules, ...estimateDurationRules]}
            disabledItem={isDisabledDates}
          >
            <Select
              centeredValue
              options={estimatedDurationOptions}
              placeholder="0"
              disabled={isDisabledDates}
            />
          </Item>
        </Col>
      </Row>
    </>
  );
});
