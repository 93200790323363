import React, { memo } from 'react';

import { Dropdown as AntDropdown } from 'antd';
import cl from 'classnames';

import type { DropdownProps as AntDropdownProps } from 'antd';

import './Dropdown.scss';

export interface DropdownProps extends AntDropdownProps {}

export const Dropdown = memo((props: DropdownProps) => {
  const { className, overlayClassName, ...restProps } = props;

  const classes = cl('custom-dropdown', className);

  const overlayClasses = cl('custom-dropdown-overlay', overlayClassName);

  return <AntDropdown className={classes} overlayClassName={overlayClasses} {...restProps} />;
});
