import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { Radio } from '@shared/UI';
import { selectPaICardStatus } from '@store/selectors/properties/PaI/Card';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { PaICardModel } from '@model/properties/PaI/Card';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormPartAndInventoryName(props: CommonPreviewProps<PaICardModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusPartAndInventoryName = useAppSelector(selectPaICardStatus);

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={statusPartAndInventoryName === 'pending'}
      header={{
        title: entity.partsAndInventoriesCardName,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.partsAndInventoriesCardCode}</PreviewField>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.partsAndInventoriesCardName}</PreviewField>
        </Item>
        <Item label={t('titles.Min_Stock_Level')}>
          <PreviewField>{entity.minimumStockLevel}</PreviewField>
        </Item>
        <Item label={t('titles.Method_Tracking')}>
          <Radio
            value={1}
            checked={entity.enumTrackingMethode === 1}
            disabled={entity.enumTrackingMethode !== 1}
          >
            {t('titles.FIFO')}
          </Radio>
          <Radio
            value={2}
            checked={entity.enumTrackingMethode === 2}
            disabled={entity.enumTrackingMethode !== 2}
          >
            {t('titles.LIFO')}
          </Radio>
        </Item>
      </CaseField>
      <CaseField>
        <Item label={t('titles.Unit')}>
          <PreviewField>{entity.partsAndInventoriesUnit.partsAndInventoriesUnitName}</PreviewField>
        </Item>
        <Item label={t('titles.Category')}>
          <PreviewField>
            {entity.partsAndInventoriesCategory.partsAndInventoriesCategoryName}
          </PreviewField>
        </Item>
        <Item label={t('titles.Description')}>
          <PreviewField typeField="textarea">{entity.description}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormPartAndInventoryName };
