import React from 'react';
import { useTranslation } from 'react-i18next';

import { AsetlyLogo, HubPicture } from '@icon/icon-components';

const Static = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={'logo'}>
        <AsetlyLogo />
      </div>

      <p className={'info'}>{t('titles.Sign_In_Info')}</p>

      <div className={'picture'}>
        <HubPicture />
      </div>
    </>
  );
};

export { Static };
