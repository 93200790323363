import { api } from '@services/Axios';

import type {
  BatchUpdatePaIsDto,
  ConsumePaIDto,
  CreatePaIDto,
  FilterPaIDto,
  PaIConsumeNAMEModel,
  PaIConsumePOModel,
  PaIModel,
  PaITypeNAMEModel,
  UpdatePaIDto,
} from '@model/management/PaI';
import type { PartAndInvetoryPropertyValueModel } from '@model/properties/PaI/Property';
import type { ApiResponse } from '@services/Axios';

const PaIEndpoint = '/PartsAndInventories';
const PaIPropertyValueEndpoint = '/PartsAndInventoriesProperyValues'
const ConsumeUrl = '/Consume';

export const PaIRepository = {
  async fetchAll(filterOptions: FilterPaIDto) {
    if (filterOptions.switchPoOrName === 2) {
      const responseName: ApiResponse<
        PaITypeNAMEModel[],
        { totalRecords: number; totalPage: number }
      > = await api.post(`${PaIEndpoint}/GetPartsAndInventoriesListByFilter`, filterOptions);

      return responseName.data;
    }
    const responsePo: ApiResponse<PaIModel[], { totalRecords: number; totalPage: number }> =
      await api.post(`${PaIEndpoint}/GetPartsAndInventoriesListByFilter`, filterOptions);

    return responsePo.data;
  },

  async add(dto: CreatePaIDto) {
    const response: ApiResponse<PaIModel> = await api.post(
      `${PaIEndpoint}/AddPartsAndInventory`,
      dto
    );

    return response.data;
  },

  async fetchById(id: string, consumeType: 'name' | 'po') {
    const response: ApiResponse<PaIModel> = await api.get(
      `${PaIEndpoint}/GetPartsAndInventoriesById/${id}/${consumeType}`
    );

    return response.data;
  },

  async update(dto: UpdatePaIDto) {
    const response: ApiResponse<PaIModel> = await api.post(
      `${PaIEndpoint}/UpdatePartsAndInventories`,
      dto
    );

    return response.data;
  },

  async batchUpdate(dto: BatchUpdatePaIsDto) {
    const response: ApiResponse<null> = await api.post(`${PaIEndpoint}/BatchProcessing`, dto);

    return response.data;
  },

  /* ----------------------------- Consume History ---------------------------- */
  async fetchConsumeByPaiCardId(paiCardId: string) {
    const response: ApiResponse<PaIConsumeNAMEModel[]> = await api.get(
      `${ConsumeUrl}/GetConsumeListByPAICardId/${paiCardId}`
    );

    return response.data;
  },
  async fetchConsumeByPO(po: number) {
    const response: ApiResponse<PaIConsumePOModel[]> = await api.get(
      `${ConsumeUrl}/GetConsumeListByRowId/${po}`
    );

    return response.data;
  },
  async addConsume(dto: ConsumePaIDto) {
    const response: ApiResponse<any> = await api.post(`${ConsumeUrl}/AddConsume`, dto);

    return response.data;
  },
  async fetchAllPropertyValue(filterOptions: {
    page: number,
    perPage: number
  }) {
    const response: ApiResponse<PartAndInvetoryPropertyValueModel[], { totalRecords: number; totalPage: number }> =
      await api.post(`${PaIPropertyValueEndpoint}/GetPartsAndInventoryDetailValueList`, filterOptions);

    return response.data;
  },
  async searchByPropertyValue(query: string) {
    const response: ApiResponse<{ value: string }[]> = await api.post(
      `${PaIEndpoint}/SearchPAICardPropertyValues`,
      { keyword: query }
    );

    return response.data;
  },
};
