import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormContract } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { formatMomentDate } from '@shared/utils/functions';
import { addContract, addContractFiles } from '@store/actions/properties/Contract';

import type { FileWithID } from '@interfaces/interfaces';
import type { CreateContractDto } from '@model/properties/Contract';
import type { FormInstance } from 'antd';

function CreateContract() {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { t } = useTranslation();

  const { contractAccess } = useGetAccess();

  const onFinish = async (
    dto: CreateContractDto & { files?: FileWithID[], cent?: number },
    form?: FormInstance<CreateContractDto>
  ) => {
    const { files, startDate, endDate, cent, price, ...restDto } = dto;

    const normalizeDate = {
      ...restDto,
      startDate: formatMomentDate(startDate),
      endDate: formatMomentDate(endDate),
      price: cent ? +`${price}.${cent}` : price,
    };

    const originalFiles = files?.map((file) => file.file as File);

    try {
      const response = await dispatch(addContract(normalizeDate)).unwrap();

      try {
        await dispatch(
          addContractFiles({
            contractId: response.contractId,
            files: originalFiles,
          })
        );
      } catch (error) {
        ApiNotifications.error(error);
      }

      ApiNotifications.create(response.name);
      navigate(paths.contract.default);
      form?.resetFields();
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={contractAccess.create} redirectTo={paths.contract.default}>
      <FormContract
        onFinish={onFinish}
        header={{
          title: t('titles.New_Item', { name: t('pages_single.Contract') }),
        }}
      />
    </CheckAccess>
  );
}

export default CreateContract;
