import React from 'react';

import { Input as AntdInput } from 'antd';
import cl from 'classnames';

import type { InputProps as AntdInputProps , InputRef } from 'antd';

import './Input.scss';

export interface InputProps extends Omit<AntdInputProps, 'className'> {
  className?: string | string[];
  isActiveField?: boolean;
  alignInputValue?: 'left' | 'right' | 'center';
}

export const Input = React.forwardRef<InputRef, InputProps>((props, ref) => {
  const { className, isActiveField, alignInputValue, style, ...rest } = props;

  const defaultClasses = cl('custom-input', className);

  const defaultStyle: typeof style = {
    ...style,
    textAlign: alignInputValue,
  };

  return (
    <AntdInput
      ref={ref}
      style={defaultStyle}
      className={defaultClasses}
      autoComplete="off"
      {...rest}
    />
  );
});
