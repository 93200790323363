import { api } from '@services/Axios';

import type {
  CreateCheckStepDto,
  CreateMaintenanceChecklistDto,
  CreateMultiplyChoiceListDto,
  MaAtChecklistModel,
  MaAtChecklistSummaryModel,
  UpdateCheckStepDto,
  UpdateMaintenanceChecklistDto,
  UpdateMultipleChoiceListDto,
} from '@model/properties/MaAt/Checklist';
import type { ApiResponse } from '@services/Axios';

const MaAtChecklistEndpoint = '/CheckList';

const MaAtChecklistRepository = {
  // Fetch endpoints
  async fetchAll(filterOptions: { page: number; perPage: number }) {
    const response: ApiResponse<MaAtChecklistModel[], { totalRecords: number; totalPage: number }> =
      await api.post(`${MaAtChecklistEndpoint}/GetCheckLists`, filterOptions);

    return response.data;
  },

  async fetchById(id: string | number) {
    const response: ApiResponse<MaAtChecklistModel[]> = await api.post(
      `${MaAtChecklistEndpoint}/GetChecklistById/${id}`
    );

    return response.data;
  },

  async fetchSummaryList() {
    const response: ApiResponse<MaAtChecklistSummaryModel[]> = await api.post(
      `${MaAtChecklistEndpoint}/GetCheckListsSummary`
    );

    return response.data;
  },

  // Add endpoints
  async addChecklist(dto: CreateMaintenanceChecklistDto) {
    const response: ApiResponse<MaAtChecklistModel> = await api.post(
      `${MaAtChecklistEndpoint}/AddCheckList`,
      dto
    );

    return response.data;
  },

  async addCheckSteps(dto: CreateCheckStepDto[]) {
    const response: ApiResponse<MaAtChecklistModel> = await api.post(
      `${MaAtChecklistEndpoint}/AddCheckSteps`,
      {
        addCheckStepModelArray: dto,
      }
    );

    return response.data;
  },

  async addMultipleChoiceList(dto: CreateMultiplyChoiceListDto) {
    const response: ApiResponse<MaAtChecklistModel> = await api.post(
      `${MaAtChecklistEndpoint}/AddMultipleChoiceList`,
      dto
    );

    return response.data;
  },

  // Update endpoints
  async updateChecklist(dto: UpdateMaintenanceChecklistDto) {
    const response: ApiResponse<MaAtChecklistModel> = await api.post(
      `${MaAtChecklistEndpoint}/UpdateCheckList`,
      dto
    );

    return response.data;
  },

  async updateCheckStep(dto: Omit<UpdateCheckStepDto, 'mcList'>) {
    const response: ApiResponse<MaAtChecklistModel> = await api.post(
      `${MaAtChecklistEndpoint}/UpdateCheckStep`,
      dto
    );

    return response.data;
  },

  async updateMultipleChoiceList(dto: UpdateMultipleChoiceListDto) {
    const response: ApiResponse<MaAtChecklistModel> = await api.post(
      `${MaAtChecklistEndpoint}/UpdateMultipleChoiceList`,
      dto
    );

    return response.data;
  },

  // Remove endpoints

  async removeChecklistByIds(ids: number[]) {
    const response: ApiResponse<null> = await api.post(
      `${MaAtChecklistEndpoint}/RemoveCheckListByIds`,
      {
        checklistIds: ids,
      }
    );

    return response.data;
  },

  async removeCheckStepByIds(ids: number[]) {
    const response: ApiResponse<null> = await api.post(
      `${MaAtChecklistEndpoint}/RemoveCheckStepByIds`,
      {
        checkStepsIds: ids,
      }
    );

    return response.data;
  },

  async removeMultipleChoiceListByIds(ids: number[]) {
    const response: ApiResponse<null> = await api.post(
      `${MaAtChecklistEndpoint}/RemoveMultipleChoiceListByIds`,
      {
        multipleChoiceListIds: ids,
      }
    );

    return response.data;
  },
};

export { MaAtChecklistRepository };
