import React from 'react';

import { LocationBarChart } from './LocationBarChart';
import { WorkOrdersBarChart } from './WorkOrdersBarChart';

import type { DashboardModel } from '@model/dashboard';

import './BarCharts.scss';

interface BarChartsProps {
  data: DashboardModel | null
}

const BarCharts = (props: BarChartsProps) => {
  const { data } = props;

  return (
    <div className="bar-charts">
      <WorkOrdersBarChart data={data?.returnWorkOrderTop5Totals} />
      <LocationBarChart data={data?.returnAssetsSiteNamesTop5Totals} />
    </div>
  );
};

export { BarCharts };
