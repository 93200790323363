import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { REQUIRED_RULE } from '@constants/formRules';
import { Input, Select } from '@shared/UI';

import type { AsAtBrandModel } from '@model/properties/AsAt/Brand';
import type { SiteModel } from '@model/properties/Site';
import type { ItemRules, SelectFieldNames } from '@shared/utils/types';

interface MiddleSectionProps {
  assetBrandsList: Array<AsAtBrandModel>;

  sitesList: Array<Omit<SiteModel, 'childSite'>>;

  onAssetBrandOpenModal: () => void;

  onSiteOpenModal: () => void;
}

const { CaseField, Item, useFormInstance } = CommonForm;

// region --- Item rules

const siteRules: ItemRules = [REQUIRED_RULE];

const serialNumberRules: ItemRules = [{ max: 50 }];

// endregion --- Item rules

// region --- Filed names

const assetBrandNames: SelectFieldNames<AsAtBrandModel> = {
  label: 'name',
  value: 'nonCurrAssetCardBrandId',
};

const siteNames: SelectFieldNames<Omit<SiteModel, 'childSite'>> = {
  label: 'name',
  value: 'siteId',
};

// endregion --- Filed names

export const MiddleSection = memo((props: MiddleSectionProps) => {
  const { assetBrandsList, sitesList, onAssetBrandOpenModal, onSiteOpenModal } = props;

  const { t } = useTranslation();

  const form = useFormInstance();

  const handleValueFromBrand = (value: number | null) => {
    form.setFieldValue('nonCurrAssetCardModelId', null);
    return value;
  };

  return (
    <CaseField md={24} lg={7}>
      <Item label={t('titles.Serial_Number')} name="serialNumber" rules={serialNumberRules}>
        <Input placeholder={t('titles.Serial_Number')} />
      </Item>

      <Item
        label={t('titles.Brand')}
        name="nonCurrAssetCardBrandId"
        getValueFromEvent={handleValueFromBrand}
      >
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Brand') })}
          options={assetBrandsList}
          fieldNames={assetBrandNames}
          isCreatable
          creatableAction={onAssetBrandOpenModal}
        />
      </Item>
      <Item label={t('titles.Site')} name="siteId" rules={siteRules}>
        <Select
          placeholder={t('titles.Choose_Name', { name: t('titles.Site') })}
          options={sitesList}
          fieldNames={siteNames}
          isCreatable
          creatableAction={onSiteOpenModal}
        />
      </Item>
    </CaseField>
  );
});
