import type { BatchUpdateMaintenancesDto, MaintenanceModel } from '@model/management/Maintenance';

type IdsParam = Partial<Omit<BatchUpdateMaintenancesDto, 'partsAndInventoriesIds'>>;

export function getBatchMaintenanceDTO(
  checkedMaintenances: Array<MaintenanceModel>,
  ids: IdsParam
): BatchUpdateMaintenancesDto {
  const defaultIds = {
    assigneeUserId: null,

    siteId: null,

    ...ids,
  };

  return {
    maintenanceIds: checkedMaintenances.map((m) => m.maintenanceId),
    ...defaultIds,
  };
}
