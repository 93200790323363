import type { RootState } from '@store/rootReducer';

export const selectPaIUnitState = (state: RootState) => state.PaIUnitReducer;

export const selectPaIUnitsList = (state: RootState) => selectPaIUnitState(state).PaIUnitsList;

export const selectPaIUnit = (state: RootState) => selectPaIUnitState(state).PaIUnit;

export const selectPaIUnitStatus = (state: RootState) => selectPaIUnitState(state).PaIUnitStatus;

export const selectPaIUnitError = (state: RootState) => selectPaIUnitState(state).PaIUnitError;
