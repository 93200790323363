import React, { memo } from 'react';

import { AssigneeDropdown } from './AssigneeDropdown';
import { CheckedCount } from './CheckedCount';
import { SiteDropdown } from './SiteDropdown';

import './LeftItems.scss';

export const LeftItems = memo(() => {
  return (
    <div className="maintenance-toolbar-left-side">
      <CheckedCount />
      <AssigneeDropdown />
      <SiteDropdown />
    </div>
  );
});
