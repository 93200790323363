import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AssetRepository } from '@repositories';
import LocalStorageService from '@services/LocaleStorage';
import { identifyError } from '@shared/utils/functions';

import type { ListViewType } from '@constants/listViewType';
import type { FileWithID } from '@interfaces/interfaces';
import type {
  AssetFileModel,
  AssetModel,
  AssetSearchBarcodeResponseModel,
  BatchUpdateAssetsDto,
  CreateAssetDto,
  FilterAssetDto,
  SetOrderAssetPictureDto,
  UpdateAssetDto,
} from '@model/management/Asset';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer';
import type { AppDispatch } from '@store/store';

// region --- Get Actions

export const getAssets = createAsyncThunk<
  {
    assets: Array<AssetModel>;
    totalPage: number;
    totalRecords: number;
    filterData: FilterAssetDto;
  },
  Partial<FilterAssetDto> | void,
  {
    state: RootState;
    rejectValue: { error: string; filterData: FilterAssetDto };
  }
>('Asset/getAssetsNew', async (filterData, { rejectWithValue, getState }) => {
  let updatedFilter: FilterAssetDto = { ...getState().AssetReducer.filterOptions, ...filterData };

  try {
    const { resultObject, totalRecords, totalPage } = await AssetRepository.fetchAll(updatedFilter);

    return {
      assets: resultObject,
      filterData: updatedFilter,
      totalRecords,
      totalPage,
    };
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue({
      error,
      filterData: updatedFilter,
    });
  }
});

export const getAssetAllRecords = createAsyncThunk<AssetModel[], void, { rejectValue: string }>(
  'Asset/getAssetAllRecords',
  async (_, { rejectWithValue }) => {
    try {
      const getTotalRecords = await AssetRepository.fetchAll({
        page: 1,
        perPage: 1,
      });
      const totalRecords = getTotalRecords.totalRecords;
      const response = await AssetRepository.fetchAll({
        page: 1,
        perPage: totalRecords,
      });

      return response.resultObject;
    } catch (err: any) {
      const error = identifyError(err);
      return rejectWithValue(error);
    }
  }
);
export const getAssetById = createAsyncThunk<AssetModel, string, { rejectValue: string }>(
  'Asset/getAssetById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await AssetRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error = identifyError(err);
      return rejectWithValue(error);
    }
  }
);

export const getAssetsByCardId = createAsyncThunk<
  AssetModel[],
  string | number,
  { rejectValue: string }
>('Asset/getAssetsByCardId', async (id, { rejectWithValue }) => {
  try {
    const response = await AssetRepository.fetchByAssetCardId(id);

    return response.resultObject;
  } catch (e) {
    const err = identifyError(e);

    return rejectWithValue(err);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addAsset = createAsyncThunk<
  AssetModel,
  CreateAssetDto,
  { rejectValue: string; dispatch: AppDispatch }
>('Asset/addAsset', async (dto, { rejectWithValue, dispatch }) => {
  const { images, ...restDto } = dto;
  try {
    const response = await AssetRepository.add(restDto);

    const formData = await createImageFormData(images);

    const id = response.resultObject.nonCurrAssetId;

    if (formData) {
      await AssetRepository.uploadImagesById(formData, id);
    }

    await dispatch(getAssets());

    return response.resultObject;
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

export const addAssetImagesById = createAsyncThunk<
  AssetModel | null,
  { files: CreateAssetDto['images']; assetId: number; isEdit?: boolean },
  { rejectValue: string }
>('Asset/addAssetImages', async (dto, { rejectWithValue }) => {
  try {
    if (dto.files && dto.files.length === 0) {
      throw new Error('No file selected');
    }
    const formData = await createImageFormData(dto.files);

    if (formData) {
      await AssetRepository.uploadImagesById(formData, dto.assetId);
      const { resultObject } = await AssetRepository.fetchById(dto.assetId);

      return resultObject;
    }

    return null;
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

export const setFilterOptions = createAction('Asset/setFilterOptions', (labels: Array<string>) => {
  return {
    payload: labels,
  };
});

export const resetAssetFilters = createAction('Asset/resetAssetFilters');

// endregion --- Add Actions

// region --- Update Actions

export const updateAsset = createAsyncThunk<
  { assets: AssetModel[]; asset: AssetModel },
  UpdateAssetDto,
  { rejectValue: string; state: RootState }
>('Asset/updateAsset', async (dto, { rejectWithValue, getState }) => {
  try {
    const { images, ...restDto } = dto;

    const stateFilterOptions = getState().AssetReducer.filterOptions;

    const id = dto.nonCurrAssetId;
    const formData = await createImageFormData(images);

    if (formData) {
      await AssetRepository.uploadImagesById(formData, id);
    }

    const response = await AssetRepository.update(restDto);

    const responseList = await AssetRepository.fetchAll(stateFilterOptions);

    return { assets: responseList.resultObject, asset: response.resultObject };
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

export const batchUpdateAssets = createAsyncThunk<
  Array<AssetModel>,
  BatchUpdateAssetsDto,
  { rejectValue: string; state: RootState }
>('Asset/batchUpdateAssets', async (dto, { rejectWithValue, getState }) => {
  try {
    await AssetRepository.batchUpdate(dto);

    const currentFilters: FilterAssetDto = getState().AssetReducer.filterOptions;

    const response = await AssetRepository.fetchAll(currentFilters);

    return response.resultObject;
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

export const updateOrderAssetPicture = createAsyncThunk<
  { assets: Array<AssetModel> | null; asset: AssetModel | null },
  SetOrderAssetPictureDto,
  { rejectValue: string; state: RootState }
>('Asset/updateOrderAssetPicture', async (dto, { rejectWithValue, getState }) => {
  try {
    await AssetRepository.updateOrderAssetPicture(dto);
    const { resultObject } = await AssetRepository.fetchById(dto.nonCurrAssetId);

    const assetState = getState().AssetReducer;

    if (assetState.asset) {
      const asset = resultObject;
      const assets = assetState.assetsList.map((currAsset) => {
        if (asset.nonCurrAssetId === currAsset.nonCurrAssetId) {
          return asset;
        }
        return currAsset;
      });

      return {
        assets,
        asset,
      };
    }

    return {
      assets: null,
      asset: null,
    };
  } catch (err: any) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

export const updateAssetFilterOptions = createAsyncThunk<
  Partial<FilterAssetDto>,
  Partial<FilterAssetDto>
>('Asset/updateAssetFilterOptions', async (filterOptions) => {
  return filterOptions;
});

export const updateAssetView = createAction('Asset/updateAssetView', (view: ListViewType) => {
  LocalStorageService.handleListView(view);
  return {
    payload: view,
  };
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeAssetById = createAsyncThunk<
  AssetModel[],
  number[],
  { rejectValue: string; state: RootState }
>('Asset/removeAssetById', async (ids, { rejectWithValue, getState }) => {
  try {
    const stateFilterOptions = getState().AssetReducer.filterOptions;
    await AssetRepository.removeById(ids);
    const response = await AssetRepository.fetchAll(stateFilterOptions);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const removeAssetImageById = createAsyncThunk<
  AssetModel | null,
  number,
  { rejectValue: string; state: RootState }
>('Asset/removeAssetImageById', async (id, { rejectWithValue, getState }) => {
  try {
    await AssetRepository.removeFileById(id);

    const currentAsset = getState().AssetReducer.asset;

    if (currentAsset) {
      const nonCurrAssetFiles =
        currentAsset.nonCurrAssetFiles?.filter(
          (image: AssetFileModel) => image.nonCurrAssetFileId !== id
        ) || [];
      return {
        ...currentAsset,
        nonCurrAssetFiles,
      };
    }

    return null;
  } catch (err) {
    const error = identifyError(err);
    return rejectWithValue(error);
  }
});

// endregion --- Remove Actions

export const handleCheckedAssets = createAction(
  'Asset/handleCheckedAssets',
  (entities: AssetModel[]) => {
    return {
      payload: {
        entities,
      },
    };
  }
);

export const clearCheckedAssets = createAction('Asset/clearCheckedAssets', () => {
  return {
    payload: [],
  };
});

export const searchAssetBarcodes = createAsyncThunk<
  AssetSearchBarcodeResponseModel[],
  string,
  { rejectValue: string }
>('Asset/searchAssetBarcodes', async (query, { rejectWithValue }) => {
  try {
    const response = await AssetRepository.searchByBarcode(query);

    return response.resultObject;
  } catch (err) {
    return rejectWithValue(identifyError(err));
  }
});

// TODO: need to create for it interface and adapter
async function createImageFormData(images?: Array<FileWithID | FileWithID<AssetFileModel>>) {
  if (!images) return null;

  const onlyFileType = images.filter((image) => image.file instanceof File);

  if (!onlyFileType.length) return null;

  const formData = new FormData();

  images.forEach((image) => {
    if (image.file instanceof File) {
      formData.append('formFiles', image.file as File);
    }
  });

  return formData;
}
