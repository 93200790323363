import React from 'react';

import { useCheckStepContext } from '../../_utils';
import { Images } from './Images';
import { Uploader } from './Uploader';

import styles from './ImagesField.module.scss';

export const ImagesField = () => {
  const { isActiveWorkStep } = useCheckStepContext();

  return (
    <div className={styles.imagesField}>
      <Images />
      {isActiveWorkStep && <Uploader />}
    </div>
  );
};
