import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseCheckStepTitle } from '@components/common/CheckStepItems/BaseCheckStepTitle';
import { KindOfCheckStep } from '@constants/kindOfCheckStep';
import { Input } from '@shared/UI';
import cl from 'classnames';

import type {
  InspectionCheckStepItem,
  NumberCheckStepItem,
  TextCheckStepItem,
} from '@constants/checkStepItems';

import './OtherCheckStep.scss';

interface OtherCheckStepProps {
  otherCheckStep: InspectionCheckStepItem | NumberCheckStepItem | TextCheckStepItem;
  isErrorTitle?: boolean;
  onChangeTitle?: (title: string, uuid: string) => void;
}

const defaultClassPrefix = 'other-check-step';

const OtherCheckStep = (props: OtherCheckStepProps) => {
  const {otherCheckStep, isErrorTitle, onChangeTitle: onChangeTitleProps} = props;

  const defaultClasses = cl(defaultClassPrefix);

  const {t} = useTranslation();

  const {isFocus, uuid, title} = otherCheckStep;

  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeTitleProps?.(e.target.value, uuid);
    },
    [onChangeTitleProps, uuid, title]
  );

  const placeholder = useCallback(() => {
    if (otherCheckStep.type === KindOfCheckStep.Inspection) {
      return t('titles.Inspection');
    }
    if (otherCheckStep.type === KindOfCheckStep.Decimal) {
      return t('titles.Decimal');
    }
    if (otherCheckStep.type === KindOfCheckStep.Text) {
      return t('titles.Text');
    }
    if (otherCheckStep.type === KindOfCheckStep.Integer) {
      return t('titles.Integer');
    }
  }, []);

  return (
    <div className={defaultClasses}>
      <div className={cl(`${defaultClassPrefix}__input-title-wrapper`)}>
        <BaseCheckStepTitle
          title={title}
          onChangeTitle={onChangeTitle}
          isFocus={isFocus}
          isError={isErrorTitle}
        />
      </div>
      <div className={cl(`${defaultClassPrefix}__input-value-wrapper`)}>
        <Input
          className={cl(`${defaultClassPrefix}__input`)}
          disabled
          readOnly
          placeholder={placeholder()}
        />
      </div>
    </div>
  );
};

export {OtherCheckStep};
