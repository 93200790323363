import React, { memo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { ImageContainer } from '@shared/UI';
import cl from 'classnames';

import type { FileWithID } from '@interfaces/interfaces';
import type { AssetFileModel } from '@model/management/Asset';
import type { ImageContainerProps } from '@shared/UI';
import type { DragImageValue, DropImageValue } from '../_utils';

import './DraggableContainer.scss';

type DraggableContainerProps = ImageContainerProps & {
  file: FileWithID | FileWithID<AssetFileModel>;

  index: number;

  onMoveItem?: (value: DropImageValue) => void;
};

const ITEM_TYPE = 'DRAGGABLE_IMAGE';

export const DraggableContainer = memo((props: DraggableContainerProps) => {
  const { file, index, onMoveItem, ...restProps } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [, drag] = useDrag({
    type: ITEM_TYPE,
    item: (): DragImageValue => {
      return { dragFile: file, dragIndex: index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop<DragImageValue, void, { isOver: boolean }>({
    accept: ITEM_TYPE,
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        handlerId: monitor.getHandlerId(),
      };
    },
    canDrop(item: DragImageValue) {
      return item.dragIndex !== index;
    },
    drop(item: DragImageValue) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.dragIndex;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const value: DropImageValue = {
        dragFile: item.dragFile,
        dragIndex,
        hoverFile: file,
        hoverIndex,
      };

      onMoveItem?.(value);

      item.dragIndex = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <ImageContainer ref={ref} className={cl({ 'asset-image--is-over': isOver })} {...restProps} />
  );
});
