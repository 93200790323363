import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getPaICategories } from '@store/actions/properties/PaI/Category';
import { selectPaICategoriesList } from '@store/selectors/properties/PaI/Category';

function EntryPartAndInventoryCategory() {
  useSetPageInfo(
    {
      pageTitle: 'pages_single.PaI_Attr',
      defaultPagePath: paths.partAndInventoryAttributes.tabs.category.default,
    },
    {
      copyTabs: true,
    }
  );

  const partAndInventoryCategoryList = useAppSelector(selectPaICategoriesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!partAndInventoryCategoryList.length) {
      dispatch(getPaICategories());
    }
  }, []);

  return <Outlet />;
}

export default EntryPartAndInventoryCategory;
