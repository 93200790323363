import React, { Children } from 'react';

import { Arrow } from '@icon/icon-components';
import { Loader } from '@shared/UI';
import { Select as AntdSelect } from 'antd';
import cl from 'classnames';
import get from 'lodash.get';

import type { SelectFieldNames } from '@shared/utils/types';
import type { SelectProps as AntdSelectProps } from 'antd';
import type { RefSelectProps } from 'antd/lib/select';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import type { FieldNames } from 'rc-select/lib/Select';

import './Select.scss';

export interface SelectProps<T = any, V = any>
  extends Omit<AntdSelectProps<T, V>, 'className' | 'popupClassName' | 'fieldNames'> {
  className?: string | string[];
  simpleMultipleTag?: boolean;
  isCreatable?: boolean;
  creatableAction?: () => void;
  onPopupScrollBottom?: (isBottom: boolean) => void;
  centeredValue?: boolean;
  fieldNames?: SelectFieldNames<V>;
}

export const { Option } = AntdSelect;

const Select = <T, V>(props: SelectProps<T, V>, ref: React.ForwardedRef<RefSelectProps>) => {
  const {
    className,
    filterOption,
    showSearch = true,
    allowClear = true,
    loading,
    simpleMultipleTag = false,
    tagRender: tagRenderProp,
    dropdownRender: dropdownRenderProp,
    isCreatable = false,
    creatableAction,
    onPopupScrollBottom,
    fieldNames,
    centeredValue = false,
    notFoundContent,
    options,
    ...rest
  } = props;

  const filterOptionDefault = (input: string, option: any) => {
    if (fieldNames && fieldNames.label) {
      const label = get(option, fieldNames.label) as string | number;
      return (label ?? '').toString().toLowerCase().includes(input.toLowerCase());
    }
    return (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase());
  };

  const simpleTag = (tagProps: CustomTagProps) => (
    <div
      className="custom-select-simpleTag"
      style={{
        color: 'var(--main-blue)',
        display: 'inline-block',
        marginRight: 5,
      }}
    >
      {tagProps.label},
    </div>
  );

  const tagRender = simpleMultipleTag ? simpleTag : tagRenderProp;

  const selectClasses = cl(className, 'custom-select', {
    'custom-select--simple-tag': simpleMultipleTag,
    'custom-select--centered-value': centeredValue,
  });

  const dropdownRender = (menu: React.ReactElement): React.ReactElement => {
    if (dropdownRenderProp) {
      return dropdownRenderProp(menu);
    }
    if (isCreatable) {
      return (
        <>
          {menu}
          <div className="custom-select-dropdown-create">
            <button onClick={creatableAction}>
              <span>+ Add new</span>
            </button>
          </div>
        </>
      );
    }

    return <>{menu}</>;
  };

  const notFoundComponent = notFoundContent ? (
    <div className="custom-select-not-found">{notFoundContent}</div>
  ) : null;

  const emptyOptions =
    options?.length === 0 || (props.children && Children.count(props.children) === 0) ? (
      <div className="custom-select-not-found">You need to create a new option</div>
    ) : null;

  return (
    <AntdSelect
      tagRender={tagRender}
      showSearch={showSearch}
      allowClear={allowClear}
      filterOption={filterOption ? filterOption : filterOptionDefault}
      className={selectClasses}
      popupClassName={'custom-select-dropdown'}
      loading={loading}
      suffixIcon={!loading ? <Arrow /> : <Loader sizeIcon={20} />}
      ref={ref}
      dropdownRender={dropdownRender}
      fieldNames={fieldNames as FieldNames}
      notFoundContent={notFoundComponent || emptyOptions}
      options={options}
      {...rest}
    />
  );
};

const SelectWithRef = React.forwardRef(Select);

export { SelectWithRef as Select };
