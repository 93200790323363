import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getCountPlanById } from '@store/actions/management/CountPlan';
import { selectCountPlan } from '@store/selectors/management/CountPlan';

import type { IOutletCountPlan } from '@constants/OutletContextEntityPages';
import type { CreateCountPlanDto } from '@model/management/CountPlan';

function CountPlan() {
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();
  const countPlan = useAppSelector(selectCountPlan);

  const condition = !countPlan || countPlan.prdCountPlanId !== +parseInt(decryptedId);
  const id = parseInt(decryptedId) as any;

  useSetPageInfo({
    defaultPagePath: paths.countPlan.default,
    pageTitle: 'pages_single.Task',
  });

  useEffect(() => {
    if (condition) {
      dispatch(getCountPlanById(id));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const siteIds = countPlan.prdCountPlanSites.map((site) => site.site.siteId);

  const initialValues: CreateCountPlanDto = {
    prdCountPlanNo: countPlan.prdCountPlanNo,
    taskName: countPlan.taskName,
    startTime: countPlan.startTime,
    endTime: countPlan.endTime,
    siteIds: siteIds,
    description: countPlan.description,
    assignTo: countPlan.assignTo,
  };

  return <Outlet context={{ initialValues, entity: countPlan } as IOutletCountPlan} />;
}

export default CountPlan;
