import React from 'react';

function Dot() {
  return (
    <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 3.13574C6 3.93139 5.68393 4.69445 5.12132 5.25706C4.55871 5.81967 3.79565 6.13574 3 6.13574C2.20435 6.13574 1.44129 5.81967 0.87868 5.25706C0.316071 4.69445 0 3.93139 0 3.13574C0 2.34009 0.316071 1.57703 0.87868 1.01442C1.44129 0.451813 2.20435 0.135742 3 0.135742C3.79565 0.135742 4.55871 0.451813 5.12132 1.01442C5.68393 1.57703 6 2.34009 6 3.13574Z"
        fill="white"
      />
    </svg>
  );
}

export { Dot };
