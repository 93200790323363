import { api } from '@services/Axios';

import type {
  AssetFileModel,
  AssetModel,
  AssetPropertyDetailModel,
  AssetSearchBarcodeResponseModel,
  BatchUpdateAssetsDto,
  CreateAssetDto,
  FilterAssetDto,
  SetOrderAssetPictureDto,
  UpdateAssetDto,
} from '@model/management/Asset';
import type { ApiResponse } from '@services/Axios';

const AssetEndpoint = 'NonCurrAsset';

const AssetFileEndpoint = 'NonCurrAssetFiles';

const AssetPropertyValueEndpoint = 'NonCurrAssetProperyValues';

export const AssetRepository = {
  async fetchAll(filterOptions: FilterAssetDto) {
    const response: ApiResponse<AssetModel[], { totalRecords: number; totalPage: number }> =
      await api.post(`${AssetEndpoint}/GetAssetListByFilter`, filterOptions);

    return response.data;
  },

  async fetchByAssetCardId(id: string | number) {
    const response: ApiResponse<AssetModel[]> = await api.get(
      `${AssetEndpoint}/GetBarcodesByNonCurrAssetCardId/${id}`
    );

    return response.data;
  },

  async add(dto: CreateAssetDto) {
    const response: ApiResponse<AssetModel> = await api.post(
      `${AssetEndpoint}/AddNonCurrAsset`,
      dto
    );

    return response.data;
  },

  async fetchById(id: string | number) {
    const response: ApiResponse<AssetModel> = await api.get(
      `${AssetEndpoint}/GetAssetListByById/${id}`
    );

    return response.data;
  },

  async update(dto: UpdateAssetDto) {
    const response: ApiResponse<AssetModel> = await api.post(
      `${AssetEndpoint}/UpdateNonCurrAssets`,
      dto,
      {
        headers: {
          'Access-Control-Allow-Credentials': 'true',
        },
      }
    );

    return response.data;
  },

  async batchUpdate(dto: BatchUpdateAssetsDto) {
    const response: ApiResponse<null> = await api.post(`${AssetEndpoint}/BatchProcessing`, dto);

    return response.data;
  },

  async updateOrderAssetPicture(dto: SetOrderAssetPictureDto) {
    const response: ApiResponse<null> = await api.post(
      `${AssetFileEndpoint}/SetOrderPicturesRows`,
      dto
    );

    return response.data;
  },

  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(
      `${AssetEndpoint}/RemoveByRemoveNonCurrAssetIdList`,
      {
        nonCurrAssetIds: ids,
      }
    );

    return response.data;
  },

  async removeFileById(id: number) {
    const response: ApiResponse<null> = await api.post(
      `${AssetFileEndpoint}/DeleteNonCurrAssetFile`,
      {
        nonCurrAssetFileId: id,
      }
    );

    return response.data;
  },

  async uploadImagesById(formData: FormData, id: string | number) {
    const response: ApiResponse<null> = await api.post(
      `${AssetFileEndpoint}/UploadNonCurrAssetImages?NonCurrAssetId=${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  },

  async fetchImagesById(id: string | number) {
    const response: ApiResponse<AssetFileModel[]> = await api.get(
      `${AssetFileEndpoint}/GetNonCurrAssetFilesListByNonFixedAssetId/${id}`
    );

    return response.data;
  },

  async searchByBarcode(query: string) {
    const response: ApiResponse<AssetSearchBarcodeResponseModel[]> = await api.post(
      `${AssetEndpoint}/SearchBarcodes`,
      { keyword: query }
    );

    return response.data;
  },

  async fetchAllPropertyValue(filterOptions: { page: number; perPage: number }) {
    const response: ApiResponse<
      AssetPropertyDetailModel[],
      { totalRecords: number; totalPage: number }
    > = await api.post(
      `${AssetPropertyValueEndpoint}/GetNonCurrAssetPropertyDetailValueList`,
      filterOptions
    );

    return response.data;
  },

  async searchByPropertyValue(query: string) {
    const response: ApiResponse<{ value: string }[]> = await api.post(
      `${AssetEndpoint}/SearchNonCurrAssetCardPropertyValues`,
      { keyword: query }
    );

    return response.data;
  },
};
