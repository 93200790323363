import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getSiteById } from '@store/actions/properties/Site';
import { selectSite } from '@store/selectors/properties/Site';

import type { IOutletSite } from '@constants/OutletContextEntityPages';
import type { CreateSiteDto } from '@model/properties/Site';

const Site = () => {
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();
  const site = useAppSelector(selectSite);

  const condition = !site || site.siteId !== +decryptedId;

  useSetPageInfo({
    pageTitle: 'pages_single.Site',
    defaultPagePath: paths.site.default,
  });

  useEffect(() => {
    if (condition) {
      dispatch(getSiteById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateSiteDto = {
    name: site.name,
    siteCode: site.siteCode,
    cityId: site.cityId,
    countryId: site.countryId,
    address: site.address,
    description: site.description,
    barcode: site.barcode,
    area: site.area,
    parentSiteId: site.parentSiteId,
    town: site.town,
  };

  return <Outlet context={{ initialValues, entity: site } as IOutletSite} />;
};

export default Site;
