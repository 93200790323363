import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '@hooks';
import { ApiNotifications } from '@services/Notifications/adapters';
import { BaseForm, BaseModal, Input, Select } from '@shared/UI';
import { addDepartment } from '@store/actions/properties/Department';
import { Col, Row } from 'antd';

import type { CreateDepartmentDto, DepartmentModel } from '@model/properties/Department';
import type { SiteModel } from '@model/properties/Site';
import type { CreateUserDto } from '@model/users/User';
import type { SelectFieldNames } from '@shared/utils/types';
import type { FormInstance } from 'antd';
import type { Rule } from 'antd/lib/form';

import './DepartmentModalForm.scss';

interface DepartmentModalFormProps {
  isOpen: boolean;

  onClose: () => void;

  sitesList: Array<SiteModel>;
  siteNames: SelectFieldNames;

  siteRules: Array<Rule>;

  departmentList: Array<Omit<DepartmentModel, 'childDepartment'>>;

  departmentNames: SelectFieldNames;

  formInstance: FormInstance<CreateUserDto>;
}

const { Item, useForm } = BaseForm;

// region --- ToolbarItems rules

const nameRules = [
  {
    required: true,
  },
];

// endregion --- ToolbarItems rules

export const DepartmentModalForm = memo((props: DepartmentModalFormProps) => {
  const {
    isOpen,
    siteNames,
    siteRules,
    sitesList,
    departmentList,
    departmentNames,
    formInstance,
    onClose,
  } = props;

  const [form] = useForm<CreateDepartmentDto>();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleFinish = (dto: CreateDepartmentDto) => {
    setIsLoading(true);
    dispatch(addDepartment(dto))
      .unwrap()
      .then(({ createdDepartment }) => {
        formInstance.setFieldValue('departmentId', createdDepartment.departmentId);
        ApiNotifications.create(createdDepartment.name);
      })
      .then(() => {
        onClose();
        form.resetFields();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOk = () => {
    form.submit();
  };

  return (
    <BaseModal
      onOk={handleOk}
      open={isOpen}
      title={t('titles.New_Item', { name: t('pages_single.Department') })}
      size="small"
      closable={false}
      onCancel={onClose}
      className="department-modal-form"
      okButtonProps={{
        disabled: isLoading,
        loading: isLoading,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
    >
      <BaseForm form={form} onFinish={handleFinish} name="departmentForm">
        <Row>
          <Col span={24}>
            <Item label={t('titles.Name')} name="name" rules={nameRules}>
              <Input placeholder={t('titles.Name')} />
            </Item>
          </Col>

          <Col span={24}>
            <Item label={t('titles.Code')} name="departmentCode">
              <Input placeholder={t('titles.Code')} />
            </Item>
          </Col>

          <Col span={24}>
            <Item label={t('titles.Parent_Department')} name="parentDepartmentId">
              <Select
                placeholder={t('titles.Choose_Name', { name: t('titles.Parent_Department') })}
                options={departmentList}
                fieldNames={departmentNames}
              />
            </Item>
          </Col>

          <Col span={24}>
            <Item name="siteId" rules={siteRules} label={t('titles.Site')}>
              <Select
                fieldNames={siteNames}
                options={sitesList}
                placeholder={t('titles.Choose_Name', { name: t('titles.Site') })}
              />
            </Item>
          </Col>
        </Row>
      </BaseForm>
    </BaseModal>
  );
});
