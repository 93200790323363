import React, { useEffect, useRef, useState } from 'react';

import { Loader } from '@shared/UI';
import get from 'lodash.get';

import { Select } from '../Select';

import type { RefSelectProps } from 'antd/lib/select';
import type { ReactElement} from 'react';
import type { SelectProps } from '../Select';

export type AsyncSelectReturnData<T> = Promise<{ data: T[]; total: number }>;

export type AsyncSelectFields<T> = {
  value: keyof T;
  label: keyof T;
  key: keyof T;
};

export type AsyncSelectProps<T = any, V = any> = Omit<
  SelectProps<T, V>,
  'fieldNames' | 'onPopupScroll' | 'onSearch'
> & {
  fetchData: (page: number, pageSize: number, searchValue: string) => AsyncSelectReturnData<T>;
  searchData?: (searchValue: string) => AsyncSelectReturnData<T>;

  fieldNames: AsyncSelectFields<T>;
};

interface OptionData {
  key: number;
  value: string;
  label: string;
}

const getOptions = <T,>(data: T[], fieldNames: AsyncSelectFields<T>) => {
  return data.map(
    (item): OptionData => ({
      key: get(item, fieldNames.key),
      value: get(item, fieldNames.value),
      label: get(item, fieldNames.label),
    })
  );
};

const AsyncSelect = <T, V>(props: AsyncSelectProps<T, V>) => {
  const [loading, setLoading] = useState(false);

  const { fieldNames, fetchData, onChange, searchData, ...restProps } = props;

  const selectRef = useRef<RefSelectProps>(null);

  const [options, setOptions] = useState<OptionData[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const searchRef = useRef<string>('');
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleFetchData = async (
    current: number,
    pageSize: number,
    searchValue: string
  ): Promise<T[]> => {
    try {
      const result = await fetchData(current, pageSize, searchValue);
      const newOptions = getOptions(result.data, fieldNames);

      setOptions((prevOptions) => [...prevOptions, ...newOptions]);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: result.total,
      }));

      return result.data;
    } catch (e) {
      return await Promise.reject(e);
    }
  };

  useEffect(() => {
    handleFetchData(pagination.current, pagination.pageSize, searchRef.current);
  }, [pagination.current]);

  const handleSearch = (value: string) => {
    setLoading(true);
    searchRef.current = value;
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (searchRef.current !== '') {
        searchData?.(value)
          .then((result) => {
            const newOptions = getOptions(result.data, fieldNames);
            setOptions([...newOptions]);
            setPagination((prevPagination) => ({
              ...prevPagination,
              current: 1,
              total: result.total,
            }));
          })
          .finally(() => setLoading(false));
        return;
      }
      if (!searchRef.current.length) {
        fetchData(1, 10, '')
          .then((result) => {
            const newOptions = getOptions(result.data, fieldNames);

            setOptions(newOptions);
            setPagination(() => ({
              current: 1,
              pageSize: 10,
              total: result.total,
            }));
          })
          .catch((e) => {
            return Promise.reject(e);
          })
          .finally(() => setLoading(false));
      }
    }, 300);
  };

  const handlePopupScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const isNearBottom = target.scrollTop + target.offsetHeight >= target.scrollHeight - 20;

    if (
      isNearBottom &&
      pagination.current * pagination.pageSize < pagination.total &&
      searchRef.current.length <= 0
    ) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: pagination.current + 1,
      }));
    }
  };

  const handleChange: SelectProps['onChange'] = (value, option) => {
    if (searchRef.current.length > 0) {
      searchRef.current = '';
      handleSearch('');
      if (selectRef.current) {
        selectRef.current.scrollTo(0);
      }
    }
    onChange?.(value, option);
  };

  const handleBlur = () => {
    if (searchRef.current.length > 0) {
      searchRef.current = '';
      handleSearch('');
      if (selectRef.current) {
        selectRef.current.scrollTo(0);
      }
    }
  };

  const dropdownMenu = (menu: ReactElement) => {
    if (loading) {
      return (
        <div
          style={{
            height: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </div>
      );
    }
    return menu;
  };

  return (
    <Select
      ref={selectRef}
      onPopupScroll={handlePopupScroll}
      onSearch={handleSearch}
      onChange={handleChange}
      searchValue={searchRef.current}
      options={options as any}
      onBlur={handleBlur}
      dropdownRender={dropdownMenu}
      {...restProps}
    />
  );
};

export { AsyncSelect };
