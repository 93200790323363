import React, { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import {
  BasicTablePage
} from '@components/common/BasicTablePage';
import { useAppDispatch, useAppSelector, useGetAccess } from '@hooks';
import { Select } from '@shared/UI';
import { checkFilterValues, compare, createCsvConfig } from '@shared/utils/functions';
import { getContracts, getContractsByFilter } from '@store/actions/properties/Contract';
import {
  selectContractFilters,
  selectContractsFilteredList,
  selectContractsListWithFormattedDate,
  selectContractStatus,
} from '@store/selectors/properties/Contract';

import type {
  BasicTablePageProps,
  ColumnsTableType} from '@components/common/BasicTablePage';
import type { ContractModel, FilterContractDto } from '@model/properties/Contract';

const columnsContract: ColumnsTableType<ContractModel> = [
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Vendor')}</Translation>,
    dataIndex: ['partner', 'name'],
    key: 'partner',
    ellipsis: true,
    sorter: (a, b) => compare(a.partner.name, b.partner.name),
  },
  {
    title: <Translation>{(t) => t('titles.Amount')}</Translation>,
    dataIndex: 'price',
    key: 'price',
    ellipsis: true,
    sorter: (a, b) => a.price - b.price,
  },
  {
    title: <Translation>{(t) => t('titles.Currency')}</Translation>,
    dataIndex: ['currency', 'name'],
    key: 'partner',
    ellipsis: true,
    sorter: (a, b) => compare(a.currency.name, b.currency.name),
  },
  {
    title: <Translation>{(t) => t('titles.End_Date')}</Translation>,
    dataIndex: 'endDate',
    key: 'partner',
    ellipsis: true,
    sorter: (a, b) => compare(a.endDate, b.endDate),
  },
];

const csvHeadersContract = [
  {
    label: 'Contract NO',
    key: 'no',
  },
  {
    label: 'Contract Name',
    key: 'name',
  },
  {
    label: 'Vendor',
    key: 'partner',
  },
  {
    label: 'Amount',
    key: 'price',
  },
  {
    label: 'Currency',
    key: 'currency',
  },
  {
    label: 'Start date',
    key: 'startDate',
  },
  {
    label: 'End date',
    key: 'endDate',
  },
];

const csvFileNameContract = 'contracts.csv';

function Contracts() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const listContract = useAppSelector(selectContractsListWithFormattedDate);
  const filteredListContract = useAppSelector(selectContractsFilteredList);
  const statusContract = useAppSelector(selectContractStatus);
  const filters = useAppSelector(selectContractFilters);

  const { contractAccess } = useGetAccess();

  const dataSource = useMemo(
    () => (Boolean(filteredListContract.length) ? filteredListContract : listContract),
    [filteredListContract, listContract]
  );

  const csvData = useMemo(
    () =>
      listContract.map((contract) => ({
        ...contract,
        partner: contract.partner.name,
        currency: contract.currency.name,
      })),
    [listContract]
  );

  const csvConfig = useMemo(
    () => createCsvConfig(csvData, csvHeadersContract, csvFileNameContract),
    [csvData]
  );

  const filterProps = {
    items: [
      {
        label: t('titles.Name'),
        name: 'contractNameArray',
        item: (
          <Select
            options={listContract}
            maxTagCount={4}
            mode="tags"
            fieldNames={{ label: 'name', value: 'name' }}
          />
        ),
      },
      {
        label: t('titles.Code'),
        name: 'contractCodeArray',
        item: (
          <Select
            options={listContract}
            maxTagCount={4}
            mode="tags"
            fieldNames={{ label: 'contractCode', value: 'contractCode' }}
          />
        ),
      },
      {
        label: t('titles.Contract_No'),
        name: 'contractNoArray',
        item: (
          <Select
            options={listContract}
            maxTagCount={4}
            mode="tags"
            fieldNames={{ label: 'no', value: 'no' }}
          />
        ),
      },
    ],
    applyAction: (values: FilterContractDto) => {
      if (!checkFilterValues(values)) {
        getContractsList();
        return;
      }

      dispatch(getContractsByFilter(values));
    },
    resetAction: () => {
      if (checkFilterValues(filters)) {
        getContractsList();
      }
    },
    initialValuesForm: {
      contractNameArray: filters?.contractNameArray,
      contractCodeArray: filters?.contractCodeArray,
      contractNoArray: filters?.contractNoArray,
    },
  };

  function getContractsList() {
    dispatch(getContracts());
  }

  const header: BasicTablePageProps['header'] = {
    titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Contract') }),
    csvConfig,
    filterProps,
    newButtonProps: {
      disabled: !contractAccess.create,
    },
  };
  return (
    <BasicTablePage
      table={{
        columns: columnsContract,
        dataSource: dataSource,
        loading: statusContract === 'pending',
        rowKey: (record) => record.contractId,
        dataSourceName: t('pages_plural.Contracts'),
      }}
      header={header}
    />
  );
}

export { Contracts };
