import {createAsyncThunk} from '@reduxjs/toolkit';
import {UserGroupRepository} from '@repositories';

import type {CreateUserGroupDto, UpdateUserGroupDto, UserGroupModel,} from '@model/users/UserGroup';
import type {ApiError} from '@services/Axios';
import type {RootState} from '@store/rootReducer';

// region --- Get Actions

export const getUserGroups = createAsyncThunk<UserGroupModel[], void, { rejectValue: string }>(
  'UserGroup/getUserGroups',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserGroupRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// region --- Add Actions

export const addUserGroup = createAsyncThunk<
  { groupList: UserGroupModel[]; createdUserGroup: UserGroupModel },
  CreateUserGroupDto
>('UserGroup/createUserGroup', async (dto, { rejectWithValue }) => {
  try {
    const createdUserGroup = await UserGroupRepository.add(dto);
    const response = await UserGroupRepository.fetchAll();

    return {
      groupList: response.resultObject,
      createdUserGroup: createdUserGroup.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// region --- Get by Id Actions

export const getUserGroupById = createAsyncThunk<UserGroupModel, string>(
  'UserGroup/getUserGroupById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await UserGroupRepository.fetchById(id);

      return res.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// region --- Update Actions

export const updateUserGroup = createAsyncThunk<
  { groups: UserGroupModel[]; group: UserGroupModel },
  UpdateUserGroupDto,
  { state: RootState }
>('UserGroup/updateUserGroup', async (dto, { rejectWithValue, getState }) => {
  try {
    const res = await UserGroupRepository.update(dto);

    const { resultObject } = res;

    const { groupList } = getState().UserGroupReducer;

    const groups = groupList.map((group) =>
      group.groupsId === resultObject.groupsId ? resultObject : group
    );

    return { groups, group: resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// region --- Remove Actions

export const removeUserGroupById = createAsyncThunk<
  UserGroupModel[],
  number[],
  { state: RootState }
>('UserGroup/removeUserGroupById', async (ids, { rejectWithValue }) => {
  try {
    await UserGroupRepository.removeById(ids);

    const response = await UserGroupRepository.fetchAll();
    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});
