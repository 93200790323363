import React from 'react';

import type { SVGProps } from 'react';

const DropdownProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={16} cy={16} r={16} fill="#F6F6F6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.662 14a2.667 2.667 0 1 1-5.334 0 2.667 2.667 0 0 1 5.334 0Zm-1.334 0a1.334 1.334 0 1 1-2.667 0 1.334 1.334 0 0 1 2.667 0Z"
      fill="#374957"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.997 8.667a7.333 7.333 0 1 0 0 14.667 7.333 7.333 0 0 0 0-14.667Zm-6 7.333c0 1.394.476 2.676 1.272 3.695a5.991 5.991 0 0 1 4.772-2.361 5.988 5.988 0 0 1 4.728 2.305A5.999 5.999 0 1 0 9.997 16Zm6 6a5.975 5.975 0 0 1-3.78-1.341 4.66 4.66 0 0 1 3.824-1.992 4.66 4.66 0 0 1 3.792 1.947A5.976 5.976 0 0 1 15.997 22Z"
      fill="#374957"
    />
  </svg>
);

export { DropdownProfile };
