class LanguageLCAdapter {
  private keyStorage = 'lng';

  getCurrentLanguage() {
    return localStorage.getItem(this.keyStorage);
  }
}

const instance = new LanguageLCAdapter();

export { instance as LanguageLCAdapter };
