import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormAssetCategory } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletAssetCategory } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateAsAtCategory } from '@store/actions/properties/AsAt/Category';

import type { CreateAsAtCategoryDto } from '@model/properties/AsAt/Category';

function EditAssetCategory() {
  const { initialValues, entity } = useOutletAssetCategory();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { assetAttrAccess } = useGetAccess();

  const onFinish = (dto: CreateAsAtCategoryDto) => {
    dispatch(
      updateAsAtCategory({
        ...dto,
        nonCurrAssetCardCategoryId: entity.nonCurrAssetCardCategoryId,
      })
    )
      .unwrap()
      .then((response) => {
        ApiNotifications.update(response.AsAtCategory.name);
        navigate(paths.assetAttribute.tabs.category.default);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <CheckAccess hasAccess={assetAttrAccess.edit} redirectTo={paths.assetAttribute.tabs.category.default}>
      <FormAssetCategory
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditAssetCategory;
