import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsAtModelRepository } from '@repositories';

import type {
  AsAtModelModel,
  CreateAsAtModelDto,
  UpdateAsAtModelDto,
} from '@model/properties/AsAt/Model';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getAsAtModels = createAsyncThunk<AsAtModelModel[], void, { rejectValue: string }>(
  'AsAtModel/getAsAtModels',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AsAtModelRepository.fetchAll();

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

export const getAsAtModelById = createAsyncThunk<AsAtModelModel, string, { rejectValue: string }>(
  'AsAtModel/getAsAtModelById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await AsAtModelRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addAsAtModel = createAsyncThunk<
  AsAtModelModel,
  CreateAsAtModelDto,
  { rejectValue: string }
>('AsAtModel/addAsAtModel', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtModelRepository.add(dto);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateAsAtModel = createAsyncThunk<
  { AsAtModelsList: AsAtModelModel[]; AsAtModel: AsAtModelModel },
  UpdateAsAtModelDto,
  { rejectValue: string }
>('AsAtModel/updateAsAtModel', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtModelRepository.update(dto);

    const responseList = await AsAtModelRepository.fetchAll();

    return {
      AsAtModelsList: responseList.resultObject,
      AsAtModel: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeAsAtModelById = createAsyncThunk<
  AsAtModelModel[],
  number[],
  { rejectValue: string }
>('AsAtModel/removeAsAtModelById', async (ids, { rejectWithValue }) => {
  try {
    await AsAtModelRepository.removeById(ids);
    const response = await AsAtModelRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
