import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector, useModalState } from '@hooks';
import { basePathChildren } from '@routes/paths';
import { Button, DeleteModal, PageHeader } from '@shared/UI';
import { selectPageInfo } from '@store/selectors/extra/Local';

import type { ButtonProps,DeleteModalProps, PageHeaderProps } from '@shared/UI';

export interface HeaderProps extends PageHeaderProps {
  textCancelButton?: string;
  textSaveButton?: string;
  textDeleteButton?: string;
  textEditButton?: string;
  hiddenDeleteButton?: boolean;
  hiddenCancelButton?: boolean;
  hiddenSaveButton?: boolean;
  hiddenEditButton?: boolean;
  loadingSaveButton?: boolean;
  saveButtonProps?: ButtonProps;
  deleteButtonProps?: ButtonProps;
  editButtonProps?: ButtonProps;
  deleteModal?: DeleteModalProps;
  previewExtra?: React.ReactNode;
  onClickCancel?: () => void;
  onClickDelete?: () => void;
}

interface DefaultHeaderProps extends HeaderProps {
  isEditForm?: boolean;
  isPreview?: boolean;
}

function Header(props: DefaultHeaderProps) {
  const {
    extra,
    loadingSaveButton,
    isEditForm,
    isPreview,
    deleteModal,
    title,
    previewExtra,
    textDeleteButton,
    textEditButton,
    textSaveButton,
    textCancelButton,
    hiddenDeleteButton,
    hiddenEditButton,
    hiddenCancelButton,
    hiddenSaveButton,
    deleteButtonProps,
    saveButtonProps,
    editButtonProps,
    onClickCancel: onClickCancelProps,
    onClickDelete: onClickDeleteProps,
    ...rest
  } = props;

  const { t } = useTranslation();

  /* -------------------------------- Navigate -------------------------------- */
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const { defaultPagePath } = useAppSelector(selectPageInfo);
  /* ------------------------------- Modal State ------------------------------ */
  const {
    isOpen,
    actions: { onClose, onOpen },
  } = useModalState();

  const onClickDelete = useCallback(() => {
    onClickDeleteProps && onClickDeleteProps();
    onClose();
  }, []);

  /* -------------------------------- Navigate Handling -------------------------------- */

  const onClickCancel = useCallback(() => {
    if (isEditForm) {
      const path = pathname.split(`/${basePathChildren.edit}`)[0];

      navigation(path);
    } else {
      navigation(defaultPagePath);
    }
  }, [defaultPagePath, isEditForm, navigation]);

  /* -------------------------- Preview Extra Buttons -------------------------- */

  const deleteButton = !hiddenDeleteButton && (
    <Button onClick={onOpen} key="1" {...deleteButtonProps}>
      {textDeleteButton || t('titles.Delete')}
    </Button>
  );

  const editButton = !hiddenEditButton && (
    <Button key="2" type="primary" {...editButtonProps}>
      <Link to={`${pathname}/${basePathChildren.edit}`}>{textEditButton || t('titles.Edit')}</Link>
    </Button>
  );

  const previewExtraButtons = previewExtra ? previewExtra : [deleteButton, editButton];

  /* --------------------------- Default Extra Buttons --------------------------- */

  const cancelButton = !hiddenCancelButton && (
    <Button onClick={onClickCancelProps ? onClickCancelProps : onClickCancel} key="1">
      {textCancelButton || t('titles.Cancel')}
    </Button>
  );
  const saveButton = !hiddenSaveButton && (
    <Button
      disabled={loadingSaveButton}
      loading={loadingSaveButton}
      htmlType="submit"
      key="2"
      type="primary"
      {...saveButtonProps}
    >
      {textSaveButton || t('titles.Save')}
    </Button>
  );

  const defaultExtra: DefaultHeaderProps['extra'] = !isPreview
    ? [cancelButton, saveButton]
    : previewExtraButtons;

  return (
    <>
      <PageHeader {...rest} extra={extra !== undefined ? extra : defaultExtra} title={title} />
      <DeleteModal
        open={isOpen}
        onCancel={onClose}
        onOk={onClickDelete}
        centered
        deletedName={Boolean(deleteModal?.deletedName) ? deleteModal?.deletedName : title}
        {...deleteModal}
      />
    </>
  );
}

export default Header;
