import * as React from 'react';

import type { SVGProps } from 'react';

export const ToolbarTrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 19.001a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-12H6v12ZM7.475 8.265h8.952v11.122H7.475V8.265ZM15.5 4l-1.003-.778H9.46l-.96.778H5v1.281h14V4.001h-3.5Z"
      fill="#fff"
    />
  </svg>
);
