import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getCostCenterById } from '@store/actions/properties/CostCenter';
import { selectCostCenter } from '@store/selectors/properties/CostCenter';

import type { IOutletCostCenter } from '@constants/OutletContextEntityPages';
import type { CreateCostCenterDto } from '@model/properties/CostCenter';

function CostCenter() {
  const costCenter = useAppSelector(selectCostCenter);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !costCenter || costCenter.costCenterId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getCostCenterById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateCostCenterDto = {
    costCenterCode: costCenter.costCenterCode,
    name: costCenter.name,
  };

  return <Outlet context={{ initialValues, entity: costCenter } as IOutletCostCenter} />;
}

export default CostCenter;
