import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassPrefix } from '@hooks';
import { Switch } from '@shared/UI';

import { useRoleAuthContext } from '../RoleAuthProvider';

import type { RoleFunctionNameModel } from '@model/users/Role';

import './SubRoleItem.scss';

interface SubRoleItemProps {
  authorizationChild: RoleFunctionNameModel;

  onChangeChild: (checked: boolean, id: number) => void;
}

export const SubRoleItem = memo((props: SubRoleItemProps) => {
  const { authorizationChild, onChangeChild } = props;

  const { prefix, withPrefix } = useClassPrefix('role-auth-sub-item');

  const { isPreview } = useRoleAuthContext();

  const { t } = useTranslation();

  const handleChange = (checked: boolean) => {
    onChangeChild(checked, authorizationChild.roleFunctionNameId);
  };

  return (
    <li className={prefix}>
      <div className={withPrefix('title')}>
        <p className={withPrefix('name')}>
          {t(`titles.${authorizationChild.roleFunctionKeywordName}`)}
        </p>
        <Switch
          size="small"
          disabled={isPreview}
          checked={authorizationChild.value}
          className={withPrefix('switcher')}
          onChange={handleChange}
        />
      </div>
    </li>
  );
});
