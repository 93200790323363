import type { ActionFunction, CheckStepItem } from '@components/common/CheckStepItem/_utils';

export const onChangeResultValue: ActionFunction<{ step: CheckStepItem; value: string }> = (
  { step, value },
  { setState }
) => {
  setState((prevState) =>
    prevState.map((s) => {
      if (s.uuid === step.uuid) {
        return {
          ...s,
          hasError: value.length === 0,
          isCompleted: value.length > 0,
          value,
        };
      }
      return s;
    })
  );
};
