import React, { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getPaIPropertyById } from '@store/actions/properties/PaI/Property';
import { selectPaIProperty } from '@store/selectors/properties/PaI/Property';

import type { IOutletPartAndInventoryProperty } from '@constants/OutletContextEntityPages';
import type { CreatePaIPropertyDto } from '@model/properties/PaI/Property';

function PartAndInventoryProperty() {
  const partAndInventoryProperty = useAppSelector(selectPaIProperty);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition =
    !partAndInventoryProperty ||
    partAndInventoryProperty.partsAndInventoriesCardPropertyId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getPaIPropertyById(decryptedId));
    }
  }, []);

  const partAndInventoryPropertyValues = useMemo(
    () =>
      partAndInventoryProperty?.partsAndInventoriesPropertyValues.map((value) => ({
        value: value.value,
        partsAndInventoriesPropertyValueId: value.partsAndInventoriesPropertyValueId,
      })),
    [partAndInventoryProperty]
  );

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreatePaIPropertyDto = {
    isUnique: partAndInventoryProperty.isUnique,
    name: partAndInventoryProperty.name,
    partsAndInventoriesCardPropertyCode:
      partAndInventoryProperty.partsAndInventoriesCardPropertyCode,
    partsAndInventoriesPropertyTypeId: partAndInventoryProperty.partsAndInventoriesPropertyTypeId,
    partsAndInventoriesCardPropertyValues: partAndInventoryPropertyValues || [],
  };

  return (
    <Outlet
      context={
        {
          initialValues,
          entity: partAndInventoryProperty,
        } as IOutletPartAndInventoryProperty
      }
    />
  );
}

export default PartAndInventoryProperty;
