import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getAsAtBrandById } from '@store/actions/properties/AsAt/Brand';
import { selectAsAtBrand } from '@store/selectors/properties/AsAt/Brand';

import type { IOutletAssetBrand } from '@constants/OutletContextEntityPages';
import type { CreateAsAtBrandDto } from '@model/properties/AsAt/Brand';

function AssetBrand() {
  const assetBrand = useAppSelector(selectAsAtBrand);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !assetBrand || assetBrand.nonCurrAssetCardBrandId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getAsAtBrandById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateAsAtBrandDto = {
    nonCurrAssetCardBrandCode: assetBrand.nonCurrAssetCardBrandCode,
    name: assetBrand.name,
  };

  return <Outlet context={{ initialValues, entity: assetBrand } as IOutletAssetBrand} />;
}

export default AssetBrand;
