import React from 'react';

import { DatePicker as AntdDatePicker } from 'antd';
import cl from 'classnames';

import 'antd/es/date-picker/style/index';

import type { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import type { Moment } from 'moment';

import './DatePicker.scss';

export interface DatePickerProps extends PickerDateProps<Moment> {
  fullWidth?: boolean;
  textAlign?: 'center' | 'left' | 'right';
}

const DatePicker = (props: DatePickerProps) => {
  const {
    className,
    showToday = false,
    placement = 'bottomLeft',
    fullWidth,
    textAlign,
    suffixIcon,
    ...rest
  } = props;

  const defaultClasses = cl(
    'custom-datepicker',
    {
      [`custom-datepicker--fullwidth`]: fullWidth,
      [`custom-datepicker--text-${textAlign}`]: textAlign,
    },
    className
  );

  return (
    <AntdDatePicker
      placement={placement}
      format="DD/MM/YYYY"
      showToday={showToday}
      className={defaultClasses}
      suffixIcon={suffixIcon ?? null}
      {...rest}
      popupClassName="custom-datepicker-popup"
    />
  );
};

export { DatePicker };
