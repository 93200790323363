import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectDepartmentStatus } from '@store/selectors/properties/Department';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { DepartmentModel } from '@model/properties/Department';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormDepartment(props: CommonPreviewProps<DepartmentModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusDepartment = useAppSelector(selectDepartmentStatus);

  return (
    <CommonForm
      isPreview
      loading={statusDepartment === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.departmentCode}</PreviewField>
        </Item>
      </CaseField>
      <CaseField>
        <Item label={t('titles.Parent_Department')}>
          <PreviewField>{entity.parentName}</PreviewField>
        </Item>
        <Item label={t('titles.Site')}>
          <PreviewField>{entity.site.name}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormDepartment };
