import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector, useGetAccess } from '@hooks';
import { compare } from '@shared/utils/functions';
import {
  selectMaAtRequestReasonsList,
  selectMaAtRequestReasonStatus,
} from '@store/selectors/properties/MaAt/RequestReason';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { MaAtRequestReasonModel } from '@model/properties/MaAt/RequestReason';

const columns: ColumnsTableType<MaAtRequestReasonModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'reasonCode',
    key: 'code',
    ellipsis: true,
    sorter: (a, b) => compare(a.reasonCode, b.reasonCode),
  },

  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'reasonName',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.reasonName, b.reasonName),
  },
];

const MaintenanceRequestReasons = () => {
  const { t } = useTranslation();

  const maintenanceRequestReasonList = useAppSelector(selectMaAtRequestReasonsList);
  const maintenanceRequestReasonStatus = useAppSelector(selectMaAtRequestReasonStatus);

  const { maintenanceAttrAccess } = useGetAccess();

  return (
    <BasicTablePage
      table={{
        loading: maintenanceRequestReasonStatus === 'pending',
        dataSource: maintenanceRequestReasonList,
        columns: columns,
        rowKey: (record) => record.requestReasonId,
        dataSourceName: t('pages_plural.Maintenance_Attr_Reason'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', {
          name: t('pages_single.Maintenance_Attr_Reason'),
        }),
        csvConfig: {
          data: maintenanceRequestReasonList,
          fileName: 'maintenance-requestReasons.csv',
          headers: [
            {
              label: 'Code',
              key: 'reasonCode',
            },
            {
              label: 'Name',
              key: 'reasonName',
            },
          ],
        },
        newButtonProps: {
          disabled: !maintenanceAttrAccess.create,
        },
      }}
    />
  );
};

export { MaintenanceRequestReasons };
