import React from 'react';

import cl from 'classnames';

import { checkStepKeys, useCheckStepContext } from '../_utils';
import { BaseField } from './BaseField';
import { ImagesField } from './ImagesField';
import { InspectionField } from './InspectionField';
import { MultipleChoice } from './MultipleChoice';

import styles from './WorkSteps.module.scss';

export const WorkStep = () => {
  const { step } = useCheckStepContext();

  const classes = cl(styles.workSteps, {
    [styles.workStepsImage]: step.stepKey === checkStepKeys.photo,
  });
  const renderContent = () => {
    switch (step.stepKey) {
      case checkStepKeys.multiple:
        return <MultipleChoice />;
      case checkStepKeys.boolean:
        return <InspectionField />;
      case checkStepKeys.photo:
        return <ImagesField />;
      default:
        return <BaseField />;
    }
  };

  return <div className={classes}>{renderContent()}</div>;
};
