import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAppSelector, useConfirmRedirect } from '@hooks';
import { basePathChildren } from '@routes/paths';
import { BackLink, BaseForm, Loader } from '@shared/UI';
import { selectPageInfo } from '@store/selectors/extra/Local';
import cl from 'classnames';

import { CaseField, Container, Header, PreviewField } from './elements';

import type { Align } from '@interfaces/interfaces';
import type { BaseFormInterface, BaseFormProps } from '@shared/UI';
import type { FormInstance } from 'antd';
import type { ContainerProps, HeaderProps } from './elements';

import './CommonForm.scss';

export interface CommonFormProps<T = any, OnFinishData = T, Initial = T> {
  onFinish?: (values: OnFinishData, formInstance?: FormInstance<T>) => void;
  initialValues?: Partial<Initial>;
  header?: Omit<HeaderProps, 'deleteModal'>;
  deleteModal?: HeaderProps['deleteModal'];
  isModal?: boolean;
}

export type CommonPreviewProps<T = any> = {
  entity: T;
  onDelete?: () => void;

  header?: Omit<HeaderProps, 'deleteModal'>;
};

export interface DefaultCommonFormProps<T = any, D = T, I = T>
  extends CommonFormProps<T, D, I>,
    Omit<BaseFormProps<T>, 'onFinish' | 'initialValues' | 'validateMessages'> {
  container?: ContainerProps;
  loading?: boolean;
  isPreview?: boolean;
  isOutsidePreviewBack?: boolean;
  hiddenPreviewBack?: boolean;
  showBackIcon?: boolean;
  hiddenHeader?: boolean;
  additionalFooter?: React.ReactNode;
  alignAdditionalFooter?: Align;
  additionalHeader?: React.ReactNode;
  alignAdditionalHeader?: Align;

  canRedirect?: boolean;
}

interface CommonFormInterface extends BaseFormInterface {
  CaseField: typeof CaseField;
  PreviewField: typeof PreviewField;
}

interface DefaultCommonFormInterface extends CommonFormInterface {
  <T, D = T, I = T>(props: DefaultCommonFormProps<T, D, I>): React.ReactElement;
}

// Helper configs for CommonForm
const defaultClassPrefix = 'common-form';

const CommonForm: DefaultCommonFormInterface = (props) => {
  const {
    loading = false,
    header,
    children,
    container,
    isPreview = false,
    deleteModal,
    isOutsidePreviewBack = true,
    hiddenPreviewBack = false,
    hiddenHeader = false,
    showBackIcon = false,
    name,
    additionalFooter,
    canRedirect = false,
    alignAdditionalFooter = 'center',
    additionalHeader,
    alignAdditionalHeader = 'center',
    form,
    isModal,
    onFinish: onFinishProps,
    onValuesChange: onValuesChangeProps,
    ...rest
  } = props;

  const { t } = useTranslation();

  const { defaultPagePath } = useAppSelector(selectPageInfo);

  const { pathname } = useLocation();

  const [isChanged, setIsChanged] = useState(false);

  const isEditForm = pathname.indexOf(basePathChildren.edit) !== -1;

  const classNames = cl(defaultClassPrefix, {
    [`${defaultClassPrefix}--preview`]: isPreview,
  });

  /* --------------------------------- render back icon --------------------------------- */
  const previewBackIcon =
    (isPreview && !hiddenPreviewBack) || showBackIcon ? (
      <BackLink path={defaultPagePath} isOutsidePreviewBack={isOutsidePreviewBack} />
    ) : null;

  /* ------------------------------- Submit Form ------------------------------ */
  const onFinish = (values: any) => {
    setIsChanged(() => false);
    onFinishProps && onFinishProps(values, form);
  };

  const handleValueChange = (changedValue: any, values: any) => {
    if (!isChanged) {
      setIsChanged(true);
    }
    onValuesChangeProps?.(changedValue, values);
  };

  useConfirmRedirect(
    t('titles.Create_Form_Confirm_Redirect'),
    !isModal && !canRedirect && isChanged && pathname.includes(basePathChildren.create)
  );

  return (
    <>
      {previewBackIcon}

      <div className={classNames}>
        <Loader spinning={loading}>
          <BaseForm
            form={form}
            name={name}
            onFinish={onFinish}
            onValuesChange={handleValueChange}
            {...rest}
          >
            {!hiddenHeader && (
              <Header
                isEditForm={isEditForm}
                isPreview={isPreview}
                deleteModal={deleteModal}
                {...header}
              />
            )}
            <div
              className={cl(
                `${defaultClassPrefix}__additional-header`,
                `${defaultClassPrefix}__additional-header--${alignAdditionalHeader}`
              )}
            >
              {additionalHeader}
            </div>
            <Container {...container}>{children}</Container>
            <div
              className={cl(
                `${defaultClassPrefix}__additional-footer`,
                `${defaultClassPrefix}__additional-footer--${alignAdditionalFooter}`
              )}
            >
              {additionalFooter}
            </div>
          </BaseForm>
        </Loader>
      </div>
    </>
  );
};

CommonForm.Item = BaseForm.Item;
CommonForm.useForm = BaseForm.useForm;
CommonForm.List = BaseForm.List;
CommonForm.Provider = BaseForm.Provider;
CommonForm.useFormInstance = BaseForm.useFormInstance;
CommonForm.CaseField = CaseField;
CommonForm.PreviewField = PreviewField;
CommonForm.useWatch = BaseForm.useWatch;

export { CommonForm };
