import React from 'react';

const RoleAndAuthorizationIcon = () => (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0593 4.63224C15.571 4.63224 15.9858 4.21744 15.9858 3.70577C15.9858 3.19409 15.571 2.7793 15.0593 2.7793C14.5476 2.7793 14.1328 3.19409 14.1328 3.70577C14.1328 4.21744 14.5476 4.63224 15.0593 4.63224Z"
      fill="white"
    />
    <path
      d="M10.3414 12.0441H6.71875V8.42152L10.7535 4.38674C10.7404 4.26316 10.7337 4.13898 10.7335 4.01471C10.7335 3.22067 10.9689 2.44447 11.4101 1.78426C11.8512 1.12404 12.4782 0.609467 13.2118 0.305603C13.9454 0.00173952 14.7526 -0.077765 15.5314 0.0771432C16.3102 0.232052 17.0255 0.614415 17.587 1.17588C18.1485 1.73735 18.5308 2.4527 18.6857 3.23148C18.8406 4.01025 18.7611 4.81748 18.4573 5.55107C18.1534 6.28466 17.6388 6.91167 16.9786 7.35281C16.3184 7.79395 15.5422 8.02941 14.7482 8.02941C14.6239 8.02917 14.4997 8.02247 14.3761 8.00934L10.3414 12.0441ZM8.05698 10.7059H9.78732L13.9158 6.57743L14.2624 6.64099C14.4224 6.67273 14.585 6.68953 14.7482 6.69118C15.3078 6.69806 15.8558 6.5315 16.317 6.21437C16.7782 5.89724 17.1298 5.4451 17.3237 4.92005C17.5175 4.39501 17.5441 3.82283 17.3996 3.2821C17.2552 2.74138 16.9469 2.25864 16.517 1.9002C16.0872 1.54176 15.5569 1.3252 14.999 1.28027C14.4411 1.23535 13.883 1.36426 13.4014 1.6493C12.9197 1.93433 12.5381 2.36152 12.309 2.87216C12.0799 3.3828 12.0145 3.95186 12.1219 4.50116L12.1854 4.84709L8.05698 8.97554V10.7059Z"
      fill="white"
    />
    <path
      d="M17.4403 9.1875V14.4375H5.52849V1.3125H8.17555V0H1.5579C1.20699 0.000347469 0.870549 0.13874 0.622415 0.384806C0.374281 0.630872 0.234725 0.96451 0.234375 1.3125V14.4375C0.234725 14.7855 0.374281 15.1191 0.622415 15.3652C0.870549 15.6113 1.20699 15.7497 1.5579 15.75H17.4403C17.7912 15.7497 18.1276 15.6113 18.3757 15.3652C18.6239 15.1191 18.7634 14.7855 18.7638 14.4375V9.1875H17.4403ZM1.5579 1.3125H4.20496V14.4375H1.5579V1.3125Z"
      fill="white"
    />
  </svg>
);

export { RoleAndAuthorizationIcon };
