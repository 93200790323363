import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getVendorById } from '@store/actions/properties/Vendor';
import { selectVendor } from '@store/selectors/properties/Vendor';

import type { IOutletVendor } from '@constants/OutletContextEntityPages';
import type { CreateVendorDto } from '@model/properties/Vendor';

function Vendor() {
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();
  const vendor = useAppSelector(selectVendor);

  const condition = !vendor || vendor.partnerId !== +decryptedId;

  useSetPageInfo({
    pageTitle: 'pages_single.Vendor',
    defaultPagePath: paths.vendor.default,
  });

  useEffect(() => {
    if (condition) {
      dispatch(getVendorById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateVendorDto = {
    name: vendor.name,
    partnerCode: vendor.partnerCode,
    cityId: vendor.cityId,
    countryId: vendor.city.countryId,
    address: vendor.address,
    description: vendor.description,
    phone: vendor.phone,
    taxNumber: vendor.taxNumber,
    taxOffice: vendor.taxOffice,
  };

  return <Outlet context={{ initialValues, entity: vendor } as IOutletVendor} />;
}

export default Vendor;
