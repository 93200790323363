import type { AssetModel, BatchUpdateAssetsDto } from '@model/management/Asset';

type IdsParam = Partial<Omit<BatchUpdateAssetsDto, 'nonCurrAssetIds'>>;

export function getBatchAssetDTO(
  checkedAssets: Array<AssetModel>,
  ids: IdsParam
): BatchUpdateAssetsDto {
  const defaultIds = {
    assigneeUserId: null,

    statusId: null,

    checkFactorId: null,

    siteId: null,

    ...ids,
  };

  return {
    nonCurrAssetIds: checkedAssets.map((asset) => asset.nonCurrAssetId),
    ...defaultIds,
  };
}
