import { defaultFormatDate } from '@constants/Date';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import type { RootState } from '@store/rootReducer';

export const selectMaintenanceState = (state: RootState) => state.MaintenanceReducer;

export const selectMaintenancesList = (state: RootState) =>
  selectMaintenanceState(state).maintenancesList;

export const selectMaintenanceOccurrencesList = (state: RootState) =>
  selectMaintenanceState(state).maintenanceOccurrencesList;

export const selectMaintenancesListWithFormattedDate = createSelector(
  selectMaintenancesList,
  (maintenancesList) => {
    return maintenancesList.map((maintenance) => ({
      ...maintenance,
      dueDate: maintenance.dueDate ? moment(maintenance.dueDate).format(defaultFormatDate) : '',
    }));
  }
);

export const selectMaintenanceStatus = (state: RootState) =>
  selectMaintenanceState(state).maintenanceStatus;

export const selectMaintenanceError = (state: RootState) =>
  selectMaintenanceState(state).maintenanceError;

export const selectMaintenance = (state: RootState) => selectMaintenanceState(state).maintenance;

export const selectMaintenanceViewType = (state: RootState) =>
  selectMaintenanceState(state).maintenanceViewType;

export const selectMaintenanceHistoryList = (state: RootState) => 
  selectMaintenanceState(state).historyList;

export const selectFilterHistoryListOptions = (state: RootState) => 
  selectMaintenanceState(state).filterHistoryListOptions;

export const selectTotalHistoryRecords = (state: RootState) => 
  selectMaintenanceState(state).totalHistoryRecords;