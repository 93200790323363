import * as React from 'react';

import type { SVGProps } from 'react';

export const TakePhotoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none" {...props}>
    <path
      fill="#E0E0E0"
      d="M17.57 10.313V8.998h-1.314V7.682h1.315V6.367h1.315v1.315h1.315v1.316h-1.315v1.315h-1.315ZM7.05 19.519c-.362 0-.672-.13-.93-.387a1.264 1.264 0 0 1-.386-.928v-7.891c0-.362.13-.672.387-.93.258-.257.567-.386.929-.385h2.07l1.217-1.316h3.946v1.316h-3.37l-1.2 1.315H7.05v7.89h10.52v-5.918h1.316v5.919c0 .361-.129.671-.387.929a1.264 1.264 0 0 1-.928.386H7.05Zm5.26-2.302c.822 0 1.521-.287 2.097-.863a2.851 2.851 0 0 0 .862-2.096c0-.822-.287-1.52-.863-2.096a2.851 2.851 0 0 0-2.096-.863c-.822 0-1.52.288-2.096.864a2.851 2.851 0 0 0-.863 2.095c0 .822.288 1.52.863 2.097a2.851 2.851 0 0 0 2.096.862Zm0-1.315c-.46 0-.85-.159-1.167-.477a1.588 1.588 0 0 1-.477-1.167c0-.46.16-.85.477-1.167a1.587 1.587 0 0 1 1.167-.477c.46 0 .85.16 1.167.477.318.318.477.707.477 1.167 0 .46-.159.85-.477 1.167a1.587 1.587 0 0 1-1.167.477Z"
    />
  </svg>
);
