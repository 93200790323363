import type { RootState } from '@store/rootReducer';

export const selectPaIStatusState = (state: RootState) => state.PaIStatusReducer;

export const selectPaIStatusesList = (state: RootState) =>
  selectPaIStatusState(state).PaIStatusesList;

export const selectPaIStatus = (state: RootState) => selectPaIStatusState(state).PaIStatus;

export const selectPaIStatusStatus = (state: RootState) =>
  selectPaIStatusState(state).PaIStatusStatus;

export const selectPaIStatusError = (state: RootState) =>
  selectPaIStatusState(state).PaIStatusError;
