import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormPartAndInventory } from '@components/views/Forms/managment';
import { CheckAccess } from '@components/wrappers';
import { updateNotificationSuccess } from '@constants/notificationMessages';
import { useOutletPartAndInventory } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { notificationController } from '@services/Notifications';
import { updatePaI } from '@store/actions/management/PaI';

import type { CreatePaIDto } from '@model/management/PaI';

function EditPartAndInventory() {
  const { initialValues, entity } = useOutletPartAndInventory();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { paiAccess } = useGetAccess();

  const onFinish = (dto: CreatePaIDto) => {
    
    dispatch(
      updatePaI({
        ...dto,
        partsAndInventoriesId: entity.partsAndInventoriesId,
      })
    )
      .unwrap()
      .then((response) => {
        notificationController.success({
          description: updateNotificationSuccess(
            response.particularPartAndInventory.partsAndInventoriesCard.partsAndInventoriesCardName
          ),
        });
        navigate(paths.partAndInventory.default);
      })
      .catch((err) => {
        if (typeof err === 'string') {
          notificationController.error({
            description: err,
          });
        }
      });
  };

  return (
    <CheckAccess hasAccess={paiAccess.edit} redirectTo={paths.partAndInventory.default}>
      <FormPartAndInventory
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.partsAndInventoriesCard.partsAndInventoriesCardName,
        }}
      />
    </CheckAccess>
  );
}

export default EditPartAndInventory;
