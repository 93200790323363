/* eslint-disable @typescript-eslint/no-unused-vars */
import {v4 as uuidv4} from 'uuid';

import type {
  MultiChoiceForWorkStepModel,
  WorkStepResultModel,
} from '@model/management/Maintenance';
import type {CheckStepItemOption} from '../../types';

export const mapWorkStepResultOptionToCheckStepOptions = (
  workStepsOptions: MultiChoiceForWorkStepModel[],
  workStepResult: WorkStepResultModel[]
) => {
  return workStepsOptions.map((option): CheckStepItemOption => {
    let value = '';
    let checked = false;
    if (
      workStepResult[0] &&
      workStepResult[0].resultText &&
      workStepResult[0].resultText === option.optionText
    ) {
      value = workStepResult[0].resultText;
      checked = true;
    } else {
      value = option.optionText;
    }

    return {
      uuid: uuidv4(),
      title: option.optionText,
      rowId: option.rowId,
      checked,
      hasError: false,
      isUpdated: false,
      isNew: false,
      isRemoved: false,
      externalId: option.multipleChoiceListForWorkStepsId,
      value,
    };
  });
};
