import React from 'react';

import { TrashCan } from '@icon/icon-components';
import { createUrlObject } from '@shared/utils/functions';
import cl from 'classnames';

import './ImageContainer.scss';

export interface ImageContainerProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  src?: string | File;
  className?: string;

  canDelete?: boolean;
  onClickDelete?: () => void;

  onClickImage?: () => void;

  isDraggable?: boolean;
}

const ImageContainer = React.forwardRef<HTMLDivElement, ImageContainerProps>((props, ref) => {
  const { src, className, canDelete = false, onClickDelete, onClickImage, ...rest } = props;

  const ImageContainerClasses = cl('image-container', className, {
    'image-container__empty-image': !src,
  });

  const content = () => {
    return (
      <>
        {canDelete && (
          <span className="image-container-delete" onClick={onClickDelete}>
            <TrashCan />
          </span>
        )}
        {src ? (
          <span className="image-container-image" onClick={onClickImage}>
            <img src={typeof src === 'string' ? src : createUrlObject(src)} alt="image" />
          </span>
        ) : null}
      </>
    );
  };

  return (
    <div ref={ref} className={ImageContainerClasses} {...rest}>
      {content()}
    </div>
  );
});

export { ImageContainer };
