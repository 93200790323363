import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldSearchIcon } from '@icon/icon-components';
import { Input } from 'antd';
import cl from 'classnames';

import type { InputProps } from 'antd';

import './Search.scss';

export interface SearchProps extends InputProps {}

export const Search = memo((props: SearchProps) => {
  const { className, ...restProps } = props;

  const { t } = useTranslation();

  const classes = cl('custom-search-field', className);

  return (
    <Input
      className={classes}
      prefix={<FieldSearchIcon />}
      placeholder={t('titles.Search')}
      bordered={false}
      allowClear
      {...restProps}
    />
  );
});
