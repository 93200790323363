import { KindOfCheckStep } from '@constants/kindOfCheckStep';
import { v4 as uuidv4 } from 'uuid';

import type { CheckStepValue, MultiplyCheckStepItem } from '@constants/checkStepItems';
import type {
  CreateCheckStepDto,
  MaAtCheckStepModel,
  UpdateCheckStepDto,
} from '@model/properties/MaAt/Checklist';

// region --- Maintenance Attributes CheckSteps

export function adaptCheckStepsToMaintenanceAttrCheckSteps(
  checkSteps: CheckStepValue[],
  ownerId: number,
  userId: number
): (CreateCheckStepDto | UpdateCheckStepDto)[] {
  if (checkSteps.length === 0) return [];

  return checkSteps.map((checkStep, idx) => {
    const checkStepType = getTypeStep(checkStep.type);
    // if checkStep has entityId then need to update it
    if (checkStep.entityId) {
      // create default values for UpdateCheckStepDto
      const defaultUpdateValue: UpdateCheckStepDto = {
        checkStepsId: checkStep.entityId,
        stepTypeId: checkStepType,
        checklistId: ownerId,
        stepName: checkStep.title,
        rowId: idx + 1,
        mcList: [],
      };
      // check if the checkStep has type MultiplyChoice
      if (checkStep.type === KindOfCheckStep.MultiplyChoice) {
        const mcList = adaptRadiosForCheckStepMcList(checkStep.radios, ownerId, checkStep.entityId);

        // update multiple choice checkStep
        return {
          ...defaultUpdateValue,
          mcList,
        } as UpdateCheckStepDto;
      }
      // update other checkStep
      return defaultUpdateValue;
    }
    // else need to create it
    // create default value for CreateCheckStepDto
    const defaultCreateValue: CreateCheckStepDto = {
      stepTypeId: checkStepType,
      checklistId: ownerId,
      stepName: checkStep.title,
      rowId: idx + 1,
      loggedUserId: userId,
      mcList: [],
    };

    // create checkStep with MultiplyChoice type
    if (checkStep.type === KindOfCheckStep.MultiplyChoice) {
      const mcList = adaptRadiosForCheckStepMcList(checkStep.radios, ownerId);

      return {
        ...defaultCreateValue,
        mcList,
      } as CreateCheckStepDto;
    }

    return defaultCreateValue;
  });
}

function adaptRadiosForCheckStepMcList(
  radios: MultiplyCheckStepItem['radios'],
  ownerId: number,
  checkStepId?: number
): (UpdateCheckStepDto['mcList'][number] | CreateCheckStepDto['mcList'][number])[] {
  const values = Object.values(radios);

  if (values.length === 0) return [];

  return values.map((radioOption, optionIdx) => {
    // if radioOption has id then need to update it
    if (radioOption.id !== undefined && checkStepId !== undefined) {
      return {
        checklistId: ownerId,
        checkStepsId: checkStepId,
        multipleChoiceListId: radioOption.id,
        multipleCheckListRowId: optionIdx + 1,
        multipleCheckListOptionText: radioOption.value,
      };
    }
    // else need to create it
    return {
      multipleCheckListRowId: optionIdx + 1,
      multipleCheckListOptionText: radioOption.value,
    };
  });
}

export function adaptMaintenanceAttrCheckStepsToCheckSteps(
  checkSteps: MaAtCheckStepModel[],
  withID = true
): CheckStepValue[] {
  return checkSteps.map((step) => {
    const { checkStepsId: entityId, checkSteps } = step;
    const { stepName: title, stepTypeId, multipleChoiceLists } = checkSteps;

    const type = getKindOfCheckStep(stepTypeId);
    const defaultCheckStep = withID
      ? {
          entityId,
          uuid: uuidv4(),
          title,
          isFocus: false,
          type,
        }
      : {
          uuid: uuidv4(),
          title,
          isFocus: false,
          type,
        };

    if (type === KindOfCheckStep.MultiplyChoice) {
      const radios = multipleChoiceLists.reduce((acc: MultiplyCheckStepItem['radios'], radio) => {
        const { multipleChoiceListId: id, optionText: value } = radio;

        return {
          ...acc,
          [uuidv4()]: withID
            ? {
                id,
                value,
              }
            : { value },
        };
      }, {});
      return {
        ...defaultCheckStep,
        radios,
        type,
      };
    } else if (type === KindOfCheckStep.Integer || type === KindOfCheckStep.Decimal) {
      return {
        ...defaultCheckStep,
        value: 0,
        type,
      };
    } else if (type === KindOfCheckStep.Inspection) {
      return {
        ...defaultCheckStep,
        value: false,
        type,
      };
    } else if (type === KindOfCheckStep.Photo) {
      return {
        ...defaultCheckStep,
        value: [],
        type,
      };
    } else {
      return {
        ...defaultCheckStep,
        value: '',
        type,
      };
    }
  });
}

// endregion --- Maintenance Attributes CheckSteps

// region --- Maintenance Work Orders

// endregion --- Maintenance Work Orders

// region --- Default adapters
function getTypeStep(type: KindOfCheckStep | number) {
  switch (type) {
    case KindOfCheckStep.Text:
      return 1;
    case KindOfCheckStep.MultiplyChoice:
      return 2;
    case KindOfCheckStep.Integer:
      return 3;
    case KindOfCheckStep.Decimal:
      return 4;
    case KindOfCheckStep.Inspection:
      return 5;
    case KindOfCheckStep.Photo:
      return 7;
    default:
      return 1;
  }
}

function getKindOfCheckStep(type: number): KindOfCheckStep {
  switch (type) {
    case 1:
      return KindOfCheckStep.Text;
    case 2:
      return KindOfCheckStep.MultiplyChoice;
    case 3:
      return KindOfCheckStep.Integer;
    case 4:
      return KindOfCheckStep.Decimal;
    case 5:
      return KindOfCheckStep.Inspection;
    case 7:
      return KindOfCheckStep.Photo;
    default:
      return KindOfCheckStep.Text;
  }
}

// endregion --- Default adapters
