import React, { memo } from 'react';

import { Search } from '@shared/UI';

import type { ChangeEvent} from 'react';

export type SearchItemProps = {
  searchQuery?: string;
  onChangeSearch?: (query: string) => void;
};

export const SearchItem = memo((props: SearchItemProps) => {
  const { searchQuery, onChangeSearch } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChangeSearch?.(value);
  };

  return <Search value={searchQuery} onChange={handleChange} />;
});
