import { Button } from '@shared/UI';

import './ModalFormFooter.scss';

interface ModalFormFooterProps {
  onBack?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  onContinue?: () => void;
  currentStep?: number;
}

const ModalFormFooter = (props: ModalFormFooterProps) => {
  const { onContinue, onBack, onSave, currentStep, onCancel } = props;

  const simpleFooter = !currentStep && (
    <>
      <Button onClick={onCancel}>Cancel</Button>
      <Button type="primary" htmlType="submit">
        Save
      </Button>
    </>
  );

  const stepFooter = currentStep && (
    <>
      <Button onClick={onBack}>Back</Button>
      <Button type="primary" onClick={onSave}>
        Save
      </Button>
    </>
  );

  return currentStep && currentStep < 2 ? (
    <div className="modal-form-btn">
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onContinue} type="primary">
        Continue
      </Button>
    </div>
  ) : (
    <div className="modal-form-btn">
      {simpleFooter}
      {stepFooter}
    </div>
  );
};

export { ModalFormFooter };
