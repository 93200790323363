import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectSiteStatus } from '@store/selectors/properties/Site';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { TitleModel } from '@model/users/Title';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormTitle(props: CommonPreviewProps<TitleModel>) {
  const { entity, onDelete } = props;

  const { t } = useTranslation();

  const statusSite = useAppSelector(selectSiteStatus);

  return (
    <CommonForm
      isPreview
      loading={statusSite === 'pending'}
      header={{
        title: entity.title,
        onClickDelete: onDelete,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.userTitleCode}</PreviewField>
        </Item>
        <Item label={t('titles.Title')}>
          <PreviewField>{entity.title}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormTitle };
