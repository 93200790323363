import { useContext } from 'react';

import { AuthContext } from './AuthProvider';

const useAuth = () => {
  const ctx = useContext(AuthContext);
  if (ctx === undefined || ctx === null) {
    throw new Error('useAuth must be inside a Provider with a value');
  }
  return ctx;
};

export { useAuth };
