import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getPaICards } from '@store/actions/properties/PaI/Card';
import { selectPaICardsList } from '@store/selectors/properties/PaI/Card';

function EntryPartAndInventoryName() {
  useSetPageInfo(
    {
      pageTitle: 'pages_single.PaI_Attr',
      defaultPagePath: paths.partAndInventoryAttributes.tabs.name.default,
    },
    {
      copyTabs: true,
    }
  );

  const partAndInventoryNameList = useAppSelector(selectPaICardsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!partAndInventoryNameList.length) {
      dispatch(getPaICards());
    }
  }, []);

  return <Outlet />;
}

export default EntryPartAndInventoryName;
