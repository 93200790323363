import React from 'react';

import {Arrow} from '@icon/icon-components';
import {add, endOfWeek, format, startOfMonth, startOfWeek, sub} from 'date-fns';

import type {CalendarMode} from '../ModeSelect';

import './DateSwitcher.scss';

interface DateSwitcherProps {
  onChange: (date: Date) => void;
  mode: CalendarMode;
  value: Date;
}

const getNewDate = (date: Date, mode: CalendarMode, type: 'prev' | 'next') => {
  const newDate = type === 'prev' ? sub(date, {[mode]: 1}) : add(date, {[mode]: 1});
  // if we have months mode then we set the first day of the month
  if (mode === 'months') {
    return startOfMonth(newDate);
  }

  if (type === 'next' && mode === 'weeks') {
    return endOfWeek(newDate)
  }

  return startOfWeek(newDate);
};

const DateSwitcher = (props: DateSwitcherProps) => {
  const {mode, value, onChange} = props;

  const defaultClass = 'date-switcher';

  const onClickPrev = () => {
    const date = getNewDate(value, mode, 'prev');

    onChange(date);
  };

  const onClickNext = () => {
    const date = getNewDate(value, mode, 'next');
    onChange(date);
  };

  return (
    <div className={defaultClass}>
      <button
        onClick={onClickPrev}
        className={`${defaultClass}-button-prev ${defaultClass}-button`}
      >
        <Arrow/>
      </button>
      <div className={`${defaultClass}-value-section`}>
        <span>{format(value, 'MMMM yyyy')}</span>
      </div>
      <button
        onClick={onClickNext}
        className={`${defaultClass}-button-next ${defaultClass}-button`}
      >
        <Arrow/>
      </button>
    </div>
  );
};

export {DateSwitcher};
