import React from 'react';
import { Translation } from 'react-i18next';

import { Label } from '@shared/UI';

import type { ColumnsTableType } from '@components/common';
import type { PaITableModel, PaITypeNAMEModel } from '@model/management/PaI';

export const columnsByPO: ColumnsTableType<PaITableModel> = [
  {
    title: <Translation>{(t) => t('titles.PO')}</Translation>,
    dataIndex: 'purchaseNumber',
    key: '3',
    ellipsis: true,
    sorter: true,
  },
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'partsAndInventoriesCardName',
    key: '2',
    ellipsis: true,
    sorter: true,
  },
  {
    title: <Translation>{(t) => t('titles.Category')}</Translation>,
    dataIndex: 'partsAndInventoriesCategoryName',
    key: '1',
    ellipsis: true,
    sorter: true,
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: 'partsAndInventoriesSiteName',
    key: 'site',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Unit')}</Translation>,
    dataIndex: 'partsAndInventoriesUnitName',
    key: 'unit',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Quantity')}</Translation>,
    dataIndex: 'quantity',
    key: 'quantity',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Status')}</Translation>,
    dataIndex: 'statusName',
    key: '4',
    ellipsis: true,
    sorter: true,
  },
  {
    title: <Translation>{(t) => t('titles.Label')}</Translation>,
    dataIndex: 'statusName',
    key: '4',
    ellipsis: true,
    render: (_, { labels }) => {
      return (
        <>
          {labels && labels.length
            ? labels.map((label) => {
                return (
                  <Label key={label.labelId} color={label.labelColor}>
                    {label.label}
                  </Label>
                );
              })
            : null}
        </>
      );
    },
  },
];

export const columnsByName: ColumnsTableType<PaITypeNAMEModel> = [
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'partsAndInventoriesCardName',
    key: '2',
    ellipsis: true,
    sorter: true,
  },
  {
    title: <Translation>{(t) => t('titles.Category')}</Translation>,
    dataIndex: 'partsAndInventoriesCategoryName',
    key: '1',
    ellipsis: true,
    sorter: true,
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: 'partsAndInventoriesSiteName',
    key: 'site',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Unit')}</Translation>,
    dataIndex: 'partsAndInventoriesUnitName',
    key: 'unit',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Quantity')}</Translation>,
    dataIndex: 'sumQuantity',
    key: 'quantity',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Remaining')}</Translation>,
    dataIndex: 'remainingQuantity',
    key: 'remaining',
    ellipsis: true,
  },
  {
    title: <Translation>{(t) => t('titles.Label')}</Translation>,
    dataIndex: 'lastLabel',
    key: 'label',
    ellipsis: true,
    render: (_, record) =>
      record.lastLabel && record.lastLabelColor ? (
        <Label color={record.lastLabelColor}>{record.lastLabel}</Label>
      ) : null,
  },
];
