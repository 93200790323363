import React from 'react';
import { useTranslation } from 'react-i18next';

import { baseFormatDate } from '@constants/Date';
import { format } from 'date-fns';

import type { MaintenanceModel, MaintenanceScheduleModel } from '@model/management/Maintenance';

import './CellContent.scss';

interface CellContentProps {
  schedule: MaintenanceScheduleModel & {
    title: string;
    priority: string;
    site: string | null;
    assignTo: string | null;
    maintenance: MaintenanceModel | null;
  };
}

const CellContent = (props: CellContentProps) => {
  const { schedule } = props;

  const { t } = useTranslation();

  const defClass = 'maintenance-kanban-cell';

  const isRouting = (nonCurrAssetId: any) => {
    if (!nonCurrAssetId)
      return <span className="routing"> {t('titles.Maintenance_Routing')} </span>;
  };

  return (
    <div className={defClass}>
      <div className={`${defClass}-header`}>
        <span>{schedule.title}</span>
        {isRouting(schedule.maintenance?.nonCurrAssetId)}
      </div>
      <div className={`${defClass}-content`}>
        <div className={`${defClass}-info`}>
          <span>{t('titles.Assign_To')}:</span>
          <span>{schedule.assignTo}</span>
        </div>
        <div className={`${defClass}-info`}>
          <span>{t('titles.Priority')}:</span>
          <span>{schedule.priority}</span>
        </div>
        <div className={`${defClass}-info`}>
          <span>{t('titles.Maintenance_Date')}:</span>
          <span>{format(new Date(schedule.maintenanceDate), baseFormatDate)}</span>
        </div>
        <div className={`${defClass}-info`}>
          <span>{t('titles.Site')}:</span>
          <span>{schedule.site}</span>
        </div>
      </div>
    </div>
  );
};

export { CellContent };
