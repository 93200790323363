import React from 'react';
import { Trans } from 'react-i18next';

import { Header } from '../Header';

import './EmptyDataPage.scss';

export interface EmptyDataPageProps {
  dataSourceName: string;
}

interface DefaultEmptyDataPageProps extends EmptyDataPageProps {
  description?: React.ReactNode;
  titleRedirectButton: string;
}

function EmptyDataPage(props: DefaultEmptyDataPageProps) {
  const { titleRedirectButton, description, dataSourceName } = props;

  const defaultDescription = (
    <div className="empty-data-page-description">
      <h6>
        <Trans i18nKey="common.empty_description">
          You don't have {{ name: dataSourceName }} yet <br /> Click the button and create a
          {{ buttonName: titleRedirectButton }}
        </Trans>
      </h6>
    </div>
  );

  return (
    <div className="empty-data-page">
      <Header
        titleRedirectButton={titleRedirectButton}
        onlyRedirectButton
        showTitleHeader={false}
      />
      {description ? description : defaultDescription}
    </div>
  );
}

export { EmptyDataPage };
