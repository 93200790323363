import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, ModalFormFooter, ModalFormHeader, SelectCountryAndCity } from '@components/common';
import { phoneNumberPattern } from '@constants/regex';
import { useAppSelector } from '@hooks';
import { Divider, Input } from '@shared/UI';
import { selectVendorStatus } from '@store/selectors/properties/Vendor';

import type { CommonFormProps } from '@components/common/CommonForm';
import type { CreateVendorDto } from '@model/properties/Vendor';

const { CaseField, Item, useForm } = CommonForm;

const FormVendor = (props: CommonFormProps<CreateVendorDto>) => {
  const [form] = useForm<CreateVendorDto>();

  const { isModal, ...rest } = props;

  const { t } = useTranslation();

  const vendorStatus = useAppSelector(selectVendorStatus);

  const [ currentStep, setCurrentStep ] = useState(1);

  function onContinue() {
    form.validateFields(['name', 'partnerCode'])
      .then(() => {
        setCurrentStep(2);
      })
      .catch(() => {})
  }

  function onSave() {
    form.submit()
  }

  function onBack() {
    setCurrentStep(1)
  }

  return (
    <CommonForm 
      form={form} 
      loading={vendorStatus === 'pending'}
      isModal={isModal}
      name='formVendor'
      hiddenHeader={isModal}
      additionalFooter={
        isModal ?
        <ModalFormFooter
          onContinue={onContinue}
          onBack={onBack}
          onSave={onSave}
          onCancel={rest.header?.onClickCancel}
          currentStep={currentStep}
        /> : null
      }
      additionalHeader={
        isModal ?
        <ModalFormHeader 
          step={currentStep}
          title={rest.header?.title as string} />
        : null
      }
      {...rest}
    >
      <CaseField title={t('titles.Summary')} isModal={isModal} hidden={currentStep === 2 && isModal}>
        <Item
          label={t('titles.Name')}
          name={'name'}
          rules={[
            {
              required: true,
              max: 100,
            },
          ]}
        >
          <Input placeholder={t('titles.Name')} />
        </Item>
        <Item
          label={t('titles.Code')}
          name={'partnerCode'}
          rules={[
            {
              required: true,
            },
            {
              min: 1,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t('titles.Code')} />
        </Item>
      </CaseField>

      <CaseField isModal={isModal} hidden={currentStep === 2 && isModal}>
        <Item
          label={t('titles.Tax_Office')}
          name={'taxOffice'}
          rules={[
            {
              max: 50,
            },
          ]}
        >
          <Input placeholder={t('titles.Tax_Office')} />
        </Item>
        <Item
          label={t('titles.TXN')}
          name={'taxNumber'}
          rules={[
            {
              max: 10,
            },
          ]}
        >
          <Input placeholder={t('titles.TXN')} />
        </Item>
      </CaseField>
      <Divider style={isModal ? {display: 'none'} : {}} />
      <CaseField title={t('titles.Location')} isModal={isModal} hidden={currentStep === 1 && isModal}>
        <SelectCountryAndCity />
        <Item
          label={t('titles.Address')}
          name={'address'}
          rules={[
            {
              required: true,
            },
            {
              max: 200,
            },
          ]}
        >
          <Input placeholder={t('titles.Address')} />
        </Item>
      </CaseField>
      <CaseField title={t('titles.Contacts')} isModal={isModal} hidden={currentStep === 1 && isModal}>
        <Item
          label={t('titles.Email')}
          name={'contactName'}
          rules={[
            {
              type: 'email',
            },
            {
              max: 50,
            }
          ]}
        >
          <Input placeholder={t('titles.Email')} />
        </Item>
        <Item
          label={t('titles.Phone_Num')}
          name={'phone'}
          rules={[
            {
              required: true,
            },
            {
              pattern: phoneNumberPattern,
              message: t('errors.Not_Valid', { name: t('titles.Phone_Num') }),
            },
            {
              max: 15,
            },
            {
              min: 12,
            }

          ]}
        >
          <Input placeholder={t('titles.Phone_Num')} />
        </Item>
      </CaseField>
    </CommonForm>
  );
};

export { FormVendor };
