import type { StepMcList } from '@model/management/Maintenance';
import type { ActionFunction, CheckStepItemOption } from '../../types';

export const mapCheckStepOptionsToWorkStepOptions: ActionFunction<
  { options: CheckStepItemOption[]; stepExternalId?: number },
  StepMcList[]
> = ({ options, stepExternalId }) => {
  const dto: StepMcList[] = options.map((option) => ({
    multipleCheckListOptionText: option.title,
    multipleCheckListRowId: option.rowId,
    workStepsId: stepExternalId,
    multipleChoiceListForWorkStepsId: option.externalId,
  }));

  return dto;
};
