import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassPrefix } from '@hooks';
import { Switch } from '@shared/UI';

import { useRoleAuthContext } from '../RoleAuthProvider';
import { SubRolesList } from '../SubRolesList';

import type { RoleFunctionNameModel } from '@model/users/Role';

import './RoleItem.scss';

interface RoleItemProps {
  authItem: RoleFunctionNameModel;
}

export const RoleItem = memo((props: RoleItemProps) => {
  const { authItem } = props;

  const { prefix, withPrefix } = useClassPrefix('role-auth-item');

  const { isPreview, onChange } = useRoleAuthContext();

  const { t } = useTranslation();

  const handleChange = (checked: boolean) => {
    const updatedItem: RoleFunctionNameModel = {
      ...authItem,
      value: checked,
      childrens: authItem.childrens.map((child) => ({
        ...child,
        value: checked,
      })),
    };

    onChange(updatedItem);
  };

  const handleChangeChild = (checked: boolean, id: number) => {
    const updatedChildren = authItem.childrens.map((child) =>
      child.roleFunctionNameId === id
        ? {
            ...child,
            value: checked,
          }
        : child
    );
    const isCheckedAll = updatedChildren.every((child) => child.value);
    const isUncheckedAll = updatedChildren.every((child) => !child.value);
    const isCheckedPartial = updatedChildren.some((child) => !child.value);
    let updatedParent = { ...authItem, childrens: updatedChildren };

    if (isCheckedAll && !authItem.value) {
      updatedParent = {
        ...updatedParent,
        value: true,
      };
    } else if (isUncheckedAll && !authItem.value) {
      updatedParent = {
        ...updatedParent,
        value: false,
      };
    } else if (isUncheckedAll) {
      updatedParent = {
        ...updatedParent,
        value: false,
      };
    } else if (isCheckedPartial && !authItem.value) {
      updatedParent = {
        ...updatedParent,
        value: true,
      };
    }

    onChange(updatedParent);
  };
  return (
    <>
      <li className={prefix}>
        <div className={withPrefix('title')}>
          <p className={withPrefix('name')}>{t(`titles.${authItem.roleFunctionKeywordName}`)}</p>
          <Switch
            disabled={isPreview}
            checked={Boolean(authItem.value)}
            size="small"
            className={withPrefix('switcher')}
            onChange={handleChange}
          />
        </div>
      </li>
      <SubRolesList
        onChangeChild={handleChangeChild}
        authorizationsChildrenList={authItem.childrens}
      />
    </>
  );
});
