import React, { useCallback, useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppDispatch, useAppSelector, useGetAccess } from '@hooks';
import { Select } from '@shared/UI';
import { checkFilterValues, compare } from '@shared/utils/functions';
import {
  getDepartments,
  getDepartmentsByFilter,
  updateDepartmentParentRelations,
} from '@store/actions/properties/Department';
import {
  selectDepartmentFilters,
  selectDepartmentsFilteredList,
  selectDepartmentsList,
  selectDepartmentsListWithoutChildren,
  selectDepartmentStatus,
} from '@store/selectors/properties/Department';

import type { ColumnsTableType, DragEnd } from '@components/common';
import type { DepartmentModel, FilterDepartmentDto } from '@model/properties/Department';

const columns: ColumnsTableType<DepartmentModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'departmentCode',
    key: 'departmentCode',
    ellipsis: true,
    sorter: (a, b) => compare(a.departmentCode, b.departmentCode),
  },
  {
    title: <Translation>{(t) => t('titles.Name')}</Translation>,
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => compare(a.name, b.name),
  },
  {
    title: <Translation>{(t) => t('titles.Site')}</Translation>,
    dataIndex: ['site', 'name'],
    key: 'siteName',
    ellipsis: true,
    sorter: (a, b) => compare(a.site.name, b.site.name),
  },
];

function Departments() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { departmentAccess } = useGetAccess();

  const departments = useAppSelector(selectDepartmentsList);
  const departmentListWithoutChildren = useAppSelector(selectDepartmentsListWithoutChildren);
  const filteredListDepartment = useAppSelector(selectDepartmentsFilteredList);
  const statusDepartment = useAppSelector(selectDepartmentStatus);
  const filtersDepartment = useAppSelector(selectDepartmentFilters);

  const data = useCallback(
    (departments: DepartmentModel[]) => {
      return departments.map(
        ({ childDepartment, ...department }): DepartmentModel => ({
          ...department,
          childDepartment: childDepartment?.length ? data(childDepartment) : undefined,
        })
      );
    },
    [departments]
  );

  const listDepartment = useMemo(() => data(departments), [departments]);

  const updateParent = (data: DragEnd<DepartmentModel>) => {
    dispatch(
      updateDepartmentParentRelations({
        fromDepartmentIds: [data.draggingRecordId],
        toDepartmentId: data.dropId,
      })
    );
  };

  const getDefaultList = useCallback(() => {
    dispatch(getDepartments());
  }, []);

  const onSubmitFilter = (values: FilterDepartmentDto) => {
    if (checkFilterValues(values)) {
      getDefaultList();
      return;
    }

    dispatch(getDepartmentsByFilter(values));
  };

  const csvData = useMemo(
    () =>
      departmentListWithoutChildren.map((department) => {
        const parentName = departmentListWithoutChildren.find(
          (d) => d.departmentId === department.parentDepartmentId
        )?.name;
        return {
          name: department.name,
          departmentCode: department.departmentCode,
          siteName: department.site.name,
          parentDepartmentName: parentName || '----',
        };
      }),
    [departmentListWithoutChildren]
  );

  const dataSource = useMemo(
    () => (Boolean(filteredListDepartment.length) ? filteredListDepartment : listDepartment),
    [filteredListDepartment, listDepartment]
  );

  return (
    <BasicTablePage
      table={{
        loading: statusDepartment === 'pending',
        dataSource: dataSource,
        columns: columns,
        rowKey: (record) => record.departmentId,
        dataSourceName: t('pages_plural.Departments'),
        draggable: true,
        dropAction: updateParent,
        expandable: {
          childrenColumnName: 'childDepartment',
        },
      }}
      header={{
        csvConfig: {
          data: csvData,
          headers: [
            { label: 'Department Code', key: 'departmentCode' },
            { label: 'Department Name', key: 'name' },
            { label: 'Site', key: 'siteName' },
            { label: 'Parent Department', key: 'parentDepartmentName' },
          ],
          fileName: 'departments.csv',
        },
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Department') }),
        filterProps: {
          items: [
            {
              label: t('titles.Code'),
              name: 'departmentCodeArray',
              item: (
                <Select
                  value={filtersDepartment?.departmentCodeArray}
                  maxTagCount={4}
                  mode="tags"
                  options={departmentListWithoutChildren}
                  fieldNames={{
                    label: 'departmentCode',
                    value: 'departmentCode',
                  }}
                />
              ),
            },
            {
              label: t('titles.Name'),
              name: 'nameArray',
              item: (
                <Select
                  value={filtersDepartment?.nameArray}
                  maxTagCount={4}
                  options={departmentListWithoutChildren}
                  mode="tags"
                  fieldNames={{ label: 'name', value: 'name' }}
                />
              ),
            },
          ],
          applyAction: onSubmitFilter,
          resetAction: () => {
            if (checkFilterValues(filtersDepartment)) {
              getDefaultList();
            }
          },
          initialValuesForm: {
            nameArray: filtersDepartment?.nameArray ? filtersDepartment?.nameArray : [],
            departmentCodeArray: filtersDepartment?.departmentCodeArray
              ? filtersDepartment?.departmentCodeArray
              : [],
          },
        },
        newButtonProps: {
          disabled: !departmentAccess.create,
        },
      }}
    />
  );
}

export { Departments };
