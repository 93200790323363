import type { RootState } from '@store/rootReducer';

export const selectDefinitionState = (state: RootState) => state.DefinitionReducer;

export const selectCitiesList = (state: RootState) => selectDefinitionState(state).citiesList;

export const selectCountriesList = (state: RootState) => selectDefinitionState(state).countriesList;

export const selectDefinitionStatus = (state: RootState) =>
  selectDefinitionState(state).definitionStatus;

export const selectDefinitionError = (state: RootState) => selectDefinitionState(state).error;
