import { MaintenanceViewType } from '@constants/MaintenanceViewType';
import { createSlice } from '@reduxjs/toolkit';
import {
  addMaintenanceGeneral,
  addMaintenanceMultipleChoiceWorkStep,
  addMaintenanceParts,
  addMaintenanceSteps,
  batchUpdateMaintenances,
  getMaintenanceById,
  getMaintenanceHistoryList,
  getMaintenanceOccurrencesList,
  getMaintenances,
  removeMaintenanceParts,
  removeWorkStepByIds,
  removeWorkStepsOptionByIds,
  updateMaintenanceGeneral,
  updateMaintenanceViewType,
  updateMaintenanceWorkStepTitle,
  updateWorkStepOptionText,
} from '@store/actions/management/Maintenance';

import type { LoadingStatus } from '@constants/loadingStatus';
import type {
  FilterHistoryDto,
  MaintenanceHistoryModel,
  MaintenanceOccurrenceModel,
  MaintenanceV2Model,
} from '@model/management/Maintenance';
import type { MaintenanceModel } from '@model/management/Maintenance/Maintenance.model';

interface MaintenanceState {
  maintenancesList: MaintenanceModel[];
  maintenanceOccurrencesList: MaintenanceOccurrenceModel[];
  maintenanceStatus: LoadingStatus;
  maintenance: MaintenanceV2Model | null;
  maintenanceError: string | null;
  maintenanceViewType: {
    prevViewType: MaintenanceViewType;
    viewType: MaintenanceViewType;
  };
  historyList: MaintenanceHistoryModel[];
  totalHistoryPage: number;
  totalHistoryRecords: number;
  filterHistoryListOptions: FilterHistoryDto | null;
}

const initialState: MaintenanceState = {
  maintenancesList: [],
  maintenanceOccurrencesList: [],
  maintenanceStatus: 'idle',
  maintenanceError: null,
  maintenance: null,
  maintenanceViewType: {
    prevViewType: MaintenanceViewType.List,
    viewType: MaintenanceViewType.List,
  },
  historyList: [],
  totalHistoryPage: 0,
  totalHistoryRecords: 0,
  filterHistoryListOptions: null,
};

const MaintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get reducers
    builder
      .addCase(getMaintenances.pending, (state) => {
        state.maintenanceStatus = 'pending';
      })
      .addCase(getMaintenances.fulfilled, (state, action) => {
        state.maintenanceStatus = 'idle';
        state.maintenancesList = action.payload;
      })
      .addCase(getMaintenances.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });
    builder
      .addCase(getMaintenanceById.pending, (state) => {
        state.maintenanceStatus = 'pending';
      })
      .addCase(getMaintenanceById.fulfilled, (state, action) => {
        state.maintenanceStatus = 'idle';
        state.maintenance = action.payload;
      })
      .addCase(getMaintenanceById.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });
    builder
      .addCase(getMaintenanceOccurrencesList.pending, (state) => {
        state.maintenanceStatus = 'pending';
      })
      .addCase(getMaintenanceOccurrencesList.fulfilled, (state, action) => {
        state.maintenanceStatus = 'idle';
        state.maintenanceOccurrencesList = action.payload;
      })
      .addCase(getMaintenanceOccurrencesList.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });
    builder
      .addCase(getMaintenanceHistoryList.pending, (state) => {
        state.maintenanceStatus = 'pending';
      })
      .addCase(getMaintenanceHistoryList.fulfilled, (state, action) => {
        state.maintenanceStatus = 'idle';
        state.historyList = action.payload.historyList;
        state.filterHistoryListOptions = action.payload.filterHistoryListOptions;
        state.totalHistoryPage = action.payload.totalHistoryPage;
        state.totalHistoryRecords = action.payload.totalHistoryRecords;
      })
      .addCase(getMaintenanceHistoryList.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });

    // add reducers
    builder
      .addCase(addMaintenanceGeneral.pending, (state) => {
        state.maintenanceStatus = 'pending';
        state.maintenanceError = null;
      })
      .addCase(addMaintenanceGeneral.fulfilled, (state) => {
        state.maintenanceStatus = 'idle';
      })
      .addCase(addMaintenanceGeneral.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });

    builder
      .addCase(addMaintenanceSteps.pending, (state) => {
        state.maintenanceStatus = 'pending';
        state.maintenanceError = null;
      })
      .addCase(addMaintenanceSteps.fulfilled, (state) => {
        state.maintenanceStatus = 'idle';
      })
      .addCase(addMaintenanceSteps.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });

    builder
      .addCase(addMaintenanceMultipleChoiceWorkStep.pending, (state) => {
        state.maintenanceStatus = 'pending';
      })
      .addCase(addMaintenanceMultipleChoiceWorkStep.fulfilled, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (state.maintenance) {
          state.maintenance = payload;
        }
      })
      .addCase(addMaintenanceMultipleChoiceWorkStep.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });

    builder
      .addCase(addMaintenanceParts.pending, (state) => {
        state.maintenanceStatus = 'pending';
        state.maintenanceError = null;
      })
      .addCase(addMaintenanceParts.fulfilled, (state) => {
        state.maintenanceStatus = 'idle';
      })
      .addCase(addMaintenanceParts.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });

    // update reducers
    builder
      .addCase(updateMaintenanceGeneral.pending, (state) => {
        state.maintenanceStatus = 'pending';
        state.maintenanceError = null;
      })
      .addCase(updateMaintenanceGeneral.fulfilled, (state) => {
        state.maintenanceStatus = 'idle';
      })
      .addCase(updateMaintenanceGeneral.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });
    builder
      .addCase(batchUpdateMaintenances.pending, (state) => {
        state.maintenanceError = null;
        state.maintenanceStatus = 'pending';
      })
      .addCase(batchUpdateMaintenances.fulfilled, (state) => {
        state.maintenance = null;
        state.maintenanceStatus = 'idle';
      })
      .addCase(batchUpdateMaintenances.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });
    builder
      .addCase(updateMaintenanceWorkStepTitle.pending, (state) => {
        state.maintenanceStatus = 'pending';
        state.maintenanceError = null;
      })
      .addCase(updateMaintenanceWorkStepTitle.fulfilled, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (state.maintenance) {
          state.maintenance = payload;
        }
      })
      .addCase(updateMaintenanceWorkStepTitle.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });
    builder
      .addCase(updateWorkStepOptionText.pending, (state) => {
        state.maintenanceStatus = 'pending';
        state.maintenanceError = null;
      })
      .addCase(updateWorkStepOptionText.fulfilled, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (state.maintenance) {
          state.maintenance = payload;
        }
      })
      .addCase(updateWorkStepOptionText.rejected, (state, { payload }) => {
        state.maintenanceStatus = 'idle';
        if (payload) {
          state.maintenanceError = payload;
        }
      });

    builder.addCase(updateMaintenanceViewType, (state, { payload }) => {
      state.maintenanceViewType = {
        prevViewType: state.maintenanceViewType.viewType,
        viewType: payload.viewType,
      };
    });

    // Remove reducers
    builder
      .addCase(removeWorkStepByIds.pending, (state) => {
        state.maintenanceError = null;
      })
      .addCase(removeWorkStepByIds.fulfilled, (state, { payload }) => {
        if (state.maintenance) {
          state.maintenance = payload;
        }
      })
      .addCase(removeWorkStepByIds.rejected, (state, { payload }) => {
        if (payload) {
          state.maintenanceError = payload;
        }
      });
    builder
      .addCase(removeWorkStepsOptionByIds.pending, (state) => {
        state.maintenanceError = null;
      })
      .addCase(removeWorkStepsOptionByIds.fulfilled, (state, { payload }) => {
        if (state.maintenance) {
          state.maintenance = payload;
        }
      })
      .addCase(removeWorkStepsOptionByIds.rejected, (state, { payload }) => {
        if (payload) {
          state.maintenanceError = payload;
        }
      });

    builder
      .addCase(removeMaintenanceParts.pending, (state) => {
        state.maintenanceError = null;
      })
      .addCase(removeMaintenanceParts.fulfilled, (state, { payload }) => {
        if (payload) {
          state.maintenance = payload;
        }
      })
      .addCase(removeMaintenanceParts.rejected, (state, { payload }) => {
        state.maintenanceError = payload || null;
      });
  },
});

const MaintenanceReducer = MaintenanceSlice.reducer;

export { MaintenanceReducer };
