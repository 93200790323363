import { api } from '@services/Axios';

import type {
  BatchUpdateMaintenancesDto,
  CreateMaintenanceGeneralDto,
  CreateMaintenanceGeneralV2Dto,
  CreateMaintenancePartsDto,
  CreateMaintenanceStepsDto,
  CreateMultipleChoiceWorkStepDto,
  FilterHistoryDto,
  MaintenanceHistoryModel,
  MaintenanceModel,
  MaintenanceOccurrenceModel,
  MaintenancePartModel,
  MaintenanceScheduleModel,
  MaintenanceV2Model,
  MaintenanceWorkStepModel,
  MultiChoiceForWorkStepModel,
  QueryMaintenanceScheduleDto,
  QueryMaintenanceScheduleKanbanDto,
  UpdateMaintenanceGeneralDto,
  UpdateMaintenanceGeneralV2Dto,
  UpdateWorkStepOptionTitleDto,
  UpdateWorkStepTitleDto,
  WorkStepModel,
} from '@model/management/Maintenance';
import type { UpdateMaintenancePartDto } from '@model/management/Maintenance/dto/update-maintenance-part.dto';
import type { ApiResponse, CustomResponse } from '@services/Axios';

const MaintenanceEndpoint = '/Maintenance';

const WorkStepEndpoint = '/WorkSteps';

const MaintenancePartEndpoint = '/MaintenancePart';

const MaintenanceResultEndpoint = '/MaintenanceResult';

export const MaintenanceRepository = {
  async fetchAll() {
    const response: ApiResponse<MaintenanceModel[]> = await api.get(
      `${MaintenanceEndpoint}/GetMaintinanceList`
    );

    return response.data;
  },

  async fetchById(id: number | string) {
    const response: ApiResponse<MaintenanceModel> = await api.get(
      `${MaintenanceEndpoint}/GetMaintinanceById/${id}`
    );

    return response.data;
  },

  async fetchByIdV2(id: number | string) {
    const response: ApiResponse<MaintenanceV2Model> = await api.get(
      `/V2${MaintenanceEndpoint}/GetMaintinanceById/${id}`
    );

    return response.data;
  },

  async fetchWorkStepsByMaintenanceId(id: number | string) {
    const response: ApiResponse<MaintenanceWorkStepModel[]> = await api.get(
      `${WorkStepEndpoint}/GetWorkStepsByMaintenanceId/${id}`
    );

    return response.data;
  },

  async fetchOccurrencesList() {
    const response: ApiResponse<MaintenanceOccurrenceModel[]> = await api.post(
      `${WorkStepEndpoint}/GetMaintenanceOccurrenceList`
    );

    return response.data;
  },

  async fetchHistoryList(filterHistoryListOptions: FilterHistoryDto) {
    const response: ApiResponse<
      MaintenanceHistoryModel[],
      { totalPage: number; totalRecords: number }
    > = await api.post(`/V2${MaintenanceEndpoint}/MaintenanceHistory`, filterHistoryListOptions);

    return response.data;
  },

  async fetchMaintenanceSchedulesList(dto: QueryMaintenanceScheduleDto) {
    const response: ApiResponse<MaintenanceScheduleModel[]> = await api.post(
      `${MaintenanceResultEndpoint}/GetMaintenanceSchedules`,
      dto
    );

    return response.data;
  },

  async fetchMaintenanceSchedulesKanbanList(dto: QueryMaintenanceScheduleKanbanDto) {
    const response: ApiResponse<MaintenanceScheduleModel[]> = await api.post(
      `${MaintenanceResultEndpoint}/GetMaintenanceSchedulesWithKanban`,
      dto
    );

    return response.data;
  },

  // Add endpoints
  async add(dto: CreateMaintenanceGeneralDto) {
    const response: ApiResponse<MaintenanceModel> = await api.post(
      `${MaintenanceEndpoint}/AddMaintenance`,
      dto
    );

    return response.data;
  },

  async addGeneralV2(dto: CreateMaintenanceGeneralV2Dto) {
    const response: ApiResponse<MaintenanceModel> = await api.post(
      `/V2${MaintenanceEndpoint}/AddMaintenance`,
      dto
    );

    return response.data;
  },

  async addWorkSteps(dto: CreateMaintenanceStepsDto[]) {
    const response: ApiResponse<any> = await api.post(`${WorkStepEndpoint}/AddWorkSteps`, {
      addWorkStepModelArray: dto,
    });

    return response.data;
  },

  async addMaintenanceParts(dto: CreateMaintenancePartsDto) {
    const response: ApiResponse<MaintenancePartModel[]> = await api.post(
      `${MaintenancePartEndpoint}/AddMaintenanceParts`,
      dto
    );

    return response.data;
  },
  async addMultipleChoiceWorkStep(dto: CreateMultipleChoiceWorkStepDto) {
    const response: ApiResponse<MultiChoiceForWorkStepModel[]> = await api.post(
      `${WorkStepEndpoint}/AddMultipleChoiceListForWorkSteps`,
      dto
    );

    return response.data;
  },

  async addWorkStepImage(dto: FormData) {
    const response: ApiResponse<string[]> = await api.post(
      `${WorkStepEndpoint}/UploadWorkStepImages`,
      dto,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  },

  async removeWorkStepImage(ids: number[]) {
    const response: ApiResponse<null> = await api.post(
      `${WorkStepEndpoint}/RemoveMaintenanceWorkStepImageByIds`,
      {
        maintenanceImageListResultsId: ids,
      }
    );

    return response.data;
  },

  // Update endpoints
  async batchUpdate(dto: BatchUpdateMaintenancesDto) {
    const response: ApiResponse<null> = await api.post(
      `/V2${MaintenanceEndpoint}/BatchProcessing`,
      dto
    );

    return response.data;
  },

  async updateMaintenance(dto: UpdateMaintenanceGeneralDto) {
    const response = await api.post<CustomResponse<MaintenanceModel>>(
      `${MaintenanceEndpoint}/UpdateMaintenance`,
      dto
    );

    return response.data;
  },

  async updateMaintenanceGeneralV2(dto: UpdateMaintenanceGeneralV2Dto) {
    const response: ApiResponse<MaintenanceModel> = await api.post(
      `/V2${MaintenanceEndpoint}/UpdateMaintenance`,
      dto
    );

    return response.data;
  },

  async updateWorkStepTitle(dto: UpdateWorkStepTitleDto) {
    const response: ApiResponse<WorkStepModel> = await api.post(
      `${WorkStepEndpoint}/UpdateWorkStep`,
      dto
    );

    return response.data;
  },

  async updateWorkStepOptionTitle(dto: UpdateWorkStepOptionTitleDto) {
    const response: ApiResponse<MultiChoiceForWorkStepModel> = await api.post(
      `${WorkStepEndpoint}/UpdateMultipleChoiceListForWorkSteps`,
      dto
    );

    return response.data;
  },

  async updateMaintenancePart(dto: UpdateMaintenancePartDto) {
    const response: ApiResponse<MaintenancePartModel[]> = await api.post(
      `${MaintenancePartEndpoint}/UpdateMaintenancePart`,
      dto
    );

    return response.data;
  },

  // Delete endpoints
  async removeWorkStepByIds(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${WorkStepEndpoint}/RemoveWorkStepByIds`, {
      workStepsIds: ids,
    });

    return response.data;
  },

  async removeWorkStepsOptionByIds(ids: number[]) {
    const response: ApiResponse<null> = await api.post(
      `${WorkStepEndpoint}/RemoveMultipleChoiceListForWorkStepsByIds`,
      {
        multipleChoiceListForWorkStepsIds: ids,
      }
    );

    return response.data;
  },

  async removeMaintenanceParts(ids: number[]) {
    const response: ApiResponse<null> = await api.post(
      `${MaintenancePartEndpoint}/RemoveByIdList`,
      {
        maintenancesPartsIds: ids,
      }
    );

    return response.data;
  },
};
