import { api } from '@services/Axios';

import type { CurrencyModel } from '@model/extra/Currency';
import type { ApiResponse } from '@services/Axios';

const CurrencyEndpoint = '/Currency';

export const CurrencyRepository = {
  async fetchCurrencies() {
    const response: ApiResponse<CurrencyModel[]> = await api.get(
      `${CurrencyEndpoint}/GetCurrencyList`
    );

    return response.data;
  },
};
