import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FormCheckOut } from '@components/views/Forms/properties';
import { useOutletCheckOut } from '@constants/OutletContextEntityPages';
import { useAppDispatch } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateCheckOut } from '@store/actions/properties/CheckOut';

import type { CreateCheckOutDto } from '@model/properties/CheckOut';

function EditCheckOut() {
  const { initialValues, entity } = useOutletCheckOut();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = (dto: CreateCheckOutDto) => {
    dispatch(updateCheckOut({ ...dto, checkFactorId: entity.checkFactorId }))
      .unwrap()
      .then((response) => {
        navigate(paths.checkOut.default);
        ApiNotifications.update(response.checkOut.name);
      })
      .catch((err) => {
        ApiNotifications.error(err);
      });
  };

  return (
    <FormCheckOut
      initialValues={initialValues}
      onFinish={onFinish}
      header={{
        title: entity.name,
      }}
    />
  );
}

export default EditCheckOut;
