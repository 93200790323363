import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectMaAtRequestReasonStatus } from '@store/selectors/properties/MaAt/RequestReason';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { MaAtRequestReasonModel } from '@model/properties/MaAt/RequestReason';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormMaintenanceRequestReason(props: CommonPreviewProps<MaAtRequestReasonModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const maintenanceBrandStatus = useAppSelector(selectMaAtRequestReasonStatus);

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={maintenanceBrandStatus === 'pending'}
      header={{
        title: entity.reasonName,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField>
        <Item label={t('titles.Code')}>
          <PreviewField>{entity.reasonCode}</PreviewField>
        </Item>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.reasonName}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormMaintenanceRequestReason };
