import { api } from '@services/Axios';

import type {
  AsAtCategoryModel,
  CreateAsAtCategoryDto,
  UpdateAsAtCategoryDto,
  UpdateAsAtCategoryParentDto,
} from '@model/properties/AsAt/Category';
import type { ApiResponse } from '@services/Axios';

const AsAtCategoryEndpoint = '/NonCurrAssetCardCategories';

export const AsAtCategoryRepository = {
  async fetchAll() {
    const response: ApiResponse<AsAtCategoryModel[]> = await api.get(
      `${AsAtCategoryEndpoint}/GetNonCurrAssetCardCategoriesList`
    );

    return response.data;
  },
  async add(dto: CreateAsAtCategoryDto) {
    const response: ApiResponse<AsAtCategoryModel[]> = await api.post(
      `${AsAtCategoryEndpoint}/AddNonCurrAssetCardCategory`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<AsAtCategoryModel> = await api.get(
      `${AsAtCategoryEndpoint}/GetNonCurrAssetCardCategoryById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdateAsAtCategoryDto) {
    const response: ApiResponse<AsAtCategoryModel> = await api.post(
      `${AsAtCategoryEndpoint}/UpdateNonCurrAssetCardCategories`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${AsAtCategoryEndpoint}/RemoveByIdList`, {
      nonCurrAssetCardCategoriesIds: ids,
    });

    return response.data;
  },

  async updatePatentRelations(dto: UpdateAsAtCategoryParentDto) {
    const response: ApiResponse<null> = await api.post(
      `${AsAtCategoryEndpoint}/ChangeNonCurrAssetCardCategoriesParentReleations`,
      dto
    );

    return response.data;
  },
};
