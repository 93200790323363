import { createSlice } from '@reduxjs/toolkit';
import {
  createMaintenanceResult,
  getMaintenanceResultByHistory,
  getMaintenanceResultByScheduleIdVer2,
  getMaintenanceSchedulesKanbanList,
  getMaintenanceSchedulesList,
  resetSchedulesList,
  updateMaintenanceResult,
  updateMaintenanceResultStatus,
} from '@store/actions/management/MaintenanceResult';

import type { LoadingStatus } from '@constants/loadingStatus';
import type {
  MaintenanceResultByHistoryModel,
  MaintenanceResultModel,
  MaintenanceResultVer2Model,
  MaintenanceScheduleModel,
} from '@model/management/Maintenance';

interface MaintenanceResultState {
  maintenanceResultsList: MaintenanceResultModel[];

  maintenanceResult: null | {
    result: MaintenanceResultVer2Model[];
    executeStatusId: number;
    resultType: number;
    scheduleId: number;
  };

  maintenanceHistoryResult: null | {
    result: MaintenanceResultByHistoryModel;
    executeStatusId: number;
    resultType: number;
    scheduleId: number;
  };

  schedulesList: MaintenanceScheduleModel[];

  maintenanceResultStatus: LoadingStatus;

  maintenanceResultError: null | string;
}

const initialState: MaintenanceResultState = {
  maintenanceResultsList: [],
  maintenanceHistoryResult: null,
  schedulesList: [],
  maintenanceResult: null,
  maintenanceResultStatus: 'idle',
  maintenanceResultError: null,
};

const MaintenanceResultSlice = createSlice({
  name: 'MaintenanceResult',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMaintenanceResultByScheduleIdVer2.pending, (state) => {
        state.maintenanceResultStatus = 'pending';
        state.maintenanceResultError = null;
      })
      .addCase(getMaintenanceResultByScheduleIdVer2.fulfilled, (state, action) => {
        state.maintenanceResultStatus = 'idle';
        state.maintenanceResult = action.payload;
      })
      .addCase(getMaintenanceResultByScheduleIdVer2.rejected, (state, { payload }) => {
        state.maintenanceResultStatus = 'failed';
        if (payload) {
          state.maintenanceResultError = payload;
        }
      });
    builder
      .addCase(getMaintenanceResultByHistory.pending, (state) => {
        state.maintenanceResultStatus = 'pending';
        state.maintenanceResultError = null;
      })
      .addCase(getMaintenanceResultByHistory.fulfilled, (state, action) => {
        state.maintenanceResultStatus = 'idle';
        state.maintenanceHistoryResult = action.payload;
      })
      .addCase(getMaintenanceResultByHistory.rejected, (state, { payload }) => {
        state.maintenanceResultStatus = 'failed';
        if (payload) {
          state.maintenanceResultError = payload;
        }
      });
    builder
      .addCase(getMaintenanceSchedulesList.pending, (state) => {
        state.maintenanceResultStatus = 'pending';
      })
      .addCase(getMaintenanceSchedulesList.fulfilled, (state, { payload }) => {
        state.maintenanceResultStatus = 'idle';
        if (payload && payload.length > 0) {
          state.schedulesList = [...state.schedulesList, ...payload];
        }
      })
      .addCase(getMaintenanceSchedulesList.rejected, (state, { payload }) => {
        state.maintenanceResultStatus = 'idle';
        if (payload) {
          state.maintenanceResultError = payload;
        }
      });
    builder
      .addCase(getMaintenanceSchedulesKanbanList.pending, (state) => {
        state.maintenanceResultStatus = 'pending';
      })
      .addCase(getMaintenanceSchedulesKanbanList.fulfilled, (state, action) => {
        state.maintenanceResultStatus = 'idle';
        state.schedulesList = action.payload;
      })
      .addCase(getMaintenanceSchedulesKanbanList.rejected, (state, { payload }) => {
        state.maintenanceResultStatus = 'idle';
        state.schedulesList = [];
        if (payload) {
          state.maintenanceResultError = payload;
        }
      });

    builder
      .addCase(createMaintenanceResult.pending, (state) => {
        state.maintenanceResultError = null;
        state.maintenanceResultStatus = 'pending';
      })
      .addCase(createMaintenanceResult.fulfilled, (state) => {
        state.maintenanceResultStatus = 'idle';
      })
      .addCase(createMaintenanceResult.rejected, (state, { payload }) => {
        state.maintenanceResultStatus = 'idle';
        if (payload) {
          state.maintenanceResultError = payload;
        }
      });
    builder
      .addCase(updateMaintenanceResultStatus.pending, (state) => {
        state.maintenanceResultError = null;
      })
      .addCase(updateMaintenanceResultStatus.fulfilled, (state, action) => {
        if (state.maintenanceResult) {
          state.maintenanceResult.executeStatusId = action.payload.executeStatus;
        }
        state.schedulesList = action.payload.schedulesList;
      })
      .addCase(updateMaintenanceResultStatus.rejected, (state, { payload }) => {
        if (payload) {
          state.maintenanceResultError = payload;
        }
      });

    builder
      .addCase(updateMaintenanceResult.pending, (state) => {
        state.maintenanceResultError = null;
        state.maintenanceResultStatus = 'pending';
      })
      .addCase(updateMaintenanceResult.fulfilled, (state, { payload }) => {
        state.maintenanceResultStatus = 'idle';
        if (payload && state.maintenanceResult) {
          state.maintenanceResult.result = payload;
        }
      })
      .addCase(updateMaintenanceResult.rejected, (state, { payload }) => {
        state.maintenanceResultStatus = 'idle';
        if (payload) {
          state.maintenanceResultError = payload;
        }
      });

    builder.addCase(resetSchedulesList, (state) => {
      state.schedulesList = [];
    });
  },
});

const MaintenanceResultReducer = MaintenanceResultSlice.reducer;

export { MaintenanceResultReducer };
