import { api } from '@services/Axios';

import type {
  CreatePaIStatusDto,
  PaIStatusModel,
  UpdatePaIStatusDto,
} from '@model/properties/PaI/Status';
import type { ApiResponse } from '@services/Axios';

const PaIStatusEndpoint = '/PartsAndInventoriesStatus';

export const PaIStatusRepository = {
  async fetchAll() {
    const response: ApiResponse<PaIStatusModel[]> = await api.get(
      `${PaIStatusEndpoint}/GetPartsAndInventoriesStatusList`
    );

    return response.data;
  },
  async add(dto: CreatePaIStatusDto) {
    const response: ApiResponse<PaIStatusModel> = await api.post(
      `${PaIStatusEndpoint}/AddPartsAndInventoriesStatus`,
      dto
    );

    return response.data;
  },
  async fetchById(id: string) {
    const response: ApiResponse<PaIStatusModel> = await api.get(
      `${PaIStatusEndpoint}/GetPartsAndInventoriesStatusById/${id}`
    );

    return response.data;
  },
  async update(dto: UpdatePaIStatusDto) {
    const response: ApiResponse<PaIStatusModel> = await api.post(
      `${PaIStatusEndpoint}/UpdatePartsAndInventoriesStatus`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(
      `${PaIStatusEndpoint}/RemovePartsAndInventoriesStatusByIdList`,
      {
        partsAndInventoriesStatusIds: ids,
      }
    );

    return response.data;
  },
};
