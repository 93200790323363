import React from 'react';

import { baseFormatDate } from '@constants/Date';
import generateCalendar from 'antd/es/calendar/generateCalendar';
import cl from 'classnames';
import { format, startOfMonth } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import 'antd/es/calendar/style/index';

import { DateCell } from './DateCell';
import { DateCellItem } from './DateCellItem';

import type { GetKey } from '@constants/GetKey';
import type { CalendarProps as AntdCalendarProps } from 'antd';

import './MonthMode.scss';

export type MonthCellConfig<T = any> = (record: T) => {
  label: string | number;
  date: Date;
  id: number | string;
  badge: number | null;
};

interface MonthModeProps<T = any> extends AntdCalendarProps<Date> {
  additionalHeaderElements?: () => React.ReactNode;
  loading?: boolean;
  data: T[];
  getCellKey: GetKey<T>;
  cellConfig: MonthCellConfig;
  onClickCellItem?: (record: T) => void;
}

const Calendar = generateCalendar<Date>(dateFnsGenerateConfig);

const MonthMode = <T,>(props: MonthModeProps<T>) => {
  const {
    className,
    additionalHeaderElements,
    onChange: onChangeProps,
    value,
    data,
    getCellKey,
    cellConfig,
    onClickCellItem,
    ...rest
  } = props;

  const defaultClass = 'custom-calendar';
  const classNames = cl(defaultClass, className);

  const onChange = (date: Date) => {
    const startDate = startOfMonth(date);

    onChangeProps?.(startDate);
  };

  const dateFullCellRender = (date: Date) => {
    return (
      <DateCell date={date}>
        {data.map((record, idx) => {
          const key = getCellKey(record, idx);
          const config = cellConfig(record);
          const formattedDate = format(date, baseFormatDate);
          const configDate = format(config.date, baseFormatDate);

          if (formattedDate !== configDate) return null;

          return (
            <DateCellItem onClick={() => onClickCellItem?.(record)} key={key} badge={config.badge}>
              {config.label}
            </DateCellItem>
          );
        })}
      </DateCell>
    );
  };

  return (
    <Calendar
      dateFullCellRender={dateFullCellRender}
      headerRender={() => null}
      className={classNames}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

export { MonthMode };
