import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsAtCategoryRepository } from '@repositories';

import type {
  AsAtCategoryModel,
  CreateAsAtCategoryDto,
  UpdateAsAtCategoryDto,
  UpdateAsAtCategoryParentDto,
} from '@model/properties/AsAt/Category';
import type { ApiError } from '@services/Axios';

// region --- Get Actions

export const getAsAtCategories = createAsyncThunk<
  AsAtCategoryModel[],
  void,
  { rejectValue: string }
>('AsAtCategory/getAsAtCategories', async (_, { rejectWithValue }) => {
  try {
    const response = await AsAtCategoryRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});
export const getAsAtCategoryById = createAsyncThunk<
  AsAtCategoryModel,
  string,
  { rejectValue: string }
>('AsAtCategory/getAsAtCategoryById', async (id, { rejectWithValue }) => {
  try {
    const response = await AsAtCategoryRepository.fetchById(id);

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Get Actions

// region --- Add Actions

export const addAsAtCategory = createAsyncThunk<
  { AsAtCategory: AsAtCategoryModel; AsAtCategoriesList: AsAtCategoryModel[] },
  CreateAsAtCategoryDto,
  { rejectValue: string }
>('AsAtCategory/addAsAtCategory', async (dto, { rejectWithValue }) => {
  try {
    const AsAtCategory = await AsAtCategoryRepository.add(dto);
    const assetCategories = await AsAtCategoryRepository.fetchAll();

    return {
      AsAtCategory: AsAtCategory.resultObject[0],
      AsAtCategoriesList: assetCategories.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Add Actions

// region --- Update Actions

export const updateAsAtCategory = createAsyncThunk<
  { AsAtCategoriesList: AsAtCategoryModel[]; AsAtCategory: AsAtCategoryModel },
  UpdateAsAtCategoryDto,
  { rejectValue: string }
>('AsAtCategory/updateAsAtCategory', async (dto, { rejectWithValue }) => {
  try {
    const response = await AsAtCategoryRepository.update(dto);

    const responseList = await AsAtCategoryRepository.fetchAll();

    return {
      AsAtCategoriesList: responseList.resultObject,
      AsAtCategory: response.resultObject,
    };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const updateAsAtCategoryParentRelations = createAsyncThunk<
  AsAtCategoryModel[],
  UpdateAsAtCategoryParentDto,
  { rejectValue: string }
>('AsAtCategory/updateAsAtCategoryParentRelations', async (dto, { rejectWithValue }) => {
  try {
    await AsAtCategoryRepository.updatePatentRelations(dto);
    const response = await AsAtCategoryRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeAsAtCategoryById = createAsyncThunk<
  AsAtCategoryModel[],
  number[],
  { rejectValue: string }
>('AsAtCategory/removeAsAtCategoryById', async (ids, { rejectWithValue }) => {
  try {
    await AsAtCategoryRepository.removeById(ids);
    const response = await AsAtCategoryRepository.fetchAll();

    return response.resultObject;
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Remove Actions
