class MaintenanceViewTypeAdapter {
  private readonly key = 'maintenance_view';


  getViewType() {
  }
}

const instance = new MaintenanceViewTypeAdapter();

export {instance as MaintenanceViewTypeAdapter}
