import React from 'react';

import { EntryFormAsset } from '@components/views/Forms/managment/Asset';
import { CheckAccess } from '@components/wrappers';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { formatMomentDate, normalizeOptionalValue } from '@shared/utils/functions';
import { addAsset } from '@store/actions/management/Asset';
import { addAssetParts } from '@store/actions/management/AssetParts';

import type { CreateAssetDto } from '@model/management/Asset';
import type { CreateAssetPartDto } from '@model/management/AssetParts';

function CreateAsset() {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  // const { t } = useTranslation();

  const { assetAccess } = useGetAccess();

  const onFinish = (dto: CreateAssetDto) => {
    const labelList =
      dto.labelsList &&
      dto.labelsList.map((label) => ({
        label: label.label,
        labelColor: label.labelColor,
      }));

    const properties = dto.nonCurrAssetPropertyDetalisModelList?.map((property) => {
      if (typeof property.nonCurrAssetPropertyValueId === 'string') {
        return {
          nonCurrAssetCardPropertyId: property.nonCurrAssetCardPropertyId,
          value: property.value,
        };
      }
      return property;
    });

    const normalizeDate: CreateAssetDto = {
      ...dto,
      invoiceDate: dto.invoiceDate ? formatMomentDate(dto.invoiceDate) : null,
      warrantyStartDate: dto.warrantyStartDate ? formatMomentDate(dto.warrantyStartDate) : null,
      warrantyEndDate: dto.warrantyEndDate ? formatMomentDate(dto.warrantyEndDate) : null,
      labelsList: labelList,
      nonCurrAssetPropertyDetalisModelList: properties || null,
    };

    let normalizeDTO = normalizeOptionalValue(normalizeDate);

    return dispatch(addAsset(normalizeDTO))
      .unwrap()
      .then((payload) => {
        ApiNotifications.create(payload.barcode);

        return Promise.resolve(payload.nonCurrAssetId);
      })
      .catch((error) => {
        if (typeof error === 'string') {
          ApiNotifications.error(error);
        }
        return Promise.reject();
      });
  };

  const onFinishParts = (dto: CreateAssetPartDto) => {
    return dispatch(addAssetParts(dto))
      .unwrap()
      .then(() => {
        navigate(paths.asset.default);
        return Promise.resolve();
      });
  };

  return (
    <CheckAccess hasAccess={assetAccess.create} redirectTo={paths.asset.default}>
      <EntryFormAsset onFinishGeneral={onFinish} onFinishParts={onFinishParts} />
    </CheckAccess>
  );
}

export default CreateAsset;
