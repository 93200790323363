import React from 'react';

import { PreviewFormSite } from '@components/views/Previews/properties';
import { useOutletSite } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { removeSiteById } from '@store/actions/properties/Site';

import type { CommonPreviewProps } from '@components/common';

function PreviewSite() {
  const { entity } = useOutletSite();

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { siteAccess } = useGetAccess();

  const onDelete = async () => {
    try {
      await dispatch(removeSiteById([entity.siteId])).unwrap();
      ApiNotifications.remove(entity.name);
      navigate(paths.site.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  const headerProps: CommonPreviewProps['header'] = {
    editButtonProps: {
      disabled: !siteAccess.edit,
    },
    deleteButtonProps: {
      disabled: !siteAccess.delete,
    },
  };

  return <PreviewFormSite entity={entity} onDelete={onDelete} header={headerProps} />;
}

export default PreviewSite;
