import React from 'react';

import { FormExitType } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletExitType } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateExitType } from '@store/actions/properties/ExitType';

import type { CreateExitTypeDto } from '@model/properties/ExitType';

function EditExitType() {
  const { initialValues, entity } = useOutletExitType();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { exitTypeAccess } = useGetAccess();

  const onFinish = async (dto: CreateExitTypeDto) => {
    try {
      const response = await dispatch(
        updateExitType({ ...dto, checkFactorId: entity.checkFactorId })
      ).unwrap();
      ApiNotifications.update(response.exitType.name);
      navigate(paths.exitType.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={exitTypeAccess.edit} redirectTo={paths.exitType.default}>
      <FormExitType
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditExitType;
