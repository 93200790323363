import type { RootState } from '@store/rootReducer';

export const selectMaAtCategoryState = (state: RootState) => state.MaAtCategoryReducer;

export const selectMaAtCategoriesList = (state: RootState) =>
  selectMaAtCategoryState(state).MaAtCategoriesList;

export const selectMaAtCategory = (state: RootState) => selectMaAtCategoryState(state).MaAtCategory;

export const selectMaAtCategoryStatus = (state: RootState) =>
  selectMaAtCategoryState(state).MaAtCategoryStatus;

export const selectMaAtCategoryError = (state: RootState) =>
  selectMaAtCategoryState(state).MaAtCategoryError;
