import type { RootState } from '@store/rootReducer';

export const selectAssetPartsState = (state: RootState) => state.AssetPartsReducer;

export const selectAssetParts = (state: RootState) => selectAssetPartsState(state).entities;

export const selectAssetPartsStatuses = (state: RootState) => ({
  status: selectAssetPartsState(state).status,
  statusCreate: selectAssetPartsState(state).statusCreate,
  statusUpdate: selectAssetPartsState(state).statusUpdate,
});

export const selectAssetPartsStatus = (state: RootState) => selectAssetPartsStatuses(state).status;
export const selectAssetPartsStatusCreate = (state: RootState) =>
  selectAssetPartsStatuses(state).statusCreate;
export const selectAssetPartsStatusUpdate = (state: RootState) =>
  selectAssetPartsStatuses(state).statusUpdate;

export const selectAssetPartsError = (state: RootState) => selectAssetPartsState(state).error;

export const selectAssetPartsCurrentAssetId = (state: RootState) =>
  selectAssetPartsState(state).currentAssetId;
