import type { BatchUpdatePaIsDto, PaITableModel } from '@model/management/PaI';

type IdsParam = Partial<Omit<BatchUpdatePaIsDto, 'partsAndInventoriesIds'>>;

export function getBatchPaIDTO(
  selectedPaIs: Array<PaITableModel>,
  ids: IdsParam
): BatchUpdatePaIsDto {
  const defaultIds = {
    partnerId: null,

    statusId: null,

    siteId: null,

    ...ids,
  };

  return {
    partsAndInventoriesIds: selectedPaIs.map((pai) => pai.partsAndInventoriesId),
    ...defaultIds,
  };
}
