import type { RootState } from '@store/rootReducer';

export const selectAsAtPropertyState = (state: RootState) => state.AsAtPropertyReducer;

export const selectAsAtPropertiesList = (state: RootState) =>
  selectAsAtPropertyState(state).AsAtPropertiesList;

export const selectAsAtPropertyTypesList = (state: RootState) =>
  selectAsAtPropertyState(state).AsAtPropertyTypesList;

export const selectAsAtProperty = (state: RootState) => selectAsAtPropertyState(state).AsAtProperty;

export const selectAsAtPropertyStatus = (state: RootState) =>
  selectAsAtPropertyState(state).AsAtPropertyStatus;

export const selectAsAtPropertyError = (state: RootState) =>
  selectAsAtPropertyState(state).AsAtPropertyError;
