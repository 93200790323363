import {useLocation} from 'react-router-dom';

export type LocationState = {
  prevPath: string;
  [key: string]: any;
};

function useAppLocation() {
  const {state, ...restLocation} = useLocation();

  const appState = state as LocationState;

  return {...restLocation, appState}
}

export {useAppLocation};
