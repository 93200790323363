import { useEffect, useMemo } from 'react';
import { batch } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@hooks';
import { checkStoreStatuses } from '@shared/utils/functions';
import { getMaintenances } from '@store/actions/management/Maintenance';
import {
  getMaintenanceSchedulesKanbanList,
  getMaintenanceSchedulesList,
} from '@store/actions/management/MaintenanceResult';
import { getUsers } from '@store/actions/user/User';
import {
  selectMaintenancesList,
  selectMaintenanceStatus,
} from '@store/selectors/management/Maintenance';
import {
  selectMaintenanceResultStatus,
  selectMaintenanceSchedulesList,
} from '@store/selectors/management/MaintenanceResult';
import { selectUsersList, selectUserStatus } from '@store/selectors/users/User';

import type {
  MaintenanceScheduleCalendarModel,
  QueryMaintenanceScheduleDto,
  QueryMaintenanceScheduleKanbanDto,
} from '@model/management/Maintenance';
import type { DependencyList } from 'react';

function useGetMaintenanceSchedules(
  scheduleActionParams: QueryMaintenanceScheduleDto | QueryMaintenanceScheduleKanbanDto,
  deps: DependencyList = []
) {
  const dispatch = useAppDispatch();

  const schedulesList = useAppSelector(selectMaintenanceSchedulesList);
  const maintenancesList = useAppSelector(selectMaintenancesList);
  const usersList = useAppSelector(selectUsersList);

  const schedulesStatus = useAppSelector(selectMaintenanceResultStatus);
  const maintenancesStatus = useAppSelector(selectMaintenanceStatus);
  const userStatus = useAppSelector(selectUserStatus);

  const loading = checkStoreStatuses([schedulesStatus, maintenancesStatus, userStatus]);

  useEffect(() => {
    batch(() => {
      getSchedulesList();
      if (!usersList.length) {
        dispatch(getUsers());
      }
      if (!maintenancesList.length) {
        dispatch(getMaintenances());
      }
    });
  }, deps);

  function getSchedulesList() {
    if ('startDate' in scheduleActionParams) {
      dispatch(getMaintenanceSchedulesKanbanList(scheduleActionParams));
    } else {
      dispatch(getMaintenanceSchedulesList(scheduleActionParams));
    }
  }

  const schedules = useMemo(
    () =>
      schedulesList.reduce((acc: MaintenanceScheduleCalendarModel[], schedule) => {
        const maintenance = maintenancesList.find(
          (mai) => mai.maintenanceId === schedule.maintenanceId
        );
        const user = usersList.find(
          (person) => maintenance && person.userId === maintenance.assignToId
        );
        if (maintenance) {
          acc.push({
            ...schedule,
            title: maintenance.title,
            nonCurrAssetId: maintenance.nonCurrAssetId,
            priority: maintenance.maintenancePriority.priorityName,
            site: maintenance.site ? maintenance.site.name : null,
            assignTo: user ? `${user.firstName} ${user.lastName}` : null,
          });
        }
        return acc;
      }, []),
    [schedulesList, usersList, maintenancesList, scheduleActionParams]
  );

  return { schedules, loading };
}

export { useGetMaintenanceSchedules };
