import React from 'react';
import { Outlet } from 'react-router-dom';

import { useSetTabs } from '@hooks';
import { paths } from '@routes/paths';

function EntryAssetAttributes() {
  useSetTabs(Object.values(paths.assetAttribute.tabs));

  return <Outlet />;
}

export default EntryAssetAttributes;
