import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@shared/UI';
import cl from 'classnames';

import './DateCellItem.scss';

interface DateCellItemProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  badge: number | null;
}

const defaultClass = 'custom-calendar';

const DateCellItem = (props: DateCellItemProps) => {
  const { children, className, onClick, badge } = props;
  const { t } = useTranslation();

  const classNames = cl(`${defaultClass}-date-cell-item`, className);

  return (
    <div className={classNames} onClick={onClick}>
      {children}
      {!badge ? <Badge count={t('titles.MR')} color="#338F13" className="badge"></Badge> : ''}
    </div>
  );
};

export { DateCellItem };
