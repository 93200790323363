import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { useAppSelector } from '@hooks';
import { selectPaIUnitStatus } from '@store/selectors/properties/PaI/Unit';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { PaIUnitModel } from '@model/properties/PaI/Unit';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormPartAndInventoryUnit(props: CommonPreviewProps<PaIUnitModel>) {
  const { entity, onDelete, header } = props;

  const { t } = useTranslation();

  const statusPartAndInventoryUnit = useAppSelector(selectPaIUnitStatus);

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={statusPartAndInventoryUnit === 'pending'}
      header={{
        title: entity.partsAndInventoriesUnitName,
        onClickDelete: onDelete,
        ...header,
      }}
    >
      <CaseField>
        <Item label={t('titles.Name')}>
          <PreviewField>{entity.partsAndInventoriesUnitName}</PreviewField>
        </Item>
        <Item label={t('titles.Short_Name')}>
          <PreviewField>{entity.partsAndInventoriesUnitShortName}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormPartAndInventoryUnit };
