import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { confirmModal } from '@shared/UI';

import { useBlockRedirect } from '../useBlocker';

function useConfirmRedirect(message: string, when = true) {
  const { t } = useTranslation();

  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  function handleConfirmModal(): Promise<boolean> {
    return new Promise((resolve) => {
      confirmModal({
        title: message,
        onOk() {
          resolve(true);
        },

        onCancel() {
          resolve(false);
        },

        okText: t('titles.Ok'),
        cancelText: t('titles.Cancel'),
      });
    });
  }

  useBlockRedirect(handleConfirmModal, when);
}

export {useConfirmRedirect};
