import { createSlice } from '@reduxjs/toolkit';
import {
  addAsAtModel,
  getAsAtModelById,
  getAsAtModels,
  removeAsAtModelById,
  updateAsAtModel,
} from '@store/actions/properties/AsAt/Model';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AsAtModelModel } from '@model/properties/AsAt/Model';

interface AsAtModelState {
  AsAtModelsList: AsAtModelModel[];
  AsAtModel: AsAtModelModel | null;
  AsAtModelStatus: LoadingStatus;
  AsAtModelError: string | null;
}

const initialState: AsAtModelState = {
  AsAtModelsList: [],
  AsAtModel: null,
  AsAtModelStatus: 'idle',
  AsAtModelError: null,
};

const AsAtModelSlice = createSlice({
  name: 'AsAtModel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsAtModels.pending, (state) => {
        state.AsAtModelError = null;
        state.AsAtModelStatus = 'pending';
      })
      .addCase(getAsAtModels.fulfilled, (state, { payload }) => {
        state.AsAtModelStatus = 'idle';
        state.AsAtModelsList = payload;
      })
      .addCase(getAsAtModels.rejected, (state, { payload }) => {
        state.AsAtModelStatus = 'idle';

        if (payload) {
          state.AsAtModelError = payload;
        }
      });

    builder
      .addCase(addAsAtModel.pending, (state) => {
        state.AsAtModelStatus = 'pending';
        state.AsAtModelError = null;
      })
      .addCase(addAsAtModel.fulfilled, (state, { payload }) => {
        state.AsAtModelStatus = 'idle';
        state.AsAtModelsList = [payload, ...state.AsAtModelsList];
      })
      .addCase(addAsAtModel.rejected, (state, { payload }) => {
        state.AsAtModelStatus = 'idle';
        if (payload) {
          state.AsAtModelError = payload;
        }
      });

    builder
      .addCase(getAsAtModelById.pending, (state) => {
        state.AsAtModelStatus = 'pending';
        state.AsAtModelError = null;
      })
      .addCase(getAsAtModelById.fulfilled, (state, { payload }) => {
        state.AsAtModelStatus = 'idle';
        state.AsAtModel = payload;
      })
      .addCase(getAsAtModelById.rejected, (state, { payload }) => {
        state.AsAtModelStatus = 'idle';
        if (payload) {
          state.AsAtModelError = payload;
        }
      });

    builder
      .addCase(updateAsAtModel.pending, (state) => {
        state.AsAtModelStatus = 'pending';
        state.AsAtModelError = null;
      })
      .addCase(updateAsAtModel.fulfilled, (state, { payload }) => {
        state.AsAtModelStatus = 'idle';
        state.AsAtModelsList = payload.AsAtModelsList;
        state.AsAtModel = payload.AsAtModel;
      })
      .addCase(updateAsAtModel.rejected, (state, { payload }) => {
        state.AsAtModelStatus = 'idle';
        if (payload) {
          state.AsAtModelError = payload;
        }
      });

    builder
      .addCase(removeAsAtModelById.pending, (state) => {
        state.AsAtModelStatus = 'pending';
        state.AsAtModelError = null;
      })
      .addCase(removeAsAtModelById.fulfilled, (state, { payload }) => {
        state.AsAtModelStatus = 'idle';
        state.AsAtModelsList = payload;
      })
      .addCase(removeAsAtModelById.rejected, (state, { payload }) => {
        state.AsAtModelStatus = 'idle';
        if (payload) {
          state.AsAtModelError = payload;
        }
      });
  },
});

const AsAtModelReducer = AsAtModelSlice.reducer;

export { AsAtModelReducer };
