import React, { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getAsAtPropertyById } from '@store/actions/properties/AsAt/Property';
import { selectAsAtProperty } from '@store/selectors/properties/AsAt/Property';

import type { IOutletAssetProperty } from '@constants/OutletContextEntityPages';
import type { CreateAsAtPropertyDto } from '@model/properties/AsAt/Property';

function AssetProperty() {
  const assetProperty = useAppSelector(selectAsAtProperty);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !assetProperty || assetProperty.nonCurrAssetCardPropertyId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getAsAtPropertyById(decryptedId));
    }
  }, []);

  const nonCurrAssetPropertyValues = useMemo(
    () =>
      assetProperty?.nonCurrAssetPropertyValues.map((value) => ({
        value: value.value,
        nonCurrAssetPropertyValueId: value.nonCurrAssetPropertyValueId,
      })),
    [assetProperty]
  );

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateAsAtPropertyDto = {
    nonCurrAssetCardPropertyCode: assetProperty.nonCurrAssetCardPropertyCode,
    name: assetProperty.name,
    isUnique: assetProperty.isUnique,
    nonCurrAssetTypeId: assetProperty.nonCurrAssetTypeId,
    nonCurrAssetPropertyValues: nonCurrAssetPropertyValues || [],
  };

  return <Outlet context={{ initialValues, entity: assetProperty } as IOutletAssetProperty} />;
}

export default AssetProperty;
