import { createSlice } from '@reduxjs/toolkit';
import LocalStorageService from '@services/LocaleStorage';
import { passwordRecovery, signIn, signOut } from '@store/actions/profile/Auth';

import type { LoadingStatus } from '@constants/loadingStatus';

type AuthState = {
  isAuth: boolean;
  authStatus: LoadingStatus;
  authError: string | null;
};

const initialState: AuthState = {
  isAuth: Boolean(LocalStorageService.handleAuthToken()),
  authStatus: 'idle',
  authError: null,
};

const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.authError = null;
        state.authStatus = 'pending';
      })
      .addCase(signIn.fulfilled, (state) => {
        state.authError = null;
        state.isAuth = true;
        state.authStatus = 'idle';
      })
      .addCase(signIn.rejected, (state, action) => {
        state.authStatus = 'idle';
        state.isAuth = false;
        state.authError = action.payload ? action.payload : null;
      });

    // builder
    //   .addCase(signOut.pending, (state) => {
    //     state.authError = null;
    //     state.authStatus = 'pending';
    //   })
    //   .addCase(signOut.fulfilled, (state) => {
    //     state.authError = null;
    //     state.isAuth = false;
    //     state.authStatus = 'idle';
    //   })
    //   .addCase(signOut.rejected, (state) => {
    //     state.authStatus = 'idle';
    //   });
    builder.addCase(signOut, (state) => {
      state.isAuth = false;
    });

    builder
      .addCase(passwordRecovery.pending, (state) => {
        state.authError = null;
        state.authStatus = 'pending';
      })
      .addCase(passwordRecovery.fulfilled, (state) => {
        state.authError = null;
        state.authStatus = 'idle';
      })
      .addCase(passwordRecovery.rejected, (state, action) => {
        state.authStatus = 'idle';
        state.authError = action.payload ? action.payload : null;
      });
  },
});

const AuthReducer = AuthSlice.reducer;

export { AuthReducer };
