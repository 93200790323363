import { createSlice } from '@reduxjs/toolkit';
import {
  addCheckOut,
  getCheckOutById,
  getCheckOuts,
  removeCheckOutById,
  updateCheckOut,
} from '@store/actions/properties/CheckOut';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { CheckOutModel } from '@model/properties/CheckOut';

type CheckOutState = {
  checkOutsList: CheckOutModel[];
  checkOut: null | CheckOutModel;
  checkOutStatus: LoadingStatus;
  checkOutError: null | string;
};

const initialState: CheckOutState = {
  checkOutsList: [],
  checkOut: null,
  checkOutStatus: 'idle',
  checkOutError: null,
};

const CheckOutSlice = createSlice({
  name: 'CheckOut',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCheckOuts.pending, (state) => {
        state.checkOutError = null;
        state.checkOutStatus = 'pending';
      })
      .addCase(getCheckOuts.fulfilled, (state, { payload }) => {
        state.checkOutStatus = 'idle';
        state.checkOutsList = payload;
      })
      .addCase(getCheckOuts.rejected, (state) => {
        state.checkOutStatus = 'idle';
      });

    builder
      .addCase(addCheckOut.pending, (state) => {
        state.checkOutStatus = 'pending';
        state.checkOutError = null;
      })
      .addCase(addCheckOut.fulfilled, (state, { payload }) => {
        state.checkOutStatus = 'idle';
        state.checkOutsList = [payload, ...state.checkOutsList];
      })
      .addCase(addCheckOut.rejected, (state, { payload }) => {
        state.checkOutStatus = 'idle';
        if (payload) {
          state.checkOutError = payload;
        }
      });

    builder
      .addCase(getCheckOutById.pending, (state) => {
        state.checkOutStatus = 'pending';
        state.checkOutError = null;
      })
      .addCase(getCheckOutById.fulfilled, (state, { payload }) => {
        state.checkOutStatus = 'idle';
        state.checkOut = payload;
      })
      .addCase(getCheckOutById.rejected, (state, { payload }) => {
        state.checkOutStatus = 'idle';
        if (payload) {
          state.checkOutError = payload;
        }
      });

    builder
      .addCase(updateCheckOut.pending, (state) => {
        state.checkOutStatus = 'pending';
        state.checkOutError = null;
      })
      .addCase(updateCheckOut.fulfilled, (state, { payload }) => {
        state.checkOutStatus = 'idle';
        state.checkOutsList = payload.CheckOuts;
        state.checkOut = payload.checkOut;
      })
      .addCase(updateCheckOut.rejected, (state, { payload }) => {
        state.checkOutStatus = 'idle';
        if (payload) {
          state.checkOutError = payload;
        }
      });

    builder
      .addCase(removeCheckOutById.pending, (state) => {
        state.checkOutStatus = 'pending';
        state.checkOutError = null;
      })
      .addCase(removeCheckOutById.fulfilled, (state, { payload }) => {
        state.checkOutStatus = 'idle';
        state.checkOutsList = payload;
      })
      .addCase(removeCheckOutById.rejected, (state, { payload }) => {
        state.checkOutStatus = 'idle';
        if (payload) {
          state.checkOutError = payload;
        }
      });
  },
});

const CheckOutReducer = CheckOutSlice.reducer;

export { CheckOutReducer };
