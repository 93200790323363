import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Loader } from '@shared/UI';
import cl from 'classnames';

import { Column } from './Column';

import type { GetKey } from '@constants/GetKey';
import type { ReactNode } from 'react';
import type { DragEnd } from './Column';

import './Kanban.scss';

export type ColumnConfig<T = any> = {
  type: string;
  key: string;
  accept: string | string[];
  title: ReactNode;
  filterData: (record: T) => boolean;
  headerColor?: React.CSSProperties['backgroundColor'];
  className?: string;
  style?: React.CSSProperties;
};

export interface KanbanProps<T = any> {
  className?: string;
  data: T[];
  columns: ColumnConfig<T>[];
  dataKey: GetKey<T>;
  onDrop?: (result: DragEnd<T>) => void;
  cellContentRender?: (record: T) => React.ReactNode;
  onClickCell?: (record: T) => void;
  loading?: boolean;
}

const Kanban = <T,>(props: KanbanProps<T>) => {
  const {
    className,
    columns,
    data,
    dataKey,
    loading = false,
    cellContentRender,
    onDrop,
    onClickCell,
  } = props;

  const defClass = 'kanban';
  const classNames = cl(defClass, className);

  const newData = columns.map((column) => {
    return {
      column,
      data: data.filter((item) => {
        return column.filterData(item);
      }),
    };
  });

  const renderColumns = () => {
    return newData.map(({ column: { filterData, style, ...restColumn }, data }) => {
      const calc = `calc(100% / ${columns.length} - 10px)`;
      const styles = {
        flex: `0 0 calc(${calc})`,
        maxWidth: `${calc}%`,
        ...style,
      };
      return (
        <Column
          {...restColumn}
          onClickCell={onClickCell}
          style={styles}
          cellContentRender={cellContentRender}
          data={data}
          dataKey={dataKey}
          onDrop={onDrop}
        />
      );
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classNames}>
        <Loader spinning={loading} middle>
          <div className={`${defClass}-container`}>{renderColumns()}</div>
        </Loader>
      </div>
    </DndProvider>
  );
};

export { Kanban };
