import React, { memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from '@hooks';
import { paths } from '@routes/paths';
import { selectPageTabs } from '@store/selectors/extra/Local';
import { Card, Layout } from 'antd';
import cl from 'classnames';

import { ContentTabs } from './elements';

import './Content.scss';

const { Content: AntdContent } = Layout;

const Content = () => {
  const tabs = useAppSelector(selectPageTabs);

  const { pathname } = useLocation();

  const isDashboard = pathname.includes(paths.dashboard.default);

  return (
    <>
      <AntdContent
        className={cl('layout-block-content', {
          'layout-block-content--tabs': tabs && tabs.length,
        })}
      >
        {tabs && tabs.length ? <ContentTabs tabs={tabs} /> : null}
        <Card
          style={{
            background: isDashboard ? 'transparent' : '#fff',
          }}
          className="wrapper-content"
        >
          <Outlet />
        </Card>
      </AntdContent>
    </>
  );
};

export default memo(Content);
