import React from 'react';

import cl from 'classnames';

import './DragHandle.scss';

export interface DragHandleProps extends React.HTMLAttributes<HTMLDivElement> {
  view?: 'horizontal' | 'vertical';
}

/* A constant variable that is used in the DragHandle component. */
const defaultClassPrefix = 'drag-handle';

const DragHandle = React.forwardRef<HTMLDivElement, DragHandleProps>(
  (props: DragHandleProps, ref) => {
    const { className, view = 'horizontal', ...rest } = props;

    const defaultClasses = cl(defaultClassPrefix, `${defaultClassPrefix}--${view}`, className);

    const handleElement = (
      <span className={`${defaultClassPrefix}__dot-wrapper`}>
        <span className={`${defaultClassPrefix}__dot`}></span>
        <span className={`${defaultClassPrefix}__dot`}></span>
        <span className={`${defaultClassPrefix}__dot`}></span>
      </span>
    );

    return (
      <div ref={ref} className={defaultClasses} {...rest}>
        {handleElement}
        {handleElement}
      </div>
    );
  }
);

export { DragHandle };
