/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { KindOfCheckStep } from '@constants/kindOfCheckStep';
import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getMaintenanceById } from '@store/actions/management/Maintenance';
import { selectMaintenance } from '@store/selectors/management/Maintenance';
import { v4 as uuidv4 } from 'uuid';

import type {
  CheckStepValue,
  InspectionCheckStepItem,
  MultiplyCheckStepItem,
  NumberCheckStepItem,
  TextCheckStepItem,
} from '@constants/checkStepItems';
import type { IOutletMaintenance } from '@constants/OutletContextEntityPages';
import type { TabType } from '@constants/pageInfo';
import type {
  CreateMaintenanceGeneralV2Dto,
  DateSelectorDto,
  MpModelDto,
} from '@model/management/Maintenance';

const Maintenance = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { decryptedId, encryptedId } = useHandleParamId();
  const maintenance = useAppSelector(selectMaintenance);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const condition = !maintenance || maintenance.maintenanceId !== +decryptedId;

  const withTranslations = Object.values(paths.maintenanceAttributes.tabs).map((value) => ({
    ...value,
    nameTab: t(value.keyTranslations),
  }));

  const tabs = [
    {
      ...paths.maintenance.tabs.general,
      default: paths.maintenance.tabs.general.default.replace(':id', `${encryptedId}`),
    },
    {
      ...paths.maintenance.tabs.workOrders,
      default: paths.maintenance.tabs.workOrders.default.replace(':id', `${encryptedId}`),
    },
    {
      ...paths.maintenance.tabs.parts,
      default: paths.maintenance.tabs.parts.default.replace(':id', `${encryptedId}`),
    },
    {
      ...paths.maintenance.tabs.history,
      default: paths.maintenance.tabs.history.default.replace(':id', `${encryptedId}`),
    },
  ] as Array<TabType>;

  useSetPageInfo(
    {
      pageTitle: 'pages_single.Maintenance',
      defaultPagePath: paths.maintenance.default,
      tabs,
    },
    { copyTabs: true }
  );

  useEffect(() => {
    dispatch(getMaintenanceById(decryptedId));

    const tabs = Object.values(withTranslations);
    const includeTabPath = tabs.find((tab) => pathname.includes(tab.key));

    if (includeTabPath) {
      return navigate(includeTabPath.key);
    }
    navigate(paths.maintenance.tabs.general.key);
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const workOrders = maintenance.maintenancesWorkSteps;

  const normalizeMaintenanceWorkOrders: CheckStepValue[] = workOrders.map((workOrder) => {
    const { workSteps, workStepsId } = workOrder;
    const {
      stepName,
      stepTypes: { stepTypeName },
      multipleChoiceListForWorkSteps,
    } = workSteps;

    const basicCheckStep = {
      entityId: workStepsId,
      uuid: uuidv4(),
      title: stepName,
      isFocus: false,
    };

    if (stepTypeName === KindOfCheckStep.MultiplyChoice) {
      const radios = multipleChoiceListForWorkSteps.reduce(
        (acc: MultiplyCheckStepItem['radios'], radio) => {
          const { multipleChoiceListForWorkStepsId, optionText } = radio;
          return {
            ...acc,
            [uuidv4()]: {
              id: multipleChoiceListForWorkStepsId,
              value: optionText,
            },
          };
        },
        {}
      );
      const checkStep: MultiplyCheckStepItem = {
        ...basicCheckStep,
        type: stepTypeName,
        radios: radios,
      };

      return checkStep;
    } else if (stepTypeName === KindOfCheckStep.Text) {
      const checkStep: TextCheckStepItem = {
        ...basicCheckStep,
        type: stepTypeName,
        value: '',
      };

      return checkStep;
    } else if (
      stepTypeName === KindOfCheckStep.Decimal ||
      stepTypeName === KindOfCheckStep.Integer
    ) {
      const checkStep: NumberCheckStepItem = {
        ...basicCheckStep,
        type: stepTypeName,
        value: 0,
      };

      return checkStep;
    } else {
      const checkStep: InspectionCheckStepItem = {
        ...basicCheckStep,
        type: stepTypeName as KindOfCheckStep.Inspection,
        value: false,
      };

      return checkStep;
    }
  });

  const normalizeMaintenances = {
    ...maintenance,
    maintenancesWorkSteps: normalizeMaintenanceWorkOrders,
  };

  const initialDateSelector: DateSelectorDto = {
    timeConstraint: {
      timeslots: [
        {
          start: {
            dateTime: maintenance.rangeStartDate,
            timeZone: 'Turkey Standard Time',
          },
          end: {
            dateTime: maintenance.rangeStartDate,
            timeZone: 'Turkey Standard Time',
          },
        },
      ],

      recurrence: {
        range: {
          type: 'endDate',
          startDate: maintenance.rangeStartDate,
          endDate: maintenance.rangeEndDate,
          startTime: maintenance?.timeOfOccurrence,
          recurrenceTimeZone: 'Turkey Standard Time',
        },
        pattern: {
          type: maintenance.patternType as string,
          interval: maintenance.patternInterval ? +maintenance.patternInterval : null,
          daysOfWeek: maintenance?.patternDaysOfWeek ? maintenance?.patternDaysOfWeek : [],
          dayOfMonth: maintenance?.patternDayOfMonth ? +maintenance?.patternDayOfMonth : null,
        },
      },
    },
  } as DateSelectorDto;

  const initialValuesGeneral: CreateMaintenanceGeneralV2Dto = {
    title: maintenance.title,
    maintenancePriorityId: maintenance.maintenancePriorityId,
    estimatedDuration: maintenance.estimatedDuration,
    startDate: maintenance.dueDate,
    maintinanceCategoryId: maintenance.maintinanceCategoryId,
    assignToId: maintenance.assignToId,
    description: maintenance.description,
    maintenanceOccurrenceId:
      maintenance.maintenanceOccurrenceId !== null ? maintenance.maintenanceOccurrenceId : 0,
    frequanceNumeric: maintenance.frequanceNumeric,
    nonCurrAssetCardId: maintenance.nonCurrAssetCardId,
    requestReasonId: maintenance.requestReasonId,
    siteId: maintenance.siteId,
    barcode: maintenance.barcode,
    additionalAssignToIds: maintenance?.maintenanceAssignTos.map((user) => user.userId),
    isRequest: maintenance.maintenanceTypeId === 1,
    timeOfOccurrence: maintenance?.timeOfOccurrence,
    nonCurrAssetCardCategoryId: maintenance?.nonCurrAssetCardCategoryId,
    buttonPermit: maintenance.buttonPermit,
    nonCurrAssetId: maintenance.nonCurrAssetId,
    dateSelector:
      maintenance.maintenanceTypeId === 2 ? initialDateSelector : ({} as DateSelectorDto),
  };

  const initialValuesParts: MpModelDto[] = maintenance.maintenancesParts.map((part) => ({
    partsAndInventoriesId: part.partsAndInventoriesId,
    quantity: part.quantity,
    maintenancesPartsId: part.maintenancesPartsId,
  }));

  const initialValuesWorkOrders: CheckStepValue[] = normalizeMaintenanceWorkOrders;

  return (
    <Outlet
      context={
        {
          initialValues: null,
          entity: maintenance,
          initialValuesGeneral,
          initialValuesParts,
          initialValuesWorkOrders: maintenance.maintenancesWorkSteps,
        } as IOutletMaintenance
      }
    />
  );
};

export default Maintenance;
