import { api } from '@services/Axios';

import type {
  ContractFileModel,
  ContractModel,
  CreateContractDto,
  FilterContractDto,
  UpdateContractDto,
} from '@model/properties/Contract';
import type { ApiResponse, CustomResponse } from '@services/Axios';

const ContractEndpoint = '/Contract';
const ContractFileEndpoint = '/ContractFile';

export const ContractRepository = {
  async fetchAll() {
    const response: ApiResponse<ContractModel[]> = await api.get(
      `${ContractEndpoint}/GetContractList`
    );

    return response.data;
  },

  async add(dto: CreateContractDto) {
    const response: ApiResponse<ContractModel> = await api.post(
      `${ContractEndpoint}/AddContract`,
      dto
    );

    return response.data;
  },

  async fetchById(id: string) {
    const response: ApiResponse<ContractModel> = await api.get(
      `${ContractEndpoint}/GetContractById/${id}`
    );

    return response.data;
  },

  async fetchAllByFilter(dto: FilterContractDto) {
    const response: ApiResponse<ContractModel[]> = await api.post(
      `${ContractEndpoint}/GetContractsListByFilter`,
      dto
    );

    return response.data;
  },

  /**
   *  Fetch all files for a particular contract
   *
   * @param id id of the contract
   *
   */
  async fetchContractFilesById(id: string) {
    const response: ApiResponse<ContractFileModel[]> = await api.get(
      `${ContractFileEndpoint}/GetContractFilesList/${id}`
    );

    return response.data;
  },

  /**
   *  Add files for a particular contract
   *
   * @param {FormData} formData files: File[], contractId: string
   *
   * @param id
   */
  async addContractFiles(formData: FormData, id: number | string) {
    const response: ApiResponse<CustomResponse<ContractFileModel>[]> = await api.post(
      `${ContractFileEndpoint}/UploadContractFiles?contractId=${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  },

  /**
   * Download contract file by id
   *
   * @param {id} id of the contract file
   */
  async downloadContractFileById(id: number) {
    const response: ApiResponse<ContractFileModel> = await api.post(
      `${ContractFileEndpoint}/DownloadContractFile`,
      {
        contractFilesId: id,
      }
    );

    return response.data;
  },

  async update(dto: UpdateContractDto) {
    const response: ApiResponse<ContractModel> = await api.post(
      `${ContractEndpoint}/UpdateContract`,
      dto
    );

    return response.data;
  },

  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${ContractEndpoint}/RemoveByIdList`, {
      contractIds: ids,
    });

    return response.data;
  },

  async removeFileById(id: string | number) {
    const respose: ApiResponse<null> = await api.post(`${ContractFileEndpoint}/RemoveByIdList`, {
      contractFilesIds: [id],
    });

    return respose.data;
  },
};
