import { api } from '@services/Axios';

import type {
  AsAtModelModel,
  CreateAsAtModelDto,
  UpdateAsAtModelDto,
} from '@model/properties/AsAt/Model';
import type { ApiResponse } from '@services/Axios';

const AssetModelUrl = '/NonCurrAssetsCardModels';

const AsAtModelRepository = {
  async fetchAll() {
    const response: ApiResponse<AsAtModelModel[]> = await api.get(
      `${AssetModelUrl}/GetNonCurrAssetsCardModelsList`
    );

    return response.data;
  },

  async fetchById(id: string) {
    const response: ApiResponse<AsAtModelModel> = await api.get(
      `${AssetModelUrl}/GetNonCurrAssetsCardModelsById/${id}`
    );

    return response.data;
  },
  async add(dto: CreateAsAtModelDto) {
    const response: ApiResponse<AsAtModelModel> = await api.post(
      `${AssetModelUrl}/AddNonCurrAssetsCardModel`,
      dto
    );

    return response.data;
  },
  async update(dto: UpdateAsAtModelDto) {
    const response: ApiResponse<AsAtModelModel> = await api.post(
      `${AssetModelUrl}/UpdateNonCurrAssetsCardModel`,
      dto
    );

    return response.data;
  },
  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${AssetModelUrl}/RemoveByIdList`, {
      modelIds: ids,
    });

    return response.data;
  },
};

export { AsAtModelRepository };
