import React from 'react';

import { RoleAuthContext } from './RoleAuthContext';

import type { RoleFunctionNameModel } from '@model/users/Role';
import type { ReactNode } from 'react';
import type { RoleAuthContextValue } from '../_utils';

interface RoleAuthProviderProps {
  children: ReactNode;

  isPreview: boolean;

  onChange: (value: RoleFunctionNameModel) => void;
}

export const RoleAuthProvider = (props: RoleAuthProviderProps) => {
  const { children, isPreview, onChange } = props;

  const value: RoleAuthContextValue = {
    isPreview,
    onChange,
  };

  return <RoleAuthContext.Provider value={value}>{children}</RoleAuthContext.Provider>;
};
