import { createSlice } from '@reduxjs/toolkit';
import {
  addCountPlan,
  getCountPlanById,
  getCountPlanResults,
  getCountPlans,
  removeCountPlanById,
  updateCountPlan,
  updateCountPlanResult,
} from '@store/actions/management/CountPlan';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AssetModel } from '@model/management/Asset';
import type {
  CountPlanModel,
  CountPlanResultModel,
  FilterCountPlanDto,
} from '@model/management/CountPlan';

type CountPlanState = {
  countPlansList: CountPlanModel[];
  countPlanResultsList: CountPlanResultModel[] | AssetModel[];
  countPlan: null | CountPlanModel;
  countPlanStatus: LoadingStatus;
  countPlanError: null | string;
  countPlanResultSettings: {
    totalPage: number;
    totalRecords: number | null;
  };
  filterOptions: FilterCountPlanDto;
};

const initialState: CountPlanState = {
  countPlansList: [],
  countPlanResultsList: [],
  countPlan: null,
  countPlanStatus: 'idle',
  countPlanError: null,
  filterOptions: {
    page: 1,
    perPage: 6,
    okDuringTheCounting: false,
    showDifferentSitesNonCurrAssets: false,
    unKnownBarcodeList: false,
    notFoundDuringTheCounting: false,
  },
  countPlanResultSettings: {
    totalPage: 0,
    totalRecords: 0,
  },
};

export const CountPlanSlice = createSlice({
  name: 'countPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountPlans.pending, (state) => {
        state.countPlanStatus = 'pending';
        state.countPlanError = null;
      })
      .addCase(getCountPlans.fulfilled, (state, action) => {
        state.countPlansList = action.payload;
        state.countPlanStatus = 'idle';
      })
      .addCase(getCountPlans.rejected, (state) => {
        state.countPlanStatus = 'idle';
      });

    builder
      .addCase(addCountPlan.pending, (state) => {
        state.countPlanStatus = 'pending';
        state.countPlanError = null;
      })
      .addCase(addCountPlan.fulfilled, (state, { payload }) => {
        state.countPlanStatus = 'idle';
        state.countPlansList = [payload, ...state.countPlansList];
      })
      .addCase(addCountPlan.rejected, (state, { payload }) => {
        state.countPlanStatus = 'idle';
        if (payload) {
          state.countPlanError = payload;
        }
      });

    builder
      .addCase(getCountPlanById.pending, (state) => {
        state.countPlanStatus = 'pending';
        state.countPlanError = null;
      })
      .addCase(getCountPlanById.fulfilled, (state, { payload }) => {
        state.countPlanStatus = 'idle';
        state.countPlan = payload;
      })
      .addCase(getCountPlanById.rejected, (state, { payload }) => {
        state.countPlanStatus = 'idle';
        if (payload) {
          state.countPlanError = payload;
        }
      });

    builder
      .addCase(updateCountPlan.pending, (state) => {
        state.countPlanStatus = 'pending';
        state.countPlanError = null;
      })
      .addCase(updateCountPlan.fulfilled, (state, { payload }) => {
        state.countPlanStatus = 'idle';
        state.countPlansList = payload.countPlansList;
        state.countPlan = payload.countPlan;
      })
      .addCase(updateCountPlan.rejected, (state, { payload }) => {
        state.countPlanStatus = 'idle';
        if (payload) {
          state.countPlanError = payload;
        }
      });

    builder
      .addCase(removeCountPlanById.pending, (state) => {
        state.countPlanStatus = 'pending';
        state.countPlanError = null;
      })
      .addCase(removeCountPlanById.fulfilled, (state, { payload }) => {
        const canceledCountPlan = state.countPlan ? {...state.countPlan, prdCountStatusId: 2} : null;
        {
          state.countPlanStatus = 'idle';
          state.countPlan = canceledCountPlan;
          state.countPlansList = payload;
        }
      })
      .addCase(removeCountPlanById.rejected, (state, { payload }) => {
        state.countPlanStatus = 'idle';
        if (payload) {
          state.countPlanError = payload;
        }
      });

    builder
      .addCase(getCountPlanResults.pending, (state) => {
        state.countPlanStatus = 'pending';
        state.countPlanError = null;
        state.countPlanResultSettings.totalRecords = null;
      })
      .addCase(getCountPlanResults.fulfilled, (state, action) => {
        if (action.payload.options) {
          state.filterOptions = {
            ...state.filterOptions,
            ...action.payload.options,
          };
        }
        state.countPlanResultsList = action.payload.countPlanResults;
        state.countPlanResultSettings = action.payload.countPlanResultSettings;
        state.countPlanStatus = 'idle';
      })
      .addCase(getCountPlanResults.rejected, (state, action) => {
        state.countPlanStatus = 'idle';
        state.countPlanResultsList = [];
        state.countPlanResultSettings.totalRecords = 0;
        if (action.payload) {
          state.countPlanError = action.payload;
        }
      });

    builder
      .addCase(updateCountPlanResult.pending, (state) => {
        state.countPlanStatus = 'pending';
        state.countPlanError = null;
      })
      .addCase(updateCountPlanResult.fulfilled, (state) => {
        state.countPlanStatus = 'idle';
      })
      .addCase(updateCountPlanResult.rejected, (state, action) => {
        state.countPlanStatus = 'idle';
        if (action.payload) {
          state.countPlanError = action.payload;
        }
      });
  },
});

const CountPlanReducer = CountPlanSlice.reducer;

export { CountPlanReducer };
