import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormProfile } from '@components/views/Forms/profile';
import { useOutletProfile } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updateProfile } from '@store/actions/profile/Profile';

import type { UpdateProfileDto } from '@model/profile/Profile';

function EditProfile() {
  const dispatch = useAppDispatch();
  const { entity } = useOutletProfile();

  const { t } = useTranslation();
  const navigate = useAppNavigate();

  const onFinish = (dto: UpdateProfileDto) => {
    dispatch(
      updateProfile({
        ...dto,
        userCode: entity.userCode,
        userTitleId: entity.userTitleId,
      })
    )
      .unwrap()
      .then(() => {
        ApiNotifications.update(t('pages_single.Profile'));
        navigate(paths.profile.tabs.general.default);
      });
  };

  return <FormProfile onFinish={onFinish} />;
}

export default EditProfile;
