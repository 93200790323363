import { api } from '@services/Axios';

import type {
  CreateSiteDto,
  FilterSiteDto,
  SiteModel,
  UpdateSiteDto,
  UpdateSiteParentDto,
} from '@model/properties/Site';
import type { ApiResponse } from '@services/Axios';

const SiteEndpoint = '/Sites';

export const SiteRepository = {
  async fetchAll() {
    const response: ApiResponse<SiteModel[]> = await api.get(`${SiteEndpoint}/GetSitesList`);

    return response.data;
  },

  async fetchAllByFilter(dto: FilterSiteDto) {
    const response: ApiResponse<SiteModel[]> = await api.post(
      `${SiteEndpoint}/GetSiteListByFilter`,
      dto
    );

    return response.data;
  },

  async add(dto: CreateSiteDto) {
    const response: ApiResponse<SiteModel[]> = await api.post(`${SiteEndpoint}/AddSite`, dto);

    return response.data;
  },

  async fetchById(id: string) {
    const response: ApiResponse<SiteModel[]> = await api.get(`${SiteEndpoint}/GetSitesById/${id}`);

    return response.data;
  },
  async update(dto: UpdateSiteDto) {
    const response: ApiResponse<SiteModel> = await api.post(`${SiteEndpoint}/UpdateSite`, dto);

    return response.data;
  },

  async removeById(ids: number[]) {
    const response: ApiResponse<null> = await api.post(`${SiteEndpoint}/RemoveByIdList`, {
      siteIds: ids,
    });

    return response.data;
  },

  async updateParentRelations(dto: UpdateSiteParentDto) {
    const response: ApiResponse<null> = await api.post(
      `${SiteEndpoint}/ChangeSiteParentReleations`,
      dto
    );

    return response.data;
  },
};
