import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId } from '@hooks';
import { Loader } from '@shared/UI';
import { getAsAtCategoryById } from '@store/actions/properties/AsAt/Category';
import { selectAsAtCategory } from '@store/selectors/properties/AsAt/Category';

import type { IOutletAssetCategory } from '@constants/OutletContextEntityPages';
import type { CreateAsAtCategoryDto } from '@model/properties/AsAt/Category';

function AssetCategory() {
  const assetCategory = useAppSelector(selectAsAtCategory);
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();

  const condition = !assetCategory || assetCategory.nonCurrAssetCardCategoryId !== +decryptedId;

  useEffect(() => {
    if (condition) {
      dispatch(getAsAtCategoryById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateAsAtCategoryDto = {
    nonCurrAssetCardCategoryCode: assetCategory.nonCurrAssetCardCategoryCode,
    name: assetCategory.name,
    parentNonCurrAssetCardCategoryId: assetCategory.parentNonCurrAssetCardCategoryId,
  };

  return <Outlet context={{ initialValues, entity: assetCategory } as IOutletAssetCategory} />;
}

export default AssetCategory;
