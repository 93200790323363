import React from 'react';
import { useTranslation } from 'react-i18next';

import { CrossIcon, MultiplyChoice } from '@icon/icon-components';
import { Typography } from 'antd';
import cl from 'classnames';

import { useCheckStepContext } from '../../../_utils';

import type { ChangeEvent } from 'react';
import type { MultipleVariantProps } from '../../../_utils';

import styles from './MultipleVariant.module.scss';

export const MultipleVariant = (props: MultipleVariantProps) => {
  const { option, type } = props;

  const { t } = useTranslation();

  const { step, actions } = useCheckStepContext();

  const classes = cl(styles.multipleVariant, {
    [styles.multipleVariantActive]: step.isActive,
    [styles.multipleVariantHasError]: option && option.hasError,
  });

  const classesInputValue = cl(styles.inputValue, {
    [styles.inputValueEmpty]: option?.title.length === 0,
  });

  const handleAddClick = () => {
    actions?.onAddNewOption?.(step);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (option) {
      actions?.onChangeOptionTitle?.(step, option, value);
    }
  };

  const handleRemoveClick = () => {
    if (option) {
      actions?.onRemoveOption?.(step, option);
    }
  };

  const getContent = () => {
    if (type === 'button') {
      return (
        <>
          <button type="button" className={styles.addOption} onClick={handleAddClick}>
            + {t('titles.Add_Option')}
          </button>
        </>
      );
    }

    return (
      <>
        {step.isActive ? (
          <input
            className={styles.input}
            value={option?.title}
            placeholder={`Option ${option?.rowId}`}
            onChange={handleInputChange}
          />
        ) : (
          <Typography.Text ellipsis className={classesInputValue}>
            {option && option.title.length > 0 ? option.title : `Option ${option?.rowId}`}
          </Typography.Text>
        )}
        <button type="button" className={styles.removeOption} onClick={handleRemoveClick}>
          <CrossIcon />
        </button>
      </>
    );
  };

  return (
    <div className={classes}>
      <span>
        <MultiplyChoice />
      </span>
      {getContent()}
    </div>
  );
};
