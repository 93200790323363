import { api } from '@services/Axios';

import type {
  AssetPartModel,
  CreateAssetPartDto,
  UpdateAssetPartDto,
} from '@model/management/AssetParts';
import type { ApiResponse } from '@services/Axios';

const AssetPartURL = 'NonCurrAssetParts';

export const AssetPartsRepository = {
  getByAssetId: async (id: number | string) => {
    const response: ApiResponse<AssetPartModel[]> = await api.get(
      `${AssetPartURL}/NonCurrAssetPartsByNonCurrAssetId/${id}`
    );

    return response.data;
  },

  add: async (dto: CreateAssetPartDto) => {
    const response: ApiResponse<AssetPartModel[]> = await api.post(
      `${AssetPartURL}/AddNonCurrAssetParts`,
      dto
    );

    return response.data;
  },

  update: async (dto: UpdateAssetPartDto) => {
    const response: ApiResponse<AssetPartModel[]> = await api.post(
      `${AssetPartURL}/UpdateNonCurrAssetPart`,
      dto
    );

    return response.data;
  },

  delete: async (ids: string[] | number[]) => {
    const response: ApiResponse<null> = await api.post(`${AssetPartURL}/RemoveByIdList`, {
      nonCurrAssetsPartsIds: ids,
    });

    return response.data;
  },
};
