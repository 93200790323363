import React from 'react';

import { FormPartAndInventoryModel } from '@components/views/Forms/properties';
import { CheckAccess } from '@components/wrappers';
import { useOutletPartAndInventoryModel } from '@constants/OutletContextEntityPages';
import { useAppDispatch, useAppNavigate, useGetAccess } from '@hooks';
import { paths } from '@routes/paths';
import { ApiNotifications } from '@services/Notifications/adapters';
import { updatePaIModel } from '@store/actions/properties/PaI/Model';

import type { CreatePaIModelDto } from '@model/properties/PaI/Model';

function EditPartAndInventoryModel() {
  const { initialValues, entity } = useOutletPartAndInventoryModel();
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { paiAttrAccess } = useGetAccess();

  const onFinish = async (dto: CreatePaIModelDto) => {
    try {
      const response = await dispatch(
        updatePaIModel({
          ...dto,
          partsAndInventoriesCardModelId: entity.partsAndInventoriesCardModelId,
        })
      ).unwrap();
      ApiNotifications.update(response.particularModel.name);
      navigate(paths.partAndInventoryAttributes.tabs.model.default);
    } catch (error) {
      ApiNotifications.error(error);
    }
  };

  return (
    <CheckAccess hasAccess={paiAttrAccess.edit} redirectTo={paths.partAndInventoryAttributes.tabs.model.default}>
      <FormPartAndInventoryModel
        initialValues={initialValues}
        onFinish={onFinish}
        header={{
          title: entity.name,
        }}
      />
    </CheckAccess>
  );
}

export default EditPartAndInventoryModel;
