import React from 'react';

import { useAppSelector } from '@hooks';
import { Bell } from '@icon/icon-components';
import { Badge, Popover } from '@shared/UI';
import { selectAlertsReadAndUnread } from '@store/selectors/extra/Alert';

import { ContentNotifications } from './ContentNotifications';

const Actions = () => {
  const { unreadAlerts } = useAppSelector(selectAlertsReadAndUnread);

  return (
    <>
      <Popover trigger="click" content={<ContentNotifications unreadAlerts={unreadAlerts} />}>
        <Badge
          offset={[0, 2]}
          count={unreadAlerts.length}
          style={{
            backgroundColor: '#00458A',
          }}
        >
          <Bell
            style={{
              cursor: 'pointer',
            }}
          />
        </Badge>
      </Popover>
    </>
  );
};

export default Actions;
