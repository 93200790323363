import { createSlice } from '@reduxjs/toolkit';
import {
  addTitle,
  getTitleById,
  getTitles,
  removeTitleById,
  updateTitle,
} from '@store/actions/user/Title';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { TitleModel } from '@model/users/Title';

type TitleState = {
  titlesList: TitleModel[];
  titleStatus: LoadingStatus;
  title: TitleModel | null;
  titleError: string | null;
};

const initialState: TitleState = {
  titlesList: [],
  titleError: null,
  title: null,
  titleStatus: 'idle',
};

const TitleSlice = createSlice({
  name: 'Title',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTitles.pending, (state) => {
        state.titleStatus = 'pending';
        state.titleError = null;
      })
      .addCase(getTitles.fulfilled, (state, { payload }) => {
        state.titleStatus = 'idle';
        state.titlesList = payload;
      })
      .addCase(getTitles.rejected, (state, { payload }) => {
        state.titleStatus = 'failed';
        state.titleError = payload as string;
      });
    builder
      .addCase(addTitle.pending, (state) => {
        state.titleError = null;
        state.titleStatus = 'pending';
      })
      .addCase(addTitle.fulfilled, (state, { payload }) => {
        state.titleStatus = 'idle';
        state.titlesList = [...state.titlesList, payload];
      })
      .addCase(addTitle.rejected, (state) => {
        state.titleStatus = 'idle';
      });
    builder
      .addCase(getTitleById.pending, (state) => {
        state.titleError = null;
        state.titleStatus = 'pending';
      })
      .addCase(getTitleById.fulfilled, (state, { payload }) => {
        state.titleStatus = 'idle';
        state.title = payload;
      })
      .addCase(getTitleById.rejected, (state) => {
        state.titleStatus = 'idle';
      });
    builder
      .addCase(updateTitle.pending, (state) => {
        state.titleError = null;
        state.titleStatus = 'pending';
      })
      .addCase(updateTitle.fulfilled, (state, { payload }) => {
        state.titleStatus = 'idle';
        state.title = payload.title;
        state.titlesList = payload.titles;
      })
      .addCase(updateTitle.rejected, (state) => {
        state.titleStatus = 'idle';
      });
    builder
      .addCase(removeTitleById.pending, (state) => {
        state.titleError = null;
        state.titleStatus = 'pending';
      })
      .addCase(removeTitleById.fulfilled, (state, { payload }) => {
        state.titleStatus = 'idle';
        state.titlesList = payload;
      })
      .addCase(removeTitleById.rejected, (state) => {
        state.titleStatus = 'idle';
      });
  },
});

const TitleReducer = TitleSlice.reducer;

export { TitleReducer };
