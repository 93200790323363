import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@shared/UI';
import cl from 'classnames';

import { useCheckStepContext } from '../_utils';
import { StepCount } from '../StepCount';

import type { ChangeEvent } from 'react';

import styles from './Title.module.scss';

export const Title = () => {
  const { t } = useTranslation();

  const { step, actions, type } = useCheckStepContext();

  const classes = cl(styles.titleInput, {
    [styles.titleInputHasError]: step.hasError && type !== 'workStep',
  });

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    actions?.onChangeTitle?.(step.uuid, value);
  };

  return (
    <div className={classes}>
      <StepCount />
      <Input
        value={step.title}
        onChange={handleChangeTitle}
        placeholder={t('titles.Untitled_Question')}
        readOnly={!step.isActive}
      />
    </div>
  );
};
