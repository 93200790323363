import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CloudUploadOutlined } from '@ant-design/icons';
import { useUploadFile } from '@hooks';
import { Button } from '@shared/UI';
import { v4 as uuidv4 } from 'uuid';

import type { FileWithID } from '@interfaces/interfaces';

import './UploaderFiles.scss';

export interface UploaderFilesProps<T extends Record<string, any>> {
  onChange?: (files: FileWithID[]) => void;
  value?: T[];
  maxFiles?: number;
  isModal?: boolean;

  showErrorNotification?: boolean;
}

function UploaderFiles<T>(props: UploaderFilesProps<T>) {
  const {
    onChange: onChangeProps,
    value,
    maxFiles,
    showErrorNotification = true
  } = props;

  const {t} = useTranslation();

  const [fileState, setFileState] = React.useState<FileWithID[]>(() => {
    if (value) {
      return value.map((file) => ({
        file,
        id: uuidv4(),
      })) as any as FileWithID[];
    }
    return [];
  });

  const {onChange, inputRef} = useUploadFile({
    cb: (files) => {
      const newFiles = files.map((file) => ({
        id: uuidv4(),
        file,
      }));

      setFileState((prevFiles) => [...prevFiles, ...newFiles]);
    },
    currentFilesLength: fileState?.length || 0,
    maxPiecesFiles: maxFiles,
    showErrorNotification
  });

  useEffect(() => {
    if (fileState.length > 0) {
      onChangeProps?.(fileState);
    }
  }, [fileState]);

  const fileClass = props.isModal ? 'uploader-files-modal' : 'uploader-files';

  return (
    <div className={fileClass}>
      <input
        className="uploader-files__input"
        id="file-uploader"
        onChange={onChange}
        ref={inputRef}
        type="file"
        multiple
      />

      {props.isModal ? (
        <>
          <span/>
          <p
            className="uploader-files-modal__text">{t('titles.Upload_Contract')}</p>
        </>
      ) : (
        <Button className="uploader-files__button" icon={<CloudUploadOutlined/>}
                type="primary">
          <label htmlFor="file-uploader">{t('titles.Upload')}</label>
        </Button>
      )}
    </div>
  );
}

export {UploaderFiles};
