import * as React from 'react';

import type { SVGProps } from 'react';

export const SmallImagePreviewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
    <path
      fill="#BDD4ED"
      d="M21.75 4.262H2.25a.75.75 0 0 0-.75.75v15c0 .415.335.75.75.75h19.5a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75ZM7.922 7.637c.827 0 1.5.673 1.5 1.5 0 .828-.673 1.5-1.5 1.5s-1.5-.672-1.5-1.5c0-.827.673-1.5 1.5-1.5Zm12.044 10.245a.19.19 0 0 1-.122.044H4.155a.188.188 0 0 1-.144-.31l3.992-4.733a.189.189 0 0 1 .264-.024c.007.007.017.014.024.024l2.33 2.765 3.705-4.394a.189.189 0 0 1 .265-.024c.007.007.016.014.023.024l5.381 6.365a.191.191 0 0 1-.028.263Z"
    />
  </svg>
);
