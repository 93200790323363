import React from 'react';

import { Collapse as AntdCollapse } from 'antd';
import cl from 'classnames';

import type { CollapseProps as AntdCollapseProps } from 'antd';

import './Collapse.scss';

export interface CollapseProps extends Omit<AntdCollapseProps, 'className'> {
  className?: string | string[];
}

interface CollapseInterface {
  (props: CollapseProps): React.ReactElement;

  Panel: typeof AntdCollapse.Panel;
}

const Collapse: CollapseInterface = (props) => {
  const { className, bordered = false, expandIconPosition = 'end', ...rest } = props;

  const collapseClasses = cl('custom-collapse', className);

  return (
    <AntdCollapse
      bordered={bordered}
      expandIconPosition={expandIconPosition}
      className={collapseClasses}
      {...rest}
    />
  );
};

Collapse.Panel = AntdCollapse.Panel;

export { Collapse };
