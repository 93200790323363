import React, { memo, useMemo } from 'react';

import { useClassPrefix } from '@hooks';

import { RoleItem } from '../RoleItem';

import type { RoleFunctionNameModel } from '@model/users/Role';

import './RolesList.scss';

interface RolesListProps {
  authorizationsList: Array<RoleFunctionNameModel>;
}

export const RolesList = memo((props: RolesListProps) => {
  const { authorizationsList } = props;

  const { prefix } = useClassPrefix('role-auth-list');

  const renderList = useMemo(
    () =>
      authorizationsList.map((authItem) => {
        return <RoleItem authItem={authItem} key={authItem.roleFunctionNameId} />;
      }),
    [authorizationsList]
  );

  return <ul className={prefix}>{renderList}</ul>;
});
