import { getDaysOfWeekByDto } from '@components/views/Forms/managment/Maintenance/GeneralV2/_util/getDaysOfWeekByDto';
import { IterationMonthType } from '@constants/IterationMonthType';
import { IterationValue } from '@constants/iterationValue';
import moment from 'moment';

import { defaultIterationState } from './defaultIterationState';

import type { IterationModalState } from '@components/views/Forms/managment/Maintenance/GeneralV2/_util/types';
import type { DateSelectorDto } from '@model/management/Maintenance';

export function createInitialIterationState(
  initialValues?: DateSelectorDto
): IterationModalState | null {
  if (!initialValues) return null;
  const isHasValues = Object.keys(initialValues).length > 0;
  if (!isHasValues) return null;

  const {
    recurrence: { range, pattern },
  } = initialValues.timeConstraint;

  let monthType = defaultIterationState.monthType;

  let repeatType = defaultIterationState.repeatType;

  if (pattern?.type) {
    if (pattern.type === IterationMonthType.ByDay) {
      monthType = IterationMonthType.ByDay;
      repeatType = IterationValue.EVERY_MONTH;
    }
    if (pattern.type === IterationMonthType.ByWeek) {
      monthType = IterationMonthType.ByWeek;
      repeatType = IterationValue.EVERY_MONTH;
    }

    if (pattern.type === IterationValue.EVERY_DAY) {
      repeatType = IterationValue.EVERY_DAY;
    }

    if (pattern.type === IterationValue.EVERY_WEEK) {
      repeatType = IterationValue.EVERY_WEEK;
    }
  }

  const daysOfWeek = pattern.daysOfWeek
    ? getDaysOfWeekByDto(pattern.daysOfWeek || [], repeatType)
    : defaultIterationState.daysOfWeek;

  return {
    startDate: range?.startDate ? moment(range.startDate) : defaultIterationState.startDate,
    startTime: range?.startTime ? moment(range.startTime) : defaultIterationState.startTime,
    endDate: range?.endDate ? moment(range.endDate) : defaultIterationState.endDate,
    repeatNumber:
      typeof pattern?.interval === 'number' ? pattern.interval : defaultIterationState.repeatNumber,
    monthType,
    daysOfWeek,
    repeatType,
  };
}
