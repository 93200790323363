import React from 'react';

import cl from 'classnames';

import './PreviewField.scss';

interface PreviewFieldProps {
  className?: string | string[];
  children?: React.ReactNode;
  typeField?: 'textarea' | 'input' | 'color-picker' | 'property-value' | 'input-focus';
  hiddenColorLabel?: boolean;
  colorPickerValue?: string;
  style?: React.CSSProperties;
  inputTextCenter?: boolean;
}

function PreviewField(props: PreviewFieldProps) {
  const {
    className,
    children,
    typeField = 'input',
    hiddenColorLabel = false,
    colorPickerValue,
    style,
    inputTextCenter,
  } = props;

  const previewClass = cl(
    'common-form-preview-field',
    `common-form-preview-field--${typeField}`,
    {
      [`common-form-preview-field--input--text-center`]: inputTextCenter,
    },
    className
  );

  const renderContent = () => {
    if (typeField === 'color-picker') {
      return (
        <div className={cl(`common-form-preview-content--${typeField}`)}>
          <span
            className="common-form-preview__color-picker-value"
            style={{
              backgroundColor: colorPickerValue,
            }}
          />
          {!hiddenColorLabel && (
            <span className="common-form-preview__color-picker-label">Color</span>
          )}
        </div>
      );
    }

    return <div className="common-form-preview-content">{children}</div>;
  };

  return (
    <div className={previewClass} style={style}>
      {renderContent()}
    </div>
  );
}

export default PreviewField;
