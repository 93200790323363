import type { RootState } from '@store/rootReducer';

export const selectRoleState = (state: RootState) => state.RoleReducer;

export const selectRoleList = (state: RootState) => selectRoleState(state).roleList;

export const selectRole = (state: RootState) => selectRoleState(state).role;

export const selectRoleStatus = (state: RootState) => selectRoleState(state).roleStatus;

export const selectRoleError = (state: RootState) => selectRoleState(state).roleError;
