import React from 'react';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from '@components/App/app';
import {Loader} from '@shared/UI';
import store from '@store/store';
import Schema from 'async-validator';

import './config/config';

import i18next from './i18n';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'swiper/swiper-bundle.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'swiper/swiper.min.css';
import 'antd/dist/antd.css';
import 'react-image-crop/dist/ReactCrop.css';
import './styles/global.scss';

// interface EventTargetT {
//   state?: 'activated';
// }

Schema.warning = function () {};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <React.Suspense fallback={<Loader middle size="large" />}>
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </React.Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     const waitingServiceWorker = registration.waiting;
//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener('statechange', (event) => {
//         if ((event.target as EventTargetT).state === 'activated') window.location.reload();
//       });
//       waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//     }
//   },
// });
