import * as React from 'react';

import type { SVGProps } from 'react';

const FlagTurkey = (props: SVGProps<SVGSVGElement>) => (
  <svg 
    width="32" 
    height="33" 
    viewBox="0 0 32 33" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
  <path opacity="0.8" d="M32 24.0683C32 25.0112 31.6254 25.9156 30.9586 26.5824C30.2918 27.2492 29.3874 27.6238 28.4444 27.6238H3.55556C2.61256 27.6238 1.70819 27.2492 1.0414 26.5824C0.374602 25.9156 0 25.0112 0 24.0683V8.06825C0 7.12526 0.374602 6.22089 1.0414 5.55409C1.70819 4.8873 2.61256 4.5127 3.55556 4.5127H28.4444C29.3874 4.5127 30.2918 4.8873 30.9586 5.55409C31.6254 6.22089 32 7.12526 32 8.06825V24.0683Z" fill="#E30917"/>
  <path d="M14.2239 21.4016C12.8094 21.4016 11.4529 20.8397 10.4527 19.8395C9.4525 18.8393 8.8906 17.4827 8.8906 16.0682C8.8906 14.6537 9.4525 13.2972 10.4527 12.297C11.4529 11.2968 12.8094 10.7349 14.2239 10.7349C15.3884 10.7349 16.4639 11.1127 17.3413 11.7464C16.7296 11.0507 15.9766 10.4933 15.1325 10.1115C14.2884 9.72965 13.3726 9.53218 12.4462 9.53223C10.7127 9.53223 9.05024 10.2208 7.82451 11.4466C6.59877 12.6723 5.91016 14.3348 5.91016 16.0682C5.91004 16.9266 6.07901 17.7766 6.40742 18.5697C6.73584 19.3628 7.21726 20.0834 7.82419 20.6905C8.43113 21.2975 9.15169 21.779 9.94473 22.1075C10.7378 22.436 11.5878 22.6051 12.4462 22.6051C14.3973 22.6051 16.1439 21.7456 17.3413 20.39C16.4351 21.048 15.3438 21.4022 14.2239 21.4016ZM17.7022 16.2727L19.871 16.7722L20.0666 18.9882L21.2115 17.0807L23.3804 17.5793L21.919 15.9011L23.063 13.9927L21.0159 14.8638L19.5546 13.1847L19.7502 15.4016L17.7022 16.2727Z" fill="#EEEEEE"/>
  </svg>
);

export { FlagTurkey };
