import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import { BasicTablePage } from '@components/common';
import { useAppSelector } from '@hooks';
import { compare } from '@shared/utils/functions';
import { selectTitlesList, selectTitleStatus } from '@store/selectors/users/Title';

import type { ColumnsTableType } from '@components/common/BasicTablePage';
import type { TitleModel } from '@model/users/Title';

const columns: ColumnsTableType<TitleModel> = [
  {
    title: <Translation>{(t) => t('titles.Code')}</Translation>,
    dataIndex: 'userTitleCode',
    key: 'titleCode',
    ellipsis: true,
    sorter: (a, b) => compare(a.userTitleCode, b.userTitleCode),
  },
  {
    title: <Translation>{(t) => t('titles.Title')}</Translation>,
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    sorter: (a, b) => compare(a.title, b.title),
  },
];

function Titles() {
  const { t } = useTranslation();

  const listTitles = useAppSelector(selectTitlesList);
  const statusTitle = useAppSelector(selectTitleStatus);

  return (
    <BasicTablePage
      table={{
        loading: statusTitle === 'pending',
        dataSource: listTitles,
        columns,
        rowKey: (record) => record.userTitleId,
        dataSourceName: t('pages_plural.Titles'),
      }}
      header={{
        titleRedirectButton: t('titles.New_Item', { name: t('pages_single.Title') }),
        csvConfig: {
          data: listTitles,
          fileName: 'titles.csv',
          headers: [
            {
              label: 'Title code',
              key: 'userTitleCode',
            },
            {
              label: 'Title',
              key: 'title',
            },
          ],
        },
      }}
    />
  );
}

export { Titles };
