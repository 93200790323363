import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, SelectCompany, SelectSite } from '@components/common';
import { useAppSelector } from '@hooks';
import { CheckRound } from '@icon/icon-components';
import { selectUserAuthStatus } from '@store/selectors/users/UserAuthorization';
import { Col, Row } from 'antd';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { UserModel } from '@model/users/User';

const { CaseField, Item } = CommonForm;

function PreviewFormUserAuthorization(props: CommonPreviewProps<UserModel>) {
  const { entity, onDelete } = props;
  const statusUserAuthorization = useAppSelector(selectUserAuthStatus);
  const { t } = useTranslation();

  const checkAll = (name: string) => {
    return (
      <Item>
        <Row>
          <Col span={3}>
            <CheckRound />
          </Col>
          <Col span={20}>{t(`titles.All_${name}_Authorized`)} </Col>
        </Row>
      </Item>
    );
  };

  const optionsCompanies = useMemo(
    () =>
      entity.userAuthorizedCompanies.map((company) => ({
        companyId: company.company.companyId,
        value: company.company.name,
      })),
    [entity.userAuthorizedCompanies]
  );

  const optionsSites = useMemo(
    () =>
      entity.userAuthorizedSites.map((site) => ({
        siteId: site.site.siteId,
        value: site.site.name,
      })),
    [entity.userAuthorizedCompanies]
  );

  return (
    <CommonForm
      isPreview
      loading={statusUserAuthorization === 'pending'}
      initialValues={{
        companyIds: optionsCompanies,
        siteIds: optionsSites,
        userId: entity.userId,
      }}
      header={{
        title: `${entity.firstName} ${entity.lastName}`,
        onClickDelete: onDelete,
        hiddenDeleteButton: true,
      }}
    >
      <CaseField md={24} lg={12}>
        {!entity.isUserAreAuthorizedForTheAllCompanies ? (
          <SelectCompany name="companyIds" mode="multiple" disabled={true} placeholder="-" />
        ) : (
          checkAll('Companies')
        )}

        {!entity.isUserAreAuthorizedForTheAllSites ? (
          <SelectSite name="siteIds" mode="multiple" disabled={true} placeholder="-" />
        ) : (
          checkAll('Sites')
        )}
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormUserAuthorization };
