import React, { memo } from 'react';

import { Toolbar } from '@shared/UI';

import { Filter } from '../Filter';
import { LeftItems } from './LeftItems';

import type { ToolbarItem } from '@shared/UI';

import './AssetToolbar.scss';

export const AssetToolbar = memo(() => {
  const items: Array<ToolbarItem> = [
    {
      label: <LeftItems />,
      key: 'left-items',
    },

    {
      label: <Filter />,
      key: 'filter',
    },
  ];

  return <Toolbar items={items} className="asset-toolbar" />;
});
