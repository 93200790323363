import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getSites } from '@store/actions/properties/Site';
import { selectSitesList } from '@store/selectors/properties/Site';

const EntrySite = () => {
  useSetPageInfo({
    pageTitle: 'pages_single.Site',
    defaultPagePath: paths.site.default,
  });

  const sites = useAppSelector(selectSitesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!sites.length) {
      dispatch(getSites());
    }
  }, []);

  return <Outlet />;
};

export default EntrySite;
