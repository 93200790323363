import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { getContracts } from '@store/actions/properties/Contract';
import { selectContractsList } from '@store/selectors/properties/Contract';

function EntryContract() {
  useSetPageInfo({
    defaultPagePath: paths.contract.default,
    pageTitle: 'pages_single.Contract',
  });

  const listContract = useAppSelector(selectContractsList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listContract.length) {
      dispatch(getContracts());
    }
  }, []);

  return <Outlet />;
}

export default EntryContract;
