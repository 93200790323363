import { createSlice } from '@reduxjs/toolkit';
import { getAuthorizationNames, getAuthorizations } from '@store/actions/extra/Authorization';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AuthorizationFunctionModel, AuthorizationModel } from '@model/extra/Authorization';

type AuthorizationState = {
  listAuthorization: AuthorizationModel[];
  statusAuthorization: LoadingStatus;
  errorAuthorization: null | string;
  functionListAuthorization: AuthorizationFunctionModel[];
};

const initialState: AuthorizationState = {
  listAuthorization: [],
  errorAuthorization: null,
  statusAuthorization: 'idle',
  functionListAuthorization: [],
};

const AuthorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuthorizations.pending, (state) => {
        state.statusAuthorization = 'pending';
        state.errorAuthorization = null;
      })
      .addCase(getAuthorizations.fulfilled, (state, { payload }) => {
        state.statusAuthorization = 'idle';
        state.listAuthorization = payload;
      })
      .addCase(getAuthorizations.rejected, (state, action) => {
        state.statusAuthorization = 'idle';
        if (action.payload) {
          state.errorAuthorization = action.payload;
        }
      })
      .addCase(getAuthorizationNames.fulfilled, (state, { payload }) => {
        state.statusAuthorization = 'idle';
        state.functionListAuthorization = payload;
      });
  },
});
const AuthorizationReducer = AuthorizationSlice.reducer;

export { AuthorizationReducer };
