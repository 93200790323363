import React from 'react';

import { useOutletAsset } from '@constants/OutletContextEntityPages';
import { useClassPrefix } from '@hooks';

import './ReportAsset.scss';

function ReportAsset() {
  const { entity } = useOutletAsset();

  const { prefix, withPrefix } = useClassPrefix('report-asset-page');

  return (
    <div className={prefix}>
      <iframe
        className={withPrefix('iframe')}
        title="ProjectRise"
        height="541.25"
        src={`https://app.powerbi.com/reportEmbed?reportId=ded13991-e5e1-42ad-bd4e-acc1a871f8dd&autoAuth=true&ctid=ca9a226b-261e-4bf9-9965-895255ba3e87&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLWQtcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D&filter=Assets/NonCurrAssetId eq ${entity.nonCurrAssetId}`}
        allowFullScreen={true}
      ></iframe>
    </div>
  );
}

export default ReportAsset;
