import { createSlice } from '@reduxjs/toolkit';
import {
  addAsAtCard,
  getAsAtCardById,
  getAsAtCards,
  removeAsAtCardById,
  updateAsAtCard,
} from '@store/actions/properties/AsAt/Card';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { AsAtCardModel } from '@model/properties/AsAt/Card';

interface AsAtCardState {
  AsAtCardsList: AsAtCardModel[];
  AsAtCard: AsAtCardModel | null;
  AsAtCardStatus: LoadingStatus;
  AsAtCardError: string | null;
}

const initialState: AsAtCardState = {
  AsAtCardsList: [],
  AsAtCard: null,
  AsAtCardStatus: 'idle',
  AsAtCardError: null,
};

const AsAtCardSlice = createSlice({
  name: 'AsAtCard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsAtCards.pending, (state) => {
        state.AsAtCardError = null;
        state.AsAtCardStatus = 'pending';
      })
      .addCase(getAsAtCards.fulfilled, (state, { payload }) => {
        state.AsAtCardStatus = 'idle';
        state.AsAtCardsList = payload;
      })
      .addCase(getAsAtCards.rejected, (state, { payload }) => {
        state.AsAtCardStatus = 'idle';
        if (payload) {
          state.AsAtCardError = payload;
        }
      });

    builder
      .addCase(addAsAtCard.pending, (state) => {
        state.AsAtCardStatus = 'pending';
        state.AsAtCardError = null;
      })
      .addCase(addAsAtCard.fulfilled, (state, { payload }) => {
        state.AsAtCardStatus = 'idle';
        state.AsAtCardsList = [payload, ...state.AsAtCardsList];
      })
      .addCase(addAsAtCard.rejected, (state, { payload }) => {
        state.AsAtCardStatus = 'idle';
        if (payload) {
          state.AsAtCardError = payload;
        }
      });

    builder
      .addCase(getAsAtCardById.pending, (state) => {
        state.AsAtCardStatus = 'pending';
        state.AsAtCardError = null;
      })
      .addCase(getAsAtCardById.fulfilled, (state, { payload }) => {
        state.AsAtCardStatus = 'idle';
        state.AsAtCard = payload;
      })
      .addCase(getAsAtCardById.rejected, (state, { payload }) => {
        state.AsAtCardStatus = 'idle';
        if (payload) {
          state.AsAtCardError = payload;
        }
      });

    builder
      .addCase(updateAsAtCard.pending, (state) => {
        state.AsAtCardStatus = 'pending';
        state.AsAtCardError = null;
      })
      .addCase(updateAsAtCard.fulfilled, (state, { payload }) => {
        state.AsAtCardStatus = 'idle';
        state.AsAtCardsList = payload.AsAtCardsList;
        state.AsAtCard = payload.AsAtCard;
      })
      .addCase(updateAsAtCard.rejected, (state, { payload }) => {
        state.AsAtCardStatus = 'idle';
        if (payload) {
          state.AsAtCardError = payload;
        }
      });

    builder
      .addCase(removeAsAtCardById.pending, (state) => {
        state.AsAtCardStatus = 'pending';
        state.AsAtCardError = null;
      })
      .addCase(removeAsAtCardById.fulfilled, (state, { payload }) => {
        state.AsAtCardStatus = 'idle';
        state.AsAtCardsList = payload;
      })
      .addCase(removeAsAtCardById.rejected, (state, { payload }) => {
        state.AsAtCardStatus = 'idle';
        if (payload) {
          state.AsAtCardError = payload;
        }
      });
  },
});

const AsAtCardReducer = AsAtCardSlice.reducer;

export { AsAtCardReducer };
