import { createSlice } from '@reduxjs/toolkit';
import {
  addRole,
  getRoleById,
  getRoles,
  removeRoleById,
  updateRole,
} from '@store/actions/user/Role';

import type { LoadingStatus } from '@constants/loadingStatus';
import type { RoleModel } from '@model/users/Role';

interface RoleState {
  roleList: RoleModel[];
  role: null | RoleModel;
  roleStatus: LoadingStatus;
  roleError: string | null;
}

const initialState: RoleState = {
  roleList: [],
  role: null,
  roleStatus: 'idle',
  roleError: null,
};

const RoleSlice = createSlice({
  name: 'Role',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.roleStatus = 'pending';
        state.roleError = null;
      })
      .addCase(getRoles.fulfilled, (state, { payload }) => {
        state.roleStatus = 'idle';
        state.roleList = payload;
      })
      .addCase(getRoles.rejected, (state, { payload }) => {
        state.roleStatus = 'failed';
        if (payload) {
          state.roleError = payload;
        }
      });
    builder
      .addCase(addRole.pending, (state) => {
        state.roleError = null;
        state.roleStatus = 'pending';
      })
      .addCase(addRole.fulfilled, (state, { payload }) => {
        state.roleStatus = 'idle';
        state.roleList = payload.roleList;
      })
      .addCase(addRole.rejected, (state) => {
        state.roleStatus = 'idle';
      });
    builder
      .addCase(getRoleById.pending, (state) => {
        state.roleError = null;
        state.roleStatus = 'pending';
      })
      .addCase(getRoleById.fulfilled, (state, { payload }) => {
        state.roleStatus = 'idle';
        state.role = payload;
      })
      .addCase(getRoleById.rejected, (state) => {
        state.roleStatus = 'idle';
      });
    builder
      .addCase(removeRoleById.pending, (state) => {
        state.roleError = null;
        state.roleStatus = 'pending';
      })
      .addCase(removeRoleById.fulfilled, (state, { payload }) => {
        state.roleStatus = 'idle';
        state.roleList = payload;
      })
      .addCase(removeRoleById.rejected, (state) => {
        state.roleStatus = 'idle';
      });
    builder
      .addCase(updateRole.pending, (state) => {
        state.roleError = null;
        state.roleStatus = 'pending';
      })
      .addCase(updateRole.fulfilled, (state, { payload }) => {
        state.roleStatus = 'idle';
        state.role = payload.role;
        state.roleList = payload.roles;
      })
      .addCase(updateRole.rejected, (state) => {
        state.roleStatus = 'idle';
      });
  },
});

const RoleReducer = RoleSlice.reducer;

export { RoleReducer };
