import { createAsyncThunk } from '@reduxjs/toolkit';
import { VendorRepository } from '@repositories';

import type {
  CreateVendorDto,
  FilterVendorDto,
  UpdateVendorDto,
  VendorModel,
} from '@model/properties/Vendor';
import type { ApiError } from '@services/Axios';
import type { RootState } from '@store/rootReducer';

// region --- Get Actions

export const getVendors = createAsyncThunk('Vendor/getVendors', async (_, { rejectWithValue }) => {
  try {
    const response = await VendorRepository.fetchAll();
    return response.resultObject;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response);
  }
});

export const getVendorsByFilter = createAsyncThunk<
  {
    vendors: VendorModel[];
    vendorFilters: FilterVendorDto;
  },
  FilterVendorDto,
  { rejectValue: string }
>('Vendor/getVendorsByFilter', async (dto, { rejectWithValue }) => {
  try {
    const response = await VendorRepository.fetchAllByFilter(dto);
    return { vendors: response.resultObject, vendorFilters: dto };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

export const getVendorById = createAsyncThunk(
  'Vendor/getVendorById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await VendorRepository.fetchById(id);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Get Actions

// region --- Add Actions

export const addVendor = createAsyncThunk(
  'Vendor/addVendor',
  async (dto: CreateVendorDto, { rejectWithValue }) => {
    try {
      const response = await VendorRepository.add(dto);

      return response.resultObject;
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Add Actions

// region --- Update Actions

export const updateVendor = createAsyncThunk<
  { vendors: VendorModel[]; vendor: VendorModel },
  UpdateVendorDto,
  { state: RootState }
>('Vendor/updateVendor', async (dto, { rejectWithValue, getState }) => {
  try {
    const response = await VendorRepository.update(dto);

    const { resultObject } = response;

    const { vendorsList } = getState().VendorReducer;

    const vendors = vendorsList.map((vendor) =>
      vendor.partnerId === resultObject.partnerId ? resultObject : vendor
    );

    return { vendors, vendor: resultObject };
  } catch (err: any) {
    const error: ApiError = err;
    if (!error.data) {
      throw err;
    }
    return rejectWithValue(error.data.languageKeyword);
  }
});

// endregion --- Update Actions

// region --- Remove Actions

export const removeVendorById = createAsyncThunk<VendorModel[], number[], { state: RootState }>(
  'Vendor/removeVendorById',
  async (ids, { rejectWithValue, getState }) => {
    try {
      await VendorRepository.removeById(ids);

      const { vendorsList } = getState().VendorReducer;

      return vendorsList.filter((vendor) => !ids.includes(vendor.partnerId));
    } catch (err: any) {
      const error: ApiError = err;
      if (!error.data) {
        throw err;
      }
      return rejectWithValue(error.data.languageKeyword);
    }
  }
);

// endregion --- Remove Actions
