import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { validateMessagesEN, validateMessagesTR } from '@constants/validateMessages';
import { Form as AntdForm } from 'antd';
import cl from 'classnames';

import { Item } from './Item';

import type { FormProps as AntdFormProps } from 'antd';

import './BaseForm.scss';

export interface BaseFormProps<T = any> extends Omit<AntdFormProps<T>, 'className'> {
  className?: string | string[];
}

export interface BaseFormInterface {
  useForm: typeof AntdForm.useForm;
  useFormInstance: typeof AntdForm.useFormInstance;
  useWatch: typeof AntdForm.useWatch;
  Provider: typeof AntdForm.Provider;
  Item: typeof Item;
  List: typeof AntdForm.List;
}

interface DefaultFormInterface extends BaseFormInterface {
  <T>(props: T): React.ReactElement;
}

const BaseForm: DefaultFormInterface = <T,>(props: BaseFormProps<T>) => {
  const { layout = 'vertical', className, form, ...rest } = props;

  const { i18n } = useTranslation();

  const classes = cl('custom-base-form', className);

  const handleLanguageChanged = () => {
    const errors = form
      ?.getFieldsError()
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), [] as any);

    setTimeout(() => form?.validateFields(errors));
  };

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  return (
    <AntdForm
      form={form}
      validateMessages={i18n.language === 'tr' ? validateMessagesTR : validateMessagesEN}
      layout={layout}
      className={classes}
      {...rest}
    />
  );
};

BaseForm.useForm = AntdForm.useForm;
BaseForm.Provider = AntdForm.Provider;
BaseForm.List = AntdForm.List;
BaseForm.Item = Item;
BaseForm.useFormInstance = AntdForm.useFormInstance;
BaseForm.useWatch = AntdForm.useWatch;

export { BaseForm };
