import type { RootState } from '@store/rootReducer';

export const selectLocalPageState = (state: RootState) => state.LocalReducer;

export const selectPageInfo = (state: RootState) => selectLocalPageState(state).pageInfo;

export const selectDefaultPagePath = (state: RootState) => selectPageInfo(state).defaultPagePath;

export const selectTableActivePage = (state: RootState) =>
  selectLocalPageState(state).tableState.activePage;

export const selectPageTabs = (state: RootState) => selectLocalPageState(state).pageInfo.tabs;

export const selectStrictCompareTabs = (state: RootState) =>
  selectPageInfo(state)?.strictCompareTabs;
