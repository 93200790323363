import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@hooks';
import { liftChildToTop } from '@shared/utils/functions';

import { EmptyDataPage } from './EmptyDataPage';
import { Header } from './Header';
import { Table } from './Table';

import type { ColumnsType } from 'antd/lib/table';
import type { EmptyDataPageProps } from './EmptyDataPage';
import type { HeaderProps } from './Header';
import type { TableProps } from './Table';

import './BasicTablePage.scss';

export type ColumnsTableType<T> = ColumnsType<T>;

export interface DragEnd<T = Record<string, any>> {
  draggingRecords: T;
  draggingRecordId: number;
  dropRecord: T | 0;
  dropId: number;
}

export interface BasicTablePageProps<T = any, CSV = any> {
  table: TableProps<T> & EmptyDataPageProps;
  header: HeaderProps<CSV>;
  additionalChildren?: React.ReactElement;
  showEmptyPage?: boolean;

  customHeader?: () => React.ReactNode
}

const BasicTablePage = <T extends Record<string, any>, CSV>(props: BasicTablePageProps<T, CSV>) => {
  const {
    table: {dataSourceName, dataSource, ...table},
    header,
    additionalChildren,
    showEmptyPage = true,
    customHeader
  } = props;

  const [data, setData] = useState<T[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const debounceValue = useDebounce(searchValue, 400);
  const {t} = useTranslation();

  useEffect(() => {
    if (dataSource && dataSource.length) {
      setData(dataSource as T[]);
    }
  }, [dataSource]);

  useEffect(() => {
    if (debounceValue.length <= 1) {
      setData(dataSource as T[]);
    }
    if (debounceValue.length > 0) {
      const values = checkSearchValue();
      setData(values);
    }
  }, [debounceValue]);

  function getValueByKey(
    item: Record<string, any> | null,
    keys: string[]
  ): (string | number | null)[] {
    if (!item) return [null];
    return keys
      .map((key) => {
        const isNestedKey = key.includes('.') || Array.isArray(key);
        if (isNestedKey) {
          const splitKey = Array.isArray(key) ? (key as string[]) : key.split('.');
          const val = getValueByKey(item[splitKey[0]], splitKey.slice(1));
          return val[0];
        }
        const value = item[key];

        return Array.isArray(value) ? null : value;
      })
      .filter((v) => Boolean(v));
  }

  function checkSearchValue() {
    const keys = table.columns
      ? (table.columns.map((col) => {
        if (Object.hasOwn(col, 'dataIndex')) {
          return (col as any).dataIndex;
        }
        return col.key;
      }) as string[])
      : [];
    let searchableData = dataSource as T[];

    if (table.expandable) {
      const name = table.expandable.childrenColumnName;
      if (name) {
        searchableData = liftChildToTop(dataSource as T[], name) as T[];
      }
    }
    return searchableData.filter((value) => {
      return getValueByKey(value, keys).some((word) => {
        return `${word}`.toLowerCase().includes(searchValue.toLowerCase());
      });
    });
  }

  if (dataSource && !Boolean(dataSource.length) && !table.loading && showEmptyPage) {
    return (
      <EmptyDataPage
        titleRedirectButton={header.titleRedirectButton}
        dataSourceName={dataSourceName}
      />
    );
  }

  const renderTable =
    (searchValue.length > 0 && data.length === 0 && dataSource && dataSource.length > 0) ||
    table.recordNotFound ? (
      <div className="reusable-table-page-not-found">
        <p>{t('errors.Not_Found_Search')}</p>
      </div>
    ) : (
      <DndProvider backend={HTML5Backend}>
        <Table dataSource={data} {...table} />
      </DndProvider>
    );

  return (
    <div className="reusable-table-page">
      {customHeader ? customHeader() :
        <Header setSearchValue={setSearchValue} {...header} />}
      {additionalChildren}
      {renderTable}
    </div>
  );
};

export {BasicTablePage};
