import { api } from '@services/Axios';

import type { DashboardModel } from '@model/dashboard';
import type { ApiResponse } from '@services/Axios';

const DashboardUrl = '/Dashboard';

export const DashboardRepository = {
  async fetchAll() {
    const response: ApiResponse<DashboardModel> = await api.get(
        `${DashboardUrl}/GetDashboardGraphDatas`
    );

    return response.data;
  },
};