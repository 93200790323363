import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm } from '@components/common';
import { IterationEndDate } from '@components/common/IterationEndDate';
import { createInitialIterationState } from '@components/views/Forms/managment/Maintenance/GeneralV2/_util/createInitialIterationState';
import { defaultFormatDate } from '@constants/Date';
import { IterationValue } from '@constants/iterationValue';
import { useAppDispatch, useAppSelector } from '@hooks';
import { Checkbox, Radio } from '@shared/UI';
import { checkStoreStatuses } from '@shared/utils/functions';
import { getAsAtCards } from '@store/actions/properties/AsAt/Card';
import { getAsAtCategories } from '@store/actions/properties/AsAt/Category';
import { getMaAtCategories } from '@store/actions/properties/MaAt/Category';
import { getMaAtRequestReasons } from '@store/actions/properties/MaAt/RequestReason';
import { getUsers } from '@store/actions/user/User';
import { selectMaintenanceStatus } from '@store/selectors/management/Maintenance';
import { selectAsAtCardsList, selectAsAtCardStatus } from '@store/selectors/properties/AsAt/Card';
import {
  selectAsAtCategoriesList,
  selectAsAtCategoryStatus,
} from '@store/selectors/properties/AsAt/Category';
import {
  selectMaAtCategoriesList,
  selectMaAtCategoryStatus,
} from '@store/selectors/properties/MaAt/Category';
import {
  selectMaAtRequestReasonsList,
  selectMaAtRequestReasonStatus,
} from '@store/selectors/properties/MaAt/RequestReason';
import { selectUsersListWithoutChildren, selectUserStatus } from '@store/selectors/users/User';
import { Col, Row } from 'antd';
import moment from 'moment';

import type { CommonPreviewProps, DefaultCommonFormProps } from '@components/common';
import type {
  CreateMaintenanceGeneralV2Dto,
  MaintenanceV2Model,
} from '@model/management/Maintenance';

const { CaseField, Item, PreviewField } = CommonForm;

const PreviewFormMaintenanceGeneral = (
  props: CommonPreviewProps<MaintenanceV2Model> & { initialValues: CreateMaintenanceGeneralV2Dto }
) => {
  const { entity, initialValues } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // region --- state variables

  const statusMaintenance = useAppSelector(selectMaintenanceStatus);
  const statusUser = useAppSelector(selectUserStatus);
  const statusAssetName = useAppSelector(selectAsAtCardStatus);
  const statusMaintenanceReason = useAppSelector(selectMaAtRequestReasonStatus);
  const statusMaintenanceCategory = useAppSelector(selectMaAtCategoryStatus);
  const statusAsAtCategory = useAppSelector(selectAsAtCategoryStatus);

  const listUsers = useAppSelector(selectUsersListWithoutChildren);
  const listAssetNames = useAppSelector(selectAsAtCardsList);
  const listMaintenanceReason = useAppSelector(selectMaAtRequestReasonsList);
  const listMaintenanceCategory = useAppSelector(selectMaAtCategoriesList);
  const listAsAtCategoriesList = useAppSelector(selectAsAtCategoriesList);

  // endregion --- state variables

  useEffect(() => {
    if (!listUsers.length) {
      dispatch(getUsers());
    }
    if (!listAssetNames.length && entity.nonCurrAssetCardId !== null) {
      dispatch(getAsAtCards());
    }
    if (!listMaintenanceReason.length && entity.requestReasonId !== null) {
      dispatch(getMaAtRequestReasons());
    }

    if (listMaintenanceCategory.length === 0) {
      dispatch(getMaAtCategories());
    }

    if (listAsAtCategoriesList.length === 0 && entity.nonCurrAssetCardCategoryId !== null) {
      dispatch(getAsAtCategories());
    }
  }, []);

  const findUser = (id: number | null) => {
    if (!id) return '';
    const user = listUsers.find((currUser) => currUser.userId === id);
    return user ? `${user.firstName} ${user.lastName}` : '';
  };

  const additionalUsers = entity.maintenanceAssignTos.map((currUser) => {
    return `${findUser(currUser.userId)}, `;
  });

  const maintenanceCategory =
    listMaintenanceCategory.find(
      (category) => category.maintinanceCategoryId === entity.maintinanceCategoryId
    )?.categoryName || '';

  const foundAsset =
    listAssetNames.find((asset) => asset.nonCurrAssetCardId === entity.nonCurrAssetCardId)?.name ||
    '';

  const foundRequestReason =
    listMaintenanceReason.find((reason) => reason.requestReasonId === entity.requestReasonId)
      ?.reasonName || '';

  const foundAsAtCategory = listAsAtCategoriesList.find(
    (category) => category.nonCurrAssetCardCategoryId === entity.nonCurrAssetCardCategoryId
  )?.name;

  // region --- Iteration Date
  const dueDate = entity.dueDate ? moment(entity.dueDate).format(defaultFormatDate) : '';

  // endregion --- Iteration Date

  const loading = checkStoreStatuses([
    statusMaintenanceCategory,
    statusMaintenance,
    statusUser,
    statusAssetName,
    statusMaintenanceReason,
    statusAsAtCategory,
  ]);

  const header: DefaultCommonFormProps['header'] = useMemo(
    () => ({
      title: entity.title,
      hiddenDeleteButton: true,
      hiddenEditButton: !entity.buttonPermit,
    }),
    [entity]
  );

  const iterationValues = createInitialIterationState(initialValues.dateSelector);

  return (
    <CommonForm
      isOutsidePreviewBack={false}
      isPreview
      loading={loading}
      header={header}
      alignAdditionalHeader="left"
    >
      <CaseField>
        <Item label={t('titles.Title')}>
          <PreviewField>{entity.title}</PreviewField>
        </Item>
        <Item label={`${t('pages_single.Maintenance')} ${t('titles.Category')}`}>
          <PreviewField>{maintenanceCategory}</PreviewField>
        </Item>
        <Item label={t('titles.Priority')}>
          <Radio.Group value={entity.maintenancePriorityId}>
            <Radio
              value={3}
              checked={entity.maintenancePriorityId === 3}
              disabled={entity.maintenancePriorityId !== 3}
            >
              {t('titles.Low')}
            </Radio>
            <Radio
              value={2}
              checked={entity.maintenancePriorityId === 2}
              disabled={entity.maintenancePriorityId !== 2}
            >
              {t('titles.Medium')}
            </Radio>
            <Radio
              value={1}
              checked={entity.maintenancePriorityId === 1}
              disabled={entity.maintenancePriorityId !== 1}
            >
              {t('titles.High')}
            </Radio>
          </Radio.Group>
        </Item>
        <Item label={t('titles.Site')}>
          <PreviewField>{entity?.site?.name}</PreviewField>
        </Item>
        <Item label={t('titles.Description')}>
          <PreviewField typeField="textarea">{entity.description}</PreviewField>
        </Item>
        <Item label={t('titles.Assign_To')}>
          <PreviewField>{findUser(entity.assignToId)}</PreviewField>
        </Item>
        <Item>
          <PreviewField>{additionalUsers}</PreviewField>
        </Item>
      </CaseField>
      <CaseField>
        <Item>
          <Checkbox
            disabled={entity.maintenanceTypeId === 2}
            checked={entity.maintenanceTypeId === 1}
          >
            {t('titles.Request')}
          </Checkbox>
        </Item>
        <Row justify="space-between">
          <Col lg={11} md={24}>
            <Item disabledItem={entity.maintenanceTypeId === 2} label={t('titles.Start_Date')}>
              <PreviewField inputTextCenter>{dueDate}</PreviewField>
            </Item>
          </Col>
          <Col lg={11} md={24}>
            <Item
              disabledItem={entity.maintenanceTypeId === 2}
              label={t('titles.Estimated_Duration')}
            >
              <PreviewField inputTextCenter>{entity?.estimatedDuration}</PreviewField>
            </Item>
          </Col>
        </Row>

        {!initialValues.isRequest &&
          iterationValues &&
          iterationValues.repeatType !== IterationValue.NEVER && (
            <IterationEndDate
              type="preview"
              iterationType={iterationValues.repeatType}
              startDate={iterationValues.startDate}
              endDate={iterationValues.endDate}
              daysOfWeek={iterationValues.daysOfWeek}
              monthType={iterationValues.monthType}
            />
          )}

        <Item label={t('titles.Card_Category')}>
          <PreviewField>{foundAsAtCategory}</PreviewField>
        </Item>
        <Item label={t('titles.Reason')}>
          <PreviewField>{foundRequestReason}</PreviewField>
        </Item>

        <Item label={t('titles.Asset_Card')}>
          <PreviewField>{foundAsset}</PreviewField>
        </Item>
        <Item label={t('titles.Barcode')}>
          <PreviewField>{entity.barcode}</PreviewField>
        </Item>
      </CaseField>
    </CommonForm>
  );
};

export { PreviewFormMaintenanceGeneral };
