import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

export const NotFoundByFilter = memo(() => {
  const { t } = useTranslation();

  return (
    <div className="empty-data-page-description">
      <h6>{t('errors.Not_Found_Search')}</h6>
    </div>
  );
});
