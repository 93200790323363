import { createSelector } from '@reduxjs/toolkit';
import { liftChildToTop } from '@shared/utils/functions';

import type { RootState } from '@store/rootReducer';

export const selectState = (state: RootState) => state.SiteReducer;

export const selectSitesList = (state: RootState) => selectState(state).sitesList;

export const selectSite = (state: RootState) => selectState(state).site;

export const selectSiteStatus = (state: RootState) => selectState(state).siteStatus;

export const selectSiteError = (state: RootState) => selectState(state).siteError;

export const selectSitesFilteredList = (state: RootState) => selectState(state).sitesFilteredList;

export const selectSiteFilters = (state: RootState) => selectState(state).siteFilters;

export const selectSitesListWithoutChildren = createSelector(selectSitesList, (sitesList) => {
  return liftChildToTop(sitesList, 'childSite');
});
