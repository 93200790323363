import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useDebounce } from '@hooks';
import { InputSearch } from '@shared/UI';
import { getAssets } from '@store/actions/management/Asset';

export type SearchProps = {
  disabledSearch?: boolean;
};

export const Search = (props: SearchProps) => {
  const { disabledSearch } = props;

  const { t } = useTranslation();
  
  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState<string | undefined>();

  const debouncedInputValue = useDebounce(inputValue, 500);

  const onHandleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInputValue(value);
  };

  const onHandleSearch = (value: string) => {
    dispatch(getAssets({ page: 1, searchKeyword: value }));
  };

  useEffect(() => {
    if (
      debouncedInputValue !== undefined &&
      (debouncedInputValue.length > 2 || debouncedInputValue === '')
    ) {
      onHandleSearch(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  return (
    <InputSearch 
      onChange={onHandleInput} 
      placeholder={t('titles.Search')} 
      value={inputValue} 
      disabled={disabledSearch}
    />
  )
}