import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonForm, RoleAuthorizationsList } from '@components/common';
import { useAppDispatch, useAppSelector } from '@hooks';
import { getAuthorizations } from '@store/actions/extra/Authorization';
import { selectAuthorizationList } from '@store/selectors/extra/Authorization';
import { selectRoleStatus } from '@store/selectors/users/Role';

import type { CommonPreviewProps } from '@components/common/CommonForm';
import type { RoleModel } from '@model/users/Role';

const { CaseField, Item, PreviewField } = CommonForm;

function PreviewFormRole(props: CommonPreviewProps<RoleModel>) {
  const { entity, onDelete } = props;

  const { t } = useTranslation();

  const statusUser = useAppSelector(selectRoleStatus);
  const authorizationList = useAppSelector(selectAuthorizationList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!authorizationList.length) {
      dispatch(getAuthorizations());
    }
  }, []);

  return (
    <CommonForm
      isPreview
      loading={statusUser === 'pending'}
      header={{
        title: entity.name,
        onClickDelete: onDelete,
      }}
      container={{
        style: {
          maxWidth: '100%',
        },
      }}
    >
      <CaseField md={10}>
        <Item label={t('titles.Role_Name')}>
          <PreviewField>{entity.name}</PreviewField>
        </Item>
        <Item label={t('titles.Description')}>
          <PreviewField typeField="textarea">{entity.description}</PreviewField>
        </Item>
      </CaseField>
      <CaseField md={14} hiddenTitle>
        <RoleAuthorizationsList isPreview value={entity.roleFunctionNameObject} />
      </CaseField>
    </CommonForm>
  );
}

export { PreviewFormRole };
