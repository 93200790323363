import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useHandleParamId, useSetPageInfo } from '@hooks';
import { paths } from '@routes/paths';
import { Loader } from '@shared/UI';
import { getTitleById } from '@store/actions/user/Title';
import { selectTitle } from '@store/selectors/users/Title';

import type { IOutletTitle } from '@constants/OutletContextEntityPages';
import type { CreateTitleDto } from '@model/users/Title';

function Title() {
  const dispatch = useAppDispatch();
  const { decryptedId } = useHandleParamId();
  const title = useAppSelector(selectTitle);

  const condition = !title || title.userTitleId !== +decryptedId;

  useSetPageInfo({
    pageTitle: 'pages_single.Title',
    defaultPagePath: paths.title.default,
  });

  useEffect(() => {
    if (condition) {
      dispatch(getTitleById(decryptedId));
    }
  }, []);

  if (condition) {
    return <Loader middle />;
  }

  const initialValues: CreateTitleDto = {
    title: title.title,
    userTitleCode: title.userTitleCode,
  };

  return <Outlet context={{ initialValues, entity: title } as IOutletTitle} />;
}

export default Title;
