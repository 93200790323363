import { useContext } from 'react';

import { RoleAuthContext } from './RoleAuthContext';

export function useRoleAuthContext() {
  const ctx = useContext(RoleAuthContext);

  if (!ctx) {
    throw new Error('RoleAuthContext must be inside a Provider with a value');
  }

  return ctx;
}
